import Box from '@mui/material/Box';
import AuthCoverSrc from '../../../../../assets/images/auth-cover.png';

const AuthCoverImage = () => (
  <Box
    position="absolute"
    right={0}
    top="50%"
    width={631}
    height={578}
    sx={{
      backgroundImage: `url('${AuthCoverSrc}')`,
      backgroundSize: 'cover',
      zIndex: 2,
      transform: 'translateY(-50%)',
    }}
  />
);

const AuthCover = () => (
  <Box
    width="100%"
    bgcolor="primary.main"
    position="relative"
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={(theme) => ({
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    })}
  >
    <Box
      position="absolute"
      width="114px"
      height="114px"
      bgcolor="rgba(255, 255, 255, 0.1)"
      top="calc(100% - 114px)"
      right={0}
    />
    <Box
      position="absolute"
      width="182px"
      height="70px"
      bgcolor="rgba(255, 255, 255, 0.1)"
      top={0}
      left={0}
    />
    <Box position="relative" width="100%" height={720} flex={1}>
      <AuthCoverImage />
    </Box>
  </Box>
);

export default AuthCover;
