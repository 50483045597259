import rectangleDrawer from './rectangleDrawer';
import circleDrawer from './circleDrawer';
import ellipseDrawer from './ellipseDrawer';
import lineDrawer from './lineDrawer';
import freeLineDrawer from './freeLineDrawer';
import triangleDrawer from './triangleDrawer';
import textDrawer from './textDrawer';
import pointerDrawer from './pointerDrawer';
import imageDrawer from './imageDrawer';
import arrowDrawer from './arrowDrawer';
import pathShapeDrawer from './pathShapeDrawer';
import formulaDrawer from './formulaDrawer';
import graphDrawer from './graphDrawer';
import cursorDrawer from './cursorDrawer';

const drawers = {
  rectangle: rectangleDrawer,
  circle: circleDrawer,
  oval: ellipseDrawer,
  line: lineDrawer,
  free: freeLineDrawer,
  free_v2: freeLineDrawer,
  free_v3: freeLineDrawer,
  erase: freeLineDrawer,
  erase_v2: freeLineDrawer,
  erase_v3: freeLineDrawer,
  triangle: triangleDrawer,
  text: textDrawer,
  pointDot: pointerDrawer,
  imageMeta: imageDrawer,
  lockedImageMeta: imageDrawer,
  arrow: arrowDrawer,
  pathShape: pathShapeDrawer,
  formula: formulaDrawer,
  graph: graphDrawer,
  cursor: cursorDrawer,
};

export default drawers;
