import { DisplayObject, Graphics, Rectangle } from 'pixi.js';
import Shape from './shape';
import OldLineAction from '../../../common/drawingActions/shapes/line.drawing.action';
import ActionName from '../types/ActionName';

class Line extends Shape {
  constructor(action?: OldLineAction) {
    super(action);

    this.name = ActionName.LINE;
  }

  toDto(): OldLineAction {
    const dto = new OldLineAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldLineAction) {
    super.setFields(action);
    action.name = ActionName.LINE;
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    graphicsObject.clear();
    graphicsObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    graphicsObject.lineStyle(size, color);

    graphicsObject.moveTo(-width / 2, -height / 2);
    graphicsObject.lineTo(width / 2, height / 2);

    const hitAreaWidth = Math.max(Math.abs(width), 20);
    const hitAreaHeight = Math.max(Math.abs(height), 20);

    graphicsObject.hitArea = new Rectangle(
      -(hitAreaWidth + size) / 2,
      -(hitAreaHeight + size) / 2,
      hitAreaWidth,
      hitAreaHeight
    );
  }
}

export default Line;
