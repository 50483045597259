import React from 'react';
import PropTypes from 'prop-types';
import { BehaviorSubject, interval } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';
import fixWebmDuration from 'fix-webm-duration';
import RecordingContext from './RecordingContext';
import * as waterMarkImageUtils from '../../common/utils/watermark-image.utils';
import { noop } from '../../common/constants';

const getBestVideoType = () => {
  const candidates = [
    'video/mp4;codecs=h264',
    'video/mp4',
    'video/webm;codecs=h264',
    'video/webm',
  ];

  const availableType = candidates.filter((t) =>
    MediaRecorder.isTypeSupported(t)
  );

  return availableType[0];
};

class RecordingContextProvider extends React.Component {
  mediaRecorder = null;

  audioContext = null;

  audioDestinationNode = null;

  conferenceStream = null;

  localConferenceAudioNode = null;

  remoteConferenceAudioNode = null;

  localAudioStream = null;

  localAudioNode = null;

  canvasStream = null;

  canvasDrawingSubscription = null;

  frontLayer$ = new BehaviorSubject();

  backgroundLayer$ = new BehaviorSubject();

  interactionLayer$ = new BehaviorSubject();

  isPause$ = new BehaviorSubject();

  recordingCanvasRef = null;

  drawingContext = null;

  mimeType = null;

  constructor(props) {
    super(props);

    this.state = {
      isRecording: false,
      chunks: [],
      blob: null,
      thumbnail: null,
      startTime: null,
    };

    this.isRecordingFeatureEnabled =
      window.MediaRecorder && window.MediaRecorder.isTypeSupported;
    if (this.isRecordingFeatureEnabled) {
      this.mimeType = getBestVideoType();
    }
  }

  componentWillUnmount() {
    const { isRecording } = this.state;
    this.frontLayer$.complete();
    this.backgroundLayer$.complete();
    this.interactionLayer$.complete();

    if (isRecording) {
      this.canvasDrawingSubscription.unsubscribe();
      this.mediaRecorder.ondataavailable = noop;
      this.mediaRecorder.onstop = noop;
      this.mediaRecorder.stop();
      this.audioContext.close();
      this.canvasStream.getTracks().forEach((t) => t.stop());
      if (this.localAudioStream) {
        this.localAudioStream.getTracks().forEach((t) => t.stop());
      }
    }
  }

  setFrontLayer = (ref) => {
    this.frontLayer$.next(ref);
  };

  setBackgroundLayer = (ref) => {
    this.backgroundLayer$.next(ref);
  };

  setInteractionLayer = (ref) => {
    this.interactionLayer$.next(ref);
  };

  setRecordingCanvasRef = (ref) => {
    this.recordingCanvasRef = ref;
  };

  setConferenceAudioStream = async (conferenceStream) => {
    this.conferenceStream = conferenceStream;

    await this.setupAudio();
  };

  clearConferenceAudioStream = () => {
    this.conferenceStream = null;
  };

  setupAudioContext = () => {
    this.audioContext = new AudioContext();
    this.audioDestinationNode =
      this.audioContext.createMediaStreamDestination();
  };

  setupRecordingCanvas = (watermarkInfo) => {
    this.recordingCanvasRef.width = 1280;
    this.recordingCanvasRef.height = 720;
    this.drawingContext = this.recordingCanvasRef.getContext('2d');
    this.canvasDrawingSubscription = interval(16)
      .pipe(
        withLatestFrom(
          this.frontLayer$,
          this.backgroundLayer$,
          this.interactionLayer$,
          this.isPause$
        ),
        map(([, frontCanvas, backCanvas, interactionCanvas, isPause]) => ({
          frontCanvas,
          backCanvas,
          interactionCanvas,
          isPause,
        }))
      )
      .subscribe(({ frontCanvas, backCanvas, interactionCanvas, isPause }) => {
        if (isPause) return;

        this.drawingContext.clearRect(
          0,
          0,
          this.recordingCanvasRef.width,
          this.recordingCanvasRef.height
        );
        this.drawingContext.drawImage(
          backCanvas,
          0,
          0,
          this.recordingCanvasRef.width,
          this.recordingCanvasRef.height
        );
        this.drawingContext.drawImage(
          frontCanvas,
          0,
          0,
          this.recordingCanvasRef.width,
          this.recordingCanvasRef.height
        );
        if (interactionCanvas) {
          this.drawingContext.drawImage(
            interactionCanvas,
            0,
            0,
            this.recordingCanvasRef.width,
            this.recordingCanvasRef.height
          );
        }
        if (!watermarkInfo.enabled) {
          this.drawingContext.drawImage(
            waterMarkImageUtils.getImage(),
            this.recordingCanvasRef.width - 160 - 40,
            this.recordingCanvasRef.height - 58 - 40,
            160,
            58
          );
        }
      });

    this.canvasStream = this.recordingCanvasRef.captureStream(16);
  };

  setupAudio = async () => {
    if (!this.conferenceStream) {
      if (this.localConferenceAudioNode) {
        this.localConferenceAudioNode.disconnect();
      }

      if (this.remoteConferenceAudioNode) {
        this.remoteConferenceAudioNode.disconnect();
      }

      try {
        this.localAudioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.localAudioNode = this.audioContext.createMediaStreamSource(
          this.localAudioStream
        );
        this.localAudioNode.connect(this.audioDestinationNode);
      } catch (e) {
        console.log(e);
      }
      return;
    }

    if (this.localAudioNode) {
      this.localAudioNode.disconnect();
      this.localAudioStream.getTracks().forEach((t) => t.stop());
    }
    if (this.conferenceStream) {
      this.localConferenceAudioNode = this.audioContext.createMediaStreamSource(
        this.conferenceStream
      );

      this.localConferenceAudioNode.connect(this.audioDestinationNode);
    }
  };

  startRecording = async (watermarkInfo) => {
    this.setupAudioContext();
    this.setupRecordingCanvas(watermarkInfo);
    await this.setupAudio();
    this.isPause$.next(false);
    const audioTracks = this.localAudioStream
      ? this.audioDestinationNode.stream.getTracks()
      : [];

    const mediaStream = new MediaStream([
      ...this.canvasStream.getTracks(),
      ...audioTracks,
    ]);

    this.mediaRecorder = new MediaRecorder(mediaStream, {
      mimeType: this.mimeType,
    });

    this.mediaRecorder.ondataavailable = this.handleDataAvailable;
    this.mediaRecorder.onstop = this.handleStop;

    this.mediaRecorder.start();
    this.setState({ isRecording: true });
    this.setState({ isRecording: true, startTime: Date.now() });
  };

  stopRecording = async () => {
    const thumbnail = await new Promise((resolve) => {
      this.recordingCanvasRef.toBlob((b) => {
        resolve(b);
      });
    });

    this.mediaRecorder.stop();
    this.canvasDrawingSubscription.unsubscribe();
    this.setState({ thumbnail });
  };

  pauseRecording = () => {
    this.mediaRecorder.pause();
    this.isPause$.next(true);
  };

  resumeRecording = () => {
    this.mediaRecorder.resume();
    this.isPause$.next(false);
  };

  handleDataAvailable = ({ data }) => {
    const { chunks } = this.state;
    const newChunks = [...chunks, data];
    this.setState({ chunks: newChunks });
  };

  handleStop = async () => {
    const { chunks, startTime } = this.state;
    const duration = Date.now() - startTime;
    const buggyBlob = new Blob(chunks, { type: this.mimeType });
    const blob = await fixWebmDuration(buggyBlob, duration, { logger: false });
    this.setState(
      {
        chunks: [],
        blob,
        isRecording: false,
        startTime,
      },
      () => {
        this.audioContext.close();
        this.canvasStream.getTracks().forEach((t) => t.stop());
        if (this.localAudioStream) {
          this.localAudioStream.getTracks().forEach((t) => t.stop());
        }
      }
    );
  };

  clearBlob = () => {
    this.setState({ blob: null });
  };

  render() {
    const { isRecording, blob, thumbnail } = this.state;
    const { children } = this.props;
    return (
      <RecordingContext.Provider
        value={{
          isRecording,
          blob,
          thumbnail,
          startRecording: this.startRecording,
          stopRecording: this.stopRecording,
          pauseRecording: this.pauseRecording,
          resumeRecording: this.resumeRecording,
          setFrontLayer: this.setFrontLayer,
          setBackgroundLayer: this.setBackgroundLayer,
          setInteractionLayer: this.setInteractionLayer,
          setRecordingCanvas: this.setRecordingCanvasRef,
          setConferenceAudioStream: this.setConferenceAudioStream,
          clearConferenceAudioStream: this.clearConferenceAudioStream,
          clearBlob: this.clearBlob,
          isRecordingFeatureEnabled: this.isRecordingFeatureEnabled,
        }}
      >
        {children}
      </RecordingContext.Provider>
    );
  }
}

RecordingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RecordingContextProvider;
