import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { push } from 'redux-first-history';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import StopIcon from '@mui/icons-material/Stop';
import Card from '../Card';
import CardInfo from '../CardInfo';
import CardThumbnail from '../CardThumbnail';
import FileOutlinePortrait from '../../atoms/Icons/FileOutlinePortraitIcon';
import AllInclusiveBoxOutlineIcon from '../../atoms/Icons/AllInclusiveBoxOutlineIcon';
import MenuItem from '../../atoms/MenuItem';
import Menu from '../Menu';
import BoardType from '../../../common/services/types/session/BoardType';
import analyticsService from '../../../common/services/analytics.service';
import {
  canDeleteSessionSelector,
  canExportPdfSelector,
} from '../../../common/selectors/planPermissionSelectors';
import useDialog from '../../../common/hooks/useDialog';
import * as userReducer from '../../../common/reducers/userReducer';
import * as sessionsActions from '../../../common/actions/sessionsActions';
import { copySessionInList } from '../../../common/actions/sessionsActions';
import { setShouldUpdateThumbnail } from '../../../common/actions/board/shouldUpdateThumbnailActions';
import sessionService from '../../../common/services/session.service';
import PlanDialog from '../PlanDialog';
import StatusType from '../../atoms/Status/type';
import DotsVerticalSvgIcon from '../../atoms/Icons/DotsVerticalIcon';
import { CardType } from '../CardThumbnail/cardThumbnailTypes';
import UpgradeDialog from '../UpgradeDialog';
import { canMoveBoardEnabledSelector } from '../../../common/reducers/configurationReducer';
import useCardAvatarImage from '../../../common/hooks/useCardAvatarImage';
import FileMoveOutlineIcon from '../../atoms/Icons/FileMoveOutlineIcon';
import { setOpenSource } from '../../../common/actions/session/sessionActions';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  id: string;
  ownerId: string;
  altText: string;
  src: string;
  fallbackSrc: string;
  boardName: string;
  boardOwnerName: string;
  boardSubtitle: string;
  date: string;
  onRename: (cardId: string) => void;
  onMove: (cardId: string) => void;
  onExport: (cardId: string) => void;
  onDelete: (cardId: string) => void;
  isShared: boolean;
  isFinished: boolean;
  onCreateDialogOpen: Dispatch<SetStateAction<boolean>>;
  boardType: BoardType;
};

const BoardCard: FC<React.PropsWithChildren<Props>> = ({
  id,
  ownerId,
  altText,
  src,
  fallbackSrc,
  boardName,
  boardOwnerName,
  boardSubtitle,
  date,
  onRename,
  onMove,
  onExport,
  onDelete,
  isShared,
  isFinished,
  onCreateDialogOpen,
  boardType,
}) => {
  const canExportPdf = useSelector(canExportPdfSelector);
  const userId = useSelector(userReducer.userIdSelector);
  const canDeleteSession = useSelector(canDeleteSessionSelector);
  const canMoveBoard = useSelector(canMoveBoardEnabledSelector);

  const [imageSrc, setImageSrc] = useState('');
  const onVisibilityChange = useCallback(
    (inView: any) => {
      if (inView) {
        setImageSrc(src);
      }
    },
    [setImageSrc, src]
  );

  const { ref } = useInView({
    root: null,
    triggerOnce: true,
    rootMargin: '200px 0px',
    onChange: onVisibilityChange,
  });

  const isHost = useMemo(() => userId === ownerId, [userId, ownerId]);

  const isLive = useMemo(() => isShared && !isFinished, [isShared, isFinished]);

  const status = useMemo(() => {
    if (isLive) return StatusType.LIVE;
    if (isFinished) return StatusType.FINISHED;

    return undefined;
  }, [isLive, isFinished]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [planSource, setPlanSource] = useState('');
  const contextMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const location = useLocation();

  const avatarImg = useCardAvatarImage(ownerId);

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const {
    open: upgradeDialog,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const handleContextMenuOpen = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleContextMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onExportPdf = useCallback(() => {
    analyticsService.event('Export As PDF Menu Item Click');

    if (canExportPdf) {
      onExport(id);
    } else {
      onUpgradeDialogOpen();
      setPlanSource('Export PDF');
    }

    handleContextMenuClose();
  }, [
    onExport,
    canExportPdf,
    id,
    onPlansDialogOpen,
    setPlanSource,
    handleContextMenuClose,
  ]);

  const copyBoard = useCallback(() => {
    handleContextMenuClose();
    dispatch(copySessionInList(id, onCreateDialogOpen));
  }, [handleContextMenuClose, dispatch, onCreateDialogOpen, id]);

  const finishSession = useCallback(async () => {
    setAnchorEl(null);
    dispatch(setShouldUpdateThumbnail(true));
    const updatedSession = await sessionService.finishSession(id);
    dispatch(sessionsActions.updateSessionInList(updatedSession));
    dispatch(push(`/group/${updatedSession.groupId || 'default'}/sessions`));
  }, [setAnchorEl, dispatch, id]);

  const deleteSession = useCallback(async () => {
    if (canDeleteSession) {
      await finishSession();
      onDelete(id);

      return;
    }

    onUpgradeDialogOpen();
    handleContextMenuClose();
  }, [
    id,
    canDeleteSession,
    finishSession,
    onDelete,
    handleContextMenuClose,
    onUpgradeDialogOpen,
  ]);

  const cardActions = useMemo(
    () => [
      {
        Icon: DotsVerticalSvgIcon,
        onClick: (event: any) => {
          event.preventDefault();
          event.stopPropagation();
          handleContextMenuOpen(event);
        },
      },
    ],
    [handleContextMenuOpen]
  );

  const onRenameClick = useCallback(() => {
    onRename(id);
    handleContextMenuClose();
  }, [id, onRename, handleContextMenuClose]);

  const onMoveClick = useCallback(() => {
    if (canMoveBoard) {
      onMove(id);
      handleContextMenuClose();

      return;
    }

    onPlansDialogOpen();
    setPlanSource('Move Group');
    handleContextMenuClose();
  }, [
    id,
    canMoveBoard,
    onMove,
    handleContextMenuClose,
    onPlansDialogOpen,
    setPlanSource,
  ]);

  const onDeleteClick = useCallback(() => {
    if (canDeleteSession) {
      onDelete(id);
      handleContextMenuClose();

      return;
    }

    onUpgradeDialogOpen();
    handleContextMenuClose();
  }, [
    id,
    canDeleteSession,
    onDelete,
    handleContextMenuClose,
    onUpgradeDialogOpen,
  ]);

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
    setPlanSource('Delete board');
  }, [onPlansDialogOpen, setPlanSource, onUpgradeDialogClose]);

  return (
    <CardGridItem ref={ref}>
      <Link
        style={{ width: '100%', height: 'auto' }}
        to={{
          pathname: `/session/${id}`,
          state: { referrer: location.pathname },
        }}
        onClick={() => dispatch(setOpenSource('List'))}
      >
        <Card>
          <CardThumbnail
            type={CardType.BOARD}
            status={status}
            altText={altText}
            src={imageSrc}
            fallbackSrc={fallbackSrc}
          />
          <CardInfo
            title={boardName}
            subtitle={isHost ? 'My board' : boardOwnerName}
            subtitleExtra={boardSubtitle}
            date={date}
            CardIcon={
              boardType === BoardType.INFINITE
                ? AllInclusiveBoxOutlineIcon
                : FileOutlinePortrait
            }
            cardActionsData={cardActions}
            avatarImg={avatarImg}
            hasAvatar={!isHost}
          />
        </Card>
      </Link>
      {contextMenuOpen && (
        <Menu
          anchorEl={anchorEl}
          open={contextMenuOpen}
          onClose={handleContextMenuClose}
          listItemWidth={164}
        >
          {isHost && (
            <MenuItem
              title="Rename"
              onSelect={onRenameClick}
              startIcon={<EditIcon />}
            />
          )}
          <MenuItem
            startIcon={<CopyIcon />}
            title="Duplicate"
            onSelect={copyBoard}
          />
          {isHost && !isFinished && (
            <MenuItem
              onSelect={onMoveClick}
              startIcon={<FileMoveOutlineIcon />}
              title="Move"
            />
          )}
          {boardType === BoardType.BASIC &&
            (isHost || (!isHost && canExportPdf)) && (
              <MenuItem
                onSelect={onExportPdf}
                startIcon={<PictureAsPdfIcon />}
                title="Export as PDF"
              />
            )}
          {!isLive && (
            <MenuItem
              startIcon={<DeleteIcon />}
              title="Delete"
              onSelect={onDeleteClick}
            />
          )}
          {isLive && isHost && !isFinished && (
            <MenuItem
              startIcon={<StopIcon />}
              title="Finish session"
              onClick={finishSession}
              onSelect={finishSession}
            />
          )}
          {isHost && isLive && (
            <MenuItem
              startIcon={<DeleteIcon />}
              title="Delete"
              onClick={deleteSession}
              onSelect={deleteSession}
            />
          )}
        </Menu>
      )}
      <PlanDialog
        source={planSource}
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
      <UpgradeDialog
        open={upgradeDialog}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
    </CardGridItem>
  );
};

export default BoardCard;
