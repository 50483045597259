import { findLast } from 'lodash';
import ImageDrawingAction from '../../drawingActions/image.drawing.action';
import ActivePageAction from '../../drawingActions/pages/active.page.action';
import FormulaDrawingAction from '../../drawingActions/formula.drawing.action';
import fireBaseService from '../../services/firebaseService';
import * as userReducer from '../../reducers/userReducer';
import * as sessionReducer from '../../reducers/session/sessionReducer';
import { setActivePage } from './activePageActions';
import * as imagesActions from '../imagesActions';
import MathJax from '../../utils/mathJax.util';
import { toColor } from '../../utils/color.utils';
import imageUtils from '../../utils/image.utils';
import GraphDrawingAction from '../../drawingActions/graph.drawing.action';
import {
  hiddenCalculatorConfiguration,
  screenshotOptions,
} from '../../constants/desmos';
import { getPage } from '../../reducers/board/pagesReducer';
import { isCurrentUserHostSelector } from '../../reducers/session/sessionReducer';

const width = 600;

const calculatorContainer = document.createElement('div');
calculatorContainer.style.position = 'absolute';
calculatorContainer.style.top = '-10000px';
calculatorContainer.style.left = '-10000px';
calculatorContainer.style.width = `${width}px`;
document.body.append(calculatorContainer);

const calculator = window.Desmos.GraphingCalculator(
  calculatorContainer,
  hiddenCalculatorConfiguration
);

const ADD_ACTIONS = Symbol('ADD_ACTIONS');
const UPDATE_ACTION = Symbol('UPDATE_ACTION');

const addAllActions = (actions) => ({
  type: ADD_ACTIONS,
  payload: {
    actions,
  },
});

const updateAction = (action) => ({
  type: UPDATE_ACTION,
  payload: {
    action,
  },
});

export function addActions(actions) {
  return async (dispatch, getState) => {
    const state = getState();
    const lastActivePageAction = findLast(
      actions,
      (action) => action instanceof ActivePageAction
    );
    const isHost = isCurrentUserHostSelector(state);

    const participantsPageNavigation = state.session.participantsPageNavigation;

    if ((isHost || !participantsPageNavigation) && lastActivePageAction) {
      const pageId = lastActivePageAction.getPageNumber();

      if (!getPage(state, pageId)) return;

      dispatch(setActivePage(pageId));
    }

    const oldActionsMap = state.board.actions.byId;

    dispatch(addAllActions(actions));

    const imageActions = actions.filter(
      (action) =>
        action instanceof ImageDrawingAction && !oldActionsMap[action.id]
    );

    for (const action of imageActions) {
      const imageUrl = action.imageUrl
        ? action.imageUrl
        : await fireBaseService.getImageUrl(action.getImageId());

      const image = await new Promise((resolve) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = imageUrl;
      });

      dispatch(imagesActions.saveImage(action.id, image));
    }

    // save images for formula actions
    const formulaActions = actions.filter(
      (action) =>
        action instanceof FormulaDrawingAction && !oldActionsMap[action.id]
    );

    for (const action of formulaActions) {
      try {
        const svg = MathJax.texToSvg(action.tex, toColor(action.paint.color));
        const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
        const imageUrl = URL.createObjectURL(blob);

        const image = await imageUtils.toImage(imageUrl);
        dispatch(imagesActions.saveImage(action.id, image));
      } catch (error) {
        dispatch(imagesActions.saveImage(action.id, new Image()));
      }
    }

    //save images for graph actions
    const graphActions = actions.filter(
      (action) =>
        action instanceof GraphDrawingAction && !oldActionsMap[action.id]
    );

    for (const action of graphActions) {
      try {
        const viewport = action.graphState.graph.viewport;
        const ratio =
          (viewport.ymax - viewport.ymin) / (viewport.xmax - viewport.xmin);
        calculatorContainer.style.height = `${width * ratio}px`;
        calculator.resize();
        calculator.setState(action.graphState);

        const imageUrl = await new Promise((resolve) => {
          calculator.asyncScreenshot(screenshotOptions, (imageUrl) =>
            resolve(imageUrl)
          );
        });

        const image = await imageUtils.toImage(imageUrl);
        dispatch(imagesActions.saveImage(action.id, image));
      } catch (error) {
        console.log(error);
        dispatch(imagesActions.saveImage(action.id, new Image()));
      }
    }
  };
}

export function addActionAndSend(action) {
  return (dispatch, getState) => {
    const state = getState();
    const user = userReducer.userSelector(state);
    action.setCreatorId(user.id);
    action.setPageNumber(state.board.activePage);
    dispatch(addAllActions([action]));

    const connected = sessionReducer.connectedSelector(state);
    if (!connected) return;

    fireBaseService.sendAction(action);
  };
}

export { addAllActions, updateAction, ADD_ACTIONS, UPDATE_ACTION };
