import React from 'react';
import { styled } from '@mui/material/styles';
import MuiTableContainer, {
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material/TableContainer';

const StyledTableContainer = styled(MuiTableContainer)<MuiTableContainerProps>(
  ({ theme }) => ({
    border: `1px solid ${theme.background.bg5}`,
    borderRadius: theme.spacing(1.5),
  })
);

export const TableContainer: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => <StyledTableContainer {...props} />;
