import React, { FC } from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { cardContentClasses } from '@mui/material';
import PlayButton from '../../atoms/PlayButton';
import Status from '../../atoms/Status';
import { PlayButtonVariant } from '../../atoms/PlayButton/PlayButton';
import ImageWrapper from '../../../components/ImageWrapper';
import { CardThumbnailProps, CardType } from './cardThumbnailTypes';

const CardThumbnail: FC<React.PropsWithChildren<CardThumbnailProps>> = ({
  type,
  status,
  src,
  altText,
  fallbackSrc,
}) => (
  <Status
    invisible={!status}
    content={status}
    type={status}
    sx={{
      display: 'flex',
      minWidth: '326px',
      minHeight: '165px',
      border: '1px solid',
      borderColor: 'primary.alpha10',
      borderTopLeftRadius: '30px',
      [`+ .${cardContentClasses.root}`]: {
        borderTop: 0,
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
      }}
    >
      <CardMedia
        component={ImageWrapper}
        imageSrc={src}
        fallbackSrc={fallbackSrc}
        altText={altText}
        hasBorder
      />
      {type === CardType.RECORDING && (
        <Box
          sx={{
            position: 'absolute',
            lineHeight: 0,
          }}
        >
          <PlayButton variant={PlayButtonVariant.GREEN} />
        </Box>
      )}
    </Box>
  </Status>
);

export default CardThumbnail;
