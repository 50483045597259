import TriangleResizer from './TriangleResizer';

export default class RightTriangleResizer extends TriangleResizer {
  center() {
    return this.right;
  }

  onResize({ x, y }) {
    return {
      top: { ...this.top },
      right: { x, y },
      left: { ...this.left },
    };
  }
}
