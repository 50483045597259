import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BasePlacement } from '@popperjs/core/lib/enums';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import TextSizeControl from './TextSizeControl';
import TextColorControl from './TextColorControl';
import TrashCanOutlineIcon from '../../../../../ui/atoms/Icons/TrashCanOutlineIcon';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import ToolbarContainer from '../../../ToolbarContainer';

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Props = {
  position: Position;
  onDelete: () => void;
  onCopy: () => Promise<void>;
  onApply: () => void;
  openCropModal: () => void;
  open: boolean;
};

const popperPadding = {
  top: 110,
  right: 320,
};

const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: popperPadding,
      fallbackPlacements: ['bottom', 'right', 'left'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 0],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: true,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

const TextToolbar: FC<React.PropsWithChildren<Props>> = ({
  position,
  open,
  onDelete,
}) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [textToolbarPlacement, setTextToolbarPlacement] =
    useState<BasePlacement | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<any>(null);

  const getToolbarBoundaryRect = () => {
    if (!toolbarRef.current) return null;

    return toolbarRef.current.getBoundingClientRect();
  };

  const toolbarBoundaryRect = getToolbarBoundaryRect();

  const onTogglePicker = useCallback(() => {
    setOpenColorPicker((prevState) => !prevState);
  }, [setOpenColorPicker]);

  const virtualElement = useMemo(
    () => ({
      getBoundingClientRect: (): DOMRect =>
        new DOMRectReadOnly(
          position.x,
          position.y,
          position.width,
          position.height
        ),
    }),
    [position]
  );

  useEffect(() => {
    if (!popperRef.current) return;
    const updatePopperPlacement = async () => {
      const updatedPopper = await popperRef.current.update();
      if (textToolbarPlacement === updatedPopper.placement) return;

      setTextToolbarPlacement(updatedPopper.placement);
    };
    updatePopperPlacement();
  }, [textToolbarPlacement, toolbarBoundaryRect, setTextToolbarPlacement]);

  return (
    <>
      <Popper
        open={open}
        placement="top"
        anchorEl={virtualElement}
        modifiers={popperModifiers}
        popperRef={popperRef}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ToolbarContainer ref={toolbarRef} id="text-toolbar">
          <TextSizeControl />
          <TextColorControl
            openColorPicker={openColorPicker}
            toolbarBoundaryRect={toolbarBoundaryRect}
            onClick={onTogglePicker}
            textToolbarPlacement={textToolbarPlacement}
          />
          <Box>
            <IconButton
              onClick={onDelete}
              variant={ButtonVariantTypes.TEXT}
              size={ButtonSizeTypes.S}
              shape={ButtonShapeTypes.ROUNDED}
            >
              <TrashCanOutlineIcon />
            </IconButton>
          </Box>
        </ToolbarContainer>
      </Popper>
    </>
  );
};

export default TextToolbar;
