import React from 'react';
import { styled } from '@mui/material/styles';
import MuiContainer, { ContainerProps } from '@mui/material/Container';

const StyledContainer = styled(MuiContainer)<ContainerProps>(({ theme }) => ({
  width: '100%',
  padding: '0 16px',
  [theme.breakpoints.up('sm')]: {
    padding: '0 24px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '1232px',
  },
}));

export const Container: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <StyledContainer maxWidth="md">{children}</StyledContainer>;
