import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import MuiTablePagination, {
  TablePaginationProps as MuiTablePaginationProps,
  TablePaginationTypeMap as MuiTablePaginationTypeMap,
} from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import Stack from '@mui/material/Stack';
import IconButton from '../IconButton';
import { ButtonVariantTypes } from '../Button/buttonTypes';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';

const StyledTablePagination: OverridableComponent<
  MuiTablePaginationTypeMap<{}, 'td'>
> = styled(MuiTablePagination)<MuiTablePaginationProps>({
  border: 'none',
}) as OverridableComponent<MuiTablePaginationTypeMap<{}, 'td'>>;

const PageActions: React.FC<
  React.PropsWithChildren<TablePaginationActionsProps>
> = ({ count, page, onPageChange, rowsPerPage }) => {
  const disableNext = (page + 1) * rowsPerPage >= count;
  const disablePrev = page === 0;

  return (
    <Stack direction="row">
      <IconButton
        onClick={(e) => onPageChange(e, page - 1)}
        disabled={disablePrev}
        aria-label="Previous Page"
        variant={ButtonVariantTypes.TEXT}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page + 1)}
        disabled={disableNext}
        aria-label="Next Page"
        variant={ButtonVariantTypes.TEXT}
      >
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};

const ROWS_PER_PAGE_DEFAULT = [5, 10, 15];
const ROWS_PER_PAGE_DEFAULT_SMALL = [] as number[];

type CommonProps = {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
};

type TablePaginationProps =
  | {
      small?: false;
      columnsCount: number;
    }
  | {
      small: true;
    };

export const TablePagination: React.FC<
  React.PropsWithChildren<TablePaginationProps & CommonProps>
> = ({
  onPageChange,
  onRowsPerPageChange,
  count,
  rowsPerPage,
  page,
  ...restProps
}) => {
  const handlePageChange = useCallback(
    (_: any, pageNumber: any) => onPageChange(pageNumber),
    [onPageChange]
  );
  const handleRowsPerPageChange = useCallback(
    (event: any) => onRowsPerPageChange(Number(event.target.value)),
    [onPageChange]
  );

  if (restProps.small) {
    return (
      <StyledTablePagination
        component="div"
        rowsPerPageOptions={ROWS_PER_PAGE_DEFAULT_SMALL}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        ActionsComponent={PageActions}
      />
    );
  } else {
    return (
      <StyledTablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_DEFAULT}
        colSpan={restProps.columnsCount}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        ActionsComponent={PageActions}
      />
    );
  }
};
