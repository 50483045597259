import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { take } from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { format, differenceInDays } from 'date-fns';
import Typography from '../../../../../../ui/atoms/Typography';
import CheckIcon from '../../../../../../ui/atoms/Icons/CheckIcon';
import { userSelector } from '../../../../../../common/reducers/userReducer';
import UpgradeNotification from '../../../../../../ui/molecules/UpgradeNotification';
import { SectionCard } from '../../../../components/SectionCard';
import VerifyEmailNotification from '../../../../../../ui/molecules/VerifyEmailNotification';
import InformationIcon from '../../../../../../ui/atoms/Icons/InformationIcon';
import { AlertColor } from '../../../../../../ui/molecules/Notification/notificationTypes';
import Notification from '../../../../../../ui/molecules/Notification';
import {
  IOSSupportInformation,
  AndroidSupportInformation,
} from '../MobileSupportInformation';
import Button from '../../../../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import { CancelSubscriptionModal } from '../CancelSubscriptionModal';
import useDialog from '../../../../../../common/hooks/useDialog';
import { PlanTitleWithPrice } from '../PlanTitleWithPrice';

type PlanFeature = {
  name: string;
};

type CurrentPlanProps = {
  name: string;
  price: number;
  features: PlanFeature[];
  billingCycle: number;
  canActivateTrial?: boolean;
  isTrial?: boolean;
  expirationDate?: number | null;
  expiresIn?: number | null;
  isVerifiedUser?: boolean;
  isFree?: boolean;
  subscriptionSource: 'google' | 'apple' | '2checkout' | 'stripe';
  onCancelSubscriptionClick?: () => void;
  canCancelSubscription?: boolean;
};

const FEATURES_TO_SHOW_COUNT = 3;

export const CurrentPlan: FC<React.PropsWithChildren<CurrentPlanProps>> =
  React.memo(
    ({
      name,
      price,
      features,
      billingCycle,
      canActivateTrial,
      isTrial,
      expirationDate,
      expiresIn,
      isVerifiedUser,
      isFree,
      subscriptionSource,
      onCancelSubscriptionClick,
      canCancelSubscription,
    }) => {
      let planTitle = (
        <Typography
          variant="h6"
          sx={(theme) => ({
            color: theme.text.t8,
          })}
        >
          {name}
        </Typography>
      );

      let featuresSection = (
        <Box
          display="flex"
          flexDirection="column"
          gap={0.875}
          mb={5}
          data-testid="current-plan-feature-list"
        >
          {features.map((feature, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <CheckIcon
                color="primary"
                sx={{
                  width: '16px',
                  height: '16px',
                }}
              />
              <Typography variant="p4">{feature.name}</Typography>
            </Box>
          ))}
        </Box>
      );

      let footer = null;
      if (!isVerifiedUser) {
        footer = <VerifyEmailNotification />;
      } else {
        if (isFree && canActivateTrial) {
          footer = (
            <UpgradeNotification
              title="Get 14-day Free trial"
              message="to use Premium features"
            />
          );
        } else if (!isFree && isTrial) {
          if (subscriptionSource === 'apple') {
            footer = <IOSSupportInformation />;
          } else if (subscriptionSource === 'google') {
            footer = <AndroidSupportInformation />;
          } else {
            const title =
              expiresIn! > 0
                ? `Your trial ends in ${expiresIn} day${
                    expiresIn! > 1 ? 's' : ''
                  }`
                : 'Your trial ends today';

            footer = (
              <Notification
                Icon={InformationIcon}
                title={title}
                message={
                  'In order to continue using Premium features add card details'
                }
                color={AlertColor.ERROR}
              />
            );
          }
        } else if (!isFree && !isTrial) {
          if (subscriptionSource === 'apple') {
            footer = <IOSSupportInformation />;
          } else if (subscriptionSource === 'google') {
            footer = <AndroidSupportInformation />;
          } else if (canCancelSubscription) {
            footer = (
              <Box>
                <Button
                  variant={ButtonVariantTypes.OUTLINED}
                  size={ButtonSizeTypes.S}
                  onClick={onCancelSubscriptionClick}
                >
                  Cancel subscription
                </Button>
              </Box>
            );
          }
        }
      }

      if (!isFree && !isTrial) {
        planTitle = (
          <PlanTitleWithPrice
            name={name}
            price={price}
            billingCycle={billingCycle}
          />
        );
      } else if (!isFree && isTrial) {
        planTitle = (
          <Stack>
            <Typography
              variant="h6"
              sx={(theme) => ({
                color: theme.text.t8,
              })}
            >
              You are on a 14-day free trial
            </Typography>
            <Typography
              variant="s4"
              sx={(theme) => ({
                color: theme.text.t7,
              })}
            >
              until {format(expirationDate!, 'MMMM d, yyyy')}
            </Typography>
          </Stack>
        );
      }

      return (
        <SectionCard title="Current plan">
          <Stack>
            <Box mb={5}>{planTitle}</Box>
            {featuresSection}
            <Box data-testid="current-plan-footer">{footer}</Box>
          </Stack>
        </SectionCard>
      );
    }
  );

export const CurrentPlanContainer = () => {
  const user = useSelector(userSelector);
  const cancelSubscriptionModal = useDialog();
  const features = user.subscription.plan.features as PlanFeature[];
  const expiresIn = Math.max(
    differenceInDays(user.subscription.expirationDate, Date.now()),
    0
  );
  const planPrice = useMemo(() => {
    return (
      user.subscription.upcomingPlanChange?.plan.price ||
      user.subscription.plan.price
    );
  }, [user]);

  return (
    <>
      <CurrentPlan
        name={user.subscription.name}
        price={planPrice}
        isVerifiedUser={user.verified}
        isFree={user.subscription.plan.isFree}
        canActivateTrial={user.canActivateTrial}
        features={features}
        billingCycle={user.subscription.plan.billingCycle}
        isTrial={user.subscription.isTrial}
        expirationDate={user.subscription.expirationDate}
        expiresIn={expiresIn}
        subscriptionSource={user.subscriptionSource}
        onCancelSubscriptionClick={cancelSubscriptionModal.onOpen}
        canCancelSubscription={!Boolean(user.stopDate)}
      />
      <CancelSubscriptionModal
        open={cancelSubscriptionModal.open}
        onClose={cancelSubscriptionModal.onClose}
        onSuccess={cancelSubscriptionModal.onClose}
      />
    </>
  );
};
