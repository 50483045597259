import { TypographyStyle } from './types/Typography';

const h1: TypographyStyle = {
  fontSize: '60px',
  lineHeight: '78px',
  fontWeight: 700,
};

const h2: TypographyStyle = {
  fontSize: '50px',
  lineHeight: '75px',
  fontWeight: 700,
};

const h3: TypographyStyle = {
  fontSize: '50px',
  lineHeight: '75px',
  fontWeight: 600,
};

const h4: TypographyStyle = {
  fontSize: '40px',
  lineHeight: '60px',
  fontWeight: 700,
};

const h5: TypographyStyle = {
  fontSize: '40px',
  lineHeight: '60px',
  fontWeight: 600,
};

const h6: TypographyStyle = {
  fontSize: '30px',
  lineHeight: '45px',
  fontWeight: 700,
};

const s1: TypographyStyle = {
  fontSize: '24px',
  lineHeight: '30px',
  fontWeight: 700,
};

const s2: TypographyStyle = {
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 700,
};

const s3: TypographyStyle = {
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 600,
};

const s4: TypographyStyle = {
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 600,
};

const p1: TypographyStyle = {
  fontSize: '24px',
  lineHeight: '30px',
  fontWeight: 500,
};

const p2: TypographyStyle = {
  fontSize: '20px',
  lineHeight: '34px',
  fontWeight: 500,
};

const p3: TypographyStyle = {
  fontSize: '18px',
  lineHeight: '30.6px',
  fontWeight: 500,
};

const p4: TypographyStyle = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
};

const p5: TypographyStyle = {
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: 500,
};

const p6: TypographyStyle = {
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 500,
};

const b1: TypographyStyle = {
  fontSize: '20px',
  lineHeight: '25px',
  fontWeight: 700,
};

const b2: TypographyStyle = {
  fontSize: '18px',
  lineHeight: '22.5px',
  fontWeight: 700,
};

const b3: TypographyStyle = {
  fontSize: '18px',
  lineHeight: '22.5px',
  fontWeight: 600,
};

const b4: TypographyStyle = {
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 600,
};

const b5: TypographyStyle = {
  fontSize: '14px',
  lineHeight: '16.8px',
  fontWeight: 600,
};

const b6: TypographyStyle = {
  fontSize: '12px',
  lineHeight: '14.4px',
  fontWeight: 600,
};

const l1: TypographyStyle = {
  textDecoration: 'underline',
  fontSize: '20px',
  lineHeight: '25px',
  fontWeight: 700,
};

const l2: TypographyStyle = {
  textDecoration: 'underline',
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: 500,
};

const l3: TypographyStyle = {
  textDecoration: 'underline',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 500,
};

export const headingStyle = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
};

export const paragraphStyle = {
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
};

export const subheadingStyle = {
  s1,
  s2,
  s3,
  s4,
};

export const buttonTextStyle = {
  b1,
  b2,
  b3,
  b4,
  b5,
  b6,
};

export const linkTextStyle = {
  l1,
  l2,
  l3,
};
