import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const onChangeSize = (event: UIEvent) => {
      const target = event.target as Window;
      if (!target) return;

      const windowWidth = target.innerWidth;
      const windowHeight = target.innerHeight;

      setWindowSize({
        width: windowWidth,
        height: windowHeight,
      });
    };

    window.addEventListener('resize', onChangeSize);

    return () => {
      window.removeEventListener('resize', onChangeSize);
    };
  }, [setWindowSize]);

  return windowSize;
};

export default useWindowSize;
