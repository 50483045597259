import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import EditIcon from '@mui/icons-material/Edit';
import { cardClasses, cardContentClasses } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SvgIconProps } from '@mui/material/SvgIcon';
import CardInfo from '../CardInfo';
import FolderOutlineIcon from '../../atoms/Icons/FolderOutlineIcon';
import Card from '../Card';
import DotsVerticalSvgIcon from '../../atoms/Icons/DotsVerticalIcon';
import Menu from '../Menu';
import MenuItem from '../../atoms/MenuItem';
import { boardsCountByGroupIdSelector } from '../../../common/reducers/sessionsReducer';
import DeleteGroupDialog from '../DeleteGroupDialog';
import useDialog from '../../../common/hooks/useDialog';
import analyticsService from '../../../common/services/analytics.service';
import useCardAvatarImage from '../../../common/hooks/useCardAvatarImage';
import AccountPlusOutlineIcon from '../../atoms/Icons/AccountPlusOutlineIcon';
import GroupMembersDialog from '../GroupMembersDialog';
import useGroupContactWithoutInvited from '../../../common/hooks/useGroupContactWithoutInvited';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  id: string;
  userId: string;
  ownerId: string;
  groupName: string;
  groupOwnerName: string;
  date: string;
  onRename: (cardId: string) => void;
  // ToDo: change selectedGroup type
  selectedGroup: any;
  setSelectedGroup: (selectedGroup: null) => void;
  selectGroupById: (cardId: string) => void;
};

const GroupCard: FC<React.PropsWithChildren<Props>> = ({
  id,
  selectedGroup,
  userId,
  groupName,
  groupOwnerName,
  date,
  ownerId,
  onRename,
  setSelectedGroup,
  selectGroupById,
}) => {
  const isHost = useMemo(() => userId === ownerId, [userId, ownerId]);
  const [anchorEl, setAnchorEl] = useState(null);
  const contextMenuOpen = Boolean(anchorEl);
  const boardsCountSelector = useMemo(
    () => boardsCountByGroupIdSelector(id),
    [id]
  );
  const boardsCount = useSelector(boardsCountSelector);

  const dispatch = useDispatch();
  const avatarImg = useCardAvatarImage(ownerId);

  const {
    open: deleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDialog();

  const {
    open: groupMemberDialogOpen,
    onOpen: onGroupMemberDialogOpen,
    onClose: onGroupMemberDialogClose,
  } = useDialog();

  const navigateTo = useCallback(
    (path: any) => {
      dispatch(push(path));
    },
    [dispatch]
  );

  const handleContextMenuOpen = useCallback(
    (event: any) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleContextMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onClick = useCallback(() => {
    const groupId = id || 'default';

    navigateTo(`/group/${groupId}/sessions`);
  }, [id, navigateTo]);

  const onCloseDeleteModal = useCallback(() => {
    setSelectedGroup(null);
    onDeleteDialogClose();
  }, [setSelectedGroup, onDeleteDialogClose]);

  const onDelete = useCallback(
    (cardId: any) => {
      analyticsService.event('Delete Board Button Click', {
        source: 'Boards',
      });
      selectGroupById(cardId);
      onDeleteDialogOpen();
    },
    [selectGroupById, onDeleteDialogOpen]
  );

  const cardActionsData = useMemo(
    (): {
      Icon: FC<React.PropsWithChildren<SvgIconProps>>;
      onClick: (...args: any[]) => void;
    }[] => [
      {
        Icon: AccountPlusOutlineIcon,
        onClick: (event) => {
          event.preventDefault();
          event.stopPropagation();
          onGroupMemberDialogOpen();
          handleContextMenuClose();
        },
      },
      {
        Icon: DotsVerticalSvgIcon,
        onClick: (event) => {
          event.preventDefault();
          event.stopPropagation();
          handleContextMenuOpen(event);
        },
      },
    ],
    [handleContextMenuClose, handleContextMenuOpen]
  );

  const groupContactWithoutInvited = useGroupContactWithoutInvited(id);

  return (
    <>
      <CardGridItem>
        <Card
          onClick={onClick}
          sx={{
            [`&.${cardClasses.root}`]: {
              borderRadius: '20px 10px',
            },
            [`& .${cardContentClasses.root}`]: {
              borderRadius: '20px 10px',
            },
          }}
        >
          <CardInfo
            title={groupName}
            subtitle={isHost ? 'My group' : groupOwnerName}
            CardIcon={FolderOutlineIcon}
            date={date}
            subtitleExtra={`${boardsCount} boards`}
            avatarImg={avatarImg}
            hasAvatar={!isHost}
            cardActionsData={isHost ? cardActionsData : []}
          />
        </Card>
        {contextMenuOpen && (
          <Menu
            anchorEl={anchorEl}
            open={contextMenuOpen}
            onClose={handleContextMenuClose}
            listItemWidth={164}
          >
            <MenuItem
              title="Rename"
              onSelect={() => {
                onRename(id);
                handleContextMenuClose();
              }}
              startIcon={<EditIcon />}
            />
            <MenuItem
              startIcon={<DeleteIcon />}
              title="Delete"
              onSelect={() => {
                onDelete(id);
                handleContextMenuClose();
              }}
            />
          </Menu>
        )}
      </CardGridItem>
      {selectedGroup && (
        <DeleteGroupDialog
          open={deleteDialogOpen}
          onClose={onCloseDeleteModal}
          onSubmit={onCloseDeleteModal}
          groupId={selectedGroup.id}
          keepCard={!!boardsCount}
        />
      )}
      <GroupMembersDialog
        open={groupMemberDialogOpen}
        onClose={onGroupMemberDialogClose}
        count={groupContactWithoutInvited.length || 0}
        groupName={groupName}
        groupId={id}
        isHost={isHost}
      />
    </>
  );
};

export default GroupCard;
