import { FC } from 'react';
import CrownOutlineIcon from '../../Icons/CrownOutlineIcon';
import NotificationButton from '../NotificationButton';
import {
  NotificationButtonProps,
  NotificationButtonSize,
} from '../notificationButtonTypes';
import { AlertColor } from '../../../molecules/Notification/notificationTypes';

type Props = {
  hasIcon: boolean;
  size: NotificationButtonSize;
};

const PremiumButton: FC<
  React.PropsWithChildren<Props & NotificationButtonProps>
> = ({ hasIcon, size, children = 'Try free for 14 days', ...props }) => (
  <NotificationButton
    {...props}
    icon={
      <CrownOutlineIcon
        sx={(theme) => ({
          fontSize: size === NotificationButtonSize.XS ? '1rem' : '0.875rem',
          color: theme.text.t1,
        })}
      />
    }
    color={AlertColor.PREMIUM}
    hasIcon={hasIcon}
    size={size}
  >
    {children}
  </NotificationButton>
);

export default PremiumButton;
