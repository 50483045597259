import { FC, useCallback, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '../../../atoms/Input';
import InputVariant from '../../../atoms/Input/InputVariant';
import InputSize from '../../../atoms/Input/InputSize';
import EmailOutlineIcon from '../../../atoms/Icons/EmailOutlineIcon';
import InputStatus from '../../../atoms/Input/InputStatus';
import Button from '../../../atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../atoms/Button/buttonTypes';
import authService from '../../../../common/services/auth.service';
import isValidEmail from '../../../../common/utils/email.validation.util';
import ErrorCodes from '../../../../common/errors/errorCodes';

const ForgotPasswordForm: FC<React.PropsWithChildren<unknown>> = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { isSubmitting } = useFormState({ control });

  const onSubmit = useCallback(
    handleSubmit(async ({ email }) => {
      try {
        const { message: serverMessage } = await authService.forgotPassword(
          email
        );
        setMessage(serverMessage);
      } catch (err) {
        if (err.code === ErrorCodes.E_USER_NOT_FOUND) {
          setError('No account found with that email address');

          return;
        }

        setError(err.message);
      }
    }),
    [setError, setMessage]
  );

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap="14px">
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'This field is required',
            validate: (value) => {
              if (isValidEmail(value)) return undefined;

              return 'Invalid email format';
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            formState: { errors },
          }) => (
            <Input
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              variant={InputVariant.CONTAINED}
              size={InputSize.M}
              placeholder="Email"
              label="Email"
              icon={<EmailOutlineIcon />}
              status={errors.email ? InputStatus.ERROR : undefined}
              helperText={errors.email ? errors.email.message : undefined}
            />
          )}
        />
        {message && (
          <Typography variant="p5" color="primary">
            {message}
          </Typography>
        )}
        {error && (
          <Typography variant="p5" color="error">
            {error}
          </Typography>
        )}
        <Box mt={1.25}>
          <Button
            type="submit"
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.M}
            fullWidth
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
