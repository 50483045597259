import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Dialog from '../../../ui/atoms/Dialog';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import DialogContent from '../../../ui/atoms/DialogContent';
import CardForm from '../../../ui/molecules/CardModal/CardForm';
import PromoCode from '../../../components/PromoCode';
import PromoCodeDto from '../../../common/services/dto/auth/PromoCodeDto';
import ButtonContent from './ButtonContent';
import environment from '../../../config/environment';
import calculateDiscountedPrice from '../../../common/utils/discount.plan.price.util';

const promise = loadStripe(environment.stripePublishKey);

type Props = {
  open: boolean;
  planId: string;
  price: number;
  onClose: () => void;
  onSuccess: (promoCodeId?: string) => Promise<void>;
};

const CheckoutModal = ({ open, planId, price, onClose, onSuccess }: Props) => {
  const [promoData, setPromoData] = useState<PromoCodeDto | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const onValidateSuccess = useCallback(
    (data: PromoCodeDto) => {
      setPromoData(data);

      enqueueSnackbar('Promo applied successfully', {
        variant: 'success',
      });
    },
    [setPromoData, enqueueSnackbar]
  );

  const discountedPromoCode = useMemo(
    () => calculateDiscountedPrice(price, promoData),
    [price, promoData]
  );

  const onExit = useCallback(() => {
    setPromoData(null);
  }, [setPromoData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onExit,
      }}
    >
      <DialogTitle hasCloseButton={false}>Checkout</DialogTitle>
      <DialogContent
        sx={{
          mb: 0,
        }}
      >
        <Elements stripe={promise}>
          <CardForm
            buttonContent={
              <ButtonContent
                price={price}
                discountedPrice={discountedPromoCode}
              />
            }
            promoCodeId={promoData?.id}
            onSuccess={onSuccess}
          >
            <Box my={4} width={1}>
              <PromoCode
                planId={planId}
                shouldFetchPromoCode={false}
                onValidateSuccess={onValidateSuccess}
              />
            </Box>
          </CardForm>
        </Elements>
        <Typography variant="subtitle2" className="text-right pt-2">
          Powered by{' '}
          <Link
            href="https://stripe.com/"
            target="_blank"
            className="text-primary"
          >
            Stripe
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutModal;
