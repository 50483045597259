import React from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { buttonTextStyle } from '../Typography/typographyStyles';
import { TableContainer } from './TableContainer';
import { TablePagination } from './TablePagination';

type MobileTableProps<T extends object> = {
  table: Table<T>;
  initialDataLoaded: boolean;
  noResults: React.ReactNode;
  onRowClick?: (row: Row<T>) => void;
};

export const MobileTable = <T extends object>({
  table,
  initialDataLoaded,
  noResults,
  onRowClick,
}: MobileTableProps<T>) => {
  return (
    <TableContainer>
      {table.getRowModel().rows.map((row) => (
        <Stack
          key={row.id}
          direction="row"
          gap={2}
          onClick={onRowClick ? () => onRowClick(row) : undefined}
          sx={{
            borderBottom: '1px solid #E1E5EC',
            '&:last-child': {
              border: 'none',
            },
            padding: '12px',
            cursor: onRowClick ? 'pointer' : 'initial',
          }}
        >
          <Box minWidth="90px">
            {table.getLeafHeaders().map((header) => (
              <Stack
                key={header.id}
                justifyContent="center"
                alignItems="flex-start"
                sx={(theme) => ({
                  height: '40px',
                  color: theme.text.t8,
                  ...buttonTextStyle.b4,
                })}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </Stack>
            ))}
          </Box>
          <Box flex={1} minWidth={0}>
            {row.getVisibleCells().map((cell) => (
              <Stack
                key={cell.id}
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                minWidth={0}
                sx={{
                  height: '40px',
                  '& > *': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Stack>
            ))}
          </Box>
        </Stack>
      ))}
      {initialDataLoaded && table.getPageCount() === 0 && (
        <Box>{noResults}</Box>
      )}
      {!initialDataLoaded && (
        <Box>
          <Stack
            alignItems="center"
            justifyContent="center"
            p={4}
            minHeight="300px"
          >
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        </Box>
      )}
      <TablePagination
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={table.getState().pagination.pageSize}
        page={table.getState().pagination.pageIndex}
        onPageChange={(page) => table.setPageIndex(page)}
        onRowsPerPageChange={(rowsPerPage) => table.setPageSize(rowsPerPage)}
        small
      />
    </TableContainer>
  );
};
