import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '../IconButton';
import { ButtonSizeTypes, ButtonVariantTypes } from '../Button/buttonTypes';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';

type Props = {
  stepsLength: number;
  activeStep: number;
  onNextClick: () => void;
  onBackClick: () => void;
};

const CustomStepper: FC<React.PropsWithChildren<Props>> = ({
  stepsLength,
  activeStep,
  onNextClick,
  onBackClick,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    padding="10px 12.2px 10px 12.2px"
  >
    <Typography variant="p5" color="primary">
      {`Step ${activeStep + 1}/${stepsLength}`}
    </Typography>
    <Box display="flex">
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={onBackClick}
        disabled={activeStep === 0}
      >
        <ChevronLeftIcon color={activeStep === 0 ? 'disabled' : 'primary'} />
      </IconButton>
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={onNextClick}
        disabled={activeStep === stepsLength - 1}
      >
        <ChevronRightIcon
          color={activeStep === stepsLength - 1 ? 'disabled' : 'primary'}
        />
      </IconButton>
    </Box>
  </Box>
);

export default CustomStepper;
