import { FC, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Dialog from '../../../ui/atoms/Dialog';
import trialBackground from '../../../assets/images/trial-background.svg';
import DialogContent from '../../../ui/atoms/DialogContent';
import Typography from '../../../ui/atoms/Typography';
import { TypographyType } from '../../../ui/atoms/Typography/types/Typography';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import Button from '../../../ui/atoms/Button';
import ContentCopyIcon from '../../../ui/atoms/Icons/ContentCopyIcon';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import analyticsService from '../../../common/services/analytics.service';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  promoCode?: string;
};

const PromoCodeDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSuccess,
  promoCode = '5HTZECUC',
}) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const onDialogClose = useCallback(
    (_: any, reason: any) => {
      if (reason === 'backdropClick') return;

      onClose();
    },
    [onClose]
  );
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(promoCode);
    enqueueSnackbar('The code is successfully copied.', {
      variant: 'success',
    });
    analyticsService.event('Promo Code Copied', {
      promoCode,
    });
  }, [promoCode, enqueueSnackbar]);

  const onPromoCodeSuccess = useCallback(() => {
    analyticsService.event('Promo Get Offer Clicked', {
      promoCode,
    });
    onSuccess();
  }, [promoCode, onSuccess]);

  const onEnter = useCallback(() => {
    analyticsService.event('Promo Dialog Opened', {
      promoCode,
    });
  }, [promoCode]);

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionProps={{
        onEntered: onEnter,
      }}
    >
      <Box width="100%" position="absolute" top={0}>
        <img width="100%" src={trialBackground} />
      </Box>
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={4.5}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="24px"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={0.75}
            >
              <Box display="flex" gap={0.5}>
                <Typography
                  variant={upSm ? TypographyType.h6 : TypographyType.s1}
                >
                  Get
                </Typography>
                <Typography
                  variant={upSm ? TypographyType.h6 : TypographyType.s1}
                  color="primary.dark5"
                >
                  50%
                </Typography>
                <Typography
                  variant={upSm ? TypographyType.h6 : TypographyType.s1}
                >
                  Off
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography variant={TypographyType.p5}>
                  Until October 20
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                columnGap="4px"
              >
                <Typography
                  textAlign="center"
                  variant={TypographyType.p5}
                  sx={(theme) => ({
                    color: theme.text.t7,
                  })}
                >
                  Apply the promo-code and upgrade with 50%
                </Typography>
              </Box>
              <Typography
                textAlign="center"
                variant={TypographyType.p5}
                sx={(theme) => ({
                  color: theme.text.t7,
                })}
              >
                discount to any Premium plan!
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Typography variant={TypographyType.p4} color="primary.dark5">
              {promoCode}
            </Typography>
            <Button
              variant={ButtonVariantTypes.SECONDARY}
              startIcon={<ContentCopyIcon sx={{ fontSize: '16px' }} />}
              size={ButtonSizeTypes.XS}
              onClick={onCopy}
              sx={{
                minWidth: '79px',
                padding: '7px 14px',
              }}
            >
              Copy
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
        }}
      >
        <DialogActionSecondaryButton fullWidth onClick={onClose}>
          Not Now
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onPromoCodeSuccess} fullWidth>
          Get offer
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromoCodeDialog;
