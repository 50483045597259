import {
  SET_POSITION,
  SET_TEXT,
  SET_SIZES,
  CLEAR_DRAWING_TOOL,
} from '../../actions/board/textDrawingToolActions';

const initialState = {
  text: '',
  position: null,
  width: 0,
  height: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITION:
      return {
        ...state,
        position: action.position,
      };
    case SET_TEXT:
      return {
        ...state,
        text: action.text,
      };
    case SET_SIZES:
      return {
        ...state,
        width: action.width,
        height: action.height,
      };
    case CLEAR_DRAWING_TOOL:
      return initialState;
    default:
      return state;
  }
};

export const textSelector = (state) => state.board.textDrawingTool.text;
