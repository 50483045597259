import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BasePlacement } from '@popperjs/core/lib/enums';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import FormatColorOutlineIcon from '../../../../../ui/atoms/Icons/FormatColorOutlineIcon';
import { getSelectedDrawingTool } from '../../../../../common/reducers/board/drawingToolReducer';
import {
  rgbaToHexWithoutAlpha,
  toColor,
} from '../../../../../common/utils/color.utils';
import TextColorPopper from './TextColorPopper';
import { getCurrentDrawing } from '../../../../../common/reducers/board/currentDrawingReducer';
import ToolbarDivider from '../../../ToolbarDivider';

type TextColorControlProps = {
  openColorPicker: boolean;
  toolbarBoundaryRect: DOMRect | null;
  onClick: () => void;
  textToolbarPlacement: BasePlacement | null;
};
const TextColorControl: FC<React.PropsWithChildren<TextColorControlProps>> = ({
  openColorPicker,
  toolbarBoundaryRect,
  onClick,
  textToolbarPlacement,
}) => {
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);

  const color = useMemo(
    () => toColor(Number(currentDrawing?.paint.color) || selectedTool.color),
    [currentDrawing, selectedTool.color]
  );
  const hexColor = useMemo(() => rgbaToHexWithoutAlpha(color), [color]);
  const anchorEl = useMemo(
    () => ({
      getBoundingClientRect: () => toolbarBoundaryRect as DOMRect,
    }),
    [toolbarBoundaryRect]
  );

  return (
    <>
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={onClick}
        shape={ButtonShapeTypes.ROUNDED}
      >
        <FormatColorOutlineIcon
          sx={(theme) => ({
            color,
            width: 20,
            height: 20,
            ...(hexColor === '#FFFFFF'
              ? {
                  [`& #Shape`]: {
                    stroke: theme.background.bg6,
                  },
                }
              : {}),
          })}
        />
      </IconButton>
      {toolbarBoundaryRect && anchorEl && (
        <TextColorPopper
          open={openColorPicker}
          anchorEl={anchorEl}
          textToolbarPlacement={textToolbarPlacement}
          onChange={onClick}
        />
      )}
      <ToolbarDivider />
    </>
  );
};

export default TextColorControl;
