import { cloneDeep } from 'lodash';
import DrawingAction from './drawing.action';
import numberUtil from '../utils/number.util';
import guid from '../utils/guid.util';
import vectorUtils from '../utils/vector.utils';

export default class ImageDrawingAction extends DrawingAction {
  startPoint = null;
  imageUrl = null;
  imageId = null;
  image = null;
  rotation = null;
  width = null;
  height = null;

  constructor(deserialized) {
    super(deserialized);
    this.name = 'imageMeta';

    if (deserialized) {
      this.startPoint = { x: deserialized.x, y: deserialized.y };
      this.imageId = deserialized.id;
      this.imageUrl = deserialized.imageUrl;
      this.width = deserialized.width || null;
      this.height = deserialized.height || null;
      this.rotation = deserialized.rotation || 0;
    }
  }

  getWidth() {
    if (this.width !== null && this.width !== undefined) return this.width;

    return this.image.width;
  }

  getHeight() {
    if (this.height !== null && this.height !== undefined) return this.height;

    return this.image.height;
  }

  getImageId() {
    return this.imageId;
  }

  getImageUrl() {
    return this.imageUrl;
  }

  getStartPoint() {
    return this.startPoint;
  }

  setImage(image) {
    if (!image) return;

    this.image = image;
  }

  serialize() {
    const { x, y } = this.getStartPoint();
    return {
      ...super.serialize(),
      x: numberUtil.toOneDecimal(x),
      y: numberUtil.toOneDecimal(y),
      imageUrl: this.getImageUrl(),
      width: numberUtil.toOneDecimal(this.width),
      height: numberUtil.toOneDecimal(this.height),
      rotation: this.rotation,
    };
  }

  createMoveAction() {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;
    newAction.startPoint = { ...this.startPoint };
    newAction.imageId = this.imageId;
    newAction.imageUrl = this.imageUrl;
    newAction.image = this.image;
    newAction.width = this.width || this.image.width;
    newAction.height = this.height || this.image.height;
    return newAction;
  }

  move({ deltaX, deltaY }) {
    this.startPoint = {
      x: this.startPoint.x + deltaX,
      y: this.startPoint.y + deltaY,
    };
  }

  intersect(point, degreeAngle) {
    const midPoint = {
      x: this.startPoint.x + this.getWidth() / 2,
      y: this.startPoint.y + this.getHeight() / 2
    };

    const rectDimension = {
      width: this.getWidth(),
      height: this.getHeight(),
    };

    const radianAngle = vectorUtils.degreeToRadian(degreeAngle);

    return vectorUtils.calculateIntersection(radianAngle, point, midPoint, this.startPoint, rectDimension);
  }
}
