const SET_IMAGE = Symbol('SET_IMAGE');
const CLEAR_IMAGE = Symbol('CLEAR_IMAGE');

const setImage = ({ image, width, height }) => ({
  type: SET_IMAGE,
  imageObject: {
    image,
    width,
    height,
  },
});

const clearImage = () => ({
  type: CLEAR_IMAGE,
});

export {
  setImage,
  clearImage,
  SET_IMAGE,
  CLEAR_IMAGE,
};
