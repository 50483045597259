import { createSelector } from 'reselect';
import {
  GET_RECORDING_LIST_REQUEST,
  GET_RECORDING_LIST_SUCCESS,
  GET_RECORDING_LIST_FAILURE,
  ADD_RECORDING_IN_LIST,
  UPDATE_RECORDING_IN_LIST,
  COPY_RECORDING_REQUEST,
  COPY_RECORDING_SUCCESS,
  COPY_RECORDING_FAILURE,
  REMOVE_RECORDING,
  CHANGE_RECORDING_GROUP,
  CHANGE_RECORDING_IN_GROUP_REQUEST,
} from '../actions/recordingsActions';

const initialState = {
  isFetching: false,
  isCopiedRecordingFetching: false,
  hash: {},
  byGroupId: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RECORDING_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case COPY_RECORDING_REQUEST:
      return {
        ...state,
        isCopiedRecordingFetching: true,
      };
    case GET_RECORDING_LIST_SUCCESS: {
      const { payload: data } = action;

      const byGroupIdHash = data.reduce((acc, recording) => {
        const groupId = recording.groupId || 'default';
        if (acc[groupId]) {
          return {
            ...acc,
            [groupId]: [...acc[groupId], recording.id]
          }
        } else {
          return {
            ...acc,
            [groupId]: [recording.id]
          }
        }
      }, {});

      return {
        ...state,
        hash: {
          ...state.hash,
          ...data
            .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
        },
        byGroupId: {
          ...state.byGroupId,
          ...byGroupIdHash,
        },
        isFetching: false,
      };
    }
    case GET_RECORDING_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case COPY_RECORDING_FAILURE:
      return {
        ...state,
        isCopiedRecordingFetching: false,
      };
    case ADD_RECORDING_IN_LIST:
      const { recording, groupId } = action.payload;

      return {
        ...state,
        hash: {
          ...state.hash,
          [recording.id]: recording,
        },
        byGroupId: {
          ...state.byGroupId,
          [groupId]: [
            ...(state.byGroupId[groupId] || []),
            recording.id,
          ],
        },
      }
    case UPDATE_RECORDING_IN_LIST:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.payload.id]: {
            ...state.hash[action.payload.id],
            ...action.payload,
          },
        },
      };
    case REMOVE_RECORDING: {
      const { recordingId } = action.payload;
      const { [recordingId]: recordingToRemove, ...newRecordings } = state.hash;
      if (!recordingToRemove) return state;
      const { groupId } = recordingToRemove;
      return {
        ...state,
        byGroupId: Object.keys(state.byGroupId).reduce((acc, key) => {
          if (key !== 'default' && key !== groupId) {
            acc[key] = state.byGroupId[key];
          } else {
            acc[key] = state.byGroupId[key].filter(id => id !== recordingId);
          }
          return acc;
        }, {}),
        hash: { ...(newRecordings || {}) },
      };
    }
    case CHANGE_RECORDING_GROUP: {
      const { recording } = action.payload;
      const { id, groupId } = recording;

      const oldGroupId = state.hash[id].groupId || 'default';
      const newGroupId = groupId || 'default';

      if (oldGroupId === newGroupId) return { ...state };

      const hash = {
        ...state.hash,
        [id]: {
          ...state.hash[id],
          ...recording,
        },
      };
      const oldGroupIdPart = oldGroupId
        ? { [oldGroupId]: (state.byGroupId[oldGroupId] || []).filter(s => s !== id) }
        : {};

      const byGroupId = {
        ...state.byGroupId,
        ...oldGroupIdPart,
        ...{
          [newGroupId]: [...(state.byGroupId[newGroupId] || []), id],
        },
      };

      return {
        ...state,
        hash,
        byGroupId,
      };
    }
    case COPY_RECORDING_SUCCESS: {
      const { payload } = action;
      const groupId = action.groupId || 'default';
      return {
        ...state,
        hash: {
          ...state.hash,
          [payload.id]: payload,
        },
        byGroupId: {
          ...state.byGroupId,
          [groupId]: [
            payload.id,
            ...state.byGroupId[groupId],
          ],
        },
        isCopiedRecordingFetching: false,
      };
    }
    case CHANGE_RECORDING_IN_GROUP_REQUEST: {
      const { id, shared } = action.payload;

      return {
        ...state,
        hash: {
          ...state.hash,
          [id]: {
            ...state.hash[id],
            shared,
          },
        },
      };
    }
    default:
      return state;
  }
};

const recordingSelector = (state, id) => state.recordings.hash[id];
const groupIdSelectorByRecordingId = (state, id) => state.recordings.hash[id].groupId;

const getRecordingsByGroupId = (recordings, groups, groupId) => (
  Object.values(recordings)
    .filter((recording) => {
      if (groupId === 'default') {
        return !recording.groupId || !groups[recording.groupId]
      }

      return recording.groupId === groupId;
    })
);

const recordingListSelector = groupId => createSelector(
  [
    state => state.recordings.hash,
    state => state.groups.hash,
  ],
  (recordings, groups) => getRecordingsByGroupId(recordings, groups, groupId),
);

const isFetchingSelector = state => state.recordings.isFetching;
const isCopiedRecordingFetchingSelector = state => state.recordings.isCopiedRecordingFetching;

const recordingsCountByGroupIdSelector = groupId => createSelector(
  [
    state => state.recordings.hash,
    state => state.groups.hash,
  ],
  (recordings, groups) => getRecordingsByGroupId(recordings, groups, groupId).length
);

export {
  recordingSelector,
  groupIdSelectorByRecordingId,
  isFetchingSelector,
  isCopiedRecordingFetchingSelector,
  recordingListSelector,
  recordingsCountByGroupIdSelector,
};
