import React, { CSSProperties, FC } from 'react';
import { CssBaseline, PaletteColorOptions } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { AvatarGroupProps } from '@mui/material/AvatarGroup';
import {
  headingStyle,
  subheadingStyle,
  paragraphStyle,
  buttonTextStyle,
  linkTextStyle,
} from '../atoms/Typography/typographyStyles';
import {
  BackgroundColors,
  StatusColors,
  TextColors,
  NeutralColors,
} from '../atoms/Colors/types/Color';
import {
  background,
  main,
  text,
  validation,
  status,
} from '../atoms/Colors/colorStyles';
import generateTooltipStyle from '../atoms/Tooltip/generateTooltipStyles';
import { AvatarSizeTypes } from '../atoms/Avatar/types';
import buttonThemeConfig from '../atoms/Button/buttonThemeConfig';
import iconButtonThemeConfig from '../atoms/IconButton/iconButtonThemeConfig';
import GlobalStyles from '@mui/material/GlobalStyles';

declare module '@mui/material/styles' {
  interface PaletteColor {
    light: string;
    dark: string;
    contrastText: string;
    main: string;
    dark5: CSSProperties['color'];
    alpha10: CSSProperties['color'];
    alpha20: CSSProperties['color'];
    alpha30: CSSProperties['color'];
    alpha50: CSSProperties['color'];
  }

  interface Palette {
    primary: PaletteColor;
    secondary: PaletteColor;
    tertiary: PaletteColor;
    success: PaletteColor;
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    premium: PaletteColor;
    /* TODO: remove this outdated color after full app redesign */
    neutral: NeutralColors;
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
    success?: PaletteColorOptions;
    error?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    info?: PaletteColorOptions;
    premium?: PaletteColorOptions;
    neutral?: NeutralColors;
  }

  interface Theme {
    background: BackgroundColors;
    text: TextColors;
    status: StatusColors;
  }

  interface ThemeOptions {
    background: BackgroundColors;
    text: TextColors;
    status: StatusColors;
  }

  interface TypographyVariants {
    p1: CSSProperties;
    p2: CSSProperties;
    p3: CSSProperties;
    p4: CSSProperties;
    p5: CSSProperties;
    p6: CSSProperties;
    s1: CSSProperties;
    s2: CSSProperties;
    s3: CSSProperties;
    s4: CSSProperties;
    b1: CSSProperties;
    b2: CSSProperties;
    b3: CSSProperties;
    b4: CSSProperties;
    b5: CSSProperties;
    b6: CSSProperties;
    l1: CSSProperties;
    l2: CSSProperties;
    l3: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    p1?: CSSProperties;
    p2?: CSSProperties;
    p3?: CSSProperties;
    p4?: CSSProperties;
    p5?: CSSProperties;
    p6?: CSSProperties;
    s1?: CSSProperties;
    s2?: CSSProperties;
    b1?: CSSProperties;
    b2?: CSSProperties;
    b3?: CSSProperties;
    b4?: CSSProperties;
    b5?: CSSProperties;
    b6?: CSSProperties;
    l1?: CSSProperties;
    l2?: CSSProperties;
    l3?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p1: true;
    p2: true;
    p3: true;
    p4: true;
    p5: true;
    p6: true;
    s1: true;
    s2: true;
    s3: true;
    s4: true;
    b1: true;
    b2: true;
    b3: true;
    b4: true;
    b5: true;
    b6: true;
    l1: true;
    l2: true;
    l3: true;
  }
}

export interface SizePropsOverride {
  xl: true;
  l: true;
  m: true;
  s: true;
  xs: true;
  xxs: true;
}

export interface ButtonVariantPropsOverride {
  primary: true;
  secondary: true;
}

export interface ColorPropsOverride {
  success: true;
  error: true;
  info: true;
  warning: true;
  tertiary: true;
  premium: true;
  neutral: true;
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides extends SizePropsOverride {}
  interface ButtonPropsVariantOverrides extends ButtonVariantPropsOverride {}
  interface ButtonPropsColorOverrides extends ColorPropsOverride {}
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides extends SizePropsOverride {}
  interface IconButtonPropsVariantOverrides
    extends ButtonVariantPropsOverride {}
  interface IconButtonPropsColorOverrides extends ColorPropsOverride {}
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides extends SizePropsOverride {}
  interface SvgIconPropsColorOverrides extends ColorPropsOverride {}
}

declare module '@mui/system' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    laptop: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const baseTheme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 768,
      laptop: 1024,
      md: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  background,
  text,
  status,
});

export const defaultMuiTheme: Theme = createTheme({
  palette: {
    ...main,
    ...validation,
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 768,
      laptop: 1024,
      md: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  background,
  text,
  status,
});

const dashboardTheme = createTheme(baseTheme, {
  palette: {
    ...main,
    ...validation,
    // Todo: Remove this after removing all used neutral colors
    neutral: {
      main: '#000000',
      dark5: '#000000',
      alpha10: '#000000',
      alpha20: '#000000',
      alpha30: '#000000',
      alpha50: '#000000',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Barlow',
    ...headingStyle,
    ...subheadingStyle,
    ...paragraphStyle,
    ...buttonTextStyle,
    ...linkTextStyle,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Barlow',
          color: text.t8,
        },
      },
      defaultProps: {
        variantMapping: {
          p1: 'p',
          p2: 'p',
          p3: 'p',
          p4: 'p',
          p5: 'p',
          p6: 'p',
          s1: 'p',
          s2: 'p',
          s3: 'p',
          s4: 'p',
          b1: 'p',
          b2: 'p',
          b3: 'p',
          b4: 'p',
          b5: 'p',
          b6: 'p',
        },
      },
    },
    ...iconButtonThemeConfig,
    ...buttonThemeConfig,
    MuiAvatarGroup: {
      styleOverrides: {
        root: ({ ownerState: { max } }: { ownerState: AvatarGroupProps }) =>
          max && {
            ...Array(max)
              .fill(null)
              .reduce(
                (result, curr, index) => ({
                  ...result,
                  [`& > .MuiAvatar-root:nth-of-type(${index + 1})`]: {
                    zIndex: max - index,
                  },
                }),
                {}
              ),
          },
      },
    },
    MuiAvatar: {
      variants: [
        {
          props: { size: AvatarSizeTypes.XL },
          style: {
            width: '64px',
            height: '64px',
          },
        },
        {
          props: { size: AvatarSizeTypes.L },
          style: {
            width: '48px',
            height: '48px',
          },
        },
        {
          props: { size: AvatarSizeTypes.M },
          style: {
            width: '40px',
            height: '40px',
          },
        },
        {
          props: { size: AvatarSizeTypes.S },
          style: {
            width: '36px',
            height: '36px',
          },
        },
        {
          props: { size: AvatarSizeTypes.XS },
          style: {
            width: '32px',
            height: '32px',
          },
        },
        {
          props: { size: AvatarSizeTypes.XXS },
          style: {
            width: '24px',
            height: '24px',
          },
        },
      ],
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }: { theme: Theme }) => ({
          ...generateTooltipStyle(theme),
          ...paragraphStyle.p5,
          borderRadius: '6px',
          backgroundColor: background.bg8,
          color: text.t1,
          padding: '4px 10px 4px 10px',
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }: { theme: Theme }) => ({
          borderRadius: 14,
          padding: '14px 0px 24px 0px',
          border: '1px solid',
          borderColor: theme.background.bg5,
          backgroundColor: theme.background.bg2,
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: theme.text.t8,
          margin: theme.spacing(0),
          padding: '36px 24px !important',
          fontFamily: theme.typography.fontFamily,
          letterSpacing: 'normal',
          ...theme.typography.p5,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          padding: '0px 16px 0px 24px',
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: () => ({
          justifyContent: 'center',
        }),
        spacer: () => ({
          flex: 'none',
        }),
      },
    },
  },
});

const LiveBoardTheme: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={dashboardTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          // ToDo: This is a quick solution for removing @mui/styles package.
          //  Remove this after removing css modules
          ':root': {
            '--lb-palette-primary-main': main.primary.main,
            '--lb-palette-error-main': validation.error.main,
            '--lb-mui-palette-grey-a100': grey.A100,
          },
        }}
      />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default LiveBoardTheme;
