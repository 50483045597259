import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import MuiTextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PersonalInformationQuestionType from '../../../common/services/dto/auth/PersonalInformationQuestionType';
import PersonalInformationQuestionDto from '../../../common/services/dto/auth/PersonalInformationQuestionDto';
import RequestPersonalInformationInputField from './RequestPersonalInformationInputField';
import FormDataType from './FormDataType';

type Props = {
  value: any;
  question: PersonalInformationQuestionDto;
  parser: (value: any, questionKey: any) => FormDataType | undefined;
  onChange: (...args: any[]) => any;
  control: any;
};

const RequestPersonalInformationQuestion = ({
  value,
  question,
  parser,
  onChange,
  control,
}: Props) => {
  const onChangeField = useCallback(
    (changedValue: any, onInputChange: any) => {
      const parsedField = parser(changedValue, question.key);
      onInputChange(parsedField);
      onChange(question.key);
    },
    [question, onChange]
  );

  return (
    <Box key={question.key}>
      <RequestPersonalInformationInputField
        name={question.key}
        label={question.text}
        inputType={question.type}
        onChange={onChangeField}
        control={control}
      >
        {question.options.map((option) => (
          <MenuItem key={option.key} value={option.text}>
            {question.type === PersonalInformationQuestionType.MultiChoice ? (
              <>
                <ListItemText primary={option.text} />
                <Checkbox
                  color="primary"
                  checked={value?.userChoices?.includes(option.text) || false}
                />
              </>
            ) : (
              option.text
            )}
          </MenuItem>
        ))}
        {question.hasOtherOption && (
          <MenuItem key="other" value="Other">
            {question.type === PersonalInformationQuestionType.MultiChoice ? (
              <>
                <ListItemText primary="Other" />
                <Checkbox color="primary" checked={value?.isOther || false} />
              </>
            ) : (
              'Other'
            )}
          </MenuItem>
        )}
      </RequestPersonalInformationInputField>
      {question.hasOtherOption && value?.isOther && (
        <Controller
          control={control}
          name={`${question.key}.userInput`}
          rules={{
            required: '* Required',
          }}
          render={({
            field: { onChange: userInputOnChange, value: inputValue },
            fieldState: { error },
          }) => (
            <FormControl
              margin="normal"
              fullWidth
              size="medium"
              sx={(theme) => ({
                minWidth: 300,
                padding: theme.spacing(0, 2),
              })}
            >
              <MuiTextField
                value={inputValue}
                onChange={userInputOnChange}
                variant="outlined"
                fullWidth
                helperText={error ? error.message : undefined}
                error={!!error}
                size="small"
                placeholder="Type your answer"
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FormControl>
          )}
        />
      )}
    </Box>
  );
};

export default RequestPersonalInformationQuestion;
