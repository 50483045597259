import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '../../atoms/ListItem';
import CheckIcon from '../../atoms/Icons/CheckIcon';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import * as groupsReducer from '../../../common/reducers/groupsReducer';
import recordingService from '../../../common/services/recording.service';

type Props = {
  open: boolean;
  onClose: (renamed?: any) => void;
  recording: any;
};

const defaultGroupName = 'default';
// Todo: change below constants when we have responsive dialogs
const listItemIcon = 20;
const listItemTextMargin = 10;
const listItemPadding = 16;

const MoveRecordingDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  recording,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState(defaultGroupName);
  const [error, setError] = useState(defaultGroupName);
  const [listItemTextWidth, setListItemTextWidth] = useState(0);
  const listItemRef = useRef<HTMLLIElement | null>(null);

  const groups = useSelector(groupsReducer.myGroupListSelector);

  const currentGroupId = useMemo(
    () =>
      recording && recording.groupId ? recording.groupId : defaultGroupName,
    [recording]
  );

  const onEnter = useCallback(() => {
    setSelectedGroupId(recording.groupId || defaultGroupName);
    setError('');

    if (!listItemRef.current) return;

    const listItemTextSize =
      listItemRef.current.offsetWidth -
      (listItemIcon + listItemTextMargin + listItemPadding * 2);
    setListItemTextWidth(listItemTextSize);
  }, [recording, setSelectedGroupId, setError, setListItemTextWidth]);

  const onSubmit = useCallback(async () => {
    try {
      const newGroupId =
        selectedGroupId === defaultGroupName ? null : selectedGroupId;
      await recordingService.moveToGroup(recording.id, newGroupId);
      onClose(true);
    } catch (e) {
      setError('Failed to find the recording');
    }
  }, [recording, selectedGroupId, onClose, setError]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <DialogTitle onClose={onClose} error={error}>
        Move to ...
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem
            title="LiveBoard"
            startIcon={
              selectedGroupId === defaultGroupName ? (
                <CheckIcon
                  sx={{
                    fontSize: '1.25rem',
                  }}
                />
              ) : (
                <Box width={20} height={20} />
              )
            }
            selected={selectedGroupId === defaultGroupName}
            onSelect={() => {
              setSelectedGroupId(defaultGroupName);
            }}
          />
          {groups.map((group: any) => (
            <ListItem
              ref={listItemRef}
              width={listItemTextWidth}
              key={group.id}
              title={group.name}
              startIcon={
                selectedGroupId === group.id ? (
                  <CheckIcon
                    sx={{
                      fontSize: '1.25rem',
                    }}
                  />
                ) : (
                  <Box width={20} height={20} />
                )
              }
              selected={selectedGroupId === group.id}
              onSelect={() => {
                setSelectedGroupId(group.id);
              }}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton
          onClick={onSubmit}
          disabled={selectedGroupId === currentGroupId}
        >
          Move
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveRecordingDialog;
