import { toColor } from '../../../../common/utils/color.utils';

const triangleDrawer = (ctx, item) => {
  const {
    left,
    right,
    top,
    paint,
  } = item;
  const {
    color,
    fill,
    strokeWidth,
  } = paint;
  const rgbaColor = toColor(color);
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(left.x, left.y);
  ctx.lineTo(right.x, right.y);
  ctx.lineTo(top.x, top.y);
  ctx.closePath();

  if (fill) {
    ctx.fillStyle = rgbaColor;
    ctx.fill();
  } else {
    ctx.strokeStyle = rgbaColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
  }
  ctx.restore();
};

export default triangleDrawer;
