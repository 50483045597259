import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import Box from '@mui/material/Box';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import Typography from '../../../../../ui/atoms/Typography';
import * as drawingToolActions from '../../../../../common/actions/board/drawingToolActions';
import ToolNames from '../../../../DrawingTools/ToolNames';
import { getSelectedDrawingTool } from '../../../../../common/reducers/board/drawingToolReducer';
import { getCurrentDrawing } from '../../../../../common/reducers/board/currentDrawingReducer';
import {
  previousClosestElement,
  nextClosestElement,
} from '../../../../../common/utils/array.utils';
import { setShapeCurrentDrawing } from '../../../../../common/actions/board/currentDrawingAction';
import ChevronUpIcon from '../../../../../ui/atoms/Icons/ChevronUpIcon';
import ChevronDownIcon from '../../../../../ui/atoms/Icons/ChevronDownIcon';
import ToolbarDivider from '../../../ToolbarDivider';
import useBoardRatio from '../../../../../common/hooks/useBoardRatio';
import { getTextDimensions } from '../../../../../common/utils/textDimensions.util';
import { setSizes } from '../../../../../common/actions/board/textDrawingToolActions';
import { textSelector } from '../../../../../common/reducers/board/textDrawingToolReducer';

const availableFontSizes = [12, 14, 18, 24, 36, 48, 64, 72];

const TextSizeControl = () => {
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);
  const text = useSelector(textSelector);
  const ratio = useBoardRatio();

  const originFontSize = useMemo(() => {
    if (currentDrawing)
      return Math.round(Number(currentDrawing.paint.textSize) / ratio);

    return selectedTool.size?.originValue;
  }, [selectedTool, ratio, currentDrawing]);

  const dispatch = useDispatch();

  const updateTextDimensions = useCallback(
    (fontSize: any) => {
      const textDimensions = getTextDimensions(text, fontSize);
      dispatch(setSizes(textDimensions.width, textDimensions.height));
    },
    [dispatch, text]
  );

  const updateFontSize = useCallback(
    (fontSize: any) => {
      dispatch(
        drawingToolActions.updateDrawingTool(ToolNames.Text, {
          size: {
            ...selectedTool.size,
            value: fontSize * ratio,
            originValue: fontSize,
          },
        })
      );
      if (currentDrawing) {
        const clonedCurrentDrawing = cloneDeep(currentDrawing);
        clonedCurrentDrawing.paint.textSize = fontSize * ratio;

        dispatch(setShapeCurrentDrawing(clonedCurrentDrawing));
      }
    },
    [dispatch, selectedTool, ratio]
  );

  const updateText = useCallback(
    (fontSize: any) => {
      updateTextDimensions(fontSize * ratio);
      updateFontSize(fontSize);
    },
    [updateTextDimensions, updateFontSize, ratio]
  );

  const onDecreaseFontSize = useCallback(
    (event: any) => {
      event.stopPropagation();

      const previousFontSize = previousClosestElement(
        availableFontSizes,
        +originFontSize
      );

      updateText(previousFontSize);
    },
    [originFontSize, updateText]
  );

  const onIncreaseFontSize = useCallback(
    (event: any) => {
      event.stopPropagation();
      const nextFontSize = nextClosestElement(
        availableFontSizes,
        +originFontSize
      );

      updateText(nextFontSize);
    },
    [originFontSize, updateText]
  );

  const shouldDisableDecreaseFontSizeButton = useMemo(() => {
    const previousFontSize = previousClosestElement(
      availableFontSizes,
      +originFontSize
    );

    return !previousFontSize;
  }, [originFontSize]);

  const shouldDisableIncreaseFontSizeButton = useMemo(() => {
    const nextClosestFontSize = nextClosestElement(
      availableFontSizes,
      +originFontSize
    );

    return !nextClosestFontSize;
  }, [originFontSize]);

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" gap={1} alignItems="center">
        <IconButton
          onClick={onDecreaseFontSize}
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          disabled={shouldDisableDecreaseFontSizeButton}
          shape={ButtonShapeTypes.ROUNDED}
        >
          <ChevronDownIcon />
        </IconButton>
        <Typography variant="b5">{originFontSize}</Typography>
        <IconButton
          onClick={onIncreaseFontSize}
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          disabled={shouldDisableIncreaseFontSizeButton}
          shape={ButtonShapeTypes.ROUNDED}
        >
          <ChevronUpIcon />
        </IconButton>
      </Box>
      <ToolbarDivider />
    </Box>
  );
};

export default TextSizeControl;
