import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from '../../organisms/Footer';
import {
  userIsVerified,
  userPlanSelector,
  userSelector,
  userSubscriptionSelector,
} from '../../../common/reducers/userReducer';
import VerifyEmailNotification from '../../molecules/VerifyEmailNotification';
import AddPaymentMethodNotification from '../../molecules/AddPaymentMethodNotification';
import userService from '../../../common/services/auth.service';
import RecordingList from '../../molecules/RecordingList';
import InjectGroup from '../../../containers/Dashboard/SessionList/InjectGroup';
import CardGrid from '../../molecules/CardGrid/CardGrid';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import {
  SortConfigDirection,
  SortConfigKey,
  SortOptions,
} from '../../molecules/SortingCard/sortTypes';
import analyticsService from '../../../common/services/analytics.service';
import {
  boardsCountByGroupIdSelector,
  hasSessionsSelector,
  isFetchingSelector as isSessionFetchingSelector,
  isInitialDataFetchedSelector as isSessionInitialDataFetchedSelector,
} from '../../../common/reducers/sessionsReducer';
import { getSessionList } from '../../../common/actions/sessionsActions';
import { getRecordingList } from '../../../common/actions/recordingsActions';
import NoContentCard from '../../molecules/NoContentCard';
import OldLiveboardIcon from '../../atoms/Icons/OldLiveboardIcon';
import useDialog from '../../../common/hooks/useDialog';
import CreateBoardDialog from '../../molecules/CreateBoardDialog';
import { getGeneralConfiguration } from '../../../common/actions/configurationActions';
import { setShowProductTour } from '../../../common/actions/dashboardActions';
import CreateCard from '../../molecules/CreateCard';
import PlusIcon from '../../atoms/Icons/PlusIcon';
import {
  getGroupSelector,
  groupsSelector,
} from '../../../common/reducers/groupsReducer';
import GroupGrid from '../../molecules/GroupGrid';
import { canCreateGroupSelector } from '../../../common/selectors/planPermissionSelectors';
import UpgradeDialog from '../../molecules/UpgradeDialog';
import PlanDialog from '../../molecules/PlanDialog';
import { contactListSelector } from '../../../common/reducers/contactsReducer';
import GroupMembersDialog from '../../molecules/GroupMembersDialog';
import Tutorial from '../../molecules/Tutorial/Tutorial';
import RouteTypes from './dashboardTypes';
import SubHeader from './Subheader';
import GroupHeader from './GroupHeader';
import INFINITE from '../../../common/utils/types/configuration/Infinite.constant';
import {
  boardsCountLimitSelector,
  boardsCountSelector,
  recordingsCountLimitSelector,
  recordingsCountSelector,
  showBoardListLimitInfoSelector,
  showRecordingListLimitInfoSelector,
} from '../../../common/reducers/configurationReducer';
import { showProductTourSelector } from '../../../common/reducers/dashboardReducer';
import TryPremiumNotification from '../../molecules/TryPremiumNotification';
import CreateGroupDialog from '../../molecules/CreateGroupDialog';
import JoinSessionDialog from '../../molecules/JoinSessionDialog';
import RequestPersonalInformation from '../../../components/RequestPersonalInformation';
import SocialMediaSupportWrapper from './SocialMediaSupportWrapper';
import SupportButtonWrapper from './SupportButtonWrapper';
import useGroupContactWithoutInvited from '../../../common/hooks/useGroupContactWithoutInvited';
import JoinSessionForm from '../../molecules/JoinSessionForm';
import useCreateBoard from '../../molecules/CreateBoardDialog/useCreateBoard';
import { useProductTour } from '../../../components/ProductTour';
import PromoCodeNotification from '../../molecules/PromoCodeNotification/PromoCodeNotification';
import storage, { liveBoardStorage } from '../../../config/storage';
import { StripeBusinessAccountStatus } from '../../../common/services/dto/collectPayments/PaymentStatusDto';
import { TitleBar } from '../../atoms/TitleBar';
import SortingCard from '../../molecules/SortingCard';
import { Layout } from '../../../components/Layout';
import Button from '../../atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';

const routes = [
  { pathPart: RouteTypes.SESSIONS, component: CardGrid },
  { pathPart: RouteTypes.RECORDINGS, component: RecordingList },
];

const pageSize = 12;

const isInViewport = (element: any) => {
  const { top } = element.getBoundingClientRect();
  const windowViewportHeight =
    window.innerHeight || document.documentElement.clientHeight;

  return top <= windowViewportHeight;
};

const promoCodeFreePlanIds = [
  '5e7cf5bff07adfee7c7db399',
  '5bdbf0ec52ad8fc7037a7dbc',
  '5fdbcd532d1a71516c90ead2',
];

const Dashboard = () => {
  const params = useParams<{ groupId: string; tab: RouteTypes }>();
  const { groupId = 'default', tab } = params;
  const containerRef = useRef<HTMLDivElement | null>(null);

  const groupAndContactsSelector = useMemo(
    () => getGroupSelector(groupId),
    [groupId]
  );

  const group = useSelector(groupAndContactsSelector);
  const userSubscription = useSelector(userSubscriptionSelector);
  const userPlan = useSelector(userPlanSelector);
  const showPromoCodeNotification = useMemo(
    () => promoCodeFreePlanIds.includes(userPlan.id),
    [userPlan.id]
  );

  const isUserVerified = useSelector(userIsVerified);
  const createdBoardsCountSelector = useMemo(
    () => boardsCountByGroupIdSelector(groupId),
    [groupId]
  );
  const createdBoardCount = useSelector(createdBoardsCountSelector);
  const groups = useSelector(groupsSelector);
  const hasSession = useSelector(hasSessionsSelector);
  const canCreateGroup = useSelector(canCreateGroupSelector);
  const contactsList = useSelector(contactListSelector);
  const isSessionFetching = useSelector(isSessionFetchingSelector);
  const isInitialDataFetched = useSelector(isSessionInitialDataFetchedSelector);
  const user = useSelector(userSelector);
  const showProductTour = useSelector(showProductTourSelector);
  const footerRef = useRef(null);

  const showBoardsLimitInfo = useSelector(showBoardListLimitInfoSelector);
  const showRecordingLimitInfo = useSelector(
    showRecordingListLimitInfoSelector
  );
  const boardsCount = useSelector(boardsCountSelector);
  const boardsCountLimit = useSelector(boardsCountLimitSelector);
  const recordingsCount = useSelector(recordingsCountSelector);
  const recordingsCountLimit = useSelector(recordingsCountLimitSelector);

  const theme = useTheme();
  const showSidePanels = useMediaQuery(theme.breakpoints.up('md'));
  const showJoin = useMediaQuery(theme.breakpoints.down('sm'));
  const showH6TutorialsText = useMediaQuery(theme.breakpoints.up('sm'));
  const showLongCreateGroupText = useMediaQuery(theme.breakpoints.up('md'));

  const productTour = useProductTour();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const location = useLocation<{
    collectPaymentsOnboardingStatus: StripeBusinessAccountStatus;
    paymentStatus?: string;
  }>();

  const collectPaymentsOnboardingStatus = useMemo(() => {
    if (!location?.state) return null;

    return location.state.collectPaymentsOnboardingStatus;
  }, [location.state]);

  const paymentStatus = useMemo(() => {
    if (!location?.state || !location.state?.paymentStatus) return null;

    return location.state.paymentStatus;
  }, [location.state]);

  const { onBoardCreate } = useCreateBoard({
    groupId,
    onLimitReached: () => {},
  });

  const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [planSource, setPlanSource] = useState('');

  const {
    open: joinSessionDialog,
    onOpen: onJoinSessionDialogOpen,
    onClose: onJoinSessionDialogClose,
  } = useDialog();

  const {
    open: createBoardDialog,
    onOpen: onCreateBoardDialogOpen,
    onClose: onCreateBoardDialogClose,
  } = useDialog();

  const {
    open: createGroupDialog,
    onOpen: onCreateGroupDialogOpen,
    onClose: onCreateGroupDialogClose,
  } = useDialog();

  const {
    open: upgradeDialog,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const {
    open: groupMemberDialogOpen,
    onOpen: onGroupMemberDialogOpen,
    onClose: onGroupMemberDialogClose,
  } = useDialog();

  const initialBoardsSortConfig = useMemo(() => {
    const boardSortConfig = storage.getItem('cardsSortConfig');
    const defaultConfig = {
      key: SortConfigKey.LASTMODIFIED,
      direction: SortConfigDirection.DESC,
    };

    if (!boardSortConfig) return defaultConfig;

    try {
      return JSON.parse(boardSortConfig);
    } catch (error) {
      return defaultConfig;
    }
  }, []);

  const initialGroupsSortConfig = useMemo(() => {
    const groupsSortConfig = storage.getItem('groupsSortConfig');
    const defaultConfig = {
      key: SortConfigKey.LASTMODIFIED,
      direction: SortConfigDirection.DESC,
    };

    if (!groupsSortConfig) return defaultConfig;

    try {
      return JSON.parse(groupsSortConfig);
    } catch (error) {
      return defaultConfig;
    }
  }, []);

  const [boardsSortConfig, setBoardsSortConfig] = useState<SortOptions>(
    initialBoardsSortConfig
  );
  const [groupsSortConfig, setGroupsSortConfig] = useState<SortOptions>(
    initialGroupsSortConfig
  );

  const getPaymentMethod = useCallback(async () => {
    const defaultCard = await userService.getDefaultPaymentMethod();
    setHasPaymentMethod(!!defaultCard);
  }, [setHasPaymentMethod]);

  const load = useCallback(
    (pageNumber = 0) => {
      dispatch(
        getSessionList({
          groupId,
          pageNumber,
          pageSize,
        })
      );

      dispatch(getRecordingList({ groupId }));
    },
    [dispatch, groupId]
  );

  useEffect(() => {
    if (!containerRef.current) return undefined;

    if (isInViewport(footerRef.current)) {
      setIsFooterVisible(true);
    }

    const checkFooterVisibility = () => {
      if (isInViewport(footerRef.current)) {
        setIsFooterVisible(true);

        return;
      }

      if (isFooterVisible) {
        setIsFooterVisible(false);
      }
    };

    containerRef.current.addEventListener('scroll', checkFooterVisibility);

    return () => {
      if (!containerRef.current) return;

      setIsFooterVisible(false);
      containerRef.current.removeEventListener('scroll', checkFooterVisibility);
    };
  }, [groupId, isFooterVisible, setIsFooterVisible]);

  useEffect(() => {
    load();
    dispatch(getGeneralConfiguration());
  }, [dispatch, load]);

  useEffect(() => {
    if (userSubscription.isTrial) {
      getPaymentMethod();
    }
  }, [userSubscription.isTrial, getPaymentMethod]);

  const requestSort = useCallback(
    (value: SortOptions, setConfig: (config: SortOptions) => void) => {
      const { key, direction } = value;

      setConfig({ key, direction });
    },
    []
  );

  const sortingCards = useCallback((cards: any, sortConfig: SortOptions) => {
    const tempCards = [...cards];

    const sortDirection = sortConfig.direction || SortConfigDirection.ASC;
    const directionMultiplier =
      sortDirection === SortConfigDirection.ASC ? 1 : -1;

    return tempCards.sort((a, b) => {
      const comparisonValue1 = a[sortConfig.key].toString();
      const comparisonValue2 = b[sortConfig.key].toString();

      const comparisonResult =
        comparisonValue1.toLowerCase() > comparisonValue2.toLowerCase()
          ? 1
          : -1;

      return comparisonResult * directionMultiplier;
    });
  }, []);

  const sortingSessionCards = useCallback(
    (cards: any) => sortingCards(cards, boardsSortConfig),
    [sortingCards, boardsSortConfig]
  );

  const sortingGroupsCards = useCallback(
    (cards: any) => sortingCards(cards, groupsSortConfig),
    [sortingCards, groupsSortConfig]
  );

  const onCreateGroupButton = useCallback(
    (event: any) => {
      event.preventDefault();

      analyticsService.event('Create Group Button Click', {
        source: 'Side Menu',
      });

      if (canCreateGroup) {
        onCreateGroupDialogOpen();

        return;
      }

      onUpgradeDialogOpen();
      setPlanSource('Create Group');
    },
    [
      onCreateGroupDialogOpen,
      onUpgradeDialogOpen,
      canCreateGroup,
      setPlanSource,
    ]
  );

  const groupContactWithoutInvited = useGroupContactWithoutInvited(group?.id);

  const generateAvatarList = useMemo(() => {
    if (group?.contacts) {
      return groupContactWithoutInvited.map((member: any) => ({
        alt: member.name || '',
        src: member?.profileImageUrl,
      }));
    }

    return [];
  }, [group, groupContactWithoutInvited]);

  const getGroupOwnerData = useCallback(
    (groupOwnerId: string) => {
      if (groupOwnerId === user.id) {
        return {
          name: user.name,
          avatarUrl: user.profileImageUrl,
        };
      }

      const filteredContact = contactsList.filter(
        (contact: any) => contact.id === groupOwnerId
      );

      return {
        name: filteredContact[0].name,
        avatarUrl: filteredContact[0].profileImageUrl,
      };
    },
    [user, contactsList]
  );

  const isHost = useMemo(() => {
    if (!group) return true;

    return user.id === group.ownerId;
  }, [user, group]);

  const showBoardLimitNotification = useMemo(
    () =>
      showBoardsLimitInfo &&
      boardsCountLimit !== INFINITE &&
      boardsCount >= boardsCountLimit,
    [showBoardsLimitInfo, boardsCount, boardsCountLimit]
  );

  const showRecordingLimitNotification = useMemo(
    () =>
      showRecordingLimitInfo &&
      recordingsCountLimit !== INFINITE &&
      recordingsCount >= recordingsCountLimit,
    [showRecordingLimitInfo, recordingsCount, recordingsCountLimit]
  );

  const showTryPremiumNotification = useMemo(
    () => showBoardLimitNotification || showRecordingLimitNotification,
    [showBoardLimitNotification, showRecordingLimitNotification]
  );

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
  }, [onPlansDialogOpen, onUpgradeDialogClose]);

  useEffect(() => {
    if (showProductTour && isInitialDataFetched) {
      productTour.startTour();
      analyticsService.event('Product Tour Start', {
        auto: true,
      });
      dispatch(setShowProductTour(false));
    }
  }, [showProductTour && isInitialDataFetched]);

  useEffect(() => {
    if (productTour.sideEffect === 'createBoard') {
      onBoardCreate().then(() => {
        productTour.setCurrentStep(1);
        productTour.setSideEffect('');
        productTour.pauseTour();
      });
    }
  }, [productTour.sideEffect]);

  useEffect(() => {
    if (paymentStatus && !userSubscription.plan.isFree) {
      const source = liveBoardStorage.getItem('source') || '';

      if (userSubscription.isTrial) {
        analyticsService.event('Trial Activated', {
          planId: userSubscription.plan.id,
          source,
        });
      }

      enqueueSnackbar(
        userSubscription.isTrial
          ? 'Your Trial has been successfully activated'
          : 'Your plan has been successfully activated',
        {
          variant: 'success',
          autoHideDuration: 3000,
        }
      );
      history.replaceState(null, '', '/group/default/sessions');
      liveBoardStorage.removeItem('source');
    }
  }, [
    paymentStatus,
    enqueueSnackbar,
    userSubscription.isTrial,
    userSubscription.plan.isFree,
    userSubscription.plan.id,
  ]);

  const onPromoCodeSuccess = useCallback(() => {
    setPlanSource('50% Discount Dialog');
    onPlansDialogOpen();
  }, [setPlanSource, onPlansDialogOpen]);

  const onChangeBoardConfig = useCallback(
    (config: SortOptions) => {
      setBoardsSortConfig(config);
      const jsonConfig = JSON.stringify(config);
      storage.setItem('cardsSortConfig', jsonConfig);
    },
    [setBoardsSortConfig]
  );

  const onChangeGroupConfig = useCallback(
    (config: SortOptions) => {
      setGroupsSortConfig(config);
      const jsonConfig = JSON.stringify(config);
      storage.setItem('groupsSortConfig', jsonConfig);
    },
    [setGroupsSortConfig]
  );

  return (
    <Layout ref={containerRef}>
      <Box>
        <Box
          sx={(t) => ({
            display: 'flex',
            justifyContent: 'center',
            [t.breakpoints.up('md')]: {
              justifyContent: 'space-between',
            },
          })}
        >
          {showSidePanels && (
            <SocialMediaSupportWrapper
              visible={!isSessionFetching && !isFooterVisible}
            />
          )}
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              minWidth: 0,
            }}
          >
            {!isUserVerified &&
              ((!showTryPremiumNotification && showPromoCodeNotification) ||
                (showTryPremiumNotification && !showPromoCodeNotification)) && (
                <Box
                  sx={(t) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '16px',
                    marginLeft: '16px',
                    marginRight: '16px',
                    [t.breakpoints.up('sm')]: {
                      marginLeft: '16px',
                      marginRight: '16px',
                    },
                    [t.breakpoints.up('md')]: {
                      marginLeft: '16px',
                      marginRight: '16px',
                    },
                  })}
                >
                  <VerifyEmailNotification />
                </Box>
              )}
            {showTryPremiumNotification && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <TryPremiumNotification
                  title={
                    showBoardLimitNotification
                      ? `You reached your ${boardsCountLimit}-board limit.`
                      : `You reached your ${recordingsCountLimit}-recording limit.`
                  }
                  source={
                    showBoardLimitNotification
                      ? 'Hitting Board limit'
                      : 'Hitting Recording limit'
                  }
                />
              </Box>
            )}
            {!showPromoCodeNotification && userSubscription.isTrial && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <AddPaymentMethodNotification
                  hasPaymentMethod={hasPaymentMethod}
                  expirationDate={userSubscription.expirationDate}
                  onAddSuccess={getPaymentMethod}
                />
              </Box>
            )}
            {showPromoCodeNotification && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <PromoCodeNotification onSuccess={onPromoCodeSuccess} />
              </Box>
            )}
            <Box
              sx={(t) => ({
                display: 'flex',
                justifyContent: 'center',
                margin: '0 16px',
                [t.breakpoints.up('sm')]: {
                  margin: '0 24px',
                },
                [t.breakpoints.up('md')]: {
                  margin: '0 30px',
                },
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  minWidth: 0,
                  flex: 1,
                  flexDirection: 'column',
                  marginBottom: '40px',
                  maxWidth: '1232px',
                }}
              >
                {showJoin && hasSession && (
                  <Box
                    sx={(t) => ({
                      marginTop: '24px',
                      [t.breakpoints.up('sm')]: {
                        marginTop: '32px',
                      },
                      [t.breakpoints.up('lg')]: {
                        marginTop: '48px',
                      },
                    })}
                  >
                    <JoinSessionForm />
                  </Box>
                )}
                <Box
                  sx={(t) => ({
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    margin: '24px 0',
                    gap: '24px',
                    [t.breakpoints.up('sm')]: {
                      margin: '32px 0',
                    },
                  })}
                >
                  {group && (
                    <GroupHeader
                      groupName={group.name}
                      ownerName={getGroupOwnerData(group.ownerId).name}
                      avatarUrl={getGroupOwnerData(group.ownerId).avatarUrl}
                      isHost={isHost}
                      participants={generateAvatarList}
                      onAvatarGroupClick={onGroupMemberDialogOpen}
                    />
                  )}
                  {!group && (
                    <TitleBar
                      title={
                        hasSession || groups.length !== 0
                          ? 'Groups'
                          : 'My LiveBoard'
                      }
                      actions={
                        groups.length !== 0 ? (
                          <SortingCard
                            sortConfig={groupsSortConfig}
                            changeSelectOption={(value) => {
                              requestSort(value, onChangeGroupConfig);
                            }}
                          />
                        ) : null
                      }
                    />
                  )}
                </Box>
                {!isSessionFetching &&
                  groupId === 'default' &&
                  !groups.length &&
                  hasSession && (
                    <Box
                      sx={(t) => ({
                        marginBottom: '24px',
                        [t.breakpoints.up('sm')]: {
                          marginBottom: '64px',
                        },
                        [t.breakpoints.up('lg')]: {
                          marginBottom: '136px',
                        },
                      })}
                    >
                      <CreateCard
                        Icon={PlusIcon}
                        title={
                          showLongCreateGroupText
                            ? 'Create a group to organize your boards by subjects and students'
                            : 'Create a group'
                        }
                        width="100%"
                        height="auto"
                        onClick={onCreateGroupButton}
                      />
                    </Box>
                  )}
                {groups.length !== 0 && groupId === 'default' && (
                  <Box
                    sx={(t) => ({
                      marginBottom: '48px',
                      [t.breakpoints.up('sm')]: {
                        marginBottom: '64px',
                      },
                      [t.breakpoints.up('lg')]: {
                        marginBottom: '136px',
                      },
                    })}
                  >
                    <GroupGrid
                      groups={groups}
                      sortingGroups={sortingGroupsCards}
                    />
                  </Box>
                )}
                <SubHeader
                  isFetching={isSessionFetching}
                  changeSelectOption={(value) => {
                    requestSort(value, onChangeBoardConfig);
                  }}
                  sortConfig={boardsSortConfig}
                />
                {!isSessionFetching &&
                  !createdBoardCount &&
                  tab === RouteTypes.SESSIONS && (
                    <NoContentCard
                      Icon={OldLiveboardIcon}
                      title={
                        isHost
                          ? 'You have no boards'
                          : 'There are no boards in this group'
                      }
                      description={
                        isHost
                          ? 'Create your first board or join a session.'
                          : "You'll be notified when the owner creates one."
                      }
                      actions={
                        <Box display="flex" gap="16px" whiteSpace="nowrap">
                          {isHost && (
                            <>
                              <Button
                                onClick={onCreateBoardDialogOpen}
                                startIcon={
                                  <PlusIcon sx={{ fontSize: '20px' }} />
                                }
                                variant={ButtonVariantTypes.PRIMARY}
                                size={ButtonSizeTypes.M}
                                className="product-tour-step-1 no-group"
                              >
                                New board
                              </Button>
                              <Button
                                onClick={onJoinSessionDialogOpen}
                                variant={ButtonVariantTypes.SECONDARY}
                                size={ButtonSizeTypes.M}
                                sx={{
                                  padding: '13px 21.5px 13px 21.5px',
                                }}
                              >
                                Join session
                              </Button>
                            </>
                          )}
                        </Box>
                      }
                      dashed
                    />
                  )}
                <Switch>
                  {routes.map(({ pathPart, component }) => (
                    <Route
                      key={pathPart}
                      exact
                      path={`/group/:groupId/${pathPart}`}
                      render={({ match }) => (
                        <InjectGroup
                          groupId={match.params.groupId}
                          sortingCards={sortingSessionCards}
                          noGroupRedirectUrl={`/group/default/${pathPart}`}
                          component={component}
                        />
                      )}
                    />
                  ))}
                </Switch>
                {!isSessionFetching && !hasSession && !group && (
                  <Box
                    sx={(t) => ({
                      marginTop: '48px',
                      [t.breakpoints.up('sm')]: {
                        marginTop: '64px',
                      },
                      [t.breakpoints.up('lg')]: {
                        marginTop: '120px',
                      },
                    })}
                  >
                    <Box
                      sx={(t) => ({
                        marginBottom: '24px',
                        [t.breakpoints.up('sm')]: {
                          marginBottom: '48px',
                        },
                      })}
                    >
                      <Typography
                        variant={
                          showH6TutorialsText
                            ? TypographyType.h6
                            : TypographyType.s1
                        }
                      >
                        Tutorials
                      </Typography>
                    </Box>
                    <Tutorial />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {showSidePanels && (
            <SupportButtonWrapper
              visible={!isSessionFetching && !isFooterVisible}
            />
          )}
        </Box>
      </Box>
      <Footer ref={footerRef} />
      <CreateBoardDialog
        open={createBoardDialog}
        onClose={onCreateBoardDialogClose}
        groupId={groupId}
      />
      <CreateGroupDialog
        open={createGroupDialog}
        onClose={onCreateGroupDialogClose}
      />
      <UpgradeDialog
        open={upgradeDialog}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source={planSource}
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
      <GroupMembersDialog
        open={groupMemberDialogOpen}
        onClose={onGroupMemberDialogClose}
        count={groupContactWithoutInvited.length || 0}
        groupName={group?.name}
        groupId={groupId}
        isHost={isHost}
      />
      <JoinSessionDialog
        open={joinSessionDialog}
        onClose={onJoinSessionDialogClose}
      />
      <RequestPersonalInformation />
    </Layout>
  );
};

export default Dashboard;
