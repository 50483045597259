import { ReactNode, useMemo, useState, FC, useCallback } from 'react';
import { TourProvider } from '@reactour/tour';
import Box from '@mui/material/Box';
import ProductTourContext from './ProductTourContext';
import Button from '../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../ui/atoms/Button/buttonTypes';
import analyticsService from '../../common/services/analytics.service';
import { noop } from '../../common/constants';
import steps from './steps';

const stepNames = ['Get Started', 'Add People', 'Invite', 'Start Call'];

type ProductTourProviderProps = {
  children: ReactNode;
};

const ProductTourProvider: FC<
  React.PropsWithChildren<ProductTourProviderProps>
> = ({ children }) => {
  const [inProgress, setInProgress] = useState(false);
  const [sideEffect, setSideEffect] = useState('');

  const stopTour = useCallback(() => {
    setInProgress(false);
    setSideEffect('');
  }, [setInProgress, setSideEffect]);

  const nextButtonClick = useCallback(
    (props: any) => {
      const onClick = () => {
        if (props.currentStep === props.stepsLength - 1) {
          analyticsService.event('Product Tour Finish Click', {
            step: stepNames[props.currentStep],
          });
          props.setIsOpen(false);
          props.setCurrentStep(0);
          stopTour();
          return;
        }
        analyticsService.event('Product Tour Next Click', {
          step: stepNames[props.currentStep],
        });
        const newStep = Math.min(props.currentStep + 1, props.stepsLength - 1);
        switch (newStep) {
          case 1: {
            setSideEffect('createBoard');
            break;
          }
          case 2: {
            setSideEffect('openInviteDialog');
            break;
          }
          case 3: {
            setSideEffect('closeInviteDialog');
            break;
          }
          default: {
            setSideEffect('');
            props.setCurrentStep(newStep);
            break;
          }
        }
      };
      return (
        <Box ml={2}>
          <Button
            onClick={onClick}
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.S}
            disabled={Boolean(sideEffect)}
          >
            {props.currentStep === props.stepsLength - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      );
    },
    [stopTour, setSideEffect, sideEffect]
  );

  const prevButtonClick = useCallback(
    (props: any) => {
      if (props.currentStep === props.stepsLength - 1) {
        return null;
      }

      const onClick = () => {
        analyticsService.event('Product Tour Skip Click', {
          step: stepNames[props.currentStep],
        });
        props.setIsOpen(false);
        props.setCurrentStep(0);
        stopTour();
      };

      return (
        <Button
          onClick={onClick}
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          disabled={Boolean(sideEffect)}
        >
          Skip
        </Button>
      );
    },
    [stopTour, sideEffect]
  );

  const value = useMemo(
    () => ({
      inProgress,
      sideEffect,
      setInProgress,
      setSideEffect,
    }),
    [inProgress, sideEffect, setInProgress, setSideEffect]
  );

  return (
    <ProductTourContext.Provider value={value}>
      <TourProvider
        steps={steps}
        showBadge={false}
        onClickClose={noop}
        onClickMask={noop}
        disableInteraction
        disableFocusLock
        nextButton={nextButtonClick}
        prevButton={prevButtonClick}
        disableDotsNavigation
        scrollSmooth
        disableKeyboardNavigation
        // @ts-ignore
        showDots={false}
        showCloseButton={false}
      >
        {children}
      </TourProvider>
    </ProductTourContext.Provider>
  );
};

export default ProductTourProvider;
