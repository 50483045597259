const SET_LINE_CURRENT_DRAWING = Symbol('SET_LINE_CURRENT_DRAWING');
const SET_SHAPE_CURRENT_DRAWING = Symbol('SET_SHAPE_CURRENT_DRAWING');
const SET_IMAGE_CURRENT_DRAWING = Symbol('SET_IMAGE_CURRENT_DRAWING');
const UPDATE_LINE_CURRENT_DRAWING = Symbol('UPDATE_LINE_CURRENT_DRAWING');
const UPDATE_SHAPE_CURRENT_DRAWING = Symbol('UPDATE_SHAPE_CURRENT_DRAWING');

const setLineCurrentDrawing = action => ({
  type: SET_LINE_CURRENT_DRAWING,
  action,
});

const setShapeCurrentDrawing = action => ({
  type: SET_SHAPE_CURRENT_DRAWING,
  action,
});

const setImageCurrentDrawing = action => ({
  type: SET_IMAGE_CURRENT_DRAWING,
  action,
});

const updateLineCurrentDrawing = action => ({
  type: UPDATE_LINE_CURRENT_DRAWING,
  action,
});

const updateShapeCurrentDrawing = action => ({
  type: UPDATE_SHAPE_CURRENT_DRAWING,
  action,
});

export {
  setLineCurrentDrawing,
  setShapeCurrentDrawing,
  setImageCurrentDrawing,
  updateLineCurrentDrawing,
  updateShapeCurrentDrawing,
  SET_LINE_CURRENT_DRAWING,
  SET_SHAPE_CURRENT_DRAWING,
  SET_IMAGE_CURRENT_DRAWING,
  UPDATE_LINE_CURRENT_DRAWING,
  UPDATE_SHAPE_CURRENT_DRAWING,
};
