import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../atoms/Typography';
import sessionsService from '../../../common/services/session.service';
import { removeSession } from '../../../common/actions/sessionsActions';
import { userSelector } from '../../../common/reducers/userReducer';
import { removeBoard } from '../../../common/actions/configurationActions';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import TrashCanOutlineIcon from '../../atoms/Icons/TrashCanOutlineIcon';

type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  card: any;
};

const DeleteSessionDialog: FC<React.PropsWithChildren<DeleteDialogProps>> = ({
  open,
  onClose,
  card,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [error, setError] = useState('');

  const onDelete = useCallback(async () => {
    try {
      await sessionsService.deleteSession(card.id);
      onClose();
      dispatch(removeSession(card.id));
      if (user.id === card.owner.id) {
        dispatch(removeBoard());
      }
    } catch (err) {
      const { code } = err;
      if (code === '200005') {
        setError('Failed to find the board.');
        dispatch(removeSession(card.id));

        if (user.id === card.owner.id) {
          dispatch(removeSession(card.id));
        }
      }
    }
  }, [dispatch, card, user, setError]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        icon={<TrashCanOutlineIcon color="error" />}
        onClose={onClose}
        error={error}
      >
        Delete board
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={TypographyType.p5}
          sx={(theme) => ({
            color: theme.text.t8,
          })}
        >
          Are you sure you want to delete this board?
        </Typography>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onDelete} color="error">
          Delete
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSessionDialog;
