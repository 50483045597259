import { FC } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import IconButton from '../../../../../ui/atoms/IconButton';

type Props = {
  onEndCall: () => void;
  loading?: boolean;
  disabled?: boolean;
  tooltipTitle: string;
  tooltipPlacment: 'top' | 'left';
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.status.new.main,
  border: `2px solid ${theme.status.new.main}`,
  '&:hover': {
    backgroundColor: theme.status.new.dark5,
    border: `2px solid ${theme.status.new.dark5}`,
  },
}));

const CallControlsPanel: FC<React.PropsWithChildren<Props>> = ({
  onEndCall,
  loading = false,
  disabled = false,
  tooltipTitle,
  tooltipPlacment,
}) => (
  <Tooltip title={tooltipTitle} placement={tooltipPlacment}>
    <Box>
      <StyledIconButton
        variant={ButtonVariantTypes.SECONDARY}
        size={ButtonSizeTypes.S}
        shape={ButtonShapeTypes.ROUNDED}
        disabled={disabled}
        onClick={onEndCall}
      >
        {loading ? (
          <CircularProgress
            size={18}
            sx={(theme) => ({
              color: theme.palette.primary.alpha50,
            })}
          />
        ) : (
          <CallEndIcon
            sx={(theme) => ({
              width: 18,
              height: 18,
              color: theme.background.bgWhite,
            })}
          />
        )}
      </StyledIconButton>
    </Box>
  </Tooltip>
);

export default CallControlsPanel;
