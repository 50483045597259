import { ButtonProps } from '@mui/material';
import {
  iconButtonLStyle,
  iconButtonMStyle,
  iconButtonRounded,
  iconButtonSStyle,
  iconButtonXlStyle,
  iconButtonXSSStyle,
  iconButtonXSStyle,
} from './iconButtonStyles';
import { DashboardThemeType } from '../../LiveBoardTheme/themeType';
import {
  ButtonColorTypes,
  ButtonShapeTypes,
  ButtonSizeTypes,
} from '../Button/buttonTypes';
import generateButtonStyle from '../Button/generateButtonStyle';

const iconButtonThemeConfig = {
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
      color: ButtonColorTypes.PRIMARY,
    },
    variants: [
      {
        props: { size: ButtonSizeTypes.XL },
        style: {
          ...iconButtonXlStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.L },
        style: {
          ...iconButtonLStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.M },
        style: {
          ...iconButtonMStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.S },
        style: {
          ...iconButtonSStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.XS },
        style: {
          ...iconButtonXSStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.XXS },
        style: {
          ...iconButtonXSSStyle,
        },
      },
      {
        props: { shape: ButtonShapeTypes.ROUNDED },
        style: {
          ...iconButtonRounded,
        },
      },
    ],
    styleOverrides: {
      root: ({
        ownerState,
        theme,
      }: {
        ownerState: ButtonProps;
        theme: DashboardThemeType;
      }) => ({
        textTransform: 'none',
        letterSpacing: 'unset',
        boxShadow: 'none',
        webkitTransition: 'none',
        verticalAlign: 'unset',
        position: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '70px',
        border: '2px solid',
        ...generateButtonStyle(ownerState, theme),
      }),
    },
  },
};

export default iconButtonThemeConfig;
