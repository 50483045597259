import { FC } from 'react';
import Divider from '@mui/material/Divider';

type ToolbarDividerProps = {
  gap?: number;
};

const ToolbarDivider: FC<React.PropsWithChildren<ToolbarDividerProps>> = ({
  gap = 6,
}) => (
  <Divider
    orientation="vertical"
    flexItem
    sx={{
      height: '30px',
      alignSelf: 'center',
      marginLeft: `${gap}px`,
      marginRight: `${gap}px`,
    }}
  />
);

export default ToolbarDivider;
