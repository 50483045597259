import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ForgotPasswordForm from './ForgotPasswordForm';
import Container from '../components/Container';
import AuthCover from '../components/AuthCover';

const CustomTypography = styled(Typography)(() => ({
  fontWeight: 600,
  lineHeight: '21.6px',
}));

const ForgotPassword = () => (
  <Box
    sx={(t) => ({
      display: 'flex',
      height: '100%',
      backgroundColor: t.background.bg2,
    })}
  >
    <Container>
      <Box
        sx={{
          maxWidth: '360px',
          width: '100%',
        }}
      >
        <Box mb={1.75}>
          <Typography
            align="center"
            variant="p1"
            sx={{
              fontWeight: 600,
            }}
          >
            Enter your email to reset your password
          </Typography>
        </Box>
        <ForgotPasswordForm />
        <NavLink
          to="/sign-in"
          style={{
            textAlign: 'center',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          <Box mt={3}>
            <CustomTypography color="primary" align="center">
              Back to Sign in
            </CustomTypography>
          </Box>
        </NavLink>
      </Box>
    </Container>
    <AuthCover />
  </Box>
);

export default ForgotPassword;
