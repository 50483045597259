import AspectRatioResizer from './AspectRatioResizer';

export default class CircleAspectRatioResizer extends AspectRatioResizer {
  onResize(pos) {
    const { startPoint, endPoint } = super.onResize(pos);

    const radius = (endPoint.x - startPoint.x) / Math.sqrt(2);
    return {
      startPoint,
      endPoint: {
        x: startPoint.x + radius,
        y: startPoint.y + radius,
      },
    };
  }
}
