import { FC } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import InfiniteBoard from '../../../components/Icons/InfiniteBoard';

type Props = {
  open: boolean;
  onClose: () => void;
};

const InfiniteBoardModal: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      className="bambourgh-wrapper"
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      open={open}
      disableRestoreFocus
      PaperProps={{
        sx: {
          backgroundColor: '#ffffff',
        },
      }}
    >
      <DialogTitle>
        <Typography>Infinite board</Typography>
        <IconButton
          onClick={onClose}
          size="small"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(2.5),
            top: theme.spacing(1.5),
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mb={4.5}>
          <InfiniteBoard
            color="primary"
            sx={{
              width: 64,
              height: 64,
            }}
          />
          <Box mt={1}>
            <Typography fontWeight={700} fontSize="1.125rem">
              Enjoy limitless space when liveboarding!
            </Typography>
          </Box>
        </Box>
        <Typography
          color="#696969"
          fontWeight={400}
          sx={{
            whiteSpace: 'pre-line',
          }}
        >
          {`This is the Beta version of the Infinite Size board (only for Web app) with a limited toolset.

            Sharing, recording and text insertion features will be added as soon as possible.`}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default InfiniteBoardModal;
