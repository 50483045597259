import StorageFallback from '@inconcept-labs/storage-fallback';

const storage = new StorageFallback();

export const sessionStorage = new StorageFallback(
  ['sessionStorage', 'cookieStorage', 'memoryStorage'],
  ''
);

export const liveBoardStorage = new StorageFallback(
  ['localStorage', 'sessionStorage', 'cookieStorage', 'memoryStorage'],
  'liveboard:'
);

export const deviceStorage = new StorageFallback(
  ['localStorage', 'sessionStorage', 'cookieStorage', 'memoryStorage'],
  'device:'
);

export default storage;
