export const calculatorConfiguration = {
  images: false,
  folders: false,
  notes: false,
  pasteGraphLink: false,
  brailleControls: false,
  brailleMode: 'none',
  sliders: true,
  distributions: true,
  showResetButtonOnGraphpaper: true,
} as const;

export const hiddenCalculatorConfiguration = {
  ...calculatorConfiguration,
  expressions: false,
  autosize: false,
} as const;

export const screenshotOptions = {
  width: 1024,
  height: 1024,
} as const;
