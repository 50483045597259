import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '../../ui/atoms/Typography';

type PopoverContentProps = {
  title: string;
  description: string;
};

const PopoverContent: FC<React.PropsWithChildren<PopoverContentProps>> = ({
  title,
  description,
}) => (
  <Box>
    <Typography variant="s1" sx={{ mb: '12px' }}>
      {title}
    </Typography>
    <Typography variant="p4">{description}</Typography>
  </Box>
);

export default PopoverContent;
