import {
  BaseTexture,
  BitmapText,
  DisplayObject,
  Graphics,
  ImageResource,
  Rectangle as PixiRectangle,
  Sprite,
  SVGResource,
  Texture,
} from 'pixi.js';

import Action from './action';
import Arrow from './arrow';
import Circle from './circle';
import DrawingAction from './drawingAction';
import Formula from './formula';
import FreeLine from './freeLine';
import Image from './image';
import Graph from './graph';
import Line from './line';
import Oval from './oval';
import PathShape from './pathShape';
import Rectangle from './rectangle';
import Shape from './shape';
import Text from './text';
import Triangle from './triangle';
import ToolNames from '../../DrawingTools/ToolNames';
import OldDrawingAction from '../../../common/drawingActions/drawing.action';
import ArrowDrawingAction from '../../../common/drawingActions/shapes/arrow.drawing.action';
import CircleDrawingAction from '../../../common/drawingActions/shapes/circle.drawing.action';
import FormulaDrawingAction from '../../../common/drawingActions/formula.drawing.action';
import FreeV3DrawingAction from '../../../common/drawingActions/free.drawing.v3.action';
import ImageDrawingAction from '../../../common/drawingActions/image.drawing.action';
import GraphDrawingAction from '../../../common/drawingActions/graph.drawing.action';
import LineDrawingAction from '../../../common/drawingActions/shapes/line.drawing.action';
import OvalDrawingAction from '../../../common/drawingActions/shapes/oval.drawing.action';
import PathShapeDrawingAction from '../../../common/drawingActions/shapes/pathShape.drawing.action';
import RectangleDrawingAction from '../../../common/drawingActions/shapes/rectangle.drawing.action';
import TextDrawingAction from '../../../common/drawingActions/text.drawing.action';
import TriangleDrawingAction from '../../../common/drawingActions/shapes/triangle.drawing.action';
import { parsePathData } from '../../../common/utils/pathData.utils';
import SelectedTool from '../types/SelectedTool';
import Point from '../types/Point';
import ActionName from '../types/ActionName';

export default {
  Action,
  Arrow,
  Circle,
  DrawingAction,
  FreeLine,
  Line,
  Oval,
  PathShape,
  Rectangle,
  Shape,
  Triangle,
};

const createActionFromData = (actionData: OldDrawingAction) => {
  switch (actionData.name) {
    case ActionName.ARROW: {
      return new Arrow(actionData as ArrowDrawingAction);
    }
    case ActionName.CIRCLE: {
      return new Circle(actionData as CircleDrawingAction);
    }
    case ActionName.FORMULA: {
      return new Formula(actionData as FormulaDrawingAction);
    }
    case ActionName.FREE_LINE_V1:
    case ActionName.FREE_LINE_V2:
    case ActionName.FREE_LINE_V3: {
      return new FreeLine(actionData as FreeV3DrawingAction);
    }
    case ActionName.IMAGE: {
      return new Image(actionData as ImageDrawingAction);
    }
    case ActionName.GRAPH: {
      return new Graph(actionData as GraphDrawingAction);
    }
    case ActionName.LINE: {
      return new Line(actionData as LineDrawingAction);
    }
    case ActionName.OVAL: {
      return new Oval(actionData as OvalDrawingAction);
    }
    case ActionName.PATH_SHAPE: {
      return new PathShape(actionData as PathShapeDrawingAction);
    }
    case ActionName.RECTANGLE: {
      return new Rectangle(actionData as RectangleDrawingAction);
    }
    case ActionName.TEXT: {
      return new Text(actionData as TextDrawingAction);
    }
    case ActionName.TRIANGLE: {
      return new Triangle(actionData as TriangleDrawingAction);
    }
    default: {
      return new DrawingAction(actionData);
    }
  }
};

const createAction = (
  startPos: Point,
  endPos: Point,
  selectedTool: SelectedTool,
  pathData?: string
) => {
  // check selectedTool.id first to see if it's a path shape
  switch (selectedTool.name) {
    case ToolNames.Arrow: {
      const arrowAction = new Arrow();
      arrowAction.startPoint = startPos;
      arrowAction.endPoint = endPos;
      arrowAction.paint.color = selectedTool.color;
      arrowAction.paint.strokeWidth = selectedTool.size.value;

      return arrowAction;
    }
    case ToolNames.Circle: {
      const circleAction = new Circle();
      circleAction.startPoint = startPos;
      circleAction.endPoint = endPos;
      circleAction.paint.color = selectedTool.color;
      circleAction.paint.strokeWidth = selectedTool.size.value;
      circleAction.paint.fill = selectedTool.fill || false;

      return circleAction;
    }
    case ToolNames.Formula: {
      const formulaAction = new Formula();
      formulaAction.startPoint = startPos;
      formulaAction.endPoint = endPos;

      return formulaAction;
    }
    case ToolNames.Graph: {
      const graphAction = new Graph();
      graphAction.startPoint = startPos;
      graphAction.endPoint = endPos;

      return graphAction;
    }
    case ToolNames.Image: {
      const imageAction = new Image();
      imageAction.startPoint = startPos;
      imageAction.endPoint = endPos;

      return imageAction;
    }
    case ToolNames.Line: {
      const lineAction = new Line();
      lineAction.startPoint = startPos;
      lineAction.endPoint = endPos;
      lineAction.paint.color = selectedTool.color;
      lineAction.paint.strokeWidth = selectedTool.size.value;

      return lineAction;
    }
    case ToolNames.Marker: {
      const penAction = new FreeLine();
      penAction.paint.color = selectedTool.color;
      penAction.paint.strokeWidth = selectedTool.size.value;
      penAction.paint.fill = selectedTool.fill || false;

      return penAction;
    }
    case ToolNames.Oval: {
      const ovalAction = new Oval();
      ovalAction.startPoint = startPos;
      ovalAction.endPoint = endPos;
      ovalAction.paint.color = selectedTool.color;
      ovalAction.paint.strokeWidth = selectedTool.size.value;
      ovalAction.paint.fill = selectedTool.fill || false;

      return ovalAction;
    }
    case 'pathShape': {
      const pathShapeAction = new PathShape();
      pathShapeAction.startPoint = startPos;
      pathShapeAction.endPoint = endPos;
      pathShapeAction.paint.color = selectedTool.color;
      pathShapeAction.paint.strokeWidth = selectedTool.size.value;
      pathShapeAction.paint.fill = selectedTool.fill || false;
      pathShapeAction.pathData = pathData || '';
      pathShapeAction.drawingData = parsePathData(pathData);
      pathShapeAction.viewBoxWidth = selectedTool.viewBoxWidth || 64;
      pathShapeAction.viewBoxHeight = selectedTool.viewBoxHeight || 64;

      return pathShapeAction;
    }
    case ToolNames.Pen: {
      const penAction = new FreeLine();
      penAction.paint.color = selectedTool.color;
      penAction.paint.strokeWidth = selectedTool.size.value;
      penAction.paint.fill = selectedTool.fill || false;

      return penAction;
    }
    case ToolNames.Rectangle: {
      const rectangleAction = new Rectangle();
      rectangleAction.startPoint = startPos;
      rectangleAction.endPoint = endPos;
      rectangleAction.paint.color = selectedTool.color;
      rectangleAction.paint.strokeWidth = selectedTool.size.value;
      rectangleAction.paint.fill = selectedTool.fill || false;

      return rectangleAction;
    }
    case ToolNames.Text: {
      const textAction = new Text();
      textAction.startPoint = startPos;
      textAction.endPoint = endPos;
      textAction.paint.color = selectedTool.color;
      textAction.paint.textSize = selectedTool.size.value;

      return textAction;
    }
    case ToolNames.Triangle: {
      const triangleAction = new Triangle();
      triangleAction.startPoint = startPos;
      triangleAction.endPoint = endPos;
      triangleAction.paint.color = selectedTool.color;
      triangleAction.paint.strokeWidth = selectedTool.size.value;
      triangleAction.paint.fill = selectedTool.fill || false;

      return triangleAction;
    }
    default: {
      return new DrawingAction();
    }
  }
};

const createPixiObject = (action: DrawingAction): DisplayObject => {
  switch (action.name) {
    case ActionName.FREE_LINE_V1:
    case ActionName.FREE_LINE_V2:
    case ActionName.FREE_LINE_V3:
    case ActionName.ARROW:
    case ActionName.CIRCLE:
    case ActionName.LINE:
    case ActionName.RECTANGLE:
    case ActionName.OVAL:
    case ActionName.PATH_SHAPE:
    case ActionName.TRIANGLE: {
      return new Graphics();
    }
    case ActionName.FORMULA: {
      const formulaAction = action as Formula;

      if (!formulaAction.image) {
        const sprite = new Sprite();
        sprite.zIndex = -500;

        return sprite;
      }

      const width = formulaAction.endPoint.x - formulaAction.startPoint.x;
      const height = formulaAction.endPoint.y - formulaAction.startPoint.y;

      const resource = new SVGResource(formulaAction.image?.src, {
        width: width * 4,
        height: height * 4,
      });
      const baseTexture = new BaseTexture(resource, {
        width,
        height,
      });

      const texture = new Texture(
        baseTexture,
        new PixiRectangle(0, 0, Math.floor(width), Math.floor(height))
      );

      const sprite = Sprite.from(texture);
      sprite.zIndex = -500;

      return sprite;
    }
    case ActionName.GRAPH: {
      const graphAction = action as Graph;

      const sprite = graphAction.image
        ? Sprite.from(graphAction.image)
        : new Sprite();
      sprite.zIndex = -500;

      return sprite;
    }
    case ActionName.IMAGE: {
      const imageAction = action as Image;

      if (imageAction.image) {
        const width = imageAction.endPoint.x - imageAction.startPoint.x;
        const height = imageAction.endPoint.y - imageAction.startPoint.y;

        const resource = new ImageResource(imageAction.image);
        const baseTexture = new BaseTexture(resource);
        baseTexture.width = width;
        baseTexture.height = height;

        const sprite = Sprite.from(baseTexture);
        sprite.zIndex = -500;

        return sprite;
      }

      if (imageAction.imageUrl) {
        const width = imageAction.endPoint.x - imageAction.startPoint.x;
        const height = imageAction.endPoint.y - imageAction.startPoint.y;

        const resource = new ImageResource(imageAction.imageUrl);
        const baseTexture = new BaseTexture(resource);
        baseTexture.on('loaded', () => {
          baseTexture.width = width;
          baseTexture.height = height;
        });

        const sprite = Sprite.from(baseTexture);
        sprite.zIndex = -500;

        return sprite;
      }

      const sprite = new Sprite();
      sprite.zIndex = -500;

      return sprite;
    }
    case ActionName.TEXT: {
      return new BitmapText('');
    }
    default: {
      return new Graphics();
    }
  }
};

export { createAction, createActionFromData, createPixiObject };
