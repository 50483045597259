import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import authService from '../../../../../../common/services/auth.service';
import { SectionCard } from '../../../../components/SectionCard';
import InputSize from '../../../../../../ui/atoms/Input/InputSize';
import Button from '../../../../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import PasswordField from '../../../../../../components/Forms/PasswordField';
import RepeatedPasswordField from '../../../../../../components/Forms/RepeatedPasswordField';
import passwordSchema, {
  PasswordSchemaType,
} from '../../../../../../common/utils/passwordSchema';

export const ChangePassword: FC<React.PropsWithChildren<unknown>> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setError,
  } = useForm<PasswordSchemaType>({
    resolver: zodResolver(passwordSchema),
  });

  const onSubmit = useCallback(
    handleSubmit(async ({ password, repeatedPassword }) => {
      try {
        await authService.updatePassword({ password, repeatedPassword });
        enqueueSnackbar('Password has been changed', {
          variant: 'success',
        });
        reset({
          password: '',
          repeatedPassword: '',
        });
      } catch (error) {
        if (error.fields) {
          // Todo: change any type
          Object.entries(error.fields).forEach(([key, value]: any) => {
            setError(key, {
              message: value.message,
            });
          });

          return;
        }

        enqueueSnackbar('Password save has failed', {
          variant: 'error',
        });
      }
    }),
    [enqueueSnackbar, setError, reset]
  );

  return (
    <SectionCard title="Password">
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
          },
        })}
        component="form"
        onSubmit={onSubmit}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: '16px',
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          <Box maxWidth="320px" flex={1}>
            <PasswordField control={control} size={InputSize.S} />
          </Box>
          <Box maxWidth="320px" flex={1}>
            <RepeatedPasswordField control={control} size={InputSize.S} />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            paddingTop: '20px',
            [theme.breakpoints.up('sm')]: {
              paddingTop: '25px',
            },
          })}
        >
          <Button
            type="submit"
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.S}
            loading={isSubmitting}
          >
            Change password
          </Button>
        </Box>
      </Box>
    </SectionCard>
  );
};
