import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import Menu from '../../../ui/molecules/Menu';
import IconButton from '../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import * as plansPermissionsSelectors from '../../../common/selectors/planPermissionSelectors';
import fireBaseService from '../../../common/services/firebaseService';
import * as participantsReducer from '../../../common/reducers/session/participantsReducer';
import useDialog from '../../../common/hooks/useDialog';
import PlanDialog from '../../../ui/molecules/PlanDialog';
import useUsersPresence from './useUsersPresence';
import UserPresence from './UserPresence';
import ChevronLeftIcon from '../../../ui/atoms/Icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../ui/atoms/Icons/ChevronRightIcon';
import { userSelector } from '../../../common/reducers/userReducer';
import useHostPresence from './useHostPresence';
import analyticsService from '../../../common/services/analytics.service';
import { setCollaborationBarSize } from '../../../common/actions/uiActions';
import Tooltip from '../../../ui/atoms/Tooltip';
import CollaborationTools from '../../BoardControls/CollaborationTools/CollaborationTools';
import { createSessionSelector } from '../../../common/reducers/session';
import { collaborationBarWidthSelector } from '../../../common/reducers/uiReducer';
import BoardNameEditBlock from '../../BoardControls/BoardNameEditBlock';
import * as sessionReducer from '../../../common/reducers/session/sessionReducer';
import SettingsOutlineIcon from '../../../ui/atoms/Icons/SettingsOutlineIcon';
import * as participantsActions from '../../../common/actions/session/participantsActions';
import {
  invitedParticipantsSelector,
  participantSelector,
} from '../../../common/reducers/session/participantsReducer';
import sessionService from '../../../common/services/session.service';
import ParticipantDto from '../../../common/services/dto/firebase/ParticipantDto';
import BoardBackgroundTypeDialog from '../../../ui/molecules/BoardBackgroundTypeDialog';
import MenuItem from '../../../ui/atoms/MenuItem';
import PencilOffOutlineIcon from '../../../ui/atoms/Icons/PencilOffOutlineIcon';
import PencilOutlineIcon from '../../../ui/atoms/Icons/PencilOutlineIcon';
import ChatOutlineOffIcon from '../../../ui/atoms/Icons/ChatOutlineOffIcon';
import ChatOutlineIcon from '../../../ui/atoms/Icons/ChatOutlineIcon';
import FileVisibleIcon from '../../../ui/atoms/Icons/FileVisibleIcon';
import FileOffIcon from '../../../ui/atoms/Icons/FileOffIcon';
import useSession from '../../../common/hooks/useSession';
import SessionContext from '../../Session/SessionContext';
import HotkeysDialog from '../../../containers/Modals/HotkeysDialog';
import FinishSessionDialog from '../../../ui/molecules/FinishSessionDialog';
import UpgradeDialog from '../../../ui/molecules/UpgradeDialog';
import DownloadPdfIcon from '../../../ui/atoms/Icons/DownloadPdfIcon';
import KeyboardIcon from '../../../ui/atoms/Icons/KeyboardIcon';
import SupportMailLink from '../../SupportMailLink';
import ChatQuestionOutlineIcon from '../../../ui/atoms/Icons/ChatQuestionOutlineIcon';
import FileLockIcon from '../../../ui/atoms/Icons/FileLockIcon';
import { validation } from '../../../ui/atoms/Colors/colorStyles';
import FileUnlockIcon from '../../../ui/atoms/Icons/FileUnlockIcon';
import ContentCopyIcon from '../../../ui/atoms/Icons/ContentCopyIcon';
import BoardType from '../../../common/services/types/session/BoardType';
import ShareOutlineIcon from '../../../ui/atoms/Icons/ShareOutlineIcon';
import AccountType from '../../../common/services/types/auth/AccountType';
import { boardTypeSelector } from '../../../common/reducers/session/sessionReducer';
import sessionsService from '../../../common/services/session.service';
import { setOpenSource } from '../../../common/actions/session/sessionActions';
import { canExportPdfSelector } from '../../../common/selectors/planPermissionSelectors';
import ExportPdfDialog from '../../../ui/molecules/ExportPdfDialog/ExportPdfDialog';
import FileConfigurationOutlineIcon from '../../../ui/atoms/Icons/FileConfigurationOutlineIcon';
import JoinedParticipants from './JoinedParticipants';
import InvitePeopleDialog from '../../../ui/molecules/InvitePeopleDialog';
import InviteButton from './InviteButton';
import { collapsedParticipantsBarWidth } from '../../../common/constants/boardToolbarsSizes';

type ParticpantsBarProps = {
  sessionId: string;
  chat: boolean;
  pageNavigation: boolean;
  showSettings: boolean;
};

type UserPresenceProps = {
  id: string;
  name: string;
  statusColor: string;
  statusText: string;
  profileImageUrl: string;
  hasDrawingPermission: boolean;
};

const drawerExpandedWidth = 320;

const drawerStyles = (isCollaborationBarOpened: boolean, theme: Theme) => {
  if (isCollaborationBarOpened) {
    return {
      width: `${drawerExpandedWidth}px`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    };
  }

  return {
    width: `${collapsedParticipantsBarWidth}px`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };
};

type CollapseButtonProps = {
  open: boolean;
  collaborationBarWidth: number;
  onClick: () => void;
};

const CollapseButton: FC<React.PropsWithChildren<CollapseButtonProps>> = ({
  open,
  collaborationBarWidth,
  onClick,
}) => (
  <Box
    onClick={onClick}
    sx={(theme) => ({
      position: 'fixed',
      bottom: 0,
      backgroundColor: theme.background.bg1,
      width: 36,
      height: 56,
      borderRadius: '14px 0px 0px 14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderLeft: `1px solid ${theme.background.bg3}`,
      right: collaborationBarWidth,
      zIndex: 1000,
      cursor: 'pointer',
    })}
  >
    {open ? (
      <ChevronRightIcon
        sx={{
          width: 18,
          height: 18,
        }}
      />
    ) : (
      <ChevronLeftIcon
        sx={{
          width: 18,
          height: 18,
        }}
      />
    )}
  </Box>
);

const MenuItemDivider = () => (
  <Divider
    sx={{
      mt: 1,
      mb: 1,
    }}
  />
);

const ParticipantsBar: FC<React.PropsWithChildren<ParticpantsBarProps>> = ({
  sessionId,
  chat,
  pageNavigation,
  showSettings,
}) => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const menuOpen = Boolean(anchorEl);
  const canChangeDrawingPermission = useSelector(
    plansPermissionsSelectors.canManageDrawingAccessSelector
  );
  const joinedParticipants = useSelector(
    participantsReducer.participantsWithoutHostSelector
  );
  const invitedParticipants = useSelector(invitedParticipantsSelector);
  const user = useSelector(userSelector);
  const sessionSelector = useMemo(() => createSessionSelector(), []);
  const session = useSelector(sessionSelector);
  const collaborationBarWidth = useSelector(collaborationBarWidthSelector);
  const groupId = useSelector(sessionReducer.groupIdSelector);
  const boardType = useSelector(boardTypeSelector);

  const { enqueueSnackbar } = useSnackbar();
  const { finishSession } = useSession();
  const { shareSession, reshareSession } = useContext(SessionContext);

  const participantHostSelector = useMemo(
    () => participantSelector(user.id),
    [user.id]
  );

  const participantHost = useSelector(participantHostSelector);
  const isHost = useSelector(sessionReducer.isCurrentUserHostSelector);
  const canExportPdf = useSelector(canExportPdfSelector);

  const hostPresenceData = useHostPresence(participantHost);

  const { joined, invited } = useUsersPresence({
    joined: joinedParticipants,
    invited: invitedParticipants,
  });

  const dispatch = useDispatch();
  const collaborationBarRef = useRef<HTMLDivElement | null>(null);
  const boardIsFinished = useMemo(() => session.finished, [session.finished]);
  const boardIsShared = useMemo(() => session.shared, [session.shared]);
  const isConnectedSession = useMemo(
    () => session.connected,
    [session.connected]
  );
  const showChatTool = useMemo(
    () => chat && isConnectedSession,
    [chat, isConnectedSession]
  );
  const callEnabled = useMemo(() => session.callEnabled, [session.callEnabled]);
  const showRecordingTools = useMemo(
    () => session.localRecordingEnabled,
    [session.localRecordingEnabled]
  );
  const isLive = useMemo(
    () => boardIsShared && !boardIsFinished,
    [boardIsShared, boardIsFinished]
  );
  const isCollaborationBarOpened = useMemo(
    () => open && isHost && !boardIsFinished,
    [open, boardIsFinished, isHost]
  );

  const {
    open: plansModalOpen,
    onOpen: onPlansModalOpen,
    onClose: onPlansModalClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const {
    open: backgroundTypeDialogOpen,
    onOpen: onBackgroundTypeDialogOpen,
    onClose: onBackgroundTypeDialogClose,
  } = useDialog();

  const {
    open: exportPdfDialogOpen,
    onOpen: onExportPdfDialogOpen,
    onClose: onExportPdfDialogClose,
  } = useDialog();

  const {
    open: leaveSessionDialogOpen,
    onOpen: onLeaveSessionDialogOpen,
    onClose: onLeaveSessionDialogClose,
  } = useDialog();

  const {
    open: hotkeysDialogOpen,
    onOpen: onHotkeysDialogOpen,
    onClose: onHotkeysDialogClose,
  } = useDialog();

  const {
    open: upgradeDialog,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const {
    open: invitePeopleDialogOpen,
    onOpen: onInvitePeopleDialogOpen,
    onClose: onInvitePeopleDialogClose,
  } = useDialog();

  useEffect(() => {
    dispatch(
      setCollaborationBarSize({
        width: isCollaborationBarOpened
          ? drawerExpandedWidth
          : collapsedParticipantsBarWidth,
      })
    );
  }, [isCollaborationBarOpened, setCollaborationBarSize, dispatch]);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);

    if (!open) {
      analyticsService.event('Collaboration Bar Expand Click');

      return;
    }

    analyticsService.event('Collaboration Bar Collapse Click');
  }, [open, setOpen]);

  const onMenuButtonClick = useCallback(
    (event: any) => {
      analyticsService.event('Collaboration Bar Settings Button Click');
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const showCollaborationTools = useMemo(
    () =>
      (isConnectedSession && !boardIsFinished) ||
      (boardIsShared && boardIsFinished),
    [isConnectedSession, boardIsFinished, boardIsShared]
  );

  const anyParticipantDrawingPermissionEnabled = useMemo(
    () =>
      joinedParticipants.some(
        (particpant: ParticipantDto) => particpant.drawingEnabled
      ),
    [joinedParticipants]
  );

  const setDrawingEnabled = useCallback(
    (id: any, value: any) => {
      dispatch(participantsActions.setDrawingEnabled(id, value));
    },
    [dispatch]
  );

  const updateDrawingPermission = useCallback(
    (participantId: any, newDrawingPermission: any) => {
      const changeDrawingPermission = async () => {
        await fireBaseService.setDrawingEnabledForParticipant(
          participantId,
          newDrawingPermission
        );
        setDrawingEnabled(participantId, newDrawingPermission);
      };

      changeDrawingPermission();
    },
    [setDrawingEnabled]
  );

  const updateAllParticipantsDrawingPermission = useCallback(() => {
    const changeDrawingPermissions = async () => {
      analyticsService.event(
        !anyParticipantDrawingPermissionEnabled
          ? 'Enable drawing permission for all'
          : 'Disable drawing permission for all',
        {
          source: 'More Menu',
        }
      );

      await sessionService.updateDrawingEnabledOnJoinOption(
        sessionId,
        !anyParticipantDrawingPermissionEnabled
      );

      for await (const participant of joinedParticipants) {
        await fireBaseService.setDrawingEnabledForParticipant(
          participant.id,
          !anyParticipantDrawingPermissionEnabled
        );
        setDrawingEnabled(
          participant.id,
          !anyParticipantDrawingPermissionEnabled
        );
      }
    };

    changeDrawingPermissions();
  }, [
    joinedParticipants,
    setDrawingEnabled,
    anyParticipantDrawingPermissionEnabled,
  ]);

  const drawingPermissionBatchUpdate = useCallback(async () => {
    onMenuClose();

    if (!canChangeDrawingPermission) {
      onPlansModalOpen();
      return;
    }

    updateAllParticipantsDrawingPermission();
  }, [
    updateAllParticipantsDrawingPermission,
    canChangeDrawingPermission,
    onPlansModalOpen,
    onMenuClose,
  ]);

  const updateChatOption = useCallback(() => {
    const updateChatCall = async () => {
      await sessionService.updateChatOption(sessionId, !chat);
    };

    updateChatCall();
  }, [sessionId, chat]);

  const onChatClick = useCallback(() => {
    onMenuClose();
    updateChatOption();

    if (chat) {
      analyticsService.event('Disable Chat Click');

      return;
    }

    analyticsService.event('Enable Chat Click');
  }, [chat, onMenuClose, updateChatOption]);

  const onPageNavigationClick = useCallback(() => {
    onMenuClose();
    analyticsService.event(
      !pageNavigation ? 'Page Navigation Enabled' : 'Page Navigation Disabled',
      {
        source: 'More Menu',
      }
    );

    const updatePageNavigation = async () => {
      await sessionService.updatePageNavigationOption(
        sessionId,
        !pageNavigation
      );
    };

    updatePageNavigation();
  }, [dispatch, pageNavigation]);

  const onBackgroundSettingsClick = useCallback(() => {
    analyticsService.event('Open Page Settings Click');

    onBackgroundTypeDialogOpen();
    onMenuClose();
  }, [onBackgroundTypeDialogOpen, onMenuClose]);

  const navigateTo = useCallback(
    (path: any) => {
      dispatch(push(path));
    },
    [dispatch]
  );

  const leaveSession = useCallback(() => {
    if (groupId) {
      navigateTo(`/group/${groupId}/sessions`);
      return;
    }

    navigateTo('/group/default/sessions');
  }, [navigateTo, groupId]);

  const onFinishSuccess = useCallback(async () => {
    if (isHost) {
      await finishSession(sessionId);

      return;
    }

    leaveSession();
  }, [sessionId, isHost, finishSession, leaveSession]);

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
  }, [onPlansDialogOpen, onUpgradeDialogClose]);

  const reshare = useCallback(async () => {
    try {
      analyticsService.event('Reshare Button Click');
      onMenuClose();
      await reshareSession();
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [reshareSession, enqueueSnackbar, onMenuClose]);

  const copyAndRedirect = useCallback(async () => {
    try {
      analyticsService.event('Board Duplicate Button Click');
      const newSession = await sessionsService.copySession(sessionId);
      dispatch(setOpenSource('Copy board'));
      dispatch(push(`/session/${newSession.id}`));
    } catch (error) {
      enqueueSnackbar(error.message, {
        autoHideDuration: 3000,
        variant: 'error',
      });

      if (error.code === '130005') {
        analyticsService.event('Create Board above Limit');
        enqueueSnackbar(
          'You have reached your 5-boards limit.\n Upgrade for unlimited boards.',
          {
            autoHideDuration: 3000,
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
            preventDuplicate: true,
          }
        );
      }
    }
  }, [enqueueSnackbar, dispatch, sessionId]);

  const onAskCustomerTeamClick = useCallback(() => {
    analyticsService.event('Ask Customer Support Button Click');
    onMenuClose();
  }, [onMenuClose]);

  const onExportPdfClick = useCallback(() => {
    analyticsService.event('Board Export PDF Click');

    if (canExportPdf) {
      onExportPdfDialogOpen();
      onMenuClose();

      return;
    }

    onUpgradeDialogOpen();
    onMenuClose();
  }, [canExportPdf, onUpgradeDialogOpen, onExportPdfDialogOpen, onMenuClose]);

  const onHotkeyClick = useCallback(() => {
    analyticsService.event('Hotkeys Dialog Open');
    onHotkeysDialogOpen();
    onMenuClose();
  }, [onHotkeysDialogOpen, onMenuClose]);

  const onFinishSessionClick = useCallback(() => {
    analyticsService.event('Finish Session Click');
    onMenuClose();
    onLeaveSessionDialogOpen();
  }, [onMenuClose, onLeaveSessionDialogOpen]);

  const onInvitePeopleClick = useCallback(() => {
    onInvitePeopleDialogOpen();
    analyticsService.event('Invite Button Click', {
      source: 'Collaboration',
    });
  }, [onInvitePeopleDialogOpen]);

  return (
    <>
      <Box
        sx={{
          gridArea: 'collaborationBar',
        }}
      >
        <ScopedCssBaseline>
          <Drawer
            variant="persistent"
            anchor="right"
            open
            PaperProps={{
              ref: collaborationBarRef,
              sx: (theme) => ({
                overflowX: 'hidden',
                backgroundColor: theme.background.bg1,
                borderLeft: `1px solid ${theme.background.bg3}`,
                ...drawerStyles(isCollaborationBarOpened, theme),
              }),
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: isCollaborationBarOpened
                  ? 'space-between'
                  : 'center',
                alignItems: 'center',
                borderBottom: `1px solid ${theme.background.bg3}`,
                padding: '8px 16px',
              })}
            >
              {isCollaborationBarOpened && <BoardNameEditBlock />}
              {showSettings &&
              user.accountType !== AccountType.Temporary &&
              (isHost || (!isHost && canExportPdf)) ? (
                <Tooltip title="Settings" placement="bottom">
                  <Box>
                    <IconButton
                      variant={ButtonVariantTypes.TEXT}
                      size={ButtonSizeTypes.S}
                      onClick={onMenuButtonClick}
                      shape={ButtonShapeTypes.ROUNDED}
                    >
                      <SettingsOutlineIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                <Box width={40} height={40} />
              )}
              <Menu
                listItemWidth={230}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={onMenuClose}
              >
                {boardIsFinished &&
                  isHost && [
                    <MenuItem
                      key="Reshare"
                      startIcon={
                        <FileUnlockIcon
                          sx={{
                            width: 18,
                            height: 18,
                          }}
                        />
                      }
                      title="Reshare"
                      onClick={reshare}
                      onSelect={reshare}
                    />,
                    <MenuItem
                      key="Duplicate"
                      startIcon={
                        <ContentCopyIcon
                          sx={{
                            width: 18,
                            height: 18,
                          }}
                        />
                      }
                      title="Duplicate"
                      onClick={copyAndRedirect}
                      onSelect={copyAndRedirect}
                    />,
                  ]}
                {isHost &&
                  !boardIsShared &&
                  boardType !== BoardType.INFINITE && [
                    <MenuItem
                      key="Share"
                      startIcon={
                        <ShareOutlineIcon
                          sx={{
                            width: 18,
                            height: 18,
                          }}
                        />
                      }
                      title="Share"
                      onClick={shareSession}
                      onSelect={shareSession}
                    />,
                  ]}
                {isHost &&
                  !boardIsFinished && [
                    <MenuItem
                      key="Page settings"
                      startIcon={<FileConfigurationOutlineIcon />}
                      title="Page settings"
                      onClick={onBackgroundSettingsClick}
                      onSelect={onBackgroundSettingsClick}
                    />,
                  ]}
                {(isHost || (!isHost && canExportPdf)) && [
                  <MenuItem
                    key="Export PDF"
                    startIcon={<DownloadPdfIcon />}
                    title="Export PDF"
                    onClick={onExportPdfClick}
                    onSelect={onExportPdfClick}
                  />,
                ]}
                {isHost &&
                  !boardIsFinished && [
                    <MenuItemDivider key="settings" />,
                    <MenuItem
                      key="Enable/Disable drawing"
                      startIcon={
                        anyParticipantDrawingPermissionEnabled ? (
                          <PencilOffOutlineIcon />
                        ) : (
                          <PencilOutlineIcon />
                        )
                      }
                      title={`${
                        anyParticipantDrawingPermissionEnabled
                          ? 'Disable'
                          : 'Enable'
                      } drawing for all`}
                      onClick={drawingPermissionBatchUpdate}
                      onSelect={drawingPermissionBatchUpdate}
                    />,
                    ...(boardIsShared && !boardIsFinished
                      ? [
                          <MenuItem
                            key="Enable/Disable chat"
                            startIcon={
                              chat ? (
                                <ChatOutlineOffIcon />
                              ) : (
                                <ChatOutlineIcon />
                              )
                            }
                            title={`${chat ? 'Disable' : 'Enable'} chat`}
                            onClick={onChatClick}
                            onSelect={onChatClick}
                          />,
                          <MenuItem
                            key="On/Off Follower mode"
                            startIcon={
                              pageNavigation ? (
                                <FileVisibleIcon />
                              ) : (
                                <FileOffIcon />
                              )
                            }
                            title={`${
                              pageNavigation ? 'Enable' : 'Disable'
                            } Follower mode`}
                            onClick={onPageNavigationClick}
                            onSelect={onPageNavigationClick}
                          />,
                        ]
                      : []),
                    <MenuItemDivider key="permissions" />,
                  ]}
                {isHost &&
                  !boardIsFinished && [
                    <MenuItem
                      key="Hotkeys"
                      startIcon={<KeyboardIcon />}
                      title="Hotkeys"
                      onSelect={onHotkeyClick}
                    />,
                    <SupportMailLink key="Ask Customer Team">
                      <MenuItem
                        startIcon={<ChatQuestionOutlineIcon />}
                        title="Ask support"
                        onSelect={onAskCustomerTeamClick}
                      />
                    </SupportMailLink>,
                  ]}
                {isHost && isLive && !boardIsFinished && (
                  <MenuItem
                    key="Finish Session"
                    startIcon={<FileLockIcon color="error" />}
                    title="Finish session"
                    onClick={onFinishSessionClick}
                    onSelect={onFinishSessionClick}
                    textPrimaryColor={validation.error.main}
                  />
                )}
              </Menu>
            </Box>
            <Box
              sx={(theme) => ({
                overflowY: 'auto',
                height: '100%',
                marginRight: '4px',
                padding: '0 16px',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.background.bg5,
                  borderRadius: '16px',
                },
              })}
            >
              {hostPresenceData && isHost && (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  mt={isCollaborationBarOpened ? 3 : 2}
                  pb={isCollaborationBarOpened ? 2.5 : 2}
                  mb={isCollaborationBarOpened ? 3 : 2}
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.background.bg3}`,
                  })}
                >
                  <UserPresence
                    key={hostPresenceData.id}
                    id={hostPresenceData.id}
                    name={hostPresenceData.name}
                    statusColor={hostPresenceData.statusColor}
                    statusText={hostPresenceData.statusText}
                    profileImageUrl={hostPresenceData.profileImageUrl}
                    collapsed={!isCollaborationBarOpened}
                    hasDrawingPermission={hostPresenceData.hasDrawingPermission}
                    updateDrawingPermission={updateDrawingPermission}
                    showPlansModal={onPlansModalOpen}
                    canChangeDrawingPermission={canChangeDrawingPermission}
                    userType="host"
                  />
                </Box>
              )}
              {isHost && (
                <JoinedParticipants
                  open={isCollaborationBarOpened}
                  joined={joined}
                  updateDrawingPermission={updateDrawingPermission}
                  onPlansModalOpen={onPlansModalOpen}
                />
              )}
              {isHost && (
                <Box
                  sx={{
                    marginBottom: 2.5,
                  }}
                >
                  {!!invited.length && isCollaborationBarOpened && (
                    <Typography
                      variant="p5"
                      sx={(theme) => ({
                        color: theme.text.t7,
                        mb: 2,
                        textAlign: isCollaborationBarOpened
                          ? 'initial'
                          : 'center',
                      })}
                    >
                      Invited
                      {open && (
                        <Typography
                          component="span"
                          variant="p5"
                          sx={(theme) => ({
                            color: theme.text.t7,
                          })}
                        >
                          {' '}
                          ({invited.length})
                        </Typography>
                      )}
                    </Typography>
                  )}
                  {!invited.length && !joined.length && !boardIsFinished && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      {open && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          mt={2.5}
                          mb={2.5}
                        >
                          <Typography variant="p6">
                            No students in the session
                          </Typography>
                        </Box>
                      )}
                      <Box display="flex" justifyContent="center">
                        <InviteButton
                          extended={open}
                          onClick={onInvitePeopleClick}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {!!invited.length && isHost && (
                <Box display="flex" flexDirection="column" gap={1.5} mb={3}>
                  {invited.map((participant: UserPresenceProps) => (
                    <UserPresence
                      key={participant.id}
                      id={participant.id}
                      name={participant.name}
                      profileImageUrl={participant.profileImageUrl}
                      collapsed={!isCollaborationBarOpened}
                      hasDrawingPermission={participant.hasDrawingPermission}
                      updateDrawingPermission={updateDrawingPermission}
                      showPlansModal={onPlansModalOpen}
                      canChangeDrawingPermission={canChangeDrawingPermission}
                      userType="invited"
                    />
                  ))}
                </Box>
              )}
            </Box>
            {showCollaborationTools && (
              <CollaborationTools
                key="collaboration-tools"
                boardIsFinished={boardIsFinished}
                showChatTool={showChatTool}
                showRecordingTools={showRecordingTools}
                showVoice={
                  callEnabled &&
                  isConnectedSession &&
                  boardIsShared &&
                  !boardIsFinished
                }
                showInviteTool={!!joined.length || !!invited.length}
                collaborationBarExpanded={isCollaborationBarOpened}
              />
            )}
          </Drawer>
        </ScopedCssBaseline>
      </Box>
      <PlanDialog
        source="Collaboration bar"
        open={plansModalOpen}
        onClose={onPlansModalClose}
      />
      <BoardBackgroundTypeDialog
        open={backgroundTypeDialogOpen}
        onSuccess={onBackgroundTypeDialogClose}
        onClose={onBackgroundTypeDialogClose}
      />
      <HotkeysDialog open={hotkeysDialogOpen} onClose={onHotkeysDialogClose} />
      <FinishSessionDialog
        open={leaveSessionDialogOpen}
        onClose={onLeaveSessionDialogClose}
        onSuccess={onFinishSuccess}
        isHost={isHost}
      />
      <UpgradeDialog
        open={upgradeDialog}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <ExportPdfDialog
        card={{
          id: sessionId,
        }}
        open={exportPdfDialogOpen}
        onCancel={onExportPdfDialogClose}
      />
      <PlanDialog
        source="Collaboration Bar"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
      <InvitePeopleDialog
        open={invitePeopleDialogOpen}
        onCancel={onInvitePeopleDialogClose}
      />
      {isHost && !boardIsFinished && (
        <CollapseButton
          open={open}
          collaborationBarWidth={collaborationBarWidth}
          onClick={toggleDrawer}
        />
      )}
    </>
  );
};

export default ParticipantsBar;
