import analyticsService from '../../common/services/analytics.service';
import { copyRecording, getGeneralConfiguration } from './configurationActions';
import recordingService from '../services/recording.service';

const GET_RECORDING_LIST_REQUEST = Symbol('GET_RECORDING_LIST_REQUEST');
const GET_RECORDING_LIST_SUCCESS = Symbol('GET_RECORDING_LIST_SUCCESS');
const GET_RECORDING_LIST_FAILURE = Symbol('GET_RECORDING_LIST_FAILURE');

const ADD_RECORDING_IN_LIST = Symbol('ADD_RECORDING_IN_LIST');

const UPDATE_RECORDING_IN_LIST = Symbol('UPDATE_RECORDING_IN_LIST');

const CHANGE_RECORDING_GROUP = Symbol('CHANGE_RECORDING_GROUP');

const COPY_RECORDING_REQUEST = Symbol('COPY_RECORDING_REQUEST');
const COPY_RECORDING_SUCCESS = Symbol('COPY_RECORDING_SUCCESS');
const COPY_RECORDING_FAILURE = Symbol('COPY_RECORDING_FAILURE');

const REMOVE_RECORDING = Symbol('REMOVE_RECORDING');

const CHANGE_RECORDING_IN_GROUP_REQUEST = Symbol('CHANGE_RECORDING_IN_GROUP_REQUEST');

const recordingListSuccess = (payload, groupId = 'default') => ({
  type: GET_RECORDING_LIST_SUCCESS,
  payload,
  groupId,
});

const recordingListRequest = () => ({
  type: GET_RECORDING_LIST_REQUEST,
});

const recordingListFailure = error => ({
  type: GET_RECORDING_LIST_FAILURE,
  error,
});

const getRecordings = (groupId) => (dispatch) => {
  dispatch(recordingListRequest());
  return recordingService.getRecordings({ groupId })
    .then((payload) => {
      dispatch(recordingListSuccess(payload, groupId));
      return payload;
    })
    .catch((error) => {
      dispatch(recordingListFailure(error));
      console.log('Error: ', error);
    });
};

const getRecordingList = ({
  groupId = 'default',
}) => (dispatch) => {
  dispatch(getRecordings(groupId));
};

const updateRecordingInList = recording => ({
  type: UPDATE_RECORDING_IN_LIST,
  payload: recording,
});

const copyRecordingRequest = () => ({
  type: COPY_RECORDING_REQUEST,
});

const changeRecordingInGroupRequest = (id, shared) => ({
  type: CHANGE_RECORDING_IN_GROUP_REQUEST,
  payload: {
    id,
    shared,
  },
});

const copyRecordingSuccess = (payload, groupId = 'default') => ({
  type: COPY_RECORDING_SUCCESS,
  payload,
  groupId,
});

const copyRecordingFailure = error => ({
  type: COPY_RECORDING_FAILURE,
  error,
});

const copyRecordingInList = (recordingId, onCopyLimitDialogOpen) => {
  return async (dispatch) => {
    dispatch(copyRecordingRequest());

    try {
      const data = await recordingService.copy(recordingId);
      dispatch(copyRecordingSuccess(data, data.groupId ? data.groupId : undefined));
      dispatch(copyRecording());
      return data;
    } catch (error) {
      dispatch(copyRecordingFailure(error));
      if (error.code === '130005') {
        analyticsService.event('Create Recording above Limit');
        onCopyLimitDialogOpen();
        dispatch(getGeneralConfiguration());
      }
      console.log('Error: ', error);
    }
  };
};

const removeRecording = recordingId => ({
  type: REMOVE_RECORDING,
  payload: {
    recordingId,
  },
});

export {
  getRecordingList,
  updateRecordingInList,
  copyRecordingInList,
  copyRecordingSuccess,
  copyRecordingFailure,
  removeRecording,
  changeRecordingInGroupRequest,
  GET_RECORDING_LIST_REQUEST,
  GET_RECORDING_LIST_SUCCESS,
  GET_RECORDING_LIST_FAILURE,
  ADD_RECORDING_IN_LIST,
  UPDATE_RECORDING_IN_LIST,
  COPY_RECORDING_REQUEST,
  COPY_RECORDING_SUCCESS,
  COPY_RECORDING_FAILURE,
  REMOVE_RECORDING,
  CHANGE_RECORDING_GROUP,
  CHANGE_RECORDING_IN_GROUP_REQUEST,
};
