import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BasePlacement } from '@popperjs/core/lib/enums';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ColorPicker from '../../../../../DrawingTools/ColorPicker';
import { updateDrawingTool } from '../../../../../../common/actions/board/drawingToolActions';
import {
  hexToRgb,
  rgbToArgbNumber,
} from '../../../../../../common/utils/color.utils';
import {
  getActiveTools,
  getSelectedDrawingTool,
} from '../../../../../../common/reducers/board/drawingToolReducer';
import { setShapeCurrentDrawing } from '../../../../../../common/actions/board/currentDrawingAction';
import { getCurrentDrawing } from '../../../../../../common/reducers/board/currentDrawingReducer';

const popperPadding = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const shapeToolbarElement = document.querySelector('#shape-toolbar');
const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      padding: popperPadding,
      fallbackPlacements: ['bottom'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 12],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      boundary: shapeToolbarElement,
      mainAxis: false,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

type ColorPickerProps = {
  open: boolean;
  anchorEl: {
    getBoundingClientRect: () => DOMRect;
  };
  shapeToolbarPlacement: BasePlacement | null;
  onChange: () => void;
};

const ShapeColorPopper: FC<React.PropsWithChildren<ColorPickerProps>> = ({
  open,
  anchorEl,
  onChange,
  shapeToolbarPlacement,
}) => {
  const dispatch = useDispatch();
  const activeTools = useSelector(getActiveTools);
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);

  const updateColor = useCallback(
    (color: string, fill: any) => {
      const { r, g, b } = hexToRgb(color);
      const argbColor = rgbToArgbNumber({
        a: 255,
        r,
        g,
        b,
      });

      dispatch(
        updateDrawingTool(activeTools.shape, { color: argbColor, fill })
      );
      if (currentDrawing) {
        dispatch(
          setShapeCurrentDrawing({
            ...currentDrawing,
            paint: {
              ...currentDrawing.paint,
              color: argbColor,
              fill,
            },
          })
        );
      }

      onChange();
    },
    [dispatch, activeTools.shape, currentDrawing, onChange]
  );

  const isSelectedToolFill = useMemo(
    () => currentDrawing?.paint.fill ?? selectedTool.fill,
    [selectedTool.fill, currentDrawing]
  );

  return (
    <Popper
      open={open}
      placement={shapeToolbarPlacement || 'top'}
      anchorEl={anchorEl}
      modifiers={popperModifiers}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="2px"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 2px 4px 0px #00000026',
          padding: '5px 8px',
          borderRadius: 4,
        }}
      >
        <ColorPicker
          key="outline"
          type="outline"
          isSelectedToolFill={!!isSelectedToolFill}
          onChange={updateColor}
        />
        {selectedTool.hasOwnProperty('fill') && (
          <ColorPicker
            key="fill"
            type="fill"
            isSelectedToolFill={!!isSelectedToolFill}
            onChange={updateColor}
          />
        )}
      </Box>
    </Popper>
  );
};

export default ShapeColorPopper;
