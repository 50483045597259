import storage, { deviceStorage } from '../../config/storage';
import axios from 'axios';
import guid from '../utils/guid.util';
import environment from '../../config/environment';

const onRequestFulfilled = (config) => {
  const token = storage.getItem('accessToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

const onRequestRejected = (error) => {
  console.error(error);
  return Promise.reject(error);
};

const onResponseFulfilled = (response) => {
  const { payload, error } = response.data;
  if (error) {
    console.error(error);
    return Promise.reject(error);
  }
  return payload;
};

const onResponseRejected = (errorObject) => {
  if (axios.isCancel(errorObject)) return Promise.reject(errorObject.message);

  const { error } = errorObject.response.data;
  return Promise.reject(error);
};

if (!deviceStorage.getItem('deviceId')) {
  deviceStorage.setItem('deviceId', guid());
}

const headers = {
  'Content-Type': 'application/json',
  'X-LiveBoard-DeviceId': deviceStorage.getItem('deviceId'),
  'X-LiveBoard-App-Version': environment.appVersion,
  'X-LiveBoard-App-Platform': 'web',
};

const instance = axios.create({
  baseURL: environment.apiUrl + '/v3',
  headers,
  withCredentials: true,
});

instance.interceptors.request.use(onRequestFulfilled, onRequestRejected);
instance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

const instanceV4 = axios.create({
  baseURL: environment.apiUrl + '/v4',
  headers,
  withCredentials: true,
});

instanceV4.interceptors.request.use(onRequestFulfilled, onRequestRejected);
instanceV4.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export { instance as httpService, instanceV4 as httpServiceV4 };
