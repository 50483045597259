import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import LiveKitContext from './LiveKitContext';
import * as userReducer from '../../../../../../common/reducers/userReducer';
import * as sessionReducer from '../../../../../../common/reducers/session/sessionReducer';
import useDialog from '../../../../../../common/hooks/useDialog';
import WebRtcNotSupportedDialog from '../../../../../../ui/molecules/WebRtcNotSupportedDialog';
import livekitService from '../../../../../../common/services/livekit.service';
import LiveKitPopup from '../../../../../LiveKitPopup';
import DeviceProvider from '../../../DeviceContext/DeviceProvider';
import { ConnectionState } from '../../../../Call/CallContext/defaultValue';

const LiveKitProvider = ({ children }) => {
  const user = useSelector(userReducer.userSelector);
  const sessionId = useSelector(sessionReducer.sessionIdSelector);
  const isHost = useSelector(sessionReducer.isCurrentUserHostSelector);
  const isConferenceInProgress = useSelector(
    sessionReducer.isConferenceInProgressSelector
  );
  const sessionMutedOnJoin = useSelector(sessionReducer.mutedOnJoinSelector);

  const [connectedToConference, setConnectedToConference] = useState(false);
  const [muted, setMuted] = useState(false);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [webRtcSupported, setWebRtcSupported] = useState(true);
  const [token, setToken] = useState('');
  const [deviceOptions, setDeviceOptions] = useState(null);
  const [connectionState, setConnectionState] = useState(
    ConnectionState.Disconnected
  );
  const [isScreenShared, setScreenShared] = useState(false);

  const {
    open: liveKitOpen,
    onOpen: onLiveKitOpen,
    onClose: onLiveKitClose,
  } = useDialog();

  const {
    open: notSupportedOpen,
    onOpen: onNotSupportedOpen,
    onClose: onNotSupportedClose,
  } = useDialog();

  const { enqueueSnackbar } = useSnackbar();
  // useEffect(() => {
  //   const { audio } = client.checkSystemRequirements();
  //   setWebRtcSupported(audio);
  // }, [setWebRtcSupported]);

  const onClose = useCallback(() => {
    onLiveKitClose();

    setConnectedToConference(false);
    setToken('');
  }, [onLiveKitClose, setConnectedToConference, setToken]);

  const disconnectFromConference = useCallback(async () => {
    try {
      if (isHost) {
        await livekitService.stopConference(sessionId);
      }

      onLiveKitClose();

      setConnectedToConference(false);
      setToken('');
    } catch (error) {
      enqueueSnackbar('Error occurred, please try again', {
        variant: 'error',
      });
    }
  }, [
    isHost,
    sessionId,
    setConnectedToConference,
    setToken,
    enqueueSnackbar,
    onLiveKitClose,
  ]);

  const startConference = useCallback(
    async (
      selfMuted,
      participantsMutedOnJoin,
      { cameraEnabled, deviceOptions }
    ) => {
      if (loading) return;

      if (!webRtcSupported) {
        onNotSupportedOpen();
        return;
      }

      const joinMuted = user.isExternal ? sessionMutedOnJoin : selfMuted;

      try {
        setLoading(true);
        setMuted(joinMuted);
        setCameraEnabled(cameraEnabled);
        setDeviceOptions(deviceOptions);

        const { token: accessToken } = await livekitService.createConference({
          sessionId,
          mutedOnJoin: participantsMutedOnJoin,
        });

        await livekitService.startConference(sessionId);

        onLiveKitOpen();

        setToken(accessToken);
        setConnectedToConference(true);
      } catch (error) {
        enqueueSnackbar(
          'Error occurred during starting the call, please try again',
          {
            variant: 'error',
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [
      webRtcSupported,
      onNotSupportedOpen,
      loading,
      setLoading,
      sessionId,
      setConnectedToConference,
      setMuted,
      enqueueSnackbar,
      setToken,
      onLiveKitOpen,
      sessionMutedOnJoin,
      setCameraEnabled,
      setDeviceOptions,
    ]
  );

  const joinConference = useCallback(
    async ({ selfMuted, cameraEnabled, deviceOptions }) => {
      if (loading) return;

      if (!webRtcSupported) {
        onNotSupportedOpen();
        return;
      }

      const joinMuted = user.isExternal ? sessionMutedOnJoin : selfMuted;

      try {
        setLoading(true);
        setMuted(joinMuted);
        setCameraEnabled(cameraEnabled);
        setDeviceOptions(deviceOptions);

        const { token: accessToken } = await livekitService.getToken(sessionId);

        onLiveKitOpen();

        setToken(accessToken);
        setConnectedToConference(true);
      } catch (error) {
        enqueueSnackbar('Error occurred, please try again', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      isHost,
      loading,
      setLoading,
      webRtcSupported,
      onNotSupportedOpen,
      sessionId,
      setConnectedToConference,
      setMuted,
      enqueueSnackbar,
      setToken,
      onLiveKitOpen,
      sessionMutedOnJoin,
      setCameraEnabled,
      setDeviceOptions,
    ]
  );

  const onChangeConnectionState = useCallback(
    (state) => {
      setConnectionState(state);
    },
    [setConnectionState]
  );

  const onChangeScreenShared = useCallback(
    (shared) => {
      setScreenShared(shared);
    },
    [setScreenShared]
  );

  const value = useMemo(
    () => ({
      loading,
      isHost,
      isConferenceInProgress,
      muted,
      connectedToConference,
      disconnectFromConference,
      startConference,
      joinConference,
      onChangeConnectionState,
      connectionState,
      onChangeScreenShared,
      isScreenShared,
    }),
    [
      loading,
      isHost,
      isConferenceInProgress,
      muted,
      connectedToConference,
      disconnectFromConference,
      startConference,
      joinConference,
      onChangeConnectionState,
      connectionState,
      onChangeScreenShared,
      isScreenShared,
    ]
  );

  return (
    <LiveKitContext.Provider value={value}>
      <DeviceProvider>
        {children}
        <LiveKitPopup
          token={token}
          open={liveKitOpen}
          onClose={onClose}
          muted={muted}
          cameraEnabled={cameraEnabled}
          deviceOptions={deviceOptions}
        />
      </DeviceProvider>
      <WebRtcNotSupportedDialog
        open={notSupportedOpen}
        onClose={onNotSupportedClose}
      />
    </LiveKitContext.Provider>
  );
};

export default LiveKitProvider;
