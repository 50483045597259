import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Subject, BehaviorSubject, pipe, combineLatest, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import DomEventsUtil from '../../../../../common/utils/domEvents.util';
import drawers from '../../drawers';
import BoundaryBox from '../boundaries/BoundaryBox';
import DeleteAction from '../../../../../common/drawingActions/delete.action';
import DrawingModes from '../DrawingModes';
import shapesHitTest from '../shapesHitTest';
import CreateFormulaDialog from '../../../../../containers/Modals/CreateFormulaDialog/CreateFormulaDialog';
import FormulaDrawingAction from '../../../../../common/drawingActions/formula.drawing.action';
import imageUtils from '../../../../../common/utils/image.utils';
import * as rectangleUtil from '../../../../../common/utils/shapes/rectangle.util';
import TopResizer from '../boundaries/resizers/TopResizer';
import BottomResizer from '../boundaries/resizers/BottomResizer';
import LeftResizer from '../boundaries/resizers/LeftResizer';
import RightResizer from '../boundaries/resizers/RightResizer';
import AspectRatioResizer from '../boundaries/resizers/AspectRatioResizer';
import * as imagesActions from '../../../../../common/actions/imagesActions';
import {
  hexToRgb,
  rgbToArgbNumber,
} from '../../../../../common/utils/color.utils';
import ToolNames from '../../../../DrawingTools/ToolNames';
import clearSelection from '../clearSelection';
import SelectCursorSvgIcon from '../../../../../assets/svgIcons/SelectCursorSvgIcon.svg';
import FormulaToolbar from './components/FormulaToolbar';
import { imagesHashSelector } from '../../../../../common/reducers/imagesReducer';

class Formula {
  name = 'formula';

  constructor(startPoint, width, height, tex, image, color) {
    this.startPoint = startPoint;
    this.width = width;
    this.height = height;
    this.tex = tex;
    this.image = image;
    this.color = color;
  }
}

const enhancer = connect(
  (state) => ({
    imagesHash: imagesHashSelector(state),
  }),
  {
    saveImage: imagesActions.saveImage,
  }
);

class FormulaLayer extends React.Component {
  constructor(props) {
    super(props);

    this.shouldApplyFormulaRef = createRef();
    this.shouldApplyFormulaRef.current = true;

    this.state = {
      formulaDialogOpen: false,
      formulaDialogEditMode: false,
      toolbarVisible: false,
    };
  }

  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.zoomProp$.next(this.props.zoom);
    this.scaleProp$.next(this.props.scale);
    this.originXProp$.next(this.props.originX);
    this.originYProp$.next(this.props.originY);
    this.currentPageIdProp$.next(this.props.currentPageId);
    if (
      this.props.currentDrawing &&
      this.props.currentDrawing.name === 'formula'
    ) {
      this.coordinates$.next({
        startPoint: { ...this.props.currentDrawing.startPoint },
        endPoint: { ...this.props.currentDrawing.endPoint },
      });
      this.formulaPosition$.next({
        startPoint: { ...this.props.currentDrawing.startPoint },
        endPoint: { ...this.props.currentDrawing.endPoint },
      });
      this.setState({
        toolbarVisible: true,
      });

      this.props.updateDrawingTool('formula', {
        color: +this.props.currentDrawing.paint.color,
      });

      this.formula$.next(
        new Formula(
          this.props.currentDrawing.startPoint,
          this.props.currentDrawing.endPoint.x -
            this.props.currentDrawing.startPoint.x,
          this.props.currentDrawing.endPoint.y -
            this.props.currentDrawing.startPoint.y,
          this.props.currentDrawing.tex,
          this.props.currentDrawing.image,
          +this.props.currentDrawing.paint.color
        )
      );
      this.mode$.next(DrawingModes.Drawn);
    } else {
      this.mode$.next(DrawingModes.Initial);
    }
    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();

    if (
      this.props.previousDrawingTool === ToolNames.Select &&
      this.props.currentDrawing
    )
      return;

    this.setState({
      formulaDialogOpen: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.zoom !== prevProps.zoom) {
      this.zoomProp$.next(this.props.zoom);
    }
    if (this.props.scale !== prevProps.scale) {
      this.scaleProp$.next(this.props.scale);
    }
    if (this.props.originX !== prevProps.originX) {
      this.originXProp$.next(this.props.originX);
    }
    if (this.props.originY !== prevProps.originY) {
      this.originYProp$.next(this.props.originY);
    }
    if (this.props.currentPageId !== prevProps.currentPageId) {
      this.currentPageIdProp$.next(this.props.currentPageId);
    }
  }

  componentWillUnmount() {
    if (this.shouldApplyFormulaRef.current) {
      const action = this.formula$.getValue();
      this.applyAction(action);
    }

    if (this.props.currentDrawing) {
      this.props.setCurrentDrawing(null);
    }

    if (this.props.previousDrawingTool) {
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
    }

    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();
    this.zoomProp$.complete();
    this.scaleProp$.complete();
    this.originXProp$.complete();
    this.originYProp$.complete();
    this.currentPageIdProp$.complete();
    this.coordinates$.complete();
    this.formula$.complete();
    this.formulaPosition$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');

    this.setDefaultCursor();
  };

  setDefaultCursor = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
  };

  canvas = null;
  drawingContext = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();
  mode$ = new BehaviorSubject();
  zoomProp$ = new BehaviorSubject();
  scaleProp$ = new BehaviorSubject();
  originXProp$ = new BehaviorSubject();
  originYProp$ = new BehaviorSubject();
  currentPageIdProp$ = new Subject();
  coordinates$ = new BehaviorSubject();
  formula$ = new BehaviorSubject(null);
  formulaPosition$ = new BehaviorSubject(null);

  props$ = combineLatest(
    this.zoomProp$,
    this.scaleProp$,
    this.originXProp$,
    this.originYProp$
  ).pipe(
    map(([zoom, scale, originX, originY]) => ({
      zoom,
      scale,
      originX,
      originY,
    }))
  );

  draw = (shape, boundaries) => {
    if (!shape) return;
    const { zoom, scale, originX, originY } = this.props;
    if (!this.drawingContext) return;

    const drawer = drawers[shape.name];
    if (!drawer) return;

    this.clear();
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    drawer(this.drawingContext, shape);

    if (boundaries && boundaries.length) {
      boundaries.forEach((boundary) => boundary.draw(this.drawingContext));
    }
    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  clear = () => {
    const { width, height } = this.props;
    this.drawingContext.clearRect(0, 0, width, height);
  };

  subscribeToDrawing = () => {
    const transformPoint = () =>
      pipe(
        withLatestFrom(this.props$),
        map(([point, props]) => {
          const { originX, originY, scale, zoom } = props;

          return {
            x: (point.x / zoom + originX) / scale,
            y: (point.y / zoom + originY) / scale,
          };
        })
      );

    const withMode = (m) =>
      pipe(
        withLatestFrom(this.mode$),
        filter(([, mode]) => mode === m),
        map(([e]) => e)
      );

    const startEvents$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint(), tap(clearSelection));

    const moveEvents$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint());

    const endEvents$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    ).pipe(transformPoint());

    combineLatest(this.coordinates$, this.props$).subscribe(
      ([positions, props]) => {
        if (!positions || !props) return null;

        const formula = this.createShape(positions);
        this.formula$.next(formula);
      }
    );

    const boundaries$ = combineLatest(this.coordinates$, this.props$).pipe(
      map(([positions, props]) => {
        if (!positions || !props) return [];

        return this.createBoundaries(positions, props);
      })
    );

    // Drag start
    startEvents$
      .pipe(
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$, this.formula$)
      )
      .subscribe(([coordinates, shapes, action]) => {
        const shape = shapesHitTest(coordinates, shapes);
        if (shape) {
          this.setState({
            toolbarVisible: false,
          });

          const newMode =
            shape instanceof BoundaryBox
              ? DrawingModes.Dragging
              : DrawingModes.Resizing;
          this.mode$.next(newMode);
          return;
        }

        this.shouldApplyFormulaRef.current = false;
        this.applyAction(action);
        this.props.selectDrawingTool(ToolNames.Select);
      });

    // Dragging
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        withLatestFrom(boundaries$),
        concatMap(() =>
          moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Dragging),
            pairwise(),
            map(([oldPos, newPos]) => ({
              deltaX: newPos.x - oldPos.x,
              deltaY: newPos.y - oldPos.y,
            }))
          )
        ),
        withLatestFrom(this.coordinates$),
        map(([transform, { startPoint, endPoint }]) => ({
          startPoint: {
            x: startPoint.x + transform.deltaX,
            y: startPoint.y + transform.deltaY,
          },
          endPoint: {
            x: endPoint.x + transform.deltaX,
            y: endPoint.y + transform.deltaY,
          },
        }))
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Drag end
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ startPoint, endPoint }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.formulaPosition$.next({
          startPoint,
          endPoint,
        });
        this.setState({
          toolbarVisible: true,
        });
      });

    // Resizing
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        withLatestFrom(boundaries$),
        concatMap(([coordinates, shapes]) => {
          const shape = shapesHitTest(coordinates, shapes);

          return moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Resizing),
            map((pos) => shape.onResize(pos)),
            map(({ startPoint, endPoint }) =>
              this.normalizePoints(startPoint, endPoint)
            )
          );
        })
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Resize end
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ startPoint, endPoint }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.setState({
          toolbarVisible: true,
        });
        this.formulaPosition$.next({
          startPoint,
          endPoint,
        });
      });

    this.mouseMove$
      .pipe(
        map(DomEventsUtil.mouseEventToCoordinates),
        transformPoint(),
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$)
      )
      .subscribe(([coordinates, shapes]) => {
        for (let i = shapes.length - 1; i >= 0; i -= 1) {
          const shape = shapes[i];
          if (shape.hitTest(coordinates.x, coordinates.y)) {
            shape.onHover(this.drawingContext);
            return;
          }
        }

        this.setDefaultCursor();
      });

    combineLatest(
      this.formula$,
      combineLatest(boundaries$, this.mode$).pipe(
        map(([boundaries, mode]) => {
          if (mode === DrawingModes.Initial || mode === DrawingModes.Dragging)
            return [];

          return boundaries;
        })
      )
    ).subscribe(([shape, boundaries]) => {
      if (!shape) {
        this.clear();
        return;
      }

      this.draw(shape, boundaries);
    });

    this.currentPageIdProp$.subscribe(() => {
      if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
        this.props.setCurrentDrawing(null);
      }
      this.shouldApplyFormulaRef.current = false;
      this.props.selectDrawingTool(ToolNames.Select);
      this.reset();
    });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  applyAction = (formula) => {
    const drawingAction = new FormulaDrawingAction();
    drawingAction.paint.color = formula.color;
    drawingAction.localStartTime = new Date();
    drawingAction.startPoint = formula.startPoint;
    drawingAction.endPoint = {
      x: formula.startPoint.x + formula.width,
      y: formula.startPoint.y + formula.height,
    };
    drawingAction.width = formula.width;
    drawingAction.height = formula.height;
    drawingAction.tex = formula.tex;
    drawingAction.image = formula.image;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      drawingAction.targetId = this.props.currentDrawing.targetId;
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    }

    this.props.saveImage(drawingAction.id, drawingAction.image);
    this.props.addActionAndSend(drawingAction);

    this.reset();
  };

  reset = () => {
    this.coordinates$.next(null);
    this.formula$.next(null);
    this.mode$.next(DrawingModes.Initial);
    this.setState({
      formulaDialogEditMode: false,
    });
    this.clear();
  };

  createShape = (positions) => {
    const { startPoint, endPoint } = positions;
    const lastFormula = this.formula$.getValue();
    return new Formula(
      startPoint,
      endPoint.x - startPoint.x,
      endPoint.y - startPoint.y,
      lastFormula.tex,
      lastFormula.image,
      this.props.color
    );
  };

  createBoundaries = (positions, props) => {
    const { scale, zoom } = props;
    const { startPoint, endPoint } = positions;

    const padding = 20 / scale;
    const params = [
      rectangleUtil.getStartingPoint(startPoint, endPoint),
      rectangleUtil.getEndPoint(startPoint, endPoint),
      scale * zoom,
      padding,
    ];

    const editEndPoint = rectangleUtil.getEndPoint(startPoint, endPoint);
    editEndPoint.x -= 45 / (scale * zoom);

    const shapes = [
      new BoundaryBox(...params),
      new TopResizer(...params),
      new RightResizer(...params),
      new BottomResizer(...params),
      new LeftResizer(...params),
      new AspectRatioResizer(...params),
    ];

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => ({
    startPoint: rectangleUtil.getStartingPoint(startPoint, endPoint),
    endPoint: rectangleUtil.getEndPoint(startPoint, endPoint),
  });

  onCancelFormulaDialog = () => {
    const { formulaDialogEditMode } = this.state;
    this.shouldApplyFormulaRef.current = false;

    const newMode = formulaDialogEditMode
      ? DrawingModes.Drawn
      : DrawingModes.Initial;

    this.mode$.next(newMode);

    this.setState({
      formulaDialogOpen: false,
    });

    if (!formulaDialogEditMode) {
      this.props.selectDrawingTool(ToolNames.Select);
    }

    if (this.props.previousDrawingTool) {
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
    }
  };

  onSubmitFormulaDialog = async (tex, svg) => {
    const image = await imageUtils.toImage(svg);

    const lastFormula = this.formula$.getValue();
    const minHeight = lastFormula?.height || 100;

    let { width, height } = image;

    if (height > 0 && height < minHeight) {
      const ratio = minHeight / height;
      width *= ratio;
      height *= ratio;
    }

    const x =
      (this.props.originX + this.props.width / 2 / this.props.zoom) /
        this.props.scale -
      width / 2 / this.props.zoom;
    const y =
      (this.props.originY + this.props.height / 2 / this.props.zoom) /
        this.props.scale -
      height / 2 / this.props.zoom;

    let startPoint = { x, y };
    const coordinates = this.coordinates$.getValue();
    if (coordinates && coordinates?.startPoint.x && coordinates?.startPoint.y) {
      startPoint = {
        x: coordinates.startPoint.x,
        y: coordinates.startPoint.y,
      };

      width = coordinates.endPoint.x - coordinates.startPoint.x;
      height = coordinates.endPoint.y - coordinates.startPoint.y;
    }

    const formula = new Formula(
      startPoint,
      width,
      height,
      tex,
      image,
      this.props.color
    );

    this.formula$.next(formula);
    const formulaPosition = {
      startPoint: formula.startPoint,
      endPoint: {
        x: formula.startPoint.x + formula.width,
        y: formula.startPoint.y + formula.height,
      },
    };

    this.coordinates$.next(formulaPosition);
    this.formulaPosition$.next(formulaPosition);

    this.mode$.next(DrawingModes.Drawn);

    this.formula$
      .pipe(take(1))
      .toPromise()
      .then(() => {
        this.setState({
          formulaDialogOpen: false,
          toolbarVisible: true,
        });
      });
  };

  onUpdateColor = (color) => {
    const { r, g, b } = hexToRgb(color);
    const alpha = 1;
    const argbColor = rgbToArgbNumber({
      a: alpha * 255,
      r,
      g,
      b,
    });

    this.props.updateDrawingTool('formula', { color: argbColor });
  };

  deleteFormula = () => {
    this.setDefaultCursor();
    this.shouldApplyFormulaRef.current = false;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const deleteAction = new DeleteAction();
      deleteAction.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(deleteAction);
      this.props.selectDrawingTool(ToolNames.Select);
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    } else {
      this.props.selectDrawingTool(ToolNames.Select);
    }

    this.reset();
  };

  copyFormula = () => {
    this.setDefaultCursor();
    const action = this.formula$.getValue();

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const drawingAction = new FormulaDrawingAction();
      drawingAction.paint.color = action.color;
      drawingAction.localStartTime = new Date();
      drawingAction.startPoint = action.startPoint;
      drawingAction.endPoint = {
        x: action.startPoint.x + action.width,
        y: action.startPoint.y + action.height,
      };
      drawingAction.width = action.width;
      drawingAction.height = action.height;
      drawingAction.tex = action.tex;
      drawingAction.image = action.image;

      if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
        drawingAction.targetId = this.props.currentDrawing.targetId;
      }
      this.props.saveImage(drawingAction.id, drawingAction.image);
      this.props.addActionAndSend(drawingAction);
      this.mode$.next(DrawingModes.Drawn);

      const formulaPosition = {
        startPoint: {
          x: action.startPoint.x + 20,
          y: action.startPoint.y + 20,
        },
        endPoint: {
          x: action.startPoint.x + 20 + action.width,
          y: action.startPoint.y + 20 + action.height,
        },
      };

      this.coordinates$.next(formulaPosition);
      this.formulaPosition$.next(formulaPosition);
    }
  };

  editFormula = () => {
    this.setState({
      formulaDialogOpen: true,
      formulaDialogEditMode: true,
    });
  };

  getToolbarPosition = (position) => {
    if (!position) return;

    const { originX, originY, scale, zoom, originOffsetY } = this.props;
    const padding = 20 / scale / zoom;

    return {
      x: ((position.startPoint.x - padding) * scale - originX) * zoom,
      y:
        ((position.startPoint.y - padding) * scale - originY + originOffsetY) *
        zoom,
      width:
        (position.endPoint.x - position.startPoint.x + 2 * padding) *
        scale *
        zoom,
      height:
        (position.endPoint.y - position.startPoint.y + 2 * padding) *
        scale *
        zoom,
    };
  };

  render() {
    const { formulaDialogOpen } = this.state;
    const { width, height, color } = this.props;

    const formula = this.formula$.getValue();
    const tex = formula ? formula.tex : '';
    const openToolbar = this.state.toolbarVisible;
    const formulaPosition = this.formulaPosition$.getValue();
    const toolbarPosition = this.getToolbarPosition(formulaPosition);

    return (
      <>
        <canvas
          ref={this.setDrawingContext}
          width={width}
          height={height}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
          onMouseUp={this.onMouseUp}
          onContextMenu={this.onContextMenu}
        />
        <CreateFormulaDialog
          color={color}
          onUpdateColor={this.onUpdateColor}
          open={formulaDialogOpen}
          tex={tex}
          onClose={this.onCancelFormulaDialog}
          onSubmit={this.onSubmitFormulaDialog}
        />
        {toolbarPosition && (
          <FormulaToolbar
            position={toolbarPosition}
            open={openToolbar}
            onDelete={this.deleteFormula}
            onCopy={this.copyFormula}
            onEdit={this.editFormula}
          />
        )}
      </>
    );
  }
}

export default enhancer(FormulaLayer);
