import { ButtonProps } from '@mui/material/Button';
import { DashboardThemeType } from '../../LiveBoardTheme/themeType';
import { ButtonVariantTypes } from './buttonTypes';

const generateButtonStyle = (
  ownerState: ButtonProps,
  theme: DashboardThemeType
) => {
  const color = ownerState.color || 'primary';

  switch (ownerState.variant) {
    case ButtonVariantTypes.PRIMARY:
      return {
        backgroundColor: theme.palette[color].main,
        borderColor: theme.palette[color].main,
        color: theme.text.t1,
        '&:hover': {
          backgroundColor: theme.palette[color].dark5,
          border: `2px solid ${theme.palette[color].dark5}`,
          boxShadow: 'unset',
        },
        '&:active': {
          backgroundColor: theme.palette[color].main,
          borderColor: theme.palette[color].main,
          boxShadow: 'unset',
        },
        '&:disabled': {
          backgroundColor: theme.background.bg2,
          borderColor: theme.background.bg2,
          color: theme.text.t4,
        },
      };
    case ButtonVariantTypes.SECONDARY:
      return {
        backgroundColor: theme.background.bg4,
        borderColor: theme.background.bg4,
        color: theme.background.bg8,
        '&:hover': {
          backgroundColor: theme.background.bg5,
          border: `2px solid ${theme.background.bg5}`,
          color: theme.palette[color].main,
          boxShadow: 'unset',
        },
        '&:active': {
          backgroundColor: theme.palette[color].alpha10,
          borderColor: theme.palette[color].alpha10,
          color: theme.palette[color].dark5,
        },
        '&:disabled': {
          backgroundColor: theme.background.bg2,
          borderColor: theme.background.bg2,
          color: theme.text.t4,
        },
      };
    case ButtonVariantTypes.TEXT:
      return {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: theme.text.t8,
        '&:hover': {
          backgroundColor: theme.background.bg3,
          border: `2px solid ${theme.background.bg3}`,
          color: theme.palette[color].main,
          boxShadow: 'unset',
        },
        '&:active': {
          backgroundColor: theme.palette[color].alpha10,
          borderColor: theme.palette[color].alpha10,
          color: theme.palette[color].dark5,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: theme.text.t4,
        },
      };
    case ButtonVariantTypes.OUTLINED:
      return {
        backgroundColor: 'transparent',
        borderColor: theme.palette[color].alpha50,
        color: theme.background.bg8,
        '&:hover': {
          backgroundColor: theme.palette[color].alpha10,
          border: `2px solid ${theme.palette[color].alpha10}`,
          color: theme.palette[color].dark5,
          boxShadow: 'unset',
        },
        '&:active': {
          backgroundColor: theme.palette[color].main,
          borderColor: theme.palette[color].main,
          color: theme.text.t1,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: theme.background.bg4,
          color: theme.text.t4,
        },
      };

    default:
      return null;
  }
};

export default generateButtonStyle;
