import DrawingAction from './drawing.action';

export default class FreeDrawingAction extends DrawingAction {
  points = [];
  processedPoints = [];

  constructor(deserialized) {
    super(deserialized);

    this.name = 'free';

    if (deserialized) {
      this.points = deserialized.pth.a;
      this.updatePoints();
    }
  }

  updatePoints() {
    if (!this.points || !this.points.length) return;
    for (let i = 0; i < this.points; i += 1) {
      const {
        x, y, x1, y1,
      } = this.points[i];

      this.processedPoints.push({ x, y });
      if (x1 || y1) {
        this.processedPoints.push({ x: x1, y: y1 });
      }
    }
  }

  addPoint(point) {
    if (!point) return;

    this.points.push(point);
  }
}
