import { useEffect, useState } from 'react';
import { Participant } from 'livekit-client';
import { defaultSortParticipants } from '../../../components/LiveKitPopup/StageUtils';

type useSortedParticipantsParams = {
  sortFunction?: (participants: Participant[]) => Participant[];
  participants: Participant[];
};
export const useSortedParticipants = ({
  sortFunction = defaultSortParticipants,
  participants,
}: useSortedParticipantsParams) => {
  const [sortedParticipants, setSortedParticipants] = useState(
    sortFunction(participants)
  );

  useEffect(() => {
    setSortedParticipants(sortFunction(participants));
  }, [participants, setSortedParticipants]);

  return sortedParticipants;
};
