import { FC, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '../../atoms/DialogTitle';
import UserDto from '../../../common/services/dto/auth/UserDto';
import * as userActions from '../../../common/actions/userActions';
import OnePaidPlan from '../OnePaidPlan';
import PlanDialogContext from '../../../components/PlanDialogContext/PlanDialogContext';

export type DialogContentProps = {
  dialog: {
    trial: {
      title: string;
      message: string;
      successBtn: string;
    };
    purchase: {
      title: string;
      message: string;
      successBtn: string;
    };
  };
  imageUrl: string;
  plan: {
    monthly: any;
    yearly: any;
  };
};

type PlanDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: (user: UserDto) => void;
  source: string;
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  marginTop: '32px',
  marginBottom: 0,
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const PlanDialog: FC<React.PropsWithChildren<PlanDialogProps>> = ({
  open,
  onClose,
  onSuccess,
  source,
}) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  const { dialogPayload } = useContext(PlanDialogContext);

  const onPlanSelectionSuccess = useCallback(
    (updatedUser: UserDto) => {
      dispatch(userActions.setUser(updatedUser));

      if (onSuccess) {
        onSuccess(updatedUser);
      }

      if (onClose) {
        onClose();
      }
    },
    [dispatch, onSuccess, onClose]
  );

  return (
    <Dialog
      open={open && !!dialogPayload}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: upSm ? 824 : 424,
        },
      }}
    >
      <DialogTitle onClose={onClose} hasCloseButton />
      <StyledDialogContent>
        <Box display="flex" gap={3}>
          {dialogPayload && (
            <OnePaidPlan
              onPlanSelectionSuccess={onPlanSelectionSuccess}
              dialogContent={dialogPayload}
              source={source}
            />
          )}
          {upSm && (
            <Box display="flex" flex={1}>
              <CardMedia
                image={dialogPayload?.imageUrl}
                component="img"
                sx={{
                  borderRadius: 5,
                }}
              />
            </Box>
          )}
        </Box>
      </StyledDialogContent>
    </Dialog>
  );
};

export default PlanDialog;
