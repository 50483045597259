import React, { FC, useCallback } from 'react';
import Box from '@mui/material/Box';
import IconButton from '../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import AccountMultiplePlusOutline from '../../../ui/atoms/Icons/AccountMultiplePlusOutline';
import useDialog from '../../../common/hooks/useDialog';
import analyticsService from '../../../common/services/analytics.service';
import InvitePeopleDialog from '../../../ui/molecules/InvitePeopleDialog';
import Tooltip from '../../../ui/atoms/Tooltip';

type InviteToolProps = {
  tooltipPlacement: 'top' | 'left';
};

const InviteTool: FC<React.PropsWithChildren<InviteToolProps>> = ({
  tooltipPlacement,
}) => {
  const {
    open: invitePeopleDialogOpen,
    onOpen: onInvitePeopleDialogOpen,
    onClose: onInvitePeopleDialogClose,
  } = useDialog();

  const onInvitePeopleClick = useCallback(() => {
    onInvitePeopleDialogOpen();
    analyticsService.event('Invite Button Click', {
      source: 'Collaboration',
    });
  }, [onInvitePeopleDialogOpen]);

  return (
    <>
      <Tooltip
        title="Invite students"
        placement={tooltipPlacement}
        className="product-tour-step-3"
      >
        <Box>
          <IconButton
            onClick={onInvitePeopleClick}
            variant={ButtonVariantTypes.SECONDARY}
            size={ButtonSizeTypes.S}
            shape={ButtonShapeTypes.ROUNDED}
          >
            <AccountMultiplePlusOutline
              sx={{
                width: 18,
                height: 18,
              }}
            />
          </IconButton>
        </Box>
      </Tooltip>
      <InvitePeopleDialog
        open={invitePeopleDialogOpen}
        onCancel={onInvitePeopleDialogClose}
      />
    </>
  );
};

export default InviteTool;
