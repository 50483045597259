import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useDialog from '../../../common/hooks/useDialog';
import CreateBoardDialog from '../CreateBoardDialog';
import {
  isFetchingSelector,
  isCopiedSessionFetchingSelector,
  sessionListSelector,
} from '../../../common/reducers/sessionsReducer';
import BoardCard from '../BoardCard';
import fallbackSrc from '../../../assets/images/fallback-thumbnail.svg';
import analyticsService from '../../../common/services/analytics.service';
import PlusIcon from '../../atoms/Icons/PlusIcon';
import CreateCard from '../CreateCard';
import RenameBoardDialog from '../RenameBoardDialog';
import MoveBoardDialog from '../MoveBoardDialog';
import formatDistanceBetweenNow, {
  cardDate,
} from '../../../common/utils/date.utils';
import DeleteSessionDialog from '../DeleteSessionDialog';
import UpgradeDialog from '../UpgradeDialog';
import PlanDialog from '../PlanDialog';
import ExportPdfDialog from '../ExportPdfDialog/ExportPdfDialog';
import SessionDto from '../../../common/services/dto/session/SessionDto';
import SkeletonGrid from '../SkeletonGrid';
import LoadingCard from '../LoadingCard';
import { getGroupSelector } from '../../../common/reducers/groupsReducer';
import { userSelector } from '../../../common/reducers/userReducer';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  groupId: string;
  sortingCards: (cards: SessionDto[]) => SessionDto[];
};

const CardGrid: FC<React.PropsWithChildren<Props>> = ({
  groupId,
  sortingCards,
}) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [selectedCard, setSelectedCard] = useState<SessionDto | null>(null);
  const isFetching = useSelector(isFetchingSelector);
  const isCopiedSessionFetching = useSelector(isCopiedSessionFetchingSelector);
  const user = useSelector(userSelector);

  const groupSelector = useMemo(() => getGroupSelector(groupId), [groupId]);
  const group = useSelector(groupSelector);

  const {
    open: renameDialogOpen,
    onOpen: onRenameDialogOpen,
    onClose: onRenameDialogClose,
  } = useDialog();

  const {
    open: deleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDialog();

  const {
    open: moveToDialogOpen,
    onOpen: onMoveToDialogOpen,
    onClose: onMoveToDialogClose,
  } = useDialog();

  const {
    open: exportPdfDialogOpen,
    onOpen: onExportPdfDialogOpen,
    onClose: onExportPdfDialogClose,
  } = useDialog();

  const {
    open: createBoardDialog,
    onOpen: onCreateBoardDialogOpen,
    onClose: onCreateBoardDialogClose,
  } = useDialog();

  const {
    open: createBoardLimitDialogOpen,
    onOpen: onCreateBoardLimitDialogOpen,
    onClose: onCreateBoardLimitDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const cardsSelector = useMemo(() => sessionListSelector(groupId), [groupId]);

  const cards = useSelector(cardsSelector);

  const sortedCards = useMemo(() => sortingCards(cards), [cards, sortingCards]);

  const selectCardById = useCallback(
    (cardId: any) => {
      const card = sortedCards.find((c) => c.id === cardId) || null;
      setSelectedCard(card);
    },
    [sortedCards, setSelectedCard]
  );

  const onRename = useCallback(
    (cardId: any) => {
      selectCardById(cardId);
      onRenameDialogOpen();
    },
    [selectCardById, onRenameDialogOpen]
  );

  const onMove = useCallback(
    (cardId: any) => {
      selectCardById(cardId);
      onMoveToDialogOpen();
    },
    [selectCardById, onMoveToDialogOpen]
  );

  const onExport = useCallback(
    (cardId: any) => {
      selectCardById(cardId);
      onExportPdfDialogOpen();
    },
    [selectCardById, onExportPdfDialogOpen]
  );

  const onDelete = useCallback(
    (cardId: any) => {
      analyticsService.event('Delete Board Button Click', {
        source: 'Boards',
      });
      selectCardById(cardId);
      onDeleteDialogOpen();
    },
    [selectCardById, onDeleteDialogOpen]
  );

  const selectCard = useCallback(
    (card: any) => {
      setSelectedCard(card);
    },
    [setSelectedCard]
  );

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onCreateBoardLimitDialogClose();
  }, [onPlansDialogOpen, onCreateBoardLimitDialogClose]);

  const skeletonCount = useMemo(() => {
    if (upLg) return 3;
    if (upSm) return 2;

    return 1;
  }, [upLg, upSm]);

  const showCreateButton = useMemo(() => {
    if (!isFetching && !group && sortedCards.length !== 0) return true;

    return (
      !isFetching &&
      group &&
      user.id === group.ownerId &&
      sortedCards.length !== 0
    );
  }, [user, group, isFetching, sortedCards]);

  return (
    <>
      <Box
        sx={(t) => ({
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          [t.breakpoints.up('sm')]: {
            gap: '16px',
          },
          [t.breakpoints.up('md')]: {
            gap: '28px',
          },
        })}
        className="card-grid"
      >
        {showCreateButton && (
          <CardGridItem>
            <CreateCard
              Icon={PlusIcon}
              title="New board"
              width="100%"
              height={upSm ? '100%' : 122}
              onClick={onCreateBoardDialogOpen}
              className={`product-tour-step-1 ${!group ? 'no-group' : ''}`}
            />
          </CardGridItem>
        )}
        {isFetching && <SkeletonGrid counts={skeletonCount} hasThumbnail />}
        {isCopiedSessionFetching && <LoadingCard hasThumbnail />}
        {!isFetching &&
          sortedCards.map((card: SessionDto) => (
            <BoardCard
              id={card.id}
              ownerId={card.ownerId}
              key={card.id}
              altText={`Board ${card.name}`}
              src={card.thumbnailUrl}
              fallbackSrc={fallbackSrc}
              boardName={card.name}
              boardOwnerName={card.owner.name}
              boardSubtitle={`Edited ${formatDistanceBetweenNow(
                card.updatedAt
              )}`}
              date={cardDate(card.createdAt)}
              onRename={onRename}
              onMove={onMove}
              onExport={onExport}
              onDelete={onDelete}
              isShared={card.shared}
              isFinished={card.finished}
              onCreateDialogOpen={onCreateBoardLimitDialogOpen}
              boardType={card.boardType}
            />
          ))}
      </Box>
      <CreateBoardDialog
        open={createBoardDialog}
        onClose={onCreateBoardDialogClose}
        groupId={groupId}
      />
      <RenameBoardDialog
        card={selectedCard}
        open={renameDialogOpen}
        onClose={() => {
          setSelectedCard(null);
          onRenameDialogClose();
        }}
      />
      <DeleteSessionDialog
        open={deleteDialogOpen}
        onClose={() => {
          setSelectedCard(null);
          onDeleteDialogClose();
        }}
        card={selectedCard}
      />
      <MoveBoardDialog
        card={selectedCard}
        open={moveToDialogOpen}
        onClose={() => {
          setSelectedCard(null);
          onMoveToDialogClose();
        }}
      />
      <ExportPdfDialog
        card={selectedCard}
        open={exportPdfDialogOpen}
        onCancel={() => {
          selectCard({});
          onExportPdfDialogClose();
        }}
      />
      <UpgradeDialog
        open={createBoardLimitDialogOpen}
        onCancel={onCreateBoardLimitDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source="Board Creation Limit"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
        onSuccess={onCreateBoardDialogClose}
      />
    </>
  );
};

export default CardGrid;
