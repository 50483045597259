import { DisplayObject, Sprite } from 'pixi.js';
import { cloneDeep } from 'lodash';
import DrawingAction from './drawingAction';
import Point from '../types/Point';
import OldImageAction from '../../../common/drawingActions/image.drawing.action';
import guid from '../../../common/utils/guid.util';
import ActionName from '../types/ActionName';

class Image extends DrawingAction {
  startPoint: Point;

  endPoint: Point;

  imageUrl: string | null = null;

  imageId = null;

  image: HTMLImageElement | null = null;

  constructor(action?: OldImageAction) {
    super(action);

    this.name = ActionName.IMAGE;

    if (action) {
      this.startPoint = {
        x: action.startPoint.x,
        y: action.startPoint.y,
      };
      this.endPoint = {
        x: action.startPoint.x + action.width,
        y: action.startPoint.y + action.height,
      };
      this.imageId = action.imageId;
      this.imageUrl = action.imageUrl;
    }
  }

  toDto(): OldImageAction {
    const dto = new OldImageAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldImageAction) {
    super.setFields(action);
    action.name = ActionName.IMAGE;
    action.startPoint = this.startPoint;
    action.width = Math.abs(this.endPoint.x - this.startPoint.x);
    action.height = Math.abs(this.endPoint.y - this.startPoint.y);
    action.imageId = this.imageId;
    action.imageUrl = this.imageUrl;
    action.image = this.image;
  }

  requiresUpdate(action: Image): boolean {
    let result = super.requiresUpdate(action);

    if (action.image && action.image !== this.image) {
      result = true;
    }

    if (!this.imageId && action.imageId !== this.imageId) {
      result = true;
    }

    return result;
  }

  createMoveAction(newPos: Point): Image {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;

    const center = {
      x: (this.startPoint.x + this.endPoint.x) / 2,
      y: (this.startPoint.y + this.endPoint.y) / 2,
    };

    const delta = {
      x: newPos.x - center.x,
      y: newPos.y - center.y,
    };

    newAction.startPoint = {
      x: this.startPoint.x + delta.x,
      y: this.startPoint.y + delta.y,
    };
    newAction.endPoint = {
      x: this.endPoint.x + delta.x,
      y: this.endPoint.y + delta.y,
    };

    return newAction;
  }

  draw(object: DisplayObject) {
    const imageObject = object as Sprite;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    imageObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );
    imageObject.anchor.set(0.5);
    imageObject.width = width;
    imageObject.height = height;
  }
}

export default Image;
