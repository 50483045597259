import { CSSProperties, FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

type Props = {
  pathData: string;
  viewBoxWidth: number;
  viewBoxHeight: number;
  style: CSSProperties;
} & SvgIconProps;

const PathShapeIcon: FC<React.PropsWithChildren<Props>> = ({
  pathData,
  viewBoxWidth,
  viewBoxHeight,
  style,
  width = 24,
  height = 24,
  ...props
}) => (
  <SvgIcon
    viewBox={`-12 -12 ${viewBoxWidth + 24} ${viewBoxHeight + 24}`}
    sx={{
      ...style,
      fill: 'none',
      strokeWidth: '6px',
      stroke: 'currentColor',
    }}
    {...props}
  >
    <path d={pathData} />
  </SvgIcon>
);

export default PathShapeIcon;
