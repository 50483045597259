import React, { FC, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import InputVariant from '../../../ui/atoms/Input/InputVariant';
import InputSize from '../../../ui/atoms/Input/InputSize';
import Input from '../../../ui/atoms/Input';

type Props = {
  details: string;
  onDeletePaymentMethod: () => Promise<void>;
};

const CardInformation: FC<React.PropsWithChildren<Props>> = ({
  details,
  onDeletePaymentMethod,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onDeleteCard = useCallback(async () => {
    try {
      setIsFetching(true);
      await onDeletePaymentMethod();
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      enqueueSnackbar('Unexpected error occurred, please try again', {
        autoHideDuration: 3000,
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, onDeletePaymentMethod]);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      gap={2}
      mb={3}
    >
      <Box display="flex" flex={1} flexDirection="column">
        <Input
          fullWidth
          disabled
          value={details}
          variant={InputVariant.CONTAINED}
          size={InputSize.S}
        />
      </Box>
      <Box>
        <Button
          onClick={onDeleteCard}
          variant={ButtonVariantTypes.OUTLINED}
          size={ButtonSizeTypes.S}
          loading={isFetching}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default CardInformation;
