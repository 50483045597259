import React from 'react';
import {
  BaseAssessmentTemplateCard,
  BaseAssessmentTemplateCardProps,
} from './BaseAssessmentTemplateCard';
import RadioboxMarkedIcon from '../../../../../../../ui/atoms/Icons/RadioboxMarkedIcon';
import RadioboxBlankIcon from '../../../../../../../ui/atoms/Icons/RadioboxBlankIcon';

type AssessmentTemplateRadioCardProps = {
  checked: boolean;
} & BaseAssessmentTemplateCardProps;

export const AssessmentTemplateRadioCard: React.FC<
  React.PropsWithChildren<AssessmentTemplateRadioCardProps>
> = ({
  templateName,
  createdDate,
  updatedDate,
  questionsCount,
  onClick,
  checked,
}) => (
  <BaseAssessmentTemplateCard
    templateName={templateName}
    createdDate={createdDate}
    updatedDate={updatedDate}
    questionsCount={questionsCount}
    onClick={onClick}
    actions={[
      {
        Icon: checked ? RadioboxMarkedIcon : RadioboxBlankIcon,
        props: {
          sx: (theme) => ({ color: theme.status.live }),
        },
        onClick: () => {},
      },
    ]}
  />
);
