import ActivePageAction from './pages/active.page.action';
import DotPointerAction from './pointers/dot.pointer.action';
import ImageDrawingAction from './image.drawing.action';
import LockedImageDrawingAction from './lockedImage.drawing.action';

const createActivePageAction = (id, creatorId) => {
  const action = new ActivePageAction();
  action.setPageNumber(id);
  action.setCreatorId(creatorId);
  return action;
};

const createDotPointerAction = (creatorId, x, y) => {
  const action = new DotPointerAction();
  action.setCreatorId(creatorId);
  action.point = { x, y };
  action.localStartTime = new Date();
  return action;
};

const createImageDrawingAction = (creatorId, x, y, width, height, imageUrl, pageNumber) => {
  const action = new ImageDrawingAction();
  action.setCreatorId(creatorId);
  action.imageUrl = imageUrl;
  action.localStartTime = new Date();
  action.startPoint = { x, y };
  action.width = width;
  action.height = height;
  action.pageNumber = pageNumber;
  return action;
};

const createLockedImageDrawingAction = (creatorId, x, y, width, height, imageUrl, pageNumber) => {
  const action = new LockedImageDrawingAction();
  action.setCreatorId(creatorId);
  action.imageUrl = imageUrl;
  action.localStartTime = new Date();
  action.startPoint = { x, y };
  action.width = width;
  action.height = height;
  action.pageNumber = pageNumber;
  return action;
};

export default {
  createActivePageAction,
  createDotPointerAction,
  createImageDrawingAction,
  createLockedImageDrawingAction,
};
