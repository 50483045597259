const shapesHitTest = (coordinates, shapes) => {
  for (let i = shapes.length - 1; i >= 0; i -= 1) {
    const shape = shapes[i];
    if (shape.hitTest(coordinates.x, coordinates.y)) {
      return shape;
    }
  }
  return null;
};

export default shapesHitTest;
