import { FC } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import PlayButton, { PlayButtonVariant } from '../../atoms/PlayButton';

export type TutorialCardProps = {
  url: string;
  src: string;
};

const TutorialCard: FC<React.PropsWithChildren<TutorialCardProps>> = ({
  url,
  src,
}) => (
  <Box
    sx={(t) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      minWidth: '328px',
      [t.breakpoints.up('sm')]: {
        width: 'calc((100% - 16px) / 2)',
        flex: 'unset',
      },
      [t.breakpoints.up('md')]: {
        width: 'calc((100% - 28px) / 2)',
        minWidth: '466px',
        // flex: 1,
      },
      [t.breakpoints.up('lg')]: {
        minWidth: '287px',
      },
    })}
  >
    <CardMedia
      component="img"
      image={src}
      sx={{
        borderRadius: '20px 0 20px 10px',
        height: 'auto',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
      }}
    >
      <Link target="_blank" href={url} underline="none">
        <PlayButton variant={PlayButtonVariant.WHITE} />
      </Link>
    </Box>
  </Box>
);

export default TutorialCard;
