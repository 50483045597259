const FRAG_SRC = `#version 300 es
precision mediump float;

uniform vec3 color;

out vec4 out_color;

void main() {
  out_color.rgb = color;
}`;

export default FRAG_SRC;
