import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import PdfFileInput from '../../common/PdfFileInput';
import PdfImport from '../../../containers/Modals/PdfImportModal/PdfImport';
import { SelectedTool } from './types/SelectToolType';

type ImportPdfToolProps = {
  selectedTool: SelectedTool;
};

const ImportPdfTool = forwardRef(
  (
    { selectedTool }: ImportPdfToolProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [pdf, setPdf] = useState(null);

    const onChange = useCallback(
      (pdf: any) => {
        setPdf(pdf);
      },
      [setPdf]
    );

    const onCancel = useCallback(() => {
      setPdf(null);
    }, []);

    const onSuccess = useCallback(() => {
      setPdf(null);
    }, []);

    return (
      <>
        <Box>
          <PdfFileInput id="pdf-file-import" onChange={onChange} ref={ref} />
        </Box>
        <PdfImport
          pdfFile={pdf}
          onCancel={onCancel}
          onSuccess={onSuccess}
          type="append"
        />
      </>
    );
  }
);

export default ImportPdfTool;
