import { push } from 'redux-first-history';
import storage from '../../config/storage';
import * as userActions from './userActions';
import authService from '../services/auth.service';
import analyticsService from '../services/analytics.service';
import { queryClient } from '../../config/queryClient';

const SIGN_OUT_SUCCESS = Symbol('SIGN_OUT_SUCCESS');

const IS_FETCHED = Symbol('IS_FETCHED');

const saveUser = (dispatch, user, accessToken) => {
  storage.setItem('accessToken', accessToken);

  dispatch(userActions.setUser(user));
  return user;
};

const saveUserAction = (user, accessToken) => (dispatch) =>
  saveUser(dispatch, user, accessToken);

const isFetched = (payload) => ({
  type: IS_FETCHED,
  payload,
});

const signInWithGoogle = async (code, role, referralId) => {
  const { user, accessToken } = await authService.signInWithGoogle(
    code,
    role,
    referralId ? 'rewardful' : undefined,
    referralId
  );

  return { user, accessToken };
};

const receiveSignOut = () => ({
  type: SIGN_OUT_SUCCESS,
});

// Logs the user out
const signOut = () => async (dispatch) => {
  try {
    await authService.signOut();
  } catch (error) {
    if (error.status !== 401) throw error;
  }

  queryClient.removeQueries();
  dispatch(receiveSignOut());
  analyticsService.setUserId('');
  dispatch(push('/sign-in'));
};

export {
  signInWithGoogle,
  signOut,
  isFetched,
  saveUserAction,
  SIGN_OUT_SUCCESS,
  IS_FETCHED,
};
