import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CanvasContext from '../Contexts/CanvasContext';
import FreeLineDrawingLayer from './drawingLayers/FreeLineDrawingLayer';
import RectangleDrawingLayer from './drawingLayers/shapes/RectangleDrawingLayer';
import CircleDrawingLayer from './drawingLayers/shapes/CircleDrawingLayer';
import EllipseDrawingLayer from './drawingLayers/shapes/EllipseDrawingLayer';
import LineDrawingLayer from './drawingLayers/shapes/LineDrawingLayer';
import MarkerDrawingLayer from './drawingLayers/MarkerDrawingLayer';
import EraseDrawingLayer from './drawingLayers/EraseDrawingLayer';
import PointerDrawingLayer from './drawingLayers/PointerDrawingLayer';
import InteractionsLayer from './drawingLayers/InteractionsLayer';
import * as drawingActionsActions from '../../../common/actions/board/drawingActionsActions';
import ImageDrawingLayer from './drawingLayers/ImageDrawingLayer';
import SelectDrawingLayer from './drawingLayers/SelectDrawingLayer';
import ArrowDrawingLayer from './drawingLayers/shapes/ArrowDrawingLayer';
import { getCurrentDrawing } from '../../../common/reducers/board/currentDrawingReducer';
import { getSelectedDrawingTool } from '../../../common/reducers/board/drawingToolReducer';
import * as drawingToolActions from '../../../common/actions/board/drawingToolActions';
import { setLineCurrentDrawing } from '../../../common/actions/board/currentDrawingAction';
import { incrementCanvasTouch } from '../../../common/actions/session/sessionActions';
import TextLayer from '../DrawingLayer/TextLayer';
import TriangleDrawingLayer from './drawingLayers/shapes/TriangleDrawingLayer';
import PathShapesDrawingLayer from './drawingLayers/shapes/PathShapesDrawingLayer';
import FormulaLayer from './drawingLayers/FormulaLayer';
import GraphLayer from './drawingLayers/GraphLayer';
import useCursorPositionUpdate from '../../../common/hooks/useCursorPositionUpdate';

const UILayer = ({
  width,
  height,
  dimensions,
  drawingEnabled,
  currentPageId,
  setRef,
  addEventListeners,
  removeEventListeners,
}) => {
  const dispatch = useDispatch();

  const { originX, originY, scale, zoom, onZoom, onMove, originOffsetY } =
    useContext(CanvasContext);

  const tool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);
  const selectedTool = useSelector(getSelectedDrawingTool);

  const addActionAndSend = useCallback(
    (action) => dispatch(drawingActionsActions.addActionAndSend(action)),
    [dispatch]
  );
  const selectDrawingTool = useCallback(
    (name) => dispatch(drawingToolActions.selectDrawingTool(name)),
    [dispatch]
  );
  const setCurrentDrawing = useCallback(
    (action) => dispatch(setLineCurrentDrawing(action)),
    [dispatch]
  );
  const updateDrawingTool = useCallback(
    (name, props) =>
      dispatch(drawingToolActions.updateDrawingTool(name, props)),
    [dispatch]
  );

  const onCursorPositionUpdate = useCursorPositionUpdate();

  const onPointerDown = useCallback(() => {
    dispatch(incrementCanvasTouch());
  }, [dispatch]);

  const { name, color, size = {}, fill, previousDrawingTool } = tool;

  const drawerProps = useMemo(
    () => ({
      shapeType: name,
      color,
      size: size.value,
      fill,
      width,
      height,
      addActionAndSend,
      previousDrawingTool,
      selectDrawingTool,
      setCurrentDrawing,
      updateDrawingTool,
      dimensions,
      currentPageId,
      setRef,
      tool,
      onCursorPositionUpdate,
      originX,
      originY,
      originOffsetY,
      scale,
      zoom,
      onZoom,
      onMove,
      onPointerDown,
    }),
    [
      originX,
      originY,
      originOffsetY,
      scale,
      zoom,
      onZoom,
      onMove,
      tool,
      width,
      height,
      addActionAndSend,
      previousDrawingTool,
      selectDrawingTool,
      setCurrentDrawing,
      updateDrawingTool,
      dimensions,
      currentPageId,
      setRef,
      onCursorPositionUpdate,
      onPointerDown,
    ]
  );

  if (!drawingEnabled) {
    return (
      <InteractionsLayer onCursorPositionUpdate={onCursorPositionUpdate} />
    );
  }

  if (tool.pathData) {
    return (
      <PathShapesDrawingLayer
        currentDrawing={currentDrawing}
        {...drawerProps}
      />
    );
  }
  switch (name) {
    case 'pen':
      return <FreeLineDrawingLayer {...drawerProps} />;
    case 'marker':
      return <MarkerDrawingLayer {...drawerProps} />;
    case 'eraser':
      return <EraseDrawingLayer {...drawerProps} />;
    case 'rectangle':
      return (
        <RectangleDrawingLayer
          currentDrawing={currentDrawing}
          {...drawerProps}
        />
      );
    case 'triangle':
      return (
        <TriangleDrawingLayer
          currentDrawing={currentDrawing}
          {...drawerProps}
        />
      );
    case 'circle':
      return (
        <CircleDrawingLayer currentDrawing={currentDrawing} {...drawerProps} />
      );
    case 'oval':
      return (
        <EllipseDrawingLayer currentDrawing={currentDrawing} {...drawerProps} />
      );
    case 'line':
      return (
        <LineDrawingLayer currentDrawing={currentDrawing} {...drawerProps} />
      );
    case 'arrow':
      return (
        <ArrowDrawingLayer currentDrawing={currentDrawing} {...drawerProps} />
      );
    case 'pointer':
      return <PointerDrawingLayer {...drawerProps} />;
    case 'image':
      return (
        <ImageDrawingLayer {...drawerProps} currentDrawing={currentDrawing} />
      );
    case 'select':
      return <SelectDrawingLayer {...drawerProps} />;
    case 'text':
      return (
        <TextLayer
          currentDrawing={currentDrawing}
          addEventListeners={addEventListeners}
          removeEventListeners={removeEventListeners}
          {...drawerProps}
        />
      );
    case 'move':
      return (
        <InteractionsLayer onCursorPositionUpdate={onCursorPositionUpdate} />
      );
    case 'formula':
      return (
        <FormulaLayer
          currentDrawing={currentDrawing}
          selectedTool={selectedTool}
          {...drawerProps}
        />
      );
    case 'graph':
      return (
        <GraphLayer
          currentDrawing={currentDrawing}
          selectedTool={selectedTool}
          {...drawerProps}
        />
      );
    default:
      return null;
  }
};

export default UILayer;
