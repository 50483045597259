import { createSelector } from 'reselect';
import {
  ADD_PARTICIPANTS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SET_PROFILE_URL,
  SET_DRAWING_ENABLED,
} from '../../actions/session/participantsActions';
import {
  CLEAR_SESSION,
  SESSION_DISCONNECTED,
} from '../../actions/session/sessionActions';
import ParticipantColorManager from '../../utils/participant-color-manager';
import { contactsSelector } from '../contactsReducer';

export default (state = [], action) => {
  switch (action.type) {
    case ADD_PARTICIPANTS:
      return [
        ...state,
        ...action.participants.map((p) => ({
          ...p,
          color: ParticipantColorManager.getNextColor(),
        })),
      ];
    case ADD_PARTICIPANT:
      return [
        ...state,
        {
          ...action.participant,
          color: ParticipantColorManager.getNextColor(),
        },
      ];
    case SET_PROFILE_URL:
      return state.map((participant) => {
        if (participant.id !== action.id) return participant;

        return {
          ...participant,
          profileUrl: action.imageUrl,
        };
      });
    case SET_DRAWING_ENABLED:
      return state.map((participant) => {
        if (participant.id !== action.id) return participant;

        return {
          ...participant,
          drawingEnabled: action.drawingEnabled,
        };
      });
    case REMOVE_PARTICIPANT:
      return state.filter(
        (participant) => participant.id !== action.participantId
      );
    case UPDATE_PARTICIPANT:
      return state.map((participant) => {
        if (participant.id === action.participant.id)
          return {
            ...action.participant,
            cursorColor: participant.cursorColor,
          };
        return participant;
      });
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return [];
    default:
      return state;
  }
};

const participantsSelector = (state) => state.session.participants;

const participantSelector = (id) =>
  createSelector([(state) => state.session.participants], (participants) =>
    participants.find((participant) => participant.id === id)
  );

const participantsWithoutHostSelector = createSelector(
  participantsSelector,
  (state) => state.session.ownerId,
  (participants, ownerId) => participants.filter((p) => p.id !== ownerId)
);

const invitedParticipantsSelector = createSelector(
  [(state) => state.session.invites, contactsSelector],
  (invites, contacts) => {
    return [
      ...invites.contacts
        .map((contactId) => contacts[contactId])
        .filter((contact) => !!contact),
      ...invites.emails.map((email) => ({
        id: email,
        name: email,
        email,
        pending: true,
      })),
    ];
  }
);

export {
  participantsSelector,
  participantSelector,
  participantsWithoutHostSelector,
  invitedParticipantsSelector,
};
