import { DisplayObject, Graphics, Rectangle as PixiRectangle } from 'pixi.js';
import Shape from './shape';
import OldRectangleAction from '../../../common/drawingActions/shapes/rectangle.drawing.action';
import ActionName from '../types/ActionName';

class Rectangle extends Shape {
  constructor(action?: OldRectangleAction) {
    super(action);

    this.name = ActionName.RECTANGLE;
  }

  toDto(): OldRectangleAction {
    const dto = new OldRectangleAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldRectangleAction) {
    super.setFields(action);
    action.name = ActionName.RECTANGLE;
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    graphicsObject.clear();
    graphicsObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    if (!this.paint.fill) {
      graphicsObject.lineStyle(size, color);
    } else {
      graphicsObject.beginFill(color);
    }

    const absoluteWidth = Math.abs(width);
    const absoluteHeight = Math.abs(height);

    graphicsObject.drawRect(
      -absoluteWidth / 2,
      -absoluteHeight / 2,
      absoluteWidth,
      absoluteHeight
    );

    if (this.paint.fill) {
      graphicsObject.endFill();
    } else {
      graphicsObject.hitArea = new PixiRectangle(
        -(absoluteWidth + size) / 2,
        -(absoluteHeight + size) / 2,
        absoluteWidth,
        absoluteHeight
      );
    }
  }
}

export default Rectangle;
