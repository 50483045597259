enum ToolNames {
  Arrow = 'arrow',
  Circle = 'circle',
  Eraser = 'eraser',
  Formula = 'formula',
  Graph = 'graph',
  Function = 'function',
  Image = 'image',
  ImportPdf = 'importPdf',
  Line = 'line',
  Marker = 'marker',
  Move = 'move',
  Oval = 'oval',
  Pen = 'pen',
  Pointer = 'pointer',
  Rectangle = 'rectangle',
  Select = 'select',
  Shape = 'shape',
  Text = 'text',
  Triangle = 'triangle',
}

export default ToolNames;
