import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import BaseTool from './BaseTool';
import * as currentDrawingReducer from '../../../common/reducers/board/currentDrawingReducer';
import * as drawingToolReducer from '../../../common/reducers/board/drawingToolReducer';
import * as drawingToolActions from '../../../common/actions/board/drawingToolActions';
import * as currentDrawingAction from '../../../common/actions/board/currentDrawingAction';
import ToolNames from '../ToolNames';
import keyMap from '../../../config/hotkeys';
import { boardTypeSelector } from '../../../common/reducers/session/sessionReducer';
import ArrowIcon from '../../../ui/atoms/Icons/Shapes/ArrowIcon';
import TriangleIcon from '../../../ui/atoms/Icons/Shapes/TriangleIcon';
import CircleIcon from '../../../ui/atoms/Icons/Shapes/CircleIcon';
import LineIcon from '../../../ui/atoms/Icons/Shapes/LineIcon';
import OvalIcon from '../../../ui/atoms/Icons/Shapes/OvalIcon';
import RectangleIcon from '../../../ui/atoms/Icons/Shapes/RectangleIcon';
import ShapesIcon from '../../../ui/atoms/Icons/Shapes/ShapesIcon';
import PathShapeIcon from '../../../ui/atoms/Icons/Shapes/PathShapeIcon';
import ToolOptionsLayout from '../ToolOptionsLayout/ToolOptionsLayout';
import DrawingButton from '../DrawingButton/DrawingButton';
import analyticsService from '../../../common/services/analytics.service';

const ShapeToolBox = ({ Icon, onClick, disabled, active, iconProps = {} }) => (
  <DrawingButton
    icon={<Icon {...iconProps} />}
    onClick={onClick}
    active={active}
    disabled={disabled}
  />
);

const settingsFactory = ({
  shapeToolsData,
  isCurrentDrawingShapeDrawing,
  activeTool,
  pathShapeTools,
  selectTool,
  selectedTool,
}) => (
  <ToolOptionsLayout width={264}>
    <Box display="flex" gap={0.75} flexWrap="wrap">
      {shapeToolsData.map(({ name, onClick, Icon }) => (
        <ShapeToolBox
          key={name}
          Icon={Icon}
          onClick={onClick}
          disabled={isCurrentDrawingShapeDrawing}
          active={activeTool === name}
        />
      ))}
      {pathShapeTools.map((pathShapeTemplate) => (
        <ShapeToolBox
          key={pathShapeTemplate.id}
          Icon={PathShapeIcon}
          onClick={() => {
            analyticsService.event('Tool Selected', {
              tool: 'customShape',
            });
            selectTool(pathShapeTemplate.id);
          }}
          iconProps={{
            pathData: pathShapeTemplate.pathData,
            viewBoxWidth: pathShapeTemplate.viewBoxWidth,
            viewBoxHeight: pathShapeTemplate.viewBoxHeight,
          }}
          disabled={isCurrentDrawingShapeDrawing}
          active={
            activeTool === pathShapeTemplate.id ||
            selectedTool.id === pathShapeTemplate.id
          }
        />
      ))}
    </Box>
  </ToolOptionsLayout>
);

const ShapeTool = ({ activeTool, selectedTool, selectTool }) => {
  const isCurrentDrawingShapeDrawing = useSelector(
    currentDrawingReducer.isCurrentDrawingShapeDrawingSelector
  );
  const pathShapeTools = useSelector(drawingToolReducer.getPathShapeTools);
  const boardType = useSelector(boardTypeSelector);
  const dispatch = useDispatch();
  const fetchPathShapeTools = () =>
    dispatch(drawingToolActions.fetchPathShapeTools(boardType));
  const setCurrentDrawing = (action) =>
    dispatch(currentDrawingAction.setLineCurrentDrawing(action));

  useEffect(() => {
    fetchPathShapeTools();
  }, []);

  const selectArrow = () => {
    analyticsService.event('Tool Selected', {
      tool: 'arrow',
    });
    selectTool(ToolNames.Arrow);
  };
  const selectLine = () => {
    analyticsService.event('Tool Selected', {
      tool: 'line',
    });
    selectTool(ToolNames.Line);
  };
  const selectOval = () => {
    analyticsService.event('Tool Selected', {
      tool: 'oval',
    });
    selectTool(ToolNames.Oval);
  };
  const selectCircle = () => {
    analyticsService.event('Tool Selected', {
      tool: 'circle',
    });
    selectTool(ToolNames.Circle);
  };
  const selectRectangle = () => {
    analyticsService.event('Tool Selected', {
      tool: 'rectangle',
    });
    selectTool(ToolNames.Rectangle);
  };
  const selectTriangle = () => {
    analyticsService.event('Tool Selected', {
      tool: 'triangle',
    });
    selectTool(ToolNames.Triangle);
  };

  const shapeToolsData = useMemo(
    () => [
      {
        name: ToolNames.Arrow,
        onClick: selectArrow,
        Icon: ArrowIcon,
      },
      {
        name: ToolNames.Line,
        onClick: selectLine,
        Icon: LineIcon,
      },
      {
        name: ToolNames.Oval,
        onClick: selectOval,
        Icon: OvalIcon,
      },
      {
        name: ToolNames.Circle,
        onClick: selectCircle,
        Icon: CircleIcon,
      },
      {
        name: ToolNames.Rectangle,
        onClick: selectRectangle,
        Icon: RectangleIcon,
      },
      {
        name: ToolNames.Triangle,
        onClick: selectTriangle,
        Icon: TriangleIcon,
      },
    ],
    [selectArrow, selectLine, selectCircle, selectRectangle, selectTriangle]
  );

  const renderSelectedTool = () => {
    switch (activeTool) {
      case ToolNames.Arrow:
        return <ArrowIcon />;
      case ToolNames.Line:
        return <LineIcon />;
      case ToolNames.Oval:
        return <OvalIcon />;
      case ToolNames.Circle:
        return <CircleIcon />;
      case ToolNames.Rectangle:
        return <RectangleIcon />;
      case ToolNames.Triangle:
        return <TriangleIcon />;
      case '':
        return <ShapesIcon />;
      default:
        const tool = pathShapeTools.find((t) => t.id === activeTool);
        if (!tool) return null;
        return (
          <PathShapeIcon
            pathData={tool.pathData}
            viewBoxWidth={tool.viewBoxWidth}
            viewBoxHeight={tool.viewBoxHeight}
          />
        );
    }
  };

  const icon = renderSelectedTool();
  const shapeTools = [
    ToolNames.Oval,
    ToolNames.Rectangle,
    ToolNames.Circle,
    ToolNames.Line,
    ToolNames.Arrow,
    ToolNames.Triangle,
  ];
  const showSettings =
    shapeTools.indexOf(selectedTool.name) !== -1 ||
    pathShapeTools.findIndex(
      (t) => t.id === selectedTool.name || selectedTool.id === t.id
    ) !== -1;

  const settings = useMemo(
    () =>
      settingsFactory({
        shapeToolsData,
        isCurrentDrawingShapeDrawing,
        activeTool,
        pathShapeTools,
        selectTool,
        selectedTool,
      }),
    [
      shapeToolsData,
      isCurrentDrawingShapeDrawing,
      activeTool,
      pathShapeTools,
      selectTool,
      selectedTool,
    ]
  );

  return (
    <BaseTool
      icon={icon}
      tooltipText={`Shapes (${keyMap.shape.description})`}
      settings={settings}
      selectedTool={selectedTool}
      activeTool={activeTool}
      selectTool={(tool) => {
        setCurrentDrawing(null);
        selectTool(tool);
      }}
      showSettings={showSettings}
      showToolOptionsIcon={true}
    />
  );
};

export default ShapeTool;
