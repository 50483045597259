import { hexToRgb } from '../../../../common/utils/color.utils';

const transformDashArray = (dashArray: number[]) =>
  dashArray.reduce((transformedData, current, index) => {
    if (index !== dashArray.length - 1) {
      transformedData += `${current}%2c `;

      return transformedData;
    }

    return transformedData + current;
  }, '');

type GenerateBorderImageStyleProps = {
  strokeWidth: number;
  strokeColor: string;
  dashArray: number[];
};

const getRgbColor = (color: string) => {
  const { r, g, b } = hexToRgb(color);

  return `rgb(${r}, ${g}, ${b})`;
};

const generateBorderImageStyle = ({
  strokeWidth,
  strokeColor,
  dashArray,
}: GenerateBorderImageStyleProps) => {
  const borderColor = strokeColor.includes('#')
    ? getRgbColor(strokeColor)
    : strokeColor;

  const transformedDashData = transformDashArray(dashArray);
  return {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${borderColor}' stroke-width='${strokeWidth}' stroke-dasharray='${transformedDashData}' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
  };
};

export default generateBorderImageStyle;
