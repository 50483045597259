import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiAvatar, { avatarClasses } from '@mui/material/Avatar';
import { AvatarBorderTypes, AvatarCustomProps } from './types';

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== 'border',
})<AvatarCustomProps>(({ theme, border = AvatarBorderTypes.NONE }) => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  [`&.${avatarClasses.root}`]: {
    backgroundColor: theme.background.bg5,
    color: theme.text.t8,
  },
  ...(border === AvatarBorderTypes.NONE && { borderColor: 'transparent' }),
  ...(border === AvatarBorderTypes.DEFAULT && {
    borderColor: theme.palette.primary.alpha50,
  }),
  ...(border === AvatarBorderTypes.ACTIVE && {
    borderColor: theme.palette.primary.dark5,
  }),
}));

const Avatar = forwardRef<HTMLDivElement, AvatarCustomProps>(
  ({ src, alt, color, children, ...props }, ref) => {
    if (src) {
      return (
        <StyledAvatar
          {...props}
          alt={alt}
          src={src}
          ref={ref}
          sx={{
            borderColor: color,
          }}
        />
      );
    }

    return (
      <StyledAvatar
        {...props}
        ref={ref}
        sx={{
          borderColor: color,
        }}
      >
        {children || (alt && alt.charAt(0).toUpperCase())}
      </StyledAvatar>
    );
  }
);

export default Avatar;
