import { httpServiceV4 } from './http.service';
import FeatureToursDto from './dto/featureTours/FeatureToursDto';

const getFeatureTours = (): Promise<FeatureToursDto> => (
  httpServiceV4.get<FeatureToursDto, FeatureToursDto>('/feature-tour')
);

const postFeatureTourSeen = (feature: string, version: number | null): Promise<FeatureToursDto> => (
  httpServiceV4.post<FeatureToursDto, FeatureToursDto>('/feature-tour', { feature, version })
);

export default {
  getFeatureTours,
  postFeatureTourSeen,
};
