import { SET_SHOULD_UPDATE_THUMBNAIL } from '../../actions/board/shouldUpdateThumbnailActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';

const initialState = false;
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOULD_UPDATE_THUMBNAIL:
      return action.value;
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const shouldUpdateThumbnailSelector = state => state.board.shouldUpdateThumbnail;

export { shouldUpdateThumbnailSelector };
