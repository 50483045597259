import React from 'react';
import { MemoryRouter } from 'react-router';
import { AssessmentPaperSidebarProvider } from './components/AssessmentPaperSidebarProvider';
import { AssessmentPaperRoutes } from './routes';

export const AssessmentPaperTool = () => {
  return (
    <MemoryRouter>
      <AssessmentPaperSidebarProvider>
        <AssessmentPaperRoutes />
      </AssessmentPaperSidebarProvider>
    </MemoryRouter>
  );
};
