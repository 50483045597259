import ShapeDrawingAction from './shape.drawing.action';
import * as ovalUtils from '../../utils/shapes/oval.util';

export default class OvalDrawingAction extends ShapeDrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'oval';
  }

  intersect(point) {
    return ovalUtils.intersect(point, this.startPoint, this.endPoint);
  }
}
