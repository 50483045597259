import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import RecordingContext from '../../Recording/RecordingContext';
import ViewRecordingDialog from '../../../containers/Modals/ViewRecordingDialog';
import RecordingControls from './RecordingControls';
import BoardType from '../../../common/services/types/session/BoardType';
import * as sessionReducer from '../../../common/reducers/session/sessionReducer';

type RecordingToolsProps = {
  collaborationBarExpanded: boolean;
  tooltipPlacment: 'top' | 'left';
};

const RecordingTools: FC<React.PropsWithChildren<RecordingToolsProps>> = ({
  collaborationBarExpanded,
  tooltipPlacment,
}) => {
  const sessionId = useSelector(sessionReducer.sessionIdSelector);
  const sessionName = useSelector(sessionReducer.nameSelector);
  const finishedSession = useSelector(sessionReducer.finishedSelector);
  const groupId = useSelector(sessionReducer.groupIdSelector);

  const { isRecordingFeatureEnabled, thumbnail, blob, clearBlob } =
    useContext(RecordingContext);

  return (
    <>
      {isRecordingFeatureEnabled && !finishedSession && (
        <RecordingControls
          boardType={BoardType.BASIC}
          collaborationBarExpanded={collaborationBarExpanded}
          tooltipPlacment={tooltipPlacment}
        />
      )}
      {blob && (
        <ViewRecordingDialog
          open={!!blob}
          thumbnail={thumbnail}
          blob={blob}
          sessionId={sessionId}
          groupId={groupId}
          name={sessionName}
          onClose={clearBlob}
        />
      )}
    </>
  );
};

export default RecordingTools;
