import {
  SIGN_OUT_SUCCESS,
  IS_FETCHED,
} from '../actions/authActions';
import { SET_USER } from '../actions/userActions';

const initialState = {
  isFetched: false,
  isFetching: false,
  isAuthenticated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHED:
      return {
        ...state,
        isFetched: true,
        isAuthenticated: action.payload,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      };
    case SET_USER:
      return {
        ...state,
        isFetched: !!action.user,
        isAuthenticated: !!action.user,
      };
    default:
      return state;
  }
};
