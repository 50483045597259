import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ToolNames from '../ToolNames';
import { boardTypeSelector } from '../../../common/reducers/session/sessionReducer';
import SessionContext from '../../Session/SessionContext';
import BoardType from '../../../common/services/types/session/BoardType';
import PointerIcon from '../../../ui/atoms/Icons/Shapes/PointerIcon';
import DrawingButton from '../DrawingButton/DrawingButton';
import { SelectedTool } from './types/SelectToolType';
import analyticsService from '../../../common/services/analytics.service';

type PointerToolProps = {
  selectedTool: SelectedTool;
  selectTool: (name: string) => void;
};

const PointerTool: FC<React.PropsWithChildren<PointerToolProps>> = ({
  selectedTool,
  selectTool,
}) => {
  const boardType = useSelector(boardTypeSelector);
  const { openInfiniteDialog } = useContext(SessionContext);

  const selectPointerTool = useCallback(() => {
    if (boardType === BoardType.INFINITE) {
      openInfiniteDialog();
      return;
    }

    analyticsService.event('Tool Selected', {
      tool: 'pointer',
    });
    selectTool(ToolNames.Pointer);
  }, [boardType, selectTool, openInfiniteDialog]);

  const isActive = useMemo(
    () => selectedTool.name === ToolNames.Pointer,
    [selectedTool]
  );

  return (
    <DrawingButton
      icon={<PointerIcon />}
      onClick={selectPointerTool}
      tooltipTitle="Pointer (N)"
      tooltipPlacement="bottom"
      active={isActive}
    />
  );
};

export default PointerTool;
