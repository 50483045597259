import guid from '../../../common/utils/guid.util';
import OldAction from '../../../common/drawingActions/action';
import Point from '../types/Point';

class Action {
  name: string | null = null;

  createdTime: number | null = null;

  creatorId: string | null = null;

  duration: number = 0;

  id: string | null = null;

  pageNumber: number | null = null;

  // deprecated?
  receiverId: string | null = null;

  drawingId: string | null = null;

  order: number | null = null;

  key: string | null = null;

  constructor(action?: OldAction) {
    if (!action) {
      this.key = guid();
      this.id = guid();

      return;
    }

    this.name = action.name;
    this.createdTime = action.createdTime;
    this.creatorId = action.creatorId;
    this.duration = action.duration || 0;
    this.id = action.id;
    this.pageNumber = action.pageNumber;
    this.receiverId = action.receiverId;
    this.drawingId = action.drawingId;
    this.key = action.key;
  }

  update(startPos: Point, endPos: Point) {}

  toDto(): OldAction {
    const dto = new OldAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldAction) {
    action.name = this.name;
    action.createdTime = this.createdTime;
    action.creatorId = this.creatorId;
    action.duration = this.duration;
    action.id = this.id;
    action.pageNumber = this.pageNumber;
    action.receiverId = this.receiverId;
    action.drawingId = this.drawingId;
    action.key = this.key;
  }

  createMoveAction(newPos: Point): Action {
    throw new Error('Not implemented');
  }
}

export default Action;
