import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { differenceBy } from 'lodash';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import ContactsSelect from '../ContactsSelect';
import { getGroupSelector } from '../../../common/reducers/groupsReducer';
import { ButtonVariantTypes } from '../../atoms/Button/buttonTypes';
import MemberItem from '../../atoms/MemberItem';
import groupsService from '../../../common/services/groups.service';
import * as groupsActions from '../../../common/actions/groupsActions';
import Button from '../../atoms/Button';

type Props = {
  open: boolean;
  onClose: () => void;
  count: number;
  groupId: string;
  groupName: string;
  isHost: boolean;
};

const GroupMembersDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  count,
  groupId,
  groupName,
  isHost,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const groupAndContactsSelector = useMemo(
    () => getGroupSelector(groupId),
    [groupId]
  );
  const group = useSelector(groupAndContactsSelector);

  const updateGroup = useCallback(
    (updatedGroup: any) => dispatch(groupsActions.updateGroup(updatedGroup)),
    [dispatch]
  );

  const onAddClick = useCallback(
    async (members: any) => {
      if (!members.length) return;

      try {
        setIsSubmitting(true);
        const oldContacts = group.contacts || [];

        const emailsToAdd = differenceBy(
          members.filter((contact: any) => contact.email),
          oldContacts,
          'email'
        ).map((contact: any) => contact.email);
        const idsToAdd = differenceBy(
          members.filter((contact: any) => contact.id),
          oldContacts,
          'id'
        ).map((contact: any) => contact.id);

        const updatedGroup = await groupsService.editGroup(
          group.id,
          groupName,
          emailsToAdd,
          idsToAdd,
          []
        );

        updateGroup(updatedGroup);
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        enqueueSnackbar('Unexpected error occurred, please try again', {
          autoHideDuration: 3000,
          variant: 'error',
        });
      }
    },
    [group, groupName, updateGroup, setIsSubmitting, enqueueSnackbar]
  );

  const onRemoveClick = useCallback(
    async (contactToDelete: any) => {
      try {
        const updatedGroup = await groupsService.editGroup(
          group.id,
          groupName,
          [],
          [],
          [contactToDelete.id || contactToDelete.email]
        );

        updateGroup(updatedGroup);
      } catch (error) {
        enqueueSnackbar('Unexpected error occurred, please try again', {
          autoHideDuration: 3000,
          variant: 'error',
        });
      }
    },
    [group, groupName, updateGroup]
  );

  const onDialogClose = useCallback(() => {
    if (isSubmitting) return;
    onClose();
  }, [isSubmitting, onClose]);

  const onDeleteIconClick = useCallback(() => {
    if (isSubmitting) return;

    onClose();
  }, [isSubmitting, onClose]);

  return (
    <Dialog open={open} onClose={onDialogClose}>
      <DialogTitle onClose={onDeleteIconClick}>
        {`Group Members (${count})`}
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: 'hidden',
        }}
      >
        {isHost && (
          <ContactsSelect
            source="editGroup"
            contacts={(group && group.contacts) || []}
            onAddMembers={onAddClick}
            onRemoveMember={onRemoveClick}
            InviteButton={(props) => (
              <Button
                {...props}
                disabled={isSubmitting}
                loading={isSubmitting}
                variant={ButtonVariantTypes.PRIMARY}
              >
                Add
              </Button>
            )}
          />
        )}
        {!isHost && group.contacts && (
          <>
            {group.contacts.map((contact: any) => (
              <MemberItem
                key={contact.name}
                avatarSrc={contact.profileImageUrl}
                name={contact.name}
                email={contact.email}
              />
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GroupMembersDialog;
