export default class ShapeControl {
  cursor = 'default';
  startPoint;
  endPoint;
  scale;
  padding;
  rotationAngle;

  constructor(startPoint, endPoint, scale, padding, rotationAngle = 0) {
    this.startPoint = startPoint;
    this.endPoint = endPoint;
    this.scale = scale;
    this.padding = padding;
    this.rotationAngle = rotationAngle;
  }

  getWidth() {
    return this.endPoint.x - this.startPoint.x;
  }

  getHeight() {
    return this.endPoint.y - this.startPoint.y;
  }

  draw() {
    throw new Error('Not implemented');
  }

  hitTest() {
    throw new Error('Not implemented');
  }

  onHover(ctx) {
    ctx.canvas.style.cursor = this.cursor;
    ctx.canvas.style.cursor = `-webkit-${this.cursor}`;
    ctx.canvas.style.cursor = `-moz-${this.cursor}`;
  }
}
