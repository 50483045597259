const getPlatform = () => {
  const { platform } = navigator;

  if (platform.indexOf('Win') !== -1) return 'Win';
  if (platform.indexOf('Mac') !== -1) return 'Mac';

  return null;
};

const isMac = () => getPlatform() === 'Mac';

export default { getPlatform, isMac };
