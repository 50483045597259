import { FC, memo } from 'react';
import CallProvider from './CallProvider';
import CallTools from './CallTools/CallTools';

type CallProps = {
  tooltipPlacment: 'top' | 'left';
  collaborationBarExpanded: boolean;
};

const Call: FC<React.PropsWithChildren<CallProps>> = ({
  tooltipPlacment,
  collaborationBarExpanded,
}) => (
  <CallProvider>
    <CallTools
      tooltipPlacment={tooltipPlacment}
      collaborationBarExpanded={collaborationBarExpanded}
    />
  </CallProvider>
);

export default memo(Call);
