import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';
import ProfileImage from '../../../../common/ProfileImage/ProfileImage';
import './Message.scss';

const Message = ({
  text,
  me,
  profileImage,
  createdTime,
  sender,
  showInfo,
}) => {
  const { avatar, time, senderName } = showInfo;
  const classNames = clsx('message-content', {
    me,
  });

  return (
    <div className={classNames}>
      <div className="sender-info">
        { avatar && <ProfileImage src={profileImage} alt={sender} width={40} height={40} /> }
        { time && <span className="message-created-time">{ format(createdTime, 'hh:mm a') }</span>}
      </div>
      <div className="sender-message">
        { senderName && !me && <span className="sender-name">{sender}</span> }
        <span className="message-text">
          {text}
        </span>
      </div>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string.isRequired,
  me: PropTypes.bool.isRequired,
  showInfo: PropTypes.objectOf(PropTypes.bool).isRequired,
  profileImage: PropTypes.string,
  createdTime: PropTypes.number.isRequired,
};

Message.defaultProps = {
  profileImage: '',
};

export default Message;
