import type ServerToClientCursorPayload from '../../types/serverToClientCursorPayload';
import type CursorDetail from '../../types/cursorDetail';

const UPDATE_CURSOR_ACTION = Symbol('UPDATE_CURSOR_ACTION');
const SET_CURRENT_CURSOR_ACTION = Symbol('SET_CURRENT_CURSOR_ACTION');

const updateCursorAction = (payload: ServerToClientCursorPayload) => ({
  type: UPDATE_CURSOR_ACTION,
  payload,
});

const setCurrentCursorAction = (action: CursorDetail | null) => ({
  type: SET_CURRENT_CURSOR_ACTION,
  payload: action,
});

export {
  updateCursorAction,
  setCurrentCursorAction,
  UPDATE_CURSOR_ACTION,
  SET_CURRENT_CURSOR_ACTION,
};
