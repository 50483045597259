import { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import CardContentComponent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Card from '../Card';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  hasThumbnail?: boolean;
};

const LoadingCard: FC<React.PropsWithChildren<Props>> = ({
  hasThumbnail = false,
}) => (
  <CardGridItem>
    <Card
      sx={{
        borderTopLeftRadius: '30px',
        '&:hover': {
          cursor: 'unset',
          boxShadow: 'unset',
        },
      }}
    >
      {hasThumbnail && (
        <Skeleton
          animation="wave"
          height="auto"
          sx={(theme) => ({
            aspectRatio: '326/164',
            backgroundColor: theme.background.bg4,
            transform: 'unset',
            borderRadius: 'unset',
          })}
        />
      )}
      <CardContentComponent
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexDirection: 'column',
          gap: '10px',
          bgcolor: '#FFFFFF',
          border: '1px solid',
          borderColor: 'primary.alpha10',
          padding: '9px 19px 15px 19px !important',
          borderRadius: '20px 10px',
          width: '100%',
          height: '122px',
          transition: 'box-shadow 0.5s',
          fontSize: '2.5rem',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Skeleton
              height={20}
              animation="wave"
              sx={(theme) => ({
                transform: 'unset',
                borderRadius: 5.5,
                backgroundColor: theme.background.bg4,
              })}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '8px',
            height: '46px',
          }}
        >
          <Skeleton
            width={30}
            height={30}
            animation="wave"
            sx={(theme) => ({
              borderRadius: 1,
              backgroundColor: theme.background.bg4,
            })}
          />
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <Skeleton
              width={181}
              height={14}
              animation="wave"
              sx={(theme) => ({
                transform: 'unset',
                backgroundColor: theme.background.bg4,
                borderColor: 4.5,
              })}
            />
            <Skeleton
              height={14}
              animation="wave"
              sx={(theme) => ({
                transform: 'unset',
                backgroundColor: theme.background.bg4,
              })}
            />
          </Box>
        </Box>
      </CardContentComponent>
    </Card>
  </CardGridItem>
);

export default LoadingCard;
