import { FC, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

type Props = {
  imageSrc: string;
  fallbackSrc: string;
  altText: string;
  hasBorder?: boolean;
  width?: number | string;
  height?: number | string;
};

const ImageWrapper: FC<React.PropsWithChildren<Props>> = ({
  imageSrc,
  fallbackSrc,
  altText,
  width = '100%',
  height = 'auto',
  hasBorder = false,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onError = useCallback(() => {
    setError(true);
  }, [setError]);

  const onLoad = useCallback(() => {
    setLoading(false);
    setError(false);
  }, [setLoading, setError]);

  const onLoadStart = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  useEffect(() => {
    setError(false);
  }, [setError, imageSrc]);

  return (
    <Box
      sx={{
        aspectRatio: '326 / 164',
        display: 'flex',
        width,
        height,
        padding: 0,
      }}
    >
      {!error && (
        <img
          src={imageSrc}
          loading="lazy"
          alt={altText}
          onError={onError}
          onLoad={onLoad}
          onLoadStart={onLoadStart}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            pointerEvents: 'none',
            borderTopLeftRadius: hasBorder ? '30px' : 'unset',
          }}
        />
      )}
      {loading && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}
        >
          <CircularProgress size={48} />
        </Box>
      )}
      {error && (
        <img
          src={fallbackSrc}
          alt={altText}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            pointerEvents: 'none',
            borderTopLeftRadius: hasBorder ? '30px' : 'unset',
          }}
        />
      )}
    </Box>
  );
};

export default ImageWrapper;
