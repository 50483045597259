import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import MuiListItemButton, {
  listItemButtonClasses,
} from '@mui/material/ListItemButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import {
  paragraphStyle,
  subheadingStyle,
} from '../../atoms/Typography/typographyStyles';

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  width: '378px',
  height: '92px',
  padding: '23px 15px 23px 23px',
  border: `1px solid ${theme.background.bg3}`,
  backgroundColor: theme.background.bg3,
  borderRadius: '12px',
  transition: 'background-color 0.5s',
  [`& .${listItemTextClasses.primary}`]: {
    ...subheadingStyle.s2,
    color: theme.text.t8,
    transition: 'color 0.5s',
  },
  [`& .${listItemTextClasses.secondary}`]: {
    ...paragraphStyle.p5,
    color: theme.text.t7,
    transition: 'color 0.5s',
  },
  [`& .${listItemIconClasses.root}`]: {
    minWidth: 'unset',
    marginRight: theme.spacing(1.75),
  },
  [`&:hover, &.${listItemButtonClasses.selected}`]: {
    backgroundColor: theme.palette.primary.alpha10,
    [`& .${listItemTextClasses.primary}, & .${listItemTextClasses.secondary}, & .${listItemIconClasses.root}`]:
      {
        color: theme.palette.primary.dark5,
      },
  },
  [`&.${listItemButtonClasses.disabled}`]: {
    border: `1px solid ${theme.background.bg4}`,
    backgroundColor: 'transparent',
    [`& .${listItemTextClasses.primary}, & .${listItemTextClasses.secondary}, & .${listItemIconClasses.root}`]:
      {
        color: theme.text.t4,
      },
  },
}));

type Props = {
  Icon: FC<React.PropsWithChildren<SvgIconProps>>;
  title: string;
  subtitle?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
};

const CreateBoardListItem: FC<React.PropsWithChildren<Props>> = ({
  disabled,
  loading,
  selected,
  Icon,
  title,
  subtitle,
  onClick,
}) => (
  <ListItemButton
    disabled={disabled || loading}
    selected={selected}
    disableRipple
    onClick={onClick}
  >
    <ListItemIcon>
      {loading ? (
        <CircularProgress size={36} />
      ) : (
        <Icon sx={{ fontSize: '36px' }} />
      )}
    </ListItemIcon>
    <ListItemText primary={title} secondary={subtitle} />
  </ListItemButton>
);

export default CreateBoardListItem;
