import { useEffect, useState } from 'react';

type UseFetchResult<TData> = {
  isLoading: boolean;
  error: string | null;
  response: TData | null;
};

function useFetch<TData, TParams>(
  fn: (...args: TParams[]) => Promise<TData>,
  ...params: TParams[]
): UseFetchResult<TData> {
  const [response, setResponse] = useState<TData | null>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fn(...params);
        setResponse(res);
        setIsLoading(false);
      } catch (err) {
        setError(err);
      }
    };
    fetchData();
  }, []);
  return { response, error, isLoading };
}

export default useFetch;
