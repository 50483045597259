import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Page from './Page/Page';
import './PagesSideBarList.scss';
import * as pagesActions from '../../../../../common/actions/board/pagesActions';
import { getPageOrders } from '../../../../../common/reducers/board/pagesReducer';
import { reorder } from '../../../../../common/utils/array.utils';

const PAGE_WIDTH = 252;
const PAGE_HEIGHT = 142;

const pageStyles = {
  width: PAGE_WIDTH,
  height: PAGE_HEIGHT,
};

const queryAttr = 'data-rbd-drag-handle-draggable-id';

const getDraggedDom = (draggableId) => {
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  return draggedDOM.nextSibling;
};

const PagesSideBarList = ({
  activePageId,
  pages,
  dimensions,
  onSelect,
  onCopy,
  onDelete,
  editable,
}) => {
  const pageOrders = useSelector(getPageOrders);
  const dispatch = useDispatch();
  const [placeholderProps, setPlaceholderProps] = useState({});
  const reorderPages = useCallback(
    (startIndex, endIndex) => {
      const ordersData = reorder(pageOrders, startIndex, endIndex);
      dispatch(pagesActions.updateOrders(ordersData));
    },
    [dispatch, pageOrders]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;
      if (!destination) return;

      if (source.index === destination.index) return;

      reorderPages(source.index, destination.index);
    },
    [reorderPages]
  );

  const onDragUpdate = useCallback(
    (update) => {
      if (!update.destination) return;

      const destinationIndex = update.destination.index;
      const draggedDOM = getDraggedDom(update.draggableId);
      if (!draggedDOM) return;

      const { offsetHeight, offsetWidth } = draggedDOM;
      const sourceIndex = update.source.index;
      const parentNode = document.querySelector('.pages-sidebar__list');
      const childrenArray = [...parentNode.children];
      const movedItem = childrenArray[sourceIndex];
      const updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
      ];

      let clientY = updatedArray
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

      setPlaceholderProps({
        offsetHeight,
        offsetWidth,
        clientY,
      });
    },
    [setPlaceholderProps]
  );

  const draggablePages = useMemo(
    () =>
      pages.map((page, index) => {
        const boardWidth = page.width ? page.width : dimensions.width;
        const boardHeight = page.height ? page.height : dimensions.height;
        const ratio = Math.min(
          PAGE_WIDTH / boardWidth,
          PAGE_HEIGHT / boardHeight
        );
        const canvasWidth = boardWidth * ratio;
        const canvasHeight = boardHeight * ratio;

        return (
          <Page
            key={page.id}
            width={canvasWidth}
            height={canvasHeight}
            index={index}
            isActive={page.id === activePageId}
            page={page}
            styles={pageStyles}
            onSelect={onSelect}
            onCopy={onCopy}
            onDelete={onDelete}
            copyEnabled={editable}
            deleteEnabled={editable && pages.length > 1}
            isDeletePageDisabled={pages.length === 1}
            scale={ratio}
          />
        );
      }),
    [pages, dimensions, activePageId, editable]
  );

  const droppableChildren = useCallback(
    (provided, dropSnapshot) => (
      <div className="pages-sidebar__list-wrapper">
        <div
          className="pages-sidebar__list"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {draggablePages}
          {provided.placeholder}
          {placeholderProps && dropSnapshot.isDraggingOver && (
            <Box
              width={1}
              position="absolute"
              style={{
                top: placeholderProps.clientY,
              }}
              display="flex"
              alignItems="center"
            >
              <Box ml={0.5} mr={0.5} width={24} height={24} />
              <Box
                borderRadius="4px"
                backgroundColor="#e1e2e7"
                style={{
                  height: placeholderProps.offsetHeight,
                  width: placeholderProps.offsetWidth,
                }}
              />
            </Box>
          )}
        </div>
      </div>
    ),
    [draggablePages, placeholderProps]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable children={droppableChildren} droppableId="list" />
    </DragDropContext>
  );
};

export default PagesSideBarList;
