import { FC, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BasePlacement } from '@popperjs/core/lib/enums';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import {
  hexToRgb,
  rgbToArgbNumber,
} from '../../../../../common/utils/color.utils';
import ToolNames from '../../../../DrawingTools/ToolNames';
import { updateDrawingTool } from '../../../../../common/actions/board/drawingToolActions';
import ColorPicker from '../../../../DrawingTools/ColorPicker/ColorPicker';
import { setShapeCurrentDrawing } from '../../../../../common/actions/board/currentDrawingAction';
import { getCurrentDrawing } from '../../../../../common/reducers/board/currentDrawingReducer';

const popperPadding = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const textToolbarElement = document.querySelector('#text-toolbar');
const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      padding: popperPadding,
      fallbackPlacements: ['bottom'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 12],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      boundary: textToolbarElement,
      mainAxis: false,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

type ColorPickerProps = {
  open: boolean;
  anchorEl: {
    getBoundingClientRect: () => DOMRect;
  };
  textToolbarPlacement: BasePlacement | null;
  onChange: () => void;
};

const TextColorPopper: FC<React.PropsWithChildren<ColorPickerProps>> = ({
  open,
  anchorEl,
  textToolbarPlacement,
  onChange,
}) => {
  const dispatch = useDispatch();
  const currentDrawing = useSelector(getCurrentDrawing);

  const updateColor = useCallback(
    (color: string) => {
      const { r, g, b } = hexToRgb(color);
      const argbColor = rgbToArgbNumber({
        a: 255,
        r,
        g,
        b,
      });

      dispatch(updateDrawingTool(ToolNames.Text, { color: argbColor }));
      if (currentDrawing) {
        const clonedCurrentDrawing = cloneDeep(currentDrawing);
        clonedCurrentDrawing.paint.color = argbColor;

        dispatch(setShapeCurrentDrawing(clonedCurrentDrawing));
      }

      onChange();
    },
    [dispatch, currentDrawing, onChange]
  );

  return (
    <Popper
      open={open}
      placement={textToolbarPlacement || 'top'}
      anchorEl={anchorEl}
      modifiers={popperModifiers}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 2px 4px 0px #00000026',
          padding: '5px 8px',
          borderRadius: 4,
        }}
      >
        <ColorPicker onChange={updateColor} />
      </Box>
    </Popper>
  );
};

export default TextColorPopper;
