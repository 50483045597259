import { FC } from 'react';
import Typography from '../../../ui/atoms/Typography';
import Dialog from '../../../ui/atoms/Dialog';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogTitle from '../../../ui/atoms/DialogTitle';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const DeletePageDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSuccess,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Delete this page?</DialogTitle>
    <DialogContent>
      <Typography>
        Deleting this page will permanently remove it from your assessment.
      </Typography>
    </DialogContent>
    <DialogActions>
      <DialogActionSecondaryButton onClick={onClose}>
        Cancel
      </DialogActionSecondaryButton>
      <DialogActionPrimaryButton color="error" onClick={onSuccess}>
        Delete page
      </DialogActionPrimaryButton>
    </DialogActions>
  </Dialog>
);

export default DeletePageDialog;
