import { FC, useMemo } from 'react';
import { BasePlacement } from '@popperjs/core/lib/enums';
import IconButton from '../../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import { ToolbarBoundaryRect } from '../../../../DrawingLayer/TextLayer/type/PopperPosition';
import ThicknessIcon from '../../../../../../ui/atoms/Icons/ThicknessIcon';
import ShapeSizePopper from './ShapeSizePopper';
import ToolbarDivider from '../../../../ToolbarDivider';

type ShapeColorControlProps = {
  openSizeControl: boolean;
  toolbarBoundaryRect: ToolbarBoundaryRect | null;
  onClick: () => void;
  shapeToolbarPlacement: BasePlacement | null;
};

const ShapeSizeControl: FC<React.PropsWithChildren<ShapeColorControlProps>> = ({
  openSizeControl,
  toolbarBoundaryRect,
  shapeToolbarPlacement,
  onClick,
}) => {
  const anchorEl = useMemo(
    () => ({
      getBoundingClientRect: () => toolbarBoundaryRect as DOMRect,
    }),
    [toolbarBoundaryRect]
  );

  return (
    <>
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={onClick}
        shape={ButtonShapeTypes.ROUNDED}
      >
        <ThicknessIcon />
      </IconButton>
      {toolbarBoundaryRect && anchorEl && (
        <ShapeSizePopper
          open={openSizeControl}
          anchorEl={anchorEl}
          shapeToolbarPlacement={shapeToolbarPlacement}
          onChange={onClick}
        />
      )}
      <ToolbarDivider />
    </>
  );
};

export default ShapeSizeControl;
