import React, { useState, useMemo, useCallback } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import MuiTabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import formulaKeys, { KEY_TYPE } from './static/formulaKeys';
import TabItem from '../../../ui/molecules/TabItem';

const FormulaTab = ({ selected, value, onSelect }) => (
  <TabItem selected={selected} onClick={onSelect}>
    {value}
  </TabItem>
);

const formulaTabs = ['All', 'Math', 'Arrow', 'Letter'];

const TabButton = styled(Button)(({ theme }) => ({
  width: 64,
  height: 37,
  padding: '10px',
  borderRadius: '4px',
  border: `1px solid ${theme.background.bg5}`,
  backgroundColor: theme.background.bg1,

  '&:hover': {
    border: `1px solid transparent`,
    backgroundColor: theme.background.bg1,
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
  },

  '&:focus': {
    border: `1px solid ${theme.background.bg5}`,
    backgroundColor: theme.background.bg3,
  },

  fontSize: '16px',
}));
const TabPanel = ({ value, keys, onKeyClick }) => (
  <MuiTabPanel
    value={value}
    key={value}
    sx={{
      display: 'grid',
      gridGap: '8px',
      gridTemplateColumns: `repeat(10, 64px)`,
      padding: 0,
    }}
  >
    {keys.map((key) => (
      <TabButton key={`${key.code}`} onClick={() => onKeyClick(key.code)}>
        {key.label}
      </TabButton>
    ))}
  </MuiTabPanel>
);

const FormulaKeyboard = ({ onKeyClick }) => {
  const [value, setValue] = useState('all');

  const handleChange = useCallback(
    (newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  const mathKeys = useMemo(
    () => formulaKeys.filter((key) => key.type === KEY_TYPE.math),
    []
  );
  const arrowKeys = useMemo(
    () => formulaKeys.filter((key) => key.type === KEY_TYPE.arrow),
    []
  );
  const letterKeys = useMemo(
    () => formulaKeys.filter((key) => key.type === KEY_TYPE.letter),
    []
  );

  const tabPanelData = useMemo(
    () => [
      {
        value: 'all',
        keys: formulaKeys,
      },
      {
        value: KEY_TYPE.math,
        keys: mathKeys,
      },
      {
        value: KEY_TYPE.arrow,
        keys: arrowKeys,
      },
      {
        value: KEY_TYPE.letter,
        keys: letterKeys,
      },
    ],
    [formulaKeys, mathKeys, arrowKeys, letterKeys]
  );

  return (
    <Box width="100%">
      <TabContext value={value}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            minHeight: 38,
            marginBottom: 2,
          }}
        >
          {formulaTabs.map((formulaTab) => (
            <FormulaTab
              key={formulaTab}
              value={formulaTab}
              selected={formulaTab.toLowerCase() === value}
              onSelect={() => {
                handleChange(formulaTab.toLowerCase());
              }}
            />
          ))}
        </Box>
        {tabPanelData.map(({ value, keys }) => (
          <TabPanel
            key={value}
            keys={keys}
            value={value}
            onKeyClick={onKeyClick}
          />
        ))}
      </TabContext>
    </Box>
  );
};

export default React.memo(FormulaKeyboard);
