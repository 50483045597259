import { FC } from 'react';
import MenuItemComponent, {
  menuItemClasses,
  MenuItemProps,
} from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography, { typographyClasses } from '../Typography';
import CheckIcon from '../Icons/CheckIcon';
import { TypographyType } from '../Typography/types/Typography';

type Props = {
  isSelected: boolean;
  onClick: () => void;
} & MenuItemProps;

const SelectItem: FC<React.PropsWithChildren<Props>> = ({
  isSelected,
  children,
  ...props
}) => (
  <MenuItemComponent
    {...props}
    disableRipple
    disableTouchRipple
    focusRipple
    sx={(theme) => ({
      borderRadius: 2,
      '&:focus-visible': {
        backgroundColor: 'unset',
      },
      '&:hover': {
        backgroundColor: theme.background.bg3,
        color: theme.palette.primary.main,
        [`& .${typographyClasses.root}`]: {
          color: `${theme.palette.primary.dark5}`,
        },
      },
      [`&.${menuItemClasses.selected}`]: {
        '&:focus-visible': {
          backgroundColor: theme.palette.primary.alpha10,
        },
        backgroundColor: theme.palette.primary.alpha10,
        [`& .${typographyClasses.root}`]: {
          color: `${theme.palette.primary.dark5}`,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.alpha10,
        },
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: '48px',
      },
    })}
  >
    <Box width="100%" display="flex" gap={1.25}>
      {isSelected ? (
        <CheckIcon
          sx={(theme) => ({
            fontSize: '1.25rem',
            color: theme.palette.primary.dark5,
          })}
        />
      ) : (
        <Box width={20} height={20} />
      )}
      <Box display="flex" minWidth={0} flex={1}>
        <Typography
          variant={TypographyType.p5}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  </MenuItemComponent>
);

export default SelectItem;
