import { createSelector } from 'reselect';
import { SET_ACTIVE_PAGE } from '../../actions/board/activePageActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';

const initialState = 0;
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return action.pageId;
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const getActivePageId = state => state.board.activePage;
const getActivePage = createSelector(
  getActivePageId,
  state => state.board.pages.data,
  (activePageId, pages) => {
    if (!Number.isInteger(activePageId)) return null;

    return pages[activePageId];
  },
);

export {
  getActivePageId,
  getActivePage,
};
