import { FC, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { typographyClasses } from '@mui/material/Typography';
import Typography from '../../atoms/Typography';
import { TypographyType } from '../../atoms/Typography/types/Typography';

type NotificationType = 'success' | 'error' | 'info';

type Props = {
  userName?: string;
  title?: string;
  message?: string;
  type: NotificationType;
  profileImageSrc?: string;
  id: string;
};

const generateNotificationStyles = (type: NotificationType) => {
  if (type === 'success') {
    return {
      backgroundColor: '#D2EFDA',
      [`& .${typographyClasses.root}`]: {
        color: '#1EB270',
      },
    };
  }
  if (type === 'error') {
    return {
      backgroundColor: '#F4D1D4',
      [`& .${typographyClasses.root}`]: {
        color: '#E71D32',
      },
    };
  }

  return {
    backgroundColor: '#DAE1E8',
    [`& .${typographyClasses.root}`]: {
      color: '#2B2C51',
    },
  };
};

const marginSize = 6;
const gapSize = 6;

const PresenceStatusNotification: FC<React.PropsWithChildren<Props>> =
  forwardRef(({ userName, title, message, type, profileImageSrc, id }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          ...generateNotificationStyles(type),
          padding: `${marginSize}px`,
          display: 'flex',
          gap: `${gapSize}px`,
          maxWidth: '320px',
          position: 'relative',
          wordWrap: 'break-word',
          pointerEvents: 'auto',
          borderRadius: '8px',
          overflow: 'hidden',
          zIndex: 9999,
          maxHeight: '150px',
          alignItems: 'center',
          height: '42px',
        }}
      >
        {userName && (
          <Box>
            {profileImageSrc ? (
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                }}
                src={profileImageSrc}
                alt={userName}
              />
            ) : (
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                }}
              >
                {userName && userName.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </Box>
        )}
        {title && (
          <Box
            sx={{
              display: 'flex',
              minWidth: 0,
            }}
          >
            <Typography
              variant={TypographyType.p4}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
              }}
            >
              {title}
            </Typography>
          </Box>
        )}
        {message && (
          <Box
            sx={{
              display: 'flex',
              minWidth: title ? 'initial' : 0,
            }}
          >
            <Typography
              variant={TypographyType.p4}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                fontSize: '1rem',
              }}
            >
              {message}
            </Typography>
          </Box>
        )}
      </Box>
    );
  });

export default PresenceStatusNotification;
