import { useCallback, useContext } from 'react';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import useThrottle from './useThrottle';
import { userIdSelector } from '../reducers/userReducer';
import { getCursorPosition } from '../utils/cursorPosition.util';
import SocketContext from '../../components/board/Contexts/SocketContext';
import CanvasContext from '../../components/board/Contexts/CanvasContext';
import { sessionIdSelector } from '../reducers/session/sessionReducer';
import ClientToServerCursorPayload from '../types/clientToServerCursorPayload';

const useCursorPositionUpdate = () => {
  const dispatch = useDispatch();
  const { send } = useContext(SocketContext);
  const { zoom, originX, originY, scale } = useContext(CanvasContext);

  const roomId = useSelector(sessionIdSelector);
  const userId = useSelector(userIdSelector);

  const onCursorPositionUpdate = useCallback(
    (nativeEvent: any) => {
      const payload: ClientToServerCursorPayload = {
        roomId,
        data: null,
      };

      const point = nativeEvent
        ? getCursorPosition(zoom, originX, originY, scale, nativeEvent)
        : null;

      if (point && !_.isNil(point.x) && !_.isNil(point.y)) {
        payload.data = {
          userId,
          cursorPosition: point,
        };
      }

      send('cursor:position', payload);
    },
    [send, userId, roomId, zoom, originX, originY, scale, dispatch]
  );

  return useThrottle(onCursorPositionUpdate, 60);
};

export default useCursorPositionUpdate;
