import { useCallback, useContext, useMemo } from 'react';
import { useTour } from '@reactour/tour';
import ProductTourContext from './ProductTourContext';

const useProductTour = () => {
  const reactour = useTour();
  const productTour = useContext(ProductTourContext);

  const startTour = useCallback(() => {
    reactour.setIsOpen(true);
    productTour.setInProgress(true);
  }, [reactour.setIsOpen, productTour.setInProgress]);

  const pauseTour = useCallback(() => {
    reactour.setIsOpen(false);
  }, [reactour.setIsOpen]);

  const resumeTour = useCallback(() => {
    reactour.setIsOpen(true);
  }, [reactour.setIsOpen]);

  const stopTour = useCallback(() => {
    reactour.setIsOpen(false);
    productTour.setInProgress(false);
  }, [reactour.setIsOpen, productTour.setInProgress]);

  return useMemo(
    () => ({
      inProgress: productTour.inProgress,
      sideEffect: productTour.sideEffect,
      startTour,
      stopTour,
      pauseTour,
      resumeTour,
      setCurrentStep: reactour.setCurrentStep,
      setSideEffect: productTour.setSideEffect,
    }),
    [
      productTour.inProgress,
      productTour.sideEffect,
      startTour,
      stopTour,
      pauseTour,
      resumeTour,
      reactour.setCurrentStep,
      productTour.setSideEffect,
    ]
  );
};

export default useProductTour;
