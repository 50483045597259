import DrawingAction from './drawing.action';

export default class FreeV3DrawingAction extends DrawingAction {
  points = [];
  processedPoints = [];

  constructor(deserialized) {
    super(deserialized);

    this.name = 'free_v3';

    if (deserialized) {
      let { pth = [] } = deserialized;
      if (pth.length % 2 === 1) {
        pth = pth.splice(-1, 1);
      }
      this.points = [];
      for (let i = 0; i <= pth.length - 2; i += 2) {
        this.points.push({
          x: pth[i],
          y: pth[i + 1],
        });
      }
      this.updatePoints();
    }
  }

  updatePoints() {
    if (!this.points || !this.points.length) return;
    this.processedPoints = this.points;
    this.processedPoints = [];
    let p1 = this.points[0];
    let p2 = this.points[1];
    const len = this.points.length;

    for (let i = 1; i < len; i += 1) {
      this.processedPoints.push({
        x1: p1.x,
        y1: p1.y,
        x2: (p2.x + p1.x) / 2,
        y2: (p2.y + p1.y) / 2,
      });
      p1 = this.points[i];
      if (i + 1 === len) break;
      p2 = this.points[i + 1];
    }

    this.processedPoints.push({
      x1: p1.x,
      y1: p1.y,
    });
  }

  addPoint(point) {
    if (!point) return;

    this.points.push(point);
  }

  serialize() {
    return {
      ...super.serialize(),
      pth: (this.points || []).reduce((acc, val) => ([
        ...acc,
        val.x,
        val.y,
      ]), []),
    };
  }
}
