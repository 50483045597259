import {
  SET_IMAGE,
  CLEAR_IMAGE,
} from '../../actions/board/imageDrawingToolActions';

export default (state = null, action) => {
  switch (action.type) {
    case SET_IMAGE:
      return action.imageObject;
    case CLEAR_IMAGE:
      return null;
    default:
      return state;
  }
};

const getImageDrawingTool = state => state.board.imageDrawingTool;

export { getImageDrawingTool };
