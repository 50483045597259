import sessionsService from '../services/session.service';
import analyticsService from '../../common/services/analytics.service';
import { copyBoard, getGeneralConfiguration } from './configurationActions';

const GET_SESSION_LIST_REQUEST = Symbol('GET_SESSION_LIST_REQUEST');
const GET_SESSION_LIST_SUCCESS = Symbol('GET_SESSION_LIST_SUCCESS');
const GET_SESSION_LIST_FAILURE = Symbol('GET_SESSION_LIST_FAILURE');

const GET_MORE_SESSION_LIST_REQUEST = Symbol('GET_SESSION_LIST_REQUEST');
const GET_MORE_SESSION_LIST_SUCCESS = Symbol('GET_MORE_SESSION_LIST_SUCCESS');
const GET_MORE_SESSION_LIST_SUCCESS_REQUEST = Symbol(
  'GET_MORE_SESSION_LIST_SUCCESS_REQUEST'
);
const GET_MORE_SESSION_LIST_FAILURE = Symbol('GET_MORE_SESSION_LIST_FAILURE');

const ADD_SESSION_IN_LIST = Symbol('ADD_SESSION_IN_LIST');

const UPDATE_SESSION_IN_LIST = Symbol('UPDATE_SESSION_IN_LIST');

const CHANGE_SESSION_GROUP = Symbol('CHANGE_SESSION_GROUP');

const COPY_SESSION_REQUEST = Symbol('COPY_SESSION_REQUEST');
const COPY_SESSION_SUCCESS = Symbol('COPY_SESSION_SUCCESS');
const COPY_SESSION_FAILURE = Symbol('COPY_SESSION_FAILURE');

const REMOVE_SESSION = Symbol('REMOVE_SESSION');

// ------------------- Get session list actions -------------------
const sessionListSuccess = (payload, groupId = 'default') => ({
  type: GET_SESSION_LIST_SUCCESS,
  payload,
  groupId,
});

const addSessionInList = (session, groupId) => ({
  type: ADD_SESSION_IN_LIST,
  payload: {
    session,
    groupId,
  },
});

const sessionListRequest = () => ({
  type: GET_SESSION_LIST_REQUEST,
});

const sessionListFailure = (error) => ({
  type: GET_SESSION_LIST_FAILURE,
  error,
});

const moreSessionListRequest = () => ({
  type: GET_MORE_SESSION_LIST_REQUEST,
});

const moreSessionListSuccessRequest = () => ({
  type: GET_MORE_SESSION_LIST_SUCCESS_REQUEST,
});

const moreSessionListFailure = (error) => ({
  type: GET_MORE_SESSION_LIST_FAILURE,
  error,
});

const getSessions = (groupId, pageNumber, pageSize) => (dispatch) => {
  dispatch(sessionListRequest());
  return sessionsService
    .getSessionList({ groupId, pageNumber, pageSize })
    .then((payload) => {
      dispatch(sessionListSuccess(payload, groupId));
      return payload;
    })
    .catch((error) => {
      dispatch(sessionListFailure(error));
      console.log('Error: ', error);
    });
};

const loadMoreSessions = (groupId, pageNumber, pageSize) => (dispatch) => {
  dispatch(moreSessionListRequest());

  return sessionsService
    .getSessionList({ groupId, pageNumber, pageSize })
    .then((payload) => {
      dispatch(sessionListSuccess(payload, groupId));
      dispatch(moreSessionListSuccessRequest());
      return payload;
    })
    .catch((error) => {
      dispatch(moreSessionListFailure(error));
      console.log('Error: ', error);
    });
};

const getSessionList =
  ({ groupId = 'default', pageNumber, pageSize }) =>
  (dispatch) => {
    if (pageNumber) {
      dispatch(loadMoreSessions(groupId, pageNumber, pageSize));
    } else {
      dispatch(getSessions(groupId, pageNumber, pageSize));
    }
  };

// ------------------- Get session list actions -------------------

// ------------------- Update session in list actions -------------------

const updateSessionInList = (session) => ({
  type: UPDATE_SESSION_IN_LIST,
  payload: session,
});
// ------------------- Update session in list actions -------------------

// ------------------- Get session list actions -------------------
const copySessionRequest = () => ({
  type: COPY_SESSION_REQUEST,
});

const copySessionSuccess = (payload, groupId = 'default') => ({
  type: COPY_SESSION_SUCCESS,
  payload,
  groupId,
});

const copySessionFailure = (error) => ({
  type: COPY_SESSION_FAILURE,
  error,
});

const copySessionInList = (sessionId, onCreateDialogOpen) => {
  return async (dispatch) => {
    dispatch(copySessionRequest());

    try {
      const data = await sessionsService.copySession(sessionId);
      dispatch(copySessionSuccess(data, data.groupId));
      dispatch(copyBoard());
      return data;
    } catch (error) {
      dispatch(copySessionFailure(error));
      if (error.code === '130005') {
        analyticsService.event('Create Board above Limit');
        onCreateDialogOpen();
        dispatch(getGeneralConfiguration());
      }
      console.log('Error: ', error);
    }
  };
};

// ------------------- Get session list actions -------------------

// ------------------- Remove session -------------------

const removeSession = (sessionId) => ({
  type: REMOVE_SESSION,
  payload: {
    sessionId,
  },
});

// ------------------- Update session group -------------------

const changeSessionGroup = (id, groupId) => ({
  type: CHANGE_SESSION_GROUP,
  payload: {
    id,
    groupId,
  },
});

// ------------------- Update session group -------------------

export {
  getSessionList,
  updateSessionInList,
  addSessionInList,
  copySessionInList,
  removeSession,
  changeSessionGroup,
  GET_SESSION_LIST_REQUEST,
  GET_SESSION_LIST_SUCCESS,
  GET_SESSION_LIST_FAILURE,
  ADD_SESSION_IN_LIST,
  UPDATE_SESSION_IN_LIST,
  COPY_SESSION_REQUEST,
  COPY_SESSION_SUCCESS,
  COPY_SESSION_FAILURE,
  REMOVE_SESSION,
  CHANGE_SESSION_GROUP,
  GET_MORE_SESSION_LIST_REQUEST,
  GET_MORE_SESSION_LIST_SUCCESS,
  GET_MORE_SESSION_LIST_SUCCESS_REQUEST,
  GET_MORE_SESSION_LIST_FAILURE,
};
