import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

const TabItem = <D extends React.ElementType>({
  children,
  selected,
  ...props
}: ButtonProps<D, { component?: D }>) => (
  <Button
    {...props}
    sx={(theme) => ({
      padding: '8px 14px',
      borderRadius: theme.spacing(1.75),
      height: '40px',
      whiteSpace: 'nowrap',
      ...theme.typography.b4,
      border: '1px solid',
      borderColor: theme.palette.primary.alpha20,
      ...(selected
        ? {
            borderColor: theme.palette.primary.alpha10,
            backgroundColor: theme.palette.primary.alpha10,
            color: theme.palette.primary.dark5,

            ':hover': {
              border: '1px solid',
              borderColor: theme.palette.primary.alpha10,
              backgroundColor: theme.palette.primary.alpha10,
              color: theme.palette.primary.dark5,
            },
          }
        : {
            ':hover': {
              border: '1px solid',
              borderColor: theme.palette.primary.alpha20,
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
            },

            '&.active': {
              borderColor: theme.palette.primary.alpha10,
              backgroundColor: theme.palette.primary.alpha10,
              color: theme.palette.primary.dark5,
              ':hover': {
                backgroundColor: theme.palette.primary.alpha20,
                borderColor: theme.palette.primary.alpha20,
              },
            },
          }),
    })}
  >
    {children}
  </Button>
);

export default TabItem;
