const HOST_CURSOR_COLOR = '#EF6D46';

let currentIndex = -1;
const colors = ['#FBB033', '#FB7EDF', '#CB7EFB', '#7EC6FB', '#7EECFB', '#7DFADC', '#A2FA7D', '#E8FA7D', '#FAE67D'];

const getNextCursorColor = () => {
  currentIndex = (currentIndex + 1) % colors.length;

  return colors[currentIndex];
};

export { HOST_CURSOR_COLOR, getNextCursorColor };
