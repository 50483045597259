import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as ConnectionQualityExcellentIcon } from '../../../assets/svgIcons/ConnectionQualityExcellentSvgIcon.svg';

const Icon = (props: SvgIconProps) => (
  <SvgIcon
    component={ConnectionQualityExcellentIcon}
    inheritViewBox
    {...props}
  />
);

export default Icon;
