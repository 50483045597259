import * as _ from 'lodash';
import { createSelector } from 'reselect';
import { participantsSelector } from '../session/participantsReducer';
import {
  SET_CURRENT_CURSOR_ACTION,
  UPDATE_CURSOR_ACTION,
} from '../../actions/board/cursorActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';
import type CursorDetail from '../../types/cursorDetail';
import type ParticipantDto from '../../services/dto/firebase/ParticipantDto';
import type CursorAction from '../../types/cursorAction';

type CursorStateType = {
  cursors: Record<string, CursorDetail>,
  currentCursor: CursorDetail | null,
};

const initialState: CursorStateType = {
  cursors: {},
  currentCursor: null,
};

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case UPDATE_CURSOR_ACTION: {
      return {
        ...state,
        cursors: _.omitBy({
          ...state.cursors,
          [payload.socketId]: payload.data,
        }, _.isNil),
      };
    }
    case SET_CURRENT_CURSOR_ACTION: {
      return {
        ...state,
        currentCursor: payload,
      };
    }
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const mapToCursorAction = (
  cursor: CursorDetail,
  participants: ParticipantDto[],
): CursorAction | null => {
  if (!cursor) return null;

  const participant = participants.find((p: ParticipantDto) => p.id === cursor.userId);

  if (!participant) return null;

  return {
    cursorPosition: cursor.cursorPosition,
    participant,
  };
};

const currentCursorActionSelector = createSelector(
  [
    state => state.board.cursorActions.currentCursor,
    participantsSelector,
  ],
  mapToCursorAction,
);

const cursorActionsSelector = createSelector(
  [
    state => state.board.cursorActions.cursors,
    participantsSelector,
  ],
  (cursors: Record<string, CursorDetail>, participants) => Object.values(cursors)
    .map(cursorDetail => mapToCursorAction(cursorDetail, participants))
    .filter(action => !_.isNil(action)),
);

export {
  currentCursorActionSelector,
  cursorActionsSelector,
};
