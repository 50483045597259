import { DisplayObject, Graphics, Ellipse } from 'pixi.js';
import Shape from './shape';
import OldOvalAction from '../../../common/drawingActions/shapes/oval.drawing.action';
import ActionName from '../types/ActionName';

class Oval extends Shape {
  constructor(action?: OldOvalAction) {
    super(action);

    this.name = ActionName.OVAL;
  }

  toDto(): OldOvalAction {
    const dto = new OldOvalAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldOvalAction) {
    super.setFields(action);
    action.name = ActionName.OVAL;
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    graphicsObject.clear();
    graphicsObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    if (!this.paint.fill) {
      graphicsObject.lineStyle(size, color);
    } else {
      graphicsObject.beginFill(color);
    }

    graphicsObject.drawEllipse(0, 0, Math.abs(width) / 2, Math.abs(height) / 2);

    if (this.paint.fill) {
      graphicsObject.endFill();
    } else {
      graphicsObject.hitArea = new Ellipse(
        0,
        0,
        Math.abs(width) / 2,
        Math.abs(height) / 2
      );
    }
  }
}

export default Oval;
