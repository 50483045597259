import { createSelector } from 'reselect';
import {
  GET_FEATURE_TOURS_REQUEST,
  GET_FEATURE_TOURS_SUCCESS,
  GET_FEATURE_TOURS_FAILURE,
} from '../actions/featureToursActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURE_TOURS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_FEATURE_TOURS_SUCCESS: {
      return {
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_FEATURE_TOURS_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

const featureToursSelector = (state) => state.featureTours;

const isFetchedSelector = createSelector(
  featureToursSelector,
  (featureTours) => featureTours && featureTours.isFetched
);

const quizTourSelector = createSelector(
  featureToursSelector,
  (featureTours) => featureTours.data && featureTours.data.quiz
);

const graphTourSelector = createSelector(
  featureToursSelector,
  (featureTours) => featureTours.data && featureTours.data.graph
);

const imageTourSelector = createSelector(
  featureToursSelector,
  (featureTours) => featureTours.data && featureTours.data.image
);

const livekitTourSelector = createSelector(
  featureToursSelector,
  (featureTours) => featureTours.data && featureTours.data.livekit
);

export {
  featureToursSelector,
  isFetchedSelector,
  quizTourSelector,
  graphTourSelector,
  imageTourSelector,
  livekitTourSelector,
};
