import fireBaseService from '../../services/firebaseService';
import { setShouldUpdateThumbnail } from './shouldUpdateThumbnailActions';
import * as sessionReducer from '../../reducers/session/sessionReducer';
import { getActivePageId } from '../../reducers/board/activePageReducer';
import { firstPageSelector } from '../../reducers/board/pagesReducer';
import { isCurrentUserHostSelector } from '../../reducers/session/sessionReducer';

const SET_ACTIVE_PAGE = Symbol('SET_ACTIVE_PAGE');

const setActivePage = (pageId) => ({
  type: SET_ACTIVE_PAGE,
  pageId,
});

const selectPage = (pageId) => {
  return (dispatch, getState) => {
    const state = getState();

    const connected = sessionReducer.connectedSelector(state);
    const finished = sessionReducer.finishedSelector(state);

    const isHost = isCurrentUserHostSelector(state);

    if (!connected || finished || !isHost)
      return dispatch(setActivePage(pageId));

    const oldActivePageId = getActivePageId(state);
    const firstPage = firstPageSelector(state);
    if (
      oldActivePageId !== pageId &&
      firstPage &&
      firstPage.id === oldActivePageId
    ) {
      dispatch(setShouldUpdateThumbnail(true));
    }

    return fireBaseService.setActivePage(pageId);
  };
};

export { setActivePage, selectPage, SET_ACTIVE_PAGE };
