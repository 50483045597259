import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { liveBoardStorage } from '../../../config/storage';
import EraserIcon from '../../../ui/atoms/Icons/Shapes/EraserIcon';
import ClearV2Action from '../../../common/drawingActions/clear.v2.action';
import BaseTool from './BaseTool';
import ToolNames from '../ToolNames';
import keyMap from '../../../config/hotkeys';
import {
  boardTypeSelector,
  isCurrentUserHostSelector,
} from '../../../common/reducers/session/sessionReducer';
import BoardType from '../../../common/services/types/session/BoardType';
import ClearPageWarningDialog from '../../../ui/molecules/ClearPageWarningDialog/ClearPageWarningDialog';
import useDialog from '../../../common/hooks/useDialog';
import Button from '../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import TrashCanOutlineIcon from '../../../ui/atoms/Icons/TrashCanOutlineIcon';
import ToolOptionsLayout from '../ToolOptionsLayout/ToolOptionsLayout';
import ToolSizeControl from '../ToolSizeControl/ToolSizeControl';
import analyticsService from '../../../common/services/analytics.service';

const LOCAL_STORAGE_KEY = 'board:clear:dontShowAgain';
const eraseSizes = [20, 30, 40, 60, 70];

const settingsFactory = ({ updateFontSize, onClearButtonClicked, isHost }) => (
  <ToolOptionsLayout>
    <ToolSizeControl
      sizes={eraseSizes}
      onChange={updateFontSize(ToolNames.Eraser)}
    />
    {isHost && (
      <Box display="flex" justifyContent="center">
        <Button
          variant={ButtonVariantTypes.OUTLINED}
          size={ButtonSizeTypes.S}
          startIcon={
            <TrashCanOutlineIcon
              sx={{
                width: 18,
                height: 18,
              }}
            />
          }
          onClick={onClearButtonClicked}
        >
          Erase all
        </Button>
      </Box>
    )}
  </ToolOptionsLayout>
);

const EraserTool = ({
  selectedTool,
  selectTool,
  addActionAndSend,
  updateFontSize,
}) => {
  const boardType = useSelector(boardTypeSelector);
  const isHost = useSelector(isCurrentUserHostSelector);

  const { open, onOpen, onClose } = useDialog();

  const clearPage = useCallback(() => {
    const action = new ClearV2Action();
    addActionAndSend(action);
    selectTool(ToolNames.Select);
  }, [addActionAndSend, selectTool]);

  const onClearButtonClicked = useCallback(() => {
    analyticsService.event('Erase All Button Click');

    if (liveBoardStorage.getItem(LOCAL_STORAGE_KEY) !== 'true') {
      onOpen();
      return;
    }
    clearPage();
  }, [clearPage, onOpen]);

  const onClearPageDialogConfirm = useCallback(
    (dontShowAgain = false) => {
      if (dontShowAgain) {
        liveBoardStorage.setItem(LOCAL_STORAGE_KEY, 'true');
      }
      clearPage();
      onClose();
    },
    [clearPage, onClose]
  );

  const icon = <EraserIcon />;
  const settings = useMemo(
    () =>
      settingsFactory({
        updateFontSize,
        onClearButtonClicked,
        isHost,
      }),
    [updateFontSize, onClearButtonClicked, isHost]
  );

  const showSettings = selectedTool.name === ToolNames.Eraser;

  const onEraseToolClick = useCallback(
    (activeTool) => {
      analyticsService.event('Tool Selected', {
        tool: 'eraser',
      });
      selectTool(activeTool);
    },
    [selectTool]
  );

  return (
    <>
      <BaseTool
        icon={icon}
        tooltipText={`Eraser (${keyMap.eraser.description})`}
        settings={boardType === BoardType.INFINITE ? undefined : settings}
        selectedTool={selectedTool}
        activeTool={ToolNames.Eraser}
        selectTool={onEraseToolClick}
        showSettings={
          boardType === BoardType.INFINITE ? undefined : showSettings
        }
        showToolOptionsIcon={true}
      />
      <ClearPageWarningDialog
        open={open}
        onClose={onClose}
        onSubmit={onClearPageDialogConfirm}
      />
    </>
  );
};

export default EraserTool;
