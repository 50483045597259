import React, { createRef } from 'react';
import { Subject, BehaviorSubject, pipe, combineLatest, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import DomEventsUtil from '../../../../../common/utils/domEvents.util';
import drawers from '../../drawers';
import DeleteAction from '../../../../../common/drawingActions/delete.action';
import DrawingModes from '../DrawingModes';
import TriangleDrawingAction from '../../../../../common/drawingActions/shapes/triangle.drawing.action';
import shapesHitTest from '../shapesHitTest';
import { withCanvasContext } from '../../../Contexts/CanvasContext';
import TopTriangleResizer from '../boundaries/resizers/TopTriangleResizer';
import RightTriangleResizer from '../boundaries/resizers/RightTriangleResizer';
import LeftTriangleResizer from '../boundaries/resizers/LeftTriangleResizer';
import TriangleBoundaryBox from '../boundaries/TriangleBoundaryBox';
import clearSelection from '../clearSelection';
import SelectCursorSvgIcon from '../../../../../assets/svgIcons/SelectCursorSvgIcon.svg';
import ShapeCursorSvgIcon from '../../../../../assets/svgIcons/ShapeCursorSvgIcon.svg';
import ToolNames from '../../../../DrawingTools/ToolNames';
import ShapeToolbar from './components/ShapeToolbar';

class TriangleDrawingLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toolbarVisible: false,
    };

    this.shouldApplyTriangleRef = createRef();
    this.shouldApplyTriangleRef.current = true;
  }
  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.zoomProp$.next(this.props.zoom);
    this.scaleProp$.next(this.props.scale);
    this.originXProp$.next(this.props.originX);
    this.originYProp$.next(this.props.originY);
    this.currentPageIdProp$.next(this.props.currentPageId);

    if (this.props.currentDrawing) {
      this.coordinates$.next({
        top: { ...this.props.currentDrawing.top },
        right: { ...this.props.currentDrawing.right },
        left: { ...this.props.currentDrawing.left },
      });
      this.trianglePosition$.next({
        top: { ...this.props.currentDrawing.top },
        right: { ...this.props.currentDrawing.right },
        left: { ...this.props.currentDrawing.left },
      });
      this.setState({
        toolbarVisible: true,
      });
      this.colorProp$.next(+this.props.currentDrawing.paint.color);
      this.sizeProp$.next(this.props.currentDrawing.paint.strokeWidth);
      this.fillProp$.next(this.props.currentDrawing.paint.fill);
      this.mode$.next(DrawingModes.Drawn);
    } else {
      this.colorProp$.next(this.props.color);
      this.sizeProp$.next(this.props.size);
      this.fillProp$.next(this.props.fill);
      this.mode$.next(DrawingModes.Initial);
    }

    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();
  }

  componentDidUpdate(prevProps) {
    if (this.props.zoom !== prevProps.zoom) {
      this.zoomProp$.next(this.props.zoom);
    }
    if (this.props.scale !== prevProps.scale) {
      this.scaleProp$.next(this.props.scale);
    }
    if (this.props.originX !== prevProps.originX) {
      this.originXProp$.next(this.props.originX);
    }
    if (this.props.originY !== prevProps.originY) {
      this.originYProp$.next(this.props.originY);
    }
    if (this.props.color !== prevProps.color) {
      this.colorProp$.next(this.props.color);
    }
    if (this.props.size !== prevProps.size) {
      this.sizeProp$.next(this.props.size);
    }
    if (this.props.fill !== prevProps.fill) {
      this.fillProp$.next(this.props.fill);
    }
    if (this.props.currentPageId !== prevProps.currentPageId) {
      this.currentPageIdProp$.next(this.props.currentPageId);
    }
  }

  componentWillUnmount() {
    if (this.shouldApplyTriangleRef.current) {
      const action = this.triangle$.getValue();
      this.applyAction(action);
    }

    if (this.props.currentDrawing) {
      this.props.setCurrentDrawing(null);
    }
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();
    this.zoomProp$.complete();
    this.scaleProp$.complete();
    this.originXProp$.complete();
    this.originYProp$.complete();
    this.colorProp$.complete();
    this.sizeProp$.complete();
    this.fillProp$.complete();
    this.currentPageIdProp$.complete();
    this.trianglePosition$.complete();
    this.triangle$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');
    this.drawingContext.canvas.style.cursor = `url(${ShapeCursorSvgIcon}) 12 12, crosshair`;
  };

  canvas = null;
  drawingContext = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();
  mode$ = new BehaviorSubject();
  zoomProp$ = new BehaviorSubject();
  scaleProp$ = new BehaviorSubject();
  originXProp$ = new BehaviorSubject();
  originYProp$ = new BehaviorSubject();
  colorProp$ = new BehaviorSubject();
  sizeProp$ = new BehaviorSubject();
  fillProp$ = new BehaviorSubject();
  currentPageIdProp$ = new Subject();
  coordinates$ = new BehaviorSubject();
  trianglePosition$ = new BehaviorSubject(null);
  triangle$ = new BehaviorSubject(null);

  props$ = combineLatest(
    this.zoomProp$,
    this.scaleProp$,
    this.originXProp$,
    this.originYProp$,
    this.colorProp$,
    this.sizeProp$,
    this.fillProp$
  ).pipe(
    map(([zoom, scale, originX, originY, color, size, fill]) => ({
      zoom,
      scale,
      originX,
      originY,
      color,
      size,
      fill,
    }))
  );

  draw = (shape, boundaries) => {
    if (!shape) return;
    const { zoom, scale, originX, originY } = this.props;
    if (!this.drawingContext) return;

    const drawer = drawers[shape.name];
    if (!drawer) return;

    this.clear();
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    drawer(this.drawingContext, shape);

    if (boundaries && boundaries.length) {
      boundaries.forEach((boundary) => boundary.draw(this.drawingContext));
    }
    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  clear = () => {
    const { width, height } = this.props;
    this.drawingContext.clearRect(0, 0, width, height);
  };

  subscribeToDrawing = () => {
    const transformPoint = () =>
      pipe(
        withLatestFrom(this.props$),
        map(([point, props]) => {
          const { originX, originY, scale, zoom } = props;

          return {
            x: (point.x / zoom + originX) / scale,
            y: (point.y / zoom + originY) / scale,
          };
        })
      );

    const withMode = (m) =>
      pipe(
        withLatestFrom(this.mode$),
        filter(([, mode]) => mode === m),
        map(([e]) => e)
      );

    const startEvents$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint(), tap(clearSelection));

    const moveEvents$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint());

    const endEvents$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    ).pipe(transformPoint());

    const triangle$ = combineLatest(this.coordinates$, this.props$).pipe(
      map(([positions, props]) => {
        if (!positions || !props) return null;

        const createdShape = this.createShape(positions, props);
        this.triangle$.next(createdShape);

        return createdShape;
      })
    );

    const boundaries$ = combineLatest(this.coordinates$, this.props$).pipe(
      map(([positions, props]) => {
        if (!positions || !props) return [];

        return this.createBoundaries(positions, props);
      })
    );

    // Drawing
    startEvents$
      .pipe(
        withMode(DrawingModes.Initial),
        concatMap(() => moveEvents$.pipe(takeUntil(endEvents$))),
        withLatestFrom(startEvents$),
        map(([lastPoint, startPoint]) =>
          this.normalizePoints(startPoint, lastPoint)
        )
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Drawing end
    startEvents$
      .pipe(
        withMode(DrawingModes.Initial),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(triangle$)
      )
      .subscribe(([, action]) => {
        if (!action) {
          this.mode$.next(DrawingModes.Initial);
          return;
        }

        this.trianglePosition$.next({
          top: action.top,
          right: action.right,
          left: action.left,
        });
        this.setState({
          toolbarVisible: true,
        });
        this.mode$.next(DrawingModes.Drawn);
      });

    // Drag start
    startEvents$
      .pipe(
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$, triangle$)
      )
      .subscribe(([coordinates, shapes, action]) => {
        const shape = shapesHitTest(coordinates, shapes);
        if (shape) {
          this.setState({
            toolbarVisible: false,
          });

          const newMode =
            shape instanceof TriangleBoundaryBox
              ? DrawingModes.Dragging
              : DrawingModes.Resizing;
          this.mode$.next(newMode);
          return;
        }

        if (!this.shouldApplyTriangleRef.current) return;

        this.shouldApplyTriangleRef.current = false;
        const isOutsideClick = true;
        this.applyAction(action, isOutsideClick);
      });

    // Dragging
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        withLatestFrom(boundaries$),
        concatMap(() =>
          moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Dragging),
            pairwise(),
            map(([oldPos, newPos]) => ({
              deltaX: newPos.x - oldPos.x,
              deltaY: newPos.y - oldPos.y,
            }))
          )
        ),
        withLatestFrom(this.coordinates$),
        map(([transform, { top, right, left }]) => ({
          top: {
            x: top.x + transform.deltaX,
            y: top.y + transform.deltaY,
          },
          right: {
            x: right.x + transform.deltaX,
            y: right.y + transform.deltaY,
          },
          left: {
            x: left.x + transform.deltaX,
            y: left.y + transform.deltaY,
          },
        }))
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Drag end
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ top, left, right }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.trianglePosition$.next({
          top,
          right,
          left,
        });
        this.setState({
          toolbarVisible: true,
        });
      });

    // Resizing
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        withLatestFrom(boundaries$),
        concatMap(([coordinates, shapes]) => {
          const shape = shapesHitTest(coordinates, shapes);

          return moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Resizing),
            map((pos) => shape.onResize(pos))
          );
        })
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Resize end
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ top, left, right }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.trianglePosition$.next({
          top,
          right,
          left,
        });
        this.setState({
          toolbarVisible: true,
        });
      });

    this.mouseMove$
      .pipe(
        map(DomEventsUtil.mouseEventToCoordinates),
        transformPoint(),
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$)
      )
      .subscribe(([coordinates, shapes]) => {
        const shape = shapesHitTest(coordinates, shapes);
        if (shape) {
          shape.onHover(this.drawingContext);
          return;
        }

        this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
      });

    combineLatest(
      triangle$,
      combineLatest(boundaries$, this.mode$).pipe(
        map(([boundaries, mode]) => {
          if (mode === DrawingModes.Initial || mode === DrawingModes.Dragging)
            return [];

          return boundaries;
        })
      )
    ).subscribe(([shape, boundaries]) => {
      if (!shape) {
        this.clear();
        return;
      }

      this.draw(shape, boundaries);
    });

    this.currentPageIdProp$.subscribe(() => {
      if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
        this.props.setCurrentDrawing(null);
        this.props.updateDrawingTool(this.props.shapeType, {
          previousDrawingTool: null,
        });
      }

      this.props.selectDrawingTool(ToolNames.Select);
      this.reset();
    });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  applyAction = (action, isOutsideClick = false) => {
    const drawingAction = action;
    if (!drawingAction) return;
    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      drawingAction.targetId = this.props.currentDrawing.targetId;
    }
    this.props.addActionAndSend(drawingAction);

    if (this.props.previousDrawingTool) {
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    }

    if (isOutsideClick) {
      this.props.selectDrawingTool(ToolNames.Select);
    }

    this.reset();
  };

  reset = () => {
    this.coordinates$.next(null);
    this.mode$.next(DrawingModes.Initial);
    this.trianglePosition$.next(null);
    this.clear();
  };

  createShape = (positions, props) => {
    const { color, size, fill } = props;

    const { top, right, left } = positions;

    const drawingAction = new TriangleDrawingAction();
    drawingAction.paint.color = color;
    drawingAction.paint.strokeWidth = size;
    drawingAction.paint.fill = fill;
    drawingAction.localStartTime = new Date();
    drawingAction.top = { x: top.x, y: top.y };
    drawingAction.right = { x: right.x, y: right.y };
    drawingAction.left = { x: left.x, y: left.y };
    return drawingAction;
  };

  createBoundaries = (positions, props) => {
    const { size, scale, zoom } = props;
    const { top, right, left } = positions;

    const padding = 20 / scale + size / 2;
    const params = [top, left, right, scale * zoom, padding];
    const shapes = [
      new TriangleBoundaryBox(...params),
      new TopTriangleResizer(...params),
      new RightTriangleResizer(...params),
      new LeftTriangleResizer(...params),
    ];

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => ({
    top: { x: startPoint.x, y: startPoint.y },
    right: { x: endPoint.x, y: endPoint.y },
    left: {
      x: startPoint.x - (endPoint.x - startPoint.x),
      y: endPoint.y,
    },
  });

  getTriangleBoundaryPosition = (position) => {
    if (!position) return null;

    const startPoint = {
      x: Math.min(position.left.x, position.right.x, position.top.x),
      y: Math.min(position.left.y, position.right.y, position.top.y),
    };
    const endPoint = {
      x: Math.max(position.left.x, position.right.x, position.top.x),
      y: Math.max(position.left.y, position.right.y, position.top.y),
    };

    return {
      startPoint,
      endPoint,
    };
  };

  getToolbarPosition = (position) => {
    if (!position) return null;

    const { originX, originY, scale, zoom } = this.props;

    return {
      x: (position.startPoint.x * scale - originX) * zoom,
      y: (position.startPoint.y * scale - originY) * zoom,
      width: (position.endPoint.x - position.startPoint.x) * scale * zoom,
      height: (position.endPoint.y - position.startPoint.y) * scale * zoom,
    };
  };

  deleteTriangle = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
    this.shouldApplyTriangleRef.current = false;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const deleteAction = new DeleteAction();
      deleteAction.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(deleteAction);
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    }

    this.props.selectDrawingTool(ToolNames.Select);
    this.reset();
  };

  copyTriangle = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const action = this.triangle$.getValue();
      const drawingAction = action;
      drawingAction.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(drawingAction);
      this.mode$.next(DrawingModes.Drawn);

      const copiedTrianglePosition = {
        left: { x: action.left.x + 20, y: action.left.y + 20 },
        right: { x: action.right.x + 20, y: action.right.y + 20 },
        top: { x: action.top.x + 20, y: action.top.y + 20 },
      };

      this.coordinates$.next(copiedTrianglePosition);
      this.trianglePosition$.next(copiedTrianglePosition);
    }
  };

  render() {
    const { width, height } = this.props;
    const trianglePosition = this.trianglePosition$.getValue();
    const triangleBoundaryPosition =
      this.getTriangleBoundaryPosition(trianglePosition);
    const toolbarPosition = this.getToolbarPosition(triangleBoundaryPosition);
    const openToolbar = this.state.toolbarVisible;

    return (
      <>
        <canvas
          ref={this.setDrawingContext}
          width={width}
          height={height}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
          onMouseUp={this.onMouseUp}
          onContextMenu={this.onContextMenu}
        />
        {toolbarPosition && (
          <ShapeToolbar
            position={toolbarPosition}
            open={openToolbar}
            onDelete={this.deleteTriangle}
            onCopy={this.copyTriangle}
          />
        )}
      </>
    );
  }
}

export default withCanvasContext(TriangleDrawingLayer);
