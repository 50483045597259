import { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import './ChatInput.scss';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import SendIcon from '../../../../../ui/atoms/Icons/SendIcon';
import InputVariant from '../../../../../ui/atoms/Input/InputVariant';
import InputSize from '../../../../../ui/atoms/Input/InputSize';
import TextArea from '../../../../../ui/atoms/TextArea';

const chatSchema = z.object({
  text: z.string().min(1),
});

const ChatInput = ({ onSubmit }) => {
  const {
    control,
    handleSubmit: formSubmit,
    reset,
  } = useForm({
    defaultValues: {
      text: '',
    },
    resolver: zodResolver(chatSchema),
  });

  const handleSubmit = useCallback(
    formSubmit(({ text }) => {
      if (!text.trim().length) return;
      onSubmit(text);
      reset();
    }),
    [onSubmit, reset]
  );

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        handleSubmit(event);
      }
    },
    [handleSubmit]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box
        display="flex"
        padding={1}
        gap={1}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.background.bg3}`,
          backgroundColor: theme.background.bg1,
        })}
      >
        <Controller
          name="text"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextArea
              id="chatTextField"
              variant={InputVariant.CONTAINED}
              size={InputSize.M}
              value={value}
              onChange={onChange}
              rows={2}
              maxRows={5}
              placeholder="Type your message..."
              resize="none !important"
              autoComplete="off"
              onKeyPress={onKeyPress}
            />
          )}
        />
        <IconButton
          variant={ButtonVariantTypes.PRIMARY}
          size={ButtonSizeTypes.S}
          shape={ButtonShapeTypes.ROUNDED}
          type="submit"
        >
          <SendIcon />
        </IconButton>
      </Box>
    </form>
  );
};

export default ChatInput;
