import CallContext from './CallContext';
import { asyncNoop } from '../../../../common/constants';

export enum ConnectionState {
  Disconnected = 'disconnected',
  Connecting = 'connecting',
  Connected = 'connected',
  Reconnecting = 'reconnecting',
}

const defaultValue = {
  loading: false,
  isHost: false,
  isConferenceInProgress: false,
  muted: false,
  connectedToConference: false,
  onAnswerCall: asyncNoop,
  handleCallButtonClick: asyncNoop,
  disconnectFromConference: asyncNoop,
  startConference: asyncNoop,
  joinConference: asyncNoop,
  onChangeConnectionState: (connectionState: ConnectionState) => {},
  connectionState: ConnectionState.Disconnected,
  onChangeScreenShared: (shared: boolean) => {},
  isScreenShared: false,
} as CallContext;

export default defaultValue;
