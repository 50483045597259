import React, {
  FC,
  ReactElement,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import axios, { CancelToken } from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import userService from '../../../common/services/auth.service';
import Button from '../../atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';

const cardStyle = {
  style: {
    base: {
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {},
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

type Props = {
  buttonContent: ReactElement | string;
  promoCodeId?: string;
  onSuccess: (promoCodeId?: string) => Promise<void>;
};

const CardForm: FC<React.PropsWithChildren<Props>> = ({
  children,
  buttonContent,
  promoCodeId,
  onSuccess,
}) => {
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const getClientSecret = useCallback(
    async (token: CancelToken | undefined = undefined) => {
      const response = await userService.getSetupIntentToken({
        cancelToken: token,
      });

      setClientSecret(response.clientSecret);
    },
    [setClientSecret]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    getClientSecret(source.token);

    return () => source.cancel('Request was cancelled');
  }, [getClientSecret]);

  const handleChange = useCallback(
    async (event: StripeCardElementChangeEvent) => {
      setError(event.error ? event.error.message : '');
    },
    [setError]
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const element = elements?.getElement(CardElement);

      if (!element) return;

      setProcessing(true);

      try {
        const payload = await stripe?.confirmCardSetup(clientSecret, {
          payment_method: {
            card: element,
          },
        });

        if (!payload) return;

        if (payload.error) {
          if (payload.error.code === 'setup_intent_unexpected_state') {
            await getClientSecret();
          }

          if (payload.error.message) {
            enqueueSnackbar(payload.error.message, {
              variant: 'error',
            });
          }

          setProcessing(false);
        } else {
          let paymentMethodId = '';
          if (payload?.setupIntent?.payment_method) {
            if (typeof payload.setupIntent.payment_method === 'string') {
              paymentMethodId = payload.setupIntent.payment_method;
            } else {
              paymentMethodId = payload.setupIntent.payment_method.id;
            }
          }

          await userService.setDefaultPaymentMethod(paymentMethodId);

          if (onSuccess) {
            await onSuccess(promoCodeId);
          }
        }
      } catch (e) {
        if (e.code === '302004') {
          await getClientSecret();
        }
      } finally {
        setProcessing(false);
      }
    },
    [
      elements,
      setProcessing,
      stripe,
      getClientSecret,
      onSuccess,
      clientSecret,
      promoCodeId,
      enqueueSnackbar,
    ]
  );

  const onReady = useCallback((element: any) => {
    element.focus();
  }, []);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Box
        alignItems="center"
        py={2}
        px={2}
        border={1}
        borderRadius={1}
        borderColor="grey.400"
      >
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
          onReady={onReady}
        />
      </Box>
      {error && (
        <Box m={1.5} textAlign="center" color="error.main">
          <Typography>{error}</Typography>
        </Box>
      )}
      {children}
      <Button
        fullWidth
        type="submit"
        variant={ButtonVariantTypes.PRIMARY}
        size={ButtonSizeTypes.S}
        disabled={processing}
        sx={{
          mt: 2,
          mb: 2,
        }}
      >
        {buttonContent}
      </Button>
    </form>
  );
};

export default CardForm;
