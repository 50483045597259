const loadFunction = (src: string) => {
  const image = new Image();
  let loadEnded = false;

  const initialize = () => {
    image.onload = () => {
      loadEnded = true;
    };
    image.src = src;
  };

  const getImage = () => {
    if (!loadEnded) return null;

    return image;
  };

  return { initialize, getImage };
};

export default loadFunction;
