import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { typographyClasses } from '@mui/material/Typography';
import FormHelperText, {
  formHelperTextClasses,
} from '@mui/material/FormHelperText';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputClasses } from '@mui/material/Input';
import { Modify } from '../../../common/types/ModifyType';
import InputSize from '../../atoms/Input/InputSize';
import generateInputStyle from './generateInputStyles';
import InputVariant from '../../atoms/Input/InputVariant';
import InputStatus from '../../atoms/Input/InputStatus';

type Props = Modify<
  TextFieldProps,
  {
    labelIcon?: ReactElement;
    size: InputSize;
    variant: InputVariant;
    status?: InputStatus;
    label?: string;
    helperText?: string;
    limit?: number;
    showLimitCount?: boolean;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    isAutocompleteOpen: boolean;
    extraParams: any;
  }
>;

const InputWithChip: FC<React.PropsWithChildren<Props>> = ({
  size,
  variant,
  status,
  helperText,
  limit,
  placeholder,
  value = '',
  disabled = false,
  onChange = () => {},
  showLimitCount = false,
  extraParams,
  isAutocompleteOpen,
  label,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const generateStyles = useCallback(
    (theme: any) => generateInputStyle(theme, variant, status),
    [variant, status]
  );

  const inputOnChange = useCallback(
    (e: any) => {
      if (limit && e.target.value.length > limit) {
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        return;
      }
      setInputValue(e.target.value);
      onChange(e.target.value);
    },
    [setInputValue, limit, onChange]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value, setInputValue]);

  return (
    <Box
      sx={(theme) => ({
        [`& .${inputClasses.input}`]: {
          padding: 0,
        },
        width: '100%',
        [`& .${inputClasses.input}::-webkit-input-placeholder`]: {
          color: theme.text.t6,
          fontWeight: 500,
        },
        [`& .${formHelperTextClasses.root}`]: {
          color: status ? theme.palette[status].main : theme.text.t8,
          fontSize: '0.75rem',
        },
        [`& .${inputLabelClasses.root}`]: {
          color: theme.text.t8,
        },
        [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
          color: theme.text.t6,
        },
        ...(disabled
          ? {
              [`.${formHelperTextClasses.root}`]: {
                color: `${theme.text.t3} !important`,
              },
              [`.${inputLabelClasses.root} .${typographyClasses.root}`]: {
                color: `${theme.text.t3} !important`,
              },
              [`.${inputLabelClasses.root} .${svgIconClasses.root}`]: {
                color: theme.text.t3,
              },
            }
          : {}),
      })}
    >
      <TextField
        {...extraParams}
        label={label}
        ref={extraParams.InputProps.ref}
        className={`input-${size.toUpperCase()}`}
        placeholder={placeholder}
        variant="standard"
        sx={(theme) => ({
          width: '100%',
          [`& .${outlinedInputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t8,
            fontWeight: 500,
          },
          [`& .${formHelperTextClasses.root}`]: {
            color: status ? theme.palette[status].main : theme.text.t8,
            fontSize: '0.75rem',
          },
          [`& .${inputLabelClasses.root}`]: {
            color: theme.text.t8,
          },
          [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
            color: theme.text.t8,
          },
          ...(disabled
            ? {
                [`.${formHelperTextClasses.root}`]: {
                  color: `${theme.text.t3} !important`,
                },
                [`.${inputLabelClasses.root} .${typographyClasses.root}`]: {
                  color: `${theme.text.t3} !important`,
                },
                [`.${inputLabelClasses.root} .${svgIconClasses.root}`]: {
                  color: theme.text.t3,
                },
              }
            : {}),
          ...generateStyles(theme),
        })}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        }}
        InputProps={{
          ...extraParams.InputProps,
          disableUnderline: true,
          sx: {
            display: 'inline-flex',
            gap: '8px',
            padding: '8px 16px',
            borderRadius: !isAutocompleteOpen ? '14px' : '18px 18px 0px 0px',
            minHeight: '40px',
            fontSize: '1rem',
          },
          value: inputValue,
        }}
        disabled={disabled}
        onChange={inputOnChange}
      />
      {(helperText || limit) && (
        <FormHelperText
          className="statusTextStyle"
          sx={{
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 0.5,
          }}
        >
          <span>{helperText || ''}</span>
          {showLimitCount && limit && (
            <span>{`${inputValue.length}/${limit}`}</span>
          )}
        </FormHelperText>
      )}
    </Box>
  );
};

export default InputWithChip;
