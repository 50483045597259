type UtmParamsType = {
  [key: string]: string;
};

export const getUtmParameters = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const utmParams: UtmParamsType = {};

  searchParams.forEach((value, key) => {
    if (key.includes('utm')) {
      utmParams[key] = value;
    }
  });

  return utmParams;
};
