import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../common/utils/compose.utils';
import { withCanvasContext } from '../Contexts/CanvasContext';
import * as currentDrawingReducer from '../../../common/reducers/board/currentDrawingReducer';
import * as sessionReducer from '../../../common/reducers/session/sessionReducer';
import drawers from './drawers/index';
import { background } from '../../../ui/atoms/Colors/colorStyles';

const enhancer = compose(
  withCanvasContext,
  connect((state) => ({
    currentDrawing: currentDrawingReducer.getCurrentDrawing(state),
    isFinished: sessionReducer.finishedSelector(state),
  }))
);

class FrontLayer extends React.Component {
  componentDidMount() {
    window.requestAnimationFrame(this.draw);
  }

  componentDidUpdate() {
    window.requestAnimationFrame(this.draw);
  }

  setDrawingContext = (ref) => {
    if (!ref) return;
    if (this.props.setRef) {
      this.props.setRef(ref);
    }
    this.drawingContext = ref.getContext('2d');
  };

  drawingContext = null;

  draw = () => {
    const {
      zoom,
      width,
      height,
      actions,
      scale,
      originX,
      originY,
      currentDrawing,
    } = this.props;

    if (!this.drawingContext) return;

    this.drawingContext.clearRect(0, 0, width, height);
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    actions.forEach((action) => {
      const drawer = drawers[action.name];
      if (!drawer) return;
      drawer(this.drawingContext, action);
    });

    if (
      currentDrawing &&
      ['erase', 'erase_v2', 'erase_v3'].includes(currentDrawing.name)
    ) {
      const drawer = drawers[currentDrawing.name];
      if (!drawer) return;
      drawer(this.drawingContext, currentDrawing);
    }

    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);

    // Draw grey border if board size is less then the canvas
    this.drawBorders();
  };

  drawBorders = () => {
    const { zoom, width, height, scaledBoardWidth, scaledBoardHeight } =
      this.props;

    const widthDiff = width - scaledBoardWidth * zoom;
    if (widthDiff > 0) {
      this.drawingContext.save();
      this.drawingContext.fillStyle = background.bg2;
      this.drawingContext.fillRect(0, 0, widthDiff / 2, height);
      this.drawingContext.fillRect(
        widthDiff / 2 + scaledBoardWidth * zoom,
        0,
        widthDiff / 2,
        height
      );
      this.drawingContext.restore();
    }

    const heightDiff = height - scaledBoardHeight * zoom;
    if (heightDiff > 0) {
      this.drawingContext.save();
      this.drawingContext.fillStyle = background.bg2;
      this.drawingContext.fillRect(0, 0, width, heightDiff / 2);
      this.drawingContext.fillRect(
        0,
        heightDiff / 2 + scaledBoardHeight * zoom,
        width,
        height / 2
      );
      this.drawingContext.restore();
    }
  };

  render() {
    const { width, height } = this.props;

    return (
      <canvas ref={this.setDrawingContext} width={width} height={height} />
    );
  }
}

export default enhancer(FrontLayer);
