import { FC, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Dialog from '../../../ui/atoms/Dialog';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import {
  calculatorConfiguration,
  screenshotOptions,
} from '../../../common/constants/desmos';
import { GraphState } from './types/graph.interface';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (graphState: GraphState, imgUrl: string) => Promise<void>;
  graphState: GraphState | null;
  edit: boolean;
};

const CreateGraphDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSubmit,
  graphState,
  edit,
}) => {
  const calculatorRef = useRef<any>(null);
  const onSuccess = useCallback(() => {
    calculatorRef.current.asyncScreenshot(
      screenshotOptions,
      (imageUrl: string) => {
        const calculatorState = calculatorRef.current.getState();
        onSubmit(calculatorState, imageUrl);
      }
    );
  }, [onSubmit]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(1365));

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
      fullWidth
      TransitionProps={{
        onEntered: () => {
          const element = document.getElementById('calculator');
          calculatorRef.current = window.Desmos.GraphingCalculator(
            element!,
            calculatorConfiguration
          );

          if (graphState) {
            calculatorRef.current.setState(graphState);
            if (edit) {
              calculatorRef.current.setDefaultState(graphState);
            }
          }
        },
        onExited: () => {
          if (!calculatorRef.current) return;
          calculatorRef.current.destroy();
          calculatorRef.current = null;
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} onClose={onClose}>
        Create Graph
      </DialogTitle>
      <DialogContent dividers>
        <Box
          id="calculator"
          sx={(theme) => ({
            minWidth: '600px',
            height: 'max(65vh, 350px)',
            [theme.breakpoints.down(1366)]: {
              height: '100%',
            },
          })}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Close
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onSuccess}>
          {edit ? 'Save' : 'Insert'}
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGraphDialog;
