import LineResizer from './LineResizer';

export default class LeftLineResizer extends LineResizer {
  center() {
    return this.startPoint;
  }

  onResize({ x, y }) {
    return {
      startPoint: { x, y },
      endPoint: { ...this.endPoint },
    };
  }
}
