import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '../../atoms/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';

type Props = {
  open: boolean;
  onClose: () => void;
};

const WebRtcNotSupportedDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle onClose={onClose}>Call feature not supported</DialogTitle>
    <DialogContent>
      <Box
        sx={{
          marginTop: 1.25,
          lineHeight: '20px',
          color: '#677273',
        }}
      >
        <Typography>Your browser does not support Call feature.</Typography>
        <Typography>We recommend using:</Typography>
        <Box component="ul" ml={1.74}>
          <Box
            component="li"
            sx={{
              listStyleType: 'disc',
            }}
          >
            <Box component="span" fontWeight={600}>
              Chrome
            </Box>{' '}
            for Windows, Linux and Android
          </Box>
          <Box
            component="li"
            sx={{
              listStyleType: 'disc',
            }}
          >
            <Box component="span" fontWeight={600}>
              Safari
            </Box>{' '}
            for Mac and iOS
          </Box>
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <DialogActionSecondaryButton onClick={onClose}>
        Close
      </DialogActionSecondaryButton>
    </DialogActions>
  </Dialog>
);

export default WebRtcNotSupportedDialog;
