const SET_SHOULD_UPDATE_THUMBNAIL = Symbol('SET_SHOULD_UPDATE_THUMBNAIL');

const setShouldUpdateThumbnail = value => ({
  type: SET_SHOULD_UPDATE_THUMBNAIL,
  value,
});

export {
  setShouldUpdateThumbnail,
  SET_SHOULD_UPDATE_THUMBNAIL,
};
