import React, { createRef } from 'react';
import { connect } from 'react-redux';
import compose from '../../../../common/utils/compose.utils';
import { Subject, BehaviorSubject, pipe, combineLatest, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { withCanvasContext } from '../../Contexts/CanvasContext';
import DomEventsUtil from '../../../../common/utils/domEvents.util';
import ImageUtils from '../../../../common/utils/image.utils';
import drawers from '../drawers';
import BoundaryBox from './boundaries/BoundaryBox';
import * as imagesActions from '../../../../common/actions/imagesActions';
import * as imageDrawingToolReducer from '../../../../common/reducers/board/imageDrawingToolReducer';
import * as currentDrawingAction from '../../../../common/actions/board/currentDrawingAction';
import * as drawingToolReducer from '../../../../common/reducers/board/drawingToolReducer';
import * as imageDrawingToolActions from '../../../../common/actions/board/imageDrawingToolActions';
import TopLeftResizer from './boundaries/resizers/TopLeftResizer';
import TopRightResizer from './boundaries/resizers/TopRightResizer';
import BottomLeftResizer from './boundaries/resizers/BottomLeftResizer';
import BottomRightResizer from './boundaries/resizers/BottomRightResizer';
import TopLeftRotation from './boundaries/rotation/TopLeftRotation';
import BottomLeftRotation from './boundaries/rotation/BottomLeftRotation';
import TopRightRotation from './boundaries/rotation/TopRightRotation';
import BottomRightRotation from './boundaries/rotation/BottomRightRotation';
import RotateButton from './boundaries/rotation/RotateButton';
import Resizer from './boundaries/resizers/Resizer';
import * as rectangleUtil from '../../../../common/utils/shapes/rectangle.util';
import ImageDrawingAction from '../../../../common/drawingActions/image.drawing.action';
import vectorUtils from '../../../../common/utils/vector.utils';
import DrawingModes from './DrawingModes';
import shapesHitTest from './shapesHitTest';
import ImageDrawingToolbar from './boundaries/ImageDrawingToolbar';
import ImageCropDialog from '../../../../containers/Modals/ImageCropDialog';
import DeleteAction from '../../../../common/drawingActions/delete.action';
import clearSelection from './clearSelection';
import withFlag from '../../../../common/hocs/withFlag';
import UnleashFlags from '../../../../common/constants/UnleashFlag';
import SelectCursorSvgIcon from '../../../../assets/svgIcons/SelectCursorSvgIcon.svg';
import ToolNames from '../../../DrawingTools/ToolNames';

const enhancer = compose(
  withCanvasContext,
  connect(
    (state) => ({
      imageAction: imageDrawingToolReducer.getImageDrawingTool(state),
      previousDrawingTool:
        drawingToolReducer.getSelectedDrawingTool(state).previousDrawingTool,
    }),
    {
      setImageAction: currentDrawingAction.setImageCurrentDrawing,
      saveImage: imagesActions.saveImage,
      clearImage: imageDrawingToolActions.clearImage,
      setImage: imageDrawingToolActions.setImage,
    }
  ),
  withFlag(UnleashFlags.IMAGE_ROTATION)
);

class ImageDrawingLayer extends React.Component {
  padding = 8;

  constructor(props) {
    super(props);

    this.state = {
      cropModalOpen: false,
      toolbarVisible: true,
      action: null,
    };

    this.shouldApplyImageRef = createRef();
    this.shouldApplyImageRef.current = true;
  }

  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.zoomProp$.next(this.props.zoom);
    this.scaleProp$.next(this.props.scale);
    this.originXProp$.next(this.props.originX);
    this.originYProp$.next(this.props.originY);
    this.colorProp$.next(this.props.color);
    this.sizeProp$.next(this.props.size);
    this.fillProp$.next(this.props.fill);
    this.currentPageIdProp$.next(this.props.currentPageId);
    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();

    if (
      this.props.currentDrawing &&
      this.props.currentDrawing.targetId !== null
    ) {
      const radianAngle = vectorUtils.degreeToRadian(
        this.props.currentDrawing.rotation
      );
      this.rotationAngle$.next(radianAngle);

      const { currentDrawing } = this.props;

      const imageCoordinates = {
        startPoint: { ...currentDrawing.startPoint },
        endPoint: {
          x: currentDrawing.startPoint.x + currentDrawing.getWidth(),
          y: currentDrawing.startPoint.y + currentDrawing.getHeight(),
        },
      };

      this.coordinates$.next(imageCoordinates);
      this.imagePosition$.next(imageCoordinates);
      this.mode$.next(DrawingModes.Drawn);
    } else if (this.props.imageAction) {
      const x =
        (this.props.originX +
          (this.props.width - this.props.imageAction.width) /
            2 /
            this.props.zoom) /
        this.props.scale;
      const y =
        (this.props.originY +
          (this.props.height - this.props.imageAction.height) /
            2 /
            this.props.zoom) /
        this.props.scale;

      const imageCoordinates = {
        startPoint: { x, y },
        endPoint: {
          x:
            x +
            this.props.imageAction.width / this.props.scale / this.props.zoom,
          y:
            y +
            this.props.imageAction.height / this.props.scale / this.props.zoom,
        },
      };

      this.coordinates$.next(imageCoordinates);
      this.imagePosition$.next(imageCoordinates);
      this.mode$.next(DrawingModes.Drawn);
    } else {
      this.mode$.next(DrawingModes.Initial);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.zoom !== prevProps.zoom) {
      this.zoomProp$.next(this.props.zoom);
    }
    if (this.props.scale !== prevProps.scale) {
      this.scaleProp$.next(this.props.scale);
    }
    if (this.props.originX !== prevProps.originX) {
      this.originXProp$.next(this.props.originX);
    }
    if (this.props.originY !== prevProps.originY) {
      this.originYProp$.next(this.props.originY);
    }
    if (this.props.color !== prevProps.color) {
      this.colorProp$.next(this.props.color);
    }
    if (this.props.size !== prevProps.size) {
      this.sizeProp$.next(this.props.size);
    }
    if (this.props.fill !== prevProps.fill) {
      this.fillProp$.next(this.props.fill);
    }
    if (this.props.currentPageId !== prevProps.currentPageId) {
      this.currentPageIdProp$.next(this.props.currentPageId);
    }

    if (this.props.imageAction !== prevProps.imageAction) {
      if (
        this.props.imageAction &&
        this.props.imageAction.image !== prevProps.imageAction?.image &&
        this.props.currentDrawing
      ) {
        this.props.imageAction.imageUrl = null;
        this.props.currentDrawing.imageUrl = null;
        this.props.currentDrawing.setImage(this.props.imageAction.image);
      }

      const x =
        (this.props.originX +
          (this.props.width - this.props.imageAction.width) /
            2 /
            this.props.zoom) /
        this.props.scale;
      const y =
        (this.props.originY +
          (this.props.height - this.props.imageAction.height) /
            2 /
            this.props.zoom) /
        this.props.scale;

      const updatedCoordinates = {
        startPoint: { x, y },
        endPoint: {
          x:
            x +
            this.props.imageAction.width / this.props.scale / this.props.zoom,
          y:
            y +
            this.props.imageAction.height / this.props.scale / this.props.zoom,
        },
      };

      this.coordinates$.next(updatedCoordinates);
      this.imagePosition$.next(updatedCoordinates);
      this.mode$.next(DrawingModes.Drawn);
    }
  }

  componentWillUnmount() {
    if (this.shouldApplyImageRef.current) {
      const action = this.state.action;
      this.applyAction(action);
    }

    if (this.props.currentDrawing) {
      this.props.setCurrentDrawing(null);
    }

    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();
    this.zoomProp$.complete();
    this.scaleProp$.complete();
    this.originXProp$.complete();
    this.originYProp$.complete();
    this.colorProp$.complete();
    this.sizeProp$.complete();
    this.fillProp$.complete();
    this.coordinates$.complete();
    this.currentPageIdProp$.complete();
    this.imagePosition$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');
  };

  canvas = null;
  drawingContext = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();
  mode$ = new BehaviorSubject();
  shape$ = new BehaviorSubject();
  zoomProp$ = new BehaviorSubject();
  scaleProp$ = new BehaviorSubject();
  originXProp$ = new BehaviorSubject();
  originYProp$ = new BehaviorSubject();
  colorProp$ = new BehaviorSubject();
  sizeProp$ = new BehaviorSubject();
  fillProp$ = new BehaviorSubject();
  currentPageIdProp$ = new Subject();
  coordinates$ = new BehaviorSubject();
  rotationShape$ = new BehaviorSubject();
  rotationAngle$ = new BehaviorSubject(0);
  imagePosition$ = new BehaviorSubject(null);

  props$ = combineLatest(
    this.zoomProp$,
    this.scaleProp$,
    this.originXProp$,
    this.originYProp$,
    this.colorProp$,
    this.sizeProp$,
    this.fillProp$
  ).pipe(
    map(([zoom, scale, originX, originY, color, size, fill]) => ({
      zoom,
      scale,
      originX,
      originY,
      color,
      size,
      fill,
    }))
  );

  onDelete = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
    this.shouldApplyImageRef.current = false;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const deleteAction = new DeleteAction();
      deleteAction.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(deleteAction);
      this.props.selectDrawingTool(ToolNames.Select);
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    } else {
      this.props.selectDrawingTool(ToolNames.Select);
    }

    this.reset();
    this.props.clearImage();
  };

  onCopy = async () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;

    const drawingAction = this.state.action;
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    c.width = drawingAction.width;
    c.height = drawingAction.height;
    ctx.drawImage(drawingAction.image, 0, 0, c.width, c.height);

    const img = await new Promise((resolve) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.src = c.toDataURL();
    });

    this.props.saveImage(drawingAction.id, img);

    drawingAction.image = img;
    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      drawingAction.targetId = this.props.currentDrawing.targetId;
      drawingAction.imageUrl = this.props.currentDrawing.imageUrl;
    }

    this.props.addActionAndSend(drawingAction);

    this.mode$.next(DrawingModes.Drawn);

    const copiedCoordinates = {
      startPoint: {
        x: drawingAction.startPoint.x + this.padding,
        y: drawingAction.startPoint.y + this.padding,
      },
      endPoint: {
        x: drawingAction.startPoint.x + drawingAction.width + this.padding,
        y: drawingAction.startPoint.y + drawingAction.height + this.padding,
      },
    };

    this.coordinates$.next(copiedCoordinates);
    this.imagePosition$.next(copiedCoordinates);
  };

  draw = (shape, boundaries, selectedShape) => {
    if (!shape) return;

    const { zoom, scale, originX, originY } = this.props;
    if (!this.drawingContext) return;

    const drawer = drawers[shape.name];
    if (!drawer) return;

    this.clear();

    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    drawer(this.drawingContext, shape);

    if (boundaries && boundaries.length) {
      boundaries.forEach((boundary) => {
        boundary.draw(
          this.drawingContext,
          selectedShape && boundary.constructor === selectedShape.constructor
        );
      });
    }
    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  clear = () => {
    const { width, height } = this.props;
    this.drawingContext.clearRect(0, 0, width, height);
  };

  subscribeToDrawing = () => {
    const transformPoint = () =>
      pipe(
        withLatestFrom(this.props$),
        map(([point, props]) => {
          const { originX, originY, scale, zoom } = props;

          return {
            x: (point.x / zoom + originX) / scale,
            y: (point.y / zoom + originY) / scale,
          };
        })
      );

    const withMode = (m) =>
      pipe(
        withLatestFrom(this.mode$),
        filter(([, mode]) => mode === m),
        map(([e]) => e)
      );

    const startEvents$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint(), tap(clearSelection));

    const moveEvents$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint());

    const endEvents$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    );

    const rectangle$ = combineLatest(
      this.coordinates$,
      this.props$,
      this.rotationAngle$
    ).pipe(
      map(([positions, props, radianAngle]) => {
        if (!positions || !props) return null;

        const action = this.createShape(positions, radianAngle, props);
        this.setState({ action });

        return action;
      })
    );

    const boundaries$ = combineLatest(
      this.coordinates$,
      this.props$,
      this.mode$,
      this.rotationAngle$
    ).pipe(
      map(([positions, props, mode, radianAngle]) => {
        if (!positions || !props) return [];

        return this.createBoundaries(positions, props, mode, radianAngle);
      })
    );

    // Drag start
    startEvents$
      .pipe(
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$, rectangle$)
      )
      .subscribe(async ([coordinates, shapes, action]) => {
        const shape = shapesHitTest(coordinates, shapes);
        if (shape) {
          let newMode;

          if (shape instanceof BoundaryBox) {
            newMode = DrawingModes.Dragging;
          }

          if (shape instanceof RotateButton) {
            newMode = DrawingModes.Rotating;
          }

          if (shape instanceof Resizer) {
            newMode = DrawingModes.Resizing;
          }

          this.mode$.next(newMode);
          this.shape$.next(shape);
          this.setState({
            toolbarVisible: false,
          });

          return;
        }

        if (!this.shouldApplyImageRef.current) return;

        const isOutsideClick = true;
        this.shouldApplyImageRef.current = false;
        this.applyAction(action, isOutsideClick);
      });

    // Dragging
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        withLatestFrom(boundaries$),
        concatMap(() =>
          moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Dragging),
            pairwise(),
            map(([oldPos, newPos]) => ({
              deltaX: newPos.x - oldPos.x,
              deltaY: newPos.y - oldPos.y,
            }))
          )
        ),
        withLatestFrom(this.coordinates$),
        map(([transform, { startPoint, endPoint }]) => ({
          startPoint: {
            x: startPoint.x + transform.deltaX,
            y: startPoint.y + transform.deltaY,
          },
          endPoint: {
            x: endPoint.x + transform.deltaX,
            y: endPoint.y + transform.deltaY,
          },
        }))
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Drag end
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ startPoint, endPoint }) => {
        this.setState({
          toolbarVisible: true,
        });
        this.imagePosition$.next({
          startPoint,
          endPoint,
        });
        this.mode$.next(DrawingModes.Drawn);
      });

    // Resizing
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        withLatestFrom(boundaries$),
        concatMap(([point, shapes]) => {
          const shape = shapesHitTest(point, shapes);

          return moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Resizing),
            map((pos) => {
              const deltaX = pos.x - point.x;
              const deltaY = pos.y - point.y;

              return shape.onResize(deltaX, deltaY);
            }),
            filter((p) => p !== null),
            map(({ startPoint, endPoint }) =>
              this.normalizePoints(startPoint, endPoint)
            )
          );
        })
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Resize end
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ startPoint, endPoint }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.shape$.next(null);
        this.imagePosition$.next({
          startPoint,
          endPoint,
        });
        this.setState({
          toolbarVisible: true,
        });
      });

    this.mouseMove$
      .pipe(
        map(DomEventsUtil.mouseEventToCoordinates),
        transformPoint(),
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$)
      )
      .subscribe(([coordinates, shapes]) => {
        for (let i = shapes.length - 1; i >= 0; i -= 1) {
          const shape = shapes[i];
          if (shape.hitTest(coordinates.x, coordinates.y)) {
            if (shape instanceof RotateButton) {
              this.rotationShape$.next(shape);
            } else {
              this.rotationShape$.next(null);
            }
            shape.onHover(this.drawingContext);
            return;
          }
          this.rotationShape$.next(null);
        }

        this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
      });

    //  Rotating start
    startEvents$
      .pipe(
        withMode(DrawingModes.Rotating),
        withLatestFrom(boundaries$),
        concatMap(([coordinates, shapes]) => {
          const shape = shapesHitTest(coordinates, shapes);

          return moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Rotating),
            map((pos) => {
              const angle = vectorUtils.radianToDegree(shape.onRotate(pos));
              const positiveAngle = vectorUtils.normalizeAngle(angle);

              if ((positiveAngle + 2) % 45 <= 4) {
                const mod = +(angle / 45).toFixed();

                return vectorUtils.degreeToRadian(mod * 45);
              }

              return vectorUtils.degreeToRadian(angle);
            })
          );
        })
      )
      .subscribe((radianAngle) => {
        this.rotationAngle$.next(radianAngle);
      });

    // Rotating end
    startEvents$
      .pipe(
        withMode(DrawingModes.Rotating),
        concatMap(() => endEvents$.pipe(take(1)))
      )
      .subscribe(() => {
        this.mode$.next(DrawingModes.Drawn);
        this.shape$.next(null);
        this.setState({
          toolbarVisible: true,
        });
      });

    combineLatest(
      rectangle$,
      combineLatest(boundaries$, this.mode$).pipe(
        map(([boundaries, mode]) => {
          if (mode === DrawingModes.Initial) return [];

          return boundaries;
        })
      ),
      this.shape$,
      this.rotationShape$,
      this.rotationAngle$
    ).subscribe(
      ([shape, boundaries, boundaryShape, rotationShape, rotationAngle]) => {
        if (!shape) {
          this.clear();
          return;
        }

        const selectedShape = rotationShape || boundaryShape;

        this.draw(shape, boundaries, selectedShape, rotationAngle);
      }
    );

    this.currentPageIdProp$.subscribe(() => {
      this.reset();
      this.shouldApplyImageRef.current = false;
      this.props.selectDrawingTool(ToolNames.Select);
      this.props.setCurrentDrawing(null);
      this.props.clearImage();
    });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  applyAction = (action, isOutsideClick = false) => {
    const drawingAction = action;
    if (!drawingAction) return;

    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    c.width = drawingAction.image.width;
    c.height = drawingAction.image.height;
    ctx.drawImage(drawingAction.image, 0, 0, c.width, c.height);
    const img = new Image();

    drawingAction.rotation = vectorUtils.normalizeAngle(drawingAction.rotation);

    img.onload = () => {
      this.props.saveImage(drawingAction.id, img);

      drawingAction.image = img;
      if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
        drawingAction.targetId = this.props.currentDrawing.targetId;
      }

      this.props.addActionAndSend(drawingAction);

      this.reset();
      if (isOutsideClick) {
        this.props.selectDrawingTool(ToolNames.Select);
      }
      this.props.setCurrentDrawing(null);
      this.props.clearImage();
    };

    const extension = ImageUtils.getMimeTypeFromDataUrl(
      drawingAction.image.src
    );

    img.src = c.toDataURL(extension);
  };

  createShape = (positions, radianAngle = 0) => {
    const { startPoint, endPoint } = positions;
    const drawingAction = new ImageDrawingAction();
    if (
      this.props.currentDrawing &&
      this.props.currentDrawing.targetId !== null
    ) {
      drawingAction.image = this.props.currentDrawing.image;
      drawingAction.imageUrl = this.props.currentDrawing.imageUrl;
    } else {
      drawingAction.image = this.props.imageAction.image;
    }
    drawingAction.localStartTime = new Date();
    drawingAction.startPoint = startPoint;
    drawingAction.width = endPoint.x - startPoint.x;
    drawingAction.height = endPoint.y - startPoint.y;
    drawingAction.rotation = vectorUtils.radianToDegree(radianAngle);

    return drawingAction;
  };

  reset = () => {
    this.coordinates$.next(null);
    this.imagePosition$.next(null);
    this.mode$.next(DrawingModes.Initial);
    this.clear();
  };

  createBoundaries = (positions, props, mode, radianAngle = 0) => {
    const { scale, zoom } = props;
    const { startPoint, endPoint } = positions;
    const scaledPadding = this.padding / scale;
    const params = [
      rectangleUtil.getStartingPoint(startPoint, endPoint),
      rectangleUtil.getEndPoint(startPoint, endPoint),
      scale * zoom,
      scaledPadding,
      radianAngle,
    ];

    const radius = 5;
    const cornerResizerParams = [...params, radius];

    const shapes = [new BoundaryBox(...params)];

    if (mode && mode !== DrawingModes.Dragging) {
      shapes.push(
        new TopLeftResizer(...cornerResizerParams),
        new TopRightResizer(...cornerResizerParams),
        new BottomRightResizer(...cornerResizerParams),
        new BottomLeftResizer(...cornerResizerParams)
      );
      if (this.props[`${UnleashFlags.IMAGE_ROTATION}Enabled`]) {
        shapes.push(
          new TopLeftRotation(...params),
          new TopRightRotation(...params),
          new BottomLeftRotation(...params),
          new BottomRightRotation(...params)
        );
      }
    }

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => ({
    startPoint: rectangleUtil.getStartingPoint(startPoint, endPoint),
    endPoint: rectangleUtil.getEndPoint(startPoint, endPoint),
  });

  openCropModal = () => {
    this.setState({
      cropModalOpen: true,
    });
  };

  closeCropModal = () => {
    this.setState({
      cropModalOpen: false,
    });
  };

  insertImage = ({ image, width, height }) => {
    const { setImage } = this.props;

    setImage({
      image,
      width,
      height,
    });
  };

  getToolbarPosition = (position) => {
    if (!position) return null;

    const { originX, originY, scale, zoom, originOffsetY } = this.props;

    return {
      x: (position.startPoint.x * scale - originX) * zoom,
      y: (position.startPoint.y * scale - originY + originOffsetY) * zoom,
      width: (position.endPoint.x - position.startPoint.x) * scale * zoom,
      height: (position.endPoint.y - position.startPoint.y) * scale * zoom,
    };
  };

  calculateResizingBoxPosition(coordinates, radianAngle = 0) {
    if (!coordinates) return;

    const midPoint = vectorUtils.calculateMidPoint(
      coordinates.startPoint,
      coordinates.endPoint
    );

    const firstPoint = {
      x: coordinates.startPoint.x,
      y: coordinates.startPoint.y,
    };

    const secondPoint = {
      x: coordinates.endPoint.x,
      y: coordinates.startPoint.y,
    };

    const thirdPoint = {
      x: coordinates.endPoint.x,
      y: coordinates.endPoint.y,
    };

    const fourthPoint = {
      x: coordinates.startPoint.x,
      y: coordinates.endPoint.y,
    };

    const rotatedFirstPoint = vectorUtils.calculateRotatedPoint(
      firstPoint,
      midPoint,
      radianAngle
    );
    const rotatedSecondPoint = vectorUtils.calculateRotatedPoint(
      secondPoint,
      midPoint,
      radianAngle
    );
    const rotatedThirdPoint = vectorUtils.calculateRotatedPoint(
      thirdPoint,
      midPoint,
      radianAngle
    );
    const rotatedFourthPoint = vectorUtils.calculateRotatedPoint(
      fourthPoint,
      midPoint,
      radianAngle
    );

    const startPoint = {
      x: Math.min(
        rotatedFirstPoint.x,
        rotatedSecondPoint.x,
        rotatedThirdPoint.x,
        rotatedFourthPoint.x
      ),
      y: Math.min(
        rotatedFirstPoint.y,
        rotatedSecondPoint.y,
        rotatedThirdPoint.y,
        rotatedFourthPoint.y
      ),
    };

    const endPoint = {
      x: Math.max(
        rotatedFirstPoint.x,
        rotatedSecondPoint.x,
        rotatedThirdPoint.x,
        rotatedFourthPoint.x
      ),
      y: Math.max(
        rotatedFirstPoint.y,
        rotatedSecondPoint.y,
        rotatedThirdPoint.y,
        rotatedFourthPoint.y
      ),
    };

    return { startPoint, endPoint };
  }

  getResizedBoxPosition = (positions) => {
    if (!positions) return null;

    const scaledPadding = this.padding / this.props.scale;
    const radianAngle = this.rotationAngle$.getValue();

    return this.calculateResizingBoxPosition(
      {
        startPoint: {
          x: positions.startPoint.x - scaledPadding,
          y: positions.startPoint.y - scaledPadding,
        },
        endPoint: {
          x: positions.endPoint.x + scaledPadding,
          y: positions.endPoint.y + scaledPadding,
        },
      },
      radianAngle
    );
  };

  render() {
    const coordinates = this.coordinates$.getValue();
    const imagePosition = this.imagePosition$.getValue();
    const resizedBoxPosition = this.getResizedBoxPosition(imagePosition);
    const toolbarPosition = this.getToolbarPosition(resizedBoxPosition);

    const { width, height, imageAction, currentDrawing, scale } = this.props;

    const { cropModalOpen, toolbarVisible } = this.state;

    const imageUrl = imageAction?.image.src || currentDrawing?.image.src;

    let srcDimensions;

    if (coordinates) {
      const { startPoint, endPoint } = coordinates;
      srcDimensions = {
        width: (endPoint.x - startPoint.x) * scale,
        height: (endPoint.y - startPoint.y) * scale,
      };
    }

    const mode = this.mode$.getValue();
    const isToolbarInteractive =
      mode !== DrawingModes.Resizing && mode !== DrawingModes.Rotating;

    return (
      <>
        <canvas
          ref={this.setDrawingContext}
          width={width}
          height={height}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
          onMouseUp={this.onMouseUp}
          onContextMenu={this.onContextMenu}
        />
        {toolbarPosition && (
          <ImageDrawingToolbar
            open={toolbarVisible}
            position={toolbarPosition}
            onDelete={this.onDelete}
            onCopy={this.onCopy}
            openCropModal={this.openCropModal}
            anchorEl={this.drawingContext}
            interactive={isToolbarInteractive}
          />
        )}
        <ImageCropDialog
          open={cropModalOpen}
          onClose={this.closeCropModal}
          imageUrl={imageUrl}
          srcDimensions={srcDimensions}
          insertImage={this.insertImage}
        />
      </>
    );
  }
}

export default enhancer(ImageDrawingLayer);
