import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CreateGraphDialog from '../CreateGraphDialog/CreateGraphDialog';
import FeatureTourDialog from '../../../components/BoardControls/QuickQuestion/common/FeatureTourDialog';
import FeatureTourDto from '../../../common/services/dto/featureTours/FeatureTourDto';
import { GraphState } from '../CreateGraphDialog/types/graph.interface';
import useDialog from '../../../common/hooks/useDialog';
import { graphTourSelector } from '../../../common/reducers/featureToursReducer';
import analyticsService from '../../../common/services/analytics.service';
import FeatureTourType from '../../../common/services/types/featureTours/FeatureTourType';
import styles from './OpenGraphDialog.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (graphState: GraphState, imgUrl: string) => Promise<void>;
  graphState: GraphState | null;
  edit: boolean;
};

const OpenGraphDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSubmit,
  graphState,
  edit,
}) => {
  const featureTourData = useSelector<any, FeatureTourDto>(graphTourSelector);

  const {
    open: createGraphDialogOpen,
    onOpen: onCreateGraphDialogOpen,
    onClose: onCreateGraphDialogClose,
  } = useDialog();

  const {
    open: featureTourDialogOpen,
    onOpen: onFeatureTourDialogOpen,
    onClose: onFeatureTourDialogClose,
  } = useDialog();

  useEffect(() => {
    if (featureTourData && open) {
      if (featureTourData.hasUpdates && featureTourData.steps.length) {
        onFeatureTourDialogOpen();
        return;
      }

      onCreateGraphDialogOpen();
    }
  }, [featureTourData, onFeatureTourDialogOpen, onCreateGraphDialogOpen, open]);

  const onTourDialogClose = useCallback(async () => {
    onFeatureTourDialogClose();
    onClose();
  }, [onFeatureTourDialogClose, onClose]);

  const createGraphDialogClose = useCallback(() => {
    onCreateGraphDialogClose();
    onClose();
  }, [onCreateGraphDialogClose, onClose]);

  const submitGraph = useCallback(
    async (calculatorState: any, screenshot: any) => {
      await onSubmit(calculatorState, screenshot);
      onCreateGraphDialogClose();
    },
    [onSubmit, onCreateGraphDialogClose]
  );

  const onCreateGraphClick = useCallback(async () => {
    onCreateGraphDialogOpen();
    onFeatureTourDialogClose();
  }, [onCreateGraphDialogOpen, onFeatureTourDialogClose]);

  const onFeatureTourComplete = useCallback((planId: any) => {
    analyticsService.event('Desmos Onboarding Completed', {
      'Plan Id': planId,
    });
  }, []);

  const onFeatureTourSkip = useCallback(() => {
    analyticsService.event('Desmos Onboarding Skipped');
  }, []);

  return (
    <>
      {featureTourData &&
        featureTourData.hasUpdates &&
        featureTourData.steps.length > 0 &&
        featureTourData.version && (
          <FeatureTourDialog
            open={featureTourDialogOpen}
            onClose={onTourDialogClose}
            featureTourData={featureTourData}
            imageClass={styles.image}
            type={FeatureTourType.GRAPH}
            title="Introducing Graph Editor"
            featureAction="Try out"
            onComplete={onFeatureTourComplete}
            onSkip={onFeatureTourSkip}
            onCreate={onCreateGraphClick}
            onUnlock={() => {}}
            steps={featureTourData.steps}
            version={featureTourData.version}
            canUseFeature
          />
        )}
      <CreateGraphDialog
        open={createGraphDialogOpen}
        graphState={graphState}
        onClose={createGraphDialogClose}
        onSubmit={submitGraph}
        edit={edit}
      />
    </>
  );
};

export default OpenGraphDialog;
