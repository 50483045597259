import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';
import analyticsService from '../../../common/services/analytics.service';
import sessionService from '../../../common/services/session.service';
import { addSessionInList } from '../../../common/actions/sessionsActions';
import { createBoard } from '../../../common/actions/configurationActions';
import { setOpenSource } from '../../../common/actions/session/sessionActions';
import * as uiReducer from '../../../common/reducers/uiReducer';
import * as planPermissionSelectors from '../../../common/selectors/planPermissionSelectors';
import {
  collapsedParticipantsBarWidth,
  toolsBarHeight,
} from '../../../common/constants/boardToolbarsSizes';

type CreateBoardProps = {
  groupId: string;
  onLimitReached: () => void;
};

const useCreateBoard = ({ groupId, onLimitReached }: CreateBoardProps) => {
  const [creatingBoard, setCreatingBoard] = useState(false);
  const canCreateNewBoard = useSelector(
    planPermissionSelectors.canCreateNewBoardSelector
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const dpi = useSelector(uiReducer.dpiSelector);

  const onBoardCreate = useCallback(async () => {
    if (!canCreateNewBoard) {
      analyticsService.event('Create Board above Limit');
      onLimitReached();
      return;
    }

    const groupNameId = groupId && groupId === 'default' ? null : groupId;

    try {
      setCreatingBoard(true);
      const session = await sessionService.createSession(
        window.innerWidth - collapsedParticipantsBarWidth,
        window.innerHeight - toolsBarHeight,
        dpi,
        groupNameId,
        ''
      );
      setCreatingBoard(false);

      if (session) {
        dispatch(addSessionInList(session, groupId));
        dispatch(createBoard());
        dispatch(
          push({
            pathname: `/session/${session.id}`,
            state: { referrer: location.pathname },
          })
        );

        let orientation = 'landscape';

        if (window.innerWidth < window.innerHeight) {
          orientation = 'portrait';
        }

        dispatch(setOpenSource(`Create dialog: ${orientation}`));
      }

      analyticsService.event('Board Created', {
        defaultGroup: groupId && groupId === 'default',
        orientation:
          window.innerHeight > window.innerWidth ? 'Portrait' : 'Landscape',
      });
    } catch (err) {
      setCreatingBoard(false);
      onLimitReached();
    }
  }, [
    canCreateNewBoard,
    onLimitReached,
    dispatch,
    groupId,
    dpi,
    location,
    setCreatingBoard,
  ]);

  return useMemo(
    () => ({
      creatingBoard,
      onBoardCreate,
    }),
    [creatingBoard, onBoardCreate]
  );
};

export default useCreateBoard;
