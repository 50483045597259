import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import BoardEventTimer from './BoardEventTimer';
import fireBaseService from '../common/services/firebaseService';
import sessionService from '../common/services/session.service';
import { resetDrawingTool } from '../common/actions/board/drawingToolActions';
import * as sessionActions from '../common/actions/session/sessionActions';
import * as uiActions from '../common/actions/uiActions';
import { isCurrentUserHostSelector } from '../common/reducers/session/sessionReducer';
import { createSessionCutSelector } from '../common/reducers/session';
import { userSelector } from '../common/reducers/userReducer';
import BoardControls from './BoardControls';
import Loader from './Icons/Loader';
import CanvasContextProvider from './board/Contexts/CanvasContextProvider';
import RecordingContextProvider from './Recording/RecordingContextProvider';
import SessionContextProvider from './Session/SessionContextProvider';
import useDialog from '../common/hooks/useDialog';
import BoardType from '../common/services/types/session/BoardType';
import { useProductTour } from './ProductTour';
import { toolsBarHeight } from './BoardControls/ToolsBar/ToolsBar';

const App = ({ sessionId }) => {
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const errorDialog = useDialog();
  const sessionSelector = useMemo(() => createSessionCutSelector(), []);
  const session = useSelector(sessionSelector);
  const user = useSelector(userSelector);
  const isHost = useSelector(isCurrentUserHostSelector);
  const productTour = useProductTour();
  const dispatch = useDispatch();

  const openErrorModal = (error, url = '/') => {
    setError(error);
    setRedirectUrl(url);
    errorDialog.onOpen();
  };

  const closeErrorModal = () => {
    dispatch(push(redirectUrl));
  };

  const connectToSession = async (id, user) => {
    if (!user || !id) return;

    try {
      const session = await sessionService.joinSession(id);
      dispatch(sessionActions.setSession(session));

      await fireBaseService.connect(
        session.id,
        session,
        session.firebaseConfig
      );
      dispatch(sessionActions.sessionConnected());
    } catch (err) {
      console.error(err);
      openErrorModal(err.message.toString());
    }
  };

  useEffect(() => {
    dispatch(resetDrawingTool());
    dispatch(uiActions.setMessagesSideBarVisibility(false));
    dispatch(sessionActions.clearSession());

    return () => {
      dispatch(sessionActions.disconnectFromSession());
    };
  }, [sessionId]);

  useEffect(() => {
    if (!session.connected && user && sessionId) {
      connectToSession(sessionId, user);
    }
  }, [sessionId, session?.connected]);

  useEffect(() => {
    if (productTour.inProgress && session.connected) {
      productTour.resumeTour();
    }
  }, [productTour.inProgress, session?.connected, productTour.resumeTour]);

  const wrapperStyles = {
    width: '100%',
    height: '100%',
    pointerEvents: !session?.connected ? 'none' : 'auto',
    display: 'grid',
    gridTemplateAreas: `"toolsBar collaborationBar" "board collaborationBar"`,
    gridTemplateRows: `${toolsBarHeight}px 1fr`,
    gridTemplateColumns: `1fr 96px`,
  };

  return (
    <SessionContextProvider sessionId={session.id}>
      <RecordingContextProvider>
        <CanvasContextProvider>
          <div style={{ height: '100%' }}>
            <div style={wrapperStyles}>
              {session?.connected && (
                <BoardControls
                  connected={session?.connected}
                  shared={session?.shared}
                  canSaveThumbnail={session?.connected && !session?.finished}
                  isHost={isHost}
                  boardIsFinished={session?.finished}
                  showPagesTool={
                    session?.connected &&
                    session?.boardType !== BoardType.INFINITE
                  }
                  sessionId={session.id}
                  chat={session?.chat}
                  trackPresence={session.trackPresence}
                />
              )}
              <BoardEventTimer />
              {!session?.connected && <Loader />}
              <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialog.open}
                onClose={closeErrorModal}
              >
                <DialogContent>
                  <DialogContentText>{error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeErrorModal}>Back to home</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </CanvasContextProvider>
      </RecordingContextProvider>
    </SessionContextProvider>
  );
};

export default App;
