import React, { Component } from 'react';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import { toColor } from '../../../../common/utils/color.utils';
import DraggingMode from './DraggingMode';
import TextCursorSvgIcon from '../../../../assets/svgIcons/TextCursorSvgIcon.svg';
import generateBorderImageStyle from './generateBorderImage';
import { text as textColor } from '../../../../ui/atoms/Colors/colorStyles';
import { getTextDimensions } from '../../../../common/utils/textDimensions.util';

class ResizableTextArea extends Component {
  state = {
    position: null,
    isDragging: false,
  };

  componentDidMount() {
    this.textArea.focus();
  }

  textChanged = ({ target }) => {
    const { onTextChange, fontSize } = this.props;
    const textDimensions = getTextDimensions(target.value, fontSize);
    onTextChange(target.value, textDimensions.width, textDimensions.height);
  };

  onStart = () => {
    const { position } = this.props;
    this.props.onChangeDragMode(DraggingMode.Dragging);

    this.setState({
      isDragging: true,
      position,
    });
  };

  onStop = (event, { x, y }) => {
    this.props.onChangeDragMode(DraggingMode.Initial);
    this.setState({ isDragging: false });
    const { position } = this.state;
    this.props.setDropPosition({
      x: x + position?.x ?? 0,
      y: y + position?.y ?? 0,
    });

    this.textArea.focus();
    this.props.onMoveStart();
  };

  onTextareaWrapperClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    const { position, fontSize, color, text } = this.props;
    const { isDragging } = this.state;

    const commonStyles = {
      fontSize: `${fontSize}px`,
      color: toColor(color),
    };

    const wrapperStyles = {
      top: position.y - 35 - 1 || 0,
      left: position.x - 40 - 1 || 0,
    };

    return (
      <Draggable
        onDrag={this.onStart}
        onStop={this.onStop}
        cancel=".resizable-textarea"
      >
        <div
          ref={this.props.textAreaRef}
          className="resizable-textarea__wrapper"
          style={wrapperStyles}
          onClick={this.onTextareaWrapperClick}
        >
          <Box
            className="resizable-textarea__border"
            sx={{
              ...generateBorderImageStyle({
                strokeWidth: 2,
                strokeColor: isDragging ? 'transparent' : textColor.t7,
                dashArray: [20, 15],
              }),
            }}
          >
            <div className="resizable-textarea__content" style={commonStyles}>
              {`${text} `}
              <textarea
                className="resizable-textarea"
                onChange={this.textChanged}
                value={text}
                style={{
                  ...commonStyles,
                  cursor: isDragging
                    ? 'move'
                    : `url(${TextCursorSvgIcon}) 0 0, text`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                ref={(ref) => {
                  this.textArea = ref;
                }}
                disabled={isDragging}
              />
            </div>
          </Box>
        </div>
      </Draggable>
    );
  }
}

export default ResizableTextArea;
