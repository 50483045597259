const UPDATE_RECORDING_POSITION = Symbol('UPDATE_RECORDING_POSITION');

const updateRecordingPosition = actionId => ({
  type: UPDATE_RECORDING_POSITION,
  payload: {
    actionId,
  },
});

export {
  updateRecordingPosition,
  UPDATE_RECORDING_POSITION,
};
