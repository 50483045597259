import { toColor } from '../../../../common/utils/color.utils';

const arrowDrawer = (ctx, item) => {
  const {
    startPoint,
    endPoint,
    paint,
  } = item;
  const {
    color,
    strokeWidth,
  } = paint;

  const rgbaColor = toColor(color);
  ctx.save();
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.strokeStyle = rgbaColor;
  ctx.lineWidth = strokeWidth;
  ctx.beginPath();
  ctx.moveTo(startPoint.x, startPoint.y);
  ctx.lineTo(endPoint.x, endPoint.y);

  const headSize = (Math.pow(strokeWidth,1.0 / 2) + 1) * 10;
  const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

  ctx.lineTo(
    endPoint.x - headSize * Math.cos(angle - Math.PI / 6),
    endPoint.y - headSize * Math.sin(angle - Math.PI / 6),
  );
  ctx.moveTo(endPoint.x, endPoint.y);
  ctx.lineTo(
    endPoint.x - headSize * Math.cos(angle + Math.PI / 6),
    endPoint.y - headSize * Math.sin(angle + Math.PI / 6),
  );

  ctx.stroke();
  ctx.restore();
};

export default arrowDrawer;
