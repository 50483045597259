import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { push } from 'redux-first-history';

export const useNavigateTo = () => {
  const dispatch = useDispatch();

  return useCallback(
    (path: string) => {
      dispatch(push(path));
    },
    [dispatch]
  );
};
