import React from 'react';
import BackgroundLayer from './PureCanvas/BackgroundLayer';
import FrontLayer from './PureCanvas/FrontLayer';
import PointersLayer from './PureCanvas/PointersLayer';
import UILayer from './PureCanvas/UILayer';
import SocketContextProvider from './Contexts/SocketContextProvider';
import CursorLayer from './PureCanvas/CursorLayer';
import SessionContext from '../Session/SessionContext';

export default class Canvas extends React.PureComponent {
  frontLayerRef = null;

  backgroundLayerRef = null;

  getThumbnail = () => {
    const tempCanvas = document.createElement('canvas');
    const drawingContext = tempCanvas.getContext('2d');
    tempCanvas.width = this.frontLayerRef.width;
    tempCanvas.height = this.frontLayerRef.height;
    drawingContext.drawImage(this.backgroundLayerRef, 0, 0);
    drawingContext.drawImage(this.frontLayerRef, 0, 0);
    return new Promise((resolve) => {
      tempCanvas.toBlob((b) => {
        resolve(b);
      });
    });
  };

  setFrontLayerRef = (ref) => {
    const { setFrontLayer } = this.props;
    this.frontLayerRef = ref;
    if (setFrontLayer) {
      setFrontLayer(ref);
    }
  };

  setBackgroundLayerRef = (ref) => {
    const { setBackgroundLayer } = this.props;
    this.backgroundLayerRef = ref;
    if (setBackgroundLayer) {
      setBackgroundLayer(ref);
    }
  };

  render() {
    const {
      width,
      height,
      dimensions,
      actions,
      pointersLayerActive = false,
      uiLayerActive = false,
      backgroundGridActive = false,
      drawingEnabled = false,
      page = {},
      setInteractionLayer,
    } = this.props;
    const imageActions = actions.filter(
      (a) =>
        a.name === 'imageMeta' ||
        a.name === 'lockedImageMeta' ||
        a.name === 'graph'
    );
    const notImageActions = actions.filter(
      (a) =>
        a.name !== 'imageMeta' &&
        a.name !== 'lockedImageMeta' &&
        a.name !== 'graph'
    );

    return (
      <div className="canvas-block" style={{ width, height }}>
        <BackgroundLayer
          width={width}
          height={height}
          page={page}
          images={imageActions}
          drawBackgroundGrid={backgroundGridActive}
          setRef={this.setBackgroundLayerRef}
        />
        <FrontLayer
          width={width}
          height={height}
          actions={notImageActions}
          setRef={this.setFrontLayerRef}
        />
        {pointersLayerActive && <CursorLayer width={width} height={height} />}
        {pointersLayerActive && <PointersLayer width={width} height={height} />}
        {uiLayerActive && (
          <SocketContextProvider>
            <SessionContext.Consumer>
              {({ addEventListeners, removeEventListeners }) => (
                <UILayer
                  width={width}
                  height={height}
                  dimensions={dimensions}
                  drawingEnabled={drawingEnabled}
                  currentPageId={page.id}
                  setRef={setInteractionLayer}
                  addEventListeners={addEventListeners}
                  removeEventListeners={removeEventListeners}
                />
              )}
            </SessionContext.Consumer>
          </SocketContextProvider>
        )}
      </div>
    );
  }
}
