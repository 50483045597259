const clearSelection = () => {
  if (window.getSelection) {
    const selection = window.getSelection();

    if (selection && selection.removeAllRanges) {
      selection.removeAllRanges();
    }
  }
};

export default clearSelection;
