import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '../../atoms/Checkbox';
import Typography from '../../atoms/Typography/Typography';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import groupsService from '../../../common/services/groups.service';
import { deleteGroup } from '../../../common/actions/groupsActions';
import TrashCanOutlineIcon from '../../atoms/Icons/TrashCanOutlineIcon';

type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  keepCard?: boolean;
  groupId: string;
};

const DeleteGroupDialog: FC<React.PropsWithChildren<DeleteDialogProps>> = ({
  open,
  onClose,
  onSubmit,
  groupId,
  keepCard = false,
}) => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');

  const handleChange = useCallback(() => {
    setChecked((prevState) => !prevState);
  }, [setChecked]);

  const dispatch = useDispatch();

  const onDelete = useCallback(async () => {
    try {
      await groupsService.deleteGroup(groupId, !checked);
      dispatch(deleteGroup(groupId));
      onSubmit();
    } catch (e) {
      setError('Failed to delete the group.');
    }
  }, [checked, groupId, dispatch, onSubmit, setError]);

  const onEnter = useCallback(() => {
    setError('');
  }, [setError]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <DialogTitle
        icon={<TrashCanOutlineIcon color="error" />}
        onClose={onClose}
        error={error}
      >
        Delete group
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={TypographyType.p5}
          sx={(theme) => ({
            color: theme.text.t8,
          })}
        >
          Are you sure you want to delete this group?
        </Typography>
        {keepCard && (
          <Box display="flex" gap={1.25} mt={3} alignItems="center">
            <Checkbox checked={checked} onChange={handleChange} />
            <Typography variant={TypographyType.p5}>
              Keep boards in LiveBoard
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onDelete} color="error">
          Delete
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGroupDialog;
