const KEY_TYPE = {
  letter: 'letter',
  math: 'math',
  arrow: 'arrow',
};

const formulaKeys = [
  {
    code: '\\alpha',
    label: 'α',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\beta',
    label: 'β',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\gamma' ,
    label: 'γ',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\delta',
    label: 'δ',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\epsilon',
    label: 'ε',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\zeta',
    label: 'ζ',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\eta',
    label: '𝜂',
    type: KEY_TYPE.letter,
  },
  {
   code: '\\iota',
   label: '𝜄',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\kappa',
    label: '𝜅',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\lambda',
    label: '𝜆',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\mu',
    label: '𝜇',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\nu',
    label: '𝜈',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\xi',
    label: '𝜉',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\omicron',
    label: '𝜊',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\pi',
    label: '𝜋',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\rho',
    label: '𝜌',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\varsigma',
    label: '𝜍',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\sigma',
    label: '𝜎',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\tau',
    label: '𝜏',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\upsilon',
    label: '𝜐',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\varphi',
    label: '𝜑',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\phi',
    label: '𝜙',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\chi',
    label: '𝜒',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\psi',
    label: '𝜓',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\omega',
    label: '𝜔',
    type: KEY_TYPE.letter,
  },
  {
    code: '\\div',
    label: '÷',
    type: KEY_TYPE.math,
  },
  {
    code: '\\sum',
    label: '∑',
    type: KEY_TYPE.math,
  },
  {
    code: '\\sqrt{}',
    label: '√',
    type: KEY_TYPE.math,
  },
  {
    code: '\\int{}',
    label: '∫',
    type: KEY_TYPE.math,
  },
  {
    code: '\\times',
    label: '×',
    type: KEY_TYPE.math,
  },
  {
    code: '\\cap',
    label: '∩',
    type: KEY_TYPE.math,
  },
  {
    code: '\\cup',
    label: '∪',
    type: KEY_TYPE.math,
  },
  {
    code: '\\neq',
    label: '≠',
    type: KEY_TYPE.math,
  },
  {
    code: '\\leq',
    label: '≤',
    type: KEY_TYPE.math,
  },
  {
    code: '\\geq',
    label: '≥',
    type: KEY_TYPE.math,
  },
  {
    code: '\\in',
    label: '∈',
    type: KEY_TYPE.math,
  },
  {
    code: '\\perp',
    label: '⟂',
    type: KEY_TYPE.math,
  },
  {
    code: '\\notin',
    label: '∉',
    type: KEY_TYPE.math,
  },
  {
    code: '\\subset',
    label: '⊂',
    type: KEY_TYPE.math,
  },
  {
    code: '\\simeq',
    label: '≃',
    type: KEY_TYPE.math,
  },
  {
    code: '\\approx',
    label: '≈',
    type: KEY_TYPE.math,
  },
  {
    code: '\\wedge',
    label: '∧',
    type: KEY_TYPE.math,
  },
  {
    code: '\\vee',
    label: '∨',
    type: KEY_TYPE.math,
  },
  {
    code: '\\oplus',
    label: '⊕',
    type: KEY_TYPE.math,
  },
  {
    code: '\\otimes',
    label: '⊗',
    type: KEY_TYPE.math,
  },
  {
    code: '\\equiv',
    label: '≡',
    type: KEY_TYPE.math,
  },
  {
    code: '\\cong',
    label: '≅',
    type: KEY_TYPE.math,
  },
  {
    code: '\\infty',
    label: '∞',
    type: KEY_TYPE.math,
  },
  {
    code: '\\forall',
    label: '∀',
    type: KEY_TYPE.math,
  },
  {
    code: '\\nabla',
    label: '∇',
    type: KEY_TYPE.math,
  },
  {
    code: '\\exists',
    label: '∃',
    type: KEY_TYPE.math,
  },
  {
    code: '\\partial',
    label: '∂',
    type: KEY_TYPE.math,
  },
  {
    code: '\\nexists',
    label: '∄',
    type: KEY_TYPE.math,
  },
  {
    code: '\\varnothing',
    label: '∅',
    type: KEY_TYPE.math,
  },
  {
    code: '\\neg',
    label: '¬',
    type: KEY_TYPE.math,
  },
  {
    code: '\\leftarrow',
    label: '⟵',
    type: KEY_TYPE.arrow,
  },
  {
    code: '\\Leftarrow',
    label: '⟸',
    type: KEY_TYPE.arrow,
  },
  {
    code: '\\rightarrow',
    label: '⟶',
    type: KEY_TYPE.arrow,
  },
  {
    code: '\\Rightarrow',
    label: '⟹',
    type: KEY_TYPE.arrow,
  },
  {
    code: '\\leftrightarrows',
    label: '⟷',
    type: KEY_TYPE.arrow,
  },
  {
    code: '\\rightleftharpoons',
    label: '⇋',
    type: KEY_TYPE.arrow,
  },
];

export default formulaKeys;

export {
  KEY_TYPE,
};
