const SAVE_IMAGE = Symbol('SAVE_IMAGE');

const saveImage = (id, image) => ({
  type: SAVE_IMAGE,
  payload: {
    id,
    image,
  },
});

export { saveImage, SAVE_IMAGE };
