import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

type NavListItemProps = {
  to: string;
  onClick?: () => void;
  children: React.ReactNode;
};

export const NavListItem: React.FC<
  React.PropsWithChildren<NavListItemProps>
> = ({ to, onClick, children }) => {
  const match = useRouteMatch({ path: to });
  return (
    <NavLink to={to} onClick={onClick}>
      {React.isValidElement(children) &&
        React.cloneElement(children, { selected: !!match })}
    </NavLink>
  );
};
