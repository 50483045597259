import { connect } from 'react-redux';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from '../common/utils/compose.utils';
import { setDpi } from '../common/actions/uiActions';

const dpiStyles = {
  height: '1in',
  left: '-100%',
  position: 'absolute',
  top: '-100%',
  width: '1in',
};

const enhancer = compose(
  connect(
    null,
    { setDpi },
  ),
  withRouter,
);

class GetDpiComponent extends React.Component {
  setRef = (ref) => {
    const { width: dpi } = ref.getBoundingClientRect();
    this.props.setDpi(dpi);
  };

  render() {
    return (<div
      style={dpiStyles}
      ref={this.setRef}
    />);
  }
}

export default enhancer(GetDpiComponent);
