import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import IconButton from '../../../../../ui/atoms/IconButton';
import ToolbarContainer from '../../../ToolbarContainer';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import ContentCopyIcon from '../../../../../ui/atoms/Icons/ContentCopyIcon';
import TrashCanOutlineIcon from '../../../../../ui/atoms/Icons/TrashCanOutlineIcon';
import CropIcon from '../../../../../ui/atoms/Icons/CropIcon';

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Props = {
  position: Position;
  onDelete: () => void;
  onCopy: () => Promise<void>;
  openCropModal: () => void;
  open: boolean;
  interactive: boolean;
};

const popperPadding = {
  top: 56,
  right: 320,
};

const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: popperPadding,
      fallbackPlacements: ['bottom', 'right', 'left'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 20],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: true,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

const ImageDrawingToolbar: FC<React.PropsWithChildren<Props>> = ({
  position,
  onDelete,
  onCopy,
  openCropModal,
  open,
  interactive,
}) => {
  const virtualElement = useMemo(
    () => ({
      getBoundingClientRect: (): DOMRect =>
        new DOMRectReadOnly(
          position.x,
          position.y,
          position.width,
          position.height
        ),
    }),
    [position]
  );

  return (
    <Popper
      open={open}
      placement="top"
      anchorEl={virtualElement}
      modifiers={popperModifiers}
      sx={{
        ...(!interactive
          ? {
              pointerEvents: 'none',
            }
          : {}),
      }}
    >
      <ToolbarContainer>
        <Box>
          <IconButton
            onClick={onCopy}
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            onClick={openCropModal}
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
          >
            <CropIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            onClick={onDelete}
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
          >
            <TrashCanOutlineIcon />
          </IconButton>
        </Box>
      </ToolbarContainer>
    </Popper>
  );
};

export default ImageDrawingToolbar;
