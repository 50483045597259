import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '../../../../../../ui/atoms/Typography';
import authService from '../../../../../../common/services/auth.service';
import ReasonOtherField from '../../../../../../ui/molecules/DeleteAccountReasonDialog/ReasonOtherField';
import Dialog from '../../../../../../ui/atoms/Dialog';
import DialogTitle from '../../../../../../ui/atoms/DialogTitle';
import DialogContent from '../../../../../../ui/atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../../../../ui/atoms/DialogActions';
import * as userActions from '../../../../../../common/actions/userActions';

const reasons = [
  'Too expensive',
  'Difficult to use',
  'Missing features I need',
  'Switching to another product',
  'Technical issues',
];

type CancelSubscriptionModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const CancelSubscriptionModal: FC<
  React.PropsWithChildren<CancelSubscriptionModalProps>
> = ({ open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    clearErrors,
    reset,
    getFieldState,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      reason: '',
      reasonOther: '',
    },
  });

  const { error: reasonError } = getFieldState('reason');

  const [submitError, setSubmitError] = useState('');

  const onSubmit = useCallback(
    handleSubmit(async ({ reason, reasonOther }) => {
      try {
        const updatedUser = await authService.stopSubscription(
          reasonOther || reason
        );
        onSuccess();
        dispatch(userActions.setUser(updatedUser));
      } catch (err) {
        setSubmitError(err.message);
      }
    }),
    [setSubmitError, onSuccess]
  );

  const deleteReason = useWatch({ control, name: 'reason' });

  const onEnter = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={onClose}>What's making you cancel?</DialogTitle>
        <DialogContent>
          <FormControl>
            <Controller
              rules={{ required: 'No reason is selected' }}
              control={control}
              name="reason"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {reasons.map((reason) => (
                    <FormControlLabel
                      key={reason}
                      value={reason}
                      control={<Radio />}
                      label={reason}
                    />
                  ))}
                  <FormControlLabel
                    key="other"
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {deleteReason === 'Other' && (
            <Box width={1}>
              <ReasonOtherField control={control} clearErrors={clearErrors} />
            </Box>
          )}
          {(submitError || reasonError?.message) && (
            <Typography
              sx={{
                color: '#e71d32',
              }}
            >
              {submitError || reasonError?.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton
            onClick={onClose}
            disabled={isSubmitting}
            color="primary"
          >
            Stay subscribed
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton
            loading={isSubmitting}
            type="submit"
            color="error"
          >
            Continue cancelling
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
