import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type Timer = {
  started?: number;
  lastInterval?: number;
  timeLeft?: number;
  timeToCount?: number;
  requestId?: number;
};

type TimerActions = {
  start: (ttc?: (number | undefined)) => void,
  pause: () => void,
  resume: () => void,
  reset: () => void,
  complete: () => void,
};

const useCountdown = (
  callback: (timeElapsed: number) => void,
  timeToCount = 60 * 1000,
  interval = 1000,
): [number, TimerActions] => {
  const [timeLeft, setTimeLeft] = useState(timeToCount);
  const timer = useRef<Timer>({});
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const run = useCallback((time: number) => {
    if (!timer.current.started) {
      timer.current.started = time;
      timer.current.lastInterval = time;
    }

    const localInterval = Math.min(interval, (timer.current.timeLeft || Infinity));

    if (timer.current.lastInterval && (time - timer.current.lastInterval) >= localInterval) {
      timer.current.lastInterval += localInterval;
      setTimeLeft((prevValue) => {
        timer.current.timeLeft = prevValue - localInterval;
        return timer.current.timeLeft;
      });
    }

    if (timer.current.timeToCount && (time - timer.current.started) < timer.current.timeToCount) {
      timer.current.requestId = window.requestAnimationFrame(run);
    } else {
      callbackRef.current(timeToCount - (timer.current.timeLeft || 0));
      timer.current = {};
      setTimeLeft(0);
    }
  }, [interval, setTimeLeft, timeToCount]);

  const start = useCallback((ttc?: number) => {
    if (timer.current.requestId) {
      window.cancelAnimationFrame(timer.current.requestId);
    }

    const newTimeToCount = ttc !== undefined ? ttc : timeToCount;
    timer.current.started = undefined;
    timer.current.lastInterval = undefined;
    timer.current.timeToCount = newTimeToCount;
    timer.current.timeLeft = newTimeToCount;
    timer.current.requestId = window.requestAnimationFrame(run);

    setTimeLeft(newTimeToCount);
  }, [timeToCount, setTimeLeft, run]);

  const pause = useCallback(() => {
    if (timer.current.requestId) {
      window.cancelAnimationFrame(timer.current.requestId);
    }

    timer.current.started = undefined;
    timer.current.lastInterval = undefined;
    timer.current.timeToCount = timer.current.timeLeft;
  }, []);

  const resume = useCallback(() => {
    if (!timer.current.started && timer.current.timeLeft && timer.current.timeLeft > 0) {
      if (timer.current.requestId) {
        window.cancelAnimationFrame(timer.current.requestId);
      }

      timer.current.requestId = window.requestAnimationFrame(run);
    }
  }, [run]);

  const reset = useCallback(() => {
    if (timer.current.timeLeft && timer.current.requestId) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current = {};
      setTimeLeft(0);
    }
  }, [setTimeLeft]);

  const complete = useCallback(() => {
    if (timer.current.requestId) {
      window.cancelAnimationFrame(timer.current.requestId);
    }

    callbackRef.current(timeToCount - (timer.current.timeLeft || 0));
    timer.current = {};
    setTimeLeft(0);
  }, [setTimeLeft, timeToCount]);

  const actions = useMemo(() => ({
    start,
    pause,
    resume,
    reset,
    complete,
  }), [
    start,
    pause,
    resume,
    reset,
    complete,
  ]);

  useEffect(() => {
    reset();
  }, [reset]);

  return [timeLeft, actions];
};

export default useCountdown;
