const ADD_EMAILS = Symbol('ADD_EMAILS');
const ADD_CONTACTS = Symbol('ADD_CONTACTS');
const UPDATE_INVITED_CONTACTS = Symbol('UPDATE_INVITED_CONTACTS');

const addEmails = (emails) => ({
  type: ADD_EMAILS,
  emails,
});

const addContacts = (contacts) => ({
  type: ADD_CONTACTS,
  contacts,
});

const updateInvitedContacts = (invites) => ({
  type: UPDATE_INVITED_CONTACTS,
  invites,
});

export {
  ADD_EMAILS,
  ADD_CONTACTS,
  UPDATE_INVITED_CONTACTS,
  addEmails,
  addContacts,
  updateInvitedContacts,
};
