import React from 'react';
import { styled } from '@mui/material/styles';
import MuiPaper, { PaperProps } from '@mui/material/Paper';

const StyledPaper = styled(MuiPaper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(3, 0),
  background: theme.background.bgWhite,
  borderRadius: theme.spacing(1.5),
  border: `1px solid ${theme.palette.primary.alpha10}`,
  boxShadow: '0px 2px 15px rgba(35, 43, 57, 0.03)',
}));

export const Paper: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <StyledPaper elevation={0}>{children}</StyledPaper>;
