import { httpService } from './http.service';

const uploadFile = async (credentialsUrl, blob) => {
  const payload = await httpService.get(credentialsUrl);

  await fetch(payload.url, { body: blob, method: 'PUT', header: { 'Content-Type': blob.type } });

  return payload.url.split('?')[0];
};

export default {
  uploadFile,
};
