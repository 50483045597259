import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import * as userReducer from '../../../common/reducers/userReducer';
import userService from '../../../common/services/auth.service';
import useDialog from '../../../common/hooks/useDialog';
import PaymentMethodDto from '../../../common/services/dto/auth/PaymentMethodDto';
import analyticsService from '../../../common/services/analytics.service';
import { CurrentPlan } from '../../../features/profile/features/plan-details/components/CurrentPlan';
import PlanDetails from './PlanDetails';
import PaidPlan from './PaidPlan';
import subscriptionsService from '../../../common/services/subscriptions.service';
import PlanListDto from '../../../common/services/dto/subscriptions/PlanListDto';
import PlanDto from '../../../common/services/dto/subscriptions/PlanDto';
import PromoCodeDialog from '../../../containers/Modals/PromoCodeDialog';
import { PaymentHistory } from '../../../features/profile/features/plan-details/components/PaymentHistory';

const providerNames: {
  [key: string]: string;
} = {
  amex: 'AmEx',
  cartes_bancaires: 'Cartes Bancaires',
  diners_club: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard',
  unionpay: 'Unionpay',
  visa: 'Visa',
  Unknown: 'Default',
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gap: '16px',
  [theme.breakpoints.up(1024)]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
  },
}));

const UserPlanBilling: FC<React.PropsWithChildren<unknown>> = () => {
  const [card, setCard] = useState<PaymentMethodDto | null>(null);
  const [fetchingCardInfo, setFetchingCardInfo] = useState(false);
  const [plans, setPlans] = useState<PlanListDto | {}>({});
  const [isFetchingPlans, setIsFetchingPlans] = useState(true);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const promoCode = query.get('stripePromoCode');

  const {
    open: promoCodeDialogOpen,
    onOpen: onPromoCodeDialogOpen,
    onClose: onPromoCodeDialogClose,
  } = useDialog();

  const user = useSelector(userReducer.userSelector);
  const canStartTrial = useMemo(
    () => user.subscription.plan.isFree && user.canActivateTrial,
    [user]
  );
  const isMobileSubscription = useMemo(
    () =>
      user.subscriptionSource === 'apple' ||
      user.subscriptionSource === 'google',
    [user.subscriptionSource]
  );

  const getPaymentMethod = useCallback(async () => {
    setFetchingCardInfo(true);

    const defaultCard = await userService.getDefaultPaymentMethod();
    setCard(defaultCard);
    setFetchingCardInfo(false);
  }, [setFetchingCardInfo, setCard]);

  useEffect(() => {
    if (user.canSeeBilling) {
      getPaymentMethod();
    }
  }, [user, getPaymentMethod]);

  useEffect(() => {
    if (promoCode && user.subscription.plan.isFree) {
      onPromoCodeDialogOpen();
    }
  }, [promoCode, onPromoCodeDialogOpen, user.subscription.plan.isFree]);

  const deletePaymentMethod = useCallback(async () => {
    if (!card) return;

    await userService.deletePaymentMethod(card.id);
    setCard(null);
  }, [card, setCard]);

  const cardDetails = card
    ? `${providerNames[card.brand]} ************${card.last4} ${
        card.expirationMonth
      }/${card.expirationYear}`
    : '';

  const onPromoClose = useCallback(() => {
    analyticsService.event('Promo Dialog Closed', {
      promoCode: promoCode!,
    });
    onPromoCodeDialogClose();
  }, [onPromoCodeDialogClose]);

  useEffect(() => {
    const fetchPlans = async () => {
      const fetchedPlans = await subscriptionsService.getSubscriptionsList();
      setPlans(fetchedPlans);
      setIsFetchingPlans(false);
    };

    if (!Object.keys(plans).length) {
      fetchPlans();
    }
  }, [plans, setPlans, setIsFetchingPlans]);

  const paidPlans = useMemo(() => {
    if (!('monthly' in plans) && !('yearly' in plans)) return null;
    const monthlyPlan = plans.monthly.filter((plan) => !plan.isFree)[0];
    const yearlyPlan = plans.yearly.filter(
      (plan) => plan.planGroupReference === monthlyPlan.planGroupReference
    )[0];

    return {
      monthly: monthlyPlan,
      yearly: yearlyPlan,
    };
  }, [plans]);

  return (
    <>
      <Stack mb={6} gap={2}>
        <Wrapper>
          <CurrentPlan />
          {!user.subscription.plan.isFree && (
            <PlanDetails
              cardFetching={fetchingCardInfo}
              cardDetails={cardDetails}
              onDeletePaymentMethod={deletePaymentMethod}
              card={card}
              onAddCardSuccess={getPaymentMethod}
            />
          )}
          {user.subscription.plan.isFree && (
            <PaidPlan
              canStartTrial={user.canActivateTrial}
              monthlyPlan={paidPlans?.monthly as PlanDto}
              yearlyPlan={paidPlans?.yearly as PlanDto}
              isFetching={isFetchingPlans}
            />
          )}
        </Wrapper>
        {user.canSeeBilling && <PaymentHistory />}
      </Stack>
      <PromoCodeDialog
        open={promoCodeDialogOpen}
        onClose={onPromoClose}
        onSuccess={onPromoCodeDialogClose}
        promoCode={promoCode || ''}
      />
    </>
  );
};

export default UserPlanBilling;
