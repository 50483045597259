import { styled } from '@mui/material/styles';
import MuiTypography, {
  TypographyTypeMap,
  TypographyProps,
  typographyClasses,
} from '@mui/material/Typography';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const Typography: OverridableComponent<TypographyTypeMap> = styled(
  MuiTypography
)<TypographyProps>({}) as OverridableComponent<TypographyTypeMap>;

export { typographyClasses };
export default Typography;
