import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '../../../ui/atoms/Typography';
import Dialog from '../../../ui/atoms/Dialog';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import useDialog from '../../../common/hooks/useDialog';
import DeleteRecordingDialog from '../DeleteRecordingDialog';
import PlanDialog from '../../../ui/molecules/PlanDialog';
import CloudUploadOutlineIcon from '../../../ui/atoms/Icons/CloudUploadOutlineIcon';
import DownloadArrowIcon from '../../../ui/atoms/Icons/DownloadArrowIcon';
import recordingService from '../../../common/services/recording.service';
import {
  createRecording,
  getGeneralConfiguration,
} from '../../../common/actions/configurationActions';
import analyticsService from '../../../common/services/analytics.service';
import environment from '../../../config/environment';

const StyledVideo = styled('video')({
  width: '100%',
  height: 'auto',
});

type Props = {
  open: boolean;
  blob: Blob;
  thumbnail: Blob | null;
  onClose: () => void;
  sessionId: string;
  groupId: string;
  name: string;
};

const getExtension = (blob: Blob) => {
  switch (blob.type) {
    case 'video/webm':
      return 'webm';
    case 'video/mp4':
    default:
      return 'webm';
  }
};

//Todo: move this declaration to snackbar wrapper
declare module 'notistack' {
  interface VariantOverrides {
    success: {
      link?: string;
      linkText?: string;
    };
  }
}

const ViewRecordingDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  blob,
  thumbnail,
  onClose,
  sessionId,
  groupId,
  name,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const uploadToCloud = useCallback(
    async ({ blob, sessionId, groupId, onLimitCallback, name }: any) => {
      const onProgress = ({
        percentCompleted,
      }: {
        percentCompleted: number;
      }) => {
        setUploadProgress(percentCompleted);
      };

      try {
        setUploadInProgress(true);
        await recordingService.createRecording({
          fileName: `${name}.${getExtension(blob)}`,
          sessionId,
          groupId,
          blob,
          onProgress,
        });

        dispatch(createRecording());

        enqueueSnackbar('Your video has been successfully saved to your', {
          variant: 'success',
          link: `${environment.appUrl}/group/${
            groupId || 'default'
          }/recordings`,
          linkText: 'Workspace',
        });
        setUploadDone(true);
      } catch (e) {
        if (e.code === '130013') {
          analyticsService.event('Create Recording above Limit');
          enqueueSnackbar(e.message, {
            variant: 'error',
          });
          dispatch(getGeneralConfiguration());
          onLimitCallback();
        }
      } finally {
        setUploadInProgress(false);
      }
    },
    [
      dispatch,
      name,
      setUploadProgress,
      setUploadInProgress,
      setUploadDone,
      enqueueSnackbar,
    ]
  );

  const url = useMemo(() => window.URL.createObjectURL(blob), [blob]);
  const thumbnailUrl = useMemo(
    () => window.URL.createObjectURL(thumbnail),
    [thumbnail]
  );

  const {
    open: deleteRecordingDialogOpen,
    onClose: onDeleteRecordingDialogClose,
    onOpen: onDeleteRecordingDialogOpen,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onClose: onPlansDialogClose,
    onOpen: onPlansDialogOpen,
  } = useDialog();

  const onSuccess = useCallback(() => {
    window.URL.revokeObjectURL(url);
    window.URL.revokeObjectURL(thumbnailUrl);
    onClose();
  }, [url, thumbnailUrl, onClose]);

  useEffect(() => {
    if (uploadDone) {
      onSuccess();
    }
  }, [uploadDone, onSuccess]);

  const downloadFileName = useMemo(
    () => `LiveBoard Recording ${format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}`,
    []
  );

  const downloadFile = useCallback(() => {
    analyticsService.event('Board Delete Recording Click');
    const link = document.createElement('a');
    link.download = downloadFileName;
    link.href = url;
    link.click();
  }, [url, downloadFileName]);

  const onSaveClick = useCallback(async () => {
    analyticsService.event('Board Upload Recording Click');

    await uploadToCloud({
      blob,
      sessionId,
      groupId,
      onPlansDialogOpen,
      name,
    });

    onSuccess();
  }, [uploadToCloud, blob, sessionId, groupId, onPlansDialogOpen, onSuccess]);

  return (
    <>
      <Dialog open={open} onClose={onDeleteRecordingDialogOpen} fullWidth>
        <DialogTitle onClose={onDeleteRecordingDialogOpen}>
          Your Recording
        </DialogTitle>
        <DialogContent>
          <Paper
            variant="outlined"
            square
            sx={{
              display: 'flex',
              mb: uploadDone ? '0.35rem' : 0,
            }}
          >
            <StyledVideo src={url} poster={thumbnailUrl} controls />
          </Paper>
          {uploadDone && (
            <Typography variant="body1" gutterBottom>
              Upload completed!
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton
            onClick={downloadFile}
            startIcon={<DownloadArrowIcon />}
          >
            Download
          </DialogActionSecondaryButton>
          {!uploadDone && (
            <DialogActionPrimaryButton
              onClick={onSaveClick}
              disabled={uploadInProgress}
              startIcon={<CloudUploadOutlineIcon />}
            >
              {uploadInProgress ? `Save ${uploadProgress}%` : 'Save to cloud'}
            </DialogActionPrimaryButton>
          )}
        </DialogActions>
      </Dialog>
      <DeleteRecordingDialog
        open={deleteRecordingDialogOpen}
        onClose={onDeleteRecordingDialogClose}
        onDiscard={onSuccess}
      />
      <PlanDialog
        source="Save Recording Limit"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </>
  );
};

export default ViewRecordingDialog;
