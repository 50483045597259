import { FC, MouseEvent } from 'react';
import ToggleButton, {
  toggleButtonClasses,
  ToggleButtonProps,
} from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type Props = {
  color: string;
  selected: boolean;
  onChange: (event: MouseEvent<HTMLElement>, selectedColor: string) => void;
};

const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<ToggleButtonProps & { backgroundColor: string }>(
  ({ theme, backgroundColor, selected }) => ({
    width: 44,
    height: 44,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor,
    ':hover': {
      backgroundColor,
    },
    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor,
      ':hover': {
        backgroundColor,
      },
    },
    ...(selected
      ? {
          border: `2px solid ${theme.background.bg1}`,
          outline: `2px solid ${theme.palette.primary.main}`,
        }
      : {
          borderWidth: backgroundColor === '#FFFFFF' ? 1 : 2,
          borderColor:
            backgroundColor === '#FFFFFF' ? '#D9D9D9' : 'transparent',
        }),
  })
);

const BackgroundColorButton: FC<React.PropsWithChildren<Props>> = ({
  color,
  selected,
  onChange,
}) => (
  <Box padding={0.25}>
    <StyledToggleButton
      value={color}
      backgroundColor={color}
      selected={selected}
      onChange={onChange}
      disableRipple
    />
  </Box>
);

export default BackgroundColorButton;
