import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

//Todo: remove any type
const DrawerToolbar = styled(Box)<any>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
}));

export default DrawerToolbar;
