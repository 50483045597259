import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import compose from '../../common/utils/compose.utils';

const enhancer = compose(
  withRouter,
  connect((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }))
);

const AuthenticatedRoute = (props) => {
  if (!props.isAuthenticated) {
    const redirectTo = {
      pathname: '/sign-up',
      state: { referrer: props.location.pathname + props.location.search },
    };

    return <Redirect push to={redirectTo} />;
  }

  return <Route {...props} />;
};

export default enhancer(AuthenticatedRoute);
