import {
  FC,
  MouseEvent,
  ReactEventHandler,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ToolOptionsLayout from '../ToolOptionsLayout/ToolOptionsLayout';
import ListItem from '../../../ui/atoms/ListItem';
import ToolNames from '../ToolNames';
import ImageAddIcon from '../../../ui/atoms/Icons/ImageAddIcon';
import UploadPdfIcon from '../../../ui/atoms/Icons/UploadPdfIcon';
import { imageTourSelector } from '../../../common/reducers/featureToursReducer';
import UnleashFlags from '../../../common/constants/UnleashFlag';
import analyticsService from '../../../common/services/analytics.service';
import * as planPermissionSelectors from '../../../common/selectors/planPermissionSelectors';
import {
  isCurrentUserHostSelector,
  sessionIdSelector,
} from '../../../common/reducers/session/sessionReducer';
import DrawingButton from '../DrawingButton/DrawingButton';
import ImportPdfTool from './ImportPdfTool';
import ImageTool from './ImageTool';
import { SelectedTool } from './types/SelectToolType';
import UpgradeDialog from '../../../ui/molecules/UpgradeDialog';
import PlanDialog from '../../../ui/molecules/PlanDialog';
import useDialog from '../../../common/hooks/useDialog';
import UploadFileIcon from '../../../ui/atoms/Icons/UploadFileIcon';
import { getCurrentDrawing } from '../../../common/reducers/board/currentDrawingReducer';

type UploadFileToolProps = {
  imageReference: RefObject<HTMLInputElement>;
  selectedTool: SelectedTool;
  selectTool: (name: string) => void;
  updateTool: (name: string, props: { [key: string]: any }) => void;
  onboardingChange: () => void;
};

type SettingsFactoryProps = {
  onSelectImageTool: () => void;
  currentDrawing: any;
  selectedTool: SelectedTool;
  hasPdfImportPermission: boolean;
  isHost: boolean;
  onPdfImportClick: ((event: MouseEvent<HTMLElement>) => void) &
    ReactEventHandler<HTMLLIElement>;
};

const settingsFactory: FC<React.PropsWithChildren<SettingsFactoryProps>> = ({
  onSelectImageTool,
  currentDrawing,
  selectedTool,
  hasPdfImportPermission,
  isHost,
  onPdfImportClick,
}) => (
  <ToolOptionsLayout>
    <Box>
      <ListItem
        title="Image"
        onSelect={onSelectImageTool}
        startIcon={<ImageAddIcon />}
        selected={!currentDrawing && selectedTool.name === ToolNames.Image}
      />
      {hasPdfImportPermission && isHost && (
        <ListItem
          title="Import PDF"
          onSelect={onPdfImportClick}
          startIcon={<UploadPdfIcon />}
          selected={
            !currentDrawing && selectedTool.name === ToolNames.ImportPdf
          }
        />
      )}
    </Box>
  </ToolOptionsLayout>
);

const UploadFileTool: FC<React.PropsWithChildren<UploadFileToolProps>> = ({
  imageReference,
  selectedTool,
  selectTool,
  updateTool,
  onboardingChange,
}) => {
  const imageTourData = useSelector(imageTourSelector);
  const sessionId = useSelector(sessionIdSelector);
  const isHost = useSelector(isCurrentUserHostSelector);
  const currentDrawing = useSelector(getCurrentDrawing);

  const imageRotationEnabled = useFlag(UnleashFlags.IMAGE_ROTATION);
  const node = useRef<HTMLDivElement | null>(null);

  const pdfUploadRef = useRef<HTMLInputElement | null>(null);

  const [isVisible, setIsVisible] = useState(false);
  const hasPdfImportPermission = useSelector(
    planPermissionSelectors.hasPdfImportPermissionSelector
  );

  const {
    open: upgradeDialogOpen,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
  }, [onPlansDialogOpen, onUpgradeDialogClose]);

  const activeTool = useMemo(() => {
    if (!selectedTool?.name) return '';

    return selectedTool.name;
  }, [selectedTool]);

  const onSelectImageTool = useCallback(() => {
    if (
      activeTool !== ToolNames.Image &&
      imageReference.current &&
      (!imageRotationEnabled || !imageTourData || !imageTourData.hasUpdates)
    ) {
      analyticsService.event('Tool Selected', {
        tool: 'image',
        source: 'files',
      });
      imageReference.current.click();
      setIsVisible(false);

      return;
    }

    onboardingChange();
  }, [
    imageRotationEnabled,
    imageTourData,
    selectedTool,
    selectTool,
    updateTool,
    activeTool,
    setIsVisible,
  ]);

  const onPdfImportClick = useCallback(
    (event: any) => {
      analyticsService.event('PDF Import Tool Click', {
        sessionId,
      });
      analyticsService.event('Tool Selected', {
        tool: 'importPDF',
      });

      if (!hasPdfImportPermission) {
        event.preventDefault();
        onUpgradeDialogOpen();
        return;
      }

      if (!pdfUploadRef.current) return;

      pdfUploadRef.current.click();
      setIsVisible(false);
    },
    [
      hasPdfImportPermission,
      onUpgradeDialogOpen,
      sessionId,
      activeTool,
      setIsVisible,
    ]
  );

  const settings = useMemo(
    () =>
      settingsFactory({
        onSelectImageTool,
        currentDrawing,
        selectedTool,
        hasPdfImportPermission,
        isHost,
        onPdfImportClick,
      }),
    [
      onSelectImageTool,
      currentDrawing,
      isHost,
      hasPdfImportPermission,
      onPdfImportClick,
      selectedTool,
    ]
  );

  const isUploadButtonActive = useMemo(
    () =>
      !currentDrawing &&
      (activeTool === ToolNames.ImportPdf || activeTool === ToolNames.Image),
    [currentDrawing, activeTool]
  );

  const handleOutsideClick = (event: any) => {
    if (node.current && node.current.contains(event.target)) return;

    setIsVisible(false);
  };

  const onUploadButtonClick = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, [setIsVisible]);

  return (
    <Box
      ref={node}
      sx={{
        position: 'relative',
      }}
    >
      <DrawingButton
        icon={<UploadFileIcon />}
        onClick={onUploadButtonClick}
        tooltipTitle="Upload Files"
        tooltipPlacement="bottom"
        showOptionsIcon={true}
        active={isUploadButtonActive}
      />
      {settings && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleOutsideClick}
        >
          <Box
            sx={{
              display: isVisible ? 'flex' : 'none',
              position: 'relative',
              top: 8,
            }}
          >
            {settings}
          </Box>
        </ClickAwayListener>
      )}
      <ImageTool
        reference={imageReference}
        selectedTool={selectedTool}
        updateTool={updateTool}
        selectTool={selectTool}
        onboardingChange={onboardingChange}
      />
      <ImportPdfTool ref={pdfUploadRef} selectedTool={selectedTool} />
      <UpgradeDialog
        open={upgradeDialogOpen}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source="Import PDF"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </Box>
  );
};

export default UploadFileTool;
