import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useDialog from '../../../common/hooks/useDialog';
import ImportPdfToBoardDialog from './ImportPdfToBoardDialog';
import CreateBoardFromPdfDialog from '../../../ui/molecules/CreateBoardFromPdfDialog';
import SelectPdfPagesDialog from '../../../ui/molecules/SelectPdfPagesDialog';
import { connectedSelector } from '../../../common/reducers/session/sessionReducer';

const PdfImport = ({ pdfFile, onSuccess, onCancel, type = 'create' }) => {
  const [selectedPages, setSelectedPages] = useState([]);
  const selectPdfPagesModal = useDialog();
  const pdfImportModal = useDialog();
  const isConnectedToSession = useSelector(connectedSelector);

  const selectPagesModalSuccess = useCallback(() => {
    selectPdfPagesModal.onClose();
    pdfImportModal.onOpen();
  }, [pdfImportModal.onOpen, selectPdfPagesModal.onClose]);

  const selectPagesModalClose = useCallback(() => {
    selectPdfPagesModal.onClose();
    onCancel();
  }, [selectPdfPagesModal.onClose, onCancel]);

  useEffect(() => {
    if (pdfFile) {
      selectPdfPagesModal.onOpen();
    } else {
      setSelectedPages([]);
      selectPdfPagesModal.onClose();
      pdfImportModal.onClose();
    }
  }, [pdfFile]);

  return (
    <>
      {isConnectedToSession ? (
        <SelectPdfPagesDialog
          open={selectPdfPagesModal.open}
          handleClose={selectPagesModalClose}
          handleSuccess={selectPagesModalSuccess}
          setSelectedPages={setSelectedPages}
          pdfFile={pdfFile}
        />
      ) : (
        <SelectPdfPagesDialog
          open={selectPdfPagesModal.open}
          handleClose={selectPagesModalClose}
          handleSuccess={selectPagesModalSuccess}
          setSelectedPages={setSelectedPages}
          pdfFile={pdfFile}
        />
      )}
      {type === 'create' && (
        <CreateBoardFromPdfDialog
          open={pdfImportModal.open}
          handleClose={pdfImportModal.onClose}
          handleSuccess={onSuccess}
          pages={selectedPages}
          name={pdfFile && pdfFile.name}
        />
      )}
      {type === 'append' && (
        <ImportPdfToBoardDialog
          open={pdfImportModal.open}
          onClose={pdfImportModal.onClose}
          handleSuccess={onSuccess}
          pages={selectedPages}
        />
      )}
    </>
  );
};

export default PdfImport;
