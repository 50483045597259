import dataUriToBlob from 'data-uri-to-blob';

const imageToBlob = image => new Promise((resolve) => {
  const blob = dataUriToBlob(image.src);

  resolve(blob);
});

const getExtensionFromBlob = blob => {
  const mimeType = blob.type;
  return mimeType.substring(mimeType.indexOf('/') + 1);
};

export default { imageToBlob, dataUriToBlob, getExtensionFromBlob };
