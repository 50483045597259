import { FC, ReactElement } from 'react';
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '../../atoms/Typography';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import IconButton from '../../atoms/IconButton';
import { TypographyType } from '../../atoms/Typography/types/Typography';

type Props = {
  icon?: ReactElement;
  onClose?: () => void;
  disabled?: boolean;
  hasCloseButton?: boolean;
  error?: string;
};

const DialogTitle: FC<React.PropsWithChildren<Props & DialogTitleProps>> = ({
  icon,
  onClose,
  children,
  disabled,
  hasCloseButton = true,
  error,
  ...props
}) => (
  <MuiDialogTitle {...props}>
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" flexGrow={1}>
          {icon && (
            <Box mr={1.125} display="flex">
              {icon}
            </Box>
          )}
          <Typography
            variant={TypographyType.s2}
            sx={(theme) => ({
              color: theme.text.t10,
            })}
          >
            {children}
          </Typography>
        </Box>
        {hasCloseButton && (
          <Box>
            <IconButton
              size={ButtonSizeTypes.XS}
              variant={ButtonVariantTypes.TEXT}
              color={ButtonColorTypes.PRIMARY}
              onClick={onClose}
              disabled={disabled}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {error && (
        <Typography variant={TypographyType.p5} color="error.main">
          {error}
        </Typography>
      )}
    </Box>
  </MuiDialogTitle>
);

export default DialogTitle;
