import {
  DisplayObject,
  Graphics,
  LINE_CAP,
  LINE_JOIN,
  Rectangle,
} from 'pixi.js';
import Shape from './shape';
import OldPathShapeAction from '../../../common/drawingActions/shapes/pathShape.drawing.action';
import PathCommand from '../types/PathCommand';
import ActionName from '../types/ActionName';

class PathShape extends Shape {
  viewBoxWidth: number;

  viewBoxHeight: number;

  pathData: string;

  drawingData: {
    command: string;
    points: number[];
  }[] = [];

  constructor(action?: OldPathShapeAction) {
    super(action);

    this.name = ActionName.PATH_SHAPE;

    if (action) {
      this.viewBoxWidth = action.viewBoxWidth;
      this.viewBoxHeight = action.viewBoxHeight;
      this.pathData = action.pathData;
      this.drawingData = action.drawingData;
    }
  }

  toDto(): OldPathShapeAction {
    const dto = new OldPathShapeAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldPathShapeAction) {
    super.setFields(action);
    action.name = ActionName.PATH_SHAPE;
    action.viewBoxWidth = this.viewBoxWidth;
    action.viewBoxHeight = this.viewBoxHeight;
    action.pathData = this.pathData;
    action.drawingData = this.drawingData;
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    graphicsObject.clear();
    graphicsObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    graphicsObject.lineStyle({
      width: size,
      color,
      cap: LINE_CAP.ROUND,
      join: LINE_JOIN.ROUND,
    });

    const scaleX = width / (this.viewBoxWidth || 64);
    const scaleY = height / (this.viewBoxHeight || 64);

    graphicsObject.moveTo(-width / 2, -height / 2);

    for (let n = 0; n < this.drawingData.length; n += 1) {
      const c = this.drawingData[n].command;
      const p = this.drawingData[n].points;

      switch (c) {
        case PathCommand.Line:
          graphicsObject.lineTo(
            p[0] * scaleX - width / 2,
            p[1] * scaleY - height / 2
          );
          break;
        case PathCommand.Move:
          graphicsObject.moveTo(
            p[0] * scaleX - width / 2,
            p[1] * scaleY - height / 2
          );
          break;
        case PathCommand.Curve:
          graphicsObject.bezierCurveTo(
            p[0] * scaleX - width / 2,
            p[1] * scaleY - height / 2,
            p[2] * scaleX - width / 2,
            p[3] * scaleY - height / 2,
            p[4] * scaleX - width / 2,
            p[5] * scaleY - height / 2
          );
          break;
        case PathCommand.Bezier:
          graphicsObject.quadraticCurveTo(
            p[0] * scaleX - width / 2,
            p[1] * scaleY - height / 2,
            p[2] * scaleX - width / 2,
            p[3] * scaleY - height / 2
          );
          break;
        case PathCommand.Arc:
          const cx = p[0] * scaleX - width / 2;
          const cy = p[1] * scaleY - height / 2;
          const rx = p[2] * scaleX;
          const ry = p[3] * scaleY;
          const theta = p[4];
          const dTheta = p[5];
          // const psi = p[6];
          const fs = p[7];

          const r = Math.max(rx, ry);
          const scaleX2 = rx > ry ? 1 : rx / ry;
          const scaleY2 = rx > ry ? ry / rx : 1;

          graphicsObject.arc(
            cx * scaleX2,
            cy * scaleY2,
            r,
            theta,
            theta + dTheta,
            Boolean(1 - fs)
          );

          break;
        case PathCommand.Close:
          graphicsObject.closePath();
          break;
        default:
          break;
      }
    }

    const hitAreaWidth = Math.abs(width);
    const hitAreaHeight = Math.abs(height);

    graphicsObject.hitArea = new Rectangle(
      -(hitAreaWidth + size) / 2,
      -(hitAreaHeight + size) / 2,
      hitAreaWidth,
      hitAreaHeight
    );
  }
}

export default PathShape;
