import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import Input from '../../atoms/Input';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import sessionService from '../../../common/services/session.service';
import { updateSessionInList } from '../../../common/actions/sessionsActions';
import InputStatus from '../../atoms/Input/InputStatus';

type Props = {
  open: boolean;
  onClose: () => void;
  card: any;
};

const RenameBoardDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  card,
}) => {
  const [error, setError] = useState('');

  const { control, setValue, handleSubmit, clearErrors } = useForm({
    defaultValues: {
      boardName: '',
    },
    reValidateMode: 'onSubmit',
  });

  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const renameSession = useCallback(
    handleSubmit(async () => {
      if (!name) return;
      try {
        const session = await sessionService.renameSession(card.id, name);
        dispatch(updateSessionInList(session));
        onClose();
      } catch (e) {
        setError('Failed to find the board.');
      }
    }),
    [card, name, onClose, dispatch, setError]
  );

  const onEnter = useCallback(
    (cardName: any) => {
      setError('');
      setValue('boardName', cardName);
      setName(cardName);
      clearErrors('boardName');
    },
    [clearErrors, setName, setValue, setError]
  );

  const handleChange = useCallback(
    (value: any) => {
      setName(value);
    },
    [setName]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEntering: () => onEnter(card.name),
      }}
    >
      <DialogTitle onClose={onClose} error={error}>
        Rename board
      </DialogTitle>
      <form onSubmit={renameSession}>
        <DialogContent>
          <Controller
            name="boardName"
            control={control}
            rules={{
              pattern: {
                value: /^((?![/<>:"\\|?*]).)*$/g,
                message:
                  'Name can\'t contain any of the following characters:[/<>:"|?*]',
              },
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Input
                onChange={(boardName) => {
                  onChange(boardName);
                  handleChange(boardName);
                }}
                value={value}
                size={InputSize.M}
                variant={InputVariant.CONTAINED}
                placeholder="Type the board name"
                status={errors.boardName ? InputStatus.ERROR : undefined}
                helperText={
                  errors.boardName ? errors.boardName.message : undefined
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton onClick={onClose}>
            Cancel
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton
            type="submit"
            disabled={(card && name === card.name) || name === ''}
          >
            Rename
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenameBoardDialog;
