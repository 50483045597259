import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Notification from '../Notification';
import { pagesCountLimitSelector } from '../../../common/reducers/session/sessionReducer';
import InformationIcon from '../../../ui/atoms/Icons/InformationIcon';
import { AlertColor } from '../../../ui/molecules/Notification/notificationTypes';

export enum SectionName {
  PAGE_SIDEBAR = 'page-sidebar',
  SELECT_PDF_DIALOG = 'select-pdf-dialog',
}

type PagesLimitNotificationProps = {
  title?: string;
  message?: string;
  sectionName: SectionName;
};

const PagesLimitNotification: FC<
  React.PropsWithChildren<PagesLimitNotificationProps>
> = ({ title, message, sectionName }) => {
  const pagesCountLimit = useSelector(pagesCountLimitSelector);

  return (
    <Box width={1}>
      <Notification
        icon={<InformationIcon />}
        borderRadius={sectionName === SectionName.SELECT_PDF_DIALOG ? 16 : 0}
        fontSize="1rem"
        title={title || `You have reached ${pagesCountLimit}-page limit.`}
        message={message}
        color={AlertColor.ERROR}
      />
    </Box>
  );
};

export default PagesLimitNotification;
