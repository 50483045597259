import { toColor } from '../../../../common/utils/color.utils';

const textDrawer = (ctx, item) => {
  const {
    startPoint,
    text,
    paint,
  } = item;
  const {
    color,
    textSize,
  } = paint;
  const rgbaColor = toColor(color);
  ctx.save();
  const fontSize = +textSize;
  ctx.font = `${fontSize}px Roboto`;
  ctx.fillStyle = rgbaColor;

  text.split('\n').forEach((t, i) => {
    const y = (i + 1) * fontSize;
    ctx.fillText(t, startPoint.x, startPoint.y + y);
  });
  ctx.restore();
};

export default textDrawer;
