import React, { useContext, useEffect, useRef, useState } from 'react';
import useRaf from '@rooks/use-raf';
import { useSelector } from 'react-redux';
import { cursorActionsSelector } from '../../../common/reducers/board/cursorActionsReducer';
import drawers from './drawers/index';
import CanvasContext from '../Contexts/CanvasContext';
import { getCursorImage } from '../../../common/utils/cursor.utils';
import { participantsSelector } from '../../../common/reducers/session/participantsReducer';
import CursorAction from '../../../common/types/cursorAction';
import ParticipantDto from '../../../common/services/dto/firebase/ParticipantDto';

const CURSOR_ACTION_NAME = 'cursor';

const CursorLayer = ({ width, height }: any) => {
  const [cursorImages, setCursorImages] = useState<
    Record<string, HTMLImageElement>
  >({});

  const cursorActions: CursorAction[] = useSelector(
    cursorActionsSelector
  ) as CursorAction[];
  const sessionParticipants: ParticipantDto[] =
    useSelector(participantsSelector);

  const { zoom, scale, originX, originY } = useContext(CanvasContext);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const getImages = async () => {
      let cursors = { ...cursorImages };

      for (const participant of sessionParticipants) {
        if (!cursors[participant.id]) {
          cursors = {
            ...cursors,
            [participant.id]: (await getCursorImage(
              participant.cursorColor
            )) as HTMLImageElement,
          };
        }
      }

      setCursorImages(cursors);
    };

    getImages();
  }, [sessionParticipants]);

  useRaf(() => {
    if (canvasRef && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      if (!ctx) return;

      ctx.clearRect(0, 0, width, height);
      ctx.scale(zoom, zoom);
      ctx.translate(-originX, -originY);
      ctx.scale(scale, scale);

      const drawer = drawers[CURSOR_ACTION_NAME];

      if (!drawer) return;

      cursorActions.forEach((action) => {
        action.cursorImage = cursorImages[action.participant.id];
        drawer(ctx, action, scale * zoom);
      });

      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }
  }, true);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default CursorLayer;
