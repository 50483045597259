const SET_MESSAGES_SIDE_BAR_VISIBILITY = Symbol(
  'SET_MESSAGES_SIDE_BAR_VISIBILITY'
);
const SET_BOARD_SIZES = Symbol('SET_BOARD_SIZES');
const SET_DPI = Symbol('SET_DPI');
const SET_COLLABORATION_BAR_SIZE = Symbol('SET_COLLABORATION_BAR_SIZE');

const setMessagesSideBarVisibility = (opened) => ({
  type: SET_MESSAGES_SIDE_BAR_VISIBILITY,
  opened,
});

const setCollaborationBarSize = ({ width, height = 0 }) => ({
  type: SET_COLLABORATION_BAR_SIZE,
  width,
  height,
});

const setBoardSizes = (width, height) => ({
  type: SET_BOARD_SIZES,
  width,
  height,
});

const setDpi = (dpi) => ({
  type: SET_DPI,
  dpi,
});

export {
  setMessagesSideBarVisibility,
  setBoardSizes,
  setDpi,
  setCollaborationBarSize,
  SET_MESSAGES_SIDE_BAR_VISIBILITY,
  SET_BOARD_SIZES,
  SET_DPI,
  SET_COLLABORATION_BAR_SIZE,
};
