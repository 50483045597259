import PromoCodeDto from '../services/dto/auth/PromoCodeDto';

const calculateDiscountedPrice = (price: number, promoData: PromoCodeDto | null) => {
  if (!promoData?.coupon) return 0;
  if (promoData.coupon?.subtotal) return promoData.coupon?.subtotal;
  if (!promoData.coupon?.percentOff) return 0;

  return price * (1 - promoData.coupon.percentOff / 100);
};

export default calculateDiscountedPrice;
