// ToDo: divide into 2 services - Auth and Account
import storage, {
  liveBoardStorage,
  sessionStorage,
} from '../../config/storage';
import { AxiosRequestConfig } from 'axios';
import { httpService, httpServiceV4 } from './http.service';
import fileService from './file.service';
import UpdatePasswordDto from './dto/auth/UpdatePasswordDto';
import SetupIntentTokenDto from './dto/auth/SetupIntentTokenDto';
import BillingInformationDto from './dto/auth/BillingInformationDto';
import PersonalInformationQuestionDto from './dto/auth/PersonalInformationQuestionDto';
import PersonalInformationAnswerDto from './dto/auth/PersonalInformationAnswerDto';
import PromoCodeDto from './dto/auth/PromoCodeDto';
import PaymentMethodDto from './dto/auth/PaymentMethodDto';
import UserDto from './dto/auth/UserDto';
import GeneralConfigurationDto from './dto/auth/GeneralConfigurationDto';
import SuccessPayloadWithMessage from './types/auth/SuccessPayloadWithMessage';
import AuthResponsePayload from './types/auth/AuthResponsePayload';
import UserByCodeDto from './dto/session/UserByCodeDto';
import SessionDto from './dto/session/SessionDto';
import PublicLinkDto from './dto/session/PublicLinkDto';
import { PaymentHistoryDto } from './dto/subscriptions/PaymentHistoryDto';

const signIn = (email: string, password: string) =>
  httpService.post<AuthResponsePayload, AuthResponsePayload>('/auth/sign-in', {
    email,
    password,
  });

const signUp = (
  email: string,
  name: string,
  lastName: string,
  password: string,
  role: string,
  referralId: string
) =>
  httpService.post<AuthResponsePayload, AuthResponsePayload>(
    '/auth/sign-up/web',
    {
      email,
      password,
      name,
      lastName,
      role,
      termsAndPrivacyAccepted: true,
      referralType: referralId ? 'rewardful' : undefined,
      referralId,
    }
  );

const forgotPassword = (email: string) =>
  httpService.post<SuccessPayloadWithMessage, SuccessPayloadWithMessage>(
    '/auth/password/forgot',
    { email }
  );

const resetPassword = (
  password: string,
  repeatedPassword: string,
  token: string
) =>
  httpService.post<SuccessPayloadWithMessage, SuccessPayloadWithMessage>(
    `/auth/password/reset/${token}`,
    {
      password,
      repeatedPassword,
    }
  );

const signInWithGoogle = (
  code: string,
  role: string,
  referralType: string,
  referralId: string
) =>
  httpService.post<AuthResponsePayload, AuthResponsePayload>(
    '/auth/google/web',
    {
      code,
      role,
      referralType,
      referralId,
    }
  );

const signOut = async () => {
  storage.clear();
  liveBoardStorage.clear();
  sessionStorage.clear();

  await httpService.post('/auth/sign-out');
};

const updateName = (name: string) => httpService.put('/me/name', { name });

const getUser = (): Promise<UserDto> =>
  httpService.get<UserDto, UserDto>('/me');

const updateProfilePictureUrl = (url: string) =>
  httpService.put('/me/profilePicture', { url });

const editProfilePicture = async (file: File) => {
  const credentialsUrl = `/me/profilePicture/credentials?filename=${file.name}`;

  const url = await fileService.uploadFile(credentialsUrl, file);

  return updateProfilePictureUrl(url);
};

const startTrial = (planId: string, requireVerification: boolean = true) =>
  httpService.post<UserDto, UserDto>('/me/trial', {
    planId,
    requireVerification,
  });

const updatePassword = ({ password, repeatedPassword }: UpdatePasswordDto) =>
  httpService.put('/me/password', {
    password,
    repeatedPassword,
  });

const resendVerificationEmail = () =>
  httpService.post('/me/email/verification/resend');

const startSubscription = (
  planId: string,
  promoCodeId: string | undefined = undefined
) =>
  httpService.post<UserDto, UserDto>('/me/subscription', {
    planId,
    promoCodeId,
  });

const stopSubscription = (reason: string) =>
  httpService.post('/me/subscription/stop', { params: { reason } });

const deleteAccount = (reason: string) =>
  httpService.post('/me/delete', { reason, confirmation: true });

const getDeleteAccountReasons = () => httpService.get('/me/delete/reason');

const existPublicLink = (code: string) =>
  httpService.get<PublicLinkDto, PublicLinkDto>(`/auth/publicLink/${code}`);

const authWithPublicLink = (code: string, name: string) =>
  httpService.post<UserByCodeDto, UserByCodeDto>(`/auth/publicLink/${code}`, {
    name,
  });

const getPersonalInformationQuestions = (options: AxiosRequestConfig) =>
  httpServiceV4.get<
    PersonalInformationQuestionDto[],
    PersonalInformationQuestionDto[]
  >('/me/personal-information/questions', options);

const setPersonalInformation = (answers: PersonalInformationAnswerDto[]) =>
  httpServiceV4.post('/me/personal-information/answers', { answers });

const updateTermsAndPrivacyAccept = () =>
  httpService.post('/me/terms-and-privacy-consent/update');

const getDefaultPaymentMethod = (): Promise<PaymentMethodDto | null> =>
  httpService.get<PaymentMethodDto, PaymentMethodDto>(
    '/payment/payment-method/default'
  );

const setDefaultPaymentMethod = (paymentMethodId: string) =>
  httpService.put(`/payment/payment-method/${paymentMethodId}`);

const getSetupIntentToken = (options: AxiosRequestConfig) =>
  httpService.get<SetupIntentTokenDto, SetupIntentTokenDto>(
    '/payment/payment-method/token',
    options
  );

const deletePaymentMethod = (id: string) =>
  httpService.delete(`/payment/payment-method/${id}`);

const getPaymentHistory = () =>
  httpService.get<PaymentHistoryDto[], PaymentHistoryDto[]>('/payment/history');

const getBillingInfo = () => httpService.get('/payment/billing');

const updateBillingInfo = (data: BillingInformationDto) =>
  httpService.put('/payment/billing', data);

const validatePromoCode = (promoCode: string, planId: string) =>
  httpServiceV4.post<PromoCodeDto, PromoCodeDto>(
    '/payment/validate-promo-code',
    { promoCode, planId }
  );

const getSubscriptionPromoCode = (options: AxiosRequestConfig) =>
  httpServiceV4.get<PromoCodeDto, PromoCodeDto>(
    '/me/subscription/promo-code',
    options
  );

const addSubscriptionPromoCode = (promoCodeId: string): Promise<PromoCodeDto> =>
  httpServiceV4.post<PromoCodeDto, PromoCodeDto>(
    '/me/subscription/promo-code',
    { promoCodeId }
  );

const getGeneralConfiguration = () =>
  httpServiceV4.get<GeneralConfigurationDto, GeneralConfigurationDto>(
    '/configuration/feature'
  );

const existSession = (code: string) =>
  httpServiceV4.get<SessionDto, SessionDto>(`/auth/sessionCode/${code}`);

const createUserByCode = (code: string, name: string) =>
  httpServiceV4.post<UserByCodeDto, UserByCodeDto>(
    `/auth/sessionCode/${code}`,
    {
      name,
    }
  );

export default {
  signIn,
  signUp,
  signInWithGoogle,
  signOut,
  getUser,
  updateName,
  forgotPassword,
  resetPassword,
  editProfilePicture,
  startTrial,
  updatePassword,
  resendVerificationEmail,
  startSubscription,
  stopSubscription,
  deleteAccount,
  getDeleteAccountReasons,
  existPublicLink,
  existSession,
  authWithPublicLink,
  getPersonalInformationQuestions,
  setPersonalInformation,
  updateTermsAndPrivacyAccept,
  getDefaultPaymentMethod,
  setDefaultPaymentMethod,
  getSetupIntentToken,
  deletePaymentMethod,
  getPaymentHistory,
  getBillingInfo,
  updateBillingInfo,
  validatePromoCode,
  getSubscriptionPromoCode,
  addSubscriptionPromoCode,
  getGeneralConfiguration,
  createUserByCode,
};
