import ShapeControl from '../ShapeControl';
import vectorUtils from '../../../../../../common/utils/vector.utils';
import { text, main } from '../../../../../../ui/atoms/Colors/colorStyles';

export default class Resizer extends ShapeControl {
  constructor(
    startPoint,
    endPoint,
    scale,
    padding,
    rotationAngle = 0,
    radius = 5
  ) {
    super(startPoint, endPoint, scale, padding, rotationAngle);

    this.radius = radius;
  }

  draw(ctx, selected) {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = selected ? main.primary.main : text.t9;
    ctx.fillStyle = '#fff';
    ctx.lineWidth = 2 / this.scale;
    const { x, y } = this.center();
    if (this.rotationAngle) {
      ctx.translate(x, y);
      ctx.rotate(this.rotationAngle);
      ctx.translate(-x, -y);
    }
    ctx.arc(x, y, this.radius / this.scale, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.stroke();
    ctx.restore();
  }

  hitTest(x, y) {
    const center = this.center();
    const a = (x - center.x) ** 2;
    const b = (y - center.y) ** 2;
    return Math.sqrt(a + b) <= (6 * 4) / this.scale;
  }

  validateImageFlipping(
    rotatedTransformationPoint,
    symmetricPoint,
    projectedPoint
  ) {
    const imageFlippingConditionX =
      rotatedTransformationPoint.x > symmetricPoint.x &&
      projectedPoint.x < symmetricPoint.x;
    const imageInverseFlippingConditionX =
      rotatedTransformationPoint.x < symmetricPoint.x &&
      projectedPoint.x > symmetricPoint.x;
    const imageFlippingConditionY =
      rotatedTransformationPoint.y > symmetricPoint.y &&
      projectedPoint.y < symmetricPoint.y;
    const imageInverseFlippingConditionY =
      rotatedTransformationPoint.y < symmetricPoint.y &&
      projectedPoint.y > symmetricPoint.y;

    return !(
      imageFlippingConditionX ||
      (imageInverseFlippingConditionX && imageFlippingConditionY) ||
      imageInverseFlippingConditionY
    );
  }

  getResizedPoints(transformationPoint, deltaX, deltaY) {
    const midPoint = vectorUtils.calculateMidPoint(
      this.startPoint,
      this.endPoint
    );

    const rotatedTransformationPoint = vectorUtils.calculateRotatedPoint(
      transformationPoint,
      midPoint,
      this.rotationAngle
    );

    const symmetricPoint = {
      x: 2 * midPoint.x - rotatedTransformationPoint.x,
      y: 2 * midPoint.y - rotatedTransformationPoint.y,
    };

    const pointToProject = {
      x: rotatedTransformationPoint.x + deltaX,
      y: rotatedTransformationPoint.y + deltaY,
    };
    const projectedPoint = vectorUtils.calculateProjectedPoint(
      symmetricPoint,
      rotatedTransformationPoint,
      pointToProject
    );

    const validImageFlipping = this.validateImageFlipping(
      rotatedTransformationPoint,
      symmetricPoint,
      projectedPoint
    );

    if (!validImageFlipping) return null;

    const newMidPoint = vectorUtils.calculateMidPoint(
      projectedPoint,
      symmetricPoint
    );

    const rotatedProjectedPoint = vectorUtils.calculateRotatedPoint(
      projectedPoint,
      newMidPoint,
      -this.rotationAngle
    );
    const rotatedSymmetricPoint = vectorUtils.calculateRotatedPoint(
      symmetricPoint,
      newMidPoint,
      -this.rotationAngle
    );
    const signX = Math.sign(rotatedSymmetricPoint.x - rotatedProjectedPoint.x);
    const signY = Math.sign(rotatedSymmetricPoint.y - rotatedProjectedPoint.y);

    if (
      signX * (rotatedSymmetricPoint.x - rotatedProjectedPoint.x) < 10 ||
      signY * (rotatedSymmetricPoint.y - rotatedProjectedPoint.y) < 10
    )
      return null;

    return {
      startPoint: {
        x: Math.min(rotatedProjectedPoint.x, rotatedSymmetricPoint.x),
        y: Math.min(rotatedProjectedPoint.y, rotatedSymmetricPoint.y),
      },
      endPoint: {
        x: Math.max(rotatedProjectedPoint.x, rotatedSymmetricPoint.x),
        y: Math.max(rotatedProjectedPoint.y, rotatedSymmetricPoint.y),
      },
    };
  }

  center() {
    throw new Error('Not implemented');
  }

  onResize() {
    throw new Error('Not implemented');
  }
}
