import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BasePlacement } from '@popperjs/core/lib/enums';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ToolSizeControl from '../../../../../DrawingTools/ToolSizeControl/ToolSizeControl';
import * as drawingToolActions from '../../../../../../common/actions/board/drawingToolActions';
import { setShapeCurrentDrawing } from '../../../../../../common/actions/board/currentDrawingAction';
import {
  getActiveTools,
  getSelectedDrawingTool,
} from '../../../../../../common/reducers/board/drawingToolReducer';
import { getCurrentDrawing } from '../../../../../../common/reducers/board/currentDrawingReducer';
import useBoardRatio from '../../../../../../common/hooks/useBoardRatio';

type ShapeSizePopperProps = {
  open: boolean;
  anchorEl: {
    getBoundingClientRect: () => DOMRect;
  };
  shapeToolbarPlacement: BasePlacement | null;
  onChange: () => void;
};

const popperPadding = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const shapeToolbarElement = document.querySelector('#shape-toolbar');
const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      padding: popperPadding,
      fallbackPlacements: ['bottom'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 12],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      boundary: shapeToolbarElement,
      mainAxis: false,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

const shapeToolSizes = [2, 4, 6, 8, 10];

const ShapeSizePopper: FC<React.PropsWithChildren<ShapeSizePopperProps>> = ({
  open,
  anchorEl,
  shapeToolbarPlacement,
  onChange,
}) => {
  const dispatch = useDispatch();
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);
  const activeTools = useSelector(getActiveTools);
  const ratio = useBoardRatio();

  const updateShapeThickness = useCallback(
    (size: any) => {
      dispatch(
        drawingToolActions.updateDrawingTool(activeTools.shape, {
          size: {
            ...selectedTool.size,
            value: size * ratio,
            originValue: size,
          },
        })
      );

      if (currentDrawing) {
        dispatch(
          setShapeCurrentDrawing({
            ...currentDrawing,
            paint: {
              ...currentDrawing.paint,
              strokeWidth: size * ratio,
              originValue: size,
            },
          })
        );
      }

      onChange();
    },
    [dispatch, currentDrawing, selectedTool, activeTools.shape, onChange, ratio]
  );

  return (
    <Popper
      open={open}
      placement={shapeToolbarPlacement || 'top'}
      anchorEl={anchorEl}
      modifiers={popperModifiers}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 2px 4px 0px #00000026',
          padding: '5px 8px',
          borderRadius: 4,
        }}
      >
        <ToolSizeControl
          sizes={shapeToolSizes}
          onChange={updateShapeThickness}
        />
      </Box>
    </Popper>
  );
};

export default ShapeSizePopper;
