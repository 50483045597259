import { FC } from 'react';
import Box from '@mui/material/Box';
import CardContentComponent from '@mui/material/CardContent';
import Tooltip from '../../atoms/Tooltip';
import Avatar from '../../atoms/Avatar';
import Typography from '../../atoms/Typography';
import { AvatarSizeTypes } from '../../atoms/Avatar/types';
import {
  CardContentProps,
  CardHeaderProps,
  CardInfoProps,
} from './cardInfoTypes';
import TooltipDirection from '../../atoms/Tooltip/tooltipTypes';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import CardActions from '../CardActions';

const CardHeader: FC<React.PropsWithChildren<CardHeaderProps>> = ({
  title,
  cardActionsData,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '40px',
      gap: '4px',
    }}
  >
    <Box
      sx={{
        flex: '1 1 auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <Typography
        variant={TypographyType.s2}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </Typography>
    </Box>
    {!!cardActionsData && !!cardActionsData.length && (
      <CardActions cardActionsData={cardActionsData} />
    )}
  </Box>
);

const CardContent: FC<React.PropsWithChildren<CardContentProps>> = ({
  CardIcon,
  subtitle,
  subtitleExtra,
  date,
  avatarImg,
  hasAvatar,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '8px',
      height: '46px',
    }}
  >
    <Box display="flex">
      <CardIcon
        className="cardIcon"
        sx={(theme) => ({
          color: theme.text.t8,
          fontSize: '1.875rem',
          transition: 'color 0.5s',
        })}
      />
    </Box>
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '4px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
        }}
      >
        <Typography
          variant={TypographyType.p5}
          sx={(theme) => ({
            color: theme.text.t7,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          })}
        >
          {subtitle}
        </Typography>
        {date && (
          <>
            <Typography
              variant={TypographyType.p5}
              sx={(theme) => ({
                color: theme.text.t7,
              })}
            >
              /
            </Typography>
            <Typography
              variant={TypographyType.p5}
              sx={(theme) => ({
                color: theme.text.t7,
                whiteSpace: 'nowrap',
              })}
            >
              {date}
            </Typography>
          </>
        )}
      </Box>
      <Typography
        variant={TypographyType.p5}
        sx={(theme) => ({
          color: theme.text.t7,
        })}
      >
        {subtitleExtra}
      </Typography>
    </Box>
    {hasAvatar && (
      <Box>
        <Tooltip title={subtitle} placement={TooltipDirection.BOTTOM}>
          <Avatar
            src={avatarImg}
            size={AvatarSizeTypes.M}
            alt={subtitle}
            sx={(theme) => ({
              bgcolor: theme.background.bg5,
              color: theme.text.t8,
            })}
          />
        </Tooltip>
      </Box>
    )}
  </Box>
);

const CardInfo: FC<React.PropsWithChildren<CardInfoProps>> = ({
  title,
  subtitle,
  CardIcon,
  date,
  subtitleExtra,
  cardActionsData,
  avatarImg,
  hasAvatar = false,
}) => (
  <CardContentComponent
    sx={(theme) => ({
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      flexDirection: 'column',
      gap: '10px',
      bgcolor: '#FFFFFF',
      border: '1px solid',
      borderColor: 'primary.alpha10',
      padding: '9px 19px 15px 19px !important',
      borderRadius: '20px 10px',
      width: '100%',
      height: '122px',
      transition: 'box-shadow 0.5s',
      fontSize: '2.5rem',
      boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
      '&:hover': {
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.14)',
        '& .cardIcon': {
          color: theme.palette.primary.main,
        },
      },
    })}
  >
    <CardHeader title={title} cardActionsData={cardActionsData} />
    <CardContent
      CardIcon={CardIcon}
      subtitle={subtitle}
      date={date}
      subtitleExtra={subtitleExtra}
      avatarImg={avatarImg}
      hasAvatar={hasAvatar}
    />
  </CardContentComponent>
);

export default CardInfo;
