import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import compose from '../../common/utils/compose.utils';
import { getUser } from '../../common/actions/userActions';

const enhancer = compose(
  withRouter,
  connect(
    state => ({
      isFetched: state.auth.isFetched,
    }),
    { getUser },
  ),
);

class TryAuthenticateRoute extends React.Component {
  componentDidMount() {
    this.props.getUser();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isFetched) {
      this.props.getUser();
    }
  }

  render() {
    const { isFetched } = this.props;

    if (isFetched) {
      return (<Switch>{this.props.children}</Switch>);
    }

    return <Switch />;
  }
}

export default enhancer(TryAuthenticateRoute);
