import { ForwardedRef, forwardRef } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import { CheckboxProps } from '@mui/material/Checkbox';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

const LiveBoardCheckbox = styled(MuiCheckbox)({
  width: '24px',
  height: '24px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1.125rem',
  },
  '&:hover': {
    backgroundColor: 'unset',
  },
});

const Checkbox = forwardRef(
  (props: CheckboxProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <LiveBoardCheckbox {...props} ref={ref} disableRipple />
  )
);

export default Checkbox;
