import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import analyticsService from '../../../../../common/services/analytics.service';

type FooterProps = {
  questionText: string;
  formButtonText: string;
};

const AuthFooter: FC<React.PropsWithChildren<FooterProps>> = ({
  questionText,
  formButtonText,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    gap={1.75}
    mt={1.5}
  >
    <Typography
      variant="p4"
      sx={(theme) => ({
        color: theme.text.t7,
      })}
    >
      {questionText}
    </Typography>
    <NavLink
      to="/sign-in"
      onClick={() => analyticsService.event('Open Sign In Click')}
    >
      <Typography
        color="primary"
        sx={{
          fontWeight: 600,
          lineHeight: '19.2px',
          textDecoration: 'underline',
        }}
      >
        {formButtonText}
      </Typography>
    </NavLink>
  </Box>
);

export default AuthFooter;
