import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { take } from 'lodash';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getSelectedDrawingTool } from '../../../common/reducers/board/drawingToolReducer';
import IconButton from '../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import { getCurrentDrawing } from '../../../common/reducers/board/currentDrawingReducer';
import useBoardRatio from '../../../common/hooks/useBoardRatio';

type SizeBoxProps = {
  size: number;
};
const SizeBox: FC<React.PropsWithChildren<SizeBoxProps>> = ({ size }) => (
  <Box
    width={24}
    height={24}
    borderRadius={8}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Box
      width={20}
      height={`${size}px`}
      borderRadius={8}
      sx={(theme) => ({
        backgroundColor: theme.text.t8,
      })}
    />
  </Box>
);

type ToolSizeControlProps = {
  sizes: number[];
  onChange: (size: number) => void;
};

const toolCircleSizes = [1, 3, 5, 7, 9, 12, 14];

type StyledIconButtonProps = {
  active: boolean;
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StyledIconButtonProps>(({ theme, active }) => ({
  ...(active
    ? {
        backgroundColor: theme.palette.primary.alpha10,
      }
    : {}),
}));

const ToolSizeControl: FC<React.PropsWithChildren<ToolSizeControlProps>> = ({
  sizes,
  onChange,
}) => {
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);
  const ratio = useBoardRatio();

  const mappedActiveSize = useMemo(() => {
    if (!currentDrawing?.paint.strokeWidth && !selectedTool.size?.originValue)
      return;

    const shapeSize = currentDrawing
      ? Math.round(Number(currentDrawing.paint.strokeWidth) / ratio)
      : Number(selectedTool.size.originValue);
    const index = sizes.indexOf(shapeSize);

    return toolCircleSizes[index];
  }, [selectedTool, currentDrawing, ratio]);

  return (
    <Box display="flex" gap="4px">
      {take(toolCircleSizes, sizes.length).map((size) => (
        <StyledIconButton
          key={size}
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          active={mappedActiveSize === size}
          onClick={() => {
            const index = toolCircleSizes.indexOf(size);
            onChange(sizes[index]);
          }}
          shape={ButtonShapeTypes.ROUNDED}
        >
          <SizeBox size={size} />
        </StyledIconButton>
      ))}
    </Box>
  );
};

export default ToolSizeControl;
