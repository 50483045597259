import { createSelector } from 'reselect';
import {
  GET_GENERAL_CONFIGURATION_REQUEST,
  GET_GENERAL_CONFIGURATION_SUCCESS,
  GET_GENERAL_CONFIGURATION_FAILURE,
  CREATE_RECORDING,
  COPY_RECORDING,
  REMOVE_RECORDING,
  CREATE_BOARD,
  COPY_BOARD,
  REMOVE_BOARD,
} from '../actions/configurationActions';
import {
  generateBaseConfiguration,
  generateCreateConfiguration,
  generateWarningConfiguration
} from '../utils/configuration.util';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: {
    board: {
      create: generateCreateConfiguration(),
      edit: generateBaseConfiguration(),
      delete: generateBaseConfiguration(),
      move: generateBaseConfiguration(),
      warning: generateWarningConfiguration(),
      importPDF: {
        create: generateCreateConfiguration(),
      },
      exportPdf: {
        create: generateCreateConfiguration(),
      },
    },
    group: {
      create: generateCreateConfiguration(),
      edit: generateBaseConfiguration(),
      delete: generateBaseConfiguration(),
    },
    session: {
      create: generateCreateConfiguration(),
      edit: generateBaseConfiguration(),
      delete: generateBaseConfiguration(),
      warning: generateWarningConfiguration(),
      call: {
        create: generateCreateConfiguration(),
        warning: generateWarningConfiguration(),
      },
      presence: generateBaseConfiguration(),
      publicLink: generateBaseConfiguration(),
      drawingPermission: generateBaseConfiguration(),
      chat: generateBaseConfiguration(),
    },
    recording: {
      create: generateCreateConfiguration(),
      edit: generateBaseConfiguration(),
      delete: generateBaseConfiguration(),
      move: generateBaseConfiguration(),
      warning: generateWarningConfiguration(),
      watermarkFree: generateBaseConfiguration(),
    },
    quiz: {
      create: generateCreateConfiguration(),
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_CONFIGURATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_GENERAL_CONFIGURATION_SUCCESS:
      return {
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    case GET_GENERAL_CONFIGURATION_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_BOARD:
    case COPY_BOARD:
      return {
        ...state,
        data: {
          ...state.data,
          board: {
            ...state.data.board,
            create: {
              ...state.data.board.create,
              count: state.data.board.create.count + 1,
            },
          },
        },
      };
    case REMOVE_BOARD:
      return {
        ...state,
        data: {
          ...state.data,
          board: {
            ...state.data.board,
            create: {
              ...state.data.board.create,
              count: state.data.board.create.count - 1,
            },
          },
        },
      };
    case CREATE_RECORDING:
    case COPY_RECORDING:
      return {
        ...state,
        data: {
          ...state.data,
          recording: {
            ...state.data.recording,
            create: {
              ...state.data.recording.create,
              count: state.data.recording.create.count + 1,
            },
          },
        },
      };
    case REMOVE_RECORDING:
      return {
        ...state,
        data: {
          ...state.data,
          recording: {
            ...state.data.recording,
            create: {
              ...state.data.recording.create,
              count: state.data.recording.create.count - 1,
            },
          },
        },
      };
    default:
      return state;
  }
};

const isFetchedSelector = state => state.configuration.isFetched;

// Boards
const boardConfigurationSelector = state => state.configuration.data.board;

const showBoardCreateLimitInfoSelector = createSelector(
  boardConfigurationSelector,
  boardConfiguration => boardConfiguration.warning.includes('create'),
);

const showBoardListLimitInfoSelector = createSelector(
  boardConfigurationSelector,
  boardConfiguration => boardConfiguration.warning.includes('list'),
);

const boardsCountSelector = createSelector(
  boardConfigurationSelector,
  boardConfiguration => boardConfiguration.create.count,
);

const boardsCountLimitSelector = createSelector(
  boardConfigurationSelector,
  boardConfiguration => boardConfiguration.create.limit,
);

const canMoveBoardEnabledSelector = createSelector(
  boardConfigurationSelector,
  boardConfiguration => boardConfiguration.move.enabled,
);

// Recordings
const recordingConfigurationSelector = state => state.configuration.data.recording;

const showRecordingCreateLimitInfoSelector = createSelector(
  recordingConfigurationSelector,
  recordingConfiguration => recordingConfiguration.warning.includes('create'),
);

const showRecordingListLimitInfoSelector = createSelector(
  recordingConfigurationSelector,
  recordingConfiguration => recordingConfiguration.warning.includes('list'),
);

const recordingsCountSelector = createSelector(
  recordingConfigurationSelector,
  recordingConfiguration => recordingConfiguration.create.count,
);

const recordingsCountLimitSelector = createSelector(
  recordingConfigurationSelector,
  recordingConfiguration => recordingConfiguration.create.limit,
);

const recordingWatermarkInfoSelector = createSelector(
  recordingConfigurationSelector,
  recordingConfiguration => recordingConfiguration.watermarkFree,
);

const canMoveRecordingEnabledSelector = createSelector(
  recordingConfigurationSelector,
  recordingConfiguration => recordingConfiguration.move.enabled,
);

export {
  isFetchedSelector,
  showBoardCreateLimitInfoSelector,
  showBoardListLimitInfoSelector,
  canMoveBoardEnabledSelector,
  boardsCountSelector,
  boardsCountLimitSelector,
  boardConfigurationSelector,
  showRecordingCreateLimitInfoSelector,
  canMoveRecordingEnabledSelector,
  showRecordingListLimitInfoSelector,
  recordingsCountSelector,
  recordingsCountLimitSelector,
  recordingWatermarkInfoSelector,
  recordingConfigurationSelector,
};
