import FileReaderUtils from './fileReader.utils';

const checkMimeTypeInList = async (file: File, possibleExtensions: string[]) => {
  const arrayBuffer = await FileReaderUtils.toArrayBuffer(file);
  const arr = (new Uint8Array(arrayBuffer)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < arr.length; i += 1) {
    header += arr[i].toString(16);
  }

  let type;

  switch (header) {
    case '25504446': { // pdf
      type = 'pdf';
      break;
    }
    case '89504e47': { // image/png
      type = 'png';
      break;
    }
    case '47494638': { // image/gif
      type = 'gif';
      break;
    }
    case '52494646': { // image/webp
      type = 'webp';
      break;
    }
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
    case 'ffd8ffee':
    case 'ffd8ffdb': { // image/jpeg
      type = 'jpeg';
      break;
    }
    default: {
      return false;
    }
  }

  return possibleExtensions.includes(type);
};

const formatFileSize = (bytes: number, decimalPoint = 2) => {
  if (bytes === 0) return '0 Bytes';

  const unit = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(unit));

  return `${parseFloat((bytes / (unit ** index)).toFixed(decimalPoint))} ${sizes[index]}`;
};

export default {
  checkMimeTypeInList,
  formatFileSize,
};
