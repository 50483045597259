import { FC, useCallback, useMemo, useState } from 'react';
import { Room } from 'livekit-client';
import DeviceContext from './DeviceContext';

const DeviceProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [audioSources, setAudioSources] = useState<MediaDeviceInfo[]>([]);
  const [videoSources, setVideoSources] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioOption, setSelectedAudioOption] = useState('');
  const [selectedVideoOption, setSelectedVideoOption] = useState('');

  const listAudioDevices = useCallback(async () => {
    const devices = await Room.getLocalDevices('audioinput');
    if (!devices) return;
    if (!selectedAudioOption) {
      setSelectedAudioOption(devices[0].deviceId);
    }
    setAudioSources(devices);
  }, [setAudioSources, setSelectedAudioOption, selectedAudioOption]);

  const listVideoDevices = useCallback(async () => {
    const devices = await Room.getLocalDevices('videoinput');
    if (!devices) return;
    if (!selectedVideoOption) {
      setSelectedVideoOption(devices[0].deviceId);
    }
    setVideoSources(devices);
  }, [setVideoSources, setSelectedVideoOption, selectedVideoOption]);

  const onChangeAudioOption = useCallback(
    (option: any) => {
      setSelectedAudioOption(option);
    },
    [setSelectedAudioOption]
  );

  const onChangeVideoOption = useCallback(
    (option: any) => {
      setSelectedVideoOption(option);
    },
    [setSelectedVideoOption]
  );

  const value = useMemo(
    () => ({
      audioSources,
      listAudioDevices,
      videoSources,
      listVideoDevices,
      onChangeAudioOption,
      onChangeVideoOption,
      selectedAudioOption,
      selectedVideoOption,
    }),
    [
      audioSources,
      listAudioDevices,
      videoSources,
      listVideoDevices,
      onChangeAudioOption,
      onChangeVideoOption,
      selectedAudioOption,
      selectedVideoOption,
    ]
  );

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};

export default DeviceProvider;
