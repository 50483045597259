import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PIXI from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { clamp, throttle } from 'lodash';
import storage from '../../config/storage';

import CanvasContext from '../board/Contexts/CanvasContext';
import SessionContext from '../Session/SessionContext';
import { addActionAndSend } from '../../common/actions/board/drawingActionsActions';
import {
  selectDrawingTool,
  updateDrawingTool,
} from '../../common/actions/board/drawingToolActions';
import { clearImage } from '../../common/actions/board/imageDrawingToolActions';
import { getActivePage } from '../../common/reducers/board/activePageReducer';
import {
  getFormulaToolColor,
  getSelectedDrawingTool,
} from '../../common/reducers/board/drawingToolReducer';
import { pageActionsSelector } from '../../common/reducers/board/drawingActionsReducer';
import { getImageDrawingTool } from '../../common/reducers/board/imageDrawingToolReducer';
import { connectedSelector } from '../../common/reducers/session/sessionReducer';
import ToolNames from '../DrawingTools/ToolNames';
import Background from './shaders/background/Background';
import {
  createAction,
  createActionFromData,
  createPixiObject,
} from './actions';
import Action from './actions/action';
import DrawingAction from './actions/drawingAction';
import DeleteAction from './actions/delete';
import CullingAlgorithm from './CullingAlgorithm';
import OpenGraphDialog from '../../containers/Modals/OpenGraphDialog';
import imageUtils from '../../common/utils/image.utils';
import Graph from './actions/graph';
import CreateFormulaDialog from '../../containers/Modals/CreateFormulaDialog/CreateFormulaDialog';
import { hexToRgb, rgbToArgbNumber } from '../../common/utils/color.utils';
import Formula from './actions/formula';
import { saveImage } from '../../common/actions/imagesActions';
import { incrementCanvasTouch } from '../../common/actions/session/sessionActions';
import ActionName from './types/ActionName';

const canvasStyles = {
  overflow: 'hidden',
};

const imageActionNames = [
  ActionName.IMAGE,
  ActionName.GRAPH,
  ActionName.FORMULA,
];
const imageToolNames = [ToolNames.Image, ToolNames.Graph, ToolNames.Formula];

const PixiBoard = () => {
  const canvasContext = useContext(CanvasContext);
  const { onZoom, setViewportCenterPosition } = canvasContext;
  const selectedTool = useSelector(getSelectedDrawingTool);
  const connected = useSelector(connectedSelector);
  const formulaToolColor = useSelector(getFormulaToolColor);

  const currentPage = useSelector(getActivePage);
  const actionsSelector = useMemo(
    () => pageActionsSelector(currentPage.id),
    [currentPage.id]
  );

  const actions = useSelector(actionsSelector);
  const imageToolState = useSelector(getImageDrawingTool);
  const dispatch = useDispatch();

  const { openInfiniteDialog } = useContext(SessionContext);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pixiAppRef = useRef<PIXI.Application | null>(null);
  const viewportRef = useRef<Viewport | null>(null);
  const cullRef = useRef<CullingAlgorithm | null>(null);

  const zoomingRef = useRef<boolean>(false);
  const erasingRef = useRef<boolean>(false);
  const draggingRef = useRef<boolean>(false);
  const shouldApplyAction = useRef<boolean>(false);

  const backgroundRef = useRef<Background | null>(null);

  const prevPos = useRef<PIXI.Point | null>(null);
  const currentGraphic = useRef<PIXI.DisplayObject | null>(null);
  const currentAction = useRef<DrawingAction | null>(null);
  const selectionObject = useRef<PIXI.Graphics | null>(null);
  const selectedAction = useRef<PIXI.DisplayObject | null>();
  const prevIndex = useRef<number | null>(null);

  const actionIdToObjectMap = useRef<Map<string, PIXI.DisplayObject>>(
    new Map<string, PIXI.DisplayObject>()
  );
  const objectToActionMap = useRef<Map<PIXI.DisplayObject, DrawingAction>>(
    new Map<PIXI.DisplayObject, DrawingAction>()
  );

  useEffect(() => {
    if (!storage.getItem('infinite-modal')) {
      storage.setItem('infinite-modal', 'true');
      openInfiniteDialog();
    }
  }, [openInfiniteDialog]);

  useEffect(() => {
    if (!canvasRef.current) return undefined;

    if (pixiAppRef.current) return undefined;

    pixiAppRef.current = new PIXI.Application({
      backgroundColor: 0xffffff,
      view: canvasRef.current,
      width: window.innerWidth,
      height: window.innerHeight,
      antialias: true,
    });

    PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON;

    backgroundRef.current = new Background();
    pixiAppRef.current.stage.addChild(backgroundRef.current?.mesh);

    viewportRef.current = new Viewport({
      screenWidth: pixiAppRef.current.view.offsetWidth,
      screenHeight: pixiAppRef.current.view.offsetHeight,
      interaction: pixiAppRef.current.renderer.plugins.interaction,
    });

    viewportRef.current.sortableChildren = true;

    viewportRef.current.clampZoom({
      minScale: 0.25,
      maxScale: 4.0,
    });

    pixiAppRef.current.stage.addChild(viewportRef.current);
    viewportRef.current
      .drag()
      .pinch({ factor: 10 })
      .wheel()
      .moveCenter(viewportRef.current.corner);

    selectionObject.current = new PIXI.Graphics();
    selectionObject.current.zIndex = 1000;
    viewportRef.current.addChild(selectionObject.current);

    backgroundRef.current.mesh.position.set(
      viewportRef.current.position.x,
      viewportRef.current.position.y
    );

    cullRef.current = new CullingAlgorithm();
    cullRef.current.addContainer(viewportRef.current);
    cullRef.current.cull(viewportRef.current.getVisibleBounds());

    PIXI.Ticker.shared.add(() => {
      if (cullRef.current && viewportRef.current && viewportRef.current.dirty) {
        cullRef.current.cull(viewportRef.current.getVisibleBounds());
        viewportRef.current.dirty = false;
      }
    });

    const resize = () => {
      if (!pixiAppRef.current) return;

      if (!viewportRef.current) return;

      if (!backgroundRef.current) return;

      backgroundRef.current.setResolution(
        window.innerWidth,
        window.innerHeight
      );
      pixiAppRef.current.renderer.resize(window.innerWidth, window.innerHeight);
      viewportRef.current.resize(window.innerWidth, window.innerHeight);
    };

    const throttledResize = throttle(resize, 300, {
      leading: false,
    });

    window.addEventListener('resize', throttledResize, false);

    return () => {
      window.removeEventListener('resize', throttledResize, false);
    };
  }, []);

  useEffect(() => {
    if (!viewportRef.current) return undefined;

    if (connected) {
      viewportRef.current.plugins.resume('wheel');

      viewportRef.current.on('zoomed', ({ type, viewport }) => {
        if (type === 'wheel') {
          zoomingRef.current = true;
        } else if (type === 'clamp-zoom') {
          zoomingRef.current = false;
        }

        onZoom({ scale: clamp(viewport.scale.x, 0.25, 4.0) });
        backgroundRef.current?.setScale(viewport.scale.x);
      });

      viewportRef.current.on('zoomed-end', (viewport) => {
        zoomingRef.current = false;
      });

      viewportRef.current.on('moved', (event) => {
        backgroundRef.current?.mesh.position.set(
          event.viewport.position.x,
          event.viewport.position.y
        );
        setViewportCenterPosition({
          x: event.viewport.center.x,
          y: event.viewport.center.y,
        });
      });
    } else {
      viewportRef.current.plugins.pause('wheel');
    }

    return () => {
      if (!viewportRef.current) return;

      viewportRef.current.off('zoomed');
      viewportRef.current.off('zoomed-end');
      viewportRef.current.off('moved');
    };
  }, [connected, onZoom, setViewportCenterPosition]);

  useEffect(() => {
    if (!viewportRef.current) return;

    if (connected && selectedTool.name === ToolNames.Move) {
      viewportRef.current.plugins.resume('drag');
    } else {
      viewportRef.current.plugins.pause('drag');
    }
  }, [connected, selectedTool.name]);

  useEffect(() => {
    if (!viewportRef.current || !backgroundRef.current) return;

    if (zoomingRef.current) return;

    viewportRef.current.setZoom(canvasContext.zoom, true);
    backgroundRef.current.setScale(canvasContext.zoom);
    backgroundRef.current.mesh.position.set(
      viewportRef.current.position.x,
      viewportRef.current.position.y
    );
  }, [canvasContext.zoom]);

  useEffect(() => {
    if (!backgroundRef.current) return;

    backgroundRef.current.setBackground(currentPage.backgroundType);
  }, [currentPage.backgroundType]);

  useEffect(() => {
    if (!backgroundRef.current) return;

    backgroundRef.current.setBackgroundColor(currentPage.color);
  }, [currentPage.color]);

  // Drag
  const onDragStart = useCallback((event: any) => {
    draggingRef.current = true;
  }, []);

  const onDragMove = useCallback((event: any) => {
    if (!selectionObject.current || !selectedAction.current) return;

    if (draggingRef.current) {
      const newPosition = event.data.getLocalPosition(viewportRef.current);
      selectionObject.current.position.set(newPosition.x, newPosition.y);
      selectedAction.current.position.set(newPosition.x, newPosition.y);

      cullRef.current?.updateObject(selectionObject.current);
      cullRef.current?.updateObject(selectedAction.current);

      shouldApplyAction.current = true;
    }
  }, []);

  const onDragEnd = useCallback((event: any) => {
    draggingRef.current = false;
  }, []);

  // Select
  const updateSelection = useCallback(() => {
    if (!viewportRef.current || !selectionObject.current) return;

    const selection = selectionObject.current;

    selection
      .off('pointerdown', onDragStart)
      .off('pointermove', onDragMove)
      .off('pointerup', onDragEnd)
      .off('pointerupoutside', onDragEnd);

    selection.clear();
    selection.hitArea = new PIXI.Rectangle();

    if (!selectedAction.current) return;

    const object = selectedAction.current;
    const bounds = object.getLocalBounds();

    prevIndex.current = viewportRef.current.getChildIndex(object);

    viewportRef.current.removeChild(object);
    viewportRef.current.addChild(object);

    selection.position.set(object.position.x, object.position.y);
    selection.lineStyle({
      width: 2,
      color: 0x346beb,
      alignment: 1,
    });
    selection.drawRect(bounds.left, bounds.top, bounds.width, bounds.height);
    selection.hitArea = bounds;
    selection.interactive = true;
    selection.buttonMode = true;

    cullRef.current?.updateObject(selection);
    viewportRef.current.dirty = true;

    selection
      .on('pointerdown', onDragStart)
      .on('pointermove', onDragMove)
      .on('pointerup', onDragEnd)
      .on('pointerupoutside', onDragEnd);
  }, [onDragStart, onDragMove, onDragEnd]);

  const deselect = useCallback(() => {
    if (!viewportRef.current) return;

    if (shouldApplyAction.current && selectedAction.current) {
      const object = selectedAction.current;
      const action = objectToActionMap.current.get(object);

      if (!action || !action.id) return;

      const newAction = action.createMoveAction(object.position);

      if (!newAction.id) return;

      actionIdToObjectMap.current.delete(action.id);
      objectToActionMap.current.delete(object);

      actionIdToObjectMap.current.set(newAction.id, object);
      objectToActionMap.current.set(object, newAction);

      if (imageActionNames.includes(newAction.name as ActionName)) {
        if (newAction.name === ActionName.IMAGE) {
          // @ts-ignore
          newAction.imageId = newAction.id;
        }

        // @ts-ignore
        dispatch(saveImage(newAction.id, newAction.image));
      }

      if (!newAction.syncedWithFirebase) {
        newAction.targetId = null;
      }

      dispatch(addActionAndSend(newAction.toDto()));

      shouldApplyAction.current = false;
      prevIndex.current = null;
    }

    if (prevIndex.current && selectedAction.current) {
      viewportRef.current.removeChild(selectedAction.current);
      viewportRef.current.addChildAt(selectedAction.current, prevIndex.current);
    }

    prevIndex.current = null;
    selectedAction.current = null;
    updateSelection();
  }, [dispatch, updateSelection]);

  const onSelectStart = useCallback(
    (event: any) => {
      if (!viewportRef.current) return;

      dispatch(incrementCanvasTouch());

      if (selectionObject.current === event.target) return;

      deselect();

      if (event.target === viewportRef.current) return;

      selectedAction.current = event.target;
      updateSelection();
      onDragStart(event);
    },
    [dispatch, deselect, updateSelection]
  );

  // Shapes
  const onShapeDrawStart = useCallback(
    (event: any) => {
      if (!viewportRef.current) return;

      dispatch(incrementCanvasTouch());

      const startPos = event.data.getLocalPosition(viewportRef.current);

      const toolName = selectedTool.id ? 'pathShape' : selectedTool.name;
      // TODO: create action here and pass it to the drawer
      const action = createAction(
        startPos,
        startPos,
        {
          ...selectedTool,
          name: toolName,
        },
        selectedTool.pathData
      );

      currentAction.current = action;

      currentGraphic.current = createPixiObject(action);

      if (!action.id) return;

      actionIdToObjectMap.current.set(action.id, currentGraphic.current);
      objectToActionMap.current.set(currentGraphic.current, action);

      action.draw(currentGraphic.current);
      viewportRef.current.addChild(currentGraphic.current);
      prevPos.current = startPos;
    },
    [dispatch, selectedTool]
  );

  const onShapeDrawMove = useCallback((event: any) => {
    if (
      !viewportRef.current ||
      !currentGraphic.current ||
      !prevPos.current ||
      !currentAction.current
    )
      return;

    const newPos = event.data.getLocalPosition(viewportRef.current);

    currentAction.current.update(prevPos.current, newPos);

    currentAction.current.drawIntermediate(currentGraphic.current);
  }, []);

  const onShapeDrawEnd = useCallback(
    (event: any) => {
      if (
        !viewportRef.current ||
        !currentGraphic.current ||
        !prevPos.current ||
        !currentAction.current
      )
        return;

      const newPos = event.data.getLocalPosition(viewportRef.current);
      currentAction.current.update(prevPos.current, newPos);
      currentAction.current.draw(currentGraphic.current);

      dispatch(addActionAndSend(currentAction.current.toDto()));

      cullRef.current?.updateObject(currentGraphic.current);

      currentAction.current = null;
      currentGraphic.current = null;
      prevPos.current = null;
    },
    [dispatch]
  );

  // Erase
  const handleErase = useCallback(
    (event: any) => {
      if (!viewportRef.current) return;

      if (viewportRef.current === event.target) return;

      if (selectionObject.current === event.target) return;

      const objectToErase = event.target;

      if (!objectToErase) return;

      const action = objectToActionMap.current.get(objectToErase);
      objectToActionMap.current.delete(objectToErase);
      viewportRef.current.removeChild(objectToErase);

      if (!action || !action.id) return;

      actionIdToObjectMap.current.delete(action.id);

      const actionToSend = new DeleteAction();
      actionToSend.targetId = action.id;
      dispatch(addActionAndSend(actionToSend.toDto()));
    },
    [dispatch]
  );

  const onEraserTap = useCallback(
    (event: any) => {
      handleErase(event);
    },
    [handleErase]
  );

  const onEraserStart = useCallback(
    (event: any) => {
      erasingRef.current = true;
      dispatch(incrementCanvasTouch());
    },
    [dispatch]
  );

  const onEraserMove = useCallback(
    (event: any) => {
      if (!erasingRef.current) return;

      handleErase(event);
    },
    [handleErase]
  );

  const onEraserEnd = useCallback((event: any) => {
    erasingRef.current = false;
  }, []);

  useEffect(
    () => () => {
      if (selectedTool.name !== ToolNames.Select) return;

      deselect();
    },
    [deselect, selectedTool.name]
  );

  useEffect(() => {
    if (!viewportRef.current) return undefined;

    if (selectedTool.name === ToolNames.Graph) {
      const pos = {
        x: viewportRef.current?.center.x,
        y: viewportRef.current?.center.y,
      };

      const startPos = {
        x: pos.x,
        y: pos.y,
      };

      const endPos = {
        x: pos.x,
        y: pos.y,
      };

      currentAction.current = createAction(startPos, endPos, selectedTool);

      return () => {
        currentAction.current = null;
      };
    }

    return undefined;
  }, [selectedTool.name]);

  useEffect(() => {
    if (!viewportRef.current) return undefined;

    if (selectedTool.name === ToolNames.Formula) {
      const pos = {
        x: viewportRef.current?.center.x,
        y: viewportRef.current?.center.y,
      };

      const startPos = {
        x: pos.x,
        y: pos.y,
      };

      const endPos = {
        x: pos.x,
        y: pos.y,
      };

      currentAction.current = createAction(startPos, endPos, selectedTool);

      return () => {
        currentAction.current = null;
      };
    }

    return undefined;
  }, [selectedTool.name]);

  useEffect(() => {
    if (!viewportRef.current) return undefined;

    if (selectedTool.name === ToolNames.Select) {
      viewportRef.current.on('pointerdown', onSelectStart);

      return () => {
        if (!viewportRef.current) return;

        viewportRef.current.off('pointerdown', onSelectStart);
      };
    }

    if (selectedTool.name === ToolNames.Eraser) {
      viewportRef.current.on('pointertap', onEraserTap);
      viewportRef.current.on('pointerdown', onEraserStart);
      viewportRef.current.on('pointermove', onEraserMove);
      viewportRef.current.on('pointerup', onEraserEnd);
      viewportRef.current.on('pointerupoutside', onEraserEnd);

      return () => {
        if (!viewportRef.current) return;

        viewportRef.current.off('pointertap', onEraserTap);
        viewportRef.current.off('pointerdown', onEraserStart);
        viewportRef.current.off('pointermove', onEraserMove);
        viewportRef.current.off('pointerup', onEraserEnd);
        viewportRef.current.off('pointerupoutside', onEraserEnd);
      };
    }

    if (selectedTool.name !== ToolNames.Move) {
      viewportRef.current.on('pointerdown', onShapeDrawStart);
      viewportRef.current.on('pointermove', onShapeDrawMove);
      viewportRef.current.on('pointerup', onShapeDrawEnd);
      viewportRef.current.on('pointerupoutside', onShapeDrawEnd);

      return () => {
        if (!viewportRef.current) return;

        viewportRef.current.off('pointerdown', onShapeDrawStart);
        viewportRef.current.off('pointermove', onShapeDrawMove);
        viewportRef.current.off('pointerup', onShapeDrawEnd);
        viewportRef.current.off('pointerupoutside', onShapeDrawEnd);
      };
    }

    return undefined;
  }, [
    onSelectStart,
    onEraserTap,
    onEraserStart,
    onEraserMove,
    onEraserEnd,
    onShapeDrawStart,
    onShapeDrawMove,
    onShapeDrawEnd,
    selectedTool.name,
  ]);

  useEffect(() => {
    if (imageToolState) {
      if (!imageToolNames.includes(selectedTool.name)) return;

      if (!viewportRef.current) return;

      const pos = {
        x: viewportRef.current?.center.x,
        y: viewportRef.current?.center.y,
      };

      const startPos = {
        x: pos.x - imageToolState.width / 2,
        y: pos.y - imageToolState.height / 2,
      };

      const endPos = {
        x: pos.x + imageToolState.width / 2,
        y: pos.y + imageToolState.height / 2,
      };

      const action = createAction(
        startPos,
        endPos,
        selectedTool,
        selectedTool.pathData
      );
      // @ts-ignore
      action.image = imageToolState.image;

      currentAction.current = action;

      currentGraphic.current = createPixiObject(action);
      currentGraphic.current.interactive = true;
      currentGraphic.current.buttonMode = true;

      if (!action.id) return;

      actionIdToObjectMap.current.set(action.id, currentGraphic.current);
      objectToActionMap.current.set(currentGraphic.current, action);

      action.draw(currentGraphic.current);
      viewportRef.current.addChild(currentGraphic.current);

      dispatch(clearImage());
      dispatch(selectDrawingTool(ToolNames.Select));
      selectedAction.current = currentGraphic.current;
      updateSelection();
      shouldApplyAction.current = true;
      currentAction.current = null;
      currentGraphic.current = null;
    }
  }, [dispatch, imageToolState, updateSelection, selectedTool.name]);

  useEffect(() => {
    if (!viewportRef.current) return;

    const actionIds: string[] = actions.map((action: Action) => action.id);
    const drawnActionIdsSynced = Array.from(objectToActionMap.current.values())
      .filter((action) => action.syncedWithFirebase)
      .map((action) => action.id!);
    const actionsToRemove = drawnActionIdsSynced.filter(
      (actionId) => !actionIds.includes(actionId)
    );
    const objectsToRemove = actionsToRemove
      .map((actionId) => actionIdToObjectMap.current.get(actionId))
      .filter((item): item is PIXI.DisplayObject => !!item);

    for (let i = 0; i < objectsToRemove.length; i += 1) {
      objectToActionMap.current.delete(objectsToRemove[i]);
    }
    viewportRef.current.removeChild(...objectsToRemove);

    for (let i = 0; i < actionsToRemove.length; i += 1) {
      actionIdToObjectMap.current.delete(actionsToRemove[i]);
    }

    for (let i = 0; i < actionIds.length; i += 1) {
      const id = actionIds[i];
      const actionToAdd = actions.find((action: Action) => action.id === id);
      const action = createActionFromData(actionToAdd);

      if (actionIdToObjectMap.current.has(id)) {
        const graphicObject = actionIdToObjectMap.current.get(id);

        if (!graphicObject) return;

        const oldAction = objectToActionMap.current.get(graphicObject);

        if (oldAction?.requiresUpdate(action)) {
          const newGraphicObject = createPixiObject(action);
          newGraphicObject.interactive = true;
          newGraphicObject.buttonMode = true;
          actionIdToObjectMap.current.set(id, newGraphicObject);
          objectToActionMap.current.set(newGraphicObject, action);
          objectToActionMap.current.delete(graphicObject);
          graphicObject.destroy();
          action.draw(newGraphicObject);
          viewportRef.current.addChild(newGraphicObject);
        } else {
          objectToActionMap.current.set(graphicObject, action);
          graphicObject.interactive = true;
          graphicObject.buttonMode = true;
        }

        action.setSyncedWithFirebase(true);

        continue;
      }

      action.id = actionToAdd.id;

      if (!action.name) continue;

      currentGraphic.current = createPixiObject(action);
      currentGraphic.current.interactive = true;
      currentGraphic.current.buttonMode = true;

      if (!action.id) continue;

      actionIdToObjectMap.current.set(action.id, currentGraphic.current);
      objectToActionMap.current.set(currentGraphic.current, action);
      action.setSyncedWithFirebase(true);

      action.draw(currentGraphic.current);
      viewportRef.current.addChild(currentGraphic.current);

      currentGraphic.current = null;
    }
  }, [actions]);

  const closeGraphDialog = useCallback(() => {
    dispatch(selectDrawingTool(ToolNames.Select));
  }, [dispatch]);

  const submitGraphDialog = useCallback(
    async (graph: any, svg: any) => {
      if (!viewportRef.current) return;

      const image = await imageUtils.toImage(svg);
      const graphAction = currentAction.current as Graph;
      graphAction.graphState = graph;
      graphAction.image = image;
      dispatch(saveImage(graphAction.id, image));

      const pos = {
        x: viewportRef.current?.center.x,
        y: viewportRef.current?.center.y,
      };

      graphAction.startPoint = {
        x: pos.x - image.width / 2,
        y: pos.y - image.height / 2,
      };
      graphAction.endPoint = {
        x: pos.x + image.width / 2,
        y: pos.y + image.height / 2,
      };

      if (!graphAction.id) return;

      currentGraphic.current = createPixiObject(graphAction);
      currentGraphic.current.interactive = true;
      currentGraphic.current.buttonMode = true;

      actionIdToObjectMap.current.set(graphAction.id, currentGraphic.current);
      objectToActionMap.current.set(currentGraphic.current, graphAction);

      graphAction.draw(currentGraphic.current);
      viewportRef.current.addChild(currentGraphic.current);

      dispatch(selectDrawingTool(ToolNames.Select));
      selectedAction.current = currentGraphic.current;
      updateSelection();
      shouldApplyAction.current = true;
      currentAction.current = null;
      currentGraphic.current = null;
    },
    [dispatch, updateSelection]
  );

  const closeFormulaDialog = useCallback(() => {
    dispatch(selectDrawingTool(ToolNames.Select));
  }, [dispatch]);

  const onSubmitFormulaDialog = useCallback(
    async (tex: string, svg: any) => {
      if (!viewportRef.current) return;

      const image = await imageUtils.toImage(svg);
      const formulaAction = currentAction.current as Formula;
      formulaAction.tex = tex;
      formulaAction.image = image;

      const minHeight = 100;

      let { width, height } = image;

      if (height > 0 && height < minHeight) {
        const ratio = minHeight / height;
        width *= ratio;
        height *= ratio;
      }

      image.width = width;
      image.height = height;
      dispatch(saveImage(formulaAction.id, image));

      const pos = {
        x: viewportRef.current?.center.x,
        y: viewportRef.current?.center.y,
      };

      formulaAction.startPoint = {
        x: pos.x - width / 2,
        y: pos.y - height / 2,
      };
      formulaAction.endPoint = {
        x: pos.x + width / 2,
        y: pos.y + height / 2,
      };

      if (!formulaAction.id) return;

      const formulaObject = createPixiObject(formulaAction);
      formulaObject.interactive = true;
      formulaObject.buttonMode = true;

      actionIdToObjectMap.current.set(formulaAction.id, formulaObject);
      objectToActionMap.current.set(formulaObject, formulaAction);

      formulaAction.draw(formulaObject);
      viewportRef.current.addChild(formulaObject);

      dispatch(selectDrawingTool(ToolNames.Select));
      selectedAction.current = formulaObject;
      updateSelection();
      shouldApplyAction.current = true;
      currentAction.current = null;
      currentGraphic.current = null;
    },
    [dispatch, updateSelection]
  );

  const onUpdateColor = useCallback(
    (color: any) => {
      if (!currentAction.current) return;

      const { r, g, b } = hexToRgb(color);
      const alpha = 1;
      const argbColor = rgbToArgbNumber({
        a: alpha * 255,
        r,
        g,
        b,
      });
      currentAction.current.paint.color = argbColor;

      dispatch(updateDrawingTool(ToolNames.Formula, { color: argbColor }));
    },
    [dispatch]
  );

  const onContextMenu = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={canvasStyles}
        onContextMenu={onContextMenu}
      />
      <OpenGraphDialog
        open={selectedTool.name === ToolNames.Graph}
        graphState={null}
        onClose={closeGraphDialog}
        onSubmit={submitGraphDialog}
        edit={false}
      />
      <CreateFormulaDialog
        color={formulaToolColor}
        onUpdateColor={onUpdateColor}
        open={selectedTool.name === ToolNames.Formula}
        tex={null}
        onClose={closeFormulaDialog}
        onSubmit={onSubmitFormulaDialog}
      />
    </>
  );
};

export default PixiBoard;
