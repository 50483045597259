import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '../Avatar';
import { AvatarSizeTypes } from '../Avatar/types';
import ExtendedAvatarProps from './ExtendedAvatarProps';

const ExtendedAvatar: FC<React.PropsWithChildren<ExtendedAvatarProps>> = ({
  avatarUrl,
  name,
  alt,
}) => (
  <Box
    sx={(theme) => ({
      width: 'fit-content',
      padding: '4px 16px 4px 5px',
      gap: '10px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '20px 0px 20px 20px',
      maxWidth: '240px',
    })}
  >
    <Avatar src={avatarUrl} size={AvatarSizeTypes.XS} alt={alt} />
    <Typography
      sx={(theme) => ({
        fontSize: '1rem',
        fontWeight: 600,
        color: theme.text.t1,
      })}
      noWrap
    >
      {name}
    </Typography>
  </Box>
);

export default ExtendedAvatar;
