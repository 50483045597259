import {
  Geometry,
  Mesh,
  Shader,
  utils,
} from 'pixi.js';
import VERT_SRC from './vert';
import NONE_FRAG_SRC from './noneFrag';
import DOT_FRAG_SRC from './dotFrag';
import LINE_FRAG_SRC from './lineFrag';
import GRID_FRAG_SRC from './gridFrag';
import GridType from '../../../../common/drawingActions/gridType';

class Background {
  geometry: Geometry;

  mesh: Mesh<Shader>;

  shaders: {[key in GridType]: Shader};

  type: GridType;

  constructor(
    width: number = window.innerWidth,
    height: number = window.innerHeight,
    scale: number = 1,
    color: string = '#fff',
  ) {
    this.type = GridType.DOT;
    this.geometry = new Geometry();
    const vertices = [
      -1, -1,
      1, -1,
      1, 1,
      -1, 1,
    ];
    this.geometry.addAttribute('aVertexPosition', vertices, 2).addIndex([0, 1, 2, 0, 2, 3]);

    // Shader and Mesh
    this.shaders = {
      NONE: Shader.from(
        VERT_SRC,
        NONE_FRAG_SRC,
        {
          resolution: [width, height],
          scale,
          color: utils.hex2rgb(utils.string2hex(color)),
        },
      ),
      DOT: Shader.from(
        VERT_SRC,
        DOT_FRAG_SRC,
        {
          resolution: [width, height],
          scale,
          color: utils.hex2rgb(utils.string2hex(color)),
        },
      ),
      LINE: Shader.from(
        VERT_SRC,
        LINE_FRAG_SRC,
        {
          resolution: [width, height],
          scale,
          color: utils.hex2rgb(utils.string2hex(color)),
        },
      ),
      GRID: Shader.from(
        VERT_SRC,
        GRID_FRAG_SRC,
        {
          resolution: [width, height],
          scale,
          color: utils.hex2rgb(utils.string2hex(color)),
        },
      ),
    };

    this.mesh = new Mesh(this.geometry, this.shaders[this.type]);
  }

  setScale(value: number) {
    Object.keys(this.shaders).forEach((key) => {
      this.shaders[key as GridType].uniforms.scale = value;
    });
  }

  setResolution(width: number, height: number) {
    Object.keys(this.shaders).forEach((key) => {
      this.shaders[key as GridType].uniforms.resolution = [width, height];
    });
  }

  setBackground(type: GridType) {
    this.type = type;
    this.mesh.shader = this.shaders[type];
  }

  setBackgroundColor(color: string) {
    Object.keys(this.shaders).forEach((key) => {
      this.shaders[key as GridType].uniforms.color = utils.hex2rgb(utils.string2hex(color));
    });
  }
}

export default Background;
