import { cloneDeep } from 'lodash';
import DrawingAction from '../drawing.action';
import guid from '../../utils/guid.util';
import numberUtil from '../../utils/number.util';

export default class ShapeDrawingAction extends DrawingAction {
  startPoint = null;
  endPoint = null;

  constructor(deserialized) {
    super(deserialized);

    if (deserialized) {
      this.startPoint = { x: deserialized.x1, y: deserialized.y1 };
      this.endPoint = { x: deserialized.x2, y: deserialized.y2 };
    }
  }

  getStartPoint() {
    return this.startPoint;
  }

  setStartPoint(startPoint) {
    this.startPoint = startPoint;
  }

  getEndPoint() {
    return this.endPoint;
  }

  setEndPoint(endPoint) {
    this.endPoint = endPoint;
  }

  createMoveAction() {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;
    newAction.setStartPoint({ ...this.startPoint });
    newAction.setEndPoint({ ...this.endPoint });
    return newAction;
  }

  move({ deltaX, deltaY }) {
    this.setStartPoint({
      x: this.startPoint.x + deltaX,
      y: this.startPoint.y + deltaY,
    });
    this.setEndPoint({
      x: this.endPoint.x + deltaX,
      y: this.endPoint.y + deltaY,
    });
  }

  serialize() {
    const startPoint = this.getStartPoint();
    const endPoint = this.getEndPoint();
    return {
      ...super.serialize(),
      x1: numberUtil.toOneDecimal(startPoint.x),
      y1: numberUtil.toOneDecimal(startPoint.y),
      x2: numberUtil.toOneDecimal(endPoint.x),
      y2: numberUtil.toOneDecimal(endPoint.y),
    };
  }
}
