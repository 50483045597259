import React, { FC } from 'react';
import MuiLinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material';

const LinearProgress = styled(MuiLinearProgress)<LinearProgressProps>(
  ({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    [`.${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1eb270',
    },
  })
);

type BorderLinearProgressProps = {
  value: number;
  maxValue: number;
};

const BorderLinearProgress: FC<
  React.PropsWithChildren<BorderLinearProgressProps & LinearProgressProps>
> = ({ value, maxValue, ...props }) => (
  <LinearProgress value={(value * 100) / maxValue} {...props} />
);

export default BorderLinearProgress;
