import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import SessionContext from './SessionContext';
import InvitePeopleDialog from '../../ui/molecules/InvitePeopleDialog';
import useDialog from '../../common/hooks/useDialog';
import sessionService from '../../common/services/session.service';
import analyticsService from '../../common/services/analytics.service';
import InfiniteBoardModal from '../../containers/Modals/InfiniteBoardModal';
import { useProductTour } from '../ProductTour';
import ToolNames from '../DrawingTools/ToolNames';
import { getSelectedDrawingTool } from '../../common/reducers/board/drawingToolReducer';
import useInputOnChange from '../ImageSelectionProvider/useInputOnChange';
import { ReactComponent as DropAreaIcon } from '../../assets/images/drop-area.svg';
import domUtil from '../../common/utils/dom.util';
import * as sessionActions from '../../common/actions/session/sessionActions';

const imageMimeTypesRegex = /^image\/(jpe?g|jfif|png|webp)/;

const SessionContextProvider = ({ sessionId, children }) => {
  const inviteDialog = useDialog();
  const productTour = useProductTour();
  const dispatch = useDispatch();

  const {
    open: infiniteDialogOpen,
    onOpen: onInfiniteDialogOpen,
    onClose: onInfiniteDialogClose,
  } = useDialog();

  const {
    open: dropAreaOpen,
    onOpen: onDropAreaOpen,
    onClose: onDropAreaClose,
  } = useDialog();

  const selectedTool = useSelector(getSelectedDrawingTool);
  const { enqueueSnackbar } = useSnackbar();
  const { processFiles, showImageTypeError } = useInputOnChange();

  const setClipboardImage = useCallback(
    async (event) => {
      if (selectedTool.name === ToolNames.Image) return;

      const isActiveElementDialog = domUtil.checkIsActiveElementDialog();
      if (isActiveElementDialog) return;

      const files = event.clipboardData.files;
      try {
        await processFiles(files);
        enqueueSnackbar('The image is successfully pasted', {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (error) {
        showImageTypeError();
      }
    },
    [enqueueSnackbar, selectedTool.name, processFiles, showImageTypeError]
  );

  const handleDragOver = useCallback(
    async (event) => {
      event.preventDefault();

      if (selectedTool.name === ToolNames.Image) return;

      const isActiveElementDialog = domUtil.checkIsActiveElementDialog();
      if (isActiveElementDialog) return;

      const files = event.dataTransfer.items;
      const file = Object.values(files).find((file) =>
        imageMimeTypesRegex.test(file.type)
      );

      if (file) {
        onDropAreaOpen();
      }
    },
    [selectedTool.name, onDropAreaOpen]
  );

  const handleDrop = useCallback(
    async (event) => {
      event.preventDefault();
      if (selectedTool.name === ToolNames.Image) return;

      const isActiveElementDialog = domUtil.checkIsActiveElementDialog();
      if (isActiveElementDialog) return;

      onDropAreaClose();

      const files = event.dataTransfer.files;
      try {
        await processFiles(files);
        enqueueSnackbar('The image is successfully pasted', {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (error) {
        showImageTypeError();
      }
    },
    [
      enqueueSnackbar,
      showImageTypeError,
      selectedTool.name,
      onDropAreaClose,
      processFiles,
    ]
  );

  useEffect(() => {
    document.addEventListener('paste', setClipboardImage);
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('dragleave', onDropAreaClose);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('paste', setClipboardImage);
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('dragleave', onDropAreaClose);
      document.removeEventListener('drop', handleDrop);
    };
  }, [setClipboardImage, handleDragOver, handleDrop, onDropAreaClose]);

  const removeEventListeners = useCallback(() => {
    document.removeEventListener('paste', setClipboardImage);
    document.removeEventListener('dragover', handleDragOver);
    document.removeEventListener('dragleave', onDropAreaClose);
    document.removeEventListener('drop', handleDrop);
  }, [setClipboardImage, handleDragOver, onDropAreaClose, handleDrop]);

  const addEventListeners = useCallback(() => {
    document.addEventListener('paste', setClipboardImage);
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('dragleave', onDropAreaClose);
    document.addEventListener('drop', handleDrop);
  }, [setClipboardImage, handleDragOver, onDropAreaClose, handleDrop]);

  const shareSession = useCallback(async () => {
    analyticsService.event('Share Button Click');

    const sharedSession = await sessionService.shareSession(
      sessionId,
      true,
      true
    );
    dispatch(sessionActions.updateSession(sharedSession));
  }, [sessionId]);

  const reshareSession = useCallback(async () => {
    const sharedSession = await sessionService.reshareSession(
      sessionId,
      true,
      true
    );
    dispatch(sessionActions.updateSession(sharedSession));
  }, [sessionId]);

  useEffect(() => {
    if (productTour.sideEffect === 'openInviteDialog') {
      inviteDialog.onOpen();
      productTour.setCurrentStep(2);
      productTour.setSideEffect('');
    } else if (productTour.sideEffect === 'closeInviteDialog') {
      inviteDialog.onClose();
      productTour.setCurrentStep(3);
      productTour.setSideEffect('');
    }
  }, [productTour.sideEffect]);

  const openInviteDialog = useCallback(() => {
    analyticsService.event('Invite Button Click', {
      source: 'invite',
    });
    inviteDialog.onOpen();
  }, [inviteDialog]);

  const value = useMemo(
    () => ({
      openInviteDialog,
      openInfiniteDialog: onInfiniteDialogOpen,
      removeEventListeners,
      addEventListeners,
      reshareSession,
      shareSession,
    }),
    [
      openInviteDialog,
      onInfiniteDialogOpen,
      removeEventListeners,
      addEventListeners,
      reshareSession,
      shareSession,
    ]
  );

  return (
    <SessionContext.Provider value={value}>
      {children}
      <InvitePeopleDialog
        open={inviteDialog.open}
        onCancel={inviteDialog.onClose}
      />
      <InfiniteBoardModal
        open={infiniteDialogOpen}
        onClose={onInfiniteDialogClose}
      />
      <Backdrop
        sx={{
          dropArea: {
            zIndex: 1,
          },
        }}
        open={dropAreaOpen}
        onMouseDown={onDropAreaClose}
      >
        <DropAreaIcon />
      </Backdrop>
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
