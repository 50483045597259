import { useSelector } from 'react-redux';
import { userSelector } from '../reducers/userReducer';
import { contactListSelector } from '../reducers/contactsReducer';

const useCardAvatarImage = (id: string) => {
  const user = useSelector(userSelector);
  const contactsList = useSelector(contactListSelector);

  if (user.id === id) return null;

  const filteredContact = contactsList.find((contact: any) => contact.id === id);

  return filteredContact?.profileImageUrl || null;
};

export default useCardAvatarImage;
