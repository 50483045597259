import ShapeDrawingAction from './shape.drawing.action';

export default class RectangleDrawingAction extends ShapeDrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'rectangle';
  }

  intersect(point) {
    return (
      this.startPoint.x < point.x && this.endPoint.x > point.x &&
      this.startPoint.y < point.y && this.endPoint.y > point.y
    );
  }
}
