import React, { FC, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Dialog from '../../../../../ui/atoms/Dialog';
import DialogTitle from '../../../../../ui/atoms/DialogTitle';
import DialogContent from '../../../../../ui/atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../../../ui/atoms/DialogActions';
import FormatListOutlinedIcon from '../../../../../ui/atoms/Icons/FormatListOutlinedIcon';
import { AssessmentTemplateRadioCard } from '../../../assessment-template/features/assessment-template-list/components/AssessmentTemplateCard';
import * as assessmentTemplateService from '../../../assessment-template/services/assessment-template.service';
import Loader from '../../../../../components/Icons/Loader';
import NoContentCard from '../../../../../ui/molecules/NoContentCard';
import Button from '../../../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import { useNavigateTo } from '../../../../../common/hooks/useNavigateTo';
import analyticsService from '../../../../../common/services/analytics.service';

type AddAssessmentDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (templateId: string) => void;
};

export const AddAssessmentDialog: FC<
  React.PropsWithChildren<AddAssessmentDialogProps>
> = ({ open, onClose, onSuccess }) => {
  const navigateTo = useNavigateTo();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>();
  const assessmentTemplatesQuery = useQuery({
    queryKey: ['assessment-templates'],
    queryFn: () => assessmentTemplateService.getPublishedAssessmentTemplates(),
    onSuccess: (data) => {
      setSelectedTemplateId(data.data?.length > 0 ? data.data[0].id : null);
    },
    onError: () => {
      enqueueSnackbar('Unknown error occurred during loading assessment list', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
    enabled: open,
  });

  const onChangeSelectedTemplateId = useCallback(
    (id: string) => {
      setSelectedTemplateId(id);
    },
    [setSelectedTemplateId]
  );

  const onSubmit = useCallback(() => {
    analyticsService.event('Create Assessment Click', {
      templateId: selectedTemplateId!,
    });
    onSuccess(selectedTemplateId!);
  }, [selectedTemplateId, onSuccess]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle onClose={onClose}>Add assessment</DialogTitle>
      <DialogContent>
        <Box minHeight="300px">
          {assessmentTemplatesQuery.isLoading && <Loader />}
          {!assessmentTemplatesQuery.isLoading &&
            !assessmentTemplatesQuery.data?.data.length && (
              <NoContentCard
                Icon={FormatListOutlinedIcon}
                title="You have no assessments"
                description="Create the first one in assessment library"
                actions={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant={ButtonVariantTypes.PRIMARY}
                      size={ButtonSizeTypes.S}
                      onClick={() => {
                        analyticsService.event(
                          'Assessments Library Link Click',
                          {
                            source: 'Add assessment dialog',
                          }
                        );
                        navigateTo('/assessments/library');
                      }}
                    >
                      Go to library
                    </Button>
                  </Box>
                }
              />
            )}
          {!assessmentTemplatesQuery.isLoading &&
            Number(assessmentTemplatesQuery.data?.data.length) > 0 && (
              <Box
                sx={(t) => ({
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                  [t.breakpoints.up('sm')]: {
                    gap: '16px',
                  },
                  [t.breakpoints.up('md')]: {
                    gap: '28px',
                  },
                })}
              >
                {assessmentTemplatesQuery.data?.data.map((template) => (
                  <AssessmentTemplateRadioCard
                    key={template.id}
                    templateName={template.name}
                    createdDate={template.createdAt}
                    updatedDate={template.updatedAt}
                    questionsCount={template.questionCount}
                    onClick={() => onChangeSelectedTemplateId(template.id)}
                    checked={selectedTemplateId === template.id}
                  />
                ))}
              </Box>
            )}
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton
          onClick={onSubmit}
          disabled={!selectedTemplateId}
        >
          Add
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
