import HorizontalResizer from './HorizontalResizer';

export default class RightResizer extends HorizontalResizer {
  center() {
    const x = Math.max(this.startPoint.x, this.endPoint.x);
    return {
      x: x + this.padding,
      y: (this.startPoint.y + this.endPoint.y) / 2,
    };
  }

  onResize({ x }) {
    if (this.endPoint.x > this.startPoint.x) {
      return {
        startPoint: { ...this.startPoint },
        endPoint: {
          x: Math.max(x - this.padding, this.startPoint.x),
          y: this.endPoint.y,
        },
      };
    }

    return {
      startPoint: {
        x: Math.max(x - this.padding, this.endPoint.x),
        y: this.startPoint.y,
      },
      endPoint: { ...this.endPoint },
    };
  }
}
