import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import * as userReducer from '../../../common/reducers/userReducer';
import useDialog from '../../../common/hooks/useDialog';
import RecordingCard from '../RecordingCard';
import CreateCard from '../CreateCard';
import INFINITE from '../../../common/utils/types/configuration/Infinite.constant';
import {
  recordingsCountLimitSelector,
  recordingsCountSelector,
  showRecordingListLimitInfoSelector,
} from '../../../common/reducers/configurationReducer';
import NoContentCard from '../NoContentCard/NoContentCard';
import VideoOutlineIcon from '../../atoms/Icons/VideoOutlineIcon';
import SessionRecordingListPayload from '../../../common/services/types/recording/sessionRecordingType';
import { cardDate } from '../../../common/utils/date.utils';
import { contactListSelector } from '../../../common/reducers/contactsReducer';
import fileUtils from '../../../common/utils/file.utils';
import PlanDialog from '../PlanDialog';
import DeleteRecordingDialog from '../DeleteRecordingDialog/DeleteRecordingDialog';
import fallbackSrc from '../../../assets/images/fallback-thumbnail.svg';
import RenameRecordingDialog from '../RenameRecordingDialog';
import MoveRecordingDialog from '../MoveRecordingDialog';
import ShareRecordingWithUsersDialog from '../ShareRecordingWithUsersDialog';
import UpgradeDialog from '../UpgradeDialog';
import { groupSelector } from '../../../common/reducers/groupsReducer';
import {
  isFetchingSelector,
  isCopiedRecordingFetchingSelector,
  recordingListSelector,
} from '../../../common/reducers/recordingsReducer';
import RecordingPlayerDialog from '../RecordingPlayerDialog';
import LoadingCard from '../LoadingCard';
import SkeletonGrid from '../SkeletonGrid';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  groupId: string;
  sortingCards: (cards: any[]) => any[];
};

const RecordingList: FC<React.PropsWithChildren<Props>> = ({
  groupId,
  sortingCards,
}) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [selectedRecording, setSelectedRecording] =
    useState<SessionRecordingListPayload | null>(null);
  const recordingGroupSelector = useMemo(
    () => groupSelector(groupId),
    [groupId]
  );
  const group = useSelector(recordingGroupSelector);
  const user = useSelector(userReducer.userSelector);
  const showRecordingLimitInfo = useSelector(
    showRecordingListLimitInfoSelector
  );
  const recordingsCount = useSelector(recordingsCountSelector);
  const contactList = useSelector(contactListSelector);
  const recordingsCountLimit = useSelector(recordingsCountLimitSelector);
  const isCopiedRecordingFetching = useSelector(
    isCopiedRecordingFetchingSelector
  );

  const isHost = !group || user.id === group?.ownerId;

  const {
    open: playerDialogOpen,
    onOpen: onPlayerDialogOpen,
    onClose: onPlayerDialogClose,
  } = useDialog();

  const {
    open: renameDialogOpen,
    onOpen: onRenameDialogOpen,
    onClose: onRenameDialogClose,
  } = useDialog();

  const {
    open: shareDialogOpen,
    onOpen: onShareDialogOpen,
    onClose: onShareDialogClose,
  } = useDialog();

  const {
    open: deleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const {
    open: moveToDialogOpen,
    onOpen: onMoveToDialogOpen,
    onClose: onMoveToDialogClose,
  } = useDialog();

  const {
    open: copyRecordingLimitDialogOpen,
    onOpen: onCopyRecordingLimitDialogOpen,
    onClose: onCopyRecordingLimitDialogClose,
  } = useDialog();

  const getRecordingOwnerName = useCallback(
    (recordingUserId: any) => {
      if (user.id === recordingUserId) return user.name;

      const foundedContact = contactList.find(
        (contact: any) => contact.id === recordingUserId
      );

      return foundedContact && foundedContact.name;
    },
    [contactList, user]
  );

  const recordingsSelector = useMemo(
    () => recordingListSelector(groupId),
    [groupId]
  );

  const recordings = useSelector(recordingsSelector);
  const isFetching = useSelector(isFetchingSelector);

  const sortedRecordings = useMemo(
    () => sortingCards(recordings),
    [recordings, sortingCards]
  );

  const selectRecordingById = useCallback(
    (recordingId: any) => {
      const recording = sortedRecordings.find((c) => c.id === recordingId);

      setSelectedRecording(recording);
    },
    [sortedRecordings, setSelectedRecording]
  );

  const onDeleteClose = useCallback(() => {
    setSelectedRecording(null);
    onDeleteDialogClose();
  }, [setSelectedRecording, onDeleteDialogClose]);

  const onDeleteClick = useCallback(
    (recording: any) => {
      setSelectedRecording(recording);
      onDeleteDialogOpen();
    },
    [setSelectedRecording, onDeleteDialogOpen]
  );

  const onCardClick = useCallback(
    (recording: any) => {
      setSelectedRecording(recording);
      onPlayerDialogOpen();
    },
    [setSelectedRecording, onPlayerDialogOpen]
  );

  const onMoveRecordingClick = useCallback(
    (recording: any) => {
      setSelectedRecording(recording);
      onMoveToDialogOpen();
    },
    [setSelectedRecording, onMoveToDialogOpen]
  );

  const onRename = useCallback(
    (recordingId: any) => {
      selectRecordingById(recordingId);
      onRenameDialogOpen();
    },
    [selectRecordingById, onRenameDialogOpen]
  );

  const onShareWithUsersClick = useCallback(
    (recording: any) => {
      setSelectedRecording(recording);
      onShareDialogOpen();
    },
    [setSelectedRecording, onShareDialogOpen]
  );

  const onCloseRecordingPlayerModal = useCallback(() => {
    setSelectedRecording(null);
    onPlayerDialogClose();
  }, [setSelectedRecording, onPlayerDialogClose]);

  const onRenameRecordingClose = useCallback(() => {
    setSelectedRecording(null);
    onRenameDialogClose();
  }, [setSelectedRecording, onRenameDialogClose]);

  const onShareRecordingClose = useCallback(() => {
    setSelectedRecording(null);
    onShareDialogClose();
  }, [setSelectedRecording, onShareDialogClose]);

  const onCloseMoveRecording = useCallback(() => {
    setSelectedRecording(null);
    onMoveToDialogClose();
  }, [setSelectedRecording, onMoveToDialogClose]);

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onCopyRecordingLimitDialogClose();
  }, [onPlansDialogOpen, onCopyRecordingLimitDialogClose]);

  const skeletonCount = useMemo(() => {
    if (upLg) return 3;
    if (upSm) return 2;

    return 1;
  }, [upLg, upSm]);

  return (
    <>
      <Box
        sx={(t) => ({
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          [t.breakpoints.up('sm')]: {
            gap: '16px',
          },
          [t.breakpoints.up('md')]: {
            gap: '28px',
          },
        })}
      >
        {!isFetching &&
          showRecordingLimitInfo &&
          recordingsCountLimit !== INFINITE &&
          recordingsCount >= recordingsCountLimit && (
            <CardGridItem>
              <CreateCard
                description="You have reached your 5 recordings limit."
                message="Upgrade for unlimited recordings or just"
                width="100%"
                height="100%"
                onUpgrade={onPlansDialogOpen}
              />
            </CardGridItem>
          )}
        {!isFetching && sortedRecordings.length === 0 && (
          <NoContentCard
            Icon={VideoOutlineIcon}
            title={
              isHost
                ? 'You have no recordings'
                : 'There are no recordings in this group'
            }
            description={
              isHost
                ? 'Create your first recording.'
                : "You'll be notified when the owner creates one."
            }
            dashed
          />
        )}
        {isFetching && <SkeletonGrid counts={skeletonCount} hasThumbnail />}
        {isCopiedRecordingFetching && <LoadingCard hasThumbnail />}
        {!isFetching &&
          sortedRecordings.map((recording) => (
            <RecordingCard
              key={recording.id}
              id={recording.id}
              groupId={recording.groupId}
              altText={`Recording ${recording.name}`}
              shared={recording.shared}
              recordingName={recording.name}
              isOwner={user.id === recording.userId}
              ownerId={recording.userId}
              src={recording.thumbnailUrl}
              fallbackSrc={fallbackSrc}
              onCardClick={() => onCardClick(recording)}
              onMove={() => onMoveRecordingClick(recording)}
              onRename={onRename}
              onShareWithUsers={() => onShareWithUsersClick(recording)}
              onDelete={() => onDeleteClick(recording)}
              date={cardDate(recording.createdAt)}
              recordingOwnerName={getRecordingOwnerName(recording.userId)}
              recordingSubtitle={fileUtils.formatFileSize(
                recording.fileSize as number
              )}
              onCopyLimitDialogOpen={onCopyRecordingLimitDialogOpen}
            />
          ))}
      </Box>
      <RecordingPlayerDialog
        open={playerDialogOpen}
        onClose={onCloseRecordingPlayerModal}
        recording={selectedRecording}
      />
      <RenameRecordingDialog
        open={renameDialogOpen}
        onClose={onRenameRecordingClose}
        recording={selectedRecording}
      />
      <ShareRecordingWithUsersDialog
        open={shareDialogOpen}
        onClose={onShareRecordingClose}
        recording={selectedRecording}
      />
      <DeleteRecordingDialog
        open={deleteDialogOpen}
        onClose={onDeleteClose}
        recording={selectedRecording}
      />
      <PlanDialog
        source="Hitting Recording limit"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
      <MoveRecordingDialog
        open={moveToDialogOpen}
        onClose={onCloseMoveRecording}
        recording={selectedRecording}
      />
      <UpgradeDialog
        open={copyRecordingLimitDialogOpen}
        onCancel={onCopyRecordingLimitDialogClose}
        onUpgrade={onUpgradeClick}
      />
    </>
  );
};

export default RecordingList;
