import React, {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import RequestPersonalInformationModal from '../containers/Modals/RequestPersonalInformationModal';
import authService from '../common/services/auth.service';
import { shouldRequestPersonalInformationSelector } from '../common/reducers/userReducer';
import PersonalInformationQuestionDto from '../common/services/dto/auth/PersonalInformationQuestionDto';

const RequestPersonalInformation = () => {
  const [questions, setQuestions] = useState<PersonalInformationQuestionDto[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const shouldRequestPersonalInformation = useSelector(shouldRequestPersonalInformationSelector);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getQuestions = async () => {
      const personalInformationQuestions = await authService.getPersonalInformationQuestions({
        cancelToken: source.token,
      });

      setQuestions(personalInformationQuestions);
      setIsFetching(false);
    };

    if (shouldRequestPersonalInformation) {
      getQuestions();
    }

    return () => source.cancel();
  }, [shouldRequestPersonalInformation, setQuestions]);

  if (!shouldRequestPersonalInformation) {
    return null;
  }

  return (
    <RequestPersonalInformationModal
      questions={questions}
      isFetching={isFetching}
    />
  );
};

export default RequestPersonalInformation;
