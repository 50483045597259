import { FC, useCallback, useContext } from 'react';
import Box from '@mui/material/Box';
import Dialog from '../../ui/atoms/Dialog';
import DialogContent from '../../ui/atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../ui/atoms/DialogActions';
import DialogTitle from '../../ui/atoms/DialogTitle';
import AudioSelect from './AudioSelect';
import DeviceContext from '../BoardControls/CollaborationTools/DeviceContext/DeviceContext';
import VideoSelect from './VideoSelect';

type AudioAndVideoSettingsProps = {
  open: boolean;
  onClose: () => void;
};

const AudioAndVideoSettingsDialog: FC<
  React.PropsWithChildren<AudioAndVideoSettingsProps>
> = ({ open, onClose }) => {
  const {
    onChangeAudioOption,
    onChangeVideoOption,
    selectedAudioOption,
    selectedVideoOption,
  } = useContext(DeviceContext);

  const changeAudioOption = useCallback(
    (event: any) => {
      onChangeAudioOption(event.target.value);
    },
    [onChangeAudioOption]
  );

  const changeVideoOption = useCallback(
    (event: any) => {
      onChangeVideoOption(event.target.value);
    },
    [onChangeVideoOption]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Audio and video settings</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <VideoSelect
            option={selectedVideoOption}
            onChangeOption={changeVideoOption}
          />
          <AudioSelect
            option={selectedAudioOption}
            onChangeOption={changeAudioOption}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onClose}>
          Done
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default AudioAndVideoSettingsDialog;
