import { GA4React } from 'ga-4-react';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import { UAParser } from 'ua-parser-js';
import PropertiesDto from './dto/analytics/PropertiesDto';
import environment from '../../config/environment';
import UserDto from './dto/auth/UserDto';
import { getUtmParameters } from '../utils/utm.utils';

type EventName = 'page_view' | string;

interface EventParameters {
  [key: string]: string | boolean | number;
}

class AnalyticsService {
  ga4?: GA4React;

  mixpanel?: OverridedMixpanel;

  async initGA() {
    if (
      environment.googleAnalytics4.enabled() &&
      environment.googleAnalytics4.id
    ) {
      try {
        const ga4 = new GA4React(environment.googleAnalytics4.id);
        await ga4.initialize();
        this.ga4 = ga4;
      } catch (e) {
        // ToDo: Send to logging service
        console.log('adblocker installed');
      }
    }
  }

  async initMixpanel() {
    if (environment.mixpanel.token && !this.mixpanel) {
      try {
        mixpanel.init(environment.mixpanel.token, {
          ignore_dnt: true,
        });
        this.mixpanel = mixpanel;
      } catch (e) {
        // ToDo: Send to logging service
        console.log('adblocker installed');
      }
    }
  }

  setUserId(userId: string | null) {
    this.ga4?.gtag('set', 'user_id', userId);
    if (userId) {
      this.mixpanel?.identify(userId);
    } else {
      this.mixpanel?.reset();
    }
    window.clarity('identify', userId);
  }

  public event(
    name: EventName,
    parameters: EventParameters = {},
    nonInteraction: boolean = false
  ): void {
    this.ga4?.gtag('event', name.toLowerCase().replaceAll(' ', '_'), {
      ...parameters,
      app_version: environment.appVersion,
      non_interaction: nonInteraction,
    });
    this.mixpanel?.track(name, {
      ...parameters,
      $app_version_string: environment.appVersion,
    });
  }

  public identifyUser(user: UserDto): void {
    const device = new UAParser().getDevice();
    let deviceType;

    switch (device.type) {
      case 'mobile':
        deviceType = ['Phone', 'Web phone'];
        break;
      case 'tablet':
        deviceType = ['Tablet', 'Web tablet'];
        break;
      case 'smarttv':
        deviceType = ['SmartTV', 'Web smartTV'];
        break;
      case undefined:
        deviceType = ['Desktop', 'Web desktop'];
        break;
      default:
        deviceType = [undefined, undefined];
    }

    const searchString = window.location.search;
    const utmParams = getUtmParameters(searchString);

    const superProperties: {
      planId: string;
      isTrial: string;
      deviceType?: string;
    } = {
      planId: user.subscription.plan.id,
      isTrial: user.subscription.isTrial.toString(),
      ...utmParams,
    };

    if (deviceType) {
      superProperties.deviceType = deviceType[0];
    }

    this.setSuperProperties(superProperties);

    const userProperties: {
      language: string;
      clients?: string;
    } = {
      language: navigator.language.substring(0, 2),
    };

    if (deviceType) {
      userProperties.clients = deviceType[1];
    }

    this.setUserProperties(userProperties, true);
    this.setUserProperties({
      $email: user.email,
      planId: user.subscription.plan.id,
    });
  }

  public setUserProperties(
    properties: PropertiesDto,
    union: boolean = false
  ): void {
    if (union) {
      this.mixpanel?.people.union(properties);
      return;
    }

    this.mixpanel?.people.set(properties);
  }

  public setUserPropertiesOnce(properties: PropertiesDto) {
    this.mixpanel?.people.set_once(properties);
  }

  public setSuperProperties(properties: PropertiesDto): void {
    this.mixpanel?.register(properties);
  }
}

export default new AnalyticsService();
