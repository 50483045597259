import { cloneDeep } from 'lodash';
import DrawingAction from './drawing.action';
import numberUtil from '../utils/number.util';
import guid from '../utils/guid.util';

class FormulaDrawingAction extends DrawingAction {
  constructor(deserialized) {
    super(deserialized);

    if (deserialized) {
      this.startPoint = { x: deserialized.x1, y: deserialized.y1 };
      this.endPoint = { x: deserialized.x2, y: deserialized.y2 };
      this.tex = deserialized.tex;
      this.width = this.endPoint.x - this.startPoint.x;
      this.height = this.endPoint.y - this.startPoint.y;
    }

    this.name = 'formula';
    this.image = null;
  }

  serialize() {
    return {
      ...super.serialize(),
      x1: numberUtil.toOneDecimal(this.startPoint.x),
      y1: numberUtil.toOneDecimal(this.startPoint.y),
      x2: numberUtil.toOneDecimal(this.endPoint.x),
      y2: numberUtil.toOneDecimal(this.endPoint.y),
      tex: this.tex,
    };
  }

  intersect(point) {
    return (
      this.image &&
      this.startPoint.x < point.x && this.endPoint.x > point.x &&
      this.startPoint.y < point.y && this.endPoint.y > point.y
    );
  }

  createMoveAction() {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;
    newAction.startPoint = { ...this.startPoint };
    newAction.endPoint = { ...this.endPoint };
    newAction.image = this.image;
    newAction.tex = this.tex;
    newAction.width = this.width || this.image.width;
    newAction.height = this.height || this.image.height;

    return newAction;
  }

  move({ deltaX, deltaY }) {
    this.startPoint = {
      x: this.startPoint.x + deltaX,
      y: this.startPoint.y + deltaY,
    };
    this.endPoint = {
      x: this.endPoint.x + deltaX,
      y: this.endPoint.y + deltaY,
    };
  }

}

export default FormulaDrawingAction;
