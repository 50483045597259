import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AuthCover from '../components/AuthCover/AuthCover';
import ColorLiveBoardLogo from '../../../atoms/Icons/ColorLiveBoardLogo';
import GoogleSignInButton from '../../../atoms/GoogleSignInButton';
import HorizontalDivider from '../../../atoms/HorizontalDivider';
import SignInForm from './SignInForm';
import Container from '../components/Container';
import analyticsService from '../../../../common/services/analytics.service';

type FooterProps = {
  questionText: string;
  formButtonText: string;
};

const Footer: FC<React.PropsWithChildren<FooterProps>> = ({
  questionText,
  formButtonText,
}) => (
  <Box display="flex" justifyContent="center" alignItems="center" gap={1.75}>
    <Typography
      variant="p4"
      sx={(theme) => ({
        color: theme.text.t7,
      })}
    >
      {questionText}
    </Typography>
    <NavLink
      to="/sign-up"
      onClick={() => analyticsService.event('Open Sign Up Click')}
    >
      <Typography
        color="primary"
        sx={{
          fontWeight: 600,
          lineHeight: '19.2px',
          textDecoration: 'underline',
        }}
      >
        {formButtonText}
      </Typography>
    </NavLink>
  </Box>
);

type SignInProps = {
  sessionCode: string;
};

const SignIn: FC<React.PropsWithChildren<SignInProps>> = ({ sessionCode }) => (
  <Box display="flex" height="100%">
    <Container>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 440,
          width: '100%',
          [theme.breakpoints.down('xs')]: {
            maxWidth: '320px',
          },
        })}
      >
        <ColorLiveBoardLogo
          sx={{
            width: '149px',
            height: '30px',
            margin: 'auto',
            mb: 3,
          }}
        />
        <Typography mb={2.5} align="center" variant="h6">
          Log in
        </Typography>
        <Typography align="center" variant="p4" mb={5} component="span">
          Use existing account
        </Typography>
        <Box mb={1.75}>
          <GoogleSignInButton>Log in with Google</GoogleSignInButton>
        </Box>
        <HorizontalDivider
          sx={{
            mb: 1.75,
          }}
        >
          OR
        </HorizontalDivider>
        <Box mb={1.75}>
          <SignInForm />
        </Box>
        <Footer
          questionText="Don't have an account?"
          formButtonText="Sign up"
        />
      </Box>
    </Container>
    <AuthCover />
  </Box>
);

export default SignIn;
