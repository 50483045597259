import { styled } from '@mui/material/styles';
import MuiToggleButtonGroup, { toggleButtonGroupClasses, ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)<ToggleButtonGroupProps>(({ theme }) => ({
  padding: '6px 8px',
  background: theme.background.bg5,
  borderRadius: '6px',
  gap: '8px',
  [`& .${toggleButtonGroupClasses.grouped}:not(:first-of-type)`]: {
    border: 'none',
    borderRadius: '4px',
  },
  [`& .${toggleButtonGroupClasses.grouped}:not(:last-of-type)`]: {
    border: 'none',
    borderRadius: '4px',
  },
}));

export default ToggleButtonGroup;
