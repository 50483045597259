import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Input from '../../ui/atoms/Input';
import InputVariant from '../../ui/atoms/Input/InputVariant';
import InputSize from '../../ui/atoms/Input/InputSize';
import InputStatus from '../../ui/atoms/Input/InputStatus';
import authService from '../../common/services/auth.service';
import PromoCodeDto from '../../common/services/dto/auth/PromoCodeDto';
import Button from '../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../ui/atoms/Button/buttonTypes';
import { useSnackbar } from 'notistack';

type Props = {
  planId: string;
  shouldFetchPromoCode: boolean;
  onFetchSuccess?: (promoCode: PromoCodeDto) => void;
  onValidateSuccess: (promoCode: PromoCodeDto) => void;
};

const StyledForm = styled('form')({
  display: 'flex',
  gap: '16px',
  width: '100%',
});

const PromoCode: FC<React.PropsWithChildren<Props>> = ({
  planId,
  shouldFetchPromoCode,
  onFetchSuccess,
  onValidateSuccess,
}: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { control, setError, setValue, handleSubmit } = useForm({
    defaultValues: {
      promoCode: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (shouldFetchPromoCode) {
      const getPromoCode = async () => {
        try {
          setIsFetching(true);
          const response = await authService.getSubscriptionPromoCode({
            cancelToken: source.token,
          });

          if (!response) return;

          setValue('promoCode', response.code);

          if (onFetchSuccess) {
            onFetchSuccess(response);
          }
        } finally {
          setIsFetching(false);
        }
      };

      getPromoCode();
    }

    return () => source.cancel('Request was cancelled');
  }, [shouldFetchPromoCode, setValue]);

  const onSubmit = useCallback(
    handleSubmit(async ({ promoCode }) => {
      try {
        setIsFetching(true);
        const response = await authService.validatePromoCode(promoCode, planId);
        onValidateSuccess(response);

        setError('promoCode', {
          message: '',
        });
      } catch (e) {
        if (e.code === '302003') {
          setError('promoCode', {
            message: 'Invalid promo code',
          });
        } else {
          enqueueSnackbar('Error occurred, please try again', {
            variant: 'error',
          });
        }
      } finally {
        setIsFetching(false);
      }
    }),
    [enqueueSnackbar, onValidateSuccess, planId, setError]
  );

  const onSubmitClick = useCallback(
    async (event: any) => {
      event.preventDefault();

      await onSubmit(event);
    },
    [onSubmit]
  );

  return (
    <Box mb={1}>
      <StyledForm>
        <Controller
          name="promoCode"
          control={control}
          rules={{
            required: 'Please enter the promo code',
          }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Input
              fullWidth
              placeholder="Promo code"
              disabled={isFetching}
              onChange={onChange}
              value={value}
              variant={InputVariant.CONTAINED}
              size={InputSize.S}
              status={
                errors.promoCode && !!errors.promoCode.message
                  ? InputStatus.ERROR
                  : undefined
              }
              helperText={
                errors.promoCode ? errors.promoCode.message : undefined
              }
            />
          )}
        />
        <Box>
          <Button
            onClick={onSubmitClick}
            loading={isFetching}
            variant={ButtonVariantTypes.OUTLINED}
            size={ButtonSizeTypes.S}
          >
            Apply
          </Button>
        </Box>
      </StyledForm>
    </Box>
  );
};

export default PromoCode;
