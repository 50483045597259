import { inputClasses } from '@mui/material/Input';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { Theme } from '@mui/material/styles';
import InputVariant from '../../atoms/Input/InputVariant';
import InputStatus from '../../atoms/Input/InputStatus';

const generateInputStyle = (
  theme: Theme,
  variant: InputVariant,
  status: InputStatus | undefined,
) => {
  switch (variant) {
    case InputVariant.CONTAINED:
      return {
        [`& .${inputClasses.root}`]: {
          backgroundColor: theme.background.bg4,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: status ? theme.palette[status].main : theme.background.bg4,
          color: theme.text.t8,
          fontWeight: 500,
        },
        [`& .${inputClasses.root}:hover`]: {
          backgroundColor: theme.background.bg5,
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
        },
        [`& .${inputClasses.root}.${inputClasses.focused}`]: {
          backgroundColor: theme.background.bg5,
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
          [`& .${svgIconClasses.root}`]: {
            color: theme.text.t6,
          },
        },
        [`& .${inputClasses.disabled}`]: {
          [` .${inputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t3,
          },
          '&:hover': {
            backgroundColor: theme.background.bg4,
            borderColor: status ? theme.palette[status].main : theme.background.bg4,
          },
          [`.${svgIconClasses.root}`]: {
            color: `${theme.text.t3} !important`,
          },
        },
      };

    case InputVariant.OUTLINED:
      return {
        [`& .${inputClasses.root}`]: {
          backgroundColor: 'inherit',
          borderColor: status ? theme.palette[status].main : theme.background.bg6,
          color: theme.text.t8,
          fontWeight: 500,
        },
        [`& .${inputClasses.root}:hover`]: {
          borderColor: status ? theme.palette[status].main : theme.palette.primary.alpha50,
        },
        [`& .${inputClasses.root}.${inputClasses.focused}`]: {
          borderColor: status ? theme.palette[status].main : theme.text.t8,
          [`& .${svgIconClasses.root}`]: {
            color: theme.palette.primary.main,
          },
        },
        [`& .${inputClasses.disabled}`]: {
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
          [` .${inputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t3,
          },
          '&:hover': {
            borderColor: status ? theme.palette[status].main : theme.background.bg5,
          },
          [`.${svgIconClasses.root}`]: {
            color: `${theme.text.t3} !important`,
          },
        },
      };

    default: return {};
  }
};

export default generateInputStyle;
