import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BasePlacement } from '@popperjs/core/lib/enums';
import Box from '@mui/material/Box';
import ShapeColorPopper from './ShapeColorPopper';
import { ToolbarBoundaryRect } from '../../../../DrawingLayer/TextLayer/type/PopperPosition';
import { getSelectedDrawingTool } from '../../../../../../common/reducers/board/drawingToolReducer';
import {
  rgbaToHexWithoutAlpha,
  toColor,
} from '../../../../../../common/utils/color.utils';
import IconButton from '../../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import { getCurrentDrawing } from '../../../../../../common/reducers/board/currentDrawingReducer';
import ToolbarDivider from '../../../../ToolbarDivider';

type ShapeColorControlProps = {
  openColorPicker: boolean;
  toolbarBoundaryRect: ToolbarBoundaryRect | null;
  onClick: () => void;
  shapeToolbarPlacement: BasePlacement | null;
};

type CircleBoxProps = {
  fill: boolean;
  color: string;
};

const CircleBox: FC<React.PropsWithChildren<CircleBoxProps>> = ({
  fill,
  color,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{
      background:
        'conic-gradient(from 180deg at 50% 50%, rgba(252, 31, 195, 0.9) -44.57deg, rgba(252, 15, 56, 0.9) 4.84deg, rgba(252, 144, 64, 0.9) 61.83deg, rgba(254, 228, 51, 0.9) 118.32deg, #62CD01 171.24deg, rgba(2, 181, 168, 0.9) 234.28deg, #1128FB 272.67deg, rgba(252, 31, 195, 0.9) 315.43deg, rgba(252, 15, 56, 0.9) 364.84deg)',
      borderRadius: '50%',
      width: 22,
      height: 22,
    }}
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        width: 19,
        height: 19,
        borderRadius: '50%',
        backgroundColor: theme.background.bg1,
      })}
    >
      <Box
        width={14}
        height={14}
        borderRadius={2}
        sx={(theme) => ({
          ...(fill
            ? {
                backgroundColor: color,
              }
            : {
                border: `1.5px solid ${color}`,
              }),
          ...(color.toLowerCase() === '#ffffff'
            ? {
                border: `1.5px solid ${theme.text.t2}`,
              }
            : {}),
        })}
      />
    </Box>
  </Box>
);

const ShapeColorControl: FC<
  React.PropsWithChildren<ShapeColorControlProps>
> = ({
  openColorPicker,
  toolbarBoundaryRect,
  shapeToolbarPlacement,
  onClick,
}) => {
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);
  const color = useMemo(
    () => toColor(Number(currentDrawing?.paint.color) || selectedTool.color),
    [currentDrawing, selectedTool.color]
  );
  const hexColor = useMemo(() => rgbaToHexWithoutAlpha(color), [color]);
  const anchorEl = useMemo(
    () => ({
      getBoundingClientRect: () => toolbarBoundaryRect as DOMRect,
    }),
    [toolbarBoundaryRect]
  );

  return (
    <>
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={onClick}
        shape={ButtonShapeTypes.ROUNDED}
        sx={{
          padding: '6px',
        }}
      >
        <CircleBox
          fill={currentDrawing?.paint.fill ?? selectedTool.fill}
          color={hexColor}
        />
      </IconButton>
      {toolbarBoundaryRect && anchorEl && (
        <ShapeColorPopper
          open={openColorPicker}
          anchorEl={anchorEl}
          shapeToolbarPlacement={shapeToolbarPlacement}
          onChange={onClick}
        />
      )}
      <ToolbarDivider />
    </>
  );
};

export default ShapeColorControl;
