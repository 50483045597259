import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Avatar from '../Avatar';
import { userSelector } from '../../../common/reducers/userReducer';
import { AvatarBorderTypes, AvatarSizeTypes } from '../Avatar/types';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ChevronUpIcon from '../Icons/ChevronUpIcon';

type ProfileAvatarProps = {
  menuOpen: boolean,
  onMenuOpen: () => void,
};

const ProfileAvatar = forwardRef<HTMLDivElement, ProfileAvatarProps>(({
  menuOpen,
  onMenuOpen,
}, ref) => {
  const user = useSelector(userSelector);

  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="center"
      width={84}
      height={48}
      onClick={onMenuOpen}
      gap={1.25}
      borderRadius={12}
      padding="6px 8px"
      sx={theme => ({
        cursor: 'pointer',
        ':hover': {
          backgroundColor: theme.background.bg3,
          '& .arrowUpIcon': {
            color: theme.palette.primary.main,
          },
        },
        ...(menuOpen ? {
          backgroundColor: theme.palette.primary.alpha10,
          ':hover': {
            backgroundColor: theme.palette.primary.alpha10,
          },
        } : {}),
      })}
    >
      <Avatar
        src={user.profileImageUrl ? user.profileImageUrl : undefined}
        size={AvatarSizeTypes.S}
        alt={user.name}
        border={menuOpen ? AvatarBorderTypes.ACTIVE : AvatarBorderTypes.DEFAULT}
      />
      <Box height={24}>
        { menuOpen ? (
          <ChevronUpIcon
            sx={theme => ({
              color: theme.palette.primary.dark5,
            })}
          />
        ) : (
          <ChevronDownIcon
            sx={theme => ({
              color: theme.text.t8,
            })}
            className="arrowUpIcon"
          />
        )}
      </Box>
    </Box>
  );
});

export default ProfileAvatar;
