import { FC, useCallback, useEffect, useState } from 'react';
import Collapse, { collapseClasses } from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { buttonClasses, ButtonProps } from '@mui/material/Button';
import Button from '../Button';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../Button/buttonTypes';
import FaceAgentIcon from '../Icons/FaceAgentIcon';
import SupportMailLink from '../../../components/SupportMailLink';

const StyledButton = styled(({ ...props }: ButtonProps) => (
  <Button
    variant={ButtonVariantTypes.OUTLINED}
    size={ButtonSizeTypes.M}
    color={ButtonColorTypes.PRIMARY}
    startIcon={<FaceAgentIcon />}
    {...props}
  />
))(() => ({
  minWidth: 'auto',
  padding: '10px',
  whiteSpace: 'nowrap',
  [`& .${buttonClasses.startIcon}`]: {
    margin: '0px',
  },
}));

type SupportButtonProp = {
  textVisibility?: boolean;
  expandable?: boolean;
};

const SupportButton: FC<React.PropsWithChildren<SupportButtonProp>> = ({
  textVisibility = false,
  expandable = true,
}) => {
  const [hoverState, setHoverState] = useState(textVisibility);
  const hoverCallBack = useCallback(() => {
    if (!expandable) return;

    setHoverState((prevState) => textVisibility || !prevState);
  }, [setHoverState, textVisibility, expandable]);

  useEffect(() => {
    setHoverState(textVisibility);
  }, [textVisibility]);

  return (
    <SupportMailLink>
      <StyledButton onMouseEnter={hoverCallBack} onMouseLeave={hoverCallBack}>
        <Collapse
          sx={{
            [`& .${collapseClasses.horizontal}`]: {
              paddingLeft: '5px',
            },
          }}
          orientation="horizontal"
          in={hoverState}
        >
          Have questions?
        </Collapse>
      </StyledButton>
    </SupportMailLink>
  );
};

export default SupportButton;
