import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BasePlacement } from '@popperjs/core/lib/enums';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ShapeColorControl from './ShapeColorControl';
import IconButton from '../../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import TrashCanOutlineIcon from '../../../../../../ui/atoms/Icons/TrashCanOutlineIcon';
import ShapeSizeControl from './ShapeSizeControl';
import ContentCopyIcon from '../../../../../../ui/atoms/Icons/ContentCopyIcon';
import { getCurrentDrawing } from '../../../../../../common/reducers/board/currentDrawingReducer';
import ToolbarContainer from '../../../../ToolbarContainer';
import ToolbarDivider from '../../../../ToolbarDivider';

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Props = {
  position: Position;
  onDelete: () => void;
  onCopy: () => void;
  onApply: () => void;
  openCropModal: () => void;
  open: boolean;
};

const popperPadding = {
  top: 156,
  right: 320,
};

const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: popperPadding,
      fallbackPlacements: ['bottom', 'right', 'left'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 20],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: true,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

const ShapeToolbar: FC<React.PropsWithChildren<Props>> = ({
  position,
  open,
  onDelete,
  onCopy,
}) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openSizeControl, setOpenSizeControl] = useState(false);
  const [shapeToolbarPlacement, setShapeToolbarPlacement] =
    useState<BasePlacement | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<any>(null);
  const currentDrawing = useSelector(getCurrentDrawing);

  const getToolbarBoundaryRect = () => {
    if (!toolbarRef.current) return null;

    return toolbarRef.current.getBoundingClientRect();
  };

  const toolbarBoundaryRect = getToolbarBoundaryRect();

  const virtualElement = useMemo(
    () => ({
      getBoundingClientRect: (): DOMRect =>
        new DOMRectReadOnly(
          position.x,
          position.y,
          position.width,
          position.height
        ),
    }),
    [position]
  );

  const onTogglePicker = useCallback(() => {
    setOpenColorPicker((prevState) => !prevState);
    setOpenSizeControl(false);
  }, [setOpenColorPicker, setOpenSizeControl]);

  const onToggleSizeControl = useCallback(() => {
    setOpenSizeControl((prevState) => !prevState);
    setOpenColorPicker(false);
  }, [setOpenSizeControl, setOpenColorPicker]);

  useEffect(() => {
    if (!popperRef.current) return;
    const updatePopperPlacement = async () => {
      const updatedPopper = await popperRef.current.update();
      if (shapeToolbarPlacement === updatedPopper.placement) return;

      setShapeToolbarPlacement(updatedPopper.placement);
    };
    updatePopperPlacement();
  }, [shapeToolbarPlacement, toolbarBoundaryRect, setShapeToolbarPlacement]);

  return (
    <>
      <Popper
        open={open}
        placement="top"
        anchorEl={virtualElement}
        modifiers={popperModifiers}
        popperRef={popperRef}
      >
        <ToolbarContainer ref={toolbarRef} id="shape-toolbar">
          <ShapeColorControl
            openColorPicker={openColorPicker}
            toolbarBoundaryRect={toolbarBoundaryRect}
            onClick={onTogglePicker}
            shapeToolbarPlacement={shapeToolbarPlacement}
          />
          <ShapeSizeControl
            openSizeControl={openSizeControl}
            toolbarBoundaryRect={toolbarBoundaryRect}
            shapeToolbarPlacement={shapeToolbarPlacement}
            onClick={onToggleSizeControl}
          />
          {currentDrawing && (
            <>
              <IconButton
                variant={ButtonVariantTypes.TEXT}
                size={ButtonSizeTypes.S}
                onClick={onCopy}
                shape={ButtonShapeTypes.ROUNDED}
              >
                <ContentCopyIcon />
              </IconButton>
              <ToolbarDivider />
            </>
          )}
          <Box>
            <IconButton
              onClick={onDelete}
              variant={ButtonVariantTypes.TEXT}
              size={ButtonSizeTypes.S}
              shape={ButtonShapeTypes.ROUNDED}
            >
              <TrashCanOutlineIcon />
            </IconButton>
          </Box>
        </ToolbarContainer>
      </Popper>
    </>
  );
};

export default ShapeToolbar;
