import FreeDrawingAction from '../free.drawing.action';

export default class LinePointerAction extends FreeDrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'pointLine';
  }

  setParticipant(participant) {
    this.participant = participant;
  }
}
