import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '../../atoms/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import BoardBackgroundEmptyIcon from '../../atoms/Icons/BackgroundEmptyIcon';
import BoardBackgroundLinesIcon from '../../atoms/Icons/BackgroundLineIcon';
import BoardBackgroundDotsIcon from '../../atoms/Icons/BackgroundDotsIcon';
import BoardBackgroundGridIcon from '../../atoms/Icons/BackgroundGridIcon';
import GridType from '../../../common/drawingActions/gridType';
import BackgroundTypeButton from './BackgroundTypeButton';
import { getActivePage } from '../../../common/reducers/board/activePageReducer';
import fireBaseService from '../../../common/services/firebaseService';
import BackgroundColorButton from './BackgroundColorButton';
import analyticsService from '../../../common/services/analytics.service';

const boardTypeOptions = [
  {
    title: 'Dots',
    value: GridType.DOT,
    icon: BoardBackgroundDotsIcon,
  },
  {
    title: 'Grid',
    value: GridType.GRID,
    icon: BoardBackgroundGridIcon,
  },
  {
    title: 'Lines',
    value: GridType.LINE,
    icon: BoardBackgroundLinesIcon,
  },
  {
    title: 'Plain',
    value: GridType.NONE,
    icon: BoardBackgroundEmptyIcon,
  },
];

const backgroundColors = [
  '#FFFFFF',
  '#E5E5E5',
  '#336186',
  '#53A7E9',
  '#274C43',
  '#FA9EA0',
  '#F2CF6E',
  '#F5A26B',
  '#4B3EA7',
  '#B4B4B4',
  '#5E656B',
  '#383838',
];

type Props = {
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

const BoardBackgroundTypeDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onSuccess,
  onClose,
}) => {
  const [selectedGridType, setSelectedGridType] = useState(GridType.DOT);
  const [selectedBackgroundColor, setSelectedBackgroundColor] =
    useState('#FFFFFF');

  const currentPage = useSelector(getActivePage);

  useEffect(() => {
    if (currentPage) {
      setSelectedGridType(currentPage.backgroundType);
      setSelectedBackgroundColor(currentPage.color);
    }
  }, [currentPage, setSelectedGridType, setSelectedBackgroundColor]);

  const onChangeBackgroundGridType = useCallback(
    (event: any, newGridType: any) => {
      if (newGridType !== null) {
        setSelectedGridType(newGridType);
      }
    },
    [setSelectedGridType]
  );

  const updateGridType = useCallback(async () => {
    if (currentPage) {
      await fireBaseService.editPage(
        currentPage.id,
        selectedGridType,
        selectedBackgroundColor
      );
      analyticsService.event('Page Settings Changed', {
        grid: selectedGridType,
        color: selectedBackgroundColor,
      });
      onSuccess();
    }
  }, [selectedGridType, selectedBackgroundColor, onSuccess, currentPage]);

  const onChangeBackgroundColor = useCallback(
    (event: any, selectedColor: any) => {
      setSelectedBackgroundColor(selectedColor);
    },
    [setSelectedBackgroundColor]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle onClose={onClose}>Page settings</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography variant="s4" mb={2}>
            Grid
          </Typography>
          <Box display="flex" alignItems="center" gap={5}>
            {boardTypeOptions.map((option) => (
              <Box key={option.value}>
                <BackgroundTypeButton
                  value={option.value}
                  selected={selectedGridType === option.value}
                  onSelect={onChangeBackgroundGridType}
                  icon={option.icon}
                />
                <Typography variant="p6" align="center" mt={0.5}>
                  {option.title}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box mt={3}>
            <Typography variant="s4">Background</Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={2.25} mt={2}>
            {backgroundColors.map((color) => (
              <BackgroundColorButton
                key={color}
                color={color}
                onChange={onChangeBackgroundColor}
                selected={selectedBackgroundColor === color}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={updateGridType} color="primary">
          Apply
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default BoardBackgroundTypeDialog;
