import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import Input from '../../atoms/Input';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import InputStatus from '../../atoms/Input/InputStatus';
import sessionService from '../../../common/services/session.service';

type Props = {
  open: boolean;
  onClose: () => void;
};

const errorMessages = {
  required: 'Please, enter a code',
  length: 'Session code must contain 5 characters',
  format: 'Session code can contain only latin symbols and numbers',
};

const JoinSessionDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
}) => {
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      sessionCode: '',
    },
    reValidateMode: 'onSubmit',
  });

  const dispatch = useDispatch();
  const location = useLocation();

  const onJoinSession = useCallback(
    async (sessionCode: string) => {
      if (!sessionCode) return;

      try {
        const session = await sessionService.getSessionByCode(sessionCode);
        dispatch(
          push({
            pathname: `/session/${session.id}`,
            state: { referrer: location.pathname },
          })
        );
      } catch (error) {
        if (error && error.message) {
          let { message } = error;
          // ToDo: LIVEWEB-408
          if (message === 'No such session!') {
            message = 'Wrong session code.';
          }

          setError('sessionCode', { message });
        }
      }
    },
    [dispatch, setError, location]
  );

  const onSubmit = useCallback(
    handleSubmit(({ sessionCode }) => {
      try {
        onJoinSession(sessionCode);
      } catch (error) {
        setError('sessionCode', error.message);
      }
    }),
    [handleSubmit, onJoinSession]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Join session</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Controller
            name="sessionCode"
            control={control}
            rules={{
              required: errorMessages.required,
              minLength: {
                value: 5,
                message: errorMessages.length,
              },
              maxLength: {
                value: 5,
                message: errorMessages.length,
              },
              pattern: {
                value: /^[a-zA-Z0-9_]*$/,
                message: errorMessages.format,
              },
            }}
            render={({
              field: { onChange, value },
              formState: { errors: error },
            }) => (
              <Input
                onChange={onChange}
                value={value}
                size={InputSize.M}
                variant={InputVariant.CONTAINED}
                placeholder="Enter session code"
                status={error.sessionCode ? InputStatus.ERROR : undefined}
                helperText={
                  error.sessionCode ? error.sessionCode.message : undefined
                }
                limit={5}
                showLimitCount
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton onClick={onClose}>
            Cancel
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton type="submit">
            Join
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default JoinSessionDialog;
