import * as pointerImageUtils from '../../../../common/utils/pointer-image.utils';

const pointerDrawer = (ctx, item, scale) => {
  const {
    point,
    participant = {},
  } = item;
  const imageSize = 48 / scale;
  const textPadding = 20 / scale;
  const textBoxHeight = 24 / scale;
  ctx.save();
  ctx.drawImage(pointerImageUtils.getImage(), point.x, point.y, imageSize, imageSize);

  if (participant) {
    const {
      name,
      color,
    } = participant;
    ctx.font = `${16 / scale}px Roboto`;
    ctx.fillStyle = 'rgb(0, 0, 0)';
    const rectWidth = ctx.measureText(name).width + (2 * textPadding);

    ctx.save();
    ctx.fillStyle = '#fff';
    ctx.fillRect(point.x, point.y + imageSize, rectWidth, textBoxHeight);
    ctx.restore();

    ctx.textBaseline = 'top';
    ctx.fillText(name, point.x + (20 / scale), point.y + imageSize + (4 / scale));

    ctx.save();
    ctx.strokeStyle = 'rgb(144, 147, 149)';
    ctx.lineWidth = 2 / scale;
    ctx.strokeRect(point.x, point.y + imageSize, rectWidth, textBoxHeight);
    ctx.restore();

    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.lineWidth = 2 / scale;
    ctx.beginPath();
    ctx.rect(point.x, point.y + imageSize + textBoxHeight, rectWidth, 6 / scale);
    ctx.fill();
    ctx.stroke();
  }

  ctx.restore();
};

export default pointerDrawer;
