import type Point from '../types/point';

export const getCursorPosition = (
  zoom: number,
  originX: number,
  originY: number,
  scale: number,
  mouseEvent: any
): Point => {
  const { layerX: x, layerY: y } = mouseEvent;

  return {
    x: (x / zoom + originX) / scale,
    y: (y / zoom + originY) / scale,
  };
};
