import React from 'react';
import { Subject, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  scan,
  take,
  withLatestFrom,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { animationFrame } from 'rxjs/Scheduler';
import DomEventsUtil from '../../../../common/utils/domEvents.util';
import { withCanvasContext } from '../../Contexts/CanvasContext';
import FreeV3DrawingAction from '../../../../common/drawingActions/free.drawing.v3.action';
import freeLineDrawer from '../drawers/freeLineDrawer';
import clearSelection from './clearSelection';
import PenCursorSvgIcon from '../../../../assets/svgIcons/PenCursorSvgIcon.svg';

class FreeLineDrawingLayer extends React.Component {
  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();
  }

  componentWillUnmount() {
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');
    this.drawingContext.canvas.style.cursor = `url(${PenCursorSvgIcon}) 0 23, default`;
  };

  canvas = null;
  drawingContext = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();

  draw = (freeLine) => {
    if (!freeLine) return;
    const { zoom, scale, originX, originY } = this.props;
    if (!this.drawingContext) return;

    this.clear();
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    freeLineDrawer(this.drawingContext, freeLine);

    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  clear = () => {
    const { width, height } = this.props;
    this.drawingContext.clearRect(0, 0, width, height);
  };

  subscribeToDrawing = () => {
    const startEvents$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(tap(clearSelection));

    const moveEvents$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    );

    const endEvents$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    );

    const freeLine$ = startEvents$.pipe(
      concatMap(() =>
        moveEvents$.pipe(
          scan((old, coordinates) => {
            if (!coordinates) return null;
            const { x, y } = coordinates;
            const { scale, originX, originY, zoom } = this.props;
            if (!old) {
              const drawingAction = new FreeV3DrawingAction();
              drawingAction.paint.color = this.props.color;
              drawingAction.paint.strokeWidth = this.props.size;
              drawingAction.addPoint({
                x: (x / zoom + originX) / scale,
                y: (y / zoom + originY) / scale,
              });
              drawingAction.updatePoints();
              drawingAction.localStartTime = new Date();
              return drawingAction;
            }

            old.addPoint({
              x: (x / zoom + originX) / scale,
              y: (y / zoom + originY) / scale,
            });
            old.updatePoints();
            return old;
          }, null),
          takeUntil(endEvents$),
          throttleTime(0, animationFrame)
        )
      )
    );

    const stop$ = startEvents$.pipe(
      concatMap(() =>
        moveEvents$.pipe(
          concatMap(() => endEvents$.pipe(take(1))),
          take(1)
        )
      )
    );

    freeLine$.subscribe((a) => this.draw(a));
    stop$.pipe(withLatestFrom(freeLine$)).subscribe(([, action]) => {
      this.props.addActionAndSend(action);
      this.clear();
    });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  render() {
    const { width, height } = this.props;

    return (
      <canvas
        ref={this.setDrawingContext}
        width={width}
        height={height}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        onContextMenu={this.onContextMenu}
      />
    );
  }
}

export default withCanvasContext(FreeLineDrawingLayer);
