import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as uiActions from '../../../common/actions/uiActions';
import * as messagesReducer from '../../../common/reducers/session/messagesReducer';
import * as uiReducer from '../../../common/reducers/uiReducer';
import ChatButton from './ChatButton/ChatButton';
import ChatTab from './ChatTab/ChatTab';
import analyticsService from '../../../common/services/analytics.service';

const enhancer = connect(
  (state) => ({
    opened: uiReducer.messagesSideBarOpenedSelector(state),
    unreadMessageCount: messagesReducer.unreadMessageCount(state),
  }),
  { toggle: uiActions.setMessagesSideBarVisibility }
);

class ChatTool extends PureComponent {
  onChatButtonClick = () => {
    const { opened, toggle } = this.props;

    toggle(!opened);

    if (!opened) {
      analyticsService.event('Chat Open Button Click', {
        source: 'Collaboration',
      });

      return;
    }

    analyticsService.event('Chat Close Button Click', {
      source: 'Collaboration',
    });
  };

  closeChat = () => {
    const { toggle } = this.props;
    toggle(false);
  };

  render() {
    const { opened, unreadMessageCount, editable } = this.props;

    return (
      <>
        <ChatButton
          key="chat-button"
          onClick={this.onChatButtonClick}
          unreadMessageCount={unreadMessageCount}
          tooltipPlacment={this.props.tooltipPlacment}
        />
        <ChatTab
          key="chat-sidebar"
          opened={opened}
          editable={editable}
          onClose={this.closeChat}
        />
      </>
    );
  }
}

export default enhancer(ChatTool);
