import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { AssessmentPaperSidebarContext } from '../AssessmentPaperSidebarContext';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonColorTypes,
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import QuizIcon from '../../../../../ui/atoms/Icons/QuizIcon';
import { AssessmentPaperSidebar } from '../AssessmentPaperSidebar';
import { useSelector } from 'react-redux';
import * as sessionReducer from '../../../../../common/reducers/session/sessionReducer';
import usePrevious from '../../../../../common/hooks/usePrevious';
import { useHistory } from 'react-router-dom';
import analyticsService from '../../../../../common/services/analytics.service';

export const Layout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const assessmentSidebarContext = useContext(AssessmentPaperSidebarContext);
  const history = useHistory();
  const assessmentInfo = useSelector(sessionReducer.assessmentInfoSelector);
  const previousAssessmentInfo = usePrevious(assessmentInfo);

  useEffect(() => {
    if (previousAssessmentInfo !== assessmentInfo && !!assessmentInfo) {
      assessmentSidebarContext.onClose();
      history.push(`/assessment/${assessmentInfo.id}`);
    }
  }, [assessmentInfo]);

  return (
    <Box>
      <IconButton
        variant={ButtonVariantTypes.PRIMARY}
        size={ButtonSizeTypes.S}
        onClick={() => {
          analyticsService.event('Open Assessments Tab Button Click', {
            role: 'student',
          });
          assessmentSidebarContext.onOpen();
        }}
        shape={ButtonShapeTypes.ROUNDED}
        color={ButtonColorTypes.SECONDARY}
      >
        <QuizIcon />
      </IconButton>
      <AssessmentPaperSidebar
        open={assessmentSidebarContext.open}
        onClose={assessmentSidebarContext.onClose}
      />
      {children}
    </Box>
  );
};
