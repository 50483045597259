import { FC, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useDialog from '../../../common/hooks/useDialog';
import Notification from '../Notification';
import CrownOutlineIcon from '../../atoms/Icons/CrownOutlineIcon';
import { AlertColor } from '../Notification/notificationTypes';
import { NotificationButtonSize } from '../../atoms/NotificationButton/notificationButtonTypes';
import NotificationButton from '../../atoms/NotificationButton/NotificationButton';
import PromoCodeDialog from '../../../containers/Modals/PromoCodeDialog';
import { FactoryFunction } from './promoCodeNotificationProps';
import analyticsService from '../../../common/services/analytics.service';

type Props = {
  onSuccess: () => void;
};

const actionButtonFactory: FactoryFunction =
  (onActionButtonClick) =>
  ({ color, children }) => {
    const theme = useTheme();
    const showXsButton = useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <NotificationButton
        onClick={onActionButtonClick}
        size={
          showXsButton ? NotificationButtonSize.XS : NotificationButtonSize.XXS
        }
        hasIcon={false}
        color={color}
        sx={{
          minWidth: 0,
        }}
      >
        {children}
      </NotificationButton>
    );
  };

const PromoCodeNotification: FC<React.PropsWithChildren<Props>> = ({
  onSuccess,
}) => {
  const {
    open: promoCodeDialogOpen,
    onOpen: onPromoCodeDialogOpen,
    onClose: onPromoCodeDialogClose,
  } = useDialog();

  const onActionButtonClick = useCallback(() => {
    analyticsService.event('Dashboard Promo Notification Upgrade Clicked', {
      promoCode: '5HTZECUC',
    });
    onPromoCodeDialogOpen();
  }, [onPromoCodeDialogOpen]);

  const ActionButton = useMemo(
    () => actionButtonFactory(onActionButtonClick),
    [onActionButtonClick]
  );

  const onPromoClose = useCallback(() => {
    analyticsService.event('Promo Dialog Closed', {
      promoCode: '5HTZECUC',
    });
    onPromoCodeDialogClose();
  }, [onPromoCodeDialogClose]);

  return (
    <>
      <Notification
        Icon={CrownOutlineIcon}
        ActionButton={ActionButton}
        title={`Get 50% discount`}
        message={'Upgrade to Pro with 50% off for 12 months!'}
        color={AlertColor.PREMIUM}
        actionText="Upgrade"
      />
      {promoCodeDialogOpen && (
        <PromoCodeDialog
          open={promoCodeDialogOpen}
          onClose={onPromoClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default PromoCodeNotification;
