import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import fireBaseService from '../../../../common/services/firebaseService';
import { createSessionSelector } from '../../../../common/reducers/session';
import { userSelector } from '../../../../common/reducers/userReducer';
import ChatTabHeader from './Header/ChatTabHeader';
import Message from './Message/Message';
import ChatInput from './ChatInput/ChatInput';
import './ChatTab.scss';

const enhancer = connect(() => {
  const sessionSelector = createSessionSelector();
  return (state) => ({
    session: sessionSelector(state),
    user: userSelector(state),
  });
});

class ChatTab extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.opened && !this.props.opened) ||
      nextProps.session.messages.length > this.props.session.messages.length
    ) {
      this.shouldScrollToBottom = true;
    }
  }

  componentDidUpdate() {
    this.scrollIfNeeded();
  }

  shouldScrollToBottom = false;
  messageContainer = null;

  handleMessageSubmit = (text) => {
    fireBaseService.sendMessage(text);
  };

  scrollIfNeeded = () => {
    if (!this.shouldScrollToBottom) return;

    this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    this.shouldScrollToBottom = false;
  };

  showMessageInfo = (index) => {
    const { messages } = this.props.session;
    const current = messages[index];
    const previous = messages[index - 1];

    const avatar =
      index > 0
        ? index === messages.length - 1 ||
          (previous.senderId !== current.senderId &&
            current.senderId !== messages[index + 1].senderId) ||
          current.senderId !== messages[index + 1].senderId
        : index === messages.length - 1 ||
          current.senderId !== messages[index + 1].senderId;

    const time =
      index > 0
        ? index === messages.length - 1 ||
          (previous.senderId !== current.senderId &&
            current.senderId !== messages[index + 1].senderId) ||
          current.senderId !== messages[index + 1].senderId ||
          current.time - previous.time > 5 * 60 * 1000
        : index === messages.length - 1 ||
          current.senderId !== messages[index + 1].senderId;

    const senderName =
      index > 0
        ? messages[index - 1].senderId !== messages[index].senderId
        : true;

    return { avatar, time, senderName };
  };

  render() {
    const {
      opened,
      onClose,
      editable,
      user,
      session: { messages },
    } = this.props;

    const classes = clsx('chat-tab', {
      'chat-tab--opened': opened,
    });

    const messageContainerRef = (c) => {
      this.messageContainer = c;
    };

    const messagesContainerClassNames = clsx('messages-container', {
      'without-input': !editable,
    });

    return (
      <Box
        className={classes}
        sx={(theme) => ({
          backgroundColor: theme.background.bg1,
        })}
      >
        <ChatTabHeader onClose={onClose} />
        <div className={messagesContainerClassNames} ref={messageContainerRef}>
          {messages.map((message, index) => (
            <Message
              showInfo={this.showMessageInfo(index)}
              key={message.id}
              text={message.text}
              createdTime={message.createdTime}
              me={user.id === message.senderId}
              sender={message.sender}
              profileImage={message.profileImageUrl}
            />
          ))}
        </div>
        {editable && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            <ChatInput onSubmit={this.handleMessageSubmit} />
          </Box>
        )}
      </Box>
    );
  }
}

ChatTab.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default enhancer(ChatTab);
