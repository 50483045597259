import React from 'react';
import { connect } from 'react-redux';
import compose from '../../common/utils/compose.utils';
import Box from '@mui/material/Box';
import * as sessionReducer from '../../common/reducers/session/sessionReducer';
import * as uiActions from '../../common/actions/uiActions';
import { setDimensions } from '../../common/actions/session/sessionActions';
import { setLineCurrentDrawing } from '../../common/actions/board/currentDrawingAction';
import { getActivePage } from '../../common/reducers/board/activePageReducer';
import * as drawingActionsReducer from '../../common/reducers/board/drawingActionsReducer';
import * as uiReducer from '../../common/reducers/uiReducer';
import Canvas from './Canvas';
import { withCanvasContext } from './Contexts/CanvasContext';
import RecordingContext from '../Recording/RecordingContext';

const makeMapStateToProps = () => {
  const actionsSelector = drawingActionsReducer.createActionsSelector();
  return (state) => {
    const currentPage = getActivePage(state);
    return {
      dimensions: sessionReducer.dimensionsSelector(state),
      canDraw: sessionReducer.canCurrentUserDrawSelector(state),
      connected: sessionReducer.connectedSelector(state),
      actions: actionsSelector(state, currentPage.id),
      currentPage,
      dpi: uiReducer.dpiSelector(state),
    };
  };
};

const enhancer = compose(
  withCanvasContext,
  connect(
    makeMapStateToProps,
    {
      setUIBoardSizes: uiActions.setBoardSizes,
      setDimensions,
      setCurrentDrawing: setLineCurrentDrawing,
    },
  ),
);

class CanvasContainer extends React.PureComponent {
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dimensions !== this.props.dimensions) {
      const {
        dimensions,
        currentPage,
        setBoardSizes,
      } = nextProps;
      const {
        width,
        height,
        dpi,
      } = dimensions;
      if (currentPage.width && currentPage.height) {
        setBoardSizes(currentPage.width, currentPage.height, dpi);
      } else {
        setBoardSizes(width, height, dpi);
      }
    }

    if (
      nextProps.currentPage && this.props.currentPage &&
      nextProps.currentPage.id !== this.props.currentPage.id
    ) {
      if (nextProps.currentPage.width && nextProps.currentPage.height) {
        nextProps.setBoardSizes(nextProps.currentPage.width, nextProps.currentPage.height, nextProps.dimensions.dpi);
      } else {
        nextProps.setBoardSizes(nextProps.dimensions.width, nextProps.dimensions.height, nextProps.dimensions.dpi);
      }
      this.props.resetZoom();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  setContainerRef = (ref) => {
    this.containerRef = ref;
  };

  containerRef;

  updateDimensions = () => {
    if (!this.containerRef) return;
    const { width, height } = this.containerRef.getBoundingClientRect();
    const newState = {
      containerWidth: width,
      containerHeight: height,
    };

    this.props.setUIBoardSizes(width, height);
    this.props.setCanvasSizes(width, height);

    const isInitialUpdate = !this.props.connected && !this.props.canvasWidth;

    this.setState(newState, () => {
      if (isInitialUpdate) {
        this.props.setDimensions({ width, height, dpi: this.props.dpi });
      }
    });
  };

  render() {
    const {
      canDraw,
      actions,
      currentPage,
      canvasWidth,
      canvasHeight,
    } = this.props;

    return (
      <Box
        className="canvas-container"
        ref={this.setContainerRef}
      >
        <RecordingContext.Consumer>
          {
            ({ setFrontLayer, setBackgroundLayer, setInteractionLayer }) => (
              <Canvas
                width={canvasWidth}
                height={canvasHeight}
                actions={actions}
                page={currentPage}
                pointersLayerActive
                drawingEnabled={canDraw}
                uiLayerActive
                backgroundGridActive
                setFrontLayer={setFrontLayer}
                setBackgroundLayer={setBackgroundLayer}
                setInteractionLayer={setInteractionLayer}
              />
            )
          }
        </RecordingContext.Consumer>
      </Box>
    );
  }
}

export default enhancer(CanvasContainer);
