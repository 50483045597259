import React, { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import IconButton from '../../../ui/atoms/IconButton';
import sessionService from '../../../common/services/session.service';
import * as participantsReducer from '../../../common/reducers/session/participantsReducer';
import {
  addParticipant,
  removeParticipant,
} from '../../../common/actions/session/participantsActions';
import { sessionIdSelector } from '../../../common/reducers/session/sessionReducer';
import ParticipantDto from '../../../common/services/dto/firebase/ParticipantDto';
import { TypographyType } from '../../../ui/atoms/Typography/types/Typography';
import PencilOutlineIcon from '../../../ui/atoms/Icons/PencilOutlineIcon';
import UserPresenceAvatar from './UserPresenceAvatar';
import PresenceBadge from './PresenceBadge';
import { updateInvitedContacts } from '../../../common/actions/session/invitesActions';
import {
  invitedContactIdsSelector,
  invitedEmailsSelector,
} from '../../../common/reducers/session/invitesReducer';
import analyticsService from '../../../common/services/analytics.service';
import PencilOffOutlineIcon from '../../../ui/atoms/Icons/PencilOffOutlineIcon';
import Tooltip from '../../../ui/atoms/Tooltip';

type UserPresenceProps = {
  id: string;
  name: string;
  statusColor?: string;
  statusText?: string;
  profileImageUrl: string;
  collapsed: boolean;
  hasDrawingPermission: boolean;
  canChangeDrawingPermission: boolean;
  updateDrawingPermission: (id: string, hasDrawingPermission: boolean) => void;
  showPlansModal: () => void;
  userType: 'host' | 'joined' | 'invited';
};

const UserPresence: FC<React.PropsWithChildren<UserPresenceProps>> = ({
  id,
  name,
  statusColor,
  statusText = '',
  profileImageUrl,
  collapsed,
  hasDrawingPermission,
  canChangeDrawingPermission,
  updateDrawingPermission,
  showPlansModal,
  userType,
}) => {
  const sessionId = useSelector(sessionIdSelector);
  const invitedEmails = useSelector(invitedEmailsSelector);
  const invitedContactsIds = useSelector(invitedContactIdsSelector);

  const participants = useSelector(participantsReducer.participantsSelector);
  const dispatch = useDispatch();

  const onChangeDrawingPermissionClick = useCallback(() => {
    analyticsService.event(
      hasDrawingPermission
        ? 'Disable Drawing Permission'
        : 'Enable Drawing Permission',
      {
        source: 'Collaboration',
      }
    );

    if (canChangeDrawingPermission) {
      updateDrawingPermission(id, !hasDrawingPermission);
    } else {
      showPlansModal();
    }
  }, [
    id,
    hasDrawingPermission,
    updateDrawingPermission,
    canChangeDrawingPermission,
  ]);

  const onRemoveInvitedClick = useCallback(async () => {
    analyticsService.event('Remove Invited User', {
      source: 'Collaboration',
    });

    const oldContactIds = [...invitedContactsIds];
    const oldEmails = [...invitedEmails];

    const filteredContactIds = invitedContactsIds.filter(
      (contactId: string) => contactId !== id
    );
    const filteredEmails = invitedEmails.filter(
      (email: string) => email !== name
    );

    dispatch(
      updateInvitedContacts({
        contacts: filteredContactIds,
        emails: filteredEmails,
      })
    );

    try {
      if (id) {
        await sessionService.removeInvitesToSession(sessionId, [id], []);

        return;
      }

      await sessionService.removeInvitesToSession(sessionId, [], [name]);
    } catch (error) {
      dispatch(
        updateInvitedContacts({
          contacts: oldContactIds,
          emails: oldEmails,
        })
      );
    }
  }, [invitedContactsIds, invitedEmails, name]);

  const onRemoveJoinedClick = useCallback(async () => {
    analyticsService.event('Remove Joined User', {
      source: 'Collaboration',
    });
    const participant = participants.find(
      (participant: ParticipantDto) => participant.id === id
    );
    dispatch(removeParticipant(id));

    try {
      await sessionService.removeInvitesToSession(sessionId, [id], []);
    } catch (error) {
      dispatch(addParticipant(participant));
    }
  }, [participants, id, dispatch, sessionId]);

  const actionsData = useMemo(() => {
    if (userType === 'joined') {
      return [
        {
          Icon: hasDrawingPermission ? PencilOutlineIcon : PencilOffOutlineIcon,
          onClick: onChangeDrawingPermissionClick,
          hasDrawingPermission,
          tooltipText: hasDrawingPermission
            ? 'Disable Drawing'
            : 'Enable Drawing',
        },
        {
          Icon: CloseIcon,
          onClick: onRemoveJoinedClick,
          tooltipText: 'Remove Participant',
        },
      ];
    }

    if (userType === 'invited') {
      return [
        {
          Icon: CloseIcon,
          onClick: onRemoveInvitedClick,
          tooltipText: 'Remove Participant',
        },
      ];
    }

    return [];
  }, [
    onRemoveInvitedClick,
    onRemoveJoinedClick,
    userType,
    onChangeDrawingPermissionClick,
    hasDrawingPermission,
  ]);

  return (
    <ListItem
      sx={{
        padding: 0,
        gap: 1,
        justifyContent: collapsed ? 'center' : 'flex-start',
      }}
    >
      {collapsed && userType !== 'host' ? (
        <PresenceBadge
          active={hasDrawingPermission}
          onClick={onChangeDrawingPermissionClick}
        >
          <UserPresenceAvatar
            src={profileImageUrl}
            alt={name}
            color={statusColor}
          />
        </PresenceBadge>
      ) : (
        <UserPresenceAvatar
          src={profileImageUrl}
          alt={name}
          color={statusColor}
        />
      )}
      {!collapsed && (
        <>
          <ListItemText
            primary={name}
            secondary={statusText}
            primaryTypographyProps={{
              color: '#2B2C51',
              variant: TypographyType.p5,
            }}
            secondaryTypographyProps={{
              color: '#7E8E99',
              variant: TypographyType.p6,
            }}
            sx={{
              [`& .${listItemTextClasses.primary}, & .${listItemTextClasses.secondary}`]:
                {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
            }}
          />
          {!!actionsData.length && (
            <Box display="flex" gap={0.25}>
              {actionsData.map(
                (
                  { Icon, onClick, hasDrawingPermission, tooltipText },
                  index
                ) => (
                  <Tooltip key={index} title={tooltipText} placement="bottom">
                    <IconButton
                      onClick={onClick}
                      size={ButtonSizeTypes.S}
                      variant={ButtonVariantTypes.TEXT}
                      color={ButtonColorTypes.PRIMARY}
                    >
                      <Icon
                        color={hasDrawingPermission ? 'primary' : 'inherit'}
                      />
                    </IconButton>
                  </Tooltip>
                )
              )}
            </Box>
          )}
        </>
      )}
    </ListItem>
  );
};

export default memo(UserPresence);
