import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import AnswerOptionDto from '../dto/AnswerOptionDto';
import FirebaseOptionDto from '../dto/FirebaseOptionDto';

type Props = {
  options: any[],
  setSelectedValue: (
    value: AnswerOptionDto[],
  ) => void,
};

const MultiChoiceAnswer = ({
  options,
  setSelectedValue,
}: Props) => {
  const [state, setState] = useState(options.reduce(
    (acc, option) => ({ ...acc, [option.id]: false }),
    {},
  ));

  const handleChange = (event: any) => {
    const checkedOptions = { ...state, [event.target.name]: event.target.checked };

    setState(checkedOptions);
    setSelectedValue(
      Object.keys(checkedOptions)
        .filter((optionId: string) => checkedOptions[optionId])
        .map((optionId: string) => ({ optionId })),
    );
  };

  return (
    <Box display="flex">
      <FormControl component="fieldset">
        <FormGroup>
          {
            options.map((option: FirebaseOptionDto) => (
              <FormControlLabel
                key={option.id}
                control={(
                  <Checkbox
                    color="primary"
                    checked={state[option.id]}
                    onChange={handleChange}
                    name={option.id}
                  />
                  )
                }
                label={option.text}
              />
            ))
          }
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default MultiChoiceAnswer;
