import { some } from 'lodash';
import contactsService from '../services/contacts.service';

const GET_CONTACT_LIST_REQUEST = Symbol('GET_CONTACT_LIST_REQUEST');
const GET_CONTACT_LIST_SUCCESS = Symbol('GET_CONTACT_LIST_SUCCESS');
const GET_CONTACT_LIST_FAILURE = Symbol('GET_CONTACT_LIST_FAILURE');

const GET_CONTACT_REQUEST = Symbol('GET_CONTACT_REQUEST');
const GET_CONTACT_SUCCESS = Symbol('GET_CONTACT_SUCCESS');
const GET_CONTACT_FAILURE = Symbol('GET_CONTACT_FAILURE');

const CREATE_CONTACT_REQUEST = Symbol('CREATE_CONTACT_REQUEST');
const CREATE_CONTACT_SUCCESS = Symbol('CREATE_CONTACT_SUCCESS');
const CREATE_CONTACT_FAILURE = Symbol('CREATE_CONTACT_FAILURE');

const DELETE_CONTACT_REQUEST = Symbol('DELETE_CONTACT_REQUEST');
const DELETE_CONTACT_SUCCESS = Symbol('DELETE_CONTACT_SUCCESS');
const DELETE_CONTACT_FAILURE = Symbol('DELETE_CONTACT_FAILURE');

// ------------------- Get contact list actions -------------------
const contactListRequest = () => ({
  type: GET_CONTACT_LIST_REQUEST,
});

const contactListSuccess = (payload) => ({
  type: GET_CONTACT_LIST_SUCCESS,
  payload,
});

const contactListFailure = (error) => ({
  type: GET_CONTACT_LIST_FAILURE,
  error,
});

const getContactList = () => {
  return (dispatch) => {
    dispatch(contactListRequest());

    return contactsService
      .getContactList()
      .then((payload) => {
        dispatch(contactListSuccess(payload));
        return payload;
      })
      .catch((error) => {
        dispatch(contactListFailure(error));
        console.log('Error: ', error);
      });
  };
};

// ------------------- Get contact list actions -------------------

// ------------------- Get contact actions -------------------
const contactRequest = () => ({
  type: GET_CONTACT_REQUEST,
});

const contactSuccess = (payload) => ({
  type: GET_CONTACT_SUCCESS,
  payload,
});

const contactFailure = (error) => ({
  type: GET_CONTACT_FAILURE,
  error,
});

const getContact = (id) => {
  return (dispatch) => {
    dispatch(contactRequest());

    return contactsService
      .getContact(id)
      .then((payload) => {
        dispatch(contactSuccess(payload));
        return payload;
      })
      .catch((error) => {
        dispatch(contactFailure(error));
        console.log('Error: ', error);
      });
  };
};

// ------------------- Get contact actions -------------------

// ------------------- Create contact actions -------------------
const createContactRequest = () => ({
  type: CREATE_CONTACT_REQUEST,
});

const createContactSuccess = (payload) => ({
  type: CREATE_CONTACT_SUCCESS,
  payload,
});

const createContactFailure = (error) => ({
  type: CREATE_CONTACT_FAILURE,
  error,
});

const createContact = (email) => {
  return (dispatch) => {
    dispatch(createContactRequest());

    return contactsService
      .createContact(email)
      .then((payload) => {
        dispatch(createContactSuccess(payload));
        dispatch(getContactList());
        return payload;
      })
      .catch((error) => {
        dispatch(createContactFailure(error));
        console.log('Error: ', error);
      });
  };
};

// ------------------- Create contact actions -------------------

// ------------------- Delete contact actions -------------------
const deleteContactRequest = () => ({
  type: DELETE_CONTACT_REQUEST,
});

const deleteContactSuccess = (payload) => ({
  type: DELETE_CONTACT_SUCCESS,
  payload,
});

const deleteContactFailure = (error) => ({
  type: DELETE_CONTACT_FAILURE,
  error,
});

const deleteContact = (id) => {
  return (dispatch) => {
    dispatch(deleteContactRequest());

    return contactsService
      .deleteContact(id)
      .then((payload) => {
        dispatch(deleteContactSuccess(payload));
        return payload;
      })
      .catch((error) => {
        dispatch(deleteContactFailure(error));
        console.log('Error: ', error);
      });
  };
};

const invalidateIfNeeded = (newContacts) => {
  return (dispatch, getState) => {
    const state = getState();
    const contacts = state.contacts.hash;
    const contactsNeedRefresh = some(newContacts, (id) => !contacts[id]);
    if (contactsNeedRefresh) {
      dispatch(getContactList());
    }
  };
};

// ------------------- Delete contact actions -------------------
export {
  getContactList,
  getContact,
  createContact,
  deleteContact,
  invalidateIfNeeded,
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
};
