import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { push } from 'redux-first-history';
import Box from '@mui/material/Box';
import Button from '../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../ui/atoms/Button/buttonTypes';
import Input from '../../ui/atoms/Input';
import InputVariant from '../../ui/atoms/Input/InputVariant';
import InputSize from '../../ui/atoms/Input/InputSize';
import InputStatus from '../../ui/atoms/Input/InputStatus';
import AccountOutlineIcon from '../../ui/atoms/Icons/AccountOutlineIcon';
import authService from '../../common/services/auth.service';
import { setOpenSource } from '../../common/actions/session/sessionActions';
import { saveUserAction } from '../../common/actions/authActions';
import Typography from '../../ui/atoms/Typography';
import { TypographyType } from '../../ui/atoms/Typography/types/Typography';
import analyticsService from '../../common/services/analytics.service';

type PublicLinkRegistrationFormProps = {
  code: string;
};

const PublicLinkRegistrationForm: FC<
  React.PropsWithChildren<PublicLinkRegistrationFormProps>
> = ({ code }) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const onSubmit = useCallback(
    handleSubmit(async ({ name }) => {
      if (!code) return;
      analyticsService.event(
        'Public Link Registration Name Submit Button Click'
      );

      try {
        const { accessToken, user, sessionId } =
          await authService.authWithPublicLink(code, name);

        dispatch(setOpenSource('Join: code'));
        dispatch(
          push({
            pathname: `/session/${sessionId}`,
            state: { referrer: location.pathname },
          })
        );
        dispatch(saveUserAction(user, accessToken));
      } catch (error) {
        if (error && error.message) {
          const { message } = error;

          if (message === 'No such session!') {
            setError('Wrong session code.');
          }
        }
      }
    }),
    [handleSubmit, code, dispatch, setError]
  );

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" gap={2}>
        <Controller
          name="name"
          control={control}
          render={({ field, formState: { errors } }) => (
            <Input
              {...field}
              variant={InputVariant.CONTAINED}
              size={InputSize.S}
              placeholder="Enter Name"
              icon={<AccountOutlineIcon />}
              status={errors.name ? InputStatus.ERROR : undefined}
              helperText={errors.name ? errors.name.message : undefined}
            />
          )}
        />
        <Box>
          <Button
            type="submit"
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.S}
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Box mt={1.75} display="flex" justifyContent="center">
        {error && (
          <Typography variant={TypographyType.p5} color="error.main">
            {error}
          </Typography>
        )}
      </Box>
    </form>
  );
};

export default PublicLinkRegistrationForm;
