import { FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '../../atoms/Checkbox';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import Typography from '../../atoms/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import TrashCanOutlineIcon from '../../atoms/Icons/TrashCanOutlineIcon';

type ClearPageWarningDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (dontShowAgain: boolean) => void;
};

const ClearPageWarningDialog: FC<
  React.PropsWithChildren<ClearPageWarningDialogProps>
> = ({ open, onClose, onSubmit }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = useCallback(() => {
    setChecked((prevState) => !prevState);
  }, [setChecked]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        icon={<TrashCanOutlineIcon color="error" />}
        onClose={onClose}
      >
        Confirm clearing page
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={TypographyType.p5}
          sx={(theme) => ({
            color: theme.text.t8,
          })}
        >
          Clearing will remove the whole data on the page. This action cannot be
          undone. Do you want to proceed?
        </Typography>
        <Box display="flex" gap={1.25} mt={3} alignItems="center">
          <FormControlLabel
            sx={{
              [`&.${formControlLabelClasses.root}`]: {
                marginLeft: '-4px',
              },
            }}
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={
              <Typography variant={TypographyType.p5}>
                Don&apos;t show this again
              </Typography>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton
          onClick={() => onSubmit(checked)}
          color="error"
        >
          Clear
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClearPageWarningDialog;
