import MATH_SRC from '../includes/math';

const FRAG_SRC = `#version 300 es
precision mediump float;

in vec2 worldPos;

uniform vec2 resolution; // TODO Should be a UBO
uniform float scale;
uniform vec3 color;

out vec4 out_color;

////////////////////////////////////////////////////////////////////////

// Thick lines
float grid(vec2 fragCoord, float space, float dotRadius) {
    vec2 p = fragCoord;
    vec2 size = vec2(dotRadius + 1.0);

    vec2 a = mod(p + size, space);

    return clamp(abs(length(a - size)) - dotRadius, 0.0, 1.0);
}

// Include missing math functions
${MATH_SRC}

////////////////////////////////////////////////////////////////////////

void main() {
  float inFirstSegment = step(0.0, scale) * step(scale, 0.40);
  float inSecondSegment = step(0.400001, scale) * step(scale, 0.80);
  float inThirdSegment = step(0.800001, scale) * step(scale, 4.0);

  float mappedFirstScale = map(scale, 0.0, 0.40, 0.0, 1.0);
  float mappedSecondScale = map(scale, 0.40, 0.80, 0.0, 1.0);
  float mappedThirdScale = map(scale, 0.80, 4.0, 0.0, 1.0);

  float space = inFirstSegment * 100.0 + inSecondSegment * 50.0 + inThirdSegment * 25.0;

  float radius = inFirstSegment * mix(4.0, 2.5, mappedFirstScale) +
                 inSecondSegment * mix(3.0, 2.0, mappedSecondScale) +
                 inThirdSegment * mix(1.0, 0.5, mappedThirdScale);

  float grad = grid(worldPos, space, radius);

  out_color.a = 1.0;
  out_color.rgb = mix(
    color,
    vec3(0.765, 0.765, 0.765),
    1.0 - grad
  );
}`;

export default FRAG_SRC;
