import axios from 'axios';
import { httpService } from './http.service';
import SessionRecordingListPayload from './types/recording/sessionRecordingType';
import UserListData from '../types/userListData';

const apiRecordingPart = '/recording';

const getRecordings = async ({ groupId }: { groupId: string }) => {
  const params = new URLSearchParams();
  if (groupId !== 'default') {
    params.append('groupId', groupId);
  }

  return httpService.get<
    SessionRecordingListPayload[],
    SessionRecordingListPayload[]
  >(`${apiRecordingPart}`, { params });
};

const getRecordingUrl = async (id: string) =>
  httpService.get<string, string>(`${apiRecordingPart}/${id}/url`);

const createRecording = async ({
  fileName,
  sessionId,
  groupId,
  blob,
  onProgress,
}: any) => {
  const { uploadUrl, ...recording }: any = await httpService.post(
    apiRecordingPart,
    {
      fileName,
      sessionId,
      groupId,
    }
  );

  await axios.put(uploadUrl, blob, {
    headers: {
      'Content-Type': blob.type.split(';')[0],
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = (progressEvent.progress ?? 0) * 100;
      onProgress({ percentCompleted });
    },
  });

  await httpService.put(`${apiRecordingPart}/${recording.id}/uploaded`, {
    uploaded: true,
  });

  return recording;
};

const shareInGroup = async (id: any, shared: any) =>
  httpService.post(`${apiRecordingPart}/${id}/share-in-group`, { shared });

const moveToGroup = async (id: any, groupId: any) =>
  httpService.put(`${apiRecordingPart}/${id}/groupId`, { groupId });

const rename = async (id: any, name: any) =>
  httpService.put(`${apiRecordingPart}/${id}/name`, { name });

const deleteRecording = async (id: any) =>
  httpService.delete(`${apiRecordingPart}/${id}`);

const copy = async (id: any) =>
  httpService.post(`${apiRecordingPart}/${id}/copy`);

const shareWithUsers = async (
  id: any,
  addContactIds: any,
  removeContactIds: any
) =>
  httpService.post(`${apiRecordingPart}/${id}/share-with-users`, {
    addContactIds,
    removeContactIds,
  });

const getUsersSharedWith = async (id: any) =>
  httpService.get<UserListData[], UserListData[]>(
    `${apiRecordingPart}/${id}/users`
  );

export default {
  getRecordings,
  getRecordingUrl,
  createRecording,
  shareInGroup,
  moveToGroup,
  rename,
  deleteRecording,
  copy,
  shareWithUsers,
  getUsersSharedWith,
};
