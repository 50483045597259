import { ReactNode, FC } from 'react';
import LiveKitProvider from '../../CollaborationTools/Manager/LiveKitManager/context/LiveKitProvider';

type Props = {
  children: ReactNode;
};

const CallProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => (
  <LiveKitProvider>{children}</LiveKitProvider>
);

export default CallProvider;
