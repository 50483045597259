import React, { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Button from '../../../../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import Avatar from '../../../../../../ui/atoms/Avatar';
import {
  AvatarBorderTypes,
  AvatarSizeTypes,
} from '../../../../../../ui/atoms/Avatar/types';
import TrayArrowUpIcon from '../../../../../../ui/atoms/Icons/TrayArrowUpIcon';
import * as userReducer from '../../../../../../common/reducers/userReducer';
import { updateProfilePicture } from '../../../../../../common/actions/userActions';
import imageUtils from '../../../../../../common/utils/image.utils';
import fileUtils from '../../../../../../common/utils/file.utils';

const profileImageMaxDimensions = {
  maxWidth: 320,
  maxHeight: 320,
} as const;

export const UpdateProfileImage: FC<React.PropsWithChildren<unknown>> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const user = useSelector(userReducer.userSelector);
  const dispatch = useDispatch();

  const isProfileImageFetching = useSelector(
    (state: any) => state.user.updateProfileImage.isFetching
  );

  const editProfilePicture = useCallback(
    (file: any) => dispatch(updateProfilePicture(file)),
    [dispatch]
  );

  const onFileSelect = useCallback(
    async (e: any) => {
      const file = e.target.files[0];
      if (!file) return;

      const result = await fileUtils.checkMimeTypeInList(file, ['jpeg', 'png']);
      const fileName = file.name;
      e.target.value = null;

      if (!result || !fileName.match(/\.(jpe?g|png)$/gi)) {
        enqueueSnackbar(
          'Failed to update profile image. Only files with following extensions are allowed: .png, .jpg, .jpeg',
          {
            autoHideDuration: 3000,
            variant: 'error',
          }
        );
        return;
      }

      const resizedImage = await imageUtils.resizeImage(
        file,
        profileImageMaxDimensions
      );
      editProfilePicture(resizedImage);
    },
    [enqueueSnackbar, editProfilePicture]
  );

  const onClick = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.click();
  }, []);

  return (
    <Stack direction="row" gap={2} mb={2} py="10px">
      <Stack position="relative">
        <Avatar
          border={AvatarBorderTypes.ACTIVE}
          size={AvatarSizeTypes.XL}
          src={user.profileImageUrl}
          alt={user.name}
        />
        {isProfileImageFetching && (
          <Stack
            position="absolute"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '50%',
              backgroundColor: '#33333333',
            }}
          >
            <CircularProgress variant="indeterminate" />
          </Stack>
        )}
      </Stack>
      <Stack justifyContent="center">
        <Button
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          onClick={onClick}
          startIcon={<TrayArrowUpIcon />}
          disabled={isProfileImageFetching}
        >
          Upload
        </Button>
        <input
          type="file"
          accept="image/png,image/jpeg"
          style={{ display: 'none' }}
          onChange={onFileSelect}
          ref={inputRef}
        />
      </Stack>
    </Stack>
  );
};
