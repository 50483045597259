import DomUtils from './dom.util';

const isRightMouseEvent = e => e.which === 3;
const isLeftMouseEvent = e => e.which === 1;
const mouseEventToCoordinates = evt => ({
  x: evt.layerX,
  y: evt.layerY,
});
const touchEventToCoordinates = (evt) => {
  evt.preventDefault();
  const [touch] = evt.touches;
  const { pageX, pageY } = touch;
  const targetPosition = DomUtils.offset(touch.target);
  return {
    x: (pageX - targetPosition.x),
    y: (pageY - targetPosition.y),
  };
};

export default {
  isRightMouseEvent,
  isLeftMouseEvent,
  mouseEventToCoordinates,
  touchEventToCoordinates,
};
