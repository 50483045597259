import { FC, useCallback, useEffect, useState } from 'react';
import { round } from 'lodash';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '../../atoms/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import * as sessionPdfService from '../../../common/services/sessionPdf.service';
import usePolling from '../../../common/hooks/usePolling';
import SessionPdfDto from '../../../common/services/dto/sessionPdf/sessionPdf';
import DownloadPdfIcon from '../../atoms/Icons/DownloadPdfIcon';
import axios from 'axios';

type Props = {
  open: boolean;
  onCancel: () => void;
  card: any;
};

const IconWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '32px',
  width: '40px',
  height: '40px',
  backgroundColor: theme.palette.primary.alpha10,
  padding: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ExportPdfDialog: FC<React.PropsWithChildren<Props>> = ({
  card,
  open,
  onCancel,
}) => {
  const [sessionPdfId, setSessionPdfId] = useState<string | null>(null);
  const [sessionPdf, setSessionPdf] = useState<SessionPdfDto | null>(null);
  const [progress, setProgress] = useState(0);

  const downloadLink = useCallback(
    async (url: any, name: any) => {
      const { data } = await axios.get(url, { responseType: 'blob' });
      var blobURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = blobURL;
      link.target = '_blank';
      link.setAttribute('download', `${name}.pdf`);
      link.setAttribute('rel', 'noopener noreferrer');

      document.body.appendChild(link);

      link.click();
      link.parentNode?.removeChild(link);
      onCancel();
    },
    [onCancel]
  );

  const fetchFunc = useCallback(() => {
    if (!sessionPdfId)
      return Promise.reject(new Error('session pdf id not defined'));

    return sessionPdfService.getSessionPdf(sessionPdfId);
  }, [sessionPdfId]);

  const [isPolling, startPolling, stopPolling] = usePolling({
    fetchFunc,
    interval: 2000,
    onSuccess: (data: SessionPdfDto | null) => {
      if (!data) return null;

      setSessionPdf(data);
      setProgress(round(data.progress * 100, 0));
      if (data.url) {
        downloadLink(data.url, data.name);
      }

      return data.status !== 'finished';
    },
  });

  const onEnter = useCallback(async () => {
    setProgress(0);
    const data = await sessionPdfService.createSessionPdf(card.id);

    setSessionPdfId(data.id);
    setProgress(round(data.progress * 100, 0));
    setSessionPdf(data);
  }, [card, setProgress, setSessionPdfId, setSessionPdf]);

  const onExit = useCallback(async () => {
    if (isPolling) {
      stopPolling();
    }
  }, [isPolling, stopPolling]);

  useEffect(() => {
    if (sessionPdfId) {
      startPolling();
    }
  }, [sessionPdfId]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      TransitionProps={{
        onEnter,
        onExit,
      }}
    >
      <DialogTitle hasCloseButton={false}>Export PDF</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <IconWrapper>
            <DownloadPdfIcon color="primary" />
          </IconWrapper>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexWrap="nowrap">
              <Typography variant="b3">
                {progress}
                {' %'}
              </Typography>
            </Box>
            <Typography
              variant="p4"
              sx={(theme) => ({
                color: theme.text.t7,
              })}
            >
              Your board is being exported. Please wait.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box pr={1} display="flex" flex={1}>
            <Box width={1}>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="primary"
                sx={{
                  borderRadius: '4px',
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onCancel}>
          {sessionPdf && sessionPdf.url ? 'Close' : 'Cancel'}
        </DialogActionSecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportPdfDialog;
