import { ButtonProps, buttonClasses } from '@mui/material/Button';
import { ButtonSizeTypes } from './buttonTypes';
import {
  buttonLStyle,
  buttonMStyle,
  buttonSStyle,
  buttonXlStyle,
  buttonXSStyle,
  buttonXXSStyle,
} from './buttonStyles';
import { DashboardThemeType } from '../../LiveBoardTheme/themeType';
import generateButtonStyle from './generateButtonStyle';

const buttonThemeConfig = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    variants: [
      {
        props: { size: ButtonSizeTypes.XL },
        style: {
          ...buttonXlStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.L },
        style: {
          ...buttonLStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.M },
        style: {
          ...buttonMStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.S },
        style: {
          ...buttonSStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.XS },
        style: {
          ...buttonXSStyle,
        },
      },
      {
        props: { size: ButtonSizeTypes.XXS },
        style: {
          ...buttonXXSStyle,
        },
      },
    ],
    styleOverrides: {
      root: ({
        ownerState,
        theme,
      }: {
        ownerState: ButtonProps;
        theme: DashboardThemeType;
      }) => ({
        fontFamily: 'Barlow',
        textTransform: 'none',
        letterSpacing: 'unset',
        boxShadow: 'none',
        webkitTransition: 'none',
        verticalAlign: 'unset',
        position: 'unset',
        border: '2px solid',
        [`& .${buttonClasses.startIcon}`]: {
          marginRight: '4px',
        },
        ...generateButtonStyle(ownerState, theme),
      }),
    },
  },
};

export default buttonThemeConfig;
