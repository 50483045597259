import { useCallback, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ParticipantDto from '../../../../../common/services/dto/firebase/ParticipantDto';
import GradeType from '../../types/GradeType';

const textByGrade = {
  [GradeType.CORRECT]: 'Correct',
  [GradeType.PARTIALLY_CORRECT]: 'Partially correct',
  [GradeType.INCORRECT]: 'Incorrect',
  [GradeType.NO_ANSWER]: 'No answer',
} as const;

const colorByGrade = {
  [GradeType.CORRECT]: '#1EB270',
  [GradeType.PARTIALLY_CORRECT]: '#F57C00',
  [GradeType.INCORRECT]: '#F44336',
  [GradeType.NO_ANSWER]: '#000000',
} as const;

type Props = {
  questionId: string;
  participant: ParticipantDto;
  grade: GradeType;
  answers?: {
    id: string;
    text: string;
    isCorrect: boolean;
    isSelected: boolean;
  }[];
};

const AnswersItem = ({ questionId, participant, grade, answers }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onChange = useCallback(
    (event: any, expanded: any) => {
      setIsExpanded(expanded);
    },
    [setIsExpanded]
  );

  useEffect(() => {
    setIsExpanded(false);
  }, [questionId, setIsExpanded]);

  return (
    <Accordion expanded={isExpanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={grade !== GradeType.NO_ANSWER ? <ExpandMoreIcon /> : null}
        className="m-0"
        disabled={grade === GradeType.NO_ANSWER}
      >
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Avatar src={participant.profileImageUrl} alt={participant.name} />
            <Box pl={1}>
              <Typography>{participant.name}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ color: colorByGrade[grade] }}>
              {textByGrade[grade]}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      {grade !== GradeType.NO_ANSWER && (
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            {answers?.map((answer) => (
              <Box key={answer.id} display="flex" py={0.5}>
                <Box visibility={answer.isSelected ? 'visible' : 'hidden'}>
                  <DoneIcon />
                </Box>
                <Box pl={1}>
                  <Typography
                    color={answer.isCorrect ? 'primary' : 'inherit'}
                    className={answer.isCorrect ? 'text-primary' : 'inherit'}
                  >
                    {answer.text}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default AnswersItem;
