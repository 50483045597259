import { cloneDeep } from 'lodash';
import DrawingAction from './drawing.action';
import guid from '../utils/guid.util';
import numberUtil from '../utils/number.util';


export default class TextDrawingAction extends DrawingAction {
  text = '';
  startPoint = null;
  width = 0;
  height = 0;

  constructor(deserialized) {
    super(deserialized);

    this.name = 'text';

    if (deserialized) {
      this.text = deserialized.t;
      this.startPoint = { x: deserialized.x1, y: deserialized.y1 };
      this.width = deserialized.tw;
      this.height = deserialized.th;
    }
  }

  createMoveAction() {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;
    newAction.startPoint = { ...this.startPoint };
    return newAction;
  }

  move({ deltaX, deltaY }) {
    this.startPoint = {
      x: this.startPoint.x + deltaX,
      y: this.startPoint.y + deltaY,
    };
  }


  getText() {
    return this.text;
  }

  getStartPoint() {
    return this.startPoint;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    return this.height;
  }

  serialize() {
    const startPoint = this.getStartPoint();
    const width = this.getWidth();
    const height = this.getHeight();
    return {
      ...super.serialize(),
      t: this.getText(),
      x1: numberUtil.toOneDecimal(startPoint.x),
      y1: numberUtil.toOneDecimal(startPoint.y),
      tw: numberUtil.toOneDecimal(width),
      th: numberUtil.toOneDecimal(height),
    };
  }

  intersect(point) {
    return (
      this.startPoint.x < point.x && this.startPoint.x + this.getWidth() > point.x &&
      this.startPoint.y < point.y && this.startPoint.y + this.getHeight() > point.y
    );
  }
}
