import React from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { ConfirmOptions } from './ConfirmOptions';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';

type ConfirmationDialogProps = {
  open: boolean;
  options: ConfirmOptions;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmationDialog: React.FC<
  React.PropsWithChildren<ConfirmationDialogProps>
> = ({ open, options, onCancel, onConfirm, onClose }) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    confirmationKeyword,
    confirmationKeywordTextFieldProps,
    hideCancelButton,
    buttonOrder,
  } = options;

  const [confirmationKeywordValue, setConfirmationKeywordValue] =
    React.useState('');

  const confirmationButtonDisabled = Boolean(
    confirmationKeyword && confirmationKeywordValue !== confirmationKeyword
  );

  const confirmationContent = (
    <>
      {confirmationKeyword && (
        <TextField
          onChange={(e) => setConfirmationKeywordValue(e.target.value)}
          value={confirmationKeywordValue}
          fullWidth
          {...confirmationKeywordTextFieldProps}
        />
      )}
    </>
  );

  const dialogActions = buttonOrder!.map((buttonType) => {
    if (buttonType === 'cancel') {
      return (
        !hideCancelButton && (
          <DialogActionSecondaryButton
            key="cancel"
            {...cancellationButtonProps}
            onClick={onCancel}
          >
            {cancellationText}
          </DialogActionSecondaryButton>
        )
      );
    }

    if (buttonType === 'confirm') {
      return (
        <DialogActionPrimaryButton
          key="confirm"
          color="primary"
          disabled={confirmationButtonDisabled}
          {...confirmationButtonProps}
          onClick={onConfirm}
        >
          {confirmationText}
        </DialogActionPrimaryButton>
      );
    }

    throw new Error(
      `Supported button types are only "confirm" and "cancel", got: ${buttonType}`
    );
  });

  return (
    <Dialog
      open={open}
      {...dialogProps}
      onClose={allowClose ? onClose : () => {}}
    >
      {title && (
        <DialogTitle hasCloseButton={false} {...titleProps}>
          {title}
        </DialogTitle>
      )}
      {content ? (
        <DialogContent {...contentProps}>
          {content}
          {confirmationContent}
        </DialogContent>
      ) : description ? (
        <DialogContent {...contentProps}>
          <DialogContentText>{description}</DialogContentText>
          {confirmationContent}
        </DialogContent>
      ) : (
        confirmationKeyword && (
          <DialogContent {...contentProps}>{confirmationContent}</DialogContent>
        )
      )}
      <DialogActions {...dialogActionsProps}>{dialogActions}</DialogActions>
    </Dialog>
  );
};
