import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { buttonClasses } from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '../../../../ui/atoms/IconButton';
import * as sessionReducer from '../../../../common/reducers/session/sessionReducer';
import {
  finishedSelector,
  pagesCountLimitSelector,
  pagesCountSelector,
  sessionIdSelector,
} from '../../../../common/reducers/session/sessionReducer';
import analyticsService from '../../../../common/services/analytics.service';
import * as pagesActions from '../../../../common/actions/board/pagesActions';
import { getActualPageOrders } from '../../../../common/reducers/board/pagesReducer';
import * as activePageActions from '../../../../common/actions/board/activePageActions';
import { setActivePage } from '../../../../common/actions/board/activePageActions';
import { getActivePageId } from '../../../../common/reducers/board/activePageReducer';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../ui/atoms/Button/buttonTypes';
import ChevronLeftIcon from '../../../../ui/atoms/Icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../../ui/atoms/Icons/ChevronRightIcon';
import PlusIcon from '../../../../ui/atoms/Icons/PlusIcon';
import generateButtonStyle from '../../../../ui/atoms/Button/generateButtonStyle';

type Props = {
  onClick: () => void;
};

const StyledButton = styled('button')(({ theme }) => ({
  ...generateButtonStyle(
    {
      variant: ButtonVariantTypes.TEXT,
    },
    theme as any
  ),
  minWidth: '124px',
  height: '40px',
  fontFamily: 'Barlow',
  textTransform: 'none',
  letterSpacing: 'unset',
  boxShadow: 'none',
  webkitTransition: 'none',
  verticalAlign: 'unset',
  position: 'unset',
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: '4px',
  },
  padding: '8px 14px',
  borderRadius: '14px',
  ...theme.typography.b4,
}));

const PagesStepper: FC<React.PropsWithChildren<Props>> = ({ onClick }) => {
  const activePageId = useSelector(getActivePageId);
  const pagesCount = useSelector(pagesCountSelector);
  const pagesCountLimit = useSelector(pagesCountLimitSelector);
  const pageOrders = useSelector(getActualPageOrders);
  const finished = useSelector(finishedSelector);
  const sessionId = useSelector(sessionIdSelector);
  const isHost = useSelector(sessionReducer.isCurrentUserHostSelector);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const activePageIndex = useMemo(
    () => pageOrders.indexOf(activePageId),
    [pageOrders, activePageId]
  );
  const isLastPage = useMemo(
    () => activePageIndex === pagesCount - 1,
    [activePageIndex, pagesCount]
  );

  const debouncedSelectPage = useCallback(
    debounce((pageId) => {
      dispatch(activePageActions.selectPage(pageId));
    }, 200),
    [dispatch]
  );

  const onNextClick = useCallback(() => {
    if (activePageIndex < pagesCount - 1) {
      analyticsService.event('Change Page Click', {
        source: 'Page stepper',
        sessionId,
        pageCount: pagesCount,
      });

      dispatch(setActivePage(pageOrders[activePageIndex + 1]));
      debouncedSelectPage(pageOrders[activePageIndex + 1]);
      return;
    }

    debouncedSelectPage.cancel();

    analyticsService.event('Create Board Page Button Click', {
      source: 'Page stepper',
      sessionId,
      pageCount: pagesCount + 1,
    });

    if (pagesCount >= pagesCountLimit) {
      analyticsService.event('Create Page above Limit');
      enqueueSnackbar('You have reached maximum pages count', {
        autoHideDuration: 3000,
        variant: 'error',
        preventDuplicate: true,
      });
      return;
    }

    if (activePageIndex === pagesCount - 1) {
      dispatch(pagesActions.createPage());
    }
  }, [
    sessionId,
    dispatch,
    pagesCount,
    pagesCountLimit,
    pageOrders,
    enqueueSnackbar,
    activePageIndex,
    debouncedSelectPage,
  ]);

  const onBackClick = useCallback(() => {
    if (activePageIndex === 0) return;

    analyticsService.event('Change Page Click', {
      source: 'Page stepper',
      sessionId,
      pageCount: pagesCount,
    });

    dispatch(setActivePage(pageOrders[activePageIndex - 1]));
    debouncedSelectPage(pageOrders[activePageIndex - 1]);
  }, [
    dispatch,
    pageOrders,
    activePageIndex,
    debouncedSelectPage,
    sessionId,
    pagesCount,
  ]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Tooltip title="Previous" placement="bottom">
        <Box>
          <IconButton
            onClick={onBackClick}
            disabled={activePageIndex === 0}
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
            shape={ButtonShapeTypes.ROUNDED}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Page list" placement="bottom">
        <Box>
          <StyledButton onClick={onClick}>
            Pages {`${activePageIndex + 1}/${pagesCount}`}
          </StyledButton>
        </Box>
      </Tooltip>
      <Tooltip title={isLastPage ? 'Add Page' : 'Next'} placement="bottom">
        <Box>
          <IconButton
            onClick={onNextClick}
            disabled={(finished && isLastPage) || (!isHost && isLastPage)}
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
            shape={ButtonShapeTypes.ROUNDED}
          >
            {isHost && !finished && isLastPage ? (
              <PlusIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default PagesStepper;
