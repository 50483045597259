import { styled } from '@mui/material/styles';
import MuiIconButton from '@mui/material/IconButton';
import { ButtonShapeTypes, ButtonVariantTypes } from '../Button/buttonTypes';

type IconButtonProps = {
  variant: ButtonVariantTypes,
  shape?: ButtonShapeTypes,
}

const IconButton = styled(MuiIconButton)<IconButtonProps>({});

export default IconButton;
