import { EventHandler, FC, ReactNode } from 'react';
import ChipComponent, { chipClasses } from '@mui/material/Chip';
import CloseIcon from '../Icons/CloseIcon';

type Props = {
  label: ReactNode;
  onDelete: EventHandler<any>;
};

const Chip: FC<React.PropsWithChildren<Props>> = ({ label, onDelete }) => (
  <ChipComponent
    sx={(theme) => ({
      display: 'inline-flex',
      gap: theme.spacing(1),
      backgroundColor: theme.background.bg3,
      border: '1px solid',
      borderColor: theme.background.bg6,
      borderRadius: '8px',
      padding: '4px 8px',
      height: '29px',
      [`& .${chipClasses.label}`]: {
        fontFamily: theme.typography.fontFamily,
        ...theme.typography.p5,
      },
      [`& .${chipClasses.deleteIcon}`]: {
        fontSize: '1.25rem !important',
        color: `${theme.background.bg7} !important`,
      },
    })}
    label={label}
    onDelete={onDelete}
    deleteIcon={<CloseIcon />}
  />
);

export default Chip;
