import { styled } from '@mui/material/styles';
import MuiToggleButton, {
  ToggleButtonProps as MuiToggleButtonProps,
  toggleButtonClasses,
} from '@mui/material/ToggleButton';

type ToggleButtonProps = MuiToggleButtonProps & {
  width?: number | string;
  size?: 'small' | 'large';
};

const ToggleButton = styled(MuiToggleButton, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'large',
})<ToggleButtonProps>(({ theme, width, size = 'large' }) => ({
  border: 'none',
  width: width || '100px',
  height: '29px',
  textTransform: 'none',
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: '#FFFFFF',
    ':hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  [theme.breakpoints.up('sm')]: {
    width: width || '120px',
    height: size === 'small' ? '32px' : '36px',
  },
}));

export default ToggleButton;
