import { format, formatDistanceToNowStrict } from 'date-fns';
import locale from 'date-fns/locale/en-US';

enum TokenType {
  xMinutes = 'xMinutes',
  aboutXHours = 'aboutXHours',
  xHours = 'xHours',
  xDays = 'xDays',
  aboutXWeeks = 'aboutXWeeks',
  xWeeks = 'xWeeks',
  aboutXMonths = 'aboutXMonths',
  xMonths = 'xMonths',
  aboutXYears = 'aboutXYears',
  xYears = 'xYears',
  overXYears = 'overXYears',
  almostXYears = 'almostXYears',
}

const formatDistanceBetweenNow = (time: number) => {
  const formatDistanceLocale = {
    xMinutes: '{{count}} min',
    aboutXHours: '{{count}} hour',
    xHours: '{{count}} hour',
    xDays: '{{count}} day',
    aboutXWeeks: '{{count}} week',
    xWeeks: '{{count}} week',
    aboutXMonths: '{{count}} month',
    xMonths: '{{count}} month',
    aboutXYears: '{{count}} year',
    xYears: '{{count}} year',
    overXYears: '{{count}} year',
    almostXYears: '{{count}} year',
  };

  const formatDistance = (token: TokenType, count: string) =>
    formatDistanceLocale[token]?.replace('{{count}}', count);

  const result = formatDistanceToNowStrict(time, {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance,
    },
  });

  return result ? `${result} ago` : 'less than one minute ago';
};

export const cardDate = (createdTime: number) =>
  format(createdTime, 'd MMM HH:mm');

export default formatDistanceBetweenNow;
