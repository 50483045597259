import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import IconButton from '../IconButton';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../Button/buttonTypes';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import { TypographyType } from '../Typography/types/Typography';

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const BackButtonWrapper = styled(Box)({});

const TitlesWrapper = styled(Box)({
  flex: 1,
});

const TitleWrapper = styled(Box)({});

const SubtitleWrapper = styled(Box)({
  marginTop: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const ActionsWrapper = styled(Box)({
  display: 'flex',
  gap: '24px',
});

type TitleBarProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  backLink?: string;
  actions?: React.ReactNode;
  SubtitleIcon?: FC<React.PropsWithChildren<SvgIconProps>>;
};

export const TitleBar: React.FC<React.PropsWithChildren<TitleBarProps>> = ({
  title,
  subtitle,
  backLink,
  actions,
  SubtitleIcon,
}) => {
  const theme = useTheme();
  const showH6Text = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      {backLink && (
        <BackButtonWrapper>
          <NavLink to={backLink}>
            <IconButton
              variant={ButtonVariantTypes.TEXT}
              size={ButtonSizeTypes.S}
              color={ButtonColorTypes.PRIMARY}
            >
              <ChevronLeftIcon />
            </IconButton>
          </NavLink>
        </BackButtonWrapper>
      )}
      <TitlesWrapper>
        <TitleWrapper>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            variant={showH6Text ? TypographyType.h6 : TypographyType.s1}
          >
            {title}
          </Typography>
        </TitleWrapper>
        {subtitle && (
          <SubtitleWrapper>
            {SubtitleIcon && (
              <SubtitleIcon
                sx={(theme) => ({
                  width: 18,
                  height: 18,
                  color: theme.text.t7,
                })}
              />
            )}
            <Typography
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              variant={TypographyType.s4}
              sx={(theme) => ({
                color: theme.text.t7,
              })}
            >
              {subtitle}
            </Typography>
          </SubtitleWrapper>
        )}
      </TitlesWrapper>
      {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
    </Container>
  );
};
