import {
  SET_VERIFICATION_TIME,
  SET_SHOW_PRODUCT_TOUR,
} from '../actions/dashboardActions';

const initialState = {
  verificationRequestTime: null,
  showProductTour: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VERIFICATION_TIME: {
      return {
        ...state,
        verificationRequestTime: action.payload,
      };
    }

    case SET_SHOW_PRODUCT_TOUR: {
      return {
        ...state,
        showProductTour: action.payload,
      };
    }

    default:
      return state;
  }
};

const verificationTimeSelector = (state) =>
  state.dashboard.verificationRequestTime;
const showProductTourSelector = (state) => state.dashboard.showProductTour;

export { verificationTimeSelector, showProductTourSelector };
