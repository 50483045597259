import { toColor } from '../../../../common/utils/color.utils';

const pathShapeDrawer = (ctx, item) => {
  const {
    startPoint,
    endPoint,
    paint,
    viewBoxWidth,
    viewBoxHeight,
    drawingData,
  } = item;

  const {
    color,
    strokeWidth,
  } = paint;

  const width = endPoint.x - startPoint.x;
  const height = endPoint.y - startPoint.y;
  const rgbaColor = toColor(color);

  ctx.save();
  ctx.translate(startPoint.x, startPoint.y);
  ctx.scale(width / viewBoxWidth, height / viewBoxHeight);

  ctx.strokeStyle = rgbaColor;
  ctx.lineCap = 'round';
  ctx.lineJoin  = 'round';
  ctx.lineWidth = strokeWidth;

  ctx.beginPath();
  for (let n = 0; n < drawingData.length; n++) {
    const c = drawingData[n].command;
    const p = drawingData[n].points;
    switch (c) {
      case 'L':
        ctx.lineTo(p[0], p[1]);
        break;
      case 'M':
        ctx.moveTo(p[0], p[1]);
        break;
      case 'C':
        ctx.bezierCurveTo(p[0], p[1], p[2], p[3], p[4], p[5]);
        break;
      case 'Q':
        ctx.quadraticCurveTo(p[0], p[1], p[2], p[3]);
        break;
      case 'A':
        const cx = p[0];
        const cy = p[1];
        const rx = p[2];
        const ry = p[3];
        const theta = p[4];
        const dTheta = p[5];
        const psi = p[6];
        const fs = p[7];

        const r = rx > ry ? rx : ry;
        const scaleX = rx > ry ? 1 : rx / ry;
        const scaleY = rx > ry ? ry / rx : 1;

        ctx.translate(cx, cy);
        ctx.rotate(psi);
        ctx.scale(scaleX, scaleY);
        ctx.arc(0, 0, r, theta, theta + dTheta, 1 - fs);
        ctx.scale(1 / scaleX, 1 / scaleY);
        ctx.rotate(-psi);
        ctx.translate(-cx, -cy);

        break;
      case 'z':
        ctx.closePath();
        break;
    }
  }

  ctx.save();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.stroke();
  ctx.restore();

  ctx.restore();
};

export default pathShapeDrawer;
