import React, { useEffect, useMemo, useState } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SectionCard } from '../../../../components/SectionCard';
import ListHistoryOutlineIcon from '../../../../../../ui/atoms/Icons/ListHistoryOutlineIcon';
import Typography from '../../../../../../ui/atoms/Typography';
import { Table } from '../../../../../../ui/atoms/Table';
import { PaymentHistoryDto } from '../../../../../../common/services/dto/subscriptions/PaymentHistoryDto';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import { getLocaleDate } from '../../../../../../common/utils/getLocaleDate';
import userService from '../../../../../../common/services/auth.service';
import Button from '../../../../../../ui/atoms/Button';
import DownloadOutlineIcon from '../../../../../../ui/atoms/Icons/DownloadOutlineIcon';

const columnHelper = createColumnHelper<PaymentHistoryDto>();

export const PaymentHistory: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
  const [data, setData] = useState<PaymentHistoryDto[]>([]);
  const columns = useMemo<ColumnDef<PaymentHistoryDto, any>[]>(
    () => [
      columnHelper.accessor('invoiceNumber', {
        header: 'Invoice number',
      }),
      columnHelper.display({
        cell: (info) => {
          return (
            <Typography variant="p4">
              {getLocaleDate(info.row.original.periodStart)}
              {' - '}
              {getLocaleDate(info.row.original.periodEnd)}
            </Typography>
          );
        },
        header: 'Period',
      }),
      columnHelper.accessor('dueDate', {
        cell: (info) => {
          const value = info.getValue() ?? info.row.original.periodStart;
          return <Box>{getLocaleDate(value)}</Box>;
        },
        header: 'Due date',
      }),
      columnHelper.accessor('amountPaid', {
        cell: (info) => (
          <Typography variant="p4">
            ${(info.getValue() / 100).toFixed(2)}
          </Typography>
        ),
        header: 'Unit price',
      }),
      columnHelper.accessor('status', {
        cell: (info) => (
          <Typography variant="p4" sx={{ textTransform: 'capitalize' }}>
            {info.getValue()}
          </Typography>
        ),
        header: 'Status',
      }),
      columnHelper.display({
        cell: (info) => (
          <Button
            href={info.row.original.invoicePDF}
            variant={ButtonVariantTypes.OUTLINED}
            size={ButtonSizeTypes.S}
            color={ButtonColorTypes.PRIMARY}
            sx={{
              padding: '16px',
            }}
          >
            <DownloadOutlineIcon />
            PDF
          </Button>
        ),
        header: 'Actions',
      }),
    ],
    []
  );

  useEffect(() => {
    userService.getPaymentHistory().then((data) => {
      setData(data);
      setInitialDataLoaded(true);
    });
  }, []);

  return (
    <SectionCard title="Payment history">
      <Table
        initialDataLoaded={initialDataLoaded}
        data={data}
        columns={columns}
        noResults={
          <Stack textAlign="center" p={4}>
            <Box>
              <ListHistoryOutlineIcon
                sx={(theme) => ({
                  fontSize: 56,
                  color: theme.background.bg6,
                })}
              />
            </Box>
            <Typography variant="s3">There are no payments yet</Typography>
          </Stack>
        }
      />
    </SectionCard>
  );
};
