import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import ThumbnailSaver from './ThumbnailSaver/ThumbnailSaver';
import RecordingContext from '../Recording/RecordingContext';
import UserPresenceTracker from './UserPresenceTracker';
import { AnswerQuestion } from './QuickQuestion';
import FeatureTourDialog from './QuickQuestion/common/FeatureTourDialog';
import PlanDialog from '../../ui/molecules/PlanDialog';
import UserDto from '../../common/services/dto/auth/UserDto';
import analyticsService from '../../common/services/analytics.service';
import FeatureTourDto from '../../common/services/dto/featureTours/FeatureTourDto';
import FeatureTourType from '../../common/services/types/featureTours/FeatureTourType';
import * as userReducer from '../../common/reducers/userReducer';
import useDialog from '../../common/hooks/useDialog';
import * as sessionActions from '../../common/actions/session/sessionActions';
import sessionService from '../../common/services/session.service';
import * as planPermissionSelectors from '../../common/selectors/planPermissionSelectors';
import { livekitTourSelector } from '../../common/reducers/featureToursReducer';
import featureToursService from '../../common/services/featureTours.service';
import UnleashFlags from '../../common/constants/UnleashFlag';
import SessionContext from '../Session/SessionContext';
import { participantsPageNavigationSelector } from '../../common/reducers/session/sessionReducer';
import { ProductTourContext } from '../ProductTour';
import useBoardRatio from '../../common/hooks/useBoardRatio';
import { updateDrawingToolsSizes } from '../../common/actions/board/drawingToolActions';
import { getSelectedDrawingTool } from '../../common/reducers/board/drawingToolReducer';
import ToolsBar from './ToolsBar/ToolsBar';
import ParticipantsBar from '../board/ParticipantsBar';
import styles from './BoardControls.module.css';

type Props = {
  connected: boolean;
  shared: boolean;
  canSaveThumbnail: boolean;
  showPagesTool: boolean;
  boardIsFinished: boolean;
  isHost: boolean;
  sessionId: string;
  chat: boolean;
  trackPresence: boolean;
};

type RefType = {
  saveThumbnail: () => Promise<void>;
};

const BoardControls: FC<React.PropsWithChildren<Props>> = ({
  connected,
  shared,
  canSaveThumbnail,
  showPagesTool,
  boardIsFinished,
  isHost,
  sessionId,
  chat,
  trackPresence,
}) => {
  const [pagesSidebarOpened, setPagesSidebarOpened] = useState(false);

  const hasVoicePermission = useSelector(
    planPermissionSelectors.hasVoicePermissionSelector
  );
  const user = useSelector(userReducer.userSelector);
  const livekitFeatureTourData = useSelector<any, FeatureTourDto>(
    livekitTourSelector
  );
  const participantsPageNavigation = useSelector(
    participantsPageNavigationSelector
  );
  const selectedTool = useSelector(getSelectedDrawingTool);
  const boardRatio = useBoardRatio();
  const dispatch = useDispatch();

  const livekitConferenceEnabled = useFlag(UnleashFlags.LIVEKIT_CONFERENCE);
  const thumbnailSaverRef = useRef<RefType>(null);
  const { openInviteDialog } = useContext(SessionContext);
  const { inProgress: isProductTourInProgress } =
    useContext(ProductTourContext);

  const {
    open: plansModalOpen,
    onOpen: onPlansModalOpen,
    onClose: onPlansModalClose,
  } = useDialog();

  const {
    open: livekitFeatureTourDialogOpen,
    onOpen: onLivekitFeatureTourDialogOpen,
    onClose: onLivekitFeatureTourDialogClose,
  } = useDialog();

  useEffect(() => {
    if (
      livekitConferenceEnabled &&
      livekitFeatureTourData &&
      livekitFeatureTourData.steps?.length &&
      !isProductTourInProgress
    ) {
      onLivekitFeatureTourDialogOpen();
    }
  }, [
    livekitFeatureTourData,
    onLivekitFeatureTourDialogOpen,
    livekitConferenceEnabled,
    isProductTourInProgress,
  ]);

  useEffect(() => {
    if (selectedTool.size && selectedTool.size.ratio === boardRatio) return;

    dispatch(updateDrawingToolsSizes(boardRatio));
  }, [dispatch, boardRatio, selectedTool]);

  const onTogglePageSidebar = useCallback(() => {
    if (!pagesSidebarOpened) {
      analyticsService.event('Open Page List Click');
    }

    setPagesSidebarOpened((open) => !open);
  }, [pagesSidebarOpened, setPagesSidebarOpened]);

  const onLivekitFeatureTourUnlock = useCallback(
    (version: any, activeStep: any) => {
      analyticsService.event('Livekit Onboarding Unlock Clicked', {
        Version: version,
        Page: activeStep,
      });

      onPlansModalOpen();
      onLivekitFeatureTourDialogClose();
      featureToursService.postFeatureTourSeen(
        FeatureTourType.LIVEKIT,
        livekitFeatureTourData.version
      );
    },
    [onPlansModalOpen, livekitFeatureTourData, onLivekitFeatureTourDialogClose]
  );

  const onLivekitFeatureTourComplete = useCallback(
    (planId: any, version: any) => {
      analyticsService.event('LiveKit Onboarding Completed', {
        Version: version,
        'Plan Id': planId,
      });
    },
    []
  );

  const onLivekitFeatureTourSkip = useCallback(
    (version: any, activeStep: any) => {
      analyticsService.event('LiveKit Onboarding Skipped', {
        Version: version,
        Page: activeStep,
      });
    },
    []
  );

  const shareBoard = useCallback(async () => {
    if (shared) return;

    const sharedSession = await sessionService.shareSession(
      sessionId,
      true,
      true
    );
    dispatch(sessionActions.updateSession(sharedSession));
  }, [sessionId, user, shared, dispatch]);

  const invitePeopleLivekit = useCallback(async () => {
    analyticsService.event('Invite Students from LiveKit Onboarding');

    await shareBoard();
    onLivekitFeatureTourDialogClose();
    featureToursService.postFeatureTourSeen(
      FeatureTourType.LIVEKIT,
      livekitFeatureTourData.version
    );

    openInviteDialog();
  }, [
    shareBoard,
    livekitFeatureTourData,
    onLivekitFeatureTourDialogClose,
    openInviteDialog,
  ]);

  const invitePeople = useCallback(async () => {
    if (livekitConferenceEnabled) {
      await invitePeopleLivekit();
      return;
    }
  }, [livekitConferenceEnabled, invitePeopleLivekit]);

  const onPlanChangeSuccess = useCallback(
    async (userData: UserDto) => {
      if (!userData.subscription.plan.permissions.voice) return;

      await invitePeople();
    },
    [invitePeople]
  );

  const shouldShowPagesTool = useMemo(
    () =>
      (!isHost && participantsPageNavigation) ||
      (showPagesTool && ((!boardIsFinished && isHost) || boardIsFinished)),
    [isHost, boardIsFinished, participantsPageNavigation, showPagesTool]
  );

  return (
    <>
      <ToolsBar
        shouldShowPagesTool={shouldShowPagesTool}
        boardIsFinished={boardIsFinished}
        pagesSidebarOpened={pagesSidebarOpened}
        onTogglePageSidebar={onTogglePageSidebar}
      />
      {!isHost && sessionId && connected && shared && <AnswerQuestion />}
      <ParticipantsBar
        sessionId={sessionId}
        pageNavigation={participantsPageNavigation}
        chat={chat}
        showSettings={connected}
      />
      {canSaveThumbnail && (
        <ThumbnailSaver ref={thumbnailSaverRef} key="thumbnail-saver" />
      )}
      <RecordingContext.Consumer>
        {({ isRecordingFeatureEnabled, setRecordingCanvas }) =>
          isRecordingFeatureEnabled && (
            <canvas
              style={{ position: 'absolute', top: -9999, left: -7999 }}
              ref={setRecordingCanvas}
              key="recording-canvas"
            />
          )
        }
      </RecordingContext.Consumer>
      {trackPresence && <UserPresenceTracker track={connected} />}
      {livekitConferenceEnabled &&
        isHost &&
        livekitFeatureTourData &&
        livekitFeatureTourData.hasUpdates &&
        livekitFeatureTourData.steps?.length > 0 &&
        livekitFeatureTourData.version && (
          <FeatureTourDialog
            open={livekitFeatureTourDialogOpen}
            onClose={onLivekitFeatureTourDialogClose}
            featureTourData={livekitFeatureTourData}
            imageClass={styles.image}
            type={FeatureTourType.LIVEKIT}
            title="Introducing Video Call"
            featureAction={
              hasVoicePermission ? 'Invite students' : 'Unlock video call'
            }
            onComplete={onLivekitFeatureTourComplete}
            onSkip={onLivekitFeatureTourSkip}
            onCreate={invitePeopleLivekit}
            onUnlock={onLivekitFeatureTourUnlock}
            steps={livekitFeatureTourData.steps}
            version={livekitFeatureTourData.version}
            canUseFeature={hasVoicePermission}
          />
        )}
      <PlanDialog
        source="Start Call"
        open={plansModalOpen}
        onClose={onPlansModalClose}
        onSuccess={onPlanChangeSuccess}
      />
    </>
  );
};

export default React.memo(BoardControls);
