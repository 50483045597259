import { FC } from 'react';
import Typography from '../../../../../../ui/atoms/Typography';
import Link from '@mui/material/Link';

type Props = {
  url: string;
  platformText: string;
};

export const MobileSupportInformation: FC<React.PropsWithChildren<Props>> = ({
  url,
  platformText,
}) => (
  <Typography variant="s4">
    Use your{' '}
    <Link
      href={url}
      target="_blank"
      rel="noreferrer"
      underline="always"
      className="text-first"
    >
      {platformText}
    </Link>{' '}
    to manage your subscription
  </Typography>
);
