import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { push } from 'redux-first-history';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Input from '../../atoms/Input';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import InputStatus from '../../atoms/Input/InputStatus';
import Button from '../../atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import { FormValues } from '../Header/headerTypes';
import sessionService from '../../../common/services/session.service';
import { setOpenSource } from '../../../common/actions/session/sessionActions';
import analyticsService from '../../../common/services/analytics.service';

const errorMessages = {
  required: 'Please, enter a code',
  length: 'Session code must contain 5 characters',
  format: 'Session code can contain only latin symbols and numbers',
};

const formStyle = {
  margin: 'auto',
};

const JoinSessionForm: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const location = useLocation();

  const { register, unregister, control, handleSubmit, setError } =
    useForm<FormValues>({
      defaultValues: {
        sessionCode: '',
      },
    });

  useEffect(() => {
    register('sessionCode');
    return () => {
      unregister('sessionCode');
    };
  }, [register, unregister]);

  const onError = useCallback(
    (error: any) => {
      enqueueSnackbar(error.sessionCode.message, {
        autoHideDuration: 3000,
        variant: 'error',
      });
    },
    [enqueueSnackbar]
  );

  const validateInput = useCallback((sessionCode: any) => {
    if (!sessionCode.length) {
      throw new Error(errorMessages.required);
    }

    if (sessionCode.length < 5 || sessionCode.length > 5) {
      throw new Error(errorMessages.length);
    }
    const sessionCodeRegexp = /^[a-zA-Z0-9_]*$/;

    if (!sessionCodeRegexp.test(sessionCode)) {
      throw new Error(errorMessages.format);
    }
  }, []);

  const onJoinSession = useCallback(
    async (sessionCode: string) => {
      if (!sessionCode) return;

      try {
        const session = await sessionService.getSessionByCode(sessionCode);
        dispatch(
          push({
            pathname: `/session/${session.id}`,
            state: { referrer: location.pathname },
          })
        );

        dispatch(setOpenSource('Join: code'));
      } catch (error) {
        if (error && error.message) {
          let { message } = error;
          // ToDo: LIVEWEB-408
          if (message === 'No such session!') {
            message = 'Wrong session code.';
          }

          enqueueSnackbar(message, {
            autoHideDuration: 3000,
            variant: 'error',
          });

          setError('sessionCode', { message });
        }
      }
    },
    [dispatch, location, enqueueSnackbar, setError]
  );

  const onSubmit = useCallback(
    handleSubmit(({ sessionCode }) => {
      try {
        validateInput(sessionCode);
        onJoinSession(sessionCode);
      } catch (error) {
        enqueueSnackbar(error.message, {
          autoHideDuration: 3000,
          variant: 'error',
        });
        setError('sessionCode', {
          message: error.message,
        });
      }
    }, onError),
    [
      enqueueSnackbar,
      handleSubmit,
      onError,
      setError,
      validateInput,
      onJoinSession,
    ]
  );

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        [t.breakpoints.up('xs')]: {
          height: '40px',
        },
        [t.breakpoints.up('md')]: {
          height: '48px',
          flex: 'unset',
        },
      })}
    >
      <form onSubmit={onSubmit} style={formStyle}>
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            gap: 1,
            flex: 1,
          }}
        >
          <Controller
            name="sessionCode"
            control={control}
            render={({ field: { onChange, value }, formState }) => (
              <Box
                sx={(t) => ({
                  flex: 1,
                  maxWidth: '229px',
                  [t.breakpoints.up('md')]: {
                    maxWidth: '320px',
                  },
                })}
              >
                <Input
                  onChange={onChange}
                  value={value}
                  size={smallSize ? InputSize.S : InputSize.M}
                  variant={InputVariant.CONTAINED}
                  placeholder="Enter session code"
                  status={
                    formState.errors.sessionCode ? InputStatus.ERROR : undefined
                  }
                  limit={5}
                />
              </Box>
            )}
          />
          <Button
            variant={ButtonVariantTypes.PRIMARY}
            size={smallSize ? ButtonSizeTypes.S : ButtonSizeTypes.M}
            type="submit"
            onClick={() => {
              analyticsService.event('Join Session Button Click', {
                source: 'Dashboard',
              });
            }}
          >
            Join session
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default JoinSessionForm;
