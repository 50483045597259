import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from '../components/Layout';
import { AssessmentLayout } from '../components/AssessmentLayout';
import { lazyImport } from '../../../../common/utils/lazy-import';
import Loader from '../../../../components/Icons/Loader';

const { AssessmentDetails } = lazyImport(
  () => import('../features/assessment-details/AssessmentDetails'),
  'AssessmentDetails'
);

const { ParticipantResult } = lazyImport(
  () => import('../features/participant-result/ParticipantResult'),
  'ParticipantResult'
);
export const AssessmentRoutes: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Layout>
    <Switch>
      <Route
        exact
        path={`/assessment/:assessmentId`}
        render={({ match }) => (
          <AssessmentLayout>
            <Suspense fallback={<Loader />}>
              <AssessmentDetails assessmentId={match.params.assessmentId} />
            </Suspense>
          </AssessmentLayout>
        )}
      />
      <Route
        path={`/assessment/:assessmentId/paper/:paperId`}
        exact
        render={({ match }) => (
          <AssessmentLayout>
            <Suspense fallback={<Loader />}>
              <ParticipantResult
                assessmentId={match.params.assessmentId}
                paperId={match.params.paperId}
              />
            </Suspense>
          </AssessmentLayout>
        )}
      />
    </Switch>
  </Layout>
);
