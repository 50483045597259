import { FC, useCallback, useEffect, useState } from 'react';
import Dialog from '../../atoms/Dialog';
import DialogActions, {
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import DialogContent from '../../atoms/DialogContent';
import recordingService from '../../../common/services/recording.service';
import SessionRecordingListPayload from '../../../common/services/types/recording/sessionRecordingType';

type Props = {
  open: boolean;
  onClose: () => void;
  recording: SessionRecordingListPayload | null;
};

const RecordingPlayerDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  recording,
}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (!recording) return;

    const fetchUrl = async () => {
      const data = await recordingService.getRecordingUrl(recording.id);
      setUrl(data);
    };

    fetchUrl();
  }, [recording]);

  const onExit = useCallback(() => {
    setUrl('');
  }, [setUrl]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionProps={{
        onExit,
      }}
    >
      <DialogContent>
        <video
          style={{
            height: 'auto',
            width: '100%',
            maxHeight: 500,
            backgroundColor: '#eee',
          }}
          src={url}
          controls
        />
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Close
        </DialogActionSecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default RecordingPlayerDialog;
