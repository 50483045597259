import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import CardInformation from './CardInformation';
import PromoCode from '../../PromoCode';
import { userSelector } from '../../../common/reducers/userReducer';
import Typography from '../../../ui/atoms/Typography/Typography';
import { getLocaleDate } from '../../../common/utils/getLocaleDate';
import PaymentMethodDto from '../../../common/services/dto/auth/PaymentMethodDto';
import PromoCodeDto from '../../../common/services/dto/auth/PromoCodeDto';
import userService from '../../../common/services/auth.service';
import calculateDiscountedPrice from '../../../common/utils/discount.plan.price.util';
import UserDto from '../../../common/services/dto/auth/UserDto';
import CardForm from './CardForm';
import environment from '../../../config/environment';
import { SectionCard } from '../../../features/profile/components/SectionCard';

type PlanDetailsProps = {
  card: PaymentMethodDto | null;
  cardFetching: boolean;
  cardDetails: string;
  onDeletePaymentMethod: () => Promise<void>;
  onAddCardSuccess: () => Promise<void>;
};

const NextPayment = ({
  promoData,
  discountedPromoCode,
  periodEnd,
  price,
  user,
  upcomingChangeDate,
}: {
  user: UserDto;
  periodEnd: string;
  promoData: PromoCodeDto | null;
  discountedPromoCode: number;
  price: number;
  upcomingChangeDate: string;
}) => {
  if (promoData) {
    return (
      <Box
        pb={3}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.background.bg5}`,
        })}
      >
        <Typography variant="p3">
          Your next bill is for ${discountedPromoCode.toFixed(2)}{' '}
          <Typography
            display="inline"
            sx={{
              textDecoration: 'line-through',
            }}
          >
            ${price.toFixed(2)}
          </Typography>{' '}
          on {periodEnd}.
        </Typography>
      </Box>
    );
  }

  if (user.subscription.upcomingPlanChange) {
    return (
      <Box
        pb={3}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.background.bg5}`,
        })}
      >
        <Typography variant="p3">
          Your next bill is for $
          {user.subscription.upcomingPlanChange.plan.price} on{' '}
          {upcomingChangeDate}.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      pb={3}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.background.bg5}`,
      })}
    >
      <Typography variant="p3">
        Your next bill is for ${user.subscription.plan.price} on {periodEnd}.
      </Typography>
    </Box>
  );
};

const subscriptionStatusFactory = ({
  user,
  hasCard,
  periodEnd,
  promoData,
  discountedPromoCode,
  price,
  upcomingChangeDate,
}: {
  user: UserDto;
  hasCard: boolean;
  periodEnd: string;
  promoData: PromoCodeDto | null;
  discountedPromoCode: number;
  price: number;
  upcomingChangeDate: string;
}) => {
  if (user.subscription.isTrial && !hasCard) {
    return (
      <Box
        pb={3}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.background.bg5}`,
        })}
      >
        <Typography variant="p3">
          Your subscription is in trial period and will expire on{' '}
          <strong>{periodEnd}</strong>. Add payment method in order to extend
          the period.
        </Typography>
      </Box>
    );
  }

  if (user.subscription.expiresIn && !user.subscription.stopDate) {
    return (
      <NextPayment
        promoData={promoData}
        discountedPromoCode={discountedPromoCode}
        periodEnd={periodEnd}
        price={price}
        user={user}
        upcomingChangeDate={upcomingChangeDate}
      />
    );
  }

  return (
    <Typography variant="p3">
      Your subscription has been cancelled. It will expire on{' '}
      <strong>{periodEnd}</strong>.
    </Typography>
  );
};

const promise = loadStripe(environment.stripePublishKey);

const PlanDetails: FC<React.PropsWithChildren<PlanDetailsProps>> = ({
  card,
  cardFetching,
  cardDetails,
  onDeletePaymentMethod,
  onAddCardSuccess,
}) => {
  const user = useSelector(userSelector);
  const periodStart = getLocaleDate(user.subscription.startDate);
  const periodEnd = getLocaleDate(user.subscription.expirationDate);
  const isStripeSubscription = user.subscriptionSource === 'stripe';
  const upcomingChangeDate = getLocaleDate(
    user.subscription.upcomingPlanChange?.changeDate
  );
  const [promoData, setPromoData] = useState<PromoCodeDto | null>(null);
  const price = useMemo(
    () =>
      user.subscription.upcomingPlanChange?.plan.price ||
      user.subscription.plan.price,
    [user]
  );
  const discountedPromoCode = useMemo(
    () => calculateDiscountedPrice(price, promoData),
    [price, promoData]
  );

  const onPromoDataFetchSuccess = useCallback(
    (data: any) => {
      setPromoData(data);
    },
    [setPromoData]
  );

  const onPromoValidateSuccess = useCallback(
    async (validateResponse: any) => {
      const response = await userService.addSubscriptionPromoCode(
        validateResponse.id
      );

      setPromoData(response);
    },
    [setPromoData]
  );

  const SubscriptionStatus = useMemo(
    () =>
      subscriptionStatusFactory({
        user,
        hasCard: !!card,
        periodEnd,
        promoData,
        discountedPromoCode,
        price,
        upcomingChangeDate,
      }),
    [
      user,
      card,
      periodEnd,
      promoData,
      discountedPromoCode,
      price,
      upcomingChangeDate,
    ]
  );

  return (
    <SectionCard title="Payment details">
      {!cardFetching ? (
        <>
          {cardDetails && user.canSeeBilling && isStripeSubscription ? (
            <>
              <CardInformation
                details={cardDetails}
                onDeletePaymentMethod={onDeletePaymentMethod}
              />
              <Divider orientation="horizontal" sx={{ mb: 3 }} />
              <PromoCode
                planId={user.subscription.plan.id}
                shouldFetchPromoCode
                onFetchSuccess={onPromoDataFetchSuccess}
                onValidateSuccess={onPromoValidateSuccess}
              />
            </>
          ) : (
            <>
              <Elements stripe={promise}>
                <CardForm
                  promoCodeId={promoData?.id}
                  onSuccess={onAddCardSuccess}
                />
              </Elements>
              <Divider orientation="horizontal" sx={{ mb: 3 }} />
            </>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            {isStripeSubscription && SubscriptionStatus}
            {periodStart && (
              <Typography variant="p3">
                Subscription was started on <strong>{periodStart}</strong>.
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <CircularProgress variant="indeterminate" />
        </Box>
      )}
    </SectionCard>
  );
};

export default PlanDetails;
