import { FC, forwardRef } from 'react';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import MuiListItem, {
  listItemClasses,
  ListItemProps as MuiListItemProps,
} from '@mui/material/ListItem';
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { typographyClasses } from '@mui/material/Typography';
import { TypographyType } from '../Typography/types/Typography';
import { ListItemProps } from './listItemTypes';

const ListItem: FC<React.PropsWithChildren<ListItemProps & MuiListItemProps>> =
  forwardRef(
    (
      {
        startIcon,
        title,
        subtitle,
        badgeVisible = false,
        endIcon,
        selected,
        onSelect,
        textPrimaryColor,
        listItemTextStyles,
        primaryTypographyProps,
        secondaryTypographyProps,
        width,
        rounded = true,
        ...props
      },
      ref
    ) => (
      <MuiListItem
        {...props}
        ref={ref}
        selected={selected}
        onClick={(event) => onSelect && onSelect(event)}
        secondaryAction={endIcon}
        sx={(theme) => ({
          borderRadius: rounded ? 2 : 0,
          padding: '14px 16px 13px 16px',
          [`& .${typographyClasses.root}`]: {
            maxWidth: '100%',
            width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
          transition: 'background-color 0.2s',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.background.bg3,
            [`& .${listItemTextClasses.primary}`]: {
              color: !textPrimaryColor
                ? theme.palette.primary.dark5
                : textPrimaryColor,
            },
            svg: {
              fill: !textPrimaryColor
                ? theme.palette.primary.main
                : textPrimaryColor,
            },
          },
          [`&.${listItemClasses.selected}`]: {
            [`& .${typographyClasses.root}`]: {
              width,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
            backgroundColor: theme.palette.primary.alpha10,
            [`& .${listItemTextClasses.primary}`]: {
              color: !textPrimaryColor
                ? theme.palette.primary.dark5
                : textPrimaryColor,
            },
            svg: {
              fill: !textPrimaryColor
                ? theme.palette.primary.dark5
                : textPrimaryColor,
            },
          },
          svg: {
            fontSize: '20px',
            fill: !textPrimaryColor ? theme.text.t8 : textPrimaryColor,
          },
          [`& .${listItemSecondaryActionClasses.root}`]: {
            display: 'flex',
            alignItems: 'center',
          },
        })}
      >
        {startIcon}
        <Badge
          color="secondary"
          variant="dot"
          invisible={!badgeVisible}
          sx={(theme) => ({
            width,
            [`& .${badgeClasses.badge}`]: {
              top: '50%',
              height: '6px',
              minWidth: '6px',
              position: 'absolute',
              right: '10px',
              color: theme.status.new.main,
            },
          })}
        >
          <ListItemText
            sx={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 1.25,
              marginRight: 3.25,
              ...listItemTextStyles,
            }}
            primary={title}
            primaryTypographyProps={{
              color: textPrimaryColor,
              variant: TypographyType.p5,
              sx: primaryTypographyProps,
            }}
            secondary={subtitle}
            secondaryTypographyProps={{
              variant: TypographyType.p5,
              sx: secondaryTypographyProps,
            }}
          />
        </Badge>
      </MuiListItem>
    )
  );

export default ListItem;
