import { FC } from 'react';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

const StyledButton = styled(MuiButton)<MuiButtonProps>({});

export type ButtonProps = {
  loading?: boolean;
} & MuiButtonProps;

const Button: FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  loading = false,
  startIcon,
  endIcon,
  ...props
}) => (
  <StyledButton
    {...props}
    startIcon={
      startIcon && loading ? (
        <CircularProgress
          size={18}
          sx={(theme) => ({
            color: theme.palette.primary.alpha50,
          })}
        />
      ) : (
        startIcon
      )
    }
    endIcon={
      endIcon && loading ? (
        <CircularProgress
          size={18}
          sx={(theme) => ({
            color: theme.palette.primary.alpha50,
          })}
        />
      ) : (
        endIcon
      )
    }
  >
    {isEmpty(startIcon) && isEmpty(endIcon) && loading ? (
      <CircularProgress
        size={18}
        sx={(theme) => ({
          color: theme.palette.primary.alpha50,
        })}
      />
    ) : (
      children
    )}
  </StyledButton>
);

export default Button;
