import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// Should be imported before any MUI component
import './mui-class-prefix';
import { createBrowserHistory as createHistory } from 'history';
import { Router } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FlagProvider from '@unleash/proxy-client-react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';

import './config/sentry';
import createStore from './common/reducers';
import Routes from './config/routes';
import { queryClient } from './config/queryClient';
import { initialize as initializePointer } from './common/utils/pointer-image.utils';
import { initialize as initializeWaterMark } from './common/utils/watermark-image.utils';
import { initialize as initializeRotation } from './common/utils/rotate-image.utils';
import * as serviceWorker from './serviceWorker';
import './assets/scss/main.scss';
import SnackbarWrapper from './components/Snackbar/SnackbarWrapper';
import { ProductTourProvider } from './components/ProductTour';
import analyticsService from './common/services/analytics.service';
import LiveBoardTheme from './ui/LiveBoardTheme';
import environment from './config/environment';
import Loader from './components/Icons/Loader';
import { ConfirmProvider } from './ui/organisms/ConfirmationDialog';

const config = {
  url: environment.unleash.url,
  clientKey: environment.unleash.clientKey,
  refreshInterval: 7 * 24 * 60 * 60,
  appName: environment.unleash.appName,
  environment: environment.unleash.environment,
};

initializePointer();
initializeWaterMark();
initializeRotation();

(async (_) => {
  await analyticsService.initGA();
  await analyticsService.initMixpanel();

  let browserHistory = createHistory();
  if (environment.hubspot.enabled()) {
    browserHistory.listen(() => {
      if (window?.HubSpotConversations?.widget?.refresh) {
        window?.HubSpotConversations?.widget?.refresh();
      }
    });
  }
  const { store, history } = createStore(browserHistory);

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <GoogleOAuthProvider clientId={environment.googleClientId}>
            <FlagProvider config={config}>
              <LiveBoardTheme>
                <ConfirmProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarWrapper>
                      <Router history={history}>
                        <ProductTourProvider>
                          <Routes />
                        </ProductTourProvider>
                      </Router>
                    </SnackbarWrapper>
                  </LocalizationProvider>
                </ConfirmProvider>
              </LiveBoardTheme>
            </FlagProvider>
          </GoogleOAuthProvider>
        </Provider>
      </QueryClientProvider>
    </Suspense>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
