import Resizer from './Resizer';
import { text } from '../../../../../../ui/atoms/Colors/colorStyles';

const calculateProjectedPoint = (firstPoint, secondPoint, pointToProject) => {
  const a = firstPoint.y - secondPoint.y;
  const b = secondPoint.x - firstPoint.x;
  const c = (-b * firstPoint.y) + (-a * firstPoint.x);
  return {
    x: ((b * ((b * pointToProject.x) - (a * pointToProject.y))) - (a * c)) / ((a ** 2) + (b ** 2)),
    y: ((a * ((-b * pointToProject.x) + (a * pointToProject.y))) - (b * c)) / ((a ** 2) + (b ** 2)),
  };
};

export default class AspectRatioResizer extends Resizer {
  cursor = 'nwse-resize';

  draw(ctx) {
    const size = 10 / this.scale;
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = text.t7;
    ctx.lineWidth = 6 / this.scale;
    const { x, y } = this.center();
    ctx.moveTo(x - size, y);
    ctx.lineTo(x, y);
    ctx.lineTo(x, y - size);
    ctx.stroke();
    ctx.restore();
  }

  center() {
    return {
      x: Math.max(this.startPoint.x, this.endPoint.x) + this.padding,
      y: Math.max(this.startPoint.y, this.endPoint.y) + this.padding,
    };
  }

  onResize(pos) {
    const projectedPoint = calculateProjectedPoint(
      {
        x: Math.min(this.startPoint.x, this.endPoint.x),
        y: Math.min(this.startPoint.y, this.endPoint.y),
      },
      {
        x: Math.max(this.startPoint.x, this.endPoint.x),
        y: Math.max(this.startPoint.y, this.endPoint.y),
      },
      { x: pos.x - this.padding, y: pos.y - this.padding },
    );

    if (this.endPoint.x > this.startPoint.x && this.endPoint.y > this.startPoint.y) {
      return {
        startPoint: {
          x: this.startPoint.x,
          y: this.startPoint.y,
        },
        endPoint: {
          x: Math.max(projectedPoint.x, this.startPoint.x),
          y: Math.max(projectedPoint.y, this.startPoint.y),
        },
      };
    }

    if (this.startPoint.x >= this.endPoint.x && this.startPoint.y >= this.endPoint.y) {
      return {
        startPoint: {
          x: Math.max(projectedPoint.x, this.endPoint.x),
          y: Math.max(projectedPoint.y, this.endPoint.y),
        },
        endPoint: {
          x: this.endPoint.x,
          y: this.endPoint.y,
        },
      };
    }

    if (this.startPoint.x > this.endPoint.x  && this.endPoint.y > this.startPoint.y) {
      return {
        startPoint: {
          x: Math.max(projectedPoint.x, this.endPoint.x),
          y: this.startPoint.y,
        },
        endPoint: {
          x: this.endPoint.x,
          y: Math.max(projectedPoint.y, this.startPoint.y),
        },
      };
    }

    return {
      startPoint: {
        x: this.startPoint.x,
        y: Math.max(projectedPoint.y, this.endPoint.y),
      },
      endPoint: {
        x: Math.max(projectedPoint.x, this.startPoint.x),
        y: this.endPoint.y,
      },
    };
  }
}
