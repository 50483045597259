import {
  sharedSelector,
  isCurrentUserHostSelector,
} from '../../reducers/session/sessionReducer';
import { setShouldUpdateThumbnail } from '../board/shouldUpdateThumbnailActions';
import { userSelector } from '../../reducers/userReducer';
import { checkCanSendBoardNotification } from '../../utils/notification.utils';
import Snackbar from '../../../components/Snackbar/SnackbarConfig';

const ADD_PARTICIPANTS = Symbol('ADD_PARTICIPANTS');
const ADD_PARTICIPANT = Symbol('ADD_PARTICIPANT');
const REMOVE_PARTICIPANT = Symbol('REMOVE_PARTICIPANT');
const UPDATE_PARTICIPANT = Symbol('UPDATE_PARTICIPANT');
const SET_PROFILE_URL = Symbol('SET_PROFILE_URL');
const SET_DRAWING_ENABLED = Symbol('SET_DRAWING_ENABLED');

const addParticipants = (participants) => ({
  type: ADD_PARTICIPANTS,
  participants,
});

const addParticipant = (participant) => ({
  type: ADD_PARTICIPANT,
  participant,
});

const removeParticipant = (participantId) => ({
  type: REMOVE_PARTICIPANT,
  participantId,
});

const setDrawingEnabled = (id, value) => ({
  type: SET_DRAWING_ENABLED,
  id,
  drawingEnabled: value,
});

const updateParticipant = (participant) => ({
  type: UPDATE_PARTICIPANT,
  participant,
});

const participantsAdded = (participants) => (dispatch) => {
  dispatch(addParticipants(participants));
};

const participantUpdated = (participant) => (dispatch, getState) => {
  dispatch(updateParticipant(participant));

  const state = getState();
  const isHost = isCurrentUserHostSelector(state);

  if (isHost) {
    dispatch(setShouldUpdateThumbnail(true));
  }
};

const participantJoined = (participant) => (dispatch, getState) => {
  const state = getState();
  const user = userSelector(state);
  const shared = sharedSelector(state);

  if (
    !checkCanSendBoardNotification({
      userId: user.id,
      participantId: participant.id,
      shared,
    })
  )
    return;

  Snackbar.presenceStatus('joined', {
    title: `${participant.name}`,
    userName: `${participant.name}`,
    profileImageSrc: participant.profileImageUrl,
    type: 'success',
  });
};

const participantLeft = (participant) => (dispatch, getState) => {
  const state = getState();
  const user = userSelector(state);
  const shared = sharedSelector(state);

  if (
    !checkCanSendBoardNotification({
      userId: user.id,
      participantId: participant.id,
      shared,
    })
  )
    return;

  Snackbar.presenceStatus('left', {
    title: `${participant.name}`,
    userName: `${participant.name}`,
    profileImageSrc: participant.profileImageUrl,
    type: 'error',
  });
};

export {
  participantsAdded,
  participantJoined,
  participantLeft,
  addParticipant,
  removeParticipant,
  setDrawingEnabled,
  updateParticipant,
  participantUpdated,
  ADD_PARTICIPANTS,
  ADD_PARTICIPANT,
  UPDATE_PARTICIPANT,
  REMOVE_PARTICIPANT,
  SET_PROFILE_URL,
  SET_DRAWING_ENABLED,
};
