import sessionService from '../../services/session.service';
import BoardType from '../../services/types/session/BoardType';

const SELECT_DRAWING_TOOL = Symbol('SELECT_DRAWING_TOOL');
const UPDATE_DRAWING_TOOL = Symbol('UPDATE_DRAWING_TOOL');
const SET_DEFAULTS = Symbol('SET_DEFAULTS');
const ADD_PATH_SHAPE_TOOLS = Symbol('ADD_PATH_SHAPE_TOOLS');
const CLEAR_PATH_SHAPE_TOOLS = Symbol('CLEAR_PATH_SHAPE_TOOLS');
const UPDATE_DRAWING_TOOLS_SIZES = Symbol('UPDATE_DRAWING_TOOLS_SIZES');

const selectDrawingTool = (name) => ({
  type: SELECT_DRAWING_TOOL,
  name,
});

const updateDrawingTool = (name, props) => ({
  type: UPDATE_DRAWING_TOOL,
  name,
  props,
});

const resetDrawingTool = () => ({
  type: SET_DEFAULTS,
});

const addPathShapeTools = (tools) => ({
  type: ADD_PATH_SHAPE_TOOLS,
  payload: {
    tools,
  },
});

const clearPathShapeTools = () => ({
  type: CLEAR_PATH_SHAPE_TOOLS,
});

const fetchPathShapeTools = (boardType) => async (dispatch) => {
  dispatch(clearPathShapeTools());
  const templates = await sessionService.getPathShapeTemplates();

  const idsToExclude =
    boardType === BoardType.INFINITE
      ? [
          '605082a354055b5d5eeb37ca',
          '5f893fe770f0d50a5b46bd12',
          '5f901c9c44645385d209edee',
        ]
      : [];

  const filteredTemplates = templates.filter(
    (template) => !idsToExclude.includes(template.id)
  );
  dispatch(addPathShapeTools(filteredTemplates));
};

const updateDrawingToolsSizes = (ratio) => ({
  type: UPDATE_DRAWING_TOOLS_SIZES,
  ratio,
});

export {
  selectDrawingTool,
  updateDrawingTool,
  resetDrawingTool,
  fetchPathShapeTools,
  updateDrawingToolsSizes,
  SELECT_DRAWING_TOOL,
  UPDATE_DRAWING_TOOL,
  SET_DEFAULTS,
  ADD_PATH_SHAPE_TOOLS,
  CLEAR_PATH_SHAPE_TOOLS,
  UPDATE_DRAWING_TOOLS_SIZES,
};
