import { Circle as PixiCircle, DisplayObject, Graphics, Point } from 'pixi.js';
import Shape from './shape';
import OldCircleAction from '../../../common/drawingActions/shapes/circle.drawing.action';
import ActionName from '../types/ActionName';

class Circle extends Shape {
  constructor(action?: OldCircleAction) {
    super(action);

    this.name = ActionName.CIRCLE;
  }

  toDto(): OldCircleAction {
    const dto = new OldCircleAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldCircleAction) {
    super.setFields(action);
    action.name = ActionName.CIRCLE;
  }

  drawIntermediate(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const radiusVector = new Point(
      this.endPoint.x - this.startPoint.x,
      this.endPoint.y - this.startPoint.y
    );
    const radius = Math.sqrt(
      radiusVector.x * radiusVector.x + radiusVector.y * radiusVector.y
    );
    graphicsObject.clear();
    graphicsObject.position.set(this.startPoint.x, this.startPoint.y);

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    if (!this.paint.fill) {
      graphicsObject.lineStyle(size, color);
    } else {
      graphicsObject.beginFill(color);
    }

    graphicsObject.drawCircle(0, 0, radius);

    if (this.paint.fill) {
      graphicsObject.endFill();
    } else {
      graphicsObject.hitArea = new PixiCircle(0, 0, radius + size / 2);
    }
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const radiusVector = new Point(
      this.endPoint.x - this.startPoint.x,
      this.endPoint.y - this.startPoint.y
    );
    const radius = Math.sqrt(
      radiusVector.x * radiusVector.x + radiusVector.y * radiusVector.y
    );
    graphicsObject.clear();
    graphicsObject.position.set(this.startPoint.x, this.startPoint.y);

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    if (!this.paint.fill) {
      graphicsObject.lineStyle(size, color);
    } else {
      graphicsObject.beginFill(color);
    }

    graphicsObject.drawCircle(0, 0, radius);

    if (this.paint.fill) {
      graphicsObject.endFill();
    } else {
      graphicsObject.hitArea = new PixiCircle(0, 0, radius + size / 2);
    }

    graphicsObject.removeChildren();
  }
}

export default Circle;
