import vectorUtils from '../../../../common/utils/vector.utils';

const imageDrawer = (ctx, item) => {
  const {
    startPoint,
    image,
    width,
    height,
  } = item;
  const { x, y } = startPoint;

  ctx.save();
  if (image) {
    const midPoint = {
      x: startPoint.x + item.getWidth() / 2,
      y: startPoint.y + item.getHeight() / 2,
    };

    if (item.rotation) {
      ctx.translate(midPoint.x, midPoint.y);
      ctx.rotate(vectorUtils.degreeToRadian(item.rotation));
      ctx.translate(-midPoint.x, -midPoint.y);
    }

    ctx.drawImage(
      image,
      x,
      y,
      width,
      height,
    );
  }
  ctx.restore();
};

export default imageDrawer;
