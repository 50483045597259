import { combineReducers } from 'redux';
import activePage from './activePageReducer';
import actions from './drawingActionsReducer';
import pages from './pagesReducer';
import temporaryActions from './temporaryDrawingActionsReducer';
import drawingTool from './drawingToolReducer';
import currentDrawing from './currentDrawingReducer';
import textDrawingTool from './textDrawingToolReducer';
import imageDrawingTool from './imageDrawingToolReducer';
import shouldUpdateThumbnail from './shouldUpdateThumbnail';
import recordingPosition from './recordingPositionReducer';
import cursorActions from './cursorActionsReducer';

export default combineReducers({
  activePage,
  actions,
  pages,
  temporaryActions,
  drawingTool,
  currentDrawing,
  textDrawingTool,
  imageDrawingTool,
  shouldUpdateThumbnail,
  recordingPosition,
  cursorActions,
});
