import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { differenceBy } from 'lodash';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import ContactsSelect from '../ContactsSelect';
import recordingService from '../../../common/services/recording.service';
import * as contactsReducer from '../../../common/reducers/contactsReducer';
import Button from '../../atoms/Button';
import { ButtonVariantTypes } from '../../atoms/Button/buttonTypes';

type Props = {
  open: boolean;
  onClose: (args?: any) => void;
  recording: any;
};

const ShareRecordingWithUsersDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  recording,
}) => {
  const [initialContacts, setInitialContacts] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const contacts = useSelector(contactsReducer.contactsWithNamesSelector);

  const onEntering = useCallback(async () => {
    const data = await recordingService.getUsersSharedWith(recording.id);
    const contactObjects = contacts.filter((c: any) =>
      data.find((d: any) => c.id === d.id)
    );
    setInitialContacts(contactObjects);
  }, [contacts, setInitialContacts, recording]);

  const onExited = useCallback(() => {
    setInitialContacts([]);
  }, [setInitialContacts]);

  const onRemoveClick = useCallback(
    async (contactToDelete: any) => {
      try {
        await recordingService.shareWithUsers(
          recording.id,
          [],
          [contactToDelete.id || contactToDelete.email]
        );

        setInitialContacts((prevValue) =>
          prevValue.filter(
            (element) =>
              element.id &&
              contactToDelete.id &&
              element.id !== contactToDelete.id
          )
        );
      } catch (error) {
        enqueueSnackbar('Unexpected error occurred, please try again', {
          autoHideDuration: 3000,
          variant: 'error',
        });
      }
    },
    [setInitialContacts, recording, enqueueSnackbar]
  );

  const onShareClick = useCallback(
    async (members: any) => {
      if (!members) return;

      try {
        setIsSubmitting(true);
        const newUserIds = differenceBy(members, initialContacts, 'id').map(
          (user: any) => user.id
        );
        await recordingService.shareWithUsers(recording.id, newUserIds, []);

        setInitialContacts((prevContacts) => [...prevContacts, ...members]);
        setIsSubmitting(false);
      } catch (error) {
        enqueueSnackbar('Unexpected error occurred, please try again', {
          autoHideDuration: 3000,
          variant: 'error',
        });
      }
    },
    [recording, initialContacts, setInitialContacts, setIsSubmitting]
  );

  const onDialogClose = useCallback(() => {
    if (isSubmitting) return;
    onClose();
  }, [isSubmitting, onClose]);

  const onDeleteIconClick = useCallback(() => {
    if (isSubmitting) return;

    onClose();
  }, [isSubmitting, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionProps={{
        onEntering,
        onExited,
      }}
    >
      <DialogTitle onClose={onDeleteIconClick}>Share recording</DialogTitle>
      <DialogContent
        sx={{
          overflow: 'hidden',
          marginBottom: 0,
        }}
      >
        <ContactsSelect
          source="shareRecording"
          onAddMembers={onShareClick}
          onRemoveMember={onRemoveClick}
          contacts={initialContacts}
          InviteButton={(props) => (
            <Button
              {...props}
              variant={ButtonVariantTypes.PRIMARY}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Share
            </Button>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ShareRecordingWithUsersDialog;
