import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { inputClasses } from '@mui/material/Input';
import * as sessionReducer from '../../../common/reducers/session/sessionReducer';
import analyticsService from '../../../common/services/analytics.service';
import sessionService from '../../../common/services/session.service';
import { updateSessionInList } from '../../../common/actions/sessionsActions';
import { createSessionSelector } from '../../../common/reducers/session';
import Input from '../../../ui/atoms/Input';
import InputSize from '../../../ui/atoms/Input/InputSize';
import InputVariant from '../../../ui/atoms/Input/InputVariant';
import Typography from '../../../ui/atoms/Typography';
import { noop } from '../../../common/constants';
import { updateSession } from '../../../common/actions/session/sessionActions';

const Container = styled(Box)(() => ({
  padding: '4px',
  border: `1px solid transparent`,
  display: 'flex',
  zIndex: 10,
  height: '40px',
}));

const StyledInput = styled(Input)(({ theme }) => ({
  [`& .${inputClasses.input}`]: {
    ...theme.typography.s4,
  },
}));

const BoardNameEditBlock: FC<React.PropsWithChildren<unknown>> = () => {
  const [isBoardNameFocused, setIsBoardNameFocused] = useState(false);
  const dispatch = useDispatch();

  const isHost = useSelector(sessionReducer.isCurrentUserHostSelector);
  const finished = useSelector(sessionReducer.finishedSelector);
  const session = useSelector(createSessionSelector());
  const sessionName = useSelector(sessionReducer.nameSelector);

  const [boardNameInputValue, setBoardNameInputValue] = useState(sessionName);

  const { enqueueSnackbar } = useSnackbar();
  const boardNameInputRef = useRef<HTMLInputElement | null>(null);

  const onChangeBoardNameInputValue = useCallback(
    (boardName: any) => {
      setBoardNameInputValue(boardName);
    },
    [setBoardNameInputValue]
  );

  const changeBoardName = useCallback(async () => {
    const oldSessioon = { ...session };
    try {
      if (boardNameInputValue.length > 80) {
        enqueueSnackbar('Maximum number of characters is 80', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setBoardNameInputValue(sessionName);

        return;
      }

      if (!boardNameInputValue.trim().length) {
        setBoardNameInputValue(sessionName);

        return;
      }

      analyticsService.event('Board Renamed');

      const updatedSession = {
        ...session,
        name: boardNameInputValue,
      };
      dispatch(updateSessionInList(updatedSession));
      dispatch(updateSession(updatedSession));

      await sessionService.renameSession(session.id, boardNameInputValue);
    } catch (error) {
      enqueueSnackbar('Unexpected error occurred, please try again', {
        variant: 'error',
        autoHideDuration: 3000,
      });

      dispatch(updateSessionInList(oldSessioon));
    }
  }, [
    boardNameInputValue,
    session,
    enqueueSnackbar,
    dispatch,
    setIsBoardNameFocused,
    sessionName,
  ]);

  const onClickBoardNameBlock = useCallback(() => {
    if (!isHost) return;
    if (finished) return;

    setIsBoardNameFocused(true);
  }, [isHost, finished, setIsBoardNameFocused]);

  useEffect(() => {
    // Todo: change input focusing logic
    if (!boardNameInputRef.current) return;

    boardNameInputRef.current.focus();
  }, [isBoardNameFocused]);

  const handleClickAway = useCallback(() => {
    setIsBoardNameFocused(false);
  }, [setIsBoardNameFocused]);

  return (
    <Container>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          onClick={!isBoardNameFocused ? onClickBoardNameBlock : noop}
          sx={(theme) => ({
            ...(!isBoardNameFocused
              ? {
                  padding: isHost ? '8px 16px' : '8px 16px 8px',
                  height: '40px',
                  border: '1px solid transparent',
                  ...(isHost
                    ? {
                        '&:hover': {
                          border: `1px solid ${theme.palette.primary.alpha50}`,
                          borderRadius: '14px',
                          cursor: 'text',
                        },
                      }
                    : {}),
                }
              : {
                  position: 'relative',
                  color: 'transparent',
                }),
          })}
          maxWidth={240}
          minWidth={40}
        >
          <Box
            sx={{
              ...(isBoardNameFocused
                ? {
                    padding: '8px 16px',
                    height: '40px',
                  }
                : {}),
            }}
          >
            <Typography
              variant="s4"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                visibility: isBoardNameFocused ? 'hidden' : 'visible',
              }}
            >
              {boardNameInputValue}
            </Typography>
          </Box>
          <Box
            sx={{
              visibility: isBoardNameFocused ? 'visible' : 'hidden',
              position: 'absolute',
              width: '100%',
              top: 0,
            }}
          >
            <StyledInput
              ref={boardNameInputRef}
              value={boardNameInputValue}
              onChange={onChangeBoardNameInputValue}
              onBlur={changeBoardName}
              variant={InputVariant.OUTLINED}
              size={InputSize.S}
              placeholder="Please enter the board name"
            />
          </Box>
        </Box>
      </ClickAwayListener>
    </Container>
  );
};

export default BoardNameEditBlock;
