import { useCallback, useState, useRef, useEffect } from 'react';
import { Controller, useWatch, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, throttle } from 'lodash';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Dialog from '../../../ui/atoms/Dialog';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import Typography from '../../../ui/atoms/Typography';
import ColorPicker from '../../../components/DrawingTools/ColorPicker/ColorPicker';
import FormulaKeyboard from './FormulaKeyboard';
import {
  hexToRgb,
  rgbToArgbNumber,
  toColor,
} from '../../../common/utils/color.utils';
import MathJax from '../../../common/utils/mathJax.util';
import analyticsService from '../../../common/services/analytics.service';
import TextArea from '../../../ui/atoms/TextArea';
import InputSize from '../../../ui/atoms/Input/InputSize';
import InputVariant from '../../../ui/atoms/Input/InputVariant';
import { updateDrawingTool } from '../../../common/actions/board/drawingToolActions';
import ToolNames from '../../../components/DrawingTools/ToolNames';
import { setShapeCurrentDrawing } from '../../../common/actions/board/currentDrawingAction';
import { getCurrentDrawing } from '../../../common/reducers/board/currentDrawingReducer';

const CreateFormulaDialog = ({
  open,
  tex,
  onClose,
  onSubmit,
  color,
  onUpdateColor,
}) => {
  const [error, setError] = useState(null);
  const [imageSource, setImageSource] = useState(null);
  const [isTextAreaWrapperFocus, setTextAreaWrapperFocus] = useState(false);

  const textFieldRef = useRef();
  const currentDrawing = useSelector(getCurrentDrawing);
  const dispatch = useDispatch();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      latex: tex || '',
    },
    mode: 'onChange',
  });

  const validateLatex = useCallback(
    (value) => {
      if (!value || value.trim() === '') {
        setError('Formula is required');
      }

      if (value.slice(-1) === '\\') {
        setError('Invalid LaTeX syntax: \\');
      }
    },
    [setError]
  );

  const generateImage = useCallback(
    throttle((value, color) => {
      try {
        const svg = MathJax.texToSvg(value, toColor(color));
        const imageSrc = URL.createObjectURL(
          new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
        );

        setImageSource(imageSrc);
        setError(null);
      } catch (err) {
        setImageSource(null);

        setError(err.message);
      } finally {
        validateLatex(value);
      }
    }, 200),
    [setImageSource, setError, validateLatex]
  );

  useEffect(() => {
    if (tex) setValue('latex', tex);
  }, [tex]);

  useEffect(() => {
    if (tex) generateImage(tex, color);
  }, [tex, generateImage, color]);

  const onSuccess = useCallback(
    handleSubmit(({ latex }) => {
      if (!latex || latex.trim() === '') {
        setError('Formula is required');
        return;
      }
      if (error) return;

      const submit = async () => {
        try {
          let result = latex.replace(/^\s+/g, '');

          if (/\\ \s*$/.test(result)) {
            result = result.replace(/\s+$/g, ' ');
          } else {
            result = result.replace(/\s+$/g, '');
          }
          await onSubmit(result, imageSource);

          analyticsService.event('Insert Formula');
        } catch (err) {
          return err.message;
        }
      };

      submit();
    }),
    [handleSubmit, onSubmit, imageSource, error, setError]
  );

  const latex = useWatch({
    control,
    name: 'latex',
  });

  const onAddSymbol = useCallback(
    (code) => {
      const latexCode = latex || '';
      const element = textFieldRef.current;
      const selectionStart = element.selectionStart;
      const selectionEnd = element.selectionEnd || selectionStart;
      const newSelectionStart = selectionStart + code.length;
      const newLatexCode =
        latexCode.slice(0, selectionStart) +
        code +
        latexCode.slice(selectionEnd);
      setValue('latex', newLatexCode);
      generateImage(newLatexCode, color);

      setTimeout(() => {
        element.focus();
        element.setSelectionRange(newSelectionStart, newSelectionStart);
      });
    },
    [generateImage, setValue, latex, color]
  );

  const onColorChange = useCallback(
    (color) => {
      onUpdateColor(color);
      const latexCode = latex || '';

      const { r, g, b } = hexToRgb(color);
      const alpha = 1;
      const argbColor = rgbToArgbNumber({
        a: alpha * 255,
        r,
        g,
        b,
      });

      generateImage(latexCode, argbColor);
      dispatch(updateDrawingTool(ToolNames.Formula, { color: argbColor }));
      if (currentDrawing) {
        const clonedCurrentDrawing = cloneDeep(currentDrawing);
        clonedCurrentDrawing.paint.color = argbColor;

        dispatch(setShapeCurrentDrawing(clonedCurrentDrawing));
      }
    },
    [latex, onUpdateColor, generateImage, dispatch]
  );

  const onToggleTextAreaWrapperFocus = useCallback(() => {
    setTextAreaWrapperFocus((prevState) => !prevState);
  }, [setTextAreaWrapperFocus]);

  const onInputChange = useCallback(
    (event) => {
      generateImage(event.target.value, color);
    },
    [generateImage, color]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      component="form"
      TransitionProps={{
        onEntered: () => {
          textFieldRef.current.focus();
          const position = textFieldRef.current.value.length;
          textFieldRef.current.setSelectionRange(position, position);
        },
      }}
      onSubmit={onSuccess}
    >
      <DialogTitle onClose={onClose}>LaTeX formula editor</DialogTitle>
      <DialogContent>
        <Box display="flex" gap={1.5} mb={1}>
          <Box
            display="flex"
            flex={1}
            height={150}
            padding="6px"
            onFocus={onToggleTextAreaWrapperFocus}
            onBlur={onToggleTextAreaWrapperFocus}
            sx={(theme) => ({
              backgroundColor: theme.background.bg4,
              '&:hover': {
                backgroundColor: theme.background.bg5,
              },
              ...(isTextAreaWrapperFocus
                ? {
                    backgroundColor: theme.background.bg5,
                  }
                : {}),
              borderRadius: '12px',
            })}
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Controller
                name="latex"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextArea
                    ref={textFieldRef}
                    variant={InputVariant.CONTAINED}
                    size={InputSize.M}
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      onInputChange(event);
                    }}
                    rows={15}
                    maxRows={15}
                    placeholder="Type LaTeX code here, e.g. \delta"
                    resize="none"
                    height="150px !important"
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            flex={1}
            sx={(theme) => ({
              padding: 1,
              border: `1px solid ${theme.background.bg6}`,
            })}
            width="100%"
            height={150}
            borderRadius="14px"
            position="relative"
          >
            <Box display="flex" position="absolute" top={10}>
              <Typography
                variant="p4"
                sx={(theme) => ({
                  color: theme.text.t6,
                })}
              >
                Preview
              </Typography>
            </Box>
            {latex && imageSource && (
              <Box display="flex" justifyContent="center">
                <img
                  style={{ width: '100%', height: '100px' }}
                  src={imageSource}
                  alt="Formula image"
                />
              </Box>
            )}
          </Box>
        </Box>
        {error && (
          <Box>
            <Typography variant="p5" color="error">
              {error}
            </Typography>
          </Box>
        )}
        <Box display="flex" gap={1.5} mb={2}>
          <Typography variant="p5">
            Get familiar{' '}
            <Typography
              component={Link}
              href="https://www.math.brown.edu/johsilve/ReferenceCards/TeXRefCard.v1.5.pdf"
              target="_blank"
              variant="l2"
              color="primary"
            >
              with the commands.
            </Typography>
          </Typography>
          <Typography variant="p5">
            Check popular{' '}
            <Typography
              component={Link}
              href="http://www.equationsheet.com"
              target="_blank"
              variant="l2"
              color="primary"
            >
              equation examples
            </Typography>
          </Typography>
        </Box>
        <Box mb={1.25}>
          <ColorPicker onChange={onColorChange} />
        </Box>
        <Box display="flex" justifyContent="left" flexWrap="wrap">
          <FormulaKeyboard onKeyClick={onAddSymbol} />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton type="submit">
          Insert
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFormulaDialog;
