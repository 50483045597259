import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  size?: number;
};

const SpinnerAdornment: FC<React.PropsWithChildren<Props>> = ({
  size = 24,
}) => {
  return (
    <CircularProgress
      size={size}
      color="inherit"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
      }}
    />
  );
};

export default SpinnerAdornment;
