import groupsService from '../services/groups.service';
import * as contactsActions from './contactsActions';

const GET_GROUP_LIST_REQUEST = Symbol('GET_GROUP_LIST_REQUEST');
const GET_GROUP_LIST_SUCCESS = Symbol('GET_GROUP_LIST_SUCCESS');
const GET_GROUP_LIST_FAILURE = Symbol('GET_GROUP_LIST_FAILURE');

const DELETE_GROUP = Symbol('DELETE_GROUP');

// ------------------- Get group list actions -------------------
const groupListRequest = () => ({
  type: GET_GROUP_LIST_REQUEST,
});

const groupListSuccess = (payload) => ({
  type: GET_GROUP_LIST_SUCCESS,
  payload,
});

const groupListFailure = (error) => ({
  type: GET_GROUP_LIST_FAILURE,
  error,
});

const getGroupList = () => async (dispatch) => {
  dispatch(groupListRequest());

  try {
    const payload = await groupsService.getGroupList();

    dispatch(groupListSuccess(payload));
    return payload;
  } catch (error) {
    dispatch(groupListFailure(error));
    console.log('Error: ', error);
  }
  return null;
};

// ------------------- Get group list actions -------------------

// ------------------- Delete group actions -------------------

const deleteGroup = (groupId) => ({
  type: DELETE_GROUP,
  payload: {
    id: groupId,
  },
});

// ------------------- Delete group actions -------------------

const updateGroup = (group) => {
  return (dispatch) => {
    dispatch(contactsActions.invalidateIfNeeded(group.contacts));
    dispatch(getGroupList());
  };
};

export {
  getGroupList,
  updateGroup,
  deleteGroup,
  GET_GROUP_LIST_REQUEST,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_FAILURE,
  DELETE_GROUP,
};
