import { useEffect } from 'react';
import environment from '../config/environment';

const InstallHubspotChat = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `//js-na1.hs-scripts.com/${environment.hubspot.id}.js`;
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default InstallHubspotChat;
