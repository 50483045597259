import React from 'react';

const CanvasContext = React.createContext({
  boardWidth: 0,
  boardHeight: 0,
  canvasWidth: 0,
  canvasHeight: 0,
  scale: 1,
  zoom: 1,
  scaledBoardWidth: 0,
  scaledBoardHeight: 0,
  scaledBoardOriginX: 0,
  scaledBoardOriginY: 0,
  originX: 0,
  originY: 0,
  originOffsetY: 0,
  onZoom: (options) => {},
  minimapOpen: false,
  setMinimapOpen: (active) => {},
  setViewportCenterPosition: (position) => {},
});

function withCanvasContext(Component) {
  return function WrapperComponent(props) {
    return (
      <CanvasContext.Consumer>
        {(value) => <Component {...props} {...value} />}
      </CanvasContext.Consumer>
    );
  };
}

export { CanvasContext as default, withCanvasContext };
