import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VirtuosoGrid } from 'react-virtuoso';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import {
  PDFDocumentProxy,
  PDFPageProxy,
} from 'pdfjs-dist/types/src/display/api';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import * as pagesReducer from '../../../common/reducers/board/pagesReducer';
import CheckboxBlankOutlineIcon from '../../atoms/Icons/CheckboxBlankOutlineIcon';
import CheckboxMarkedIcon from '../../atoms/Icons/CheckboxMarkedIcon';
import { pagesCountLimitSelector } from '../../../common/reducers/session/sessionReducer';
import PagesLimitNotification, {
  SectionName,
} from '../../../components/Notifications/PagesLimitNotification/PagesLimitNotification';

type PageDimensions = {
  width: number;
  height: number;
};

type CanvasData = {
  width: number;
  height: number;
  url: string;
};

type Props = {
  open: boolean;
  handleSuccess: () => void;
  handleClose: () => void;
  setSelectedPages: (
    selectedPages: (CanvasData | Promise<CanvasData>)[]
  ) => void;
  pdfFile: any;
};

const PasswordResponses = {
  NEED_PASSWORD: 1,
  INCORRECT_PASSWORD: 2,
};

const ITEM_SIZE = 396;
const MAX_PAGES = 50;

const ItemContainer = styled('div')(
  ({ theme }) => `
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex: none;
    align-content: center;
    justify-content: center;

    ${theme.breakpoints.up('md')} {
      width: 50%;
    }

    ${theme.breakpoints.up('lg')} {
      width: 33%;
    }
  `
);

const ItemWrapper = styled('div')`
  width: ${ITEM_SIZE}px;
  height: ${ITEM_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PdfDocument = styled(Document)({
  height: '100%',
  '& .react-pdf__message--loading': {
    height: '100%',
  },
});

const desiredWidth = 1280;
const desiredHeight = 720;

const getCanvasData = async (page: PDFPageProxy) => {
  const canvas = document.createElement('canvas');
  const viewport = page.getViewport({ scale: 1 });
  const scale = Math.max(
    desiredWidth / viewport.width,
    desiredHeight / viewport.height
  );
  const scaledViewport = page.getViewport({ scale });
  const { width, height } = scaledViewport;

  canvas.width = width;
  canvas.height = height;

  await page.render({
    canvasContext: canvas.getContext('2d')!,
    viewport: scaledViewport,
  }).promise;

  return {
    width,
    height,
    url: canvas.toDataURL(),
  };
};

const SelectPdfPagesDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  handleSuccess,
  handleClose,
  setSelectedPages,
  pdfFile,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pagesLoading, setPagesLoading] = useState(true);
  const [cachedPagesDimensions, setCachedPagesDimensions] = useState<
    PageDimensions[]
  >([]);
  const [selectedPageIds, setSelectedPageIds] = useState<number[]>([]);
  const [loadedPages, setLoadedPages] = useState<PDFPageProxy[]>([]);
  const [canvasDatas, setCanvasDatas] = useState<{
    [key: number]: CanvasData | Promise<CanvasData>;
  }>({});

  const existingPagesCount = useSelector(pagesReducer.getPageCount);
  const pagesLimitCount = useSelector(pagesCountLimitSelector);

  const onDocumentLoadSuccess = useCallback(
    (pdf: PDFDocumentProxy) => {
      setNumPages(pdf.numPages);
      setPagesLoading(false);
      const promises = Array.from(
        { length: pdf.numPages },
        (v, i) => i + 1
      ).map((pageNumber) => {
        return pdf.getPage(pageNumber);
      });

      // Assuming all pages may have different heights. Otherwise we can just
      // load the first page and use its height for determining all the row
      // heights.
      Promise.all(promises).then((pages: PDFPageProxy[]) => {
        setLoadedPages(pages);

        const pageDimensions: PageDimensions[] = [];

        for (const page of pages) {
          const wRatio = page.view[2] / ITEM_SIZE;
          const hRatio = page.view[3] / ITEM_SIZE;
          const scale = Math.max(wRatio, hRatio);
          pageDimensions[page._pageIndex] = {
            width: page.view[2] / scale,
            height: page.view[3] / scale,
          };
        }

        setCachedPagesDimensions(pageDimensions);
      });
    },
    [setNumPages, setPagesLoading, setCachedPagesDimensions, setLoadedPages]
  );

  const { enqueueSnackbar } = useSnackbar();

  const remainingPages = MAX_PAGES - (existingPagesCount || 0);
  const maxPagesCount =
    loadedPages.length < remainingPages ? loadedPages.length : remainingPages;
  const reachedSelectedPagesCount =
    loadedPages.length &&
    !!selectedPageIds.length &&
    selectedPageIds.length >= remainingPages;

  const exceededPageLimit = useMemo(
    () => !selectedPageIds.length && !remainingPages,
    [selectedPageIds.length, remainingPages]
  );

  const selectPage = useCallback(
    (pageNumber: number, selected: boolean) => {
      if (selected && (exceededPageLimit || reachedSelectedPagesCount)) {
        enqueueSnackbar('You have reached maximum pages count', {
          autoHideDuration: 3000,
          variant: 'error',
          preventDuplicate: true,
        });
        return;
      }
      const newSelectedPages = selectedPageIds.filter((i) => i !== pageNumber);

      if (selected) {
        newSelectedPages.push(pageNumber);
      }

      const sortedIds = newSelectedPages.sort((a, b) => a - b);

      setSelectedPageIds(sortedIds);

      const newCanvasDatas = {
        ...canvasDatas,
        [pageNumber]: getCanvasData(loadedPages[pageNumber]),
      };

      setCanvasDatas(newCanvasDatas);
      setSelectedPages(sortedIds.map((i) => newCanvasDatas[i]));
    },
    [
      loadedPages,
      exceededPageLimit,
      reachedSelectedPagesCount,
      selectedPageIds,
      setSelectedPageIds,
      setSelectedPages,
      canvasDatas,
      setCanvasDatas,
      enqueueSnackbar,
    ]
  );

  const selectAllPages = useCallback(() => {
    if (reachedSelectedPagesCount) {
      setSelectedPageIds([]);
      setSelectedPages([]);
      return;
    }

    const newPageIds = loadedPages
      .map((_, i) => i)
      .filter((i) => i < maxPagesCount);
    setSelectedPageIds(newPageIds);
    setSelectedPages(newPageIds.map((i) => getCanvasData(loadedPages[i])));

    const canvasDatas = newPageIds.reduce(
      (acc, pageNumber) => ({
        ...acc,
        [pageNumber]: getCanvasData(loadedPages[pageNumber]),
      }),
      {}
    );
    setCanvasDatas(canvasDatas);
  }, [
    maxPagesCount,
    loadedPages,
    reachedSelectedPagesCount,
    setSelectedPages,
    setSelectedPageIds,
    setCanvasDatas,
  ]);

  const deselectAllPages = useCallback(() => {
    setSelectedPageIds([]);
  }, [setSelectedPageIds]);

  const placeholderStyles = useMemo(() => {
    const firstPageDimensions = cachedPagesDimensions[0];
    return {
      width: firstPageDimensions?.width || (ITEM_SIZE * 9) / 16,
      height: firstPageDimensions?.height || ITEM_SIZE,
    };
  }, [cachedPagesDimensions]);

  const onEnter = useCallback(() => {
    setSelectedPageIds([]);
    setCanvasDatas({});
    setNumPages(null);
    setLoadedPages([]);
  }, [setSelectedPageIds, setCanvasDatas, setNumPages, setLoadedPages]);

  const onPassword = useCallback(
    (callback: any, reason: any) => {
      const callbackProxy = (password: string | null) => {
        if (password === null) {
          handleClose();
        }

        callback(password);
      };

      switch (reason) {
        case PasswordResponses.NEED_PASSWORD: {
          const password = prompt('Enter the password to open this PDF file.');
          callbackProxy(password);
          break;
        }
        case PasswordResponses.INCORRECT_PASSWORD: {
          const password = prompt('Invalid password. Please try again.');
          callbackProxy(password);
          break;
        }
        default:
          throw new Error('Unknown pdf password reason.');
      }
    },
    [handleClose]
  );

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown
      TransitionProps={{
        onEntering: onEnter,
      }}
      keepMounted
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box flex={1}>
          <Typography
            variant={TypographyType.s2}
            sx={(theme) => ({
              color: theme.text.t10,
            })}
          >
            Select pages to import
          </Typography>
        </Box>
        {!!numPages && (
          <Box flex={1} textAlign="center">
            <Typography
              variant={TypographyType.s4}
              sx={(theme) => ({
                color: theme.text.t7,
              })}
            >
              Selected {selectedPageIds.length}/
              {!!exceededPageLimit ? pagesLimitCount : maxPagesCount} of{' '}
              {numPages}
            </Typography>
          </Box>
        )}
        <Box flex={1} textAlign="right">
          {!exceededPageLimit && (
            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              gap={0.5}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Box
                onClick={
                  selectedPageIds.length ? deselectAllPages : selectAllPages
                }
              >
                {selectedPageIds.length ? (
                  <CheckboxMarkedIcon />
                ) : (
                  <CheckboxBlankOutlineIcon />
                )}
              </Box>
              <Box
                onClick={
                  selectedPageIds.length ? deselectAllPages : selectAllPages
                }
              >
                <Typography variant="b4">
                  {selectedPageIds.length ? 'Deselect all' : 'Select all'}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          height: '80vh',
          padding: 0,
          margin: 0,
        }}
      >
        {!!exceededPageLimit && (
          <Box maxWidth={828} margin="14px auto">
            <PagesLimitNotification
              title="If you already have 50 pages."
              message="To upload a file, please delete some pages."
              sectionName={SectionName.SELECT_PDF_DIALOG}
            />
          </Box>
        )}
        {!!reachedSelectedPagesCount && (
          <Box maxWidth={800} margin="14px auto">
            <PagesLimitNotification
              title="You cannot exceed the 50-page limit."
              sectionName={SectionName.SELECT_PDF_DIALOG}
            />
          </Box>
        )}
        <PdfDocument
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onPassword={onPassword}
          loading={
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          {!pagesLoading && cachedPagesDimensions.length > 0 && !!numPages && (
            <VirtuosoGrid
              totalCount={numPages}
              overscan={20}
              components={{
                Item: ItemContainer,
                // @ts-ignore
                List: ListContainer,
                ScrollSeekPlaceholder: () => (
                  <ItemContainer>
                    <ItemWrapper>
                      <Paper sx={placeholderStyles} />
                    </ItemWrapper>
                  </ItemContainer>
                ),
              }}
              itemContent={(index) => (
                <ItemWrapper>
                  <Paper
                    sx={{
                      width: cachedPagesDimensions[index]?.width ?? 0,
                      height: cachedPagesDimensions[index]?.height ?? 0,
                    }}
                  >
                    {cachedPagesDimensions[index] && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        pb={2}
                      >
                        <Box position="relative">
                          <Page
                            pageIndex={index}
                            width={cachedPagesDimensions[index].width}
                            height={cachedPagesDimensions[index].height}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                          <Box
                            position="absolute"
                            top={0}
                            right={0}
                            sx={{
                              opacity: 0.8,
                              borderBottomLeftRadius: 8,
                            }}
                            bgcolor="grey.200"
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                selectPage(
                                  index,
                                  !(selectedPageIds.indexOf(index) !== -1)
                                )
                              }
                              sx={{
                                border: 'none',
                              }}
                            >
                              {selectedPageIds.indexOf(index) !== -1 ? (
                                <CheckboxMarkedIcon color="primary" />
                              ) : (
                                <CheckboxBlankOutlineIcon color="primary" />
                              )}
                            </IconButton>
                          </Box>
                          <Box
                            position="absolute"
                            py={1}
                            top={0}
                            left="50%"
                            sx={{
                              translate: '-50%',
                            }}
                          >
                            {index + 1}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Paper>
                </ItemWrapper>
              )}
            />
          )}
        </PdfDocument>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={handleClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton
          onClick={handleSuccess}
          disabled={!selectedPageIds.length}
        >
          Next
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default SelectPdfPagesDialog;
