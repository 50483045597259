import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ReactComponent as QuizLogo } from '../../../../assets/images/quiz.svg';

type Props = {
  title: string,
};

const QuestionDialogTitle = ({
  title,
}: Props) => (
  <DialogTitle>
    <Box display="flex" alignItems="center">
      <Box pr={2}>
        <QuizLogo width={36} height={36} />
      </Box>
      <Box>
        <Typography component="h2" variant="h6" align="center" color="textSecondary">
          {title}
        </Typography>
      </Box>
    </Box>
  </DialogTitle>
);

export default QuestionDialogTitle;
