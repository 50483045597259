import { FC } from 'react';
import { SxProps } from '@mui/material';
import Badge, { badgeClasses } from '@mui/material/Badge';
import StatusType from './type';
import { buttonTextStyle } from '../Typography/typographyStyles';

type Props = {
  invisible: boolean;
  type?: StatusType;
  content?: string;
  sx?: SxProps;
};

const Status: FC<React.PropsWithChildren<Props>> = ({
  invisible,
  type,
  content,
  children,
  sx = {},
}) => (
  <Badge
    invisible={invisible}
    badgeContent={content}
    sx={(theme) => ({
      [`& .${badgeClasses.badge}`]: {
        ...buttonTextStyle.b6,
        transform: 'none',
        padding: '5px 8px 5px 8px',
        borderRadius: '10px 0px 10px 10px',
        height: '24px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        fontFamily: 'Barlow',
        textTransform: 'capitalize',
        ...(type
          ? {
              backgroundColor:
                type === StatusType.LIVE
                  ? theme.status.live
                  : theme.status.closed,
              color: type === StatusType.LIVE ? theme.text.t1 : theme.text.t7,
            }
          : {}),
        display: invisible ? 'none' : 'block',
      },
      ...sx,
    })}
  >
    {children}
  </Badge>
);

export default Status;
