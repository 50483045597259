import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../common/utils/compose.utils';
import * as uiReducer from '../../../common/reducers/uiReducer';
import { withCanvasContext } from '../Contexts/CanvasContext';
import GridType from '../../../common/drawingActions/gridType';
import drawers from './drawers';
import BACKGROUND_COLOR from '../../../common/constants/boardBackgroundColor';

const NET_COLOR = '#acacac';
const NET_WIDTH = 1;

const gridDrawers = {
  [GridType.GRID]: (ctx, originX, originY, width, height, lineDistance) => {
    ctx.save();
    ctx.strokeStyle = NET_COLOR;
    ctx.lineWidth = NET_WIDTH;
    ctx.beginPath();
    for (let i = originY + lineDistance; i <= height; i += lineDistance) {
      ctx.moveTo(originX, i);
      ctx.lineTo(width, i);
    }
    for (let i = originX + lineDistance; i <= width; i += lineDistance) {
      ctx.moveTo(i, originY);
      ctx.lineTo(i, height);
    }
    ctx.stroke();
    ctx.restore();
  },
  [GridType.LINE]: (ctx, originX, originY, width, height, lineDistance) => {
    ctx.save();
    ctx.strokeStyle = NET_COLOR;
    ctx.lineWidth = NET_WIDTH;
    ctx.beginPath();
    for (let i = originY + lineDistance; i <= height; i += lineDistance) {
      ctx.moveTo(originX, i);
      ctx.lineTo(width, i);
    }
    ctx.stroke();
    ctx.restore();
  },
  [GridType.DOT]: (ctx, originX, originY, width, height, lineDistance) => {
    ctx.save();
    ctx.strokeStyle = NET_COLOR;

    ctx.beginPath();
    for (let y = originY + lineDistance; y <= height; y += lineDistance) {
      ctx.setLineDash([NET_WIDTH, lineDistance - NET_WIDTH]);
      ctx.moveTo(originX, y);
      ctx.lineTo(width, y);
    }

    ctx.stroke();
    ctx.restore();
  },
};

const enhancer = compose(
  withCanvasContext,
  connect((state) => ({
    deviceDpi: uiReducer.dpiSelector(state),
  }))
);

class BackgroundLayer extends React.Component {
  componentDidMount() {
    window.requestAnimationFrame(this.draw);
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height ||
      nextProps.boardDpi !== this.props.boardDpi ||
      nextProps.deviceDpi !== this.props.deviceDpi ||
      nextProps.zoom !== this.props.zoom ||
      nextProps.page !== this.props.page ||
      nextProps.scale !== this.props.scale ||
      nextProps.originX !== this.props.originX ||
      nextProps.originY !== this.props.originY ||
      (nextProps.images &&
        this.props.images &&
        (nextProps.images.length !== this.props.images.length ||
          nextProps.images.some(
            (a, i) =>
              !this.props.images[i] || this.props.images[i].image !== a.image
          )))
    );
  }

  componentDidUpdate() {
    window.requestAnimationFrame(this.draw);
  }

  setDrawingContext = (ref) => {
    if (!ref) return;
    if (this.props.setRef) {
      this.props.setRef(ref);
    }

    this.drawingContext = ref.getContext('2d');
  };

  getLineDistance = () => {
    const { boardDpi, deviceDpi, scale, zoom } = this.props;
    const power =
      Math.floor(Math.log2((boardDpi * zoom * scale) / deviceDpi)) * -1;
    return 5 * boardDpi * (1.0 / 25.4) * zoom * scale * 2 ** power;
  };

  drawingContext = null;

  draw = async () => {
    if (!this.drawingContext) return;
    this.drawBackgroundColor();

    if (this.props.drawBackgroundGrid) {
      this.drawBackgroundGrid();
    }

    this.drawImages();
  };

  drawBackgroundColor = () => {
    const { drawingContext } = this;
    const {
      originX,
      originY,
      boardWidth,
      boardHeight,
      zoom,
      scale,
      page = {},
      canvasWidth,
      canvasHeight,
    } = this.props;
    drawingContext.clearRect(0, 0, canvasWidth, canvasHeight);

    drawingContext.save();
    drawingContext.fillStyle = page.color || BACKGROUND_COLOR;

    drawingContext.scale(zoom, zoom);
    drawingContext.translate(-originX, -originY);
    drawingContext.scale(scale, scale);

    drawingContext.fillRect(0, 0, boardWidth, boardHeight);

    drawingContext.setTransform(1, 0, 0, 1, 0, 0);
    drawingContext.restore();
  };

  drawBackgroundGrid = () => {
    const { drawingContext } = this;
    const {
      width,
      height,
      page = {},
      zoom,
      originX,
      originY,
      scale,
    } = this.props;

    const gridType = page.backgroundType || GridType.NONE;
    if (gridType === GridType.NONE) return;

    const lineDistance = this.getLineDistance();
    gridDrawers[gridType](
      drawingContext,
      Math.trunc(originX / lineDistance) * lineDistance -
        originX * scale * zoom,
      Math.trunc(originY / lineDistance) * lineDistance -
        originY * scale * zoom,
      width,
      height,
      lineDistance
    );
  };

  drawImages = () => {
    const { drawingContext } = this;
    const { images, scale, zoom, originX, originY } = this.props;
    drawingContext.scale(zoom, zoom);
    drawingContext.translate(-originX, -originY);
    drawingContext.scale(scale, scale);

    images.forEach((action) => {
      const drawer = drawers['imageMeta'];
      drawer(drawingContext, action);
    });
    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  render() {
    const { width, height } = this.props;

    return (
      <canvas ref={this.setDrawingContext} width={width} height={height} />
    );
  }
}

export default enhancer(BackgroundLayer);
