import * as contactsActions from '../contactsActions';
import fireBaseService from '../../services/firebaseService';

const SET_CODE = Symbol('SET_CODE');
const CLEAR_SESSION = Symbol('CLEAR_SESSION');
const SESSION_CONNECTED = Symbol('SESSION_CONNECTED');
const SESSION_DISCONNECTED = Symbol('SESSION_DISCONNECTED');
const SET_END_TIME = Symbol('SET_END_TIME');
const SET_START_TIME = Symbol('SET_START_TIME');
const SET_DIMENSIONS = Symbol('SET_DIMENSIONS');
const SET_SESSION = Symbol('SET_SESSION');
const SET_CONFERENCE = Symbol('SET_CONFERENCE');
const SET_SHARED = Symbol('SET_SHARED');
const SET_FINISHED = Symbol('SET_FINISHED');
const UPDATE_SESSION = Symbol('UPDATE_SESSION');
const UPDATE_SESSION_OPTIONS = Symbol('UPDATE_SESSION_OPTIONS');
const UPDATE_PUBLIC_LINK = Symbol('UPDATE_PUBLIC_LINK');
const SET_OPEN_SOURCE = Symbol('SET_OPEN_SOURCE');
const INCREMENT_CANVAS_TOUCH = Symbol('INCREMENT_CANVAS_TOUCH');
const RESET_CANVAS_TOUCH = Symbol('RESET_CANVAS_TOUCH');
const CHANGE_ASSESSMENT_INFO = Symbol('CHANGE_ASSESSMENT_INFO');

const setStartTime = (startTime) => ({
  type: SET_START_TIME,
  startTime,
});

const setEndTime = (endTime) => ({
  type: SET_END_TIME,
  endTime,
});

const sessionConnected = () => ({
  type: SESSION_CONNECTED,
});

const sessionDisconnected = () => ({
  type: SESSION_DISCONNECTED,
});

const setCode = (code) => ({
  type: SET_CODE,
  code,
});

const setDimensions = (displayMetrics) => ({
  type: SET_DIMENSIONS,
  displayMetrics,
});

const disconnectFromSession = () => async (dispatch) => {
  await fireBaseService.disconnect();
  dispatch(sessionDisconnected());
};

const setSession = (session) => {
  return (dispatch) => {
    if (session.invites && session.invites.contacts) {
      dispatch(contactsActions.invalidateIfNeeded(session.invites.contacts));
    }

    dispatch({
      type: SET_SESSION,
      ...session,
    });
  };
};

const setConference = (conference) => ({
  type: SET_CONFERENCE,
  payload: conference,
});

const setSessionOptions = ({
  chat,
  drawingEnabledOnJoin,
  mutedOnJoin,
  participantsPageNavigation,
}) => ({
  type: UPDATE_SESSION_OPTIONS,
  payload: {
    chat,
    drawingEnabledOnJoin,
    mutedOnJoin,
    participantsPageNavigation,
  },
});

const clearSession = () => ({
  type: CLEAR_SESSION,
});

const setShared = (shared) => ({
  type: SET_SHARED,
  shared,
});

const setFinished = (finished) => ({
  type: SET_FINISHED,
  finished,
});

const changeSession = (session) => ({
  type: UPDATE_SESSION,
  session,
});

const updateSession = (session) => {
  return (dispatch) => {
    if (session.invites && session.invites.contacts) {
      dispatch(contactsActions.invalidateIfNeeded(session.invites.contacts));
    }

    dispatch(changeSession(session));
  };
};

const updatePublicLink = (publicLink) => ({
  type: UPDATE_PUBLIC_LINK,
  publicLink,
});

const setOpenSource = (source) => ({
  type: SET_OPEN_SOURCE,
  payload: source,
});

const incrementCanvasTouch = () => ({
  type: INCREMENT_CANVAS_TOUCH,
});

const resetCanvasTouch = () => ({
  type: RESET_CANVAS_TOUCH,
});

const changeAssessmentInfo = (info) => ({
  type: CHANGE_ASSESSMENT_INFO,
  info,
});

export {
  setStartTime,
  setEndTime,
  sessionConnected,
  sessionDisconnected,
  setCode,
  setDimensions,
  disconnectFromSession,
  clearSession,
  setSession,
  setConference,
  setSessionOptions,
  setShared,
  setFinished,
  updateSession,
  updatePublicLink,
  setOpenSource,
  incrementCanvasTouch,
  resetCanvasTouch,
  changeAssessmentInfo,
  SET_CODE,
  CLEAR_SESSION,
  SESSION_CONNECTED,
  SESSION_DISCONNECTED,
  SET_END_TIME,
  SET_START_TIME,
  SET_DIMENSIONS,
  SET_SESSION,
  SET_CONFERENCE,
  SET_SHARED,
  SET_FINISHED,
  UPDATE_SESSION,
  UPDATE_SESSION_OPTIONS,
  UPDATE_PUBLIC_LINK,
  SET_OPEN_SOURCE,
  INCREMENT_CANVAS_TOUCH,
  RESET_CANVAS_TOUCH,
  CHANGE_ASSESSMENT_INFO,
};
