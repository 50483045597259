import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AssessmentSidebarContext } from '../AssessmentSidebarContext';
import useDialog from '../../../../../common/hooks/useDialog';
import { AssessmentSidebarContextProps } from '../AssessmentSidebarContext/AssessmentSidebarContext';

export const AssessmentSidebarProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const history = useHistory();
  const assessmentSidebar = useDialog();

  const onOpenQuizSidebar = useCallback(() => {
    assessmentSidebar.onOpen();
  }, [assessmentSidebar.onOpen]);

  const onCloseQuizSidebar = useCallback(() => {
    assessmentSidebar.onClose();
    history.push('/');
  }, [assessmentSidebar.onClose]);

  const value = useMemo<AssessmentSidebarContextProps>(
    () => ({
      open: assessmentSidebar.open,
      onOpen: onOpenQuizSidebar,
      onClose: onCloseQuizSidebar,
    }),
    [assessmentSidebar.open, onOpenQuizSidebar, onCloseQuizSidebar]
  );

  return (
    <AssessmentSidebarContext.Provider value={value}>
      {children}
    </AssessmentSidebarContext.Provider>
  );
};
