import { createContext } from 'react';

const SessionContext = createContext({
  openInviteDialog: () => {},
  openInfiniteDialog: () => {},
  reshareSession: async () => {},
  shareSession: async () => {},
});

export default SessionContext;
