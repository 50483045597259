import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import Box from '@mui/material/Box';
import Typography from '../../../../../../ui/atoms/Typography';
import Button from '../../../../../../ui/atoms/Button';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import { SectionCard } from '../../../../components/SectionCard';
import DeleteAccountReasonDialog from '../../../../../../ui/molecules/DeleteAccountReasonDialog';
import useDialog from '../../../../../../common/hooks/useDialog';
import * as authActions from '../../../../../../common/actions/authActions';
import authService from '../../../../../../common/services/auth.service';

export const DeleteAccount: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [deleteReasons, setDeleteReasons] = useState([]);
  const deleteAccountDialog = useDialog();

  const dispatch = useDispatch();
  const signOut = () =>
    useCallback(dispatch(authActions.signOut()), [dispatch]);
  const navigateTo = useCallback(
    (path: any) => dispatch(push(path)),
    [dispatch]
  );

  useEffect(() => {
    const getReasons = async () => {
      const response = await authService.getDeleteAccountReasons();
      setDeleteReasons(response.data);
    };

    getReasons();
  }, [setDeleteReasons]);

  const onDeleteAccount = useCallback(() => {
    signOut();
    navigateTo('/');
  }, [signOut, navigateTo]);

  return (
    <>
      <SectionCard title="Delete Account">
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: '16px',
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Typography
              variant="p3"
              sx={(theme) => ({
                color: theme.text.t8,
              })}
            >
              Account deletion is final and irreversible. There will be no way
              to restore your account.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              paddingTop: '20px',
              [theme.breakpoints.up('sm')]: {
                paddingTop: 0,
              },
            })}
          >
            <Button
              variant={ButtonVariantTypes.PRIMARY}
              size={ButtonSizeTypes.S}
              onClick={deleteAccountDialog.onOpen}
              color={ButtonColorTypes.ERROR}
            >
              Delete account
            </Button>
          </Box>
        </Box>
      </SectionCard>
      <DeleteAccountReasonDialog
        open={deleteAccountDialog.open}
        onClose={deleteAccountDialog.onClose}
        onSuccess={onDeleteAccount}
        reasons={deleteReasons}
      />
    </>
  );
};
