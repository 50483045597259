import { styled } from '@mui/material/styles';
import MuiDialog, { DialogProps, dialogClasses } from '@mui/material/Dialog';

type CustomDialogProps = DialogProps & {
  maxDialogWidth?: number | string;
};

/** Todo: maxDialogWidth necessary because we can't override mui maxWidth type to accept number values.
Change it with maxWidth when it is possible */

const Dialog = styled(MuiDialog)<CustomDialogProps>(({ theme, fullWidth, maxDialogWidth, fullScreen }) => ({
  [`& .${dialogClasses.paper}`]: {
    borderRadius: !fullScreen ? 14 : 0,
    padding: '14px 0px 24px 0px',
    border: '1px solid',
    borderColor: theme.background.bg5,
    backgroundColor: theme.background.bg2,
    ...(!fullWidth
      ? {
          width: '450px',
        }
      : {}),
    ...(maxDialogWidth ? {
      maxWidth: maxDialogWidth,
    } : {}),
  },
}));

export default Dialog;
