const numberToRgba = (num) => {
  let tempParam = num;
  tempParam >>>= 0;
  const b = tempParam & 0xff;
  const g = (tempParam & 0xff00) >>> 8;
  const r = (tempParam & 0xff0000) >>> 16;
  const a = ((tempParam & 0xff000000) >>> 24) / 255;
  return {
    r,
    g,
    b,
    a,
  };
};

const componentToRgb = (hexNumber) => parseInt(hexNumber, 16);

const hexToRgb = (hex) => {
  const [_, red, green, blue] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = componentToRgb(red);
  const g = componentToRgb(green);
  const b = componentToRgb(blue);

  return { r, g , b};
};

const rgbaToHexWithoutAlpha = (color) => {
  const [red, green, blue] = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

  return `#${(1 << 24 | red << 16 | green << 8 | blue).toString(16).slice(1).toUpperCase()}`;
};

const toRgbaString = (r, g, b, a) => `rgba(${r},${g},${b},${a})`;

const toColor = (num) => {
  const { r, g, b, a } = numberToRgba(num);
  return toRgbaString(r, g, b, a);
};

const compositeAlpha = (foregroundAlpha, backgroundAlpha) => {
  return 255 - ((255 - foregroundAlpha) * (255 - backgroundAlpha)) / 255;
};

const compositeComponent = (fgC, fgA, bgC, bgA, a) => {
  if (a === 0) return 0;

  return (255 * fgC * fgA + bgC * bgA * (255 - fgA)) / (a * 255);
};

const compositeColors = (foreground, background) => {
  const bgAlpha = background.a;
  const fgAlpha = foreground.a;
  const a = compositeAlpha(fgAlpha, bgAlpha);

  const r = compositeComponent(foreground.r, fgAlpha, background.r, bgAlpha, a);
  const g = compositeComponent(foreground.g, fgAlpha, background.g, bgAlpha, a);
  const b = compositeComponent(foreground.b, fgAlpha, background.b, bgAlpha, a);

  return {
    a,
    r,
    g,
    b,
  };
};

const toARGB = (color) => {
  const { r, g, b, a } = color;

  return {
    r,
    g,
    b,
    a: a * 255,
  };
};

const toXYZ = (color) => {
  const { r, g, b } = color;

  let sr = r / 255.0;
  sr = sr < 0.04045 ? sr / 12.92 : ((sr + 0.055) / 1.055) ** 2.4;
  let sg = g / 255.0;
  sg = sg < 0.04045 ? sg / 12.92 : ((sg + 0.055) / 1.055) ** 2.4;
  let sb = b / 255.0;
  sb = sb < 0.04045 ? sb / 12.92 : ((sb + 0.055) / 1.055) ** 2.4;

  const x = 100 * (sr * 0.4124 + sg * 0.3576 + sb * 0.1805);
  const y = 100 * (sr * 0.2126 + sg * 0.7152 + sb * 0.0722);
  const z = 100 * (sr * 0.0193 + sg * 0.1192 + sb * 0.9505);

  return { x, y, z };
};

const calculateLuminance = (color) => {
  const xyz = toXYZ(color);

  return xyz.y / 100;
};

const calculateContrast = (foreground, background) => {
  const backgroundARGB = toARGB(background);
  let foregroundARGB = toARGB(foreground);

  if (backgroundARGB.a !== 255) {
    throw new Error(`background can not be translucent: ${backgroundARGB}`);
  }
  if (foregroundARGB.a < 255) {
    // If the foreground is translucent, composite the foreground over the background
    foregroundARGB = compositeColors(foregroundARGB, backgroundARGB);
  }

  const luminance1 = calculateLuminance(foregroundARGB) + 0.05;
  const luminance2 = calculateLuminance(backgroundARGB) + 0.05;

  // Now return the lighter luminance divided by the darker luminance
  return Math.max(luminance1, luminance2) / Math.min(luminance1, luminance2);
};

const rgbToArgbNumber = ({ r, g, b, a }) =>
  (a << 24) + (r << 16) + (g << 8) + b;

export {
  toColor,
  calculateContrast,
  toRgbaString,
  numberToRgba,
  rgbToArgbNumber,
  rgbaToHexWithoutAlpha,
  hexToRgb,
};
