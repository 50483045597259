import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ToolsOptionsIcon from '../../../ui/atoms/Icons/ToolsOptionsIcon';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import IconButton from '../../../ui/atoms/IconButton';

type DrawingButtonProps = {
  icon: ReactNode;
  onClick: () => void;
  tooltipTitle: string;
  tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom';
  showOptionsIcon?: boolean;
  active?: boolean;
  disabled?: boolean;
};

type StyledButtonProps = {
  active: boolean;
};

const StyledButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})<StyledButtonProps>(({ theme, active, disabled }) => ({
  '& .tooltipOptionsIcon': {
    color: theme.text.t8,
  },

  ...(active
    ? {
        backgroundColor: theme.palette.primary.alpha10,
        [`& .${svgIconClasses.root}`]: {
          color: theme.palette.primary.main,
        },
        '& .tooltipOptionsIcon': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          [`& .${svgIconClasses.root}`]: {
            color: theme.palette.primary.main,
          },
          '& .tooltipOptionsIcon': {
            color: theme.palette.primary.main,
          },
        },
      }
    : {}),
  ...(disabled
    ? {
        '& .tooltipOptionsIcon': {
          color: theme.palette.primary.main,
        },
      }
    : {}),
}));

const DrawingButton: FC<React.PropsWithChildren<DrawingButtonProps>> = ({
  icon,
  onClick,
  tooltipTitle = '',
  tooltipPlacement = 'bottom',
  showOptionsIcon = false,
  active = false,
  disabled = false,
}) => (
  <Tooltip
    disableHoverListener={!tooltipTitle}
    title={tooltipTitle}
    placement={tooltipPlacement}
  >
    <Box>
      <StyledButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        shape={ButtonShapeTypes.ROUNDED}
        onClick={onClick}
        active={active}
        disabled={disabled}
      >
        {icon}
        {showOptionsIcon && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
            }}
          >
            <ToolsOptionsIcon
              className="tooltipOptionsIcon"
              sx={{
                width: '4px',
                height: '4px',
              }}
            />
          </Box>
        )}
      </StyledButton>
    </Box>
  </Tooltip>
);

export default DrawingButton;
