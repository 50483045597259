import featureToursService from '../services/featureTours.service';

const GET_FEATURE_TOURS_REQUEST = Symbol('GET_FEATURE_TOURS_REQUEST');
const GET_FEATURE_TOURS_SUCCESS = Symbol('GET_FEATURE_TOURS_SUCCESS');
const GET_FEATURE_TOURS_FAILURE = Symbol('GET_FEATURE_TOURS_FAILURE');

const featureToursRequest = () => ({
  type: GET_FEATURE_TOURS_REQUEST,
});

const featureToursSuccess = payload => ({
  type: GET_FEATURE_TOURS_SUCCESS,
  payload,
});

const featureToursFailure = error => ({
  type: GET_FEATURE_TOURS_FAILURE,
  error,
});

const getFeatureTours = () => {
  return async (dispatch) => {
    dispatch(featureToursRequest());

    try {
      const data = await featureToursService.getFeatureTours();
      dispatch(featureToursSuccess(data));
      return data;
    } catch (error) {
      dispatch(featureToursFailure(error));
      console.log('Error: ', error);
    }
  };
};

export {
  featureToursSuccess,
  getFeatureTours,
  GET_FEATURE_TOURS_REQUEST,
  GET_FEATURE_TOURS_SUCCESS,
  GET_FEATURE_TOURS_FAILURE,
};
