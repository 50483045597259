import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { visibilityProps, getIsDocumentVisible, isSupported } from './util';

const isSupportedLocal = isSupported && visibilityProps;

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible());

  const onVisibilityChange = useCallback(() => setIsVisible(getIsDocumentVisible()), [setIsVisible]);

  useEffect(() => {
    if (isSupportedLocal) {
      const visibilityChange = visibilityProps.event;
      document.addEventListener(visibilityChange, onVisibilityChange, false)
      return () => {
        document.removeEventListener(visibilityChange, onVisibilityChange)
      }
    }
  }, []);

  return isVisible;
};

export default usePageVisibility;
