import LineResizer from './LineResizer';

export default class RightLineResizer extends LineResizer {
  center() {
    return this.endPoint;
  }

  onResize({ x, y }) {
    return {
      startPoint: { ...this.startPoint },
      endPoint: { x, y },
    };
  }
}
