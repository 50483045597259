import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { formatDistanceToNowStrict } from 'date-fns';
import { useInterval } from 'use-interval';
import UserPresenceStatus from '../../../common/constants/UserPresenceStatus';

const colors = {
  none: 'transparent',
  offline: '#AEAEB2',
  away: '#FFCD24',
  online: '#42A976',
};

let cachedParticipants = [];

const useBoardParticipantsPresence = (participants) => {
  const [joinedUsersPresenceData, setJoinedUsersPresenceData] = useState([]);

  const updateJoinedUsersPresenceData = useCallback(() => {
    const data = participants.map((participant) => {
      let statusColor;
      let statusText;

      if (participant.status === UserPresenceStatus.online) {
        statusColor = colors.online;
        statusText = 'Online';
      } else if (participant.status === UserPresenceStatus.offline) {
        statusColor = colors.offline;
        statusText = `Offline for ${formatDistanceToNowStrict(
          participant.statusChangeDate
        )}`;
      } else if (participant.status === UserPresenceStatus.away) {
        statusColor = colors.away;
        statusText = `Away for ${formatDistanceToNowStrict(
          participant.statusChangeDate
        )}`;
      } else {
        statusColor = colors.none;
      }

      return {
        id: participant.id,
        name: participant.name,
        statusColor,
        statusText,
        profileImageUrl: participant.profileImageUrl,
        hasDrawingPermission: participant.drawingEnabled,
      };
    });

    setJoinedUsersPresenceData(data);
  }, [setJoinedUsersPresenceData, participants]);

  useInterval(() => {
    if (isEqual(cachedParticipants, participants)) return;

    cachedParticipants = participants;

    updateJoinedUsersPresenceData();
  }, 3000);

  useEffect(() => {
    updateJoinedUsersPresenceData();
  }, [updateJoinedUsersPresenceData]);

  return joinedUsersPresenceData;
};

const useUsersPresence = ({ joined, invited }) => {
  const joinedUsersPresenceData = useBoardParticipantsPresence(joined);
  const invitedUsersPresenceData = invited.map((participant) => ({
    id: participant.id,
    name: participant.name,
    profileImageUrl: participant.profileImageUrl,
    hasDrawingPermission: participant.drawingEnabled,
  }));

  return {
    joined: joinedUsersPresenceData,
    invited: invitedUsersPresenceData,
  };
};

export default useUsersPresence;
