import { useCallback, useMemo, useState } from 'react';

export default function useDialog() {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const dialog = useMemo(() => ({
    open,
    onOpen,
    onClose,
  }), [open, onOpen, onClose]);

  return dialog;
};
