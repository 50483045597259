import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import RemainingTime from './RemainingTime';

type Props = {
  duration: number,
  initialRemainingTime: number,
  onComplete?: () => void,
};

const QuestionTimer = ({
  duration,
  initialRemainingTime,
  onComplete = () => {},
}: Props) => (
  <CountdownCircleTimer
    duration={duration}
    initialRemainingTime={initialRemainingTime}
    colors={[['#fcae1c', 0], ['#ffe65c', 0]]}
    trailColor="#d7dee2"
    size={80}
    strokeWidth={8}
    strokeLinecap="square"
    rotation="counterclockwise"
    onComplete={onComplete}
    isLinearGradient
    isPlaying
  >
    {
      ({ remainingTime }) => (
        <RemainingTime remainingTime={remainingTime || 0} />
      )
    }
  </CountdownCircleTimer>
);

export default QuestionTimer;
