import { useCallback, useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '../../../atoms/Input';
import AuthResponsePayload from '../../../../common/services/types/auth/AuthResponsePayload';
import authService from '../../../../common/services/auth.service';
import analyticsService from '../../../../common/services/analytics.service';
import { saveUserAction } from '../../../../common/actions/authActions';
import isValidEmail from '../../../../common/utils/email.validation.util';
import InputVariant from '../../../atoms/Input/InputVariant';
import InputSize from '../../../atoms/Input/InputSize';
import EmailOutlineIcon from '../../../atoms/Icons/EmailOutlineIcon';
import InputStatus from '../../../atoms/Input/InputStatus';
import EyeOutlineIcon from '../../../atoms/Icons/EyeOutlineIcon';
import EyeOffOutlineIcon from '../../../atoms/Icons/EyeOffOutlineIcon';
import LockOutlineIcon from '../../../atoms/Icons/LockOutlineIcon';
import Button from '../../../atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../atoms/Button/buttonTypes';
import ErrorCodes from '../../../../common/errors/errorCodes';

const SignInForm = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = useCallback(
    handleSubmit(async ({ email, password }) => {
      try {
        const { user, accessToken }: AuthResponsePayload =
          await authService.signIn(email, password);
        analyticsService.event('Sign In', { method: 'Email' });

        dispatch(saveUserAction(user, accessToken));
      } catch (err) {
        if (err.code === ErrorCodes.E_INVALID_CREDENTIALS) {
          setError('Invalid email address or password');

          return;
        }

        setError(err.message);
      }
    }),
    [dispatch, setError]
  );

  const { isSubmitting } = useFormState({ control });

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" gap="14px" flexDirection="column">
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'This field is required',
            validate: (value) => {
              if (isValidEmail(value)) return undefined;

              return 'Invalid email format';
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            formState: { errors },
          }) => (
            <Input
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              variant={InputVariant.CONTAINED}
              size={InputSize.M}
              placeholder="Email address"
              label="Email"
              icon={<EmailOutlineIcon />}
              status={errors.email ? InputStatus.ERROR : undefined}
              helperText={errors.email ? errors.email.message : undefined}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'This field is required',
            },
          }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Input
              value={value}
              onChange={onChange}
              variant={InputVariant.CONTAINED}
              size={InputSize.M}
              placeholder="Password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              endAdornmentPrimaryIcon={
                showPassword ? (
                  <EyeOutlineIcon
                    onClick={handleClickShowPassword}
                    sx={(theme) => ({
                      color: theme.text.t6,
                    })}
                  />
                ) : (
                  <EyeOffOutlineIcon
                    onClick={handleClickShowPassword}
                    sx={(theme) => ({
                      color: theme.text.t6,
                    })}
                  />
                )
              }
              icon={<LockOutlineIcon />}
              status={errors.password ? InputStatus.ERROR : undefined}
              helperText={errors.password ? errors.password.message : undefined}
            />
          )}
        />
        {error && <Typography color="error">{error}</Typography>}
        <NavLink
          to="/password-forgot"
          style={{
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <Typography variant="l2" color="primary" align="center">
            Forgot password
          </Typography>
        </NavLink>
        <Box mt={1.25}>
          <Button
            type="submit"
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.M}
            fullWidth
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Log in
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SignInForm;
