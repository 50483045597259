import { FC, ReactNode } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  icon?: ReactNode;
  action?: ReactNode;
  title: string;
  message?: string;
  className?: string;
  color: AlertColor;
  borderRadius?: number;
  fontSize?: string | number;
};

const Notification: FC<React.PropsWithChildren<Props>> = ({
  icon,
  action,
  title,
  message,
  className,
  color,
  borderRadius = 12,
  fontSize = '1rem',
}) => (
  <Alert
    className={className}
    sx={{
      borderRadius: `${borderRadius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    icon={icon}
    color={color}
  >
    <Box display="flex" justifyContent="center">
      <Typography component="div">
        <Box color={`${color}.main`} fontWeight="medium" fontSize={fontSize}>
          {title}
        </Box>
      </Typography>
      {message && (
        <Box ml={1.25}>
          <Typography color="#696969">{message}</Typography>
        </Box>
      )}
      {action && <Box ml={1.25}>{action}</Box>}
    </Box>
  </Alert>
);

export default Notification;
