import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { push } from 'redux-first-history';
import Box from '@mui/material/Box';
import Input from '../../../atoms/Input';
import InputVariant from '../../../atoms/Input/InputVariant';
import InputSize from '../../../atoms/Input/InputSize';
import InputStatus from '../../../atoms/Input/InputStatus';
import Button from '../../../atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../atoms/Button/buttonTypes';
import sessionService from '../../../../common/services/session.service';
import Typography from '../../../atoms/Typography';
import { TypographyType } from '../../../atoms/Typography/types/Typography';
import analyticsService from '../../../../common/services/analytics.service';

const JoinWithCodeForm = () => {
  const [error, setError] = useState('');

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      sessionCode: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    handleSubmit(async ({ sessionCode }) => {
      try {
        const publicLink = await sessionService.getPublicLinkBySessionCode(
          sessionCode
        );
        if (!publicLink) {
          setError('Wrong session code');
        }
        dispatch(push(`/public/${publicLink.code}`));
      } catch (err) {
        setError('Wrong session code');
      }
    }),
    [dispatch, setError]
  );

  return (
    <Box onSubmit={onSubmit} component="form" mb={3}>
      <Box display="flex" flexDirection="row" mb={1} gap={2}>
        <Controller
          name="sessionCode"
          control={control}
          rules={{
            required: 'This field is required',
          }}
          render={({ field, formState: { errors } }) => (
            <Input
              {...field}
              variant={InputVariant.CONTAINED}
              size={InputSize.L}
              placeholder="Enter session code"
              status={errors.sessionCode ? InputStatus.ERROR : undefined}
              helperText={
                errors.sessionCode ? errors.sessionCode.message : undefined
              }
            />
          )}
        />
        <Button
          type="submit"
          variant={ButtonVariantTypes.PRIMARY}
          size={ButtonSizeTypes.L}
          disabled={formState.isSubmitting}
          loading={formState.isSubmitting}
          onClick={() => {
            analyticsService.event('Join Session Button Click', {
              source: 'Sign Up',
            });
          }}
        >
          Join
        </Button>
      </Box>
      <Box>
        {error && (
          <Typography variant={TypographyType.p5} color="error.main">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default JoinWithCodeForm;
