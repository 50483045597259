import { buttonTextStyle } from '../Typography/typographyStyles';

export const buttonXlStyle = {
  ...buttonTextStyle.b1,
  minWidth: '140px',
  height: '64px',
  padding: '17px 24px 18px 24px',
  borderRadius: '20px',
};

export const buttonLStyle = {
  ...buttonTextStyle.b2,
  minWidth: '130px',
  height: '56px',
  padding: '14px 18px 15px 18px',
  borderRadius: '18px',
};

export const buttonMStyle = {
  ...buttonTextStyle.b3,
  minWidth: '120px',
  height: '48px',
  padding: '10px 16px 11px 16px',
  borderRadius: '16px',
};

export const buttonSStyle = {
  ...buttonTextStyle.b4,
  minWidth: '100px',
  height: '40px',
  padding: '8px 14px',
  borderRadius: '14px',
};

export const buttonXSStyle = {
  ...buttonTextStyle.b5,
  minWidth: '90px',
  height: '32px',
  padding: '5px 12px',
  borderRadius: '12px',
};

export const buttonXXSStyle = {
  ...buttonTextStyle.b6,
  minWidth: '70px',
  height: '24px',
  padding: '2px 6px 3px 6px',
  borderRadius: '10px',
};
