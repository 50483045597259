enum PathCommand {
  Line = 'L',
  Move = 'M',
  Curve = 'C',
  Bezier = 'Q',
  Arc = 'A',
  Close = 'z',
}

export default PathCommand;
