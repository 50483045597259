export const checkCanSendBoardNotification = ({
  userId,
  shared,
  participantId,
}: {
  userId: string;
  participantId: string;
  shared: boolean;
}) => {
  if (participantId === userId) return false;
  if (!shared) return false;

  return true;
};
