export const getTextDimensions = (
  text: string,
  fontSize: string,
  fontFamily = 'Roboto'
) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  div.style.position = 'absolute';
  div.style.top = '-9999px';
  div.style.left = '-9999px';
  div.style.fontFamily = fontFamily;
  div.style.fontSize = `${fontSize}px`;
  div.style.lineHeight = `${fontSize}px`;
  div.style.whiteSpace = 'pre';
  document.body.appendChild(div);
  const textDimensions = {
    width: div.offsetWidth,
    height: div.offsetHeight,
  };
  document.body.removeChild(div);

  return textDimensions;
};
