import { ReactElement } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { SxProps } from '@mui/material';
import { Modify } from '../../../common/types/ModifyType';
import { AlertColor } from '../../molecules/Notification/notificationTypes';

export enum NotificationButtonSize {
  XS = 'xs',
  XXS = 'xxs',
}

export type NotificationButtonProps = Modify<ButtonProps, {
  size?: NotificationButtonSize,
  icon?: ReactElement,
  hasIcon?: boolean,
  sx?: SxProps,
  color?: AlertColor,
}>
