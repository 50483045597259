import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { getGroupSelector } from '../../../common/reducers/groupsReducer';

const InjectGroup = ({
  groupId,
  sortingCards,
  noGroupRedirectUrl,
  component: Component,
}) => {
  const groupSelector = useMemo(() => getGroupSelector(groupId), [groupId]);
  const group = useSelector(groupSelector);

  const dispatch = useDispatch();

  if (groupId !== 'default' && !group) {
    dispatch(push(noGroupRedirectUrl));
    return null;
  }

  return (
    <Component group={group} groupId={groupId} sortingCards={sortingCards} />
  );
};

export default InjectGroup;
