import Resizer from './Resizer';
import vectorUtils from '../../../../../../common/utils/vector.utils';

export default class BottomLeftResizer extends Resizer {
  cursor = 'nesw-resize';
  x = Math.min(this.startPoint.x, this.endPoint.x);
  y = Math.max(this.startPoint.y, this.endPoint.y);

  center() {
    const pointerPosition = {
      x: this.x - this.padding,
      y: this.y + this.padding,
    };

    const midPoint = vectorUtils.calculateMidPoint(this.startPoint, this.endPoint);

    return vectorUtils.calculateRotatedPoint(pointerPosition, midPoint, this.rotationAngle);
  };

  onResize(deltaX, deltaY) {
    const transformationPoint = {
      x: this.x,
      y: this.y,
    };

    return this.getResizedPoints(transformationPoint, deltaX, deltaY);
  }
};
