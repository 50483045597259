const toOneDecimal = (n: number): number => {
  // if n is integer don't convert it
  if (n % 1 === 0) return n;

  return Math.round(n * 10) / 10;
};

const toLetterIndex = (num: number): string => {
  if (num <= 0) {
    return '';
  }

  const letter = String.fromCharCode(((num - 1) % 26) + 65);
  const rest = Math.floor((num - 1) / 26);
  return toLetterIndex(rest) + letter;
};

export default {
  toOneDecimal,
  toLetterIndex,
};
