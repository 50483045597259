import { useCallback, useEffect, useRef } from 'react';
import * as _ from 'lodash';

type ThrottleOptions = {
  leading: boolean,
  trailing: boolean,
};

const defaultThrottleOptions = { leading: true, trailing: true };

const useThrottle = (
  cb: Function,
  delay: number,
  options: ThrottleOptions = defaultThrottleOptions,
) => {
  const cbRef = useRef(cb);

  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => { cbRef.current = cb; }, [cb]);

  return useCallback(
    _.throttle((...args) => cbRef.current(...args), delay, options),
    [delay, options],
  );
};

export default useThrottle;
