import { UPDATE_RECORDING_POSITION } from '../../actions/board/recordingPositionActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';

const initialState = null;
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_RECORDING_POSITION:
      return payload.actionId;
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const recordingPositionSelector = state => state.board.recordingPosition;

export { recordingPositionSelector };
