import { FC } from 'react';
import Link from '@mui/material/Link';
import Dialog from '../../../ui/atoms/Dialog';
import DialogActions, {
  DialogActionPrimaryButton,
} from '../../../ui/atoms/DialogActions';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogTitle from '../../../ui/atoms/DialogTitle';

type AcceptTermsAndPrivacyDialogProps = {
  opened: boolean;
  onAgree: () => Promise<void>;
};

const AcceptTermsAndPrivacyDialog: FC<
  React.PropsWithChildren<AcceptTermsAndPrivacyDialogProps>
> = ({ opened, onAgree }) => (
  <Dialog fullWidth open={opened} aria-labelledby="alert-dialog-title">
    <DialogTitle id="alert-dialog-title" hasCloseButton={false}>
      Terms and Conditions
    </DialogTitle>
    <DialogContent>
      By using this applications I agree to LiveBoard's{' '}
      <Link href="https://liveboard.online/terms" target="_blank">
        Terms and Conditions
      </Link>{' '}
      and{' '}
      <Link href="https://liveboard.online/privacy" target="_blank">
        Privacy Policy
      </Link>
      .
    </DialogContent>
    <DialogActions>
      <DialogActionPrimaryButton onClick={onAgree}>
        Agree
      </DialogActionPrimaryButton>
    </DialogActions>
  </Dialog>
);

export default AcceptTermsAndPrivacyDialog;
