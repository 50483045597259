const validatePassword = (value: string, option: {
  length: number,
  digit: number,
  letter: number,
}) => {
  const {
    length,
    digit,
    letter,
  } = option;
  if (!value.length) return 'This field is required';

  if (value.length < length) return `Password must contain at least ${length} character${length > 1 ? 's' : ''}.`;

  const digitsCount = value.replace(/[^0-9]/g, '').length;
  const lettersCount = value.replace(/[^A-Za-z]/g, '').length;

  if (!digitsCount || digitsCount < digit) {
    return `Password must contain at least ${digit} digit${digit > 1 ? 's' : ''}.`;
  }

  if (!lettersCount || lettersCount < letter) {
    return `Password must contain at least ${letter} letter${letter > 1 ? 's' : ''}.`;
  }

  return undefined;
};

export default validatePassword;
