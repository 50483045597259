import { FC } from 'react';
import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: FC<
  React.PropsWithChildren<unknown>
> = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

type CustomOptionsObject = OptionsObject & {
  link?: string;
  linkText?: string;
};

export default {
  success(message: string, options: CustomOptionsObject = {}) {
    this.toast({
      message,
      severity: 'success',
      ...options,
    });
  },
  warning(message: string, options: CustomOptionsObject = {}) {
    this.toast({
      message,
      variant: 'warning',
      ...options,
    });
  },
  error(message: string, options: CustomOptionsObject = {}) {
    this.toast({
      message,
      variant: 'error',
      ...options,
    });
  },
  presenceStatus(message: string, options: CustomOptionsObject = {}) {
    this.toast({
      message,
      variant: 'presenceStatus',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 3000,
      ...options,
    });
  },
  toast(msg: any) {
    useSnackbarRef.enqueueSnackbar(msg);
  },
};
