import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import analyticsService from '../../../common/services/analytics.service';
import ToolNames from '../ToolNames';
import BaseTool from './BaseTool';
import FunctionIcon from '../../../ui/atoms/Icons/Shapes/FunctionIcon';
import FormulaIcon from '../../../ui/atoms/Icons/Shapes/FormulaIcon';
import GraphIcon from '../../../ui/atoms/Icons/Shapes/GraphIcon';
import ListItem from '../../../ui/atoms/ListItem';
import ToolOptionsLayout from '../ToolOptionsLayout/ToolOptionsLayout';

const settingsFactory = ({ selectFormulaTool, selectGraph, activeTool }) => (
  <ToolOptionsLayout>
    <Box>
      <ListItem
        title="LaTeX"
        onSelect={selectFormulaTool}
        startIcon={<FormulaIcon />}
        selected={activeTool === ToolNames.Formula}
      />
      <ListItem
        title="Graph Editor"
        onSelect={selectGraph}
        startIcon={<GraphIcon />}
        selected={activeTool === ToolNames.Graph}
      />
    </Box>
  </ToolOptionsLayout>
);

const FunctionTool = ({ selectedTool, selectTool, updateTool, activeTool }) => {
  const selectFormulaTool = useCallback(() => {
    analyticsService.event('Formula Tool Click');
    analyticsService.event('Tool Selected', {
      tool: 'formula',
    });
    selectTool(ToolNames.Formula);
    updateTool(ToolNames.Formula, { previousDrawingTool: selectedTool.name });
  }, [selectTool, updateTool, selectedTool]);

  const selectGraph = useCallback(() => {
    analyticsService.event('Open Graph Editor');
    analyticsService.event('Tool Selected', {
      tool: 'graph',
    });
    selectTool(ToolNames.Graph);
    updateTool(ToolNames.Graph, { previousDrawingTool: selectedTool.name });
  }, [selectTool, updateTool, selectedTool]);

  const icon = <FunctionIcon />;

  const settings = useMemo(
    () =>
      settingsFactory({
        selectFormulaTool,
        selectGraph,
        activeTool,
      }),
    [activeTool, selectFormulaTool, selectGraph]
  );

  const showSettings =
    selectedTool.name === ToolNames.Formula ||
    selectedTool.name === ToolNames.Graph;

  return (
    <BaseTool
      icon={icon}
      tooltipText="Formula"
      settings={settings}
      selectedTool={selectedTool}
      activeTool={activeTool}
      selectTool={selectTool}
      showSettings={showSettings}
      showToolOptionsIcon={true}
    />
  );
};

export default FunctionTool;
