import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ToolNames from '../DrawingTools/ToolNames';
import { getSelectedDrawingTool } from '../../common/reducers/board/drawingToolReducer';
import fileUtils from '../../common/utils/file.utils';
import imageUtils from '../../common/utils/image.utils';
import FileReaderUtils from '../../common/utils/fileReader.utils';
import * as sessionReducer from '../../common/reducers/session/sessionReducer';
import { setImage } from '../../common/actions/board/imageDrawingToolActions';
import * as drawingToolActions from '../../common/actions/board/drawingToolActions';

export const imageMimeTypesRegex = /^image\/(jpe?g|jfif|png|webp)/;

export const validateImage = async (file: File | undefined) => {
  if (!file) return false;

  const result = await fileUtils.checkMimeTypeInList(file, ['jpeg', 'jpg', 'png', 'webp']);

  return result && imageMimeTypesRegex.test(file.type);
};

export const useInputOnChange = () => {
  const dispatch = useDispatch();
  const dimensions = useSelector(sessionReducer.dimensionsSelector);
  const selectedTool = useSelector(getSelectedDrawingTool);
  const selectTool = useCallback((name: string) => {
    dispatch(drawingToolActions.selectDrawingTool(name));
  }, [dispatch]);

  const updateTool = useCallback((name: string, props: { [key: string]: any }) => {
    dispatch(drawingToolActions.updateDrawingTool(name, props));
  }, [dispatch]);

  const { enqueueSnackbar } = useSnackbar();

  const insertImage = useCallback((image: HTMLImageElement) => {
    const imageDimension = imageUtils.scaleImageDownToFit(image, dimensions);
    dispatch(setImage({
      image,
      width: imageDimension.width,
      height: imageDimension.height,
    }));

    if (selectedTool.name !== ToolNames.Image) {
      selectTool(ToolNames.Image);
      updateTool(ToolNames.Image, { previousDrawingTool: selectedTool.name });
    }
  }, [dispatch, dimensions, selectTool, updateTool, selectedTool]);

  const showImageTypeError = useCallback(() => {
    enqueueSnackbar('File type not supported', {
      variant: 'error',
      preventDuplicate: true,
    });
  }, [enqueueSnackbar]);

  const processFiles = useCallback(async (files: FileList) => {
    const file: File | undefined = Object.values(files).find(
      item => imageMimeTypesRegex.test(item.type),
    );

    const isValidImage = await validateImage(file);
    if (!isValidImage) {
      throw new Error('File type not supported');
    }
    const resizedBlob = await imageUtils.resizeImage(file as File, {
      maxWidth: Math.max(dimensions.width, dimensions.height),
      maxHeight: Math.max(dimensions.width, dimensions.height),
    });
    const imageUrl = await FileReaderUtils.toDataURL(resizedBlob);
    const image: HTMLImageElement = await imageUtils.toImage(imageUrl);
    insertImage(image);
  }, [insertImage, dimensions]);

  const inputOnChange = useCallback(async (e: React.FormEvent<HTMLInputElement> | Event) => {
    try {
      const { files } = e.target as HTMLInputElement;
      await processFiles(files as FileList);
    } catch (error) {
      showImageTypeError();
    }
  }, [processFiles, showImageTypeError]);

  return useMemo(() => ({
    inputOnChange,
    processFiles,
    showImageTypeError,
  }), [
    inputOnChange,
    processFiles,
    showImageTypeError,
  ]);
};

export default useInputOnChange;
