import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { userSelector } from '../../common/reducers/userReducer';

type Props = {
  open: boolean;
  onClose: () => void;
};

const MobilePlanChangeWarning: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
}) => {
  const user = useSelector(userSelector);

  const platformText = useMemo(() => {
    switch (user.subscriptionSource) {
      case 'google':
        return 'To change your Subscription Plan, please open LiveBoard from your Android device.';
      case 'apple':
        return 'To change your Subscription Plan, please open LiveBoard from your iOS device.';
      default:
        return '';
    }
  }, [user]);

  return (
    <Dialog
      classes={{
        paper: 'modal-content rounded-lg border-0',
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent className="p-4">
        <Typography>{platformText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="neutral">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobilePlanChangeWarning;
