import { FC } from 'react';
import { useController } from 'react-hook-form';
import TextField from '@mui/material/TextField';

type Props = {
  control: any;
  clearErrors: any;
};

const ReasonOtherField: FC<React.PropsWithChildren<Props>> = ({
  control,
  clearErrors,
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name: 'reasonOther',
    control,
    rules: { required: 'Required' },
  });

  return (
    <TextField
      value={value}
      onChange={(event) => {
        if (error) {
          clearErrors('reasonOther');
        }
        onChange(event.target.value);
      }}
      onBlur={onBlur}
      multiline
      fullWidth
      rows={3}
      variant="outlined"
      size="small"
      helperText={error ? error.message : undefined}
      error={!!error}
    />
  );
};

export default ReasonOtherField;
