import {
  SET_MESSAGES_SIDE_BAR_VISIBILITY,
  SET_BOARD_SIZES,
  SET_DPI,
  SET_COLLABORATION_BAR_SIZE,
} from '../actions/uiActions';
import { SESSION_DISCONNECTED } from '../actions/session/sessionActions';

const initialState = {
  messagesSideBarOpened: false,
  width: 0,
  height: 0,
  dpi: 0,
  collaborationBar: {
    width: 0,
    height: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES_SIDE_BAR_VISIBILITY:
      return {
        ...state,
        messagesSideBarOpened: action.opened,
      };
    case SESSION_DISCONNECTED:
      return {
        ...state,
        messagesSideBarOpened: false,
      };
    case SET_BOARD_SIZES:
      return {
        ...state,
        width: action.width,
        height: action.height,
      };
    case SET_COLLABORATION_BAR_SIZE:
      return {
        ...state,
        collaborationBar: {
          width: action.width,
          height: action.height,
        },
      };
    case SET_DPI:
      return {
        ...state,
        dpi: action.dpi,
      };
    default:
      return state;
  }
};

const messagesSideBarOpenedSelector = (state) => state.ui.messagesSideBarOpened;
const dpiSelector = (state) => state.ui.dpi;
const widthSelector = (state) => state.ui.width;
const heightSelector = (state) => state.ui.height;
const collaborationBarWidthSelector = (state) =>
  state.ui.collaborationBar.width;

export {
  messagesSideBarOpenedSelector,
  dpiSelector,
  widthSelector,
  heightSelector,
  collaborationBarWidthSelector,
};
