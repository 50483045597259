import React, { useCallback } from 'react';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import BaseTool from './BaseTool';
import ToolNames from '../ToolNames';
import keyMap from '../../../config/hotkeys';
import analyticsService from '../../../common/services/analytics.service';

const TextTool = ({ selectedTool, selectTool }) => {
  const selectTextTool = useCallback(
    (activeTool) => {
      analyticsService.event('Tool Selected', {
        tool: 'text',
      });
      selectTool(activeTool);
    },
    [selectTool]
  );

  return (
    <BaseTool
      icon={<TextFieldsIcon />}
      tooltipText={`Text (${keyMap.text.description})`}
      selectedTool={selectedTool}
      activeTool={ToolNames.Text}
      selectTool={selectTextTool}
    />
  );
};

export default TextTool;
