import { FC, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import useDialog from '../../../common/hooks/useDialog';
import Notification from '../Notification';
import AlertIcon from '../../atoms/Icons/AlertIcon';
import { AlertColor } from '../Notification/notificationTypes';
import { getLocaleDate } from '../../../common/utils/getLocaleDate';
import { NotificationButtonSize } from '../../atoms/NotificationButton/notificationButtonTypes';
import NotificationButton from '../../atoms/NotificationButton/NotificationButton';
import CardModal from '../CardModal';
import { FactoryFunction } from './addPaymentMethodNotificationProps';

type Props = {
  hasPaymentMethod: boolean;
  expirationDate: number;
  onAddSuccess: () => Promise<void>;
};

const actionButtonFactory: FactoryFunction =
  (onCardDialogOpen) =>
  ({ color, children }) => {
    const theme = useTheme();
    const showXsButton = useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <NotificationButton
        onClick={onCardDialogOpen}
        size={
          showXsButton ? NotificationButtonSize.XS : NotificationButtonSize.XXS
        }
        hasIcon={false}
        color={color}
        sx={{
          minWidth: 0,
        }}
      >
        {children}
      </NotificationButton>
    );
  };

const AddPaymentMethodNotification: FC<React.PropsWithChildren<Props>> = ({
  hasPaymentMethod,
  expirationDate,
  onAddSuccess,
}) => {
  const {
    open: cardDialogOpen,
    onOpen: onCardDialogOpen,
    onClose: onCardDialogClose,
  } = useDialog();

  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = useCallback(async () => {
    await onAddSuccess();

    enqueueSnackbar('Payment method confirmed!', {
      variant: 'success',
    });

    onCardDialogClose();
  }, [enqueueSnackbar, onAddSuccess, onCardDialogClose]);

  const ActionButton = useMemo(
    () => actionButtonFactory(onCardDialogOpen),
    [onCardDialogOpen]
  );

  return (
    <>
      <Notification
        Icon={AlertIcon}
        ActionButton={hasPaymentMethod ? undefined : ActionButton}
        title={`Your trial period will expire on ${getLocaleDate(
          expirationDate
        )}.`}
        message={
          hasPaymentMethod
            ? undefined
            : 'In order to keep using the current plan’s features'
        }
        color={AlertColor.WARNING}
        actionText="Add payment method"
      />
      {cardDialogOpen && (
        <CardModal
          open={cardDialogOpen}
          onClose={onCardDialogClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default AddPaymentMethodNotification;
