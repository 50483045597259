import { createContext } from 'react';

export type DialogContentProps = {
  dialog: {
    trial: {
      title: string;
      message: string;
      successBtn: string;
    };
    purchase: {
      title: string;
      message: string;
      successBtn: string;
    };
  };
  imageUrl: string;
  plan: {
    monthly: any;
    yearly: any;
  };
};

type PlanContextProps = {
  dialogPayload: DialogContentProps | null;
};

const defaultValue = {
  dialogPayload: null,
};

const PlanDialogContext = createContext<PlanContextProps>(defaultValue);

export default PlanDialogContext;
