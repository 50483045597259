import {
  DisplayObject,
  Graphics,
  LINE_CAP,
  LINE_JOIN,
  Rectangle,
} from 'pixi.js';
import Shape from './shape';
import OldArrowAction from '../../../common/drawingActions/shapes/arrow.drawing.action';
import ActionName from '../types/ActionName';

class Arrow extends Shape {
  constructor(action?: OldArrowAction) {
    super(action);

    this.name = ActionName.ARROW;
  }

  toDto(): OldArrowAction {
    const dto = new OldArrowAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldArrowAction) {
    super.setFields(action);
    action.name = ActionName.ARROW;
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    graphicsObject.clear();
    graphicsObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    graphicsObject.lineStyle({
      width: size,
      color,
      cap: LINE_CAP.ROUND,
      join: LINE_JOIN.ROUND,
    });

    graphicsObject.moveTo(-width / 2, -height / 2);
    graphicsObject.lineTo(width / 2, height / 2);

    const headSize = (size ** (1.0 / 2) + 1) * 10;
    const angle = Math.atan2(
      this.endPoint.y - this.startPoint.y,
      this.endPoint.x - this.startPoint.x
    );

    graphicsObject.lineTo(
      width / 2 - headSize * Math.cos(angle - Math.PI / 6),
      height / 2 - headSize * Math.sin(angle - Math.PI / 6)
    );
    graphicsObject.moveTo(width / 2, height / 2);
    graphicsObject.lineTo(
      width / 2 - headSize * Math.cos(angle + Math.PI / 6),
      height / 2 - headSize * Math.sin(angle + Math.PI / 6)
    );

    const hitAreaWidth = Math.max(Math.abs(width), 20);
    const hitAreaHeight = Math.max(Math.abs(height), 20);

    graphicsObject.hitArea = new Rectangle(
      -(hitAreaWidth + size) / 2,
      -(hitAreaHeight + size) / 2,
      hitAreaWidth,
      hitAreaHeight
    );
  }
}

export default Arrow;
