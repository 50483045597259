import ShapeControl from './ShapeControl';
import vectorUtils from '../../../../../common/utils/vector.utils';
import { text } from '../../../../../ui/atoms/Colors/colorStyles';

export default class BoundaryBox extends ShapeControl {
  cursor;
  midPoint;

  constructor(startPoint, endPoint, scale, padding, rotationAngle) {
    super(startPoint, endPoint, scale, padding, rotationAngle);

    this.cursor = 'move';
    this.midPoint = vectorUtils.calculateMidPoint(startPoint, endPoint);
  };

  draw(ctx) {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = text.t7;
    ctx.lineWidth = 2 / this.scale;
    if (this.rotationAngle) {
      ctx.translate(this.midPoint.x, this.midPoint.y);
      ctx.rotate(this.rotationAngle);
      ctx.translate(-this.midPoint.x, -this.midPoint.y);
    }
    ctx.setLineDash([20 / this.scale, 15 / this.scale]);
    ctx.strokeRect(
      this.startPoint.x - this.padding,
      this.startPoint.y - this.padding,
      this.getWidth() + (2 * this.padding),
      this.getHeight() + (2 * this.padding),
    );
    ctx.restore();
  }

  hitTest(x, y) {
    const rectDimension = {
      width: this.endPoint.x - this.startPoint.x,
      height: this.endPoint.y - this.startPoint.y,
    };

    return vectorUtils.calculateIntersection(
      this.rotationAngle,
      { x, y },
      this.midPoint,
      this.startPoint,
      rectDimension,
    );
  };
}
