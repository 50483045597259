import { httpService } from './http.service';
import SessionPdfDto from './dto/sessionPdf/sessionPdf';

const apiSessionPdfPart = '/session-pdf';

const getSessionPdf = (id: string) => httpService.get<SessionPdfDto, SessionPdfDto>(`${apiSessionPdfPart}/${id}`);

const createSessionPdf = (sessionId: string) => httpService.post<SessionPdfDto, SessionPdfDto>(
  apiSessionPdfPart,
  { sessionId },
);

export {
  getSessionPdf,
  createSessionPdf,
};
