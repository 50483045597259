import { FC } from 'react';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
} from '../../atoms/DialogActions';

type Props = {
  open: boolean;
  onClose: () => void;
};

const MediaDeviceNotSupportDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle onClose={onClose}>Camera or microphone blocked.</DialogTitle>
    <DialogContent>
      Please go to your browser settings, enable the access to camera and
      microphone, and reload the page.
    </DialogContent>
    <DialogActions>
      <DialogActionPrimaryButton onClick={onClose}>
        Ok
      </DialogActionPrimaryButton>
    </DialogActions>
  </Dialog>
);

export default MediaDeviceNotSupportDialog;
