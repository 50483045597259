const hasDocument = typeof document !== 'undefined';

const vendorEvents = [
  {
    hidden: 'hidden',
    event: 'visibilitychange',
    state: 'visibilityState',
  },
  {
    hidden: 'webkitHidden',
    event: 'webkitvisibilitychange',
    state: 'webkitVisibilityState',
  },
  {
    hidden: 'mozHidden',
    event: 'mozvisibilitychange',
    state: 'mozVisibilityState',
  },
  {
    hidden: 'msHidden',
    event: 'msvisibilitychange',
    state: 'msVisibilityState',
  },
  {
    hidden: 'oHidden',
    event: 'ovisibilitychange',
    state: 'oVisibilityState',
  },
];

const isSupported = hasDocument && Boolean(document.addEventListener);

const visibilityProps = (() => {
  if (!isSupported) {
    return null;
  }

  for (let i = 0; i < vendorEvents.length; i++) {
    const event = vendorEvents[i];
    if (event.hidden in document) {
      return event;
    }
  }

  return null;
})();

const getIsDocumentVisible = () => !document[visibilityProps.hidden];

export {
  isSupported,
  visibilityProps,
  getIsDocumentVisible,
}
