import { httpServiceV4 } from './http.service';
import PlanListDto from './dto/subscriptions/PlanListDto';

const apiSubscriptionsPart = '/plan/list';
const apiPlanPart = '/plan';

const getSubscriptionsList = (): Promise<PlanListDto> =>
  httpServiceV4.get<PlanListDto, PlanListDto>(apiSubscriptionsPart);

const getPlanById = (id: string) =>
  httpServiceV4.get<PlanListDto, PlanListDto>(`${apiPlanPart}/${id}`);

export default {
  getSubscriptionsList,
  getPlanById,
};
