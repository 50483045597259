import { FC, ReactElement } from 'react';
import ListItem, { listItemClasses } from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction';
import { typographyClasses } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { iconButtonClasses } from '@mui/material';
import { avatarClasses } from '@mui/material/Avatar';
import Avatar from '../Avatar';
import { AvatarSizeTypes } from '../Avatar/types';
import Typography from '../Typography';
import { TypographyType } from '../Typography/types/Typography';
import IconButton from '../IconButton';
import { ButtonSizeTypes, ButtonVariantTypes } from '../Button/buttonTypes';

type MemberItemProps = {
  width?: string | number;
  avatarSrc: string;
  name: string;
  email?: string;
  actionIcon?: ReactElement | false;
  onIconClick?: (event: any) => void;
  onClick?: () => void;
  disabled?: boolean;
  id?: any;
  className?: any;
};

const MemberItem: FC<React.PropsWithChildren<MemberItemProps>> = ({
  width = '100%',
  avatarSrc,
  name,
  email,
  actionIcon,
  onIconClick = () => {},
  onClick = () => {},
  disabled = false,
  ...props
}) => (
  <Box
    sx={{
      [`& .${listItemClasses.disabled}`]: {
        opacity: 1,
        [`.${typographyClasses.root}`]: {
          opacity: 0.4,
        },
        [`.${iconButtonClasses.root}`]: {
          opacity: 0.4,
        },
        [`.${avatarClasses.root}`]: {
          opacity: 0.4,
        },
      },
    }}
  >
    <ListItem
      {...props}
      sx={(theme) => ({
        borderRadius: 3,
        transition: '0.2s',
        p: '7px 16px 8px 8px',
        width,
        cursor: 'default',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: theme.background.bg3,
        },
        [`&:hover .${listItemSecondaryActionClasses.root}`]: {
          display: 'flex',
        },
        [`& .${listItemSecondaryActionClasses.root}`]: {
          display: 'none',
          alignItems: 'center',
          cursor: 'pointer',
        },
        [`& .${typographyClasses.root}`]: {
          width: '85%',
        },
      })}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      secondaryAction={
        actionIcon && (
          <IconButton
            onClick={!disabled ? onIconClick : undefined}
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.XS}
          >
            {actionIcon}
          </IconButton>
        )
      }
    >
      <ListItemAvatar>
        <Avatar size={AvatarSizeTypes.M} src={avatarSrc} alt={name} />
      </ListItemAvatar>
      <ListItemText
        sx={{
          margin: 0,
        }}
        disableTypography
        primary={
          name &&
          !email && (
            <Typography noWrap variant={TypographyType.p4}>
              {name}
            </Typography>
          )
        }
        secondary={
          email && (
            <Typography noWrap variant={TypographyType.p5}>
              {email}
            </Typography>
          )
        }
      />
    </ListItem>
  </Box>
);

export default MemberItem;
