import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Header from '../../../ui/molecules/Header';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import ChevronLeftIcon from '../../../ui/atoms/Icons/ChevronLeftIcon';
import { Container } from '../../../ui/atoms/Container';
import TabItem from '../../../ui/molecules/TabItem';
import AccountOutlineIcon from '../../../ui/atoms/Icons/AccountOutlineIcon';
import BookmarkOutlineIcon from '../../../ui/atoms/Icons/BookmarkOutlineIcon';
import OrganizationSettingsIcon from '../../../ui/atoms/Icons/OrganizationSettingsIcon';
import * as planPermissionSelectors from '../../../common/selectors/planPermissionSelectors';
import { userSelector } from '../../../common/reducers/userReducer';

const Layout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const user = useSelector(userSelector);

  return (
    <Box
      sx={(theme) => ({
        background: theme.background.bg2,
        height: '100%',
        overflowY: 'auto',
      })}
    >
      <Header isBoardCreated={false} />
      <Container>
        <Box
          sx={(theme) => ({
            marginTop: '16px',
            marginBottom: '24px',
            [theme.breakpoints.up('md')]: {
              marginBottom: '48px',
            },
          })}
        >
          <Button
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
            startIcon={<ChevronLeftIcon />}
            component={NavLink}
            to="/"
          >
            Back to home
          </Button>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: '16px',
            marginBottom: '24px',
            [theme.breakpoints.up('md')]: {
              marginBottom: '48px',
            },
          })}
        >
          <Box
            display="block"
            sx={{
              overflowX: 'auto',
            }}
            px={2}
            mx={-2}
          >
            <Box display="inline-flex" gap={2}>
              <TabItem
                to="/profile/account"
                activeClassName="active"
                exact
                component={NavLink}
                startIcon={
                  <AccountOutlineIcon
                    sx={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
              >
                Profile
              </TabItem>
              {!user.organizationId && (
                <TabItem
                  component={NavLink}
                  to="/profile/plan-billing"
                  activeClassName="active"
                  exact
                  startIcon={
                    <BookmarkOutlineIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  }
                >
                  Plan & Billing
                </TabItem>
              )}
            </Box>
          </Box>
        </Box>
        {children}
      </Container>
    </Box>
  );
};

export default Layout;
