import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserPresence from './UserPresence';
import * as plansPermissionsSelectors from '../../../common/selectors/planPermissionSelectors';

type UserPresenceProps = {
  id: string;
  name: string;
  statusColor: string;
  statusText: string;
  profileImageUrl: string;
  hasDrawingPermission: boolean;
};

type JoinedParticipantsProps = {
  open: boolean;
  joined: any;
  updateDrawingPermission: (
    participantId: string,
    newDrawingPermission: boolean
  ) => void;
  onPlansModalOpen: () => void;
};

const JoinedParticipants: FC<
  React.PropsWithChildren<JoinedParticipantsProps>
> = ({ open, joined, updateDrawingPermission, onPlansModalOpen }) => {
  const canChangeDrawingPermission = useSelector(
    plansPermissionsSelectors.canManageDrawingAccessSelector
  );

  return (
    <>
      {!!joined.length && (
        <Typography
          variant="p5"
          sx={(theme) => ({
            color: theme.text.t7,
            textAlign: open ? 'initial' : 'center',
          })}
        >
          Joined
          {open && (
            <Typography
              component="span"
              variant="p5"
              sx={(theme) => ({
                color: theme.text.t7,
              })}
            >
              {' '}
              ({joined.length})
            </Typography>
          )}
        </Typography>
      )}
      {!!joined.length && (
        <Box display="flex" flexDirection="column" gap={1.5} mt={2} mb={2.5}>
          {joined.map((participant: UserPresenceProps) => (
            <UserPresence
              key={participant.id}
              id={participant.id}
              name={participant.name}
              statusColor={participant.statusColor}
              statusText={participant.statusText}
              profileImageUrl={participant.profileImageUrl}
              collapsed={!open}
              hasDrawingPermission={participant.hasDrawingPermission}
              updateDrawingPermission={updateDrawingPermission}
              showPlansModal={onPlansModalOpen}
              canChangeDrawingPermission={canChangeDrawingPermission}
              userType="joined"
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default memo(JoinedParticipants);
