import TriangleResizer from './TriangleResizer';

export default class LeftTriangleResizer extends TriangleResizer {
  center() {
    return this.left;
  }

  onResize({ x, y }) {
    return {
      top: { ...this.top },
      right: { ...this.right },
      left: { x, y },
    };
  }
}
