const SET_POSITION = Symbol('SET_POSITION');
const SET_TEXT = Symbol('SET_TEXT');
const SET_SIZES = Symbol('SET_SIZES');
const CLEAR_DRAWING_TOOL = Symbol('CLEAR_DRAWING_TOOL');

const setPosition = (position) => ({
  type: SET_POSITION,
  position,
});

const setText = (text) => ({
  type: SET_TEXT,
  text,
});

const setSizes = (width, height) => ({
  type: SET_SIZES,
  width,
  height,
});

const clearDrawingTool = () => ({
  type: CLEAR_DRAWING_TOOL,
});

export {
  setPosition,
  setText,
  setSizes,
  clearDrawingTool,
  SET_POSITION,
  SET_TEXT,
  SET_SIZES,
  CLEAR_DRAWING_TOOL,
};
