import RotateButton from './RotateButton';
import vectorUtils from '../../../../../../common/utils/vector.utils';

export default class TopRightRotation extends RotateButton {
  x;
  y;

  constructor(startPoint, endPoint, scale, padding, rotationAngle) {
    super(startPoint, endPoint, scale, padding, rotationAngle, -Math.PI / 2);

    this.x = Math.max(this.startPoint.x, this.endPoint.x);
    this.y = Math.min(this.startPoint.y, this.endPoint.y);
  };

  center() {
    const pointerPosition = {
      x: this.x + this.padding + this.buttonWidth / this.scale,
      y: this.y - this.padding - this.buttonHeight / this.scale,
    };

    const midPoint = vectorUtils.calculateMidPoint(this.startPoint, this.endPoint);

    return vectorUtils.calculateRotatedPoint(pointerPosition, midPoint, this.rotationAngle);
  };

  onRotate(pos) {
    const relativePoint = {
      x: this.x,
      y: this.y,
    };

    return vectorUtils.calculateRotationAngle(this.startPoint, this.endPoint, pos, relativePoint);
  };
}
