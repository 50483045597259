import React from 'react';
import circleUtil from '../../../../../common/utils/shapes/circle.util';
import BoundaryBox from '../boundaries/BoundaryBox';
import CircleDrawingAction from '../../../../../common/drawingActions/shapes/circle.drawing.action';
import ShapeDrawingLayer from './ShapeDrawingLayer';
import { withCanvasContext } from '../../../Contexts/CanvasContext';
import CircleAspectRatioResizer from '../boundaries/resizers/CircleAspectRatioResizer';

class CircleDrawingLayer extends ShapeDrawingLayer {
  createShape = (positions, props) => {
    const { color, size, fill } = props;
    const { startPoint, endPoint } = positions;
    const drawingAction = new CircleDrawingAction();
    drawingAction.paint.color = color;
    drawingAction.paint.strokeWidth = size;
    drawingAction.paint.fill = fill;
    drawingAction.localStartTime = new Date();
    drawingAction.setStartPoint(startPoint);
    drawingAction.setEndPoint(endPoint);
    return drawingAction;
  };

  createBoundaries = (positions, props) => {
    const { size, scale, zoom } = props;
    const { startPoint, endPoint } = positions;

    const padding = 20 / scale + size / 2;
    const radius = circleUtil.getRadius(startPoint, endPoint);
    const shapes = [
      new BoundaryBox(
        {
          x: startPoint.x - radius,
          y: startPoint.y - radius,
        },
        {
          x: startPoint.x + radius,
          y: startPoint.y + radius,
        },
        scale * zoom,
        padding
      ),
      new CircleAspectRatioResizer(
        startPoint,
        {
          x: startPoint.x + radius,
          y: startPoint.y + radius,
        },
        scale * zoom,
        padding
      ),
    ];

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => ({
    startPoint,
    endPoint,
  });

  getBoundaryBoxPosition = (position) => {
    if (!position) return null;

    const { scale, size } = this.props;
    const scaledPadding = 20 / scale + size / 2;
    const radius = circleUtil.getRadius(position.startPoint, position.endPoint);

    return {
      startPoint: {
        x: position.startPoint.x - scaledPadding - radius,
        y: position.startPoint.y - scaledPadding - radius,
      },
      endPoint: {
        x: position.startPoint.x + scaledPadding + radius,
        y: position.startPoint.y + scaledPadding + radius,
      },
    };
  };
}

export default withCanvasContext(CircleDrawingLayer);
