import { cloneDeep } from 'lodash';
import DrawingAction from './drawing.action';
import numberUtil from '../utils/number.util';
import guid from '../utils/guid.util';

class GraphDrawingAction extends DrawingAction {
  constructor(deserialized) {
    super(deserialized);

    if (deserialized) {
      this.startPoint = { x: deserialized.x, y: deserialized.y};
      this.endPoint = { x: deserialized.x + deserialized.width, y: deserialized.y + deserialized.height }
      this.width = deserialized.width;
      this.height = deserialized.height;
      this.graphState = deserialized.graphState;
    }

    this.name = 'graph';
    this.image = null;
  }

  getWidth() {
    if (this.width !== null && this.width !== undefined) return this.width;

    return this.image.width;
  }

  getHeight() {
    if (this.height !== null && this.height !== undefined) return this.height;

    return this.image.height;
  }

  serialize() {
    return {
      ...super.serialize(),
      graphState: this.graphState,
      x: numberUtil.toOneDecimal(this.startPoint.x),
      y: numberUtil.toOneDecimal(this.startPoint.y),
      width: numberUtil.toOneDecimal(this.endPoint.x - this.startPoint.x),
      height: numberUtil.toOneDecimal(this.endPoint.y - this.startPoint.y),
    };
  }

  intersect(point) {
    return (
      this.image &&
      this.startPoint.x < point.x && this.endPoint.x > point.x &&
      this.startPoint.y < point.y && this.endPoint.y > point.y
    );
  }

  createMoveAction() {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;
    newAction.startPoint = { ...this.startPoint };
    newAction.endPoint = { ...this.endPoint };
    newAction.image = this.image;
    newAction.graphState = this.graphState;
    newAction.width = this.width || this.image.width;
    newAction.height = this.height || this.image.height;

    return newAction;
  }

  move({ deltaX, deltaY }) {
    this.startPoint = {
      x: this.startPoint.x + deltaX,
      y: this.startPoint.y + deltaY,
    };
    this.endPoint = {
      x: this.endPoint.x + deltaX,
      y: this.endPoint.y + deltaY,
    };
  }

}

export default GraphDrawingAction;
