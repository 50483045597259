import getUserLocale from 'get-user-locale';

const getLocaleDate = (date) => {
  const locale = getUserLocale();

  return new Date(date).toLocaleDateString(locale);
};

export {
  getLocaleDate,
};
