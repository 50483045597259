import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../../common/utils/compose.utils';
import { Subject, BehaviorSubject, pipe, combineLatest, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import DomEventsUtil from '../../../../common/utils/domEvents.util';
import { withCanvasContext } from '../../Contexts/CanvasContext';
import drawers from '../drawers';
import * as currentDrawingAction from '../../../../common/actions/board/currentDrawingAction';
import * as drawingToolActions from '../../../../common/actions/board/drawingToolActions';
import { getActivePage } from '../../../../common/reducers/board/activePageReducer';
import * as drawingActionsReducer from '../../../../common/reducers/board/drawingActionsReducer';
import {
  getPathShapeTools,
  getSelectedDrawingTool,
} from '../../../../common/reducers/board/drawingToolReducer';
import clearSelection from './clearSelection';
import SelectCursorSvgIcon from '../../../../assets/svgIcons/SelectCursorSvgIcon.svg';

const Modes = {
  Initial: Symbol(0),
  Dragging: Symbol(1),
};

const notSelectableShapes = ['lockedImageMeta'];

const makeMapStateToProps = () => {
  const actionsSelector = drawingActionsReducer.createActionsSelector();
  return (state) => {
    const currentPage = getActivePage(state);
    return {
      selectedTool: getSelectedDrawingTool(state),
      actions: actionsSelector(state, currentPage.id),
      pathShapes: getPathShapeTools(state),
    };
  };
};

const enhancer = compose(
  withCanvasContext,
  connect(makeMapStateToProps, {
    setCurrentDrawing: currentDrawingAction.setLineCurrentDrawing,
    selectDrawingTool: drawingToolActions.selectDrawingTool,
    updateDrawingTool: drawingToolActions.updateDrawingTool,
  })
);

class SelectDrawingLayer extends React.Component {
  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.zoomProp$.next(this.props.zoom);
    this.scaleProp$.next(this.props.scale);
    this.originXProp$.next(this.props.originX);
    this.originYProp$.next(this.props.originY);
    this.colorProp$.next(this.props.color);
    this.sizeProp$.next(this.props.size);
    this.fillProp$.next(this.props.fill);
    this.shapes$.next([
      ...this.props.actions.filter((a) => a.name === 'imageMeta'),
      ...this.props.actions.filter(
        (a) => a.name !== 'imageMeta' && !notSelectableShapes.includes(a.name)
      ),
    ]);
    this.mode$.next(Modes.Initial);
    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();
  }

  componentDidUpdate(prevProps) {
    if (this.props.zoom !== prevProps.zoom) {
      this.zoomProp$.next(this.props.zoom);
    }
    if (this.props.scale !== prevProps.scale) {
      this.scaleProp$.next(this.props.scale);
    }
    if (this.props.originX !== prevProps.originX) {
      this.originXProp$.next(this.props.originX);
    }
    if (this.props.originY !== prevProps.originY) {
      this.originYProp$.next(this.props.originY);
    }
    if (this.props.color !== prevProps.color) {
      this.colorProp$.next(this.props.color);
    }
    if (this.props.size !== prevProps.size) {
      this.sizeProp$.next(this.props.size);
    }
    if (this.props.fill !== prevProps.fill) {
      this.fillProp$.next(this.props.fill);
    }
    if (this.props.actions !== prevProps.actions) {
      this.shapes$.next([
        ...this.props.actions.filter((a) => a.name === 'imageMeta'),
        ...this.props.actions.filter(
          (a) => a.name !== 'imageMeta' && !notSelectableShapes.includes(a.name)
        ),
      ]);
    }
  }

  componentWillUnmount() {
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();
    this.zoomProp$.complete();
    this.scaleProp$.complete();
    this.originXProp$.complete();
    this.originYProp$.complete();
    this.colorProp$.complete();
    this.sizeProp$.complete();
    this.fillProp$.complete();
    this.coordinates$.complete();
    this.shapes$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, pointer`;
  };

  canvas = null;
  drawingContext = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();
  mode$ = new BehaviorSubject();
  zoomProp$ = new BehaviorSubject();
  scaleProp$ = new BehaviorSubject();
  originXProp$ = new BehaviorSubject();
  originYProp$ = new BehaviorSubject();
  colorProp$ = new BehaviorSubject();
  sizeProp$ = new BehaviorSubject();
  fillProp$ = new BehaviorSubject();
  coordinates$ = new BehaviorSubject();
  shapes$ = new BehaviorSubject();
  props$ = combineLatest(
    this.zoomProp$,
    this.scaleProp$,
    this.originXProp$,
    this.originYProp$,
    this.colorProp$,
    this.sizeProp$,
    this.fillProp$
  ).pipe(
    map(([zoom, scale, originX, originY, color, size, fill]) => ({
      zoom,
      scale,
      originX,
      originY,
      color,
      size,
      fill,
    }))
  );

  draw = (shape) => {
    if (!shape) return;
    const { zoom, scale, originX, originY } = this.props;
    if (!this.drawingContext) return;

    const drawer = drawers[shape.name];

    if (!drawer) return;

    this.clear();
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    drawer(this.drawingContext, shape);

    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  clear = () => {
    const { width, height } = this.props;
    this.drawingContext.clearRect(0, 0, width, height);
  };

  subscribeToDrawing = () => {
    const transformPoint = () =>
      pipe(
        withLatestFrom(this.props$),
        map(([point, props]) => {
          const { originX, originY, scale, zoom } = props;

          return {
            x: (point.x / zoom + originX) / scale,
            y: (point.y / zoom + originY) / scale,
          };
        })
      );

    const withMode = (m) =>
      pipe(
        withLatestFrom(this.mode$),
        filter(([, mode]) => mode === m),
        map(([e]) => e)
      );

    const startEvents$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint(), tap(clearSelection));

    const moveEvents$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint());

    const endEvents$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    );

    // Draw start
    startEvents$
      .pipe(withMode(Modes.Initial), withLatestFrom(this.shapes$))
      .subscribe(([coordinates, shapes]) => {
        for (let i = shapes.length - 1; i >= 0; i -= 1) {
          const shape = shapes[i];
          if (shape.intersect(coordinates, shape.rotation)) {
            this.a = shape.createMoveAction();
            this.props.setCurrentDrawing(this.a);
            this.mode$.next(Modes.Dragging);
            this.draw(this.a);
            return;
          }
        }
      });

    // Dragging
    startEvents$
      .pipe(
        withMode(Modes.Dragging),
        concatMap(() =>
          moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(Modes.Dragging),
            pairwise(),
            map(([oldPos, newPos]) => ({
              deltaX: newPos.x - oldPos.x,
              deltaY: newPos.y - oldPos.y,
            }))
          )
        ),
        map((transform) => {
          this.a.move(transform);
        })
      )
      .subscribe(() => {
        this.draw(this.a);
      });

    // Drag end
    startEvents$
      .pipe(
        withMode(Modes.Dragging),
        concatMap(() => endEvents$.pipe(take(1)))
      )
      .subscribe(() => {
        this.clear();
        this.mode$.next(Modes.Initial);

        const newToolName = this.a.name === 'imageMeta' ? 'image' : this.a.name;
        this.props.updateDrawingTool(newToolName, {
          previousDrawingTool: 'select',
        });

        const pathShape = this.props.pathShapes.find(
          (pathShape) => pathShape.pathData === this.a?.pathData
        );

        this.props.selectDrawingTool(pathShape?.id || newToolName);
      });

    this.mouseMove$
      .pipe(
        withMode(Modes.Initial),
        map(DomEventsUtil.mouseEventToCoordinates),
        transformPoint(),
        withLatestFrom(this.shapes$)
      )
      .subscribe(([coordinates, shapes]) => {
        for (let i = shapes.length - 1; i >= 0; i -= 1) {
          const shape = shapes[i];
          if (shape.intersect(coordinates, shape.rotation)) {
            this.drawingContext.canvas.style.cursor = 'select';
            this.drawingContext.canvas.style.cursor = '-webkit-move';
            this.drawingContext.canvas.style.cursor = '-moz-move';
            return;
          }
        }
      });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  render() {
    const { width, height } = this.props;

    return (
      <canvas
        ref={this.setDrawingContext}
        width={width}
        height={height}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        onContextMenu={this.onContextMenu}
      />
    );
  }
}

export default enhancer(SelectDrawingLayer);
