import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '../components/Container';
import ColorLiveBoardLogo from '../../../atoms/Icons/ColorLiveBoardLogo';
import AuthCover from '../components/AuthCover';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../atoms/Button/buttonTypes';
import AuthFooter from '../components/AuthFooter';
import analyticsService from '../../../../common/services/analytics.service';
import HorizontalDivider from '../../../atoms/HorizontalDivider';
import JoinWithCodeForm from '../JoinWithCodeForm';

const SignUpPreview = () => (
  <Box display="flex" height="100%">
    <Container>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 440,
          width: '100%',
          [theme.breakpoints.down('xs')]: {
            maxWidth: '320px',
          },
        })}
      >
        <ColorLiveBoardLogo
          sx={{
            width: '149px',
            height: '30px',
            margin: 'auto',
            mb: 3,
          }}
        />
        <Typography mb={2.5} align="center" variant="h6">
          Let’s get started!
        </Typography>
        <Typography align="center" variant="p4">
          Enter code to join existing session or sign up as a tutor
        </Typography>
        <Box display="flex" flexDirection="column" mt={8}>
          <JoinWithCodeForm />
          <HorizontalDivider
            sx={{
              mb: 3,
            }}
          >
            OR
          </HorizontalDivider>
          <Button
            variant={ButtonVariantTypes.OUTLINED}
            size={ButtonSizeTypes.L}
            to="/sign-up/teacher"
            component={NavLink}
            onClick={() =>
              analyticsService.event('Open Sign Up Click', {
                role: 'teacher',
              })
            }
            sx={{ mb: 2.5 }}
          >
            Sign up
          </Button>
          <AuthFooter
            questionText="Already have an account?"
            formButtonText="Log in"
          />
        </Box>
      </Box>
    </Container>
    <AuthCover />
  </Box>
);

export default SignUpPreview;
