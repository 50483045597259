import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { Track } from 'livekit-client';
import { Property } from 'csstype';

export interface VideoRendererProps {
  track: Track;
  isLocal: boolean;
  objectFit?: Property.ObjectFit;
  className?: string;
  width?: number | string;
  height?: number | string;
  onSizeChanged?: (width: number, height: number) => void;
  isPreviewMode?: boolean;
  isSpeaking?: boolean;
  isMirrored?: boolean;
}

const VideoRenderer = ({
  track,
  isLocal,
  objectFit,
  className,
  onSizeChanged,
  width,
  height,
  isPreviewMode,
  isSpeaking,
  isMirrored,
}: VideoRendererProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }
    videoElement.muted = true;
    track.attach(videoElement);
    return () => {
      track.detach(videoElement);
    };
  }, [track]);

  const handleResize = useCallback(
    (event: UIEvent) => {
      if (event.target instanceof HTMLVideoElement) {
        if (onSizeChanged) {
          onSizeChanged(event.target.videoWidth, event.target.videoHeight);
        }
      }
    },
    [onSizeChanged]
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('resize', handleResize);
    }
    return () => {
      videoElement?.removeEventListener('resize', handleResize);
    };
  }, []);

  const style: CSSProperties = {
    width: width,
    height: height,
  };

  const isFrontFacingOrUnknown =
    track.mediaStreamTrack?.getSettings().facingMode !== 'environment';
  if (
    isMirrored ||
    (isMirrored === undefined && isLocal && isFrontFacingOrUnknown)
  ) {
    style.transform = 'rotateY(180deg)';
  }

  if (objectFit) {
    style.objectFit = objectFit;
  }

  return (
    <video
      ref={videoRef}
      className={className}
      style={{
        ...style,
        borderRadius: isPreviewMode ? '4px' : '12px',
        ...(!isPreviewMode
          ? {
              border: `2px solid ${isSpeaking ? '#1EB270' : 'transparent'}`,
            }
          : {}),
      }}
    />
  );
};

export default VideoRenderer;
