import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useVariant } from '@unleash/proxy-client-react';
import PlanSrc from '../../assets/images/one-paid-plan.png';
import subscriptionsService from '../../common/services/subscriptions.service';
import environment from '../../config/environment';
import PlanDialogContext, { DialogContentProps } from './PlanDialogContext';

const getFirstPaidPlans = async () => {
  const plans = await subscriptionsService.getSubscriptionsList();
  const firstPaidMonthlyPlan = plans.monthly.find((plan) => !plan.isFree);
  const firstPaidYearlyPlan = plans.yearly.find(
    (plan) =>
      plan.planGroupReference === firstPaidMonthlyPlan?.planGroupReference
  );

  return {
    monthly: firstPaidMonthlyPlan,
    yearly: firstPaidYearlyPlan,
  };
};

const getPlanDto = async () => {
  const { monthly, yearly } = await getFirstPaidPlans();

  return {
    dialog: {
      trial: {
        title: 'Try free for 14 days',
        message:
          'Run engaging sessions, collaborate with students and manage your business from one place.',
        successBtn: 'Start Free Trial',
      },
      purchase: {
        title: 'Upgrade to Premium',
        message:
          'Run engaging sessions, collaborate with students and manage your business from one place.',
        successBtn: 'Unlock Features',
      },
    },
    imageUrl: PlanSrc,
    plan: {
      monthly,
      yearly,
    },
  };
};

const PlanDialogProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [dialogPayload, setDialogPayload] = useState<DialogContentProps | null>(
    null
  );

  const dialogContext = useVariant(
    environment.isProduction ? 'one-plan' : 'one-plan-dev'
  );

  const getDialogPayload = useCallback(async () => {
    const payload = dialogContext.payload?.value;
    if (!payload) {
      const planDto = await getPlanDto();

      return planDto;
    }

    const parsedPayload = JSON.parse(payload);
    const [monthly, yearly] = await Promise.all([
      subscriptionsService.getPlanById(parsedPayload.monthlyPlanId),
      subscriptionsService.getPlanById(parsedPayload.yearlyPlanId),
    ]);

    return {
      ...parsedPayload,
      plan: {
        monthly,
        yearly,
      },
    };
  }, [dialogContext?.payload?.value]);

  useEffect(() => {
    const fetchDialogPayload = async () => {
      const dialogPayload = await getDialogPayload();
      setDialogPayload(dialogPayload);
    };
    fetchDialogPayload();
  }, [setDialogPayload]);

  const value = useMemo(
    () => ({
      dialogPayload,
    }),
    [dialogPayload]
  );

  return (
    <PlanDialogContext.Provider value={value}>
      {children}
    </PlanDialogContext.Provider>
  );
};

export default PlanDialogProvider;
