import { FC, ReactElement } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import IconButton from '../IconButton';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
  ButtonShapeTypes,
} from '../Button/buttonTypes';
import FacebookIcon from '../Icons/FacebookIcon';
import InstagramIcon from '../Icons/InstagramIcon';
import YoutubeIcon from '../Icons/YoutubeIcon';
import SocialMediaSupportVariant from './SocialMediaSupportVariant';
import AccountGroupOutlineIcon from '../Icons/AccountGroupOutlineIcon';

type SocialMediaItemProps = {
  src: string;
  icon: ReactElement;
};

const SocialMediaItem: FC<React.PropsWithChildren<SocialMediaItemProps>> = ({
  src,
  icon,
}) => (
  <Link href={src} target="_blank" rel="noopener">
    <IconButton
      variant={ButtonVariantTypes.SECONDARY}
      color={ButtonColorTypes.PRIMARY}
      size={ButtonSizeTypes.M}
      shape={ButtonShapeTypes.ROUNDED}
    >
      {icon}
    </IconButton>
  </Link>
);

type SocialMediaSupportProps = {
  variant?: SocialMediaSupportVariant;
};

const SocialMediaSupport: FC<
  React.PropsWithChildren<SocialMediaSupportProps>
> = ({ variant = SocialMediaSupportVariant.HORIZONTAL }) => (
  <Box
    display="inline-flex"
    flexDirection={
      variant === SocialMediaSupportVariant.VERTICAL ? 'column' : 'row'
    }
    gap={1}
  >
    <SocialMediaItem
      src="https://www.facebook.com/liveboard.online"
      icon={<FacebookIcon />}
    />
    <SocialMediaItem
      src="https://www.instagram.com/liveboard.online/"
      icon={<InstagramIcon />}
    />
    <SocialMediaItem
      src="https://www.facebook.com/groups/338273881395636"
      icon={<AccountGroupOutlineIcon />}
    />
    <SocialMediaItem
      src="https://www.youtube.com/LiveBoardWhiteboard"
      icon={<YoutubeIcon />}
    />
  </Box>
);

export default SocialMediaSupport;
