import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from '../components/Layout';
import { AssessmentPaperLayout } from '../components/AssessmentPaperLayout';
import { lazyImport } from '../../../../common/utils/lazy-import';
import Loader from '../../../../components/Icons/Loader';

const { TakeAssessment } = lazyImport(
  () => import('../features/take-assessment/TakeAssessment'),
  'TakeAssessment'
);

const { PaperDetails } = lazyImport(
  () => import('../features/paper-details/PaperDetails'),
  'PaperDetails'
);
export const AssessmentPaperRoutes: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Layout>
    <Switch>
      <Route
        exact
        path={`/assessment/:assessmentId`}
        render={({ match }) => (
          <AssessmentPaperLayout>
            <Suspense fallback={<Loader />}>
              <TakeAssessment assessmentId={match.params.assessmentId} />
            </Suspense>
          </AssessmentPaperLayout>
        )}
      />
      <Route
        path={`/paper/:paperId`}
        exact
        render={({ match }) => (
          <AssessmentPaperLayout>
            <Suspense fallback={<Loader />}>
              <PaperDetails paperId={match.params.paperId} />
            </Suspense>
          </AssessmentPaperLayout>
        )}
      />
    </Switch>
  </Layout>
);
