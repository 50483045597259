import { FC, MouseEvent } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import ToggleButton from '../../atoms/ToggleButton';
import Typography from '../../atoms/Typography';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import ToggleButtonGroup from '../../atoms/ToggleButtonGroup';

type PlanToggleProps = {
  isYearlySelected: boolean;
  onChange: (event: MouseEvent<HTMLElement>, value: any) => void;
  discountText: string;
};

const PlanToggle: FC<React.PropsWithChildren<PlanToggleProps>> = ({
  isYearlySelected,
  onChange,
  discountText,
}) => {
  const theme = useTheme();
  const showB4Text = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ToggleButtonGroup
      exclusive
      onChange={onChange}
      value={isYearlySelected ? 'yearly' : 'monthly'}
      fullWidth
    >
      <ToggleButton disableRipple value="monthly" width="100%" size="small">
        <Typography
          variant={showB4Text ? TypographyType.b4 : TypographyType.b5}
          sx={(t) => ({
            color: !isYearlySelected ? t.text.t8 : t.text.t7,
          })}
        >
          Monthly
        </Typography>
      </ToggleButton>
      <ToggleButton disableRipple value="yearly" width="100%" size="small">
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
          }}
        >
          <Typography
            variant={showB4Text ? TypographyType.b4 : TypographyType.b5}
            sx={(t) => ({
              color: isYearlySelected ? t.text.t8 : t.text.t7,
            })}
          >
            Yearly
          </Typography>
          <Typography
            variant={showB4Text ? TypographyType.b4 : TypographyType.b5}
            sx={(t) => ({
              fontWeight: 700,
              color: t.status.new.main,
            })}
          >
            -{discountText}
          </Typography>
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PlanToggle;
