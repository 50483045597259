import { httpServiceV4 } from './http.service';
import CreateConferencePayload from './dto/livekit/CreateConferencePayload';
import CreateConferenceDto from './dto/livekit/CreateConferenceDto';
import SuccessPayload from './types/common/SuccessPayload';

const livekitUrl = '/livekit/conference';

const createConference = ({
  sessionId,
  mutedOnJoin,
}: CreateConferencePayload) =>
  httpServiceV4.post<CreateConferenceDto, CreateConferenceDto>(
    `${livekitUrl}/create`,
    {
      sessionId,
      mutedOnJoin,
    }
  );

const startConference = (sessionId: string) =>
  httpServiceV4.post<SuccessPayload, SuccessPayload>(`${livekitUrl}/start`, {
    sessionId,
  });

const stopConference = (sessionId: string) =>
  httpServiceV4.post<SuccessPayload, SuccessPayload>(`${livekitUrl}/stop`, {
    sessionId,
  });

const getToken = (sessionId: string) => {
  const searchParams = new URLSearchParams();
  searchParams.set('sessionId', sessionId);
  return httpServiceV4.get<CreateConferenceDto, CreateConferenceDto>(
    `${livekitUrl}/access-token`,
    {
      params: searchParams,
    }
  );
};

export default {
  createConference,
  startConference,
  stopConference,
  getToken,
};
