import TriangleResizer from './TriangleResizer';

export default class TopTriangleResizer extends TriangleResizer {
  center() {
    return this.top;
  }

  onResize({ x, y }) {
    return {
      top: { x, y },
      right: { ...this.right },
      left: { ...this.left },
    };
  }
}
