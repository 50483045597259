import React, { createContext } from 'react';

type ProductTourContextType = {
  inProgress: boolean;
  sideEffect: string;
  setInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setSideEffect: React.Dispatch<React.SetStateAction<string>>;
};

const initialProductTourState: ProductTourContextType = {
  inProgress: false,
  sideEffect: '',
  setInProgress: (prevState) => prevState,
  setSideEffect: (prevState) => prevState,
};

const ProductTourContext = createContext<ProductTourContextType>(
  initialProductTourState
);

export default ProductTourContext;

export type { ProductTourContextType };
