import React, { FC } from 'react';
import { cardClasses, cardContentClasses } from '@mui/material';
import Card from '../../../../../../../ui/molecules/Card';
import CardInfo from '../../../../../../../ui/molecules/CardInfo';
import QuestionListOutlineIcon from '../../../../../../../ui/atoms/Icons/QuestionListOutlineIcon';
import { CardGridItem } from '../../../../../../../ui/atoms/CardGridItem';
import { CardAction } from '../../../../../../../ui/molecules/CardInfo/cardInfoTypes';
import formatDistanceBetweenNow, {
  cardDate,
} from '../../../../../../../common/utils/date.utils';

export type BaseAssessmentTemplateCardProps = {
  templateName: string;
  createdDate: number;
  updatedDate: number;
  questionsCount: number;
  onClick: () => void;
};

const BaseAssessmentTemplateCard: FC<
  React.PropsWithChildren<
    BaseAssessmentTemplateCardProps & {
      actions: CardAction[];
    }
  >
> = ({
  templateName,
  createdDate,
  updatedDate,
  questionsCount,
  onClick,
  actions,
}) => (
  <CardGridItem>
    <Card
      onClick={onClick}
      sx={{
        [`&.${cardClasses.root}`]: {
          borderRadius: '20px 10px',
        },
        [`& .${cardContentClasses.root}`]: {
          borderRadius: '20px 10px',
        },
      }}
    >
      <CardInfo
        title={templateName}
        subtitle={`${questionsCount} question${
          questionsCount === 1 || questionsCount === 0 ? '' : 's'
        }`}
        CardIcon={QuestionListOutlineIcon}
        date={cardDate(createdDate)}
        subtitleExtra={`Edited ${formatDistanceBetweenNow(updatedDate)}`}
        cardActionsData={actions}
      />
    </Card>
  </CardGridItem>
);

const MemoizedBaseAssessmentTemplateCard = React.memo(
  BaseAssessmentTemplateCard
);

export { MemoizedBaseAssessmentTemplateCard as BaseAssessmentTemplateCard };
