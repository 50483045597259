import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push as redirect } from 'redux-first-history';
import collectPaymentsService from '../../common/services/collectPayments.service';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import analyticsService from '../../common/services/analytics.service';

export const CollectPaymentsOnboarding: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        analyticsService.event('Refresh Create Business Account Link');
        const result = await collectPaymentsService.createOnboardingUrl();
        window.location.href = result.url;
      } catch (e) {
        dispatch(redirect('/dashboard'));
      }
    })();
  }, []);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress variant="indeterminate" />
    </Box>
  );
};
