import { useCallback, useContext, useRef, useState } from 'react';
import {
  AudioTrack,
  ConnectionState,
  LocalTrackPublication,
  Participant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomConnectOptions,
  RoomEvent,
  RoomOptions,
  Track,
} from 'livekit-client';
import LiveKitContext from '../../components/BoardControls/CollaborationTools/Manager/LiveKitManager/context/LiveKitContext';
import RecordingContext from '../../components/Recording/RecordingContext';
import analyticsService from '../services/analytics.service';

const useRoom = (roomOptions: RoomOptions) => {
  const [room] = useState<Room>(new Room(roomOptions));
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<Error>();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.Disconnected
  );

  const livekitContext = useContext(LiveKitContext);

  const audioContext = useRef(new AudioContext());
  const destinationStream = useRef(
    audioContext.current.createMediaStreamDestination()
  );
  const isConferenceStreamInitialized = useRef(false);
  const { setConferenceAudioStream, clearConferenceAudioStream } =
    useContext(RecordingContext);

  const connect = useCallback(
    async (url: string, token: string, options?: RoomConnectOptions) => {
      setIsConnecting(true);
      try {
        const onParticipantsChanged = () => {
          if (!room) return;
          const remotes = Array.from(room.participants.values());
          const newParticipants: Participant[] = [room.localParticipant];
          newParticipants.push(...remotes);
          setParticipants(newParticipants);
        };

        const onSubscribedTrackChanged = (track?: RemoteTrack) => {
          // ordering may have changed, re-sort
          onParticipantsChanged();
          if ((track && track.kind !== Track.Kind.Audio) || !room) {
            return;
          }
          const tracks: AudioTrack[] = [];
          room.participants.forEach((p) => {
            p.audioTracks.forEach((pub) => {
              if (pub.audioTrack) {
                tracks.push(pub.audioTrack);
                if (pub.audioTrack.mediaStream) {
                  const stream = audioContext.current.createMediaStreamSource(
                    pub.audioTrack.mediaStream
                  );
                  stream.connect(destinationStream.current);
                }
              }
            });
          });
          setAudioTracks(tracks);
        };

        const onConnectionStateChanged = (state: ConnectionState) => {
          setConnectionState(state);
        };

        const onTrackSubscribed = (
          track?: RemoteTrack,
          publication?: RemoteTrackPublication
        ) => {
          if (publication && publication.source === Track.Source.ScreenShare) {
            livekitContext.onChangeScreenShared(true);
          }

          onSubscribedTrackChanged(track);

          if (
            !isConferenceStreamInitialized.current &&
            destinationStream.current.stream
          ) {
            const applyConferenceAudioStream = async () => {
              await setConferenceAudioStream(destinationStream.current.stream);
            };
            applyConferenceAudioStream();
            isConferenceStreamInitialized.current = true;
          }
        };

        const onTrackUnsubscribed = (
          track?: RemoteTrack,
          publication?: RemoteTrackPublication
        ) => {
          if (publication && publication.source === Track.Source.ScreenShare) {
            livekitContext.onChangeScreenShared(false);
          }

          onSubscribedTrackChanged(track);
          clearConferenceAudioStream();
          isConferenceStreamInitialized.current = false;
        };

        const onLocalTrackUnpublished = (
          publication?: LocalTrackPublication
        ) => {
          if (publication && publication.source === Track.Source.ScreenShare) {
            livekitContext.onChangeScreenShared(false);
            analyticsService.event('Screen Share Disable Click');
          }

          onParticipantsChanged();
        };

        room.once(RoomEvent.Disconnected, () => {
          room
            .off(RoomEvent.ParticipantConnected, onParticipantsChanged)
            .off(RoomEvent.ParticipantDisconnected, onParticipantsChanged)
            .off(RoomEvent.ActiveSpeakersChanged, onParticipantsChanged)
            .off(RoomEvent.TrackSubscribed, onTrackSubscribed)
            .off(RoomEvent.TrackUnsubscribed, onTrackUnsubscribed)
            .off(RoomEvent.LocalTrackPublished, onParticipantsChanged)
            .off(RoomEvent.LocalTrackUnpublished, onLocalTrackUnpublished)
            .off(RoomEvent.AudioPlaybackStatusChanged, onParticipantsChanged)
            .off(RoomEvent.ConnectionStateChanged, onConnectionStateChanged);
        });
        room
          .on(RoomEvent.ParticipantConnected, onParticipantsChanged)
          .on(RoomEvent.ParticipantDisconnected, onParticipantsChanged)
          .on(RoomEvent.ActiveSpeakersChanged, onParticipantsChanged)
          .on(RoomEvent.TrackSubscribed, onTrackSubscribed)
          .on(RoomEvent.TrackUnsubscribed, onTrackUnsubscribed)
          .on(RoomEvent.LocalTrackPublished, onParticipantsChanged)
          .on(RoomEvent.LocalTrackUnpublished, onLocalTrackUnpublished)
          // trigger a state change by re-sorting participants
          .on(RoomEvent.AudioPlaybackStatusChanged, onParticipantsChanged)
          .on(RoomEvent.ConnectionStateChanged, onConnectionStateChanged);

        await room?.connect(url, token, options);
        setIsConnecting(false);
        onSubscribedTrackChanged();
        setError(undefined);
        return room;
      } catch (err: any) {
        setIsConnecting(false);
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error('an error has occured'));
        }

        return undefined;
      }
    },
    []
  );

  const disconnect = useCallback(async () => {
    await room.disconnect(true);
    setIsConnecting(false);
    setError(undefined);
    setParticipants([]);
    setAudioTracks([]);
  }, []);

  return {
    connect,
    disconnect,
    isConnecting,
    room,
    error,
    participants,
    audioTracks,
    connectionState,
  };
};

export default useRoom;
