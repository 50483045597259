import { FC } from 'react';
import Box from '@mui/material/Box';

const Container: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box
    sx={(theme) => ({
      width: '100%',
      padding: '24px 20px',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
    })}
  >
    {children}
  </Box>
);

export default Container;
