import {
  DisplayObject,
  filters,
  Graphics,
  LINE_CAP,
  LINE_JOIN,
  Rectangle,
} from 'pixi.js';
import { cloneDeep } from 'lodash';
import DrawingAction from './drawingAction';
import OldFreeLineAction from '../../../common/drawingActions/free.drawing.v3.action';
import Point from '../types/Point';
import guid from '../../../common/utils/guid.util';
import ActionName from '../types/ActionName';

interface QuadraticPointData {
  x1: number;
  y1: number;
  x2?: number;
  y2?: number;
}

interface AABB {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

const alphaFilter = new filters.AlphaFilter(0.4);

class FreeLine extends DrawingAction {
  aabb: AABB;

  points: Point[];

  processedPoints: QuadraticPointData[];

  constructor(action?: OldFreeLineAction) {
    super(action);

    this.name = ActionName.FREE_LINE_V3;
    this.aabb = {
      xMin: Number.MAX_VALUE,
      xMax: -Number.MAX_VALUE,
      yMin: Number.MAX_VALUE,
      yMax: -Number.MAX_VALUE,
    };
    this.points = [];
    this.processedPoints = [];

    if (action) {
      this.points = action.points;

      this.processedPoints = action.processedPoints;

      for (let i = 0; i < action.points.length; i += 1) {
        this.aabb = {
          xMin: Math.min(this.aabb.xMin, action.points[i].x),
          xMax: Math.max(this.aabb.xMax, action.points[i].x),
          yMin: Math.min(this.aabb.yMin, action.points[i].y),
          yMax: Math.max(this.aabb.yMax, action.points[i].y),
        };
      }
    }
  }

  toDto(): OldFreeLineAction {
    const dto = new OldFreeLineAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldFreeLineAction) {
    super.setFields(action);
    action.name = ActionName.FREE_LINE_V3;
    action.points = this.points;
    action.processedPoints = this.processedPoints;
  }

  createMoveAction(newPos: Point): FreeLine {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;

    const center = {
      x: (this.aabb.xMin + this.aabb.xMax) / 2,
      y: (this.aabb.yMin + this.aabb.yMax) / 2,
    };

    const delta = {
      x: newPos.x - center.x,
      y: newPos.y - center.y,
    };

    const startPoint = {
      x: this.aabb.xMin + delta.x,
      y: this.aabb.yMin + delta.y,
    };
    const endPoint = {
      x: this.aabb.xMax + delta.x,
      y: this.aabb.yMax + delta.y,
    };

    newAction.points = newAction.points.map((point) => ({
      x: point.x + delta.x,
      y: point.y + delta.y,
    }));

    newAction.updatePoints();

    newAction.aabb = {
      xMin: startPoint.x,
      xMax: endPoint.x,
      yMin: startPoint.y,
      yMax: endPoint.y,
    };

    return newAction;
  }

  update(startPos: Point, endPos: Point) {
    this.aabb = {
      xMin: Math.min(this.aabb.xMin, endPos.x),
      xMax: Math.max(this.aabb.xMax, endPos.x),
      yMin: Math.min(this.aabb.yMin, endPos.y),
      yMax: Math.max(this.aabb.yMax, endPos.y),
    };

    this.points.push({
      x: endPos.x,
      y: endPos.y,
    });

    this.updatePoints();
  }

  updatePoints() {
    if (!this.points || !this.points.length) return;
    this.processedPoints = [];
    let p1 = this.points[0];
    let p2 = this.points[1];
    const len = this.points.length;

    for (let i = 1; i < len; i += 1) {
      this.processedPoints.push({
        x1: p1.x,
        y1: p1.y,
        x2: (p2.x + p1.x) / 2,
        y2: (p2.y + p1.y) / 2,
      });
      p1 = this.points[i];
      if (i + 1 === len) break;
      p2 = this.points[i + 1];
    }

    this.processedPoints.push({
      x1: p1.x,
      y1: p1.y,
    });
  }

  draw(object: DisplayObject) {
    if (!this.processedPoints || !this.processedPoints.length) return;

    const graphicsObject = object as Graphics;

    const width = this.aabb.xMax - this.aabb.xMin;
    const height = this.aabb.yMax - this.aabb.yMin;

    const center = {
      x: (this.aabb.xMin + this.aabb.xMax) / 2,
      y: (this.aabb.yMin + this.aabb.yMax) / 2,
    };

    graphicsObject.clear();
    graphicsObject.position.set(center.x, center.y);

    const color = this.paint.color & 0xffffff;
    const alpha = ((this.paint.color & 0xff000000) >>> 24) / 255;
    const size = +this.paint.strokeWidth;

    if (alpha < 1) {
      graphicsObject.filters = [alphaFilter];
    }

    if (this.processedPoints.length === 1) {
      graphicsObject.beginFill(color);

      graphicsObject.drawCircle(0, 0, size / 2);
    } else {
      graphicsObject.lineStyle({
        width: size,
        color,
        cap: LINE_CAP.ROUND,
        join: LINE_JOIN.ROUND,
      });

      const firstPoint = this.processedPoints[0];
      graphicsObject.moveTo(firstPoint.x1 - center.x, firstPoint.y1 - center.y);
      this.processedPoints.forEach(({ x1, y1, x2, y2 }) => {
        if (!x2 || !y2) return;

        graphicsObject.quadraticCurveTo(
          x1 - center.x,
          y1 - center.y,
          x2 - center.x,
          y2 - center.y
        );
      });

      const lastPoint = this.processedPoints[this.processedPoints.length - 1];
      // Draw last line as a straight line while
      // we wait for the next point to be able to calculate
      // the bezier control point
      graphicsObject.lineTo(lastPoint.x1 - center.x, lastPoint.y1 - center.y);
    }

    graphicsObject.hitArea = new Rectangle(
      -(width + size) / 2,
      -(height + size) / 2,
      width + size,
      height + size
    );
  }
}

export default FreeLine;
