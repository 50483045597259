enum ErrorCodes {
  E_NOT_FOUND = '000003',
  E_USER_NOT_FOUND = '100001',
  E_USER_EXISTS = '100003',
  E_INVALID_CREDENTIALS = '100008',
  E_RECORDING_NOT_FOUND = '201001',
  E_VALIDATION = '000002',
  E_ASSESSMENT_TEMPLATE_NOT_PUBLISHED = '310006',
}

export default ErrorCodes;
