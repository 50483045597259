import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';
import { useVariant } from '@unleash/proxy-client-react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { liveBoardStorage } from '../../../../config/storage';
import Input from '../../../atoms/Input';
import InputVariant from '../../../atoms/Input/InputVariant';
import InputSize from '../../../atoms/Input/InputSize';
import EmailOutlineIcon from '../../../atoms/Icons/EmailOutlineIcon';
import InputStatus from '../../../atoms/Input/InputStatus';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../atoms/Button/buttonTypes';
import Button from '../../../atoms/Button';
import { saveUserAction } from '../../../../common/actions/authActions';
import analyticsService from '../../../../common/services/analytics.service';
import EyeOutlineIcon from '../../../atoms/Icons/EyeOutlineIcon';
import EyeOffOutlineIcon from '../../../atoms/Icons/EyeOffOutlineIcon';
import LockOutlineIcon from '../../../atoms/Icons/LockOutlineIcon';
import authService from '../../../../common/services/auth.service';
import AuthResponsePayload from '../../../../common/services/types/auth/AuthResponsePayload';
import ErrorCodes from '../../../../common/errors/errorCodes';
import isValidEmail from '../../../../common/utils/email.validation.util';
import validatePassword from './validatePassword';
import { getGeneralConfiguration } from '../../../../common/actions/configurationActions';
import { useProductTour } from '../../../../components/ProductTour';
import { useBoard } from './useBoard';
import { setShowProductTour } from '../../../../common/actions/dashboardActions';
import { setOpenSource } from '../../../../common/actions/session/sessionActions';
import AccountOutlineIcon from '../../../atoms/Icons/AccountOutlineIcon';

type Props = {
  planId?: string;
  userRole: 'student' | 'teacher';
};

interface UrlParams {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const SignUpForm: FC<React.PropsWithChildren<Props>> = ({
  planId,
  userRole,
}) => {
  const dispatch = useDispatch();
  const productTour = useProductTour();
  const shouldCreateBoardVariant = useVariant('open-board-after-registration');
  const { processBoardCreating } = useBoard();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation<{ referrer?: string }>();
  const {
    firstName: paramsName,
    lastName: paramsLastName,
    email: paramsEmail,
  } = useMemo<UrlParams>(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search]
  );

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  const {
    handleSubmit: formSubmit,
    control,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: paramsName || '',
      lastName: paramsLastName || '',
      email: paramsEmail || '',
      password: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (paramsEmail) {
      trigger('email');
    }
  }, [paramsEmail, trigger]);

  const onSubmit = useCallback(
    formSubmit(async ({ name, lastName, email, password }) => {
      try {
        if (!location.state?.referrer) {
          analyticsService.setSuperProperties({
            'experiment_open-board-after-registration': `${shouldCreateBoardVariant.name}`,
          });
        }

        const { user, accessToken }: AuthResponsePayload =
          await authService.signUp(
            email,
            name,
            lastName,
            password,
            userRole,
            (window as any).Rewardful?.referral
          );
        analyticsService.event('Sign Up', { method: 'Email', role: userRole });

        if (location.state?.referrer) {
          dispatch(setOpenSource('Sign up'));
          dispatch(push(location.state.referrer));
          dispatch(saveUserAction(user, accessToken));

          return;
        }

        if (
          user.role === 'teacher' &&
          shouldCreateBoardVariant?.name === 'open-board'
        ) {
          await processBoardCreating();
        }

        if (planId) {
          const updatedUser = await authService.startTrial(planId, false);
          analyticsService.event('Trial Activated', {
            planId,
            source: 'Sign Up',
          });
          liveBoardStorage.setItem('showTrialSuccess', 'true');
          liveBoardStorage.setItem('liveboard:showTrialSuccess', 'true');
          dispatch(getGeneralConfiguration());
          dispatch(saveUserAction(updatedUser, accessToken));

          return;
        }

        dispatch(saveUserAction(user, accessToken));
        if (
          user.role === 'teacher' &&
          shouldCreateBoardVariant?.name === 'open-dashboard'
        ) {
          dispatch(setShowProductTour(true));
        }
      } catch (err) {
        if (err.code === ErrorCodes.E_USER_EXISTS) {
          setError('This email address is already in use');

          return;
        }

        setError(err.message);
      }
    }),
    [
      dispatch,
      setError,
      planId,
      userRole,
      productTour,
      shouldCreateBoardVariant,
      processBoardCreating,
      location,
    ]
  );

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" gap="16px" flexDirection="column">
        <Box display="flex" gap="12px" flexWrap="wrap">
          <Box
            sx={{
              minWidth: '214px',
              width: '100%',
              flex: 1,
            }}
          >
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
              render={({
                field: { onChange, value, name },
                formState: {
                  errors: { name: nameError },
                },
              }) => (
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  variant={InputVariant.CONTAINED}
                  size={InputSize.M}
                  placeholder="First name"
                  icon={<AccountOutlineIcon />}
                  status={nameError ? InputStatus.ERROR : undefined}
                  helperText={nameError ? nameError.message : undefined}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              minWidth: '214px',
              width: '100%',
              flex: 1,
            }}
          >
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
              render={({
                field: { onChange, value, name },
                formState: {
                  errors: { lastName },
                },
              }) => (
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  variant={InputVariant.CONTAINED}
                  size={InputSize.M}
                  placeholder="Last name"
                  icon={<AccountOutlineIcon />}
                  status={lastName ? InputStatus.ERROR : undefined}
                  helperText={lastName ? lastName.message : undefined}
                />
              )}
            />
          </Box>
        </Box>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'This field is required',
            validate: (value) => {
              if (isValidEmail(value)) return undefined;

              return 'Invalid email format';
            },
          }}
          render={({
            field: { onChange, value, name },
            formState: {
              errors: { email },
            },
          }) => (
            <Input
              name={name}
              disabled={!!paramsEmail && !email?.message}
              value={value}
              onChange={onChange}
              variant={InputVariant.CONTAINED}
              size={InputSize.M}
              placeholder="Email address"
              icon={<EmailOutlineIcon />}
              status={email ? InputStatus.ERROR : undefined}
              helperText={email ? email.message : undefined}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            validate: (value) =>
              validatePassword(value, {
                length: 8,
                digit: 1,
                letter: 1,
              }),
          }}
          render={({
            field: { onChange, value, name },
            formState: {
              errors: { password },
            },
          }) => (
            <Input
              name={name}
              value={value}
              onChange={onChange}
              variant={InputVariant.CONTAINED}
              size={InputSize.M}
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              endAdornmentPrimaryIcon={
                showPassword ? (
                  <EyeOutlineIcon
                    onClick={handleClickShowPassword}
                    sx={(theme) => ({
                      color: theme.text.t6,
                    })}
                  />
                ) : (
                  <EyeOffOutlineIcon
                    onClick={handleClickShowPassword}
                    sx={(theme) => ({
                      color: theme.text.t6,
                    })}
                  />
                )
              }
              icon={<LockOutlineIcon />}
              status={password ? InputStatus.ERROR : undefined}
              helperText={password ? password.message : undefined}
            />
          )}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Box display="flex" gap="12px">
          <Typography variant="p5">
            {"By submitting, you agree to LiveBoard's"}{' '}
            <Link
              target="_blank"
              href="https://liveboard.online/terms"
              color="premium.main"
            >
              Terms and Conditions
            </Link>
            {' and '}
            <Link
              target="_blank"
              href="https://liveboard.online/privacy"
              color="premium.main"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
        <Box mt={1.25}>
          <Button
            type="submit"
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.M}
            fullWidth
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Sign up
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default SignUpForm;
