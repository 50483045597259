import React from 'react';
import Stack from '@mui/material/Stack';
import { ChangePassword } from './components/ChangePassword';
import { UpdateUser } from './components/UpdateUser';
import { DeleteAccount } from './components/DeleteAccount';

export const Account: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Stack mb={6} gap={2}>
    <UpdateUser />
    <ChangePassword />
    <DeleteAccount />
  </Stack>
);
