import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import * as activePageActions from '../../../../common/actions/board/activePageActions';
import { getActivePageId } from '../../../../common/reducers/board/activePageReducer';
import { getPages } from '../../../../common/reducers/board/pagesReducer';
import * as sessionReducer from '../../../../common/reducers/session/sessionReducer';
import * as pagesActions from '../../../../common/actions/board/pagesActions';
import PagesSideBarList from './List/PagesSideBarList';
import PagesLimitNotification, {
  SectionName,
} from '../../../Notifications/PagesLimitNotification/PagesLimitNotification';
import analyticsService from '../../../../common/services/analytics.service';

import {
  isCurrentUserHostSelector,
  pagesCountLimitSelector,
  pagesCountSelector,
  sessionIdSelector,
  showPagesLimitInfoSelector,
} from '../../../../common/reducers/session/sessionReducer';
// ToDo: remove usage of styles inside scss
import './PagesSideBar.scss';
import './Header/PagesSideBarHeader.scss';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../ui/atoms/Button/buttonTypes';
import CloseIcon from '../../../../ui/atoms/Icons/CloseIcon';
import FilePlusOutlineIcon from '../../../../ui/atoms/Icons/FilePlusOutlineIcon';

const Toolbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const PagesSideBar = ({ opened, onClose, editable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const activePageId = useSelector(getActivePageId);
  const pages = useSelector(getPages);
  const dimensions = useSelector(sessionReducer.dimensionsSelector);
  const showPagesLimitInfo = useSelector(showPagesLimitInfoSelector);
  const pagesCount = useSelector(pagesCountSelector);
  const pagesCountLimit = useSelector(pagesCountLimitSelector);
  const sessionId = useSelector(sessionIdSelector);
  const isHost = useSelector(isCurrentUserHostSelector);

  const deletePage = useCallback(
    (id) => {
      if (pagesCount <= 1 || !editable) return;

      dispatch(pagesActions.deletePage(id));
    },
    [pagesCount, dispatch, editable]
  );

  const selectPage = useCallback(
    (id) => {
      dispatch(activePageActions.selectPage(id));
    },
    [dispatch]
  );

  const createPage = useCallback(() => {
    analyticsService.event('Create Board Page Button Click', {
      source: 'Page picker',
      sessionId,
      pageCount: pagesCount + 1,
    });

    if (pagesCount >= pagesCountLimit) {
      analyticsService.event('Create Page above Limit');
      enqueueSnackbar('You have reached maximum pages count', {
        autoHideDuration: 3000,
        variant: 'error',
        preventDuplicate: true,
      });
      return;
    }
    dispatch(pagesActions.createPage());
  }, [pagesCount, pagesCountLimit, enqueueSnackbar, dispatch, sessionId]);

  const copyPage = useCallback(
    (id) => {
      if (pagesCount >= pagesCountLimit) {
        analyticsService.event('Create Page above Limit');
        enqueueSnackbar('You have reached maximum pages count', {
          autoHideDuration: 3000,
          variant: 'error',
          preventDuplicate: true,
        });
        return;
      }
      dispatch(pagesActions.copyPage(id));
    },
    [enqueueSnackbar, dispatch, pagesCount, pagesCountLimit]
  );

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={opened}
      onClose={onClose}
      PaperProps={{
        sx: (theme) => ({
          width: 320,
          zIndex: theme.zIndex.drawer + 1,
          overflowY: 'unset',
          backgroundColor: theme.background.bg1,
        }),
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Toolbar>
        {editable && (
          <Tooltip title="Add page" placement="bottom-end">
            <Box>
              <IconButton
                onClick={createPage}
                variant={ButtonVariantTypes.PRIMARY}
                size={ButtonSizeTypes.S}
                shape={ButtonShapeTypes.ROUNDED}
              >
                <FilePlusOutlineIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          px={1}
        >
          <Typography variant="s4">{`Pages(${pagesCount})`}</Typography>
        </Box>
        <IconButton
          onClick={onClose}
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          shape={ButtonShapeTypes.ROUNDED}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider
        sx={{
          //Todo: remove this code after removing bambourgh-wrapper
          marginTop: '0 !important',
          marginBottom: '0 !important',
        }}
      />
      {isHost && showPagesLimitInfo && pagesCount >= pagesCountLimit && (
        <PagesLimitNotification sectionName={SectionName.PAGE_SIDEBAR} />
      )}
      <PagesSideBarList
        activePageId={activePageId}
        pages={pages}
        dimensions={dimensions}
        onSelect={selectPage}
        onCopy={copyPage}
        onDelete={deletePage}
        editable={editable}
      />
    </Drawer>
  );
};

PagesSideBar.propTypes = {
  activePageId: PropTypes.number,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PagesSideBar;
