import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Rnd } from 'react-rnd';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import useWindowSize from '../../common/hooks/useWindowSize';
import { getPopupPosition } from './LiveKitPopup';

type LivekitDraggableComponentProps = {
  userPresenceBarWidth: number;
  rndStyle?: CSSProperties;
  dialogMargin: number;
  collaborationToolsHeight: number;
  collaborationTop: number;
};

type DropPosition = {
  x: number;
  y: number;
};

const DraggableMinimizedComponent: FC<
  React.PropsWithChildren<LivekitDraggableComponentProps>
> = ({
  userPresenceBarWidth,
  rndStyle,
  dialogMargin,
  collaborationToolsHeight,
  collaborationTop,
  children,
}) => {
  //Todo: remove any type and declare appropriate type
  const rndRef = useRef<any | null>(null);
  const { width: windowWidth } = useWindowSize();
  const [dropPosition, setDropPosition] = useState<DropPosition | null>(null);
  const onDragEnd = useCallback(
    (event: any, { lastX, lastY }: any) => {
      setDropPosition({ x: lastX, y: lastY });
    },
    [setDropPosition]
  );

  useEffect(() => {
    const changePosition = (event: UIEvent) => {
      if (!rndRef.current) return;

      const newPosition = getPopupPosition(
        event,
        dropPosition,
        userPresenceBarWidth
      );
      setDropPosition(null);
      rndRef.current.updatePosition(newPosition);
    };

    window.addEventListener('resize', changePosition);

    return () => {
      window.removeEventListener('resize', changePosition);
    };
  }, [dropPosition, userPresenceBarWidth, setDropPosition]);

  const rndDefault = useMemo(
    () => ({
      x: windowWidth - userPresenceBarWidth - 420 - dialogMargin,
      y: collaborationTop + collaborationToolsHeight + dialogMargin,
      width: 420,
      height: 48,
    }),
    [
      windowWidth,
      userPresenceBarWidth,
      dialogMargin,
      collaborationTop,
      collaborationToolsHeight,
    ]
  );

  return (
    <Rnd
      ref={rndRef}
      default={rndDefault}
      bounds="window"
      dragHandleClassName="dragging-handle"
      style={rndStyle}
      width={420}
      height={48}
      onDragStop={onDragEnd}
    >
      <Box
        className="dragging-handle"
        sx={{
          height: '100%',
          width: '100%',
          cursor: 'grab',
        }}
      >
        <Box
          sx={(t) => ({
            height: '100%',
            backgroundColor: t.background.bg2,
            position: 'relative',
            ':hover': {
              [`& .${toggleButtonGroupClasses.root}`]: {
                opacity: 1,
              },
            },
          })}
        >
          {children}
        </Box>
      </Box>
    </Rnd>
  );
};

export default DraggableMinimizedComponent;
