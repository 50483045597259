import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { useDispatch, useSelector } from 'react-redux';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import SocketContext from './SocketContext';
import ServerToClientCursorPayload from '../../../common/types/serverToClientCursorPayload';
import { updateCursorAction } from '../../../common/actions/board/cursorActions';
import {
  connectedSelector,
  sessionIdSelector,
} from '../../../common/reducers/session/sessionReducer';
import UnleashFlags from '../../../common/constants/UnleashFlag';
import environment from '../../../config/environment';

const SocketContextProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const activeCursorFlag = useFlag(UnleashFlags.ACTIVE_CURSOR);
  const dispatch = useDispatch();
  const [localSocket, setLocalSocket] = useState<Socket | null>(null);

  const sessionId: string = useSelector(sessionIdSelector);
  const connected: boolean = useSelector(connectedSelector);
  useEffect(() => {
    if (sessionId && connected && activeCursorFlag) {
      const socket = io(environment.socketServer.url);

      socket.emit('join', { roomId: sessionId });

      socket.on(
        'cursor:position:update',
        (payload: ServerToClientCursorPayload) => {
          dispatch(updateCursorAction(payload));
        }
      );

      setLocalSocket(socket);

      return () => {
        socket.emit('leave', { roomId: sessionId });
        socket.disconnect();
        setLocalSocket(null);
      };
    }

    return undefined;
  }, [sessionId, connected, dispatch, setLocalSocket, activeCursorFlag]);

  const send = useCallback(
    (event: string, data: Record<string, any>): void => {
      if (!localSocket) return;

      localSocket.emit(event, data);
    },
    [localSocket]
  );

  const context = useMemo(() => ({ send }), [send]);

  return (
    <SocketContext.Provider value={context}>{children}</SocketContext.Provider>
  );
};

export default SocketContextProvider;
