import { toColor } from '../../../../common/utils/color.utils';
import * as rectangleUtil from '../../../../common/utils/shapes/rectangle.util';

const rectangleDrawer = (ctx, item) => {
  const {
    startPoint,
    endPoint,
    paint,
  } = item;
  const {
    color,
    fill,
    strokeWidth,
  } = paint;
  const rgbaColor = toColor(color);
  const width = rectangleUtil.getWidth(startPoint, endPoint);
  const height = rectangleUtil.getHeight(startPoint, endPoint);
  const point = rectangleUtil.getStartingPoint(startPoint, endPoint);
  ctx.save();

  if (fill) {
    ctx.fillStyle = rgbaColor;
    ctx.fillRect(point.x, point.y, width, height);
  } else {
    ctx.strokeStyle = rgbaColor;
    ctx.lineWidth = strokeWidth;
    ctx.strokeRect(point.x, point.y, width, height);
  }
  ctx.restore();
};

export default rectangleDrawer;
