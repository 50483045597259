import { FC, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AlertColor } from '../Notification/notificationTypes';
import { NotificationButtonSize } from '../../atoms/NotificationButton/notificationButtonTypes';
import Notification from '../Notification';
import NotificationButton from '../../atoms/NotificationButton/NotificationButton';
import InformationIcon from '../../atoms/Icons/InformationIcon';
import PlanDialog from '../PlanDialog';
import useDialog from '../../../common/hooks/useDialog';
import { FactoryFunction } from './tryPremiumNotificationProps';

type Props = {
  title: string;
  source: string;
};

const actionButtonFactory: FactoryFunction =
  (onPlansDialogOpen) =>
  ({ color, children }) => {
    const theme = useTheme();
    const showXsButton = useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <NotificationButton
        size={
          showXsButton ? NotificationButtonSize.XS : NotificationButtonSize.XXS
        }
        hasIcon={false}
        color={color}
        sx={{
          minWidth: 0,
        }}
        onClick={onPlansDialogOpen}
      >
        {children}
      </NotificationButton>
    );
  };

const TryPremiumNotification: FC<React.PropsWithChildren<Props>> = ({
  title,
  source,
}) => {
  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const ActionButton = useMemo(
    () => actionButtonFactory(onPlansDialogOpen),
    [onPlansDialogOpen]
  );

  return (
    <>
      <Notification
        Icon={InformationIcon}
        ActionButton={ActionButton}
        title={title}
        message="Upgrade your plan to use Premium features."
        color={AlertColor.ERROR}
        actionText="Upgrade"
      />
      <PlanDialog
        source={source}
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </>
  );
};

export default TryPremiumNotification;
