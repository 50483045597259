import { cloneDeep } from 'lodash';

export default class DrawingUtil {
  actions;
  startDrawIndex;
  endDrawIndex;
  notDrawingActions;

  constructor(startDrawIndex, endDrawIndex, oldActions, notDrawingActions = []) {
    this.actions = [...oldActions];
    this.startDrawIndex = startDrawIndex;
    this.endDrawIndex = endDrawIndex;
    this.notDrawingActions = new Set([...notDrawingActions]);
  }

  processActions(newActions) {
    newActions.forEach((action) => {
      return this.addShape(cloneDeep(action));
    });

    return {
      startDrawIndex: this.startDrawIndex,
      endDrawIndex: this.endDrawIndex,
      actions: this.actions,
      notDrawingActions: this.notDrawingActions,
    };
  }

  addShape(shape) {
    if (shape.name === 'activePage' || shape.name === 'not_supported') return;

    if (shape && shape.targetId) {
      this.notDrawingActions.add(shape.targetId);
    }

    if (['clear', 'clear_v2'].includes(shape.name)) {
      this.addClearAction(shape);
      return;
    }

    if (shape.name === 'undo') {
      this.addUndoAction();
      return;
    }

    if (shape.name === 'redo') {
      this.addRedoAction();
      return;
    }

    if (this.endDrawIndex !== this.actions.length) {
      this.actions.splice(this.endDrawIndex, this.actions.length - this.endDrawIndex);
    }

    this.actions.push(shape);
    this.endDrawIndex += 1;
  }

  addClearAction(shape) {
    const previous = this.actions[this.endDrawIndex - 1];

    if (previous && ['clear', 'clear_v2'].includes(previous.name)) return;
    if (this.startDrawIndex === this.endDrawIndex) return;

    if (this.endDrawIndex !== this.actions.length) {
      this.actions.splice(this.endDrawIndex, this.actions.length - this.endDrawIndex);
    }
    this.actions.push(shape);
    this.startDrawIndex = this.actions.length;
    this.endDrawIndex = this.actions.length;
  }

  addUndoAction() {
    const previous = this.actions[this.endDrawIndex - 1];

    if (previous && previous.targetId) {
      this.notDrawingActions.delete(previous.targetId);
    }

    if (previous && previous.name === 'clear_v2') return;

    if (previous && previous.name === 'clear' && this.startDrawIndex === this.endDrawIndex) {
      this.startDrawIndex = this.find(this.startDrawIndex - 2);
    }

    if (this.startDrawIndex === this.endDrawIndex) return;

    this.endDrawIndex -= 1;
  }

  find(index) {
    for (let i = index; i >= 0; i -= 1) {
      const curShape = this.actions[i];

      if (curShape && ['clear', 'clear_v2'].includes(curShape.name)) return i + 1;
    }
    return 0;
  }

  addRedoAction() {
    const next = this.actions[this.endDrawIndex];

    if (!next) return;

    if (next.targetId) {
      this.notDrawingActions.add(next.targetId);
    }

    if (['clear', 'clear_v2'].includes(next.name)) {
      this.startDrawIndex = this.endDrawIndex + 1;
    }

    this.endDrawIndex += 1;
  }
}
