import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '../../atoms/Button/Button';
import PlanToggle from '../PlanToggle';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  userSelector,
  userSubscriptionSelector,
} from '../../../common/reducers/userReducer';
import { liveBoardStorage, sessionStorage } from '../../../config/storage';
import useDialog from '../../../common/hooks/useDialog';
import PlanDto from '../../../common/services/dto/subscriptions/PlanDto';
import SubscriptionDto from '../../../common/services/dto/auth/SubscriptionDto';
import authService from '../../../common/services/auth.service';
import analyticsService from '../../../common/services/analytics.service';
import { getGeneralConfiguration } from '../../../common/actions/configurationActions';
import UserDto from '../../../common/services/dto/auth/UserDto';
import { DialogContentProps } from '../PlanDialog/PlanDialog';
import SubscriptionSourceType from '../../../common/services/types/auth/SubscriptionSourceType';
import CheckoutModal from '../../../containers/Modals/CheckoutModal';
import { getLocaleDate } from '../../../common/utils/getLocaleDate';
import MobilePlanChangeWarning from '../../../components/Plans/MobilePlanChangeWarning';
import VerifyEmailNotification from '../VerifyEmailNotification';
import Badge from '../../atoms/Badge';
import { BadgeSize, BadgeVariants } from '../../atoms/Badge/badgeTypes';

const FeaturesWrapper = styled(Box)({
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
});

const PlanToggleWrapper = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  paddingBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
    paddingBottom: '20px',
  },
  borderBottom: `1px solid ${theme.background.bg4}`,
}));

type OnePaidPlanProps = {
  onPlanSelectionSuccess: (updatedUser: UserDto) => void;
  dialogContent: DialogContentProps;
  source: string;
};

type Features = {
  icon: string;
  text: string;
  showNew: boolean;
  showComingSoon: boolean;
};

const OnePaidPlan: FC<React.PropsWithChildren<OnePaidPlanProps>> = ({
  onPlanSelectionSuccess,
  dialogContent,
  source,
}) => {
  const user = useSelector(userSelector);
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isYearlySelected, setIsYearlySelected] = useState(
    sessionStorage.getItem('pp') !== '1'
  );
  const [startTrial, setStartTrial] = useState(
    sessionStorage.getItem('st') !== '0'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    open: verificationDialogOpen,
    onClose: onVerificationDialogClose,
    onOpen: onVerificationDialogOpen,
  } = useDialog();

  const {
    open: checkoutDialogOpen,
    onClose: onCheckoutDialogClose,
    onOpen: onCheckoutDialogOpen,
  } = useDialog();

  const {
    open: mobileWarningDialogOpen,
    onClose: onMobileWarningDialogClose,
    onOpen: onMobileWarningDialogOpen,
  } = useDialog();

  const onChangePlanPeriod = useCallback(
    (event: any, value: any) => {
      if (!value) return;

      setIsYearlySelected(value === 'yearly');
    },
    [setIsYearlySelected]
  );

  const planToShow = useMemo(
    () =>
      isYearlySelected
        ? dialogContent?.plan.yearly
        : dialogContent?.plan.monthly,
    [isYearlySelected, dialogContent]
  );

  const planPrice = useMemo(
    () =>
      (
        Math.trunc((planToShow.price / planToShow.billingCycle) * 100) / 100
      ).toFixed(2),
    [planToShow]
  );

  const billingPeriod = useMemo(
    () =>
      isYearlySelected ? '/month, billed yearly' : '/month, billed monthly',
    [isYearlySelected]
  );

  const currentSubscription = useSelector<any, SubscriptionDto>(
    userSubscriptionSelector
  );

  const checkCanStartTrial = useCallback(
    (plan: PlanDto) => {
      if (plan.canStartTrial) {
        return (
          currentSubscription.plan.isFree &&
          user.canActivateTrial &&
          plan.canStartTrial &&
          startTrial
        );
      }

      return (
        currentSubscription.plan.isFree && user.canActivateTrial && startTrial
      );
    },
    [currentSubscription, user, startTrial]
  );

  useEffect(() => {
    const query = new URLSearchParams(search);

    const pp = query.get('pp');

    if (pp) {
      sessionStorage.setItem('pp', pp);
      setIsYearlySelected(pp !== '1');
    }

    const st = query.get('st');

    if (st) {
      sessionStorage.setItem('st', st);
      setStartTrial(st !== '0');
    }
  }, [search, setIsYearlySelected, setStartTrial]);

  const title = useMemo(() => {
    if (checkCanStartTrial(planToShow)) {
      return dialogContent.dialog.trial.title;
    }

    return dialogContent.dialog.purchase.title;
  }, [checkCanStartTrial, currentSubscription, planToShow]);

  const description = useMemo(() => {
    if (checkCanStartTrial(planToShow)) {
      return dialogContent.dialog.trial.message;
    }

    return dialogContent.dialog.purchase.message;
  }, [checkCanStartTrial, currentSubscription, planToShow]);

  const buttonText = useMemo(() => {
    if (checkCanStartTrial(planToShow)) {
      return dialogContent.dialog.trial.successBtn;
    }

    return dialogContent.dialog.purchase.successBtn;
  }, [checkCanStartTrial, currentSubscription, planToShow]);
  const checkoutLink = planToShow?.checkoutLink;

  const onStartTrialClick = useCallback(
    async (planId: any) => {
      try {
        setIsSubmitting(true);
        analyticsService.event('Trial Activation Button Click', {
          planId,
          source,
        });

        if (checkoutLink) {
          liveBoardStorage.setItem('source', source);
          window.open(checkoutLink, '_self');
          return;
        }

        const updatedUser = await authService.startTrial(planId);

        onPlanSelectionSuccess(updatedUser);
        enqueueSnackbar('Congratulations! Your 14-day trial started', {
          variant: 'success',
        });
        dispatch(getGeneralConfiguration());
      } catch (error) {
        if (error.code === '102001') {
          onVerificationDialogOpen();
        } else {
          enqueueSnackbar('Starting trial has failed', {
            variant: 'error',
          });
        }

        setIsSubmitting(false);
      }
    },
    [
      dispatch,
      setIsSubmitting,
      onPlanSelectionSuccess,
      enqueueSnackbar,
      source,
      onVerificationDialogOpen,
      checkoutLink,
    ]
  );

  const onPaymentSuccessFactory = useCallback(
    (planId: string) => async (promoCodeId?: string) => {
      try {
        const updatedUser = await authService.startSubscription(
          planId,
          promoCodeId
        );

        onPlanSelectionSuccess(updatedUser);

        let planName = updatedUser.subscription.plan.name;
        let message = `Your transaction has been successfully processed and ${planName} plan benefits are already reflected in your account`;

        if (
          currentSubscription.upcomingPlanChange &&
          !updatedUser.subscription.upcomingPlanChange &&
          currentSubscription.plan.id === updatedUser.subscription.plan.id
        ) {
          message = 'Your current plan has been reactivated';
        }

        if (updatedUser.subscription.upcomingPlanChange) {
          planName = updatedUser.subscription.upcomingPlanChange.plan.name;
          const changeDate = getLocaleDate(
            updatedUser.subscription.upcomingPlanChange.changeDate
          );

          message = `Your transaction has been successfully scheduled and ${planName} plan benefits will be reflected in your account on ${changeDate}`;
        }

        enqueueSnackbar(message, {
          variant: 'success',
        });
        dispatch(getGeneralConfiguration());
      } catch (e) {
        if (e.code === '302004') {
          enqueueSnackbar(
            'Payment failed due to a card issue or insufficient funds',
            {
              variant: 'error',
            }
          );

          throw e;
        }

        enqueueSnackbar('Plan purchase has failed', {
          variant: 'error',
        });
      }
    },
    [dispatch, onPlanSelectionSuccess, enqueueSnackbar]
  );

  const onPurchaseClick = useCallback(
    async (plan: PlanDto) => {
      analyticsService.event('Purchase Button Click', {
        planId: plan.id,
        source,
      });

      if (
        !currentSubscription.plan.isFree &&
        user.subscriptionSource !== SubscriptionSourceType.STRIPE
      ) {
        onMobileWarningDialogOpen();

        return;
      }

      setIsSubmitting(true);

      if (currentSubscription.isTrial) {
        const onPaymentSuccess = onPaymentSuccessFactory(plan.id);
        try {
          await onPaymentSuccess();
        } finally {
          setIsSubmitting(false);
        }
        return;
      }

      try {
        const card = await authService.getDefaultPaymentMethod();

        if (card) {
          if (checkoutLink) {
            liveBoardStorage.setItem('source', source);
            window.open(checkoutLink, '_self');

            return;
          }

          const onPaymentSuccess = onPaymentSuccessFactory(plan.id);
          await onPaymentSuccess();
        } else {
          if (checkoutLink) {
            liveBoardStorage.setItem('source', source);
            window.open(checkoutLink, '_self');

            return;
          }

          onCheckoutDialogOpen();
        }
      } catch {
        if (checkoutLink) {
          liveBoardStorage.setItem('source', source);
          window.open(checkoutLink, '_self');

          return;
        }

        onCheckoutDialogOpen();
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      currentSubscription,
      setIsSubmitting,
      onPaymentSuccessFactory,
      onCheckoutDialogOpen,
      onMobileWarningDialogOpen,
      user,
      source,
      checkoutLink,
    ]
  );

  const onButtonClick = useCallback(() => {
    if (checkCanStartTrial(planToShow)) {
      onStartTrialClick(planToShow.id);
      return;
    }

    onPurchaseClick(planToShow);
  }, [checkCanStartTrial, onPurchaseClick, onStartTrialClick, planToShow]);

  const discountPercent = useMemo(() => {
    const monthlyPrice = dialogContent.plan.monthly.price;
    const yearlyPrice = dialogContent.plan.yearly.price;
    const yearlyPriceMonthlyPeriod = yearlyPrice / 12;

    return `${Math.round(
      (1 - yearlyPriceMonthlyPeriod / monthlyPrice) * 100
    )}%`;
  }, [dialogContent.plan.monthly.price, dialogContent.plan.yearly.price]);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Typography variant={upSm ? 'h6' : 's1'} align={upSm ? 'left' : 'center'}>
        {title}
      </Typography>
      <Typography
        variant={upSm ? 'p4' : 'p5'}
        mt={1.75}
        mr={1.25}
        align={upSm ? 'left' : 'center'}
      >
        {description}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '20px',
          padding: '24px 24px 32px 24px',
          marginTop: 3,
          height: '100%',
        }}
      >
        <PlanToggleWrapper>
          <PlanToggle
            isYearlySelected={isYearlySelected}
            onChange={onChangePlanPeriod}
            discountText={discountPercent}
          />
        </PlanToggleWrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={upSm ? 3 : 2.5}
        >
          <Typography variant={upSm ? 'h6' : 's1'} color="primary">
            {planToShow.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Typography variant={upSm ? 'h6' : 's1'}>$</Typography>
              <Typography variant={upSm ? 'h6' : 's1'}>{planPrice}</Typography>
            </Box>
            <Typography
              variant={TypographyType.p5}
              sx={(t) => ({
                color: t.text.t7,
              })}
            >
              {billingPeriod}
            </Typography>
          </Box>
        </Box>
        <Button
          variant={ButtonVariantTypes.PRIMARY}
          size={upSm ? ButtonSizeTypes.M : ButtonSizeTypes.S}
          fullWidth
          onClick={onButtonClick}
          loading={isSubmitting}
        >
          {buttonText}
        </Button>
        <FeaturesWrapper>
          {planToShow.features.map(
            ({ icon, text, showNew, showComingSoon }: Features) => (
              <Box key={text} display="flex" gap={1}>
                <img
                  alt="Icon"
                  src={`data:image/svg+xml;base64, ${icon}`}
                  width={16}
                  height={16}
                />
                <Typography
                  variant="p5"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
                {showNew && (
                  <Badge
                    variant={BadgeVariants.OUTLINED}
                    invisible={false}
                    size={BadgeSize.M}
                    styles={{
                      right: 0,
                      top: 0,
                      marginLeft: '16px',
                      position: 'unset',
                      borderRadius: '0 8px 8px 8px',
                    }}
                    badgeContent="New"
                  />
                )}
                {showComingSoon && (
                  <Badge
                    invisible={false}
                    size={BadgeSize.M}
                    styles={{
                      right: 0,
                      top: 0,
                      marginLeft: '16px',
                      position: 'unset',
                      borderRadius: '0 8px 8px 8px',
                      backgroundColor: 'primary.alpha50',
                      borderColor: 'primary.alpha50',
                    }}
                    badgeContent="Soon"
                  />
                )}
              </Box>
            )
          )}
        </FeaturesWrapper>
      </Box>
      <VerifyEmailNotification
        dialog
        open={verificationDialogOpen}
        onClose={onVerificationDialogClose}
      />
      <CheckoutModal
        open={checkoutDialogOpen}
        planId={planToShow.id}
        price={planToShow.price}
        onClose={onCheckoutDialogClose}
        onSuccess={onPaymentSuccessFactory(planToShow.id)}
      />
      <MobilePlanChangeWarning
        open={mobileWarningDialogOpen}
        onClose={onMobileWarningDialogClose}
      />
    </Box>
  );
};

export default OnePaidPlan;
