import React, { forwardRef, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Header from '../../ui/molecules/Header';
import { boardsCountByGroupIdSelector } from '../../common/reducers/sessionsReducer';
import { StripeBusinessAccountStatus } from '../../common/services/dto/collectPayments/PaymentStatusDto';

type LayoutProps = {};

export const Layout = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<LayoutProps>
>(({ children }, ref) => {
  const params = useParams<{ groupId: string }>();
  const { groupId = 'default' } = params;
  const createdBoardsCountSelector = useMemo(
    () => boardsCountByGroupIdSelector(groupId),
    [groupId]
  );
  const createdBoardCount = useSelector(createdBoardsCountSelector);
  const location = useLocation<{
    collectPaymentsOnboardingStatus: StripeBusinessAccountStatus;
    paymentStatus?: string;
  }>();

  const collectPaymentsOnboardingStatus = useMemo(() => {
    if (!location?.state) return null;

    return location.state.collectPaymentsOnboardingStatus;
  }, [location.state]);

  return (
    <Box
      ref={ref}
      width="100%"
      sx={(t) => ({
        backgroundColor: t.background.bg2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        height: 'auto',
        overflow: 'auto',
      })}
      className="dashboard"
    >
      <Header
        isBoardCreated={!!createdBoardCount}
        collectPaymentsOnboardingStatus={collectPaymentsOnboardingStatus}
      />
      {children}
    </Box>
  );
});
