import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Account } from '../features/account/Account';
import UserPlanBilling from '../../../components/Profile/UserPlanBilling';
import Layout from './Layout';

export const Profile = () => (
  <Layout>
    <Switch>
      <Route exact path="/profile/account" component={Account} />
      <Route exact path="/profile/plan-billing" component={UserPlanBilling} />
      <Route
        path="/profile/*"
        render={({ location }) => {
          const query = new URLSearchParams(location.search);
          const promoCode = query.get('stripePromoCode');

          if (promoCode) {
            return <UserPlanBilling />;
          }

          return <Redirect to="/profile/account" />;
        }}
      />
      <Route exact path="/profile/members" component={UserPlanBilling} />
    </Switch>
  </Layout>
);
