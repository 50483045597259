import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { differenceBy } from 'lodash';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '../../atoms/Button';
import DialogContent from '../../atoms/DialogContent';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import ContentCopyIcon from '../../atoms/Icons/ContentCopyIcon';
// @ts-ignore
import * as planPermissionSelectors from '../../../common/selectors/planPermissionSelectors';
import * as sessionReducer from '../../../common/reducers/session';
import * as sessionActions from '../../../common/actions/session/sessionActions';
import sessionService from '../../../common/services/session.service';
import ContactsSelect from '../../molecules/ContactsSelect';
import PublicLink from './PublicLink';
import UserListData from '../../../common/types/userListData';
import analyticsService from '../../../common/services/analytics.service';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import {
  invitedParticipantsSelector,
  participantsWithoutHostSelector,
} from '../../../common/reducers/session/participantsReducer';
import * as contactsActions from '../../../common/actions/contactsActions';
import { updateInvitedContacts } from '../../../common/actions/session/invitesActions';
import {
  invitedContactIdsSelector,
  invitedEmailsSelector,
} from '../../../common/reducers/session/invitesReducer';
import UpgradeDialog from '../UpgradeDialog';
import useDialog from '../../../common/hooks/useDialog';
import PlanDialog from '../PlanDialog';
import { getFilteredInvitedParticipants } from '../../../common/utils/participants.utils';

type Props = {
  open: boolean;
  onCancel: () => void;
};

const InvitePeopleDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onCancel,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const session = useSelector(sessionReducer.createSessionSelector());
  const showGuestShare = useSelector(
    planPermissionSelectors.hasGuestSharePermissionSelector
  );
  const maxParticipantsCount = useSelector(
    planPermissionSelectors.maxSessionParticipantsSelector
  );
  const invitedEmails = useSelector(invitedEmailsSelector);
  const invitedContactsIds = useSelector(invitedContactIdsSelector);
  const invitedParticipants = useSelector(invitedParticipantsSelector);
  const joinedParticipants = useSelector(participantsWithoutHostSelector);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    open: upgradeDialogOpen,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
  }, [onPlansDialogOpen, onUpgradeDialogClose]);

  const updateSession = useCallback(
    (updatedSession: any) =>
      dispatch(sessionActions.updateSession(updatedSession)),
    [dispatch]
  );

  const canInvitePeople = useMemo(() => {
    const joinedParticipantsCount = joinedParticipants.length;
    const invitedEmailsCount = invitedEmails.length;
    const invitedContactsCount = invitedContactsIds.length;

    return (
      maxParticipantsCount -
        (joinedParticipantsCount + invitedEmailsCount + invitedContactsCount) >
      0
    );
  }, [
    joinedParticipants,
    invitedEmails,
    invitedContactsIds,
    maxParticipantsCount,
  ]);

  useEffect(() => {
    if (!canInvitePeople && !errorMessage) {
      setErrorMessage('Limited plan has 1 student limit');
    }
  }, [canInvitePeople, errorMessage, setErrorMessage]);

  const onAddClick = useCallback(
    async (members: any) => {
      if (!canInvitePeople) {
        onUpgradeDialogOpen();
        onCancel();

        return;
      }
      setIsLoading(true);
      setErrorMessage('');

      const oldContacts = session.contacts || [];
      const emailsToAdd = differenceBy<UserListData, UserListData>(
        members.filter((contact: UserListData) => contact.email),
        oldContacts,
        'email'
      ).map((contact) => contact.email);
      const idsToAdd = differenceBy<UserListData, UserListData>(
        members.filter((contact: UserListData) => contact.id),
        oldContacts,
        'id'
      ).map((contact) => contact.id);

      try {
        analyticsService.event('Board Invite Dialog User Invite', {
          contacts: idsToAdd.length,
          emails: emailsToAdd.length,
        });

        const { invites } = await sessionService.inviteToSession(
          session.id,
          idsToAdd,
          emailsToAdd
        );
        const { contacts, emails } = getFilteredInvitedParticipants(
          joinedParticipants,
          invites?.emails || [],
          invites?.contacts || []
        );
        dispatch(
          updateInvitedContacts({
            contacts,
            emails,
          })
        );
        dispatch(contactsActions.invalidateIfNeeded(contacts));
        setIsLoading(false);
      } catch (error) {}
    },
    [
      updateSession,
      canInvitePeople,
      onUpgradeDialogOpen,
      onCancel,
      joinedParticipants,
      setIsLoading,
      dispatch,
    ]
  );

  const onRemoveClick = useCallback(
    async (member: any) => {
      const oldContactIds = [...invitedContactsIds];
      const oldEmails = [...invitedEmails];
      analyticsService.event('Board Invite Dialog User Remove');

      try {
        const filteredContactIds = invitedContactsIds.filter(
          (id: string) => id !== member.id
        );
        const filteredEmails = invitedEmails.filter(
          (id: string) => id !== member.email
        );

        dispatch(
          updateInvitedContacts({
            contacts: filteredContactIds,
            emails: filteredEmails,
          })
        );

        if (member.id) {
          await sessionService.removeInvitesToSession(
            session.id,
            [member.id],
            []
          );

          return;
        }

        await sessionService.removeInvitesToSession(
          session.id,
          [],
          [member.email]
        );
      } catch (error) {
        dispatch(
          updateInvitedContacts({
            contacts: oldContactIds,
            emails: oldEmails,
          })
        );
      }
    },
    [invitedEmails, invitedContactsIds, invitedEmails, session.id]
  );

  const onCopyCodeClick = () => {
    analyticsService.event('Board Invite Code Copy Click');
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      autoHideDuration: 1500,
    });
  };

  const onClose = () => {
    analyticsService.event('Board Invite Dialog Cancel Click');
    onCancel();
  };

  const onEntering = useCallback(() => {
    if (!canInvitePeople) {
      setErrorMessage('Limited plan has 1 student limit');
    }
  }, [canInvitePeople, setErrorMessage]);

  const { code } = session;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            overflow: 'hidden',
          },
        }}
        classes={{ paper: 'product-tour-step-4' }}
        TransitionProps={{
          onEntering,
        }}
        keepMounted
      >
        <DialogTitle onClose={onClose}>Invite students via...</DialogTitle>
        <DialogContent
          sx={{
            margin: '36px 0 0',
            overflowY: 'hidden',
          }}
        >
          {showGuestShare && session.publicLink && (
            <Box mb={4.5}>
              <PublicLink url={session.publicLink.url} />
            </Box>
          )}
          {code && (
            <Box mb={4.5}>
              <Box display="flex" alignItems="center" gap="10px" mb={0.5}>
                <Typography component="span" variant="p5">
                  Session code:
                </Typography>
                <CopyToClipboard text={code} onCopy={onCopyCodeClick}>
                  <Button
                    variant={ButtonVariantTypes.TEXT}
                    size={ButtonSizeTypes.S}
                    endIcon={<ContentCopyIcon />}
                    color="primary"
                    sx={(theme) => ({
                      color: `${theme.palette.primary.main} !important`,
                    })}
                  >
                    {code}
                  </Button>
                </CopyToClipboard>
              </Box>
              <Box>
                <Typography
                  variant="p6"
                  sx={(theme) => ({
                    color: theme.text.t7,
                  })}
                >
                  To join by entering the code
                </Typography>
              </Box>
            </Box>
          )}
          <Box my={4.5}>
            <Typography
              variant="p5"
              sx={(theme) => ({
                color: theme.text.t8,
                marginBottom: '4px',
              })}
            >
              Contacts
            </Typography>
            <ContactsSelect
              source="invite"
              isInviteDialog
              contacts={invitedParticipants}
              onAddMembers={onAddClick}
              onRemoveMember={onRemoveClick}
              membersListHeight={252}
              InviteButton={(props) => (
                <Button
                  {...props}
                  variant={ButtonVariantTypes.PRIMARY}
                  loading={isLoading}
                >
                  Invite
                </Button>
              )}
              errorMessage={errorMessage}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <UpgradeDialog
        open={upgradeDialogOpen}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source="Invite dialog"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </>
  );
};

export default InvitePeopleDialog;
