import React from 'react';
import * as rectangleUtil from '../../../../../common/utils/shapes/rectangle.util';
import BoundaryBox from '../boundaries/BoundaryBox';
import AspectRatioResizer from '../boundaries/resizers/AspectRatioResizer';
import TopResizer from '../boundaries/resizers/TopResizer';
import BottomResizer from '../boundaries/resizers/BottomResizer';
import LeftResizer from '../boundaries/resizers/LeftResizer';
import RightResizer from '../boundaries/resizers/RightResizer';
import EllipseDrawingAction from '../../../../../common/drawingActions/shapes/oval.drawing.action';
import ShapeDrawingLayer from './ShapeDrawingLayer';
import { withCanvasContext } from '../../../Contexts/CanvasContext';

class EllipseDrawingLayer extends ShapeDrawingLayer {
  createShape = (positions, props) => {
    const { color, size, fill } = props;
    const { startPoint, endPoint } = positions;
    const drawingAction = new EllipseDrawingAction();
    drawingAction.paint.color = color;
    drawingAction.paint.strokeWidth = size;
    drawingAction.paint.fill = fill;
    drawingAction.localStartTime = new Date();
    drawingAction.setStartPoint(startPoint);
    drawingAction.setEndPoint(endPoint);
    return drawingAction;
  };

  createBoundaries = (positions, props) => {
    const { size, scale, zoom } = props;
    const { startPoint, endPoint } = positions;

    const padding = 20 / scale + size / 2;
    const params = [
      rectangleUtil.getStartingPoint(startPoint, endPoint),
      rectangleUtil.getEndPoint(startPoint, endPoint),
      scale * zoom,
      padding,
    ];

    const shapes = [
      new BoundaryBox(...params),
      new TopResizer(...params),
      new RightResizer(...params),
      new BottomResizer(...params),
      new LeftResizer(...params),
      new AspectRatioResizer(...params),
    ];

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => ({
    startPoint: rectangleUtil.getStartingPoint(startPoint, endPoint),
    endPoint: rectangleUtil.getEndPoint(startPoint, endPoint),
  });
}

export default withCanvasContext(EllipseDrawingLayer);
