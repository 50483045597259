import { httpServiceV4 } from '../../../../common/services/http.service';
import { AssessmentDto } from '../../assessment-template/services/dto/AssessmentDto';
import { PageDto } from '../../../../common/dto/PageDto';
import { AssessmentPaperDto } from '../../assessment-paper/services/dto/AssessmentPaperDto';
import { AssessmentListItemDto } from '../../assessment-template/services/dto/AssessmentListItemDto';

const getBoardAssessmentList = (
  sessionId: string
): Promise<PageDto<AssessmentListItemDto>> =>
  httpServiceV4.get<
    PageDto<AssessmentListItemDto>,
    PageDto<AssessmentListItemDto>
  >(`/assessment?filters[sessionId]=${sessionId}`);

const createBoardAssessment = ({
  templateId,
  sessionId,
}: {
  templateId: string;
  sessionId: string;
}): Promise<AssessmentDto> =>
  httpServiceV4.post<AssessmentDto, AssessmentDto>('/assessment', {
    templateId,
    sessionId,
  });

const getAssessment = (id: string): Promise<AssessmentDto> =>
  httpServiceV4.get<AssessmentDto, AssessmentDto>(`/assessment/${id}`);

const updateBoardAssessmentStatus = ({
  id,
  status,
}: {
  id: string;
  status: 'created' | 'in-progress' | 'finished';
}): Promise<AssessmentDto> =>
  httpServiceV4.put<AssessmentDto, AssessmentDto>(`/assessment/${id}`, {
    status,
  });

const startBoardAssessment = (
  id: string,
  participants: any[]
): Promise<AssessmentDto> =>
  httpServiceV4.post<AssessmentDto, AssessmentDto>(`/assessment/${id}/start`, {
    participants,
  });

const getAssessmentPapers = (
  id: string
): Promise<PageDto<AssessmentPaperDto>> =>
  httpServiceV4.get<PageDto<AssessmentPaperDto>, PageDto<AssessmentPaperDto>>(
    `/assessment/${id}/paper`
  );

const finishBoardAssessment = (id: string): Promise<AssessmentDto> =>
  httpServiceV4.post<AssessmentDto, AssessmentDto>(`/assessment/${id}/end`);

export {
  getBoardAssessmentList,
  createBoardAssessment,
  getAssessment,
  updateBoardAssessmentStatus,
  startBoardAssessment,
  getAssessmentPapers,
  finishBoardAssessment,
};
