import { useFlag } from '@unleash/proxy-client-react';

const withFlag = (name: string) => (Component: any) => (props: any) => {
  const featureFlagEnabled = useFlag(name);

  return (
    <Component
      {...props}
      {...({ [`${name}Enabled`]: featureFlagEnabled })}
    />
  );
};

export default withFlag;
