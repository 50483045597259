import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Countdown from 'react-countdown';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
} from '../../atoms/DialogActions';
import { setVerificationTime } from '../../../common/actions/dashboardActions';
import getTimeLeft from '../../../common/utils/countDown.utils';

type Props = {
  open: boolean;
  onClose: () => void;
  handleClick: () => void;
  countdownTime: number;
};

const resendTimeInSeconds = 60;

const ResendEmailVerificationDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  handleClick,
  countdownTime,
}) => {
  const dispatch = useDispatch();

  const onCompleteTimer = useCallback(() => {
    dispatch(setVerificationTime(null));
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>Account is not verified</DialogTitle>
      <DialogContent>
        {countdownTime
          ? 'We have sent you an email. Please check your inbox'
          : 'Please, verify your account to activate the 14-day Trial!'}
      </DialogContent>
      <DialogActions>
        <DialogActionPrimaryButton
          onClick={handleClick}
          disabled={!!countdownTime}
        >
          {!countdownTime ? (
            'Resend verification email'
          ) : (
            <Countdown
              autoStart={!!countdownTime}
              date={resendTimeInSeconds * 1000 + countdownTime}
              renderer={getTimeLeft}
              onComplete={onCompleteTimer}
            />
          )}
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResendEmailVerificationDialog;
