import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ControlsProps as LivekitControlsProps } from '@livekit/react-components';
import { useParticipant } from '@livekit/react-core';
import Box from '@mui/material/Box';
import { AudioSelectButton } from './AudioSelectButton';
import { VideoSelectButton } from './VideoSelectButton';
import { ControlButton } from './ControlButton';
import CallEndIcon from '../../ui/atoms/Icons/CallEndIcon';
import AudioAndVideoSettingsDialog from '../AudioAndVideoSettingsDialog';
import MoreIcon from '../../ui/atoms/Icons/MoreIcon';
import useDialog from '../../common/hooks/useDialog';
import DeviceContext from '../BoardControls/CollaborationTools/DeviceContext/DeviceContext';
import MediaDeviceNotSupportedDialog from '../../ui/molecules/MediaDeviceNotSupportDialog';
import { ShareScreenButton } from './ShareScreenButton';
import { isCurrentUserHostSelector } from '../../common/reducers/session/sessionReducer';
import LiveKitContext from '../BoardControls/CollaborationTools/Manager/LiveKitManager/context/LiveKitContext';
import analyticsService from '../../common/services/analytics.service';

type ControlsProps = LivekitControlsProps & {
  showMoreButton?: boolean;
};

const ControlsRenderer: FC<React.PropsWithChildren<ControlsProps>> = ({
  room,
  enableAudio = true,
  enableVideo = true,
  showMoreButton = true,
  onLeave,
}) => {
  const [isScreenShareButtonDisabled, setIsScreenShareButtonDisabled] =
    useState(false);
  const isHost = useSelector(isCurrentUserHostSelector);
  const livekitContext = useContext(LiveKitContext);

  const {
    open: audioAndVideoSettingsOpen,
    onOpen: onAudioAndVideoSettingsOpen,
    onClose: onAudioAndVideoSettingsClose,
  } = useDialog();

  const {
    open: mediaDeviceNotSupportedDialogOpen,
    onOpen: onMediaDeviceNotSupportedDialogOpen,
    onClose: onMediaDeviceNotSupportedDialogClose,
  } = useDialog();

  const isMounted = useRef<boolean | null>(null);

  const {
    selectedAudioOption,
    selectedVideoOption,
    listAudioDevices,
    listVideoDevices,
  } = useContext(DeviceContext);

  const {
    cameraPublication: camPub,
    microphonePublication: micPub,
    screenSharePublication: screenPub,
  } = useParticipant(room.localParticipant);

  const [audioButtonDisabled, setAudioButtonDisabled] = useState(false);
  let muteButton: ReactElement | undefined;
  if (enableAudio) {
    const enabled = !(micPub?.isMuted ?? true);

    muteButton = (
      <AudioSelectButton
        className="cancel"
        isMuted={!enabled}
        isButtonDisabled={audioButtonDisabled}
        onClick={async () => {
          if (room.localParticipant.lastMicrophoneError) {
            navigator.permissions.query({ name: 'microphone' });
            onMediaDeviceNotSupportedDialogOpen();
            return;
          }
          setAudioButtonDisabled(true);
          room.localParticipant
            .setMicrophoneEnabled(!enabled)
            .finally(() => setAudioButtonDisabled(false));
        }}
      />
    );
  }
  const [videoButtonDisabled, setVideoButtonDisabled] = useState(false);

  let videoButton: ReactElement | undefined;
  if (enableVideo) {
    const enabled = !(camPub?.isMuted ?? true);

    videoButton = (
      <VideoSelectButton
        className="cancel"
        isEnabled={enabled}
        isButtonDisabled={videoButtonDisabled}
        onClick={() => {
          if (room.localParticipant.lastCameraError) {
            navigator.permissions.query({ name: 'camera' });
            onMediaDeviceNotSupportedDialogOpen();
            return;
          }
          if (videoButtonDisabled) return;

          setVideoButtonDisabled(true);
          room.localParticipant
            .setCameraEnabled(!enabled)
            .finally(() => setVideoButtonDisabled(false));
        }}
      />
    );
  }

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!selectedAudioOption) return;

    setAudioButtonDisabled(true);
    room.switchActiveDevice('audioinput', selectedAudioOption).finally(() => {
      if (!isMounted.current) return;

      setAudioButtonDisabled(false);
    });
  }, [room, setAudioButtonDisabled, selectedAudioOption]);

  useEffect(() => {
    if (!selectedVideoOption) return;

    setVideoButtonDisabled(true);
    room.switchActiveDevice('videoinput', selectedVideoOption).finally(() => {
      if (!isMounted.current) return;

      setVideoButtonDisabled(false);
    });
  }, [room, setVideoButtonDisabled, selectedVideoOption]);

  const onMoreClick = useCallback(async () => {
    await Promise.all([listAudioDevices(), listVideoDevices()]);
    onAudioAndVideoSettingsOpen();
  }, [listAudioDevices, listVideoDevices, onAudioAndVideoSettingsOpen]);

  const isScreenSharedEnabled = screenPub?.isEnabled;
  const onShareScreenClick = useCallback(() => {
    if (isScreenShareButtonDisabled) return;
    if (!isScreenSharedEnabled) {
      analyticsService.event('Screen Share Enable Click');
    }

    setIsScreenShareButtonDisabled(true);
    room.localParticipant
      .setScreenShareEnabled(!isScreenSharedEnabled)
      .finally(() => {
        setIsScreenShareButtonDisabled(false);
        livekitContext.onChangeScreenShared(!isScreenSharedEnabled);
      });
  }, [
    room.localParticipant,
    isScreenShareButtonDisabled,
    setIsScreenShareButtonDisabled,
    isScreenSharedEnabled,
    livekitContext.onChangeScreenShared,
  ]);

  return (
    <Box display="flex" gap={3}>
      {onLeave && (
        <ControlButton
          className="cancel"
          onClick={() => {
            room.disconnect();
            onLeave(room);
          }}
          icon={CallEndIcon}
          color="error"
        />
      )}
      {muteButton}
      {videoButton}
      {isHost && (
        <ShareScreenButton
          className="cancel"
          isEnabled={!!isScreenSharedEnabled}
          isButtonDisabled={isScreenShareButtonDisabled}
          onClick={onShareScreenClick}
        />
      )}
      {showMoreButton && (
        <ControlButton
          className="cancel"
          onClick={onMoreClick}
          icon={MoreIcon}
          active
        />
      )}
      <AudioAndVideoSettingsDialog
        open={audioAndVideoSettingsOpen}
        onClose={onAudioAndVideoSettingsClose}
      />
      <MediaDeviceNotSupportedDialog
        open={mediaDeviceNotSupportedDialogOpen}
        onClose={onMediaDeviceNotSupportedDialogClose}
      />
    </Box>
  );
};

export default ControlsRenderer;
