import Box from '@mui/material/Box';
import TutorialCard from './TutorialCard';
import CreateAndShareBoardSrc from '../../../assets/images/board_tutorial.png';
import CreateGroupSrc from '../../../assets/images/group_tutorial.png';
import LivekitSrc from '../../../assets/images/livekit_tutorial.png';
import DesmosSrc from '../../../assets/images/desmos_tutorial.png';

const tutorials = [
  {
    videoUrl: 'https://www.youtube.com/watch?v=Vzk0qL0TsAs',
    src: CreateAndShareBoardSrc,
  },
  {
    videoUrl: 'https://www.youtube.com/watch?v=sJRP8oKbKhc',
    src: CreateGroupSrc,
  },
  {
    videoUrl: 'https://www.youtube.com/watch?v=FEscx79QtjI',
    src: LivekitSrc,
  },
  {
    videoUrl: 'https://www.youtube.com/watch?v=zimkXMKX7iM',
    src: DesmosSrc,
  },
];

type TutorialType = {
  videoUrl: string;
  src: string;
};

const Tutorial = () => (
  <Box
    sx={(t) => ({
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      [t.breakpoints.up('sm')]: {
        gap: '16px',
      },
      [t.breakpoints.up('md')]: {
        gap: '28px',
      },
    })}
  >
    {tutorials.map((tutorial: TutorialType, index) => (
      <TutorialCard key={index} url={tutorial.videoUrl} src={tutorial.src} />
    ))}
  </Box>
);

export default Tutorial;
