import { httpService, httpServiceV4 } from './http.service';

const apiContactsPart = '/contacts';

const getContactList = () => httpServiceV4.get(apiContactsPart);

const getContact = id => httpService.get(`${apiContactsPart}/${id}`);

const createContact = email => httpService.post(apiContactsPart, { email });

const deleteContact = id => httpService.delete(`${apiContactsPart}/${id}`);

export default {
  getContactList,
  getContact,
  createContact,
  deleteContact,
};
