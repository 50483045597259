import { dialogClasses } from '@mui/material/Dialog';

const offset = (el) => {
  let elem = el;
  let x = 0;
  let y = 0;

  while (elem) {
    if (elem.tagName === 'BODY') {
      // deal with browser quirks with body/window/document and page scroll
      const xScroll = elem.scrollLeft || document.documentElement.scrollLeft;
      const yScroll = elem.scrollTop || document.documentElement.scrollTop;

      x += elem.offsetLeft - xScroll + elem.clientLeft;
      y += elem.offsetTop - yScroll + elem.clientTop;
    } else {
      // for all other non-BODY elements
      x += elem.offsetLeft - elem.scrollLeft + elem.clientLeft;
      y += elem.offsetTop - elem.scrollTop + elem.clientTop;
    }

    elem = elem.offsetParent;
  }
  return {
    x,
    y,
  };
};

const isLoadedInIframe = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();

const checkIsActiveElementDialog = () => {
  const activeElement = document.activeElement;

  return !!activeElement.closest(`.${dialogClasses.container}`);
};

export default {
  offset,
  isLoadedInIframe,
  checkIsActiveElementDialog,
};
