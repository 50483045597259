import React, { Suspense, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { useSelector } from 'react-redux';
import App from '../components/App';
import SignIn from '../ui/pages/auth/SignIn';
import SignUp from '../ui/pages/auth/SignUp';
import { Profile } from '../features/profile/routes';
import ForgotPassword from '../ui/pages/auth/ForgotPassword';
import ResetPassword from '../ui/pages/auth/ResetPassword';
import NotAuthenticatedRoute from '../components/routes/NotAuthenticatedRoute';
import TryAuthenticateRoute from '../components/routes/TryAuthenticateRoute';
import AuthenticatedRoute from '../components/routes/AuthenticatedRoute';
import GetDpiComponent from '../components/GetDpiComponent';
import InitialDataFetcher from '../components/InitialDataFetcher';
import InstallMobileAppBanner from '../components/InstallMobileAppBanner';
import InstallHubspotChat from '../components/InstallHubspotChat';
import AcceptTermsAndPrivacy from '../components/AcceptTermsAndPrivacy';
import domUtils from '../common/utils/dom.util';
import CursorSVG from '../components/CursorSVG';
import * as userReducer from '../common/reducers/userReducer';
import Dashboard from '../ui/pages/Dashboard/Dashboard';
import environment from './environment';
import SignUpPreview from '../ui/pages/auth/SignUpPreview/SignUpPreview';
import PublicLinkRegistration from '../components/PublicLinkRegistration';
import { CollectPaymentsOnboarding } from '../components/CollectPaymentsOnboarding/CollectPaymentsOnboarding';
import { StripeBusinessAccountStatus } from '../common/services/dto/collectPayments/PaymentStatusDto';
import PlanDialogProvider from '../components/PlanDialogContext/PlanDialogProvider';
import { lazyImport } from '../common/utils/lazy-import';
import Loader from '../components/Icons/Loader';

const { AssessmentTemplateRoutes } = lazyImport(
  () => import('../features/assessment/assessment-template/routes'),
  'AssessmentTemplateRoutes'
);

const { DashboardRoutes } = lazyImport(
  () => import('../features/dashboard/routes'),
  'DashboardRoutes'
);

const routes = () => {
  const user = useSelector(userReducer.userSelector);
  const updateContext = useUnleashContext();

  useEffect(() => {
    if (user) {
      updateContext({
        platform: 'web',
        userId: user.id,
      });
    } else {
      updateContext({
        platform: 'web',
      });
    }
  }, [user]);

  return (
    <main className="main lb-holygrail">
      {!domUtils.isLoadedInIframe && <InstallMobileAppBanner />}
      {environment.hubspot.enabled() && <InstallHubspotChat />}
      <GetDpiComponent />
      <CursorSVG />
      <Switch>
        <Route
          path="*"
          render={() => (
            <TryAuthenticateRoute>
              <Route
                exact
                path="/login"
                render={() => <Redirect to="/sign-in" />}
              />
              <NotAuthenticatedRoute exact path="/sign-in" component={SignIn} />
              <NotAuthenticatedRoute
                exact
                path="/sign-up"
                component={SignUpPreview}
              />
              <NotAuthenticatedRoute
                exact
                path="/sign-up/:userRole"
                component={SignUp}
              />
              <NotAuthenticatedRoute
                exact
                path="/password-forgot"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/password-reset/:resetToken"
                component={ResetPassword}
              />
              <AuthenticatedRoute
                path="/profile"
                render={() => (
                  <InitialDataFetcher>
                    <AcceptTermsAndPrivacy>
                      <Profile />
                    </AcceptTermsAndPrivacy>
                  </InitialDataFetcher>
                )}
              />
              {user?.canManageUsers && (
                <AuthenticatedRoute
                  path="/dashboard"
                  render={() => (
                    <InitialDataFetcher>
                      <AcceptTermsAndPrivacy>
                        <DashboardRoutes />
                      </AcceptTermsAndPrivacy>
                    </InitialDataFetcher>
                  )}
                />
              )}
              <AuthenticatedRoute
                exact
                path="/session/:sessionId"
                render={({ match }) => (
                  <InitialDataFetcher>
                    <AcceptTermsAndPrivacy>
                      <PlanDialogProvider>
                        <App sessionId={match.params.sessionId} />
                      </PlanDialogProvider>
                    </AcceptTermsAndPrivacy>
                  </InitialDataFetcher>
                )}
              />
              <AuthenticatedRoute
                exact
                path={[
                  '/group/:groupId/:tab(sessions|members|recordings)',
                  '/usage-statistics',
                ]}
                render={() => (
                  <InitialDataFetcher>
                    <AcceptTermsAndPrivacy>
                      <PlanDialogProvider>
                        <Dashboard />
                      </PlanDialogProvider>
                    </AcceptTermsAndPrivacy>
                  </InitialDataFetcher>
                )}
              />
              <AuthenticatedRoute
                exact
                path="/group/:groupId"
                render={({ match }) => (
                  <Redirect to={`/group/${match.params.groupId}/sessions`} />
                )}
              />
              <AuthenticatedRoute
                exact
                path="/"
                render={() => <Redirect to="/group/default/sessions" />}
              />
              <AuthenticatedRoute
                exact
                path="/collect-payments/onboarding/completed"
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/group/default/sessions',
                      state: {
                        collectPaymentsOnboardingStatus:
                          StripeBusinessAccountStatus.ONBOARDING_COMPLETED,
                      },
                    }}
                  />
                )}
              />
              <AuthenticatedRoute
                exact
                path="/payment/success"
                render={() => (
                  <Redirect
                    to={{
                      pathname: '/group/default/sessions',
                      state: {
                        paymentStatus: 'success',
                      },
                    }}
                  />
                )}
              />
              <Route
                exact
                path="/public/:code"
                render={({ match }) => (
                  <PublicLinkRegistration code={match.params.code} />
                )}
              />
              <AuthenticatedRoute
                exact
                path="/collect-payments/onboarding/refresh"
                component={CollectPaymentsOnboarding}
              />
              <AuthenticatedRoute
                path="/assessments"
                render={() => (
                  <InitialDataFetcher>
                    <AcceptTermsAndPrivacy>
                      <PlanDialogProvider>
                        <Suspense fallback={<Loader />}>
                          <AssessmentTemplateRoutes />
                        </Suspense>
                      </PlanDialogProvider>
                    </AcceptTermsAndPrivacy>
                  </InitialDataFetcher>
                )}
              />
              <Redirect from="*" to="/" />
            </TryAuthenticateRoute>
          )}
        />
      </Switch>
    </main>
  );
};

export default routes;
