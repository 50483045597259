import { createSelector } from 'reselect';

import { ADD_MESSAGE, ADD_MESSAGES } from '../../actions/session/messageActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';
import { SET_MESSAGES_SIDE_BAR_VISIBILITY } from '../../actions/uiActions';

export default (state = [], action) => {
  switch (action.type) {
    case ADD_MESSAGES:
      return [
        ...state,
        ...action.messages,
      ];
    case ADD_MESSAGE:
      return [
        ...state,
        action.message,
      ];
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return [];
    case SET_MESSAGES_SIDE_BAR_VISIBILITY: {
      const { opened } = action;
      if (!opened) return state;

      return state.map(message => ({
        ...message,
        isRead: true,
      }));
    }
    default:
      return state;
  }
};

const messagesSelector = (state) => {
  const { messages } = state.session;
  const participants = state.session.participants
    .reduce((acc, participant) => ({
      ...acc, [participant.id]: participant.profileImageUrl,
    }), []);

  return messages.map(message => ({
    ...message,
    profileImageUrl: participants[message.senderId],
  }));
};

const unreadMessageCount = createSelector(
  messagesSelector,
  messages => messages.reduce((acc, cur) => acc + (!cur.isRead), 0),
);

export { messagesSelector, unreadMessageCount };
