import { useCallback, useEffect, useState } from 'react';
import { Participant } from 'livekit-client';
import Box from '@mui/material/Box';
import { StageProps } from './StageView';

const getGridCount = (participants: Participant[]) => {
  const root = Math.ceil(Math.sqrt(participants.length));

  return root * Math.ceil(participants.length / root);
};

export const GridStage = ({
  roomState,
  participantRenderer: ParticipantRenderer,
  controlRenderer: ControlRenderer,
  onLeave,
}: StageProps) => {
  const { isConnecting, error, participants, room } = roomState;
  const [visibleParticipants, setVisibleParticipants] = useState<Participant[]>(
    []
  );
  const [gridStyles, setGridStyles] = useState({
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
  });

  const generateGridStyles = useCallback(
    (participants: Participant[]) => {
      if (participants.length === 1) {
        setGridStyles({
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto',
        });
      } else if (participants.length === 2) {
        setGridStyles({
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'auto',
        });
      } else {
        const cols = Math.ceil(Math.sqrt(participants.length));
        const rows = Math.ceil(participants.length / cols);
        setGridStyles({
          gridTemplateColumns: `repeat(${cols}, 1fr)`,
          gridTemplateRows: `repeat(${rows}, 1fr)`,
        });
      }
    },
    [setGridStyles]
  );

  const getNewParticipants = useCallback(
    (visibleParticipants: any) =>
      visibleParticipants.filter(
        (participant: Participant) =>
          room?.participants.has(participant.sid) ||
          room?.localParticipant.sid === participant.sid
      ),
    [room]
  );

  useEffect(() => {
    const numVisible = getGridCount(participants);
    generateGridStyles(participants);

    const newParticipants = getNewParticipants(visibleParticipants);

    room?.activeSpeakers?.forEach((speaker) => {
      if (
        newParticipants.includes(speaker) ||
        (speaker !== room?.localParticipant &&
          !room?.participants.has(speaker.sid))
      ) {
        return;
      }
      const idx = newParticipants.findIndex((p: Participant) => !p.isSpeaking);
      if (idx >= 0) {
        newParticipants[idx] = speaker;
      } else {
        newParticipants.push(speaker);
      }
    });

    for (const p of participants) {
      if (newParticipants.length >= numVisible) {
        break;
      }
      if (newParticipants.includes(p) || p.isSpeaking) {
        continue;
      }
      newParticipants.push(p);
    }

    if (newParticipants.length > numVisible) {
      newParticipants.splice(numVisible, newParticipants.length - numVisible);
    }
    setVisibleParticipants(newParticipants);
  }, [
    participants,
    setVisibleParticipants,
    generateGridStyles,
    getNewParticipants,
  ]);

  if (error) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Error {error.message}
      </Box>
    );
  }

  if (isConnecting) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Connecting
      </Box>
    );
  }
  if (!room) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Room closed
      </Box>
    );
  }

  if (participants.length === 0) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        No one is in the room
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto min-content',
        minHeight: 0,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: '8px',
          overflow: 'hidden',
          height: '100%',
          alignItems: 'center',
          justifyItems: 'center',
          ...gridStyles,
        }}
      >
        {visibleParticipants.map((participant) => {
          return (
            <ParticipantRenderer
              key={participant.identity}
              participant={participant}
              orientation="landscape"
              width="100%"
              height="100%"
              showOverlay
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
          padding: '12px',
        }}
      >
        <ControlRenderer room={room} onLeave={onLeave} />
      </Box>
    </Box>
  );
};
