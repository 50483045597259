import { ControlButton } from './ControlButton';
import MicrophoneOffOutlineIcon from '../../ui/atoms/Icons/MicrophoneOffOutlinecon';
import MicrophoneIcon from '../../ui/atoms/Icons/MicrophoneIcon';

export interface AudioSelectButtonProps {
  isMuted: boolean;
  onClick: () => void;
  className?: string;
  isButtonDisabled?: boolean;
  popoverContainerClassName?: string;
  popoverTriggerBtnClassName?: string;
  popoverTriggerBtnSeparatorClassName?: string;
  minimized?: boolean;
}

export const AudioSelectButton = ({
  isMuted,
  onClick,
  className,
  isButtonDisabled,
  popoverContainerClassName,
  popoverTriggerBtnClassName,
  popoverTriggerBtnSeparatorClassName,
  minimized = false,
}: AudioSelectButtonProps) => (
  <ControlButton
    icon={isMuted ? MicrophoneOffOutlineIcon : MicrophoneIcon}
    active={!isMuted}
    disabled={isButtonDisabled}
    onClick={onClick}
    className={className}
    popoverContainerClassName={popoverContainerClassName}
    popoverTriggerBtnClassName={popoverTriggerBtnClassName}
    popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
    minimized={minimized}
  />
);
