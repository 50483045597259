import domUtils from '../common/utils/dom.util';

type Environment = {
  isProduction: boolean;
  appUrl: string;
  googleClientId: string;
  apiUrl?: string;
  appVersion: string;
  hubspot: {
    id?: string;
    enabled: () => boolean;
  };
  firebase: {
    apiKey?: string;
    authDomain?: string;
    projectId?: string;
    storageBucket?: string;
    messagingSenderId?: string;
  };
  stripePublishKey: string;
  unleash: {
    url?: string;
    clientKey?: string;
    appName?: string;
    environment?: string;
  };
  googleAnalytics4: {
    id?: string;
    enabled: () => boolean;
  };
  socketServer: {
    url: string;
  };
  livekit: {
    url?: string;
  };
  mixpanel: {
    token?: string;
  };
  sentry: {
    enabled: () => boolean;
    dsn?: string;
    environment?: string;
  };
};

const environment: Environment = {
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'production',
  apiUrl: process.env.REACT_APP_API_URL,
  appUrl: process.env.REACT_APP_ENVIRONMENT_URL || '',
  appVersion: process.env.REACT_APP_APP_VERSION || '',
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  hubspot: {
    id: process.env.REACT_APP_HUBSPOT_ID,
    enabled: () =>
      environment.isProduction &&
      Boolean(environment.hubspot.id) &&
      !domUtils.isLoadedInIframe,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  },
  stripePublishKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  unleash: {
    url: process.env.REACT_APP_UNLEASH_URL,
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
    appName: process.env.REACT_APP_UNLEASH_APP_NAME,
    environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT,
  },
  googleAnalytics4: {
    id: process.env.REACT_APP_GA_4_ID,
    enabled: () =>
      environment.isProduction && Boolean(environment.googleAnalytics4.id),
  },
  socketServer: {
    url: process.env.REACT_APP_SOCKET_SERVER_URL || '',
  },
  livekit: {
    url: process.env.REACT_APP_LIVEKIT_URL,
  },
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN,
  },
  sentry: {
    enabled: () => Boolean(environment.sentry.dsn),
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
};

export default environment;
