import FreeV3DrawingAction from './free.drawing.v3.action';

export default class EraseV3DrawingAction extends FreeV3DrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'erase_v3';
  }

  serialize() {
    const serialized = super.serialize();
    serialized.pnt = {
      ...serialized.pnt,
      // ToDo: Remove this attribute. It is needed only on android
      x: '',
    };
    return serialized;
  }
}
