import { createContext } from 'react';
import RecordingContextInterface from './RecordingContext.interface';

const RecordingContext = createContext<RecordingContextInterface>({
  isRecording: false,
  blob: null,
  thumbnail: null,
  startRecording: async () => {},
  stopRecording: async () => {},
  pauseRecording: () => {},
  resumeRecording: () => {},
  setFrontLayer: () => {},
  setBackgroundLayer: () => {},
  setInteractionLayer: () => {},
  setRecordingCanvas: () => {},
  setConferenceAudioStream: async (audioStream) => {},
  clearConferenceAudioStream: () => {},
  clearBlob: () => {},
  isRecordingFeatureEnabled: false,
});

export default RecordingContext;
