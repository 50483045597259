import { createSelector } from 'reselect';
import {
  GET_GROUP_LIST_REQUEST,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_FAILURE,
  DELETE_GROUP,
} from '../actions/groupsActions';
import * as contactsReducer from './contactsReducer';
import * as userReducer from './userReducer';
import { contactsSelector } from './contactsReducer';

const initialState = {
  isFetching: false,
  isFetched: false,
  hash: {},
  my: [],
  invited: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_GROUP_LIST_SUCCESS: {
      const { my: myGroups, invited: invitedGroups } = action.payload;
      const myGroupsIds = myGroups.map(c => c.id);
      const invitedGroupsIds = invitedGroups.map(c => c.id);
      const toHash = (acc, curr) => ({
        ...acc,
        [curr.id]: {
          ...curr,
          isFetching: false,
          isFetched: false,
        },
      });
      return {
        ...state,
        hash: {
          ...myGroups.reduce(toHash, {}),
          ...invitedGroups.reduce(toHash, {}),
        },
        my: [
          ...myGroupsIds,
        ],
        invited: [
          ...invitedGroupsIds,
        ],
        isFetching: false,
        isFetched: true,
      };
    }
    case DELETE_GROUP: {
      const { id: deletedGroupId } = action.payload;
      return {
        ...state,
        hash: Object.keys(state.hash)
          .filter(id => id !== deletedGroupId)
          .reduce((acc, curr) => ({ ...acc, [curr]: state.hash[curr] }), {}),
        my: state.my.filter(id => id !== deletedGroupId),
        invited: state.invited.filter(id => id !== deletedGroupId),
        isFetching: false,
      };
    }
    case GET_GROUP_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
const isFetchingSelector = state => state.groups.isFetching;
const isFetchedSelector = state => state.groups.isFetched;
const groupsSelector = state => Object.values(state.groups.hash);
const groupSelector = id => state => state.groups.hash[id];
const ownerSelector = id => state => state.groups.hash[id]?.ownerId;
const isGroupJoined = (state, id) => !!state.groups.invited.find(groupId => groupId === id);
const myGroupListSelector = state => state.groups.my.map(id => state.groups.hash[id]);
const invitedGroupListSelector = state => state.groups.invited.map(id => state.groups.hash[id]);
const invitedGroupIdsSelector = state => state.groups.invited;

const groupContactsSelector = id => createSelector(
  [
    groupSelector(id),
    contactsSelector,
  ],
  (group, contacts) => {
    if (!group) return [];

    const { contacts: contactIds = [], invited = [] } = group;
    return [
      ...contactIds.map(contactId => contacts[contactId]).filter(contact => !!contact),
      ...invited.map(email => ({ name: email, email, pending: true })),
    ];
  }
);

const getGroupSelector = id => createSelector(
  [
    groupSelector(id),
    groupContactsSelector(id),
  ],
  (group, contacts) => {
    if (!group) return null;

    return {
      ...group,
      contacts,
    };
  },
);

const sortAlphabetically = (a, b) => (
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
);

const getGroupMembers = id => createSelector(
  [
    groupSelector(id),
    () => id,
    userReducer.userSelector,
    contactsReducer.contactListSelector,
  ],
  (group, groupId, currentUser, allContacts) => {
    if (groupId === 'default') return allContacts.filter(contact => contact.name);
    if (!group) return [];

    const memberIds = [...group.contacts, group.ownerId];
    let contacts = [...allContacts.filter(c => memberIds.includes(c.id))];

    const userData = {
      id: currentUser.id,
      name: currentUser.name,
      profileImageUrl: currentUser.profileImageUrl,
    };

    const otherContacts = [...contacts.filter(contact => contact.id !== group.ownerId), userData].sort(sortAlphabetically);

    contacts = [...otherContacts];

    if (group.invited) {
      contacts = [...contacts, group.invited.map(email => ({ name: email, email, pending: true }))];
    }

    return contacts.filter(contact => contact.name);
  },
);

export {
  getGroupSelector,
  myGroupListSelector,
  invitedGroupListSelector,
  invitedGroupIdsSelector,
  groupContactsSelector,
  isFetchingSelector,
  isFetchedSelector,
  ownerSelector,
  isGroupJoined,
  getGroupMembers,
  groupsSelector,
  groupSelector,
};
