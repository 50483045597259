import { useState, useEffect, useRef } from 'react';
import SessionPdfDto from '../services/dto/sessionPdf/sessionPdf';

type Config = {
  fetchFunc: () => Promise<SessionPdfDto>,
  interval: number,
  onSuccess: (data: SessionPdfDto | null) => boolean | null,
  retryCount?: number,
  onFailure?: (error: any) => void,
};

type UsePollingType = (config: Config) => [boolean, () => void, () => void];

const usePolling: UsePollingType = (config) => {
  const {
    fetchFunc,
    interval = 3000,
    retryCount = 0,
    onSuccess,
    onFailure = () => {},
  } = config;
  const [isPolling, togglePolling] = useState(false);

  const persistedIsPolling = useRef<boolean | null>(null);
  const isMounted = useRef<boolean | null>(null);
  const poll = useRef<ReturnType<typeof setTimeout> | null>(null);

  persistedIsPolling.current = isPolling;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      stopPolling();
    };
  }, []);

  // if no url specified, throw an error
  if (!fetchFunc) {
    throw new Error('No fetchFunc provided to poll');
  }

  const shouldRetry = !!retryCount;

  const stopPolling = () => {
    if (isMounted.current) {
      if (poll.current) {
        clearTimeout(poll.current);
        poll.current = null;
      }
      togglePolling(false);
    }
  };

  const startPolling = () => {
    // why this does not update state?
    togglePolling(true);
    // call runPolling, which will start timer and call our api
    runPolling();
  };

  const runPolling = () => {
    const timeoutId = setTimeout(() => {
      fetchFunc()
        .then(onSuccess)
        .then((continuePolling) => {
          if (persistedIsPolling.current && continuePolling) {
            runPolling();

            return;
          }

          stopPolling();
        })
        .catch((error) => {
          if (shouldRetry && retryCount > 0) {
            if (onFailure) {
              onFailure(error);
            }
            runPolling();
          } else {
            if (onFailure) {
              onFailure(error);
            }

            stopPolling();
          }
        });
    }, interval);
    poll.current = timeoutId;
  };

  return [isPolling, startPolling, stopPolling];
};

export default usePolling;
