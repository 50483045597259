import React from 'react';
import Box from '@mui/material/Box';
import Typography from '../../../ui/atoms/Typography';
import { Paper } from '../../../ui/atoms/Paper';

type SectionCardProps = {
  title?: string;
};

export const SectionCard: React.FC<
  React.PropsWithChildren<SectionCardProps>
> = ({ title, children }) => (
  <Paper>
    {title ? (
      <Box
        sx={(theme) => ({
          marginBottom: '24px',
          [theme.breakpoints.up('sm')]: {
            marginBottom: '40px',
          },
        })}
      >
        <Typography
          variant="s1"
          sx={(theme) => ({
            color: theme.text.t8,
          })}
        >
          {title}
        </Typography>
      </Box>
    ) : null}
    {children}
  </Paper>
);
