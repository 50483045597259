import React, { FC, useCallback } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useVariant } from '@unleash/proxy-client-react';
import { useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';
import { styled } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
import Box from '@mui/material/Box';
import { liveBoardStorage } from '../../../config/storage';
import GoogleIcon from '../Icons/GoogleIcon';
import {
  saveUserAction,
  signInWithGoogle,
} from '../../../common/actions/authActions';
import analyticsService from '../../../common/services/analytics.service';
import UserDto from '../../../common/services/dto/auth/UserDto';
import Button from '../Button';
import { ButtonSizeTypes } from '../Button/buttonTypes';
import { ButtonProps } from '../Button/Button';
import authService from '../../../common/services/auth.service';
import { setUser } from '../../../common/actions/userActions';
import { getGeneralConfiguration } from '../../../common/actions/configurationActions';
import { useBoard } from '../../pages/auth/SignUp/useBoard';
import { setShowProductTour } from '../../../common/actions/dashboardActions';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderWidth: '1px',
  borderColor: theme.background.bg7,
  fontSize: '1rem',
  fontFamily: 'Roboto',
  fontWeight: 500,
  justifyContent: 'unset',
  padding: '11px 24px',
  lineHeight: '24px',
  '&:hover': {
    backgroundColor: theme.background.bg3,
    color: theme.text.t8,
    borderWidth: '1px',
    borderColor: theme.background.bg7,
  },
  [`.${buttonClasses.startIcon}`]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

type Props = {
  planId?: string;
  role?: string;
};

const GoogleSignInButton: FC<React.PropsWithChildren<Props>> = ({
  planId,
  role,
  children,
}) => {
  const dispatch = useDispatch();
  const location = useLocation<{ referrer?: string }>();
  const shouldCreateBoardVariant = useVariant('open-board-after-registration');

  const { processBoardCreating } = useBoard();

  const googleLoginClicked = useCallback(
    async (permissions: any) => {
      analyticsService.event(
        'Continue With Google Click',
        role ? { role } : {}
      );

      if (!location.state?.referrer) {
        analyticsService.setSuperProperties({
          'experiment_open-board-after-registration': `${shouldCreateBoardVariant.name}`,
        });
      }

      const {
        user,
        accessToken,
      }: {
        user: UserDto;
        accessToken: string;
      } = await signInWithGoogle(
        permissions.code,
        role,
        (window as any).Rewardful?.referral
      );

      if (!user.recentlySignedUp) {
        analyticsService.event('Sign In', { method: 'Google' });

        if (location.state?.referrer) {
          dispatch(push(location.state.referrer));
        }

        dispatch(saveUserAction(user, accessToken));
        return;
      }

      if (location.state?.referrer) {
        dispatch(push(location.state.referrer));
        dispatch(saveUserAction(user, accessToken));

        return;
      }

      analyticsService.event(
        'Sign Up',
        role ? { method: 'Google', role } : { method: 'Google' }
      );

      if (
        user.role === 'teacher' &&
        shouldCreateBoardVariant?.name === 'open-board'
      ) {
        await processBoardCreating();
      }

      if (planId) {
        const updatedUser = await authService.startTrial(planId, false);
        analyticsService.event('Trial Activated', {
          planId,
          source: 'Sign Up',
        });
        liveBoardStorage.setItem('showTrialSuccess', 'true');
        dispatch(setUser(updatedUser));
        dispatch(getGeneralConfiguration());
      }

      if (
        user.role === 'teacher' &&
        shouldCreateBoardVariant?.name === 'open-dashboard'
      ) {
        dispatch(setShowProductTour(true));
      }
      dispatch(saveUserAction(user, accessToken));
    },
    [dispatch, planId, shouldCreateBoardVariant, processBoardCreating, location]
  );

  const login = useGoogleLogin({
    onSuccess: googleLoginClicked,
    flow: 'auth-code',
  });

  return (
    <CustomButton
      onClick={login}
      size={ButtonSizeTypes.M}
      startIcon={<GoogleIcon />}
      fullWidth
    >
      <Box flex={1}>{children}</Box>
    </CustomButton>
  );
};

export default GoogleSignInButton;
