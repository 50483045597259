import ShapeDrawingAction from './shape.drawing.action';
import vectorUtils from '../../utils/vector.utils';

export default class LineDrawingAction extends ShapeDrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'line';
  }

  intersect(point) {
    const distance = vectorUtils.distanceToSegment(point, this.startPoint, this.endPoint);
    return distance < +this.paint.strokeWidth;
  }
}
