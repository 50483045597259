export enum SortConfigKey {
  DATECREATED = 'createdAt',
  LASTMODIFIED = 'updatedAt',
  ALPHABETICAL = 'name',
}

export enum SortConfigDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SortOptions = {
  key: SortConfigKey,
  direction: SortConfigDirection,
};
