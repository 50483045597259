import {
  BackgroundColors,
  MainColors,
  StatusColors,
  TextColors,
  ValidationColors,
} from './types/Color';

export const main: MainColors = {
  primary: {
    main: '#3A7859',
    dark5: '#32674C',
    alpha10: '#E7EEEC',
    alpha20: '#D4E1DB',
    alpha30: '#C0D4CB',
    alpha50: '#9ABAAB',
  },
  secondary: {
    main: '#EF6D46',
    dark5: '#ED5A2F',
    alpha10: '#F9EDEA',
    alpha20: '#F8DFD8',
    alpha30: '#F7D0C5',
    alpha50: '#F4B4A1',
  },
  tertiary: {
    main: '#FBB033',
    dark5: '#FBA61A',
    alpha10: '#FAF4E8',
    alpha20: '#FAECD4',
    alpha30: '#FAE5C0',
    alpha50: '#FAD597',
  },
};

export const background: BackgroundColors = {
  bgWhite: '#FFFFFF',
  bg1: '#FAFBFC',
  bg2: '#F6F7F8',
  bg3: '#EFF2F7',
  bg4: '#E7EBF1',
  bg5: '#E1E5EC',
  bg6: '#BCC4D1',
  bg7: '#919BAB',
  bg8: '#354053',
  bg9: '#32415A',
  bg10: '#232B39',
};

export const text: TextColors = {
  t1: '#FFFFFF',
  t2: '#EBEBEB',
  t3: '#D3D3D3',
  t4: '#C4C4C4',
  t5: '#AEAEB2',
  t6: '#9EA0A4',
  t7: '#7C7F8B',
  t8: '#354153',
  t9: '#32335A',
  t10: '#3D3D3D',
  t11: '#282828',
  t12: '#1C1C1C',
};

export const validation: ValidationColors = {
  success: {
    main: '#19E39C',
    dark5: '#16CC8C',
    alpha10: '#E0F5EF',
    alpha20: '#CAF3E6',
    alpha30: '#B4F1DC',
    alpha50: '#87EDCA',
  },
  error: {
    main: '#FF6666',
    dark5: '#FF4D4D',
    alpha10: '#F7E8E9',
    alpha20: '#F8DADB',
    alpha30: '#F9CCCC',
    alpha50: '#FAAEAF',
  },
  warning: {
    main: '#DDBA00',
    dark5: '#C3A500',
    alpha10: '#F3F1DF',
    alpha20: '#F1EBC6',
    alpha30: '#EEE5AE',
    alpha50: '#E9D87C',
  },
  info: {
    main: '#CCCCCC',
    dark5: '#BFBFBF',
    alpha10: '#F2F3F4',
    alpha20: '#EEEEEF',
    alpha30: '#E9EAEB',
    alpha50: '#E1E1E2',
  },
  premium: {
    main: '#1982E3',
    dark5: '#1675CC',
    alpha10: '#E4EFFA',
    alpha20: '#CDE3F7',
    alpha30: '#B7D7F5',
    alpha50: '#8ABFEF',
  },
};

export const status: StatusColors = {
  live: '#42A976',
  closed: background.bg4,
  new: {
    main: '#E71D32',
    dark5: '#DB1C2F',
    alpha10: '#FDE8EB',
    alpha20: '#FAD2D6',
    alpha30: '#F8BBC2',
    alpha50: '#F38E98',
  },
};

export const borderColor = 'rgba(0, 0, 0, 0.1)';
export const boardDrawingToolsColor = [
  '#000000',
  '#FFFFFF',
  '#FE3B36',
  '#008CED',
  '#7ABB4A',
  '#9724A3',
  '#FFD242',
];
