import { FC, useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import Box from '@mui/material/Box';
import Typography from '../../atoms/Typography';
import CreateBoardListItem from '../CreateBoardListItem';
import FileOutlinePortraitIcon from '../../atoms/Icons/FileOutlinePortraitIcon';
import FilePdfBoxIcon from '../../atoms/Icons/FilePdfBoxIcon';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent/DialogContent';
import INFINITE from '../../../common/utils/types/configuration/Infinite.constant';
import {
  boardsCountLimitSelector,
  boardsCountSelector,
  showBoardCreateLimitInfoSelector,
} from '../../../common/reducers/configurationReducer';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import PremiumButton from '../../atoms/NotificationButton/Premium';
import { NotificationButtonSize } from '../../atoms/NotificationButton/notificationButtonTypes';
import analyticsService from '../../../common/services/analytics.service';
import * as planPermissionSelectors from '../../../common/selectors/planPermissionSelectors';
import useDialog from '../../../common/hooks/useDialog';
import PdfFileInput from '../../../components/common/PdfFileInput';
import PdfImport from '../../../containers/Modals/PdfImportModal/PdfImport';
import UpgradeDialog from '../UpgradeDialog/UpgradeDialog';
import { setOpenSource } from '../../../common/actions/session/sessionActions';
import useCreateBoard from './useCreateBoard';
import PlanDialog from '../PlanDialog';

type CreateBoardDialogProps = {
  open: boolean;
  onClose: () => void;
  groupId: string;
};

type BoardLimitInformationProps = {
  boardsCount: number;
  boardsCountLimit: number;
  onUpgradeButtonClick: () => void;
};

const BoardLimitInformation: FC<
  React.PropsWithChildren<BoardLimitInformationProps>
> = ({ boardsCount, boardsCountLimit, onUpgradeButtonClick }) => (
  <Box display="flex" flexDirection="column" gap={1} mb={3.5}>
    <Box display="flex" justifyContent="space-between">
      <Typography
        variant={TypographyType.p5}
        color={boardsCount === boardsCountLimit ? 'error.main' : ''}
      >
        Your current plan has {boardsCountLimit} board
        {boardsCountLimit > 1 ? 's' : ''} limit
      </Typography>
      <Typography
        variant={TypographyType.p5}
        color={boardsCount === boardsCountLimit ? 'error.main' : ''}
      >
        {`(${boardsCount}/${boardsCountLimit})`}
      </Typography>
    </Box>
    <Box display="flex" gap={1.375}>
      <PremiumButton
        hasIcon
        size={NotificationButtonSize.XXS}
        onClick={onUpgradeButtonClick}
      >
        Upgrade plan
      </PremiumButton>
      <Typography variant={TypographyType.p5}>for unlimited boards.</Typography>
    </Box>
  </Box>
);

const CreateBoardDialog: FC<
  React.PropsWithChildren<CreateBoardDialogProps>
> = ({ open, onClose, groupId = 'default' }) => {
  const [pdf, setPdf] = useState(null);

  const boardsCountLimit = useSelector(boardsCountLimitSelector);
  const showBoardsLimitInfo = useSelector(showBoardCreateLimitInfoSelector);
  const boardsCount = useSelector(boardsCountSelector);
  const canCreateNewBoard = useSelector(
    planPermissionSelectors.canCreateNewBoardSelector
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const {
    open: createBoardDialogOpen,
    onOpen: onCreateBoardDialogOpen,
    onClose: onCreateBoardDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const onLimitReached = useCallback(() => {
    onCreateBoardDialogOpen();
    onClose();
  }, [onCreateBoardDialogOpen, onClose]);

  const { onBoardCreate, creatingBoard } = useCreateBoard({
    groupId,
    onLimitReached,
  });

  const onPdfFileSelect = useCallback(
    (pdfFile: any) => {
      setPdf(pdfFile);
    },
    [setPdf]
  );

  const onPdfInputClick = useCallback(() => {
    analyticsService.event('Create Board From PDF Click');
    if (!canCreateNewBoard) {
      analyticsService.event('Create Board above Limit');
      onLimitReached();
      return;
    }
    if (inputRef.current !== null) {
      inputRef.current.click();
      onClose();
    }
  }, [onLimitReached, canCreateNewBoard, onClose]);

  const onPdfImportModalSuccess = useCallback(
    (drawingId: any) => {
      setPdf(null);
      dispatch(
        push({
          pathname: `/session/${drawingId}`,
          state: { referrer: location.pathname },
        })
      );

      dispatch(setOpenSource('Create dialog: from PDF'));
    },
    [setPdf, dispatch, location]
  );

  const onClosePdfImportModal = useCallback(() => {
    setPdf(null);
  }, [setPdf]);

  const onUpgradeButtonClick = useCallback(() => {
    if (open) onClose();

    onPlansDialogOpen();
    onCreateBoardDialogClose();
  }, [onPlansDialogOpen, onCreateBoardDialogClose, onClose, open]);

  const onUpgradeCancelClick = useCallback(() => {
    onCreateBoardDialogClose();
  }, [onCreateBoardDialogClose]);

  return (
    <>
      <Dialog open={open} onClose={creatingBoard ? undefined : onClose}>
        <DialogTitle onClose={onClose} disabled={creatingBoard}>
          Create new board
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            gap: 1,
            marginBottom: 0,
            flexDirection: 'column',
          }}
        >
          {showBoardsLimitInfo && boardsCountLimit !== INFINITE && (
            <BoardLimitInformation
              boardsCount={boardsCount}
              boardsCountLimit={boardsCountLimit}
              onUpgradeButtonClick={onUpgradeButtonClick}
            />
          )}
          <CreateBoardListItem
            onClick={onBoardCreate}
            Icon={FileOutlinePortraitIcon}
            title={creatingBoard ? 'Creating board...' : 'Blank board'}
            subtitle={creatingBoard ? undefined : 'Fixed canvas size'}
            loading={creatingBoard}
          />
          <CreateBoardListItem
            onClick={onPdfInputClick}
            Icon={FilePdfBoxIcon}
            title="From PDF"
            subtitle="Created from a file"
            disabled={creatingBoard}
          />
        </DialogContent>
      </Dialog>
      <PlanDialog
        source="Board Creation Limit"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
      <PdfFileInput onChange={onPdfFileSelect} ref={inputRef} />
      <PdfImport
        pdfFile={pdf}
        type="create"
        onCancel={onClosePdfImportModal}
        onSuccess={onPdfImportModalSuccess}
      />
      <UpgradeDialog
        open={createBoardDialogOpen}
        onCancel={onUpgradeCancelClick}
        onUpgrade={onUpgradeButtonClick}
      />
    </>
  );
};

export default CreateBoardDialog;
