import React from 'react';
import * as rectangleUtil from '../../../../../common/utils/shapes/rectangle.util';
import BoundaryBox from '../boundaries/BoundaryBox';
import AspectRatioResizer from '../boundaries/resizers/AspectRatioResizer';
import TopResizer from '../boundaries/resizers/TopResizer';
import BottomResizer from '../boundaries/resizers/BottomResizer';
import LeftResizer from '../boundaries/resizers/LeftResizer';
import RightResizer from '../boundaries/resizers/RightResizer';
import PathShapeDrawingAction from '../../../../../common/drawingActions/shapes/pathShape.drawing.action';
import ShapeDrawingLayer from './ShapeDrawingLayer';
import { withCanvasContext } from '../../../Contexts/CanvasContext';

class PathShapesDrawingLayer extends ShapeDrawingLayer {
  createShape = (positions, props) => {
    const { color, size } = props;
    const { pathData, viewBoxWidth, viewBoxHeight } = this.props.tool;

    const { startPoint, endPoint } = positions;
    const drawingAction = new PathShapeDrawingAction();

    drawingAction.paint.color = color;
    drawingAction.paint.strokeWidth = size;
    drawingAction.localStartTime = new Date();
    drawingAction.setStartPoint(startPoint);
    drawingAction.setEndPoint(endPoint);
    drawingAction.setPathData(pathData);
    drawingAction.viewBoxWidth = viewBoxWidth;
    drawingAction.viewBoxHeight = viewBoxHeight;

    return drawingAction;
  };

  createBoundaries = (positions, props) => {
    const { size, scale, zoom } = props;
    const { startPoint, endPoint } = positions;

    const padding = 20 / scale + size / 2;

    const shapes = [
      new BoundaryBox(
        rectangleUtil.getStartingPoint(startPoint, endPoint),
        rectangleUtil.getEndPoint(startPoint, endPoint),
        scale * zoom,
        padding
      ),
      new TopResizer(startPoint, endPoint, scale * zoom, padding),
      new RightResizer(startPoint, endPoint, scale * zoom, padding),
      new BottomResizer(startPoint, endPoint, scale * zoom, padding),
      new LeftResizer(startPoint, endPoint, scale * zoom, padding),
      new AspectRatioResizer(startPoint, endPoint, scale * zoom, padding),
    ];

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => ({
    startPoint,
    endPoint,
  });
}

export default withCanvasContext(PathShapesDrawingLayer);
