import React, { useCallback, useState } from 'react';
import { clamp } from 'lodash';
import CanvasContext from './CanvasContext';
import { toolsBarHeight } from '../../BoardControls/ToolsBar/ToolsBar';

const ZOOM_STEP = 0.5;
const ZOOM_MIN = 1;
const ZOOM_MAX = 8;

const clampOrigin = (origin = 0, size, scaledSize, scaledOrigin, zoom) => {
  const diff = size / zoom - scaledSize;
  if (diff > 0) {
    return -diff / 2;
  }

  return clamp(origin, 0, -diff);
};

const CanvasContextProvider = ({ children }) => {
  const [value, setValue] = useState({
    boardWidth: 0,
    boardHeight: 0,
    boardDpi: 0,
    canvasWidth: 0,
    canvasHeight: 0,
    scale: 1,
    zoom: 1,
    scaledBoardWidth: 0,
    scaledBoardHeight: 0,
    scaledBoardOriginX: 0,
    scaledBoardOriginY: 0,
    originX: 0,
    originY: 0,
    originOffsetY: toolsBarHeight,
    minimapOpen: false,
    viewportCenterPosition: {
      x: 0,
      y: 0,
    },
  });

  const [animateTo, setAnimateTo] = useState(1);

  const onZoom = useCallback(
    (options) => {
      setValue((prevState) => {
        const {
          zoom,
          originX,
          originY,
          canvasWidth,
          canvasHeight,
          scaledBoardOriginX,
          scaledBoardOriginY,
          scaledBoardWidth,
          scaledBoardHeight,
        } = prevState;

        const { layerX, layerY, deltaY, scale, zoomOption } = options;

        if (scale) {
          setAnimateTo(scale);

          return {
            ...prevState,
            zoom: scale,
          };
        }

        let newZoom = zoomOption || zoom + -1 * Math.sign(deltaY) * ZOOM_STEP;
        newZoom = clamp(newZoom, ZOOM_MIN, ZOOM_MAX);
        const zoomFactor = 1 / zoom - 1 / newZoom;

        const eventX = layerX === undefined ? canvasWidth / 2 : layerX;
        let newOriginX =
          originX +
          (scaledBoardWidth * zoomFactor) / 2 +
          (eventX - scaledBoardWidth / 2) * zoomFactor;

        newOriginX = clampOrigin(
          newOriginX,
          canvasWidth,
          scaledBoardWidth,
          scaledBoardOriginX,
          newZoom
        );

        const eventY = layerY === undefined ? canvasHeight / 2 : layerY;
        let newOriginY =
          originY +
          (scaledBoardHeight * zoomFactor) / 2 +
          (eventY - scaledBoardHeight / 2) * zoomFactor;

        newOriginY = clampOrigin(
          newOriginY,
          canvasHeight,
          scaledBoardHeight,
          scaledBoardOriginY,
          newZoom
        );

        return {
          ...prevState,
          zoom: newZoom,
          originX: newOriginX,
          originY: newOriginY,
        };
      });
    },
    [setValue, animateTo, setAnimateTo]
  );

  const onMove = useCallback(
    ({ deltaX, deltaY }) => {
      setValue((prevState) => {
        const {
          zoom,
          originX,
          originY,
          canvasWidth,
          canvasHeight,
          scaledBoardOriginX,
          scaledBoardOriginY,
          scaledBoardWidth,
          scaledBoardHeight,
        } = prevState;

        const newOriginX = clampOrigin(
          originX - deltaX / zoom,
          canvasWidth,
          scaledBoardWidth,
          scaledBoardOriginX,
          zoom
        );
        const newOriginY = clampOrigin(
          originY - deltaY / zoom,
          canvasHeight,
          scaledBoardHeight,
          scaledBoardOriginY,
          zoom
        );

        return {
          ...prevState,
          originX: newOriginX,
          originY: newOriginY,
        };
      });
    },
    [setValue]
  );

  const updateFields = useCallback((options) => {
    const {
      boardWidth,
      boardHeight,
      canvasWidth,
      canvasHeight,
      originX,
      originY,
      zoom,
    } = options;

    const scale = Math.min(
      canvasWidth / boardWidth,
      canvasHeight / boardHeight
    );
    const scaledBoardWidth = boardWidth * scale;
    const scaledBoardHeight = boardHeight * scale;
    const scaledBoardOriginX = (canvasWidth - scaledBoardWidth) / 2;
    const scaledBoardOriginY = (canvasHeight - scaledBoardHeight) / 2;

    const newOriginX = clampOrigin(
      originX,
      canvasWidth,
      scaledBoardWidth,
      scaledBoardOriginX,
      zoom
    );
    const newOriginY = clampOrigin(
      originY,
      canvasHeight,
      scaledBoardHeight,
      scaledBoardOriginY,
      zoom
    );

    return {
      scale,
      scaledBoardWidth,
      scaledBoardHeight,
      scaledBoardOriginX,
      scaledBoardOriginY,
      originX: newOriginX,
      originY: newOriginY,
    };
  }, []);

  const setBoardSizes = useCallback(
    (width, height, dpi) => {
      setValue((prevState) => {
        const newState = {
          ...prevState,
          boardWidth: width,
          boardHeight: height,
          boardDpi: dpi,
        };
        return {
          ...newState,
          ...updateFields(newState),
        };
      });
    },
    [setValue, updateFields]
  );

  const setCanvasSizes = useCallback(
    (width, height) => {
      setValue((prevState) => {
        const newState = {
          ...prevState,
          canvasWidth: width,
          canvasHeight: height,
        };
        return {
          ...newState,
          ...updateFields(newState),
        };
      });
    },
    [setValue, updateFields]
  );

  const setMinimapOpen = useCallback(
    (active) => {
      setValue((prevState) => {
        const newState = {
          ...prevState,
          minimapOpen: active,
        };
        return {
          ...newState,
          ...updateFields(newState),
        };
      });
    },
    [setValue, updateFields]
  );

  const resetZoom = useCallback(() => {
    setValue((prevState) => {
      const {
        scaledBoardWidth,
        scaledBoardHeight,
        scaledBoardOriginX,
        scaledBoardOriginY,
        canvasWidth,
        canvasHeight,
      } = prevState;
      const zoom = ZOOM_MIN;

      const newOriginX = clampOrigin(
        0,
        canvasWidth,
        scaledBoardWidth,
        scaledBoardOriginX,
        zoom
      );
      const newOriginY = clampOrigin(
        0,
        canvasHeight,
        scaledBoardHeight,
        scaledBoardOriginY,
        zoom
      );

      return {
        ...prevState,
        zoom,
        scaledBoardWidth,
        scaledBoardHeight,
        scaledBoardOriginX,
        scaledBoardOriginY,
        originX: newOriginX,
        originY: newOriginY,
      };
    });
  }, [setValue]);

  const setViewportCenterPosition = useCallback(
    (position) => {
      setValue((prevValue) => ({
        ...prevValue,
        viewportCenterPosition: {
          x: position.x,
          y: position.y,
        },
      }));
    },
    [setValue]
  );

  return (
    <CanvasContext.Provider
      value={{
        ...value,
        onMove: onMove,
        onZoom: onZoom,
        setBoardSizes: setBoardSizes,
        setCanvasSizes: setCanvasSizes,
        resetZoom: resetZoom,
        setMinimapOpen: setMinimapOpen,
        setViewportCenterPosition,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export default CanvasContextProvider;
