const getCenter = (start, end) => ({
  x: (start.x + end.x) / 2,
  y: (start.y + end.y) / 2,
});

const getRadii = (start, end) => ({
  x: Math.abs(end.x - start.x) / 2,
  y: Math.abs(end.y - start.y) / 2,
});

const intersect = (point, start, end) => {
  const cos = Math.cos(0);
  const sin = Math.sin(0);
  const center = getCenter(start, end);
  const dx = (point.x - center.x);
  const dy = (point.y - center.y);
  const radii = getRadii(start, end);
  const tdx = (cos * dx) + (sin * dy);
  const tdy = (sin * dx) - (cos * dy);

  return ((tdx ** 2) / (radii.x ** 2)) + ((tdy ** 2) / (radii.y ** 2)) <= 1;
};

export { getCenter, getRadii, intersect };
