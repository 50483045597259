import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  transform: 'unset',
  backgroundColor: theme.background.bg4,
}));

export const LoadingListItem = () => (
  <Box width="100%" padding="14px 16px 13px 16px" display="flex" gap={2}>
    <StyledSkeleton width={25} height={25} animation="wave" />
    <Box display="flex" gap={1} flexDirection="column">
      <StyledSkeleton width={140} height={10} animation="wave" />
      <StyledSkeleton width={80} height={10} animation="wave" />
    </Box>
  </Box>
);
