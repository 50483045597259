import ShapeDrawingAction from './shape.drawing.action';

export default class CircleDrawingAction extends ShapeDrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'circle';
  }

  radius(scale) {
    const a = (this.startPoint.x * scale) - (this.endPoint.x * scale);
    const b = (this.startPoint.y * scale) - (this.endPoint.y * scale);
    return Math.ceil(Math.sqrt((a ** 2) + (b ** 2)));
  }

  intersect(point) {
    const a = (point.x - this.startPoint.x) ** 2;
    const b = (point.y - this.startPoint.y) ** 2;
    return Math.sqrt(a + b) <= this.radius(1);
  }
}
