import DrawingAction from './drawingAction';
import OldDeleteAction from '../../../common/drawingActions/delete.action';
import ActionName from '../types/ActionName';

export default class DeleteAction extends DrawingAction {
  constructor(action?: OldDeleteAction) {
    super(action);

    this.name = ActionName.DELETE;

    if (action) {
      this.targetId = action.targetId;
    }
  }

  toDto(): OldDeleteAction {
    const dto = new OldDeleteAction();
    this.setFields(dto);

    return dto;
  }
}
