const generateBaseConfiguration = () => ({
  enabled: false,
  visible: false,
  upgradable: false,
  showIcon: false,
});

const generateCreateConfiguration = () => ({
  ...generateBaseConfiguration(),
  limit: 0, // < 0 | 0 | > 0
  count: 0,
});

const generateWarningConfiguration = () => (['create', 'list']);

export {
  generateBaseConfiguration,
  generateCreateConfiguration,
  generateWarningConfiguration,
};
