import {
  SET_SESSION,
  SESSION_DISCONNECTED,
  CLEAR_SESSION,
  UPDATE_SESSION,
} from '../../actions/session/sessionActions';
import {
  ADD_EMAILS,
  ADD_CONTACTS,
  UPDATE_INVITED_CONTACTS,
} from '../../actions/session/invitesActions';

const initialState = {
  contacts: [],
  emails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION: {
      return {
        ...state,
        ...action.invites,
      };
    }
    case ADD_EMAILS: {
      return {
        ...state,
        emails: action.emails,
      };
    }
    case ADD_CONTACTS: {
      return {
        ...state,
        contacts: action.contacts,
      };
    }
    case UPDATE_INVITED_CONTACTS:
      return {
        ...state,
        ...action.invites,
      };
    case UPDATE_SESSION:
      return {
        ...state,
        ...action.session.invites,
      };
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED: {
      return initialState;
    }
    default:
      return state;
  }
};

const invitedEmailsSelector = (state) => state.session.invites.emails;
const invitedContactIdsSelector = (state) => state.session.invites.contacts;
const invitedContactsSelector = (state) => {
  return [
    ...invitedContactIdsSelector(state)
      .map((id) => state.contacts.hash[id])
      .filter((c) => !!c),
    ...invitedEmailsSelector(state).map((email) => ({
      name: email,
      email,
      pending: true,
    })),
  ];
};

export {
  invitedContactsSelector,
  invitedEmailsSelector,
  invitedContactIdsSelector,
};
