import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Typography from '../../atoms/Typography';
import ColorLiveBoardLogo from '../../atoms/Icons/ColorLiveBoardLogo';
import ColorLiveBoardIcon from '../../atoms/Icons/ColorLiveBoardIcon';
import { borderColor, validation } from '../../atoms/Colors/colorStyles';
import CrownOutlineIcon from '../../atoms/Icons/CrownOutlineIcon';
import ListItem from '../../atoms/ListItem';
import Menu from '../Menu';
import PlanDialog from '../PlanDialog';
import useDialog from '../../../common/hooks/useDialog';
import * as userActions from '../../../common/actions/userActions';
import * as authActions from '../../../common/actions/authActions';
import {
  userPlanSelector,
  userSelector,
} from '../../../common/reducers/userReducer';
import LinkButton from '../../atoms/LinkButton';
import JoinSessionForm from '../JoinSessionForm';
import { HeaderProps } from './headerTypes';
import { noop } from '../../../common/constants';
import BurgerIcon from '../../atoms/Icons/BurgerIcon';
import CloseIcon from '../../atoms/Icons/CloseIcon';
import SettingsOutlineIcon from '../../atoms/Icons/SettingsOutlineIcon';
import LogoutIcon from '../../atoms/Icons/LogoutIcon';
import SchoolOutlineIcon from '../../atoms/Icons/SchoolOutlineIcon';
import ProfileAvatar from '../../atoms/ProfileAvatar';
import CrownIcon from '../../atoms/Icons/CrownIcon';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import { useProductTour } from '../../../components/ProductTour';
import analyticsService from '../../../common/services/analytics.service';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import Button from '../../atoms/Button';
import QuizIcon from '../../atoms/Icons/QuizIcon';
import ViewDashboardOutlineIcon from '../../atoms/Icons/ViewDashboardOutlineIcon';

const tutorialsLink =
  'https://www.youtube.com/playlist?list=PLE6l-RD1Tv6DFYc2mFPMLriwd6sO2zVnJ';

const Header: FC<React.PropsWithChildren<HeaderProps>> = ({
  isBoardCreated,
  fixed,
  collectPaymentsOnboardingStatus = null,
}) => {
  const location = useLocation();
  const user = useSelector(userSelector);
  const plan = useSelector(userPlanSelector);
  const anchorElRef = useRef(null);
  const drawerRef = useRef(null);
  const productTour = useProductTour();

  const theme = useTheme();
  const showBurger = useMediaQuery(theme.breakpoints.down('md'));
  const showJoin = useMediaQuery(theme.breakpoints.up('sm'));
  const showTrialInHeader = useMediaQuery(theme.breakpoints.up('md'));
  const hideLiveBoardName = useMediaQuery(
    theme.breakpoints.between('sm', 'md')
  );

  const {
    open: drawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDialog();

  const {
    open: menuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const dispatch = useDispatch();
  const navigateTo = useCallback(
    (path: any) => {
      dispatch(push(path));
    },
    [dispatch]
  );

  const plansModalSuccess = useCallback(() => {
    dispatch(userActions.getUser());
  }, [dispatch]);

  const onProductTourOpen = useCallback(async () => {
    analyticsService.event('Product Tour Start', {
      auto: false,
    });
    navigateTo('/group/default/sessions');
    productTour.startTour();
    onMenuClose();
    onDrawerClose();
  }, [productTour.startTour, onMenuClose, onDrawerClose, navigateTo]);

  useEffect(() => {
    const updateOverlayStates = () => {
      if (!anchorElRef.current && menuOpen) {
        onMenuClose();
      }

      if (!drawerRef.current && drawerOpen) {
        onDrawerClose();
      }
    };

    window.addEventListener('resize', updateOverlayStates);

    return () => {
      window.removeEventListener('resize', updateOverlayStates);
    };
  }, [menuOpen, onMenuClose, drawerOpen, onDrawerClose]);

  const handleDisconnect = useCallback(() => {
    dispatch(authActions.signOut());
  }, [dispatch]);

  const onBurgerClick = useCallback(() => {
    onDrawerOpen();
  }, [onDrawerOpen]);

  return (
    <Box
      sx={(t) => ({
        position: fixed ? 'fixed' : 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        height: '76px',
        zIndex: t.zIndex.drawer + 1,
        backgroundColor: t.background.bg2,
        borderBottom: `1px solid ${borderColor}`,
      })}
    >
      <Box
        sx={(t) => ({
          padding: '26px 16px',
          [t.breakpoints.up('sm')]: {
            padding: '23px 24px',
          },
          [t.breakpoints.up('md')]: {
            padding: '23px 36px',
            borderRight: '1px solid',
            borderColor,
          },
        })}
      >
        <Box
          sx={(t) => ({
            display: 'flex',
            justifyContent: 'flex-start',
            width: '118px',
            height: '24px',
            [t.breakpoints.up('sm')]: {
              width: 'auto',
              height: '30px',
            },
          })}
        >
          <NavLink to="/group/default/sessions">
            {hideLiveBoardName && isBoardCreated ? (
              <ColorLiveBoardIcon
                sx={{
                  width: '30px',
                  height: '30px',
                }}
              />
            ) : (
              <ColorLiveBoardLogo
                sx={(t) => ({
                  width: '118px',
                  height: '24px',
                  [t.breakpoints.up('sm')]: {
                    width: '148px',
                    height: '30px',
                  },
                })}
              />
            )}
          </NavLink>
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box display="flex" alignItems="center" ml={1} gap={1}>
          <NavLink
            to="/assessments/library"
            onClick={() => {
              analyticsService.event('Assessments Library Link Click', {
                source: 'Header',
              });
            }}
          >
            <Button
              variant={ButtonVariantTypes.OUTLINED}
              size={ButtonSizeTypes.S}
              startIcon={<QuizIcon />}
            >
              Assessments
            </Button>
          </NavLink>
        </Box>
        <Box
          sx={(t) => ({
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            padding: '23px 16px 23px 0',
            gap: 3,
            [t.breakpoints.up('sm')]: {
              padding: '18px 24px 18px 0',
            },
            [t.breakpoints.up('md')]: {
              padding: '14px 36px 14px 0',
              gap: 4.5,
            },
          })}
        >
          {showTrialInHeader && plan.isFree && (
            <Box>
              <Typography variant="p4" textAlign="right">
                You are in Free plan
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                gap={0.5}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={onPlansDialogOpen}
              >
                <CrownOutlineIcon
                  color="premium"
                  sx={{
                    fontSize: '1.125rem',
                  }}
                />
                <Typography variant="p4" color="premium.main">
                  {user.canActivateTrial
                    ? 'Try free for 14 days'
                    : 'Upgrade plan'}
                </Typography>
              </Box>
            </Box>
          )}
          {showJoin && isBoardCreated && <JoinSessionForm />}
          {showBurger && (
            <Box
              sx={(t) => ({
                display: 'flex',
                justifyContent: 'end',
                width: 'auto',
                color: t.text.t8,
                [t.breakpoints.up('sm')]: {
                  width: '148px',
                },
              })}
            >
              {drawerOpen ? (
                <CloseIcon
                  onClick={onDrawerClose}
                  sx={(t) => ({
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    [t.breakpoints.up('sm')]: {
                      width: '40px',
                      height: '40px',
                    },
                  })}
                />
              ) : (
                <BurgerIcon
                  onClick={onBurgerClick}
                  sx={(t) => ({
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    [t.breakpoints.up('sm')]: {
                      width: '40px',
                      height: '40px',
                    },
                  })}
                />
              )}
            </Box>
          )}
          {!showBurger && (
            <>
              <ProfileAvatar
                ref={anchorElRef}
                menuOpen={menuOpen}
                onMenuOpen={onMenuOpen}
              />
              <Menu
                open={menuOpen}
                onClose={onMenuClose}
                anchorEl={anchorElRef.current}
                listItemWidth={208}
              >
                <Box pb={1} mb={1} borderBottom={`1px solid ${borderColor}`}>
                  <LinkButton
                    title={user.name}
                    subtitle={user.email}
                    navigateTo="/profile/account"
                    onSelect={noop}
                    listItemTextStyles={{
                      width: '176px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                    primaryTypographyProps={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    secondaryTypographyProps={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  />
                </Box>
                {user.canManageUsers && (
                  <LinkButton
                    startIcon={<ViewDashboardOutlineIcon />}
                    title="Admin Dashboard"
                    navigateTo="/dashboard"
                    onSelect={() => {
                      analyticsService.event('Admin Dashboard Link Click', {
                        organizationId: user.organizationId,
                      });
                    }}
                  />
                )}
                <Link href={tutorialsLink} underline="none">
                  <ListItem
                    startIcon={<SchoolOutlineIcon />}
                    title="Tutorials"
                    onSelect={noop}
                  />
                </Link>
                <LinkButton
                  startIcon={<SettingsOutlineIcon />}
                  title="Settings"
                  navigateTo="/profile/account"
                  onSelect={noop}
                />
                {location.pathname === '/group/default/sessions' && (
                  <ListItem
                    startIcon={<HelpOutlineOutlinedIcon />}
                    title="Guided tour"
                    onSelect={onProductTourOpen}
                  />
                )}
                <ListItem
                  startIcon={<LogoutIcon />}
                  title="Log out"
                  textPrimaryColor={validation.error.main}
                  onSelect={handleDisconnect}
                />
              </Menu>
            </>
          )}
        </Box>
      </Box>
      {showBurger && (
        <Drawer
          ref={drawerRef}
          disableEnforceFocus
          anchor="top"
          open={drawerOpen}
          onClose={onDrawerClose}
          sx={(t) => ({
            [`& .${drawerClasses.paper}`]: {
              paddingTop: '76px',
              backgroundColor: t.background.bg2,
            },
          })}
        >
          <Box
            sx={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LinkButton
              startIcon={<Avatar src={user.profileImageUrl} />}
              title={user.name}
              subtitle={user.email}
              navigateTo="/profile/account"
              onSelect={noop}
              listItemTextStyles={{
                width: '176px',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
              primaryTypographyProps={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              secondaryTypographyProps={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            />
            <Divider
              sx={{
                margin: '8px 0',
                color: `1px solid ${borderColor}`,
                backgroundColor: `1px solid ${borderColor}`,
              }}
            />
            {!showTrialInHeader && plan.isFree && (
              <ListItem
                startIcon={<CrownIcon />}
                endIcon={
                  <Box
                    sx={(t) => ({
                      borderRadius: '8px 8px 8px 0px',
                      backgroundColor: t.palette.info.main,
                      padding: '2px 5px',
                    })}
                  >
                    <Typography
                      variant={TypographyType.p6}
                      sx={(t) => ({
                        color: t.text.t1,
                      })}
                    >
                      Free plan
                    </Typography>
                  </Box>
                }
                title="Subscription"
                textPrimaryColor={validation.premium.main}
                onSelect={onPlansDialogOpen}
              />
            )}
            {user.canManageUsers && (
              <LinkButton
                startIcon={<ViewDashboardOutlineIcon />}
                title="Admin Dashboard"
                navigateTo="/dashboard"
                onSelect={() => {
                  analyticsService.event('Admin Dashboard Link Click', {
                    organizationId: user.organizationId,
                  });
                }}
              />
            )}
            <Link href={tutorialsLink} underline="none">
              <ListItem
                startIcon={<SchoolOutlineIcon />}
                title="Tutorials"
                onSelect={noop}
              />
            </Link>
            <LinkButton
              startIcon={<SettingsOutlineIcon />}
              title="Settings"
              navigateTo="/profile/account"
              onSelect={noop}
            />
            {location.pathname === '/group/default/sessions' && (
              <ListItem
                startIcon={<HelpOutlineOutlinedIcon />}
                title="Guided tour"
                onSelect={onProductTourOpen}
              />
            )}
            <ListItem
              startIcon={<LogoutIcon />}
              title="Log out"
              textPrimaryColor={validation.error.main}
              onSelect={handleDisconnect}
            />
          </Box>
        </Drawer>
      )}
      <PlanDialog
        source="Header"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
        onSuccess={plansModalSuccess}
      />
    </Box>
  );
};

export default Header;
