import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { boardTypeSelector } from '../common/reducers/session/sessionReducer';
import BoardType from '../common/services/types/session/BoardType';
import CanvasContainer from './board/CanvasContainer';
import PixiBoard from './PixiBoard';

const Board = () => {
  const sessionType = useSelector(boardTypeSelector);

  const renderedSession = useMemo(() => {
    if (sessionType === BoardType.INFINITE) {
      return <PixiBoard />;
    }

    return <CanvasContainer />;
  }, [sessionType]);

  return (
    <main className="canvas-wrapper">
      {renderedSession}
    </main>
  );
};

export default Board;
