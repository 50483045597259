import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import CreateQuestionOptionDto from '../../../../common/services/dto/sessionQuestion/CreateQuestionOptionDto';
import TextField from './TextField';

const TableCell = styled(MuiTableCell)<TableCellProps>(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(0.5, 0),
  verticalAlign: 'top',
}));

type Props = {
  options: CreateQuestionOptionDto[];
  setOptions: (options: CreateQuestionOptionDto[]) => void;
  deleteOption: (option: CreateQuestionOptionDto) => void;
  setCorrectOption: (option: CreateQuestionOptionDto) => void;
  // ToDo: change control, clearErrors type
  control: any;
  clearErrors: any;
};

const SingleChoiceOptions = ({
  options,
  setOptions,
  deleteOption,
  setCorrectOption,
  control,
  clearErrors,
}: Props) => {
  const firstCorrectOption = useMemo(
    () => options.find((option: CreateQuestionOptionDto) => option.isCorrect),
    []
  );

  useEffect(() => {
    setOptions([
      ...options.map((option: CreateQuestionOptionDto) => {
        if (option === firstCorrectOption) return option;

        return {
          ...option,
          isCorrect: false,
        };
      }),
    ]);
  }, []);

  return (
    <>
      {options.map((option: CreateQuestionOptionDto, index: number) => (
        <React.Fragment key={index}>
          <TableRow>
            <TableCell scope="row" padding="checkbox">
              <Box height={40}>
                <Radio
                  onChange={() => {
                    setCorrectOption(option);
                  }}
                  checked={option.isCorrect}
                  color="primary"
                />
              </Box>
            </TableCell>
            <TableCell className="px-3" scope="row">
              <Box display="flex" alignItems="flex-start" width={1}>
                <Controller
                  name={`options[${index}].text`}
                  control={control}
                  rules={{
                    required: '* Required',
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                    formState: { errors },
                  }) => (
                    <TextField
                      value={value}
                      onChange={(event) => {
                        if (error) {
                          clearErrors(`options[${index}].text`);
                        }

                        onChange(event);
                      }}
                      onBlur={onBlur}
                      errors={errors}
                      index={index}
                    />
                  )}
                />
              </Box>
            </TableCell>
            <TableCell
              scope="row"
              padding="checkbox"
              align="right"
              variant="body"
            >
              {options.length > 2 && (
                <Box height={40}>
                  <Button
                    onClick={() => deleteOption(option)}
                    className="p-2"
                    color="neutral"
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              )}
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </>
  );
};

export default SingleChoiceOptions;
