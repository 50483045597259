import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { typographyClasses } from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '../../atoms/Typography';
import SupportButton from '../../atoms/SupportButton';
import SocialMediaSupport from '../../atoms/SocialMediaSupport';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import { borderColor } from '../../atoms/Colors/colorStyles';

const Footer = forwardRef<HTMLDivElement>((_, ref) => {
  const theme = useTheme();
  const showSupportText = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      ref={ref}
      sx={{
        borderTop: `1px solid ${borderColor}`,
        width: '100%',
        marginTop: 'auto',
      }}
    >
      <Box
        sx={t => ({
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '24px 0',
          gap: '20px',
          backgroundColor: t.background.bg2,
          [t.breakpoints.up('md')]: {
            maxWidth: '1280px',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '22px 24px',
            gap: '50px',
            margin: 'auto',
          },
        })}
      >
        <Box
          sx={t => ({
            display: 'flex',
            justifyContent: 'center',
            gap: '40px',
            [t.breakpoints.up('md')]: {
              justifyContent: 'space-between',
              flex: 1,
              gap: 'unset',
              order: 2,
            },
          })}
        >
          <Box>
            <SocialMediaSupport />
          </Box>
          <Box>
            <SupportButton
              textVisibility={showSupportText}
              expandable={showSupportText}
            />
          </Box>
        </Box>
        <Box
          sx={t => ({
            display: 'flex',
            justifyContent: 'center',
            gap: '24px',
            [t.breakpoints.up('md')]: {
              order: 3,
              gap: '36px',
            },
          })}
        >
          <Link
            href="https://liveboard.online/terms/"
            sx={t => ({
              textDecoration: 'none',
              '&:hover': {
                color: t.palette.primary.main,
                textDecoration: 'underline',
                [`& .${typographyClasses.root}`]: {
                  color: t.palette.primary.main,
                },
              },
            })}
          >
            <Typography variant={TypographyType.p5}>
              Terms & Conditions
            </Typography>
          </Link>
          <Link
            href="https://liveboard.online/privacy/"
            sx={t => ({
              textDecoration: 'none',
              '&:hover': {
                color: t.palette.primary.main,
                textDecoration: 'underline',
                [`& .${typographyClasses.root}`]: {
                  color: t.palette.primary.main,
                },
              },
            })}
          >
            <Typography variant={TypographyType.p5}>
              Privacy Policy
            </Typography>
          </Link>
        </Box>
        <Box
          sx={t => ({
            [t.breakpoints.up('md')]: {
              order: 1,
            },
          })}
        >
          <Typography
            variant={TypographyType.p5}
            align="center"
            sx={t => ({
              color: t.text.t7,
            })}
          >
            ©
            {' '}
            {process.env.REACT_APP_CURRENT_YEAR}
            {' '}
            LiveBoard, Inc.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

export default Footer;
