import { SpatialHash } from 'pixi-cull';
import { DisplayObjectWithCulling, AABB } from 'pixi-cull/code/types';

const intersect = (objectAABB: AABB, aabb: AABB) =>
  objectAABB.x <= aabb.x + aabb.width &&
  objectAABB.x + objectAABB.width >= aabb.x &&
  objectAABB.y <= aabb.y + aabb.height &&
  objectAABB.y + objectAABB.height >= aabb.y;

class CullingAlgorithm extends SpatialHash {
  query(aabb: AABB, simpleTest: boolean = true): DisplayObjectWithCulling[] {
    let buckets = 0;
    let results: any[] = [];
    const { xStart, yStart, xEnd, yEnd } = this.getBounds(aabb);
    for (let y = yStart; y <= yEnd; y += 1) {
      for (let x = xStart; x <= xEnd; x += 1) {
        // @ts-ignore
        const entry = this.hash[`${x},${y}`];
        if (entry) {
          if (simpleTest) {
            const { length } = entry;
            for (let i = 0; i < length; i += 1) {
              const object = entry[i];
              const box = object.AABB;

              if (intersect(box, aabb)) {
                results.push(object);
              }
            }
          } else {
            results = results.concat(entry);
          }
          buckets += 1;
        }
      }
    }
    this.lastBuckets = buckets;
    return results;
  }

  queryCallbackAll(
    aabb: AABB,
    simpleTest: boolean = true,
    callback: (object: DisplayObjectWithCulling) => boolean
  ): DisplayObjectWithCulling[] {
    let buckets = 0;
    let results: any[] = [];
    const { xStart, yStart, xEnd, yEnd } = this.getBounds(aabb);
    for (let y = yStart; y <= yEnd; y += 1) {
      for (let x = xStart; x <= xEnd; x += 1) {
        // @ts-ignore
        const entry = this.hash[`${x},${y}`];
        if (entry) {
          if (simpleTest) {
            const { length } = entry;
            for (let i = 0; i < length; i += 1) {
              const object = entry[i];
              const box = object.AABB;

              if (intersect(box, aabb)) {
                results.push(object);
                callback(object);
              }
            }
          } else {
            results = results.concat(entry);
            for (const object of entry) {
              callback(object);
            }
          }
          buckets += 1;
        }
      }
    }
    this.lastBuckets = buckets;
    return results;
  }

  queryCallback(
    aabb: AABB,
    callback: (object: DisplayObjectWithCulling) => boolean,
    simpleTest: boolean = true
  ): boolean {
    const { xStart, yStart, xEnd, yEnd } = this.getBounds(aabb);
    for (let y = yStart; y <= yEnd; y += 1) {
      for (let x = xStart; x <= xEnd; x += 1) {
        // @ts-ignore
        const entry = this.hash[`${x},${y}`];
        if (entry) {
          for (let i = 0; i < entry.length; i += 1) {
            const object = entry[i];
            if (simpleTest) {
              const box = object.AABB;

              if (intersect(box, aabb)) {
                return callback(object);
              }
            } else {
              return callback(object);
            }
          }
        }
      }
    }
    return false;
  }
}

export default CullingAlgorithm;
