import { DisplayObject } from 'pixi.js';
import Action from './action';
import OldDrawingAction from '../../../common/drawingActions/drawing.action';
import ToolSettings from '../types/ToolSettings';
import Point from '../types/Point';

class DrawingAction extends Action {
  paint: ToolSettings = {
    strokeWidth: 5,
    textSize: 20,
    color: -16777216,
    alpha: 1,
    fill: false,
  };

  targetId: string | null = null;

  syncedWithFirebase: boolean = false;

  constructor(action?: OldDrawingAction) {
    super(action);

    this.name = 'drawingAction';

    if (action) {
      if (action.paint) {
        this.paint.strokeWidth = action.paint.strokeWidth;
        this.paint.textSize = action.paint.textSize;
        this.paint.fill = action.paint.fill;
        this.paint.color = action.paint.color;
        // ToDo: Alpha is included in color now. Change later!
        // this.paint.alpha = deserialized.pnt.a / 255;
        this.paint.alpha = action.paint.alpha;
      }

      this.targetId = action.targetId;
    }
  }

  toDto(): OldDrawingAction {
    const dto = new OldDrawingAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldDrawingAction) {
    super.setFields(action);
    action.paint = this.paint;
    action.targetId = this.targetId;
  }

  setSyncedWithFirebase(value: boolean) {
    this.syncedWithFirebase = value;
  }

  requiresUpdate(action: DrawingAction): boolean {
    return false;
  }

  createMoveAction(newPos: Point): DrawingAction {
    throw new Error('Not implemented');
  }

  drawIntermediate(object: DisplayObject) {
    this.draw(object);
  }

  draw(object: DisplayObject) {}
}

export default DrawingAction;
