import { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as userReducer from '../common/reducers/userReducer';
import useDialog from '../common/hooks/useDialog';
import AcceptTermsAndPrivacyDialog from '../containers/Modals/AcceptTermsAndPrivacyDialog';
import authService from '../common/services/auth.service';
import { setUser } from '../common/actions/userActions';

const enhancer = connect(() => {
  return (state) => ({
    user: userReducer.userSelector(state),
  });
});

const AcceptTermsAndPrivacy = (props) => {
  const { children, user } = props;
  const dispatch = useDispatch();

  const { open, onOpen, onClose } = useDialog();

  useEffect(() => {
    if (user.documentsToAccept.length) {
      onOpen();
    }
  }, [user, onOpen]);

  const onAgree = useCallback(async () => {
    const updatedUser = await authService.updateTermsAndPrivacyAccept();
    dispatch(setUser(updatedUser));
    onClose();
  }, [dispatch, onClose]);

  return (
    <>
      {children}
      <AcceptTermsAndPrivacyDialog opened={open} onAgree={onAgree} />
    </>
  );
};

export default enhancer(AcceptTermsAndPrivacy);
