import guid from '../utils/guid.util';

export default class Action {
  id = null;
  creatorId = null;
  receiverId = null;
  createdTime = null;
  drawingId = null;
  pageNumber = null;
  order = null;
  name = null;
  key = null;
  duration = 0;

  constructor(deserialized) {
    this.key = guid();
    if (deserialized) {
      this.id = deserialized.id;
      this.creatorId = deserialized.creatorID || deserialized.creatorId;
      this.receiverId = deserialized.receiverID || deserialized.receiverId;
      this.pageNumber = deserialized.pageNumber;
      this.order = deserialized.order;
      this.createdTime = deserialized.createdTime;
      this.duration = deserialized.duration || 0;
    } else {
      this.id = guid();
    }
  }

  getName() {
    if (!this.name) throw new Error('abstract not implemented');

    return this.name;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this.id = id;
  }

  getCreatorId() {
    return this.creatorId;
  }

  setCreatorId(creatorId) {
    this.creatorId = creatorId;
  }


  getReceiverId() {
    return this.receiverId;
  }

  setReceiverId(receiverId) {
    this.receiverId = receiverId;
  }

  getOrder() {
    return this.order;
  }

  setOrder(order) {
    this.order = order;
  }

  getCreatedTime() {
    return this.createdTime;
  }

  setCreatedTime(createdTime) {
    this.createdTime = createdTime;
  }

  getDrawingId() {
    return this.drawingId;
  }

  setDrawingId(drawingId) {
    this.drawingId = drawingId;
  }

  getPageNumber() {
    return this.pageNumber;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber;
  }

  getDuration() {
    return this.duration;
  }

  serialize() {
    return {
      '@': this.getName(),
      id: this.getId(),
      creatorId: this.getCreatorId(),
      pageNumber: this.getPageNumber(),
      duration: this.getDuration(),
    };
  }
}
