import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Box from '@mui/material/Box';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import Input from '../../atoms/Input';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import InputStatus from '../../atoms/Input/InputStatus';
import ContactsSelect from '../ContactsSelect';
import groupsService from '../../../common/services/groups.service';
import * as groupsActions from '../../../common/actions/groupsActions';
import Button from '../../atoms/Button';
import { ButtonVariantTypes } from '../../atoms/Button/buttonTypes';

type Props = {
  open: boolean;
  onClose: () => void;
};

type FieldValues = {
  groupName: string;
};

const CreateGroupDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
}) => {
  const { control, handleSubmit, setError, resetField, clearErrors } =
    useForm<FieldValues>({
      defaultValues: {
        groupName: '',
      },
      reValidateMode: 'onSubmit',
    });

  const [membersToAdd, setMembersToAdd] = useState<any[]>([]);

  const { isSubmitting } = useFormState({ control });

  const dispatch = useDispatch();
  const updateGroup = useCallback(
    (group: any) => dispatch(groupsActions.updateGroup(group)),
    [dispatch]
  );

  const onAddClick = useCallback(
    (members: any) => {
      setMembersToAdd((prevValue) => [...prevValue, ...members]);
    },
    [setMembersToAdd]
  );

  const onRemoveClick = useCallback(
    (member: any) => {
      setMembersToAdd((prevValue) =>
        prevValue.filter(
          (element) =>
            (element.id && member.id && element.id !== member.id) ||
            element.email !== member.email
        )
      );
    },
    [setMembersToAdd]
  );

  const onSubmit = useCallback(
    handleSubmit(async ({ groupName }) => {
      try {
        const emailToAdd = membersToAdd
          .filter((contact: any) => contact.email)
          .map((contact: any) => contact.email);
        const idsToAdd = membersToAdd
          .filter((contact: any) => contact.id)
          .map((contact: any) => contact.id);

        const group = await groupsService.createGroup(
          groupName,
          emailToAdd,
          idsToAdd
        );

        updateGroup(group);
        onClose();
        resetField('groupName');
        setMembersToAdd([]);
      } catch (error) {
        setError('groupName', {
          message: 'You already have a group with this name.',
        });
      }
    }),
    [
      membersToAdd,
      handleSubmit,
      onClose,
      updateGroup,
      setError,
      resetField,
      setMembersToAdd,
    ]
  );

  const onDialogClose = useCallback(() => {
    if (isSubmitting) return;
    onClose();
  }, [isSubmitting, onClose]);

  const onDeleteIconClick = useCallback(() => {
    if (isSubmitting) return;

    onClose();
  }, [isSubmitting, onClose]);

  const onEnter = useCallback(() => {
    resetField('groupName');
    clearErrors('groupName');
  }, [resetField, clearErrors]);

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <DialogTitle onClose={onDeleteIconClick}>Create new group</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={1}>
            <Controller
              name="groupName"
              control={control}
              rules={{
                required: 'Name is required',
                pattern: {
                  value: /^((?![/<>:"\\|?*]).)*$/g,
                  message:
                    'Name can\'t contain any of the following characters:[/<>:"|?*]',
                },
              }}
              render={({
                field: { onChange, value },
                formState: { errors: error },
              }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  size={InputSize.S}
                  variant={InputVariant.CONTAINED}
                  placeholder="Name"
                  status={error.groupName ? InputStatus.ERROR : undefined}
                  helperText={
                    error.groupName ? error.groupName.message : undefined
                  }
                />
              )}
            />
            <ContactsSelect
              source="createGroup"
              contacts={membersToAdd}
              onAddMembers={onAddClick}
              onRemoveMember={onRemoveClick}
              membersListHeight={252}
              InviteButton={(props) => (
                <Button {...props} variant={ButtonVariantTypes.OUTLINED}>
                  Add
                </Button>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Create
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateGroupDialog;
