import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { setShouldUpdateThumbnail } from '../actions/board/shouldUpdateThumbnailActions';
import sessionService from '../services/session.service';
import * as sessionActions from '../actions/session/sessionActions';

const useSession = () => {
  const dispatch = useDispatch();

  const finishSession = useCallback(
    async (sessionId: string) => {
      dispatch(setShouldUpdateThumbnail(true));
      const updatedSession = await sessionService.finishSession(sessionId);

      dispatch(sessionActions.updateSession(updatedSession));
      dispatch(push(`/group/${updatedSession.groupId || 'default'}/sessions`));
    },
    [dispatch]
  );

  return {
    finishSession,
  };
};

export default useSession;
