import { FC, ReactNode, useMemo } from 'react';
import { useController } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import PersonalInformationQuestionType from '../../../common/services/dto/auth/PersonalInformationQuestionType';

type Props = {
  label: string;
  inputType: PersonalInformationQuestionType;
  onChange: any;
  control: any;
  name: string;
};

const RequestPersonalInformationInputField: FC<
  React.PropsWithChildren<Props>
> = ({ control, name, inputType, children, label, onChange, ...props }) => {
  const {
    field: { onChange: onInputChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: '* Required',
    },
  });

  const isSingle = useMemo(
    () => inputType === PersonalInformationQuestionType.SingleChoice,
    [inputType]
  );

  const isMultiple = useMemo(
    () => inputType === PersonalInformationQuestionType.MultiChoice,
    [inputType]
  );

  const isSelect = useMemo(
    () => isSingle || isMultiple,
    [isSingle, isMultiple]
  );

  const selectProps = useMemo(
    () => ({
      multiple: isMultiple,
      renderValue: isMultiple
        ? (selected: any): ReactNode => selected.join(', ')
        : (selected: any): string => selected,
    }),
    [isMultiple]
  );

  const valueToShow = useMemo(() => {
    if (!value) return isMultiple ? [] : '';

    if (!value.isOther && value.userInput) return value.userInput;

    return value.isOther ? [...value.userChoices, 'Other'] : value.userChoices;
  }, [value]);

  return (
    <FormControl margin="normal" fullWidth size="medium">
      <FormControlLabel
        label={label}
        labelPlacement="top"
        control={
          <TextField
            {...props}
            value={valueToShow}
            onChange={(event) => {
              onChange(event.target.value, onInputChange);
            }}
            select={isSelect}
            SelectProps={selectProps}
            error={!!error}
            helperText={error?.message}
            variant="outlined"
            fullWidth
            size="small"
            inputProps={{
              maxLength: 100,
            }}
          >
            {children}
          </TextField>
        }
      />
    </FormControl>
  );
};

export default RequestPersonalInformationInputField;
