const CURSOR_PADDING_X = 3;
const CURSOR_PADDING_Y = 1;
const CURSOR_BOX_WIDTH = 16;
const CURSOR_BOX_HEIGHT = 16;

const CURSOR_RECT_HEIGHT_DIFF = 2;

const RECT_WIDTH_PADDING = 7;
const RECT_HEIGHT = 22;
const RECT_RADIUS = 11;
const RECT_BOTTOM_LEFT_RADIUS = 13;

const RECT_SHADOW_COLOR = 'rgba(0,0,0,0.25)';
const RECT_SHADOW_OFFSET_X = 1;
const RECT_SHADOW_OFFSET_Y = 1;
const RECT_SHADOW_BLUR = 3;

const TEXT_FONT_SIZE = 12;
const TEXT_STYLE = 'normal';
const TEXT_WEIGHT = 600;
const TEXT_FONT = 'Barlow';
const TEXT_COLOR = '#354153';
const TEXT_ALIGN = 'left';
const TEXT_TOP_PADDING = 5;

const drawNameRectangle = (ctx, x, y, options) => {
  const {
    width,
    height,
    radius,
    bottomLeftRadius,
    shadowColor,
    shadowOffsetX,
    shadowOffsetY,
    shadowBlur,
    color,
  } = options;

  const dx = x + width;
  const dy = y + height;

  ctx.shadowColor = shadowColor;
  ctx.shadowOffsetX = shadowOffsetX;
  ctx.shadowOffsetY = shadowOffsetY;
  ctx.shadowBlur = shadowBlur;

  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.moveTo(x, y);
  ctx.lineTo(dx - radius, y);
  ctx.quadraticCurveTo(dx, y, dx, y + radius);
  ctx.lineTo(dx, dy - radius);
  ctx.quadraticCurveTo(dx, dy, dx - radius, dy);
  ctx.lineTo(x + bottomLeftRadius, dy);
  ctx.quadraticCurveTo(x, dy, x, dy - bottomLeftRadius);
  ctx.lineTo(x, y);
  ctx.fill();
};

const cursorDrawer = (ctx, item, scale) => {
  const {
    cursorPosition,
    cursorImage,
    participant,
  } = item;

  const {
    name,
    cursorColor: color,
  } = participant;

  if (!cursorImage) return;

  // Draw cursor
  const cursorX = cursorPosition.x - CURSOR_PADDING_X / scale;
  const cursorY = cursorPosition.y - CURSOR_PADDING_Y / scale;

  const iw = cursorImage.naturalWidth / scale;
  const ih = cursorImage.naturalHeight / scale;
  ctx.drawImage(cursorImage, cursorX, cursorY, iw, ih);

  // Measure text width and height
  const fontSize = TEXT_FONT_SIZE / scale;
  ctx.font = `${TEXT_STYLE} ${TEXT_WEIGHT} ${fontSize}px ${TEXT_FONT}`;
  const { width: textWidth } = ctx.measureText(name);

  // Draw name box
  const rectangleX = cursorX + CURSOR_BOX_WIDTH / scale;
  const rectangleY = cursorY + (CURSOR_BOX_HEIGHT + CURSOR_RECT_HEIGHT_DIFF) / scale;

  const width = textWidth + (2 * RECT_WIDTH_PADDING) / scale;
  const height = RECT_HEIGHT / scale;
  const radius = RECT_RADIUS / scale;
  const bottomLeftRadius = RECT_BOTTOM_LEFT_RADIUS / scale;

  drawNameRectangle(ctx, rectangleX, rectangleY, {
    shadowColor: RECT_SHADOW_COLOR,
    shadowOffsetX: RECT_SHADOW_OFFSET_X,
    shadowOffsetY: RECT_SHADOW_OFFSET_Y,
    shadowBlur: RECT_SHADOW_BLUR,
    width,
    height,
    radius,
    bottomLeftRadius,
    color,
  });

  // Draw text
  const textX = rectangleX + RECT_WIDTH_PADDING / scale;
  const textY = rectangleY + TEXT_TOP_PADDING / scale;

  ctx.textAlign = TEXT_ALIGN;
  ctx.fillStyle = TEXT_COLOR;
  ctx.textBaseline = 'top';
  ctx.fillText(name, textX, textY);
};

export default cursorDrawer;
