import { FC } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { NotificationButtonProps } from '../../atoms/NotificationButton/notificationButtonTypes';

export enum AlertColor {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  PREMIUM = 'premium',
}

export type NotificationProps = {
  color: AlertColor;
  title: string;
  message?: string;
  Icon?: FC<React.PropsWithChildren<SvgIconProps>>;
  actionText?: string;
  ActionButton?: FC<React.PropsWithChildren<NotificationButtonProps>>;
};
