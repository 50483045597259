import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';
import board from './board';
import session from './session/index';
import ui from './uiReducer';
import auth from './authReducer';
import user from './userReducer';
import contacts from './contactsReducer';
import groups from './groupsReducer';
import sessions from './sessionsReducer';
import recordings from './recordingsReducer';
import images from './imagesReducer';
import featureTours from './featureToursReducer';
import configuration from './configurationReducer';
import { setStore as setStoreInFirebase } from '../services/firebaseService';
import { SIGN_OUT_SUCCESS } from '../actions/authActions';
import dashboardReducer from './dashboardReducer';

export default (history) => {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history,
      //other options if needed
    });
  const appReducer = combineReducers({
    board,
    session,
    ui,
    auth,
    user,
    contacts,
    groups,
    sessions,
    recordings,
    images,
    configuration,
    featureTours,
    router: routerReducer,
    dashboard: dashboardReducer,
  });
  const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT_SUCCESS) {
      const {
        router,
        ui: { width, height, dpi },
      } = state;

      state = {
        router,
        ui: {
          messagesSideBarOpened: false,
          width,
          height,
          dpi,
          collaborationBar: {
            width: 0,
            height: 0,
          },
        },
      };
    }

    return appReducer(state, action);
  };

  const reduxDevtoolsCompose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
    });

  const composeEnhancers = reduxDevtoolsCompose || compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(routerMiddleware, thunk))
  );

  setStoreInFirebase(store);

  return {
    store,
    history: createReduxHistory(store),
  };
};
