import React, { useCallback, useContext, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import CanvasContext from '../../board/Contexts/CanvasContext';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import Button from '../../../ui/atoms/Button';
import analyticsService from '../../../common/services/analytics.service';
import MenuItem from '../../../ui/atoms/MenuItem';
import Menu from '../../../ui/molecules/Menu';
import ChevronUpIcon from '../../../ui/atoms/Icons/ChevronUpIcon';
import ChevronDownIcon from '../../../ui/atoms/Icons/ChevronDownIcon';
import Tooltip from '../../../ui/atoms/Tooltip';

const zoomOptions = [1, 1.25, 1.5, 2, 4, 6, 8];

const ZoomTool = () => {
  const canvasContext = useContext(CanvasContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const availableZoomOptions = useMemo(
    () => zoomOptions.filter((option) => option !== canvasContext.zoom),
    [zoomOptions, canvasContext.zoom]
  );

  const onToggleMiniMap = useCallback(() => {
    canvasContext.setMinimapOpen(!canvasContext.minimapOpen);

    if (!canvasContext.minimapOpen) {
      analyticsService.event('Minimap Open Button Click');

      return;
    }

    analyticsService.event('Minimap Close Button Click');
  }, [canvasContext.minimapOpen]);

  const onChangeZoomOption = useCallback(
    (option: number) => {
      canvasContext.onZoom({
        zoomOption: option,
      });
      onMenuClose();
    },
    [onMenuClose]
  );

  const onZoomOptionsOpen = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  return (
    <Box display="flex" justifyContent="end">
      <Box
        sx={() => ({
          zIndex: 5,
          display: 'flex',
          width: 99,
        })}
      >
        <Tooltip title="Zoom optins" placement="top">
          <Box>
            <Button
              onClick={onZoomOptionsOpen}
              variant={ButtonVariantTypes.TEXT}
              size={ButtonSizeTypes.S}
              endIcon={isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {(canvasContext.zoom * 100).toFixed(0)}%
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={onMenuClose}
        listItemWidth={100}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {availableZoomOptions.map((option) => (
          <MenuItem
            key={option}
            title={`${(option * 100).toFixed(0)}%`}
            onClick={() => onChangeZoomOption(option)}
            onSelect={() => onChangeZoomOption(option)}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default ZoomTool;
