import { createSelector } from 'reselect';
import * as Sentry from '@sentry/react';

import {
  UPDATE_PROFILE_IMAGE_REQUEST,
  GET_ACCOUNT_DATA_REQUEST,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  SET_USER,
  SET_VERIFIED,
  SUBMIT_PERSONAL_INFORMATION_ANSWERS,
} from '../actions/userActions';
import analyticsService from '../services/analytics.service';

const defaultSupportEmail = 'support+web@liveboard.online';

const initialState = {
  updateUser: {
    errorMessage: '',
    isFetching: false,
  },
  updateProfileImage: {
    errorMessage: '',
    isFetching: false,
  },
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_DATA_REQUEST:
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          isFetching: true,
        },
      };
    case UPDATE_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        updateProfileImage: {
          ...state.updateProfileImage,
          isFetching: true,
        },
      };
    case GET_ACCOUNT_DATA_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case SET_USER: {
      if (action.user) {
        analyticsService.setUserId(action.user.id);
        analyticsService.identifyUser(action.user);
        Sentry.setUser({ id: action.user.id, email: action.user.email });
      } else {
        analyticsService.setUserId('');
        Sentry.setUser(null);
      }
      return {
        ...state,
        updateProfileImage: {
          ...state.updateProfileImage,
          isFetching: false,
        },
        updateUser: {
          ...state.updateUser,
          isFetching: false,
        },
        data: action.user,
      };
    }
    case GET_ACCOUNT_DATA_FAILURE:
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateProfileImage: {
          ...state.updateProfileImage,
          isFetching: false,
        },
        updateUser: {
          ...state.updateUser,
          isFetching: false,
        },
      };
    case SET_VERIFIED: {
      const {
        payload: { isVerified },
      } = action;
      return {
        ...state,
        data: {
          ...state.data,
          verified: isVerified,
        },
      };
    }
    case SUBMIT_PERSONAL_INFORMATION_ANSWERS: {
      return {
        ...state,
        data: {
          ...state.data,
          shouldRequestPersonalInformation: false,
        },
      };
    }
    default:
      return state;
  }
};

const userSelector = (state) => state.user.data;
const userAccountTypeSelector = (state) =>
  state.user.data && state.user.data.accountType;
const userIdSelector = (state) => state.user.data?.id;
const shouldRequestPersonalInformationSelector = (state) =>
  state.user.data.shouldRequestPersonalInformation;

const userIsVerified = createSelector(
  userSelector,
  (user) => user && user.verified
);

const userEmailSelector = createSelector(
  userSelector,
  (user) => user && user.email
);

const supportEmailSelector = createSelector(userSelector, (user) =>
  user && user.supportEmail ? user.supportEmail : defaultSupportEmail
);

const userSubscriptionSelector = createSelector(
  userSelector,
  (user) => user && user.subscription
);

const userPlanSelector = createSelector(
  userSelector,
  (user) => user && user.subscription && user.subscription.plan
);

export {
  userSelector,
  userIdSelector,
  shouldRequestPersonalInformationSelector,
  userIsVerified,
  userEmailSelector,
  supportEmailSelector,
  userSubscriptionSelector,
  userPlanSelector,
  userAccountTypeSelector,
};
