import { DisplayObject, Sprite } from 'pixi.js';
import { cloneDeep } from 'lodash';
import DrawingAction from './drawingAction';
import Point from '../types/Point';
import OldFormulaAction from '../../../common/drawingActions/formula.drawing.action';
import guid from '../../../common/utils/guid.util';
import ActionName from '../types/ActionName';

class Formula extends DrawingAction {
  startPoint: Point;

  endPoint: Point;

  image: HTMLImageElement | null = null;

  tex: string;

  constructor(action?: OldFormulaAction) {
    super(action);

    this.name = ActionName.FORMULA;

    if (action) {
      this.startPoint = {
        x: action.startPoint?.x,
        y: action.startPoint?.y,
      };
      this.endPoint = {
        x: action.endPoint?.x,
        y: action.endPoint?.y,
      };

      this.tex = action.tex;
      this.image = action.image;
    }
  }

  toDto(): OldFormulaAction {
    const dto = new OldFormulaAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldFormulaAction) {
    super.setFields(action);
    action.name = ActionName.FORMULA;
    action.startPoint = this.startPoint;
    action.endPoint = this.endPoint;
    action.width = Math.abs(this.endPoint.x - this.startPoint.x);
    action.height = Math.abs(this.endPoint.y - this.startPoint.y);
    action.tex = this.tex;
    action.image = this.image;
  }

  requiresUpdate(action: Formula): boolean {
    let result = super.requiresUpdate(action);

    if (action.image && action.image !== this.image) {
      result = true;
    }

    return result;
  }

  createMoveAction(newPos: Point): Formula {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;

    const center = {
      x: (this.startPoint.x + this.endPoint.x) / 2,
      y: (this.startPoint.y + this.endPoint.y) / 2,
    };

    const delta = {
      x: newPos.x - center.x,
      y: newPos.y - center.y,
    };

    newAction.startPoint = {
      x: this.startPoint.x + delta.x,
      y: this.startPoint.y + delta.y,
    };
    newAction.endPoint = {
      x: this.endPoint.x + delta.x,
      y: this.endPoint.y + delta.y,
    };

    return newAction;
  }

  draw(object: DisplayObject) {
    const formulaObject = object as Sprite;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    formulaObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );
    formulaObject.anchor.set(0.5);
    formulaObject.width = width;
    formulaObject.height = height;
  }
}

export default Formula;
