import { FC } from 'react';
import Box from '@mui/material/Box';
import SupportButton from '../../atoms/SupportButton';

type SupportButtonWrapperProps = {
  visible: boolean;
};

const SupportButtonWrapper: FC<
  React.PropsWithChildren<SupportButtonWrapperProps>
> = ({ visible }) => (
  <Box
    sx={{
      position: 'sticky',
      top: 'calc(50% - 24px)',
      width: '48px',
      height: '48px',
      pointerEvents: visible ? 'initial' : 'none',
      opacity: visible ? 1 : 0,
      transition: 'opacity 0.2s linear',
      marginRight: '30px',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        right: 0,
      }}
    >
      <SupportButton />
    </Box>
  </Box>
);

export default SupportButtonWrapper;
