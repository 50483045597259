import { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/base';
import { styled, Theme } from '@mui/material/styles';
import { inputClasses } from '@mui/material/Input';
import { svgIconClasses } from '@mui/material/SvgIcon';
import InputSize from '../Input/InputSize';
import InputVariant from '../Input/InputVariant';
import InputStatus from '../Input/InputStatus';

const generateTextAreaSizeStyles = (size: InputSize) => {
  switch (size) {
    case InputSize.L:
      return {
        padding: '12.5px 16px',
        borderRadius: '18px',
        fontSize: '1.125rem',
      }

    case InputSize.M:
      return {
        padding: '8.5px 16px',
        borderRadius: '16px',
        fontSize: '1.125rem',
      }

    case InputSize.S:
      return {
        padding: '8px 16px',
        borderRadius: '14px',
        fontSize: '1rem',
      }

    default: return {}
  }
};

const generateInputStyle = (
  theme: Theme,
  variant: InputVariant,
  status: InputStatus | undefined,
  isHovered: boolean,
  isFocused: boolean,
  disabled: boolean,
) => {
  switch (variant) {
    case InputVariant.CONTAINED:
      return {
        backgroundColor: theme.background.bg4,
        borderColor: status ? theme.palette[status].main : theme.background.bg4,
        color: theme.text.t8,
        fontWeight: 500,

        ...(isHovered ? {
          backgroundColor: theme.background.bg5,
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
        } : {}),

        ...(isFocused ? {
          backgroundColor: theme.background.bg5,
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
        } : {}),
        ...(disabled ? {
          '&::-webkit-input-placeholder': {
            color: theme.text.t3,
          },
          ...(isHovered ? {
            backgroundColor: theme.background.bg4,
            borderColor: status ? theme.palette[status].main : theme.background.bg4,
          } : {}),
        } : {}),
      };

    case InputVariant.OUTLINED:
      return {
        backgroundColor: 'inherit',
        borderColor: status ? theme.palette[status].main : theme.background.bg6,
        color: theme.text.t8,
        fontWeight: 500,

        '&:hover': {
          borderColor: status ? theme.palette[status].main : theme.palette.primary.alpha50,
        },
        [`& .${inputClasses.focused}`]: {
          borderColor: status ? theme.palette[status].main : theme.palette.primary.alpha50,
        },
        [`& .${inputClasses.disabled}`]: {
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
          [` .${inputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t3,
          },
          '&:hover': {
            borderColor: status ? theme.palette[status].main : theme.background.bg5,
          },
          [`.${svgIconClasses.root}`]: {
            color: `${theme.text.t3} !important`,
          },
        },
      };

    default: return {};
  }
};

type StyledTextAreaProps = {
  size: InputSize;
  variant: InputVariant;
  status?: InputStatus;
  isHovered: boolean;
  isFocused: boolean;
  disabled: boolean;
  resize: 'none' | 'vertical' | 'horizontal';
  height: number | string;
};

const StyledTextArea = styled(TextareaAutosize, {
  shouldForwardProp: prop => (
    prop !== 'size'
    && prop !== 'variant'
    && prop !== 'status'
    && prop !== 'isHovered'
    && prop !== 'isFocused'
    && prop !== 'disabled'
    && prop !== 'resize'
  ),
})<StyledTextAreaProps>(({
  theme,
  size,
  variant,
  status,
  isHovered,
  isFocused,
  disabled,
  resize,
  height,
}) => ({
  backgroundColor: theme.background.bg4,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.background.bg4,
  color: theme.text.t8,
  fontWeight: 500,
  width: '100%',
  height: height || '100px',
  ...(height ? {
    // Todo: remove important when it is possible
    overflow: 'auto !important',
  } : {}),

  ...(generateTextAreaSizeStyles(size)),
  ...(generateInputStyle(theme, variant, status, isHovered, isFocused, disabled)),
  resize,
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background.bg7,
    borderRadius: '16px',
  },
}));

type CustomTextareaAutosizeProps = TextareaAutosizeProps & {
  size: InputSize;
  variant: InputVariant;
  status?: InputStatus;
  disabled?: boolean;
  resize: 'none' | 'vertical' | 'horizontal';
  height: number | string;
};

const TextArea = forwardRef(({
  value,
  onChange,
  placeholder,
  size,
  disabled = false,
  variant,
  status,
  resize,
  height,
  ...props
}: CustomTextareaAutosizeProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const onToggleHover = useCallback(() => {
    setIsHovered(prevState => !prevState);
  }, [setIsHovered]);

  const onToggleFocused = useCallback(() => {
    setIsFocused(prevState => !prevState);
  }, [setIsFocused]);

  return (
    <StyledTextArea
      ref={ref}
      size={size}
      value={value}
      onChange={onChange}
      status={status}
      variant={variant}
      placeholder={placeholder}
      isHovered={isHovered}
      isFocused={isFocused}
      disabled={disabled}
      onFocus={onToggleFocused}
      onBlur={onToggleFocused}
      onMouseEnter={onToggleHover}
      onMouseLeave={onToggleHover}
      resize={resize}
      height={height}
      {...props}
    />
  )
});

export default TextArea;
