import { useEffect, useMemo } from 'react';
import tinykeys, { KeyBindingMap } from 'tinykeys';
import { KeyMap, KeyMapOptions } from '../../config/hotkeys';
import domUtil from '../utils/dom.util';

type Handler = (event: KeyboardEvent) => void;
type Handlers = {
  [handler: string]: Handler;
};

const isEventTargetInput = (eventTarget: Element | null) => {
  if (eventTarget === null) return false;

  const eventTargetTagName = (eventTarget as HTMLElement).tagName.toLowerCase();
  const isActiveElementDialog = domUtil.checkIsActiveElementDialog();

  if (isActiveElementDialog) return true;

  return ['input', 'select', 'textarea'].includes(eventTargetTagName);
};

const createKeyBinding = (
  options: KeyMapOptions,
  handler: Handler
): KeyBindingMap => {
  const filteredHandler = (event: KeyboardEvent) => {
    if (!isEventTargetInput(event.target as Element)) {
      handler(event);
    }
  };

  if (Array.isArray(options.sequence)) {
    return options.sequence.reduce<KeyBindingMap>(
      (mem, key) => ({
        ...mem,
        [key]: filteredHandler,
      }),
      {}
    );
  }

  return { [options.sequence]: filteredHandler };
};

const useKeyboardShortcuts = (keyMap: KeyMap, handlers: Handlers) => {
  const keyBindings = useMemo<KeyBindingMap>(
    () =>
      Object.entries(keyMap).reduce((mem, [action, options]) => {
        const newBindings = createKeyBinding(options, handlers[action]);
        return {
          ...mem,
          ...newBindings,
        };
      }, {}),
    [keyMap, handlers]
  );

  useEffect(() => {
    const unsubscribe = tinykeys(window, keyBindings);
    return () => {
      unsubscribe();
    };
  }, [keyBindings]);
};

export default useKeyboardShortcuts;
