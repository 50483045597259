import { FC } from 'react';
import Button, { buttonClasses } from '@mui/material/Button';
import {
  NotificationButtonProps,
  NotificationButtonSize,
} from './notificationButtonTypes';

const NotificationButton: FC<
  React.PropsWithChildren<NotificationButtonProps>
> = ({
  icon,
  hasIcon = false,
  color,
  size = NotificationButtonSize.XS,
  sx,
  children,
  ...props
}) => (
  <Button
    {...props}
    variant="primary"
    color={color}
    size={size}
    startIcon={hasIcon && icon}
    sx={{
      minWidth: 73,
      [`& .${buttonClasses.startIcon}`]: {
        marginRight: 0.5,
        marginLeft: 0,
      },
      ...sx,
    }}
  >
    {children}
  </Button>
);

export default NotificationButton;
