import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SectionCard } from '../../../../components/SectionCard';
import * as userReducer from '../../../../../../common/reducers/userReducer';
import { UpdateProfileImage } from './UpdateProfileImage';
import { updateUserName } from '../../../../../../common/actions/userActions';
import InputVariant from '../../../../../../ui/atoms/Input/InputVariant';
import InputSize from '../../../../../../ui/atoms/Input/InputSize';
import Input from '../../../../../../ui/atoms/Input';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import Button from '../../../../../../ui/atoms/Button';
import InputStatus from '../../../../../../ui/atoms/Input/InputStatus';

const userProfileSchema = z.object({
  name: z.string().min(1, { message: 'Name cannot be empty' }),
});

type UserProfileType = z.infer<typeof userProfileSchema>;

export const UpdateUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(userReducer.userSelector);

  const editUserName = useCallback(
    (name: any) => dispatch(updateUserName(name)),
    [dispatch]
  );

  const { control, handleSubmit } = useForm<UserProfileType>({
    resolver: zodResolver(userProfileSchema),
    defaultValues: {
      name: user.name,
    },
  });

  const onSubmit = useCallback(
    handleSubmit(async ({ name }) => {
      editUserName(name.trim());

      enqueueSnackbar('Profile has been saved', {
        variant: 'success',
      });
    }),
    [handleSubmit, enqueueSnackbar, editUserName]
  );

  return (
    <SectionCard>
      <Stack direction="column">
        <UpdateProfileImage />
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
          component="form"
          onSubmit={onSubmit}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: '16px',
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Box maxWidth="320px" flex={1}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    type="text"
                    variant={InputVariant.CONTAINED}
                    size={InputSize.S}
                    label="Name"
                    helperText={fieldState.error?.message}
                    status={fieldState.error ? InputStatus.ERROR : undefined}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
            <Box maxWidth="320px" flex={1}>
              <Input
                value={user.email}
                type="text"
                variant={InputVariant.CONTAINED}
                size={InputSize.S}
                label="Email"
                disabled
              />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              paddingTop: '20px',
              [theme.breakpoints.up('sm')]: {
                paddingTop: '25px',
              },
            })}
          >
            <Button
              type="submit"
              variant={ButtonVariantTypes.PRIMARY}
              size={ButtonSizeTypes.S}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Stack>
    </SectionCard>
  );
};
