import { useSelector } from 'react-redux';
import { dimensionsSelector } from '../reducers/session/sessionReducer';
import { getActivePage } from '../reducers/board/activePageReducer';
import { useMemo } from 'react';

const HDScreenSizes = {
  width: 1280,
  height: 720,
};

const useBoardRatio = () => {
  const currentPage = useSelector(getActivePage);
  const displayMetrics = useSelector(dimensionsSelector);

  const boardSizes = useMemo(
    () =>
      currentPage.width && currentPage.height
        ? {
            width: currentPage.width,
            height: currentPage.height,
          }
        : {
            width: displayMetrics.width,
            height: displayMetrics.height,
          },
    [currentPage, displayMetrics]
  );

  const ratio = useMemo(
    () =>
      Math.sqrt(
        (boardSizes.width * boardSizes.height) /
          (HDScreenSizes.width * HDScreenSizes.height)
      ),
    [boardSizes]
  );

  return ratio;
};

export default useBoardRatio;
