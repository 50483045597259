const VERT_SRC = `#version 300 es

precision mediump float;
in vec2 aVertexPosition;

uniform vec2 resolution; // TODO Should be a UBO
uniform mat3 translationMatrix;
uniform float scale;

out vec2 worldPos;

void main() {
  worldPos = vec2(
    -translationMatrix[2][0] / scale + resolution.x / scale / 2.0 + aVertexPosition.x / scale * resolution.x / 2.0,
    -translationMatrix[2][1] / scale + resolution.y / scale / 2.0 - aVertexPosition.y / scale * resolution.y / 2.0
  );

  gl_Position = vec4(aVertexPosition, 0.0, 1.0);
}`;

export default VERT_SRC;
