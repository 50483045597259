import { createContext } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { asyncNoop } from '../../../../common/constants';

type DeviceContextProps = {
  audioSources: MediaDeviceInfo[];
  listAudioDevices: () => Promise<void>;
  videoSources: MediaDeviceInfo[];
  listVideoDevices: () => Promise<void>;
  selectedAudioOption: string;
  selectedVideoOption: string;
  onChangeAudioOption: (option: SelectChangeEvent) => void;
  onChangeVideoOption: (option: SelectChangeEvent) => void;
};

const defaultValue = {
  audioSources: [],
  listAudioDevices: asyncNoop,
  videoSources: [],
  listVideoDevices: asyncNoop,
  selectedAudioOption: '',
  selectedVideoOption: '',
  onChangeAudioOption: (option: SelectChangeEvent) => {},
  onChangeVideoOption: (option: SelectChangeEvent) => {},
};

const DeviceContext = createContext<DeviceContextProps>(defaultValue);

export default DeviceContext;
