import { createContext } from 'react';

export type AssessmentPaperSidebarContextProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const defaultValue: AssessmentPaperSidebarContextProps = {
  open: false,
  onOpen: () => {},
  onClose: () => {},
};

export const AssessmentPaperSidebarContext =
  createContext<AssessmentPaperSidebarContextProps>(defaultValue);
