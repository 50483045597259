import { FC, ReactNode, useCallback, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '../../ui/molecules/Menu';
import MenuItem from '../../ui/atoms/MenuItem';
import ViewDashboardOutlineIcon from '../../ui/atoms/Icons/ViewDashboardOutlineIcon';
import Typography from '../../ui/atoms/Typography';
import ViewGridOutlineIcon from '../../ui/atoms/Icons/ViewGridOutlineIcon';
import { ButtonSizeTypes } from '../../ui/atoms/Button/buttonTypes';
import MinimizeIcon from '../../ui/atoms/Icons/MinimizeIcon';

type Props = {
  onViewSpeakerClick: () => void;
  onViewGridClick: () => void;
  onViewMinimizeClick: () => void;
  stageLayout: 'speaker' | 'grid' | 'minimize';
};

type ButtonComponentProps = {
  minimized: boolean;
  children: ReactNode;
} & ButtonProps;

const ButtonComponent = ({
  minimized,
  children,
  ...props
}: ButtonComponentProps) => {
  if (minimized) {
    return <IconButton {...props}>{children}</IconButton>;
  }

  return <Button {...props}>{children}</Button>;
};

const ViewOptions: FC<React.PropsWithChildren<Props>> = ({
  onViewSpeakerClick,
  onViewGridClick,
  onViewMinimizeClick,
  stageLayout,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onMoreOptionClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSpeakerViewClick = useCallback(() => {
    onViewSpeakerClick();
    setAnchorEl(null);
  }, [onViewSpeakerClick, setAnchorEl]);

  const onGridViewClick = useCallback(() => {
    onViewGridClick();
    setAnchorEl(null);
  }, [onViewGridClick, setAnchorEl]);

  const onMinimizeViewClick = useCallback(() => {
    onViewMinimizeClick();
    setAnchorEl(null);
  }, [onViewMinimizeClick, setAnchorEl]);

  const minimized = stageLayout === 'minimize';

  return (
    <>
      <ButtonComponent
        minimized={minimized}
        onClick={onMoreOptionClick}
        size={ButtonSizeTypes.XS}
        sx={{
          display: 'flex',
          gap: 0.5,
          borderRadius: minimized ? 8 : 2,
          padding: '4px 8px',
          border: 'unset',
          ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            border: 'unset',
          },
          ...(open
            ? {
                backgroundColor: 'rgba(0, 0, 0, 0.55)',
                border: 'unset',
              }
            : {
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
              }),
        }}
      >
        {stageLayout === 'speaker' && (
          <ViewDashboardOutlineIcon
            sx={(theme) => ({
              color: theme.text.t2,
              width: 18,
              height: 18,
            })}
          />
        )}
        {stageLayout === 'grid' && (
          <ViewGridOutlineIcon
            sx={(theme) => ({
              color: theme.text.t2,
              width: 18,
              height: 18,
            })}
          />
        )}
        {stageLayout === 'minimize' && (
          <MinimizeIcon
            sx={(theme) => ({
              color: theme.text.t2,
              width: 18,
              height: 18,
            })}
          />
        )}
        {stageLayout !== 'minimize' && (
          <Typography
            variant="p5"
            sx={(theme) => ({
              color: theme.text.t2,
            })}
          >
            View
          </Typography>
        )}
      </ButtonComponent>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
        listItemWidth={180}
      >
        <MenuItem
          title="Speaker view"
          startIcon={<ViewDashboardOutlineIcon />}
          onSelect={onSpeakerViewClick}
          selected={stageLayout === 'speaker'}
        />
        <MenuItem
          title="Grid view"
          startIcon={<ViewGridOutlineIcon />}
          onSelect={onGridViewClick}
          selected={stageLayout === 'grid'}
        />
        <MenuItem
          title="Minimized"
          startIcon={<MinimizeIcon />}
          onSelect={onMinimizeViewClick}
          selected={stageLayout === 'minimize'}
        />
      </Menu>
    </>
  );
};

export default ViewOptions;
