import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AssessmentPaperSidebarContext } from '../AssessmentPaperSidebarContext';
import useDialog from '../../../../../common/hooks/useDialog';
import { AssessmentPaperSidebarContextProps } from '../AssessmentPaperSidebarContext/AssessmentPaperSidebarContext';

export const AssessmentPaperSidebarProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const history = useHistory();
  const assessmentSidebar = useDialog();

  const onOpenQuizSidebar = useCallback(() => {
    assessmentSidebar.onOpen();
  }, [assessmentSidebar.onOpen]);

  const onCloseQuizSidebar = useCallback(() => {
    assessmentSidebar.onClose();
    history.push('/');
  }, [assessmentSidebar.onClose]);

  const value = useMemo<AssessmentPaperSidebarContextProps>(
    () => ({
      open: assessmentSidebar.open,
      onOpen: onOpenQuizSidebar,
      onClose: onCloseQuizSidebar,
    }),
    [assessmentSidebar.open, onOpenQuizSidebar, onCloseQuizSidebar]
  );

  return (
    <AssessmentPaperSidebarContext.Provider value={value}>
      {children}
    </AssessmentPaperSidebarContext.Provider>
  );
};
