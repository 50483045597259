export enum TypographyType {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  s1 = 's1',
  s2 = 's2',
  s3 = 's3',
  s4 = 's4',
  p1 = 'p1',
  p2 = 'p2',
  p3 = 'p3',
  p4 = 'p4',
  p5 = 'p5',
  p6 = 'p6',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  b5 = 'b5',
  b6 = 'b6',
  l1 = 'l1',
  l2 = 'l2',
  l3 = 'l3',
}

export interface TypographyStyle {
  fontSize: string,
  lineHeight: string,
  fontWeight: number,
  textDecoration?: string,
}
