const getPageConfigs = (page) => {
  const pageConfig = {
    width: page.width,
    height: page.height,
  };

  const imageConfig = {
    x: 0,
    y: 0,
    width: page.width,
    height: page.height,
  };

  return {
    page: pageConfig,
    image: imageConfig,
    imageData: page.url,
  };
};

export {
  getPageConfigs,
};
