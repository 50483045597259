import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { first, take } from 'lodash';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '../../atoms/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import blobUtils from '../../../common/utils/blob.utils';
import fireBaseService from '../../../common/services/firebaseService';
import sessionService from '../../../common/services/session.service';
import analyticsService from '../../../common/services/analytics.service';
import * as uiReducer from '../../../common/reducers/uiReducer';
import * as planPermissionSelectors from '../../../common/selectors/planPermissionSelectors';
import * as userReducer from '../../../common/reducers/userReducer';
import { addSessionInList } from '../../../common/actions/sessionsActions';
import * as configurationActions from '../../../common/actions/configurationActions';
import useDialog from '../../../common/hooks/useDialog';
import SessionDto from '../../../common/services/dto/session/SessionDto';
import { getPageConfigs } from '../../../containers/Modals/PdfImportModal/pageConfigCalculator';
import BorderLinearProgress from '../../../containers/Modals/PdfImportModal/BorderLinearProgress';
import PlanDialog from '../PlanDialog';
import FirebaseConfig from '../../../common/services/types/session/FirebaseConfig';
import { CreateBoardFromPdfProps } from './createBoardFromPdfProps';

const pagesCountForFreeUser = 1;

const CreateBoardFromPdfDialog: FC<
  React.PropsWithChildren<CreateBoardFromPdfProps>
> = ({ open, pages, handleSuccess, handleClose, name }) => {
  const [processing, setProcessing] = useState(false);
  const [processedCount, setProcessedCount] = useState(0);
  const [error, setError] = useState('');
  const [drawing, setDrawing] = useState<SessionDto | null>(null);
  const [config, setConfig] = useState<FirebaseConfig | null>(null);
  const [isUpgraded, setIsUpgraded] = useState(false);
  const [areAllPagesUploaded, setAreAllPagesUploaded] = useState(false);
  const hasPdfImportPermission = useSelector(
    planPermissionSelectors.hasPdfImportPermissionSelector
  );
  const user = useSelector(userReducer.userSelector);
  const dpi = useSelector(uiReducer.dpiSelector);

  const params = useParams<{ groupId: string }>();
  const { groupId = 'default' } = params;

  const dispatch = useDispatch();

  const {
    open: plansModalOpen,
    onOpen: onPlansModalOpen,
    onClose: onPlansModalClose,
  } = useDialog();

  const onPlansModalSuccess = useCallback(() => {
    setIsUpgraded(true);
  }, [setIsUpgraded]);

  const openBoard = useCallback(() => {
    if (drawing && drawing.id) {
      handleSuccess(drawing.id);
    }
  }, [handleSuccess, drawing]);

  const uploadImages = useCallback(
    async (importData: any, drawingId: any) => {
      for await (const { image, imageData } of importData) {
        const blob = blobUtils.dataUriToBlob(imageData);
        image.url = await sessionService.uploadSessionImage(drawingId, blob);
        setProcessedCount((progress) => progress + 1);
      }
    },
    [setProcessedCount]
  );

  useEffect(() => {
    const createBoard = async () => {
      try {
        setProcessing(true);
        setProcessedCount(0);

        const pageCount = !hasPdfImportPermission
          ? pagesCountForFreeUser
          : pages.length;
        const pagesToImport = take(pages, pageCount);
        if (pagesToImport.length === 0) {
          setAreAllPagesUploaded(true);
          setProcessing(false);
          return;
        }
        const grId = groupId && groupId === 'default' ? null : groupId;
        const sessionName = name.split('.').slice(0, -1).join('.');

        const pagesData = await Promise.all(pagesToImport);
        const importData = pagesData.map((page) => getPageConfigs(page));
        const { page: firstPage }: any = first(importData);
        const session = await sessionService.createSession(
          firstPage.width,
          firstPage.height,
          dpi,
          grId,
          sessionName
        );

        await uploadImages(importData, session.id);

        const firebaseConfig = await sessionService.getFirebaseConfig(
          session.id
        );
        setConfig(firebaseConfig);
        await fireBaseService.initializeFromImages(
          session.id,
          importData,
          firebaseConfig
        );

        if (pageCount === pages.length) {
          setAreAllPagesUploaded(true);
        }

        dispatch(addSessionInList(session, groupId));
        dispatch(configurationActions.createBoard());

        setDrawing(session);
        setProcessing(false);

        analyticsService.event('Board Created From PDF', {
          defaultGroup: groupId && groupId === 'default',
          pages: pageCount,
        });
      } catch (e) {
        if (e.code === '130005') {
          setError('You have reached maximum boards count of your plan.');
        } else {
          setError(e.message);
        }
        console.log(e);
      }
    };

    if (open && pages.length) {
      createBoard();
    }
  }, [dispatch, pages, open]);

  useEffect(() => {
    const uploadRemainingPDFPages = async () => {
      try {
        if (!drawing) {
          // Todo: change error message
          throw new Error('Session id does not exist');
        }

        setProcessing(true);

        const pageCount = !hasPdfImportPermission
          ? pagesCountForFreeUser
          : pages.length;
        const pagesToImport = take(pages, pageCount).slice(
          pagesCountForFreeUser,
          pageCount
        );
        if (pagesToImport.length === 0) {
          setAreAllPagesUploaded(true);
          setProcessing(false);
          return;
        }

        const importData = pagesToImport.map((page) => getPageConfigs(page));

        await uploadImages(importData, drawing.id);

        await fireBaseService.importImagePages(
          importData,
          pagesCountForFreeUser - 1,
          drawing.id,
          config,
          false
        );
        setAreAllPagesUploaded(true);
        setProcessing(false);
      } catch (e) {
        setError(e.message);
        console.log(e);
      }
    };

    if (
      isUpgraded &&
      hasPdfImportPermission &&
      !processing &&
      !areAllPagesUploaded
    ) {
      uploadRemainingPDFPages();
    }
  }, [
    isUpgraded,
    hasPdfImportPermission,
    processing,
    areAllPagesUploaded,
    config,
    pages,
    drawing,
    setAreAllPagesUploaded,
    setProcessing,
  ]);

  const onDialogClose = useCallback(() => {
    if (processing) return;

    handleClose();
  }, [handleClose, processing]);

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={processing}
    >
      <DialogTitle hasCloseButton={!processing} onClose={onDialogClose}>
        Create board from PDF
      </DialogTitle>
      {error && (
        <>
          <DialogContent>
            <DialogContentText color="error">{error}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogActionPrimaryButton onClick={handleClose}>
              Ok
            </DialogActionPrimaryButton>
          </DialogActions>
        </>
      )}
      {!error && (
        <>
          <DialogContent>
            <DialogContentText>
              {`Processed ${processedCount}`}{' '}
              {`of ${pages.length} ${pages.length === 1 ? 'page' : 'pages'}`}
            </DialogContentText>
            {!hasPdfImportPermission && pages.length > 1 && (
              <Box mb={2}>
                <Typography color="error">
                  {!user.trial
                    ? 'Start 14-day Trial to import all pages'
                    : 'Upgrade Plan to import all pages'}
                </Typography>
              </Box>
            )}
            <BorderLinearProgress
              variant="determinate"
              value={processedCount}
              maxValue={pages.length}
            />
          </DialogContent>
          <DialogActions>
            {!hasPdfImportPermission && pages.length > 1 && (
              <Button onClick={onPlansModalOpen} color="primary">
                {!user.trial ? 'Start Trial' : 'Upgrade'}
              </Button>
            )}
            {!processing && (
              <DialogActionSecondaryButton
                onClick={handleClose}
                disabled={processing}
              >
                Close
              </DialogActionSecondaryButton>
            )}
            <DialogActionPrimaryButton
              onClick={openBoard}
              disabled={processing}
            >
              Open Board
            </DialogActionPrimaryButton>
          </DialogActions>
        </>
      )}
      <PlanDialog
        source="Create From PDF"
        open={plansModalOpen}
        onClose={onPlansModalClose}
        onSuccess={onPlansModalSuccess}
      />
    </Dialog>
  );
};

export default CreateBoardFromPdfDialog;
