import { createSelector } from 'reselect';
import {
  GET_SESSION_LIST_REQUEST,
  GET_SESSION_LIST_SUCCESS,
  GET_SESSION_LIST_FAILURE,
  ADD_SESSION_IN_LIST,
  UPDATE_SESSION_IN_LIST,
  COPY_SESSION_REQUEST,
  COPY_SESSION_SUCCESS,
  COPY_SESSION_FAILURE,
  REMOVE_SESSION,
  CHANGE_SESSION_GROUP,
  GET_MORE_SESSION_LIST_REQUEST,
  GET_MORE_SESSION_LIST_FAILURE,
  GET_MORE_SESSION_LIST_SUCCESS_REQUEST,
} from '../actions/sessionsActions';

const initialState = {
  isFetching: false,
  isInitialDataFetched: false,
  isLoadingMore: false,
  isCopiedSessionFetching: false,
  hash: {},
  byGroupId: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSION_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case COPY_SESSION_REQUEST:
      return {
        ...state,
        isCopiedSessionFetching: true,
      };
    case GET_MORE_SESSION_LIST_REQUEST:
      return {
        ...state,
        isLoadingMore: true,
      };
    case GET_MORE_SESSION_LIST_SUCCESS_REQUEST: {
      return {
        ...state,
        isLoadingMore: false,
      };
    }
    case GET_SESSION_LIST_SUCCESS: {
      const { payload } = action;
      const { data: newSessions } = payload;

      const byGroupIdHash = newSessions.reduce((acc, session) => {
        const groupId = session.groupId || 'default';
        if (acc[groupId]) {
          return {
            ...acc,
            [groupId]: [...acc[groupId], session.id],
          };
        } else {
          return {
            ...acc,
            [groupId]: [session.id],
          };
        }
      }, {});

      return {
        ...state,
        hash: {
          ...state.hash,
          ...newSessions.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: curr }),
            {}
          ),
        },
        byGroupId: {
          ...state.byGroupId,
          ...byGroupIdHash,
        },
        isFetching: false,
        isInitialDataFetched: true,
      };
    }
    case GET_SESSION_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case COPY_SESSION_FAILURE:
      return {
        ...state,
        isCopiedSessionFetching: false,
      };
    case GET_MORE_SESSION_LIST_FAILURE:
      return {
        ...state,
        isLoadingMore: false,
      };
    case ADD_SESSION_IN_LIST:
      const { session, groupId } = action.payload;

      return {
        ...state,
        hash: {
          ...state.hash,
          [session.id]: session,
        },
        byGroupId: {
          ...state.byGroupId,
          [groupId]: [...(state.byGroupId[groupId] || []), session.id],
        },
      };
    case UPDATE_SESSION_IN_LIST:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.payload.id]: {
            ...state.hash[action.payload.id],
            ...action.payload,
          },
        },
      };
    case REMOVE_SESSION: {
      const { sessionId } = action.payload;
      const { [sessionId]: sessionToRemove, ...newSessions } = state.hash;
      if (!sessionToRemove) return state;
      const { groupId } = sessionToRemove;
      return {
        ...state,
        byGroupId: Object.keys(state.byGroupId).reduce((acc, key) => {
          if (key !== 'default' && key !== groupId) {
            acc[key] = state.byGroupId[key];
          } else {
            acc[key] = state.byGroupId[key].filter((id) => id !== sessionId);
          }
          return acc;
        }, {}),
        hash: { ...(newSessions || {}) },
      };
    }
    case CHANGE_SESSION_GROUP: {
      const { id, groupId } = action.payload;

      const oldGroupId = state.hash[id].groupId || 'default';
      const newGroupId = groupId || 'default';

      if (oldGroupId === newGroupId) return { ...state };

      const hash = {
        ...state.hash,
        [id]: {
          ...state.hash[id],
          groupId,
        },
      };
      const oldGroupIdPart = oldGroupId
        ? {
            [oldGroupId]: (state.byGroupId[oldGroupId] || []).filter(
              (s) => s !== id
            ),
          }
        : {};

      const byGroupId = {
        ...state.byGroupId,
        ...oldGroupIdPart,
        ...{
          [newGroupId]: [...(state.byGroupId[newGroupId] || []), id],
        },
      };

      return {
        ...state,
        hash,
        byGroupId,
      };
    }
    case COPY_SESSION_SUCCESS: {
      const { payload } = action;
      const groupId = action.groupId || 'default';
      return {
        ...state,
        hash: {
          ...state.hash,
          [payload.id]: payload,
        },
        byGroupId: {
          ...state.byGroupId,
          [groupId]: [payload.id, ...state.byGroupId[groupId]],
        },
        isCopiedSessionFetching: false,
      };
    }
    default:
      return state;
  }
};

const getSessionsByGroupId = (sessions, groups, groupId) =>
  Object.values(sessions).filter((session) => {
    if (groupId === 'default') {
      return !session.groupId || !groups[session.groupId];
    }

    return session.groupId === groupId;
  });

const sessionListSelector = (groupId) =>
  createSelector(
    [(state) => state.sessions.hash, (state) => state.groups.hash],
    (sessions, groups) => getSessionsByGroupId(sessions, groups, groupId)
  );

const isFetchingSelector = (state) => state.sessions.isFetching;
const isInitialDataFetchedSelector = (state) =>
  state.sessions.isInitialDataFetched;
const isCopiedSessionFetchingSelector = (state) =>
  state.sessions.isCopiedSessionFetching;
const hasSessionsSelector = (state) =>
  !!Object.values(state.sessions.hash).length;

const canLoadMoreSelector = () => {
  // const totalCount = state.sessions.totalCountByGroupId[groupId];
  // if (totalCount === undefined || totalCount === null) return true;
  //
  // const byGroupId = state.sessions.byGroupId[groupId] || [];
  // return byGroupId.length < (totalCount || 0);
  return false;
};

const boardsCountByGroupIdSelector = (groupId) =>
  createSelector(
    [(state) => state.sessions.hash, (state) => state.groups.hash],
    (sessions, groups) => getSessionsByGroupId(sessions, groups, groupId).length
  );

export {
  isFetchingSelector,
  isInitialDataFetchedSelector,
  isCopiedSessionFetchingSelector,
  canLoadMoreSelector,
  sessionListSelector,
  boardsCountByGroupIdSelector,
  hasSessionsSelector,
};
