import { FC } from 'react';
import Box from '@mui/material/Box';
import SocialMediaSupport from '../../atoms/SocialMediaSupport';
import SocialMediaSupportVariant from '../../atoms/SocialMediaSupport/SocialMediaSupportVariant';

type SocialMediaSupportWrapperProps = {
  visible: boolean;
};

const SocialMediaSupportWrapper: FC<
  React.PropsWithChildren<SocialMediaSupportWrapperProps>
> = ({ visible }) => (
  <Box
    position="sticky"
    top="calc(50% - 108px)"
    display="flex"
    alignItems="center"
    ml={3.75}
    height="216px"
    sx={{
      pointerEvents: visible ? 'initial' : 'none',
      opacity: visible ? 1 : 0,
      transition: 'opacity 0.2s linear',
    }}
  >
    <SocialMediaSupport variant={SocialMediaSupportVariant.VERTICAL} />
  </Box>
);

export default SocialMediaSupportWrapper;
