import { FC } from 'react';
import { AlertColor } from '../Notification/notificationTypes';
import Notification from '../Notification';
import CrownOutlineIcon from '../../atoms/Icons/CrownOutlineIcon';

type Props = {
  title: string;
  message?: string;
};

const UpgradeNotification: FC<React.PropsWithChildren<Props>> = ({
  title,
  message,
}) => (
  <Notification
    Icon={CrownOutlineIcon}
    title={title}
    message={message}
    color={AlertColor.PREMIUM}
  />
);

export default UpgradeNotification;
