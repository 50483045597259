import { FC } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import { ButtonSizeTypes } from '../../ui/atoms/Button/buttonTypes';

interface ButtonProps {
  disabled?: boolean;
  onClick: () => void;
  icon: FC<React.PropsWithChildren<SvgIconProps>>;
  className?: string;
  popoverContainerClassName?: string;
  popoverTriggerBtnClassName?: string;
  popoverTriggerBtnSeparatorClassName?: string;
  color?: 'main' | 'error';
  active?: boolean;
  minimized?: boolean;
}

export const ControlButton: FC<React.PropsWithChildren<ButtonProps>> = ({
  disabled,
  onClick,
  icon: IconComponent,
  color = 'main',
  active = false,
  minimized = false,
  className,
}: ButtonProps) => (
  <IconButton
    disabled={disabled}
    onClick={onClick}
    size={minimized ? ButtonSizeTypes.XS : ButtonSizeTypes.S}
    className={className}
    sx={(theme) => ({
      backgroundColor:
        color === 'error' ? theme.status.new.main : theme.background.bg8,
      ...(active && color === 'main'
        ? {
            backgroundColor: theme.background.bg4,
            ':hover': {
              backgroundColor: theme.background.bg5,
            },
          }
        : {}),
      ...(color === 'error'
        ? {
            backgroundColor: theme.status.new.main,
            ':hover': {
              backgroundColor: theme.status.new.dark5,
            },
          }
        : {}),
      border: 'none',
      ':disabled': {
        backgroundColor: theme.background.bg4,
      },
    })}
  >
    <IconComponent
      sx={(theme) => ({
        color: active ? theme.text.t8 : theme.text.t1,
        ...(disabled
          ? {
              color: theme.background.bg6,
            }
          : {}),
      })}
    />
  </IconButton>
);
