import { clamp } from 'lodash';

const dot = (v, u) => (v.x * u.x) + (v.y * u.y);
const norm = v => Math.sqrt(dot(v, v));
const distance = (v, u) => norm({
  x: v.x - u.x,
  y: v.y - u.y,
});

const distanceToSegment = (point, startPoint, endPoint) => {
  const v = {
    x: endPoint.x - startPoint.x,
    y: endPoint.y - startPoint.y,
  };
  const w = {
    x: point.x - startPoint.x,
    y: point.y - startPoint.y,
  };

  const c1 = dot(w, v);
  if (c1 <= 0) return distance(point, startPoint);

  const c2 = dot(v, v);
  if (c2 <= c1) return distance(point, endPoint);

  const b = c1 / c2;
  return distance(point, {
    x: startPoint.x + (b * v.x),
    y: startPoint.y + (b * v.y),
  });
};

const calculateProjectedPoint = (firstPoint, secondPoint, pointToProject) => {
  const a = firstPoint.y - secondPoint.y;
  const b = secondPoint.x - firstPoint.x;
  const c = (-b * firstPoint.y) + (-a * firstPoint.x);

  return {
    x: ((b * ((b * pointToProject.x) - (a * pointToProject.y))) - (a * c)) / ((a ** 2) + (b ** 2)),
    y: ((a * ((-b * pointToProject.x) + (a * pointToProject.y))) - (b * c)) / ((a ** 2) + (b ** 2)),
  };
};

const calculateRotatedVector = ({ x, y }, radianAngle) => ({
  x: x * Math.cos(radianAngle) - y * Math.sin(radianAngle),
  y: x * Math.sin(radianAngle) + y * Math.cos(radianAngle),
});

const calculateRotatedPoint = (transformationPoint, midPoint, radianAngle) => {
  const rotatedVector = calculateRotatedVector({
    x: transformationPoint.x - midPoint.x,
    y: transformationPoint.y - midPoint.y,
  }, radianAngle);

  return { x: rotatedVector.x + midPoint.x,  y: rotatedVector.y + midPoint.y };
};

const calculateRotationAngle = (startPoint, endPoint, pointerPosition, relativePoint) => {
  const midPoint = calculateMidPoint(startPoint, endPoint);

  const pointerVector = {
    x: pointerPosition.x - midPoint.x,
    y: pointerPosition.y - midPoint.y,
  };
  const relativeVector = {
    x: relativePoint.x - midPoint.x,
    y: relativePoint.y - midPoint.y,
  };

  return Math.atan2(
    relativeVector.x * pointerVector.y - relativeVector.y * pointerVector.x,
    relativeVector.x * pointerVector.x + relativeVector.y * pointerVector.y,
  );
};

const degreeToRadian = (degree) => degree * Math.PI / 180;
const radianToDegree = (radian) => radian * 180 / Math.PI;
const normalizeAngle = (degreeAngle) => (degreeAngle % 360 + 360) % 360;

const calculateMidPoint = (startPoint, endPoint) => ({
  x: (startPoint.x + endPoint.x) / 2,
  y: (startPoint.y + endPoint.y) / 2,
});

const findDistanceSquaredBetweenPoints = (from, to) => {
  const a = from.x - to.x;
  const b = from.y - to.y;

  return a * a + b * b;
};

const calculateIntersection = (radianAngle, mousePos, rectMidPoint, rectPos, rectDimension) => {
  const rotatedPoint = calculateRotatedPoint(mousePos, rectMidPoint, -radianAngle);
  const clampedPoint = {
    x: clamp(rotatedPoint.x, rectPos.x, rectPos.x + rectDimension.width),
    y: clamp(rotatedPoint.y, rectPos.y, rectPos.y + rectDimension.height),
  };

  return findDistanceSquaredBetweenPoints(rotatedPoint, clampedPoint) === 0;
};

export default {
  calculateProjectedPoint,
  calculateRotatedVector,
  calculateRotatedPoint,
  calculateRotationAngle,
  calculateMidPoint,
  calculateIntersection,
  distanceToSegment,
  degreeToRadian,
  radianToDegree,
  normalizeAngle,
};
