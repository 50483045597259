import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ImageWrapper from '../../../../../ImageWrapper';
import * as drawingActionsReducer from '../../../../../../common/reducers/board/drawingActionsReducer';
import useDialog from '../../../../../../common/hooks/useDialog';
import DeletePageDialog from '../../../../../../containers/Modals/DeletePageDialog';
import canvasUtils from '../../../../../../common/utils/canvas.utils';
import OrderIcon from '../../../../../Icons/PageDrag';
import {
  finishedSelector,
  isCurrentUserHostSelector,
  sessionIdSelector,
} from '../../../../../../common/reducers/session/sessionReducer';
import analyticsService from '../../../../../../common/services/analytics.service';
import FallbackImgSrc from '../../../../../../assets/images/page-fallback.png';
import { getPageCount } from '../../../../../../common/reducers/board/pagesReducer';
import BACKGROUND_COLOR from '../../../../../../common/constants/boardBackgroundColor';
import IconButton from '../../../../../../ui/atoms/IconButton';
import ContentCopyIcon from '../../../../../../ui/atoms/Icons/ContentCopyIcon';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import TrashCanOutlineIcon from '../../../../../../ui/atoms/Icons/TrashCanOutlineIcon';
import Typography from '../../../../../../ui/atoms/Typography';
import cssStyles from './Page.module.css';

const shallowEqualById = (actionsA, actionsB) => {
  if (
    typeof actionsA !== 'object' ||
    actionsA === null ||
    typeof actionsB !== 'object' ||
    actionsB === null
  ) {
    return false;
  }

  if (actionsA.length !== actionsB.length) return false;

  for (let i = 0; i < actionsA.length; i++) {
    if (actionsA[i].id !== actionsB[i].id) {
      return false;
    }

    if (actionsA[i].image !== actionsB[i].image) {
      return false;
    }
  }

  return true;
};

const HoverPopup = ({
  onCopyWrap,
  onDeletePageDialogOpen,
  isActive,
  isDeletePageDisabled,
}) => (
  <Box
    className="hoverPopup"
    sx={(theme) => ({
      width: '252px',
      height: '142px',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: '4px',
      border: `1px solid ${
        isActive ? theme.palette.primary.main : theme.background.bg6
      }`,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
    })}
  >
    <Box display="flex" gap={1}>
      <IconButton
        onClick={onCopyWrap}
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.M}
      >
        <ContentCopyIcon />
      </IconButton>
      {!isDeletePageDisabled && (
        <IconButton
          onClick={onDeletePageDialogOpen}
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.M}
        >
          <TrashCanOutlineIcon />
        </IconButton>
      )}
    </Box>
  </Box>
);

const PageNumber = ({ pageNumber }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '4px',
      right: '4px',
      padding: '2px 5px',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      borderRadius: '8px 0px 8px 8px',
      width: 26,
      height: 18,
    }}
  >
    <Typography
      align="center"
      variant="p6"
      sx={(theme) => ({
        color: theme.text.t1,
      })}
    >
      {pageNumber}
    </Typography>
  </Box>
);

const Page = ({
  width,
  height,
  isActive,
  onSelect,
  onCopy,
  onDelete,
  styles,
  copyEnabled,
  deleteEnabled,
  page,
  index,
  scale,
  isDeletePageDisabled,
}) => {
  const [imageSource, setImageSource] = useState('');

  const actionsSelector = useMemo(
    () => drawingActionsReducer.pageActionsSelector(page.id),
    [page.id]
  );

  const actions = useSelector(actionsSelector, shallowEqualById);
  const finished = useSelector(finishedSelector);
  const sessionId = useSelector(sessionIdSelector);
  const pageCount = useSelector(getPageCount);
  const isHost = useSelector(isCurrentUserHostSelector);

  const {
    open: deletePageDialogOpen,
    onClose: onDeletePageDialogClose,
    onOpen: onDeletePageDialogOpen,
  } = useDialog();

  useEffect(() => {
    const imageActions = actions.filter(
      (a) => a.name === 'imageMeta' || a.name === 'lockedImageMeta'
    );
    const nonImageActions = actions.filter(
      (a) => a.name !== 'imageMeta' && a.name !== 'lockedImageMeta'
    );

    const backgroundCanvas = document.createElement('canvas');
    const backgroundDrawingContext = backgroundCanvas.getContext('2d');

    const frontCanvas = document.createElement('canvas');
    const frontDrawingContext = frontCanvas.getContext('2d');

    backgroundCanvas.width = width;
    backgroundCanvas.height = height;

    frontCanvas.width = width;
    frontCanvas.height = height;

    canvasUtils.drawBackgroundColorOnCanvas(
      backgroundDrawingContext,
      width,
      height,
      page.color || BACKGROUND_COLOR
    );

    if (imageActions.length) {
      const transformData = {
        scale,
        originX: 0,
        originY: 0,
      };
      canvasUtils.drawImagesOnCanvas(
        backgroundDrawingContext,
        transformData,
        imageActions
      );
    }

    if (nonImageActions.length) {
      canvasUtils.drawNonImagesOnCanvas(
        frontDrawingContext,
        scale,
        nonImageActions,
        null
      );
    }

    const tempCanvas = document.createElement('canvas');
    const drawingContext = tempCanvas.getContext('2d');
    tempCanvas.width = frontCanvas.width;
    tempCanvas.height = frontCanvas.height;
    drawingContext.drawImage(backgroundCanvas, 0, 0);
    drawingContext.drawImage(frontCanvas, 0, 0);

    setImageSource(tempCanvas.toDataURL('png'));
  }, [actions, setImageSource, width, height, scale, page.color]);

  const onSuccess = useCallback(() => {
    analyticsService.event('Delete Board Page Button Click', {
      sessionId,
      pageCount: pageCount - 1,
    });
    onDeletePageDialogClose();
    onDelete(page.id);
  }, [onDeletePageDialogClose, onDelete, page.id, sessionId, pageCount]);

  const onSelectWrap = useCallback(() => {
    if (isActive) return;

    analyticsService.event('Change Page Click', {
      source: 'Page picker',
      sessionId,
      pageCount,
    });

    onSelect(page.id);
  }, [isActive, onSelect, page.id, pageCount, sessionId]);

  const onCopyWrap = useCallback(() => {
    analyticsService.event('Copy Board Page Button Click', {
      sessionId,
      pageCount: pageCount + 1,
    });
    onCopy(page.id);
  }, [onCopy, page.id, sessionId, pageCount]);

  const draggableChildren = useCallback(
    (provided) => (
      <Box
        className={cssStyles.container}
        ref={provided.innerRef}
        display="flex"
        alignItems="center"
        width={1}
        {...provided.draggableProps}
      >
        <Box position="relative" display="flex" alignItems="center">
          <Box
            className={
              !isHost || finished
                ? cssStyles.dragIconDisabled
                : cssStyles.dragIcon
            }
            ml={0.5}
            mr={0.5}
            {...provided.dragHandleProps}
          >
            {!finished && isHost && <OrderIcon />}
          </Box>
          <Box
            onClick={onSelectWrap}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                '.hoverPopup': {
                  display: 'flex !important',
                },
              },
            }}
          >
            {!finished && isHost && (
              <HoverPopup
                isActive={isActive}
                onCopyWrap={onCopyWrap}
                onDeletePageDialogOpen={onDeletePageDialogOpen}
                isDeletePageDisabled={isDeletePageDisabled}
              />
            )}
            <Paper
              id={`page-${page.id}`}
              square
              variant="outlined"
              className={clsx(cssStyles.content, {
                [cssStyles.active]: isActive,
              })}
              style={styles}
            >
              <ImageWrapper
                width={width}
                height={height}
                imageSrc={imageSource}
                fallbackSrc={FallbackImgSrc}
              />
              <PageNumber pageNumber={index + 1} />
            </Paper>
          </Box>
        </Box>
      </Box>
    ),
    [
      finished,
      isActive,
      styles,
      onSelectWrap,
      imageSource,
      copyEnabled,
      onCopyWrap,
      deleteEnabled,
      onDeletePageDialogOpen,
      page.id,
      index,
    ]
  );

  return (
    <>
      <Draggable
        draggableId={page.id.toString()}
        index={index}
        isDragDisabled={!isHost || finished}
        children={draggableChildren}
      />
      <DeletePageDialog
        open={deletePageDialogOpen}
        onClose={onDeletePageDialogClose}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default React.memo(Page);
