import React from 'react';
import { FC, useMemo } from 'react';
import { Participant, Track } from 'livekit-client';
import { Property } from 'csstype';
import { useParticipant } from '@livekit/react-core';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '../../ui/atoms/Typography';
import LiveKitVideoRenderer from '../LiveKitPopup/VideoRenderer';
import VideoRenderer from './VideoRenderer';

export interface ScreenShareViewProps {
  participant: Participant;
  width?: string;
  height?: string;
  onClick?: () => void;
  track: Track;
}

const ParticipantBar = styled(Box)(() => ({
  boxSizing: 'border-box',
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  color: 'white',
  padding: '0px 8px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr min-content min-content',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  background:
    'linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80.67%)',
}));

type VideoRendererFactoryFunction = (track: Track) => FC<
  React.PropsWithChildren<{
    isLocal: boolean;
    objectFit: Property.ObjectFit;
  }>
>;

const videoRendererFactory: VideoRendererFactoryFunction =
  (track) =>
  ({ isLocal, objectFit }) =>
    (
      <LiveKitVideoRenderer
        track={track}
        isLocal={isLocal}
        objectFit={objectFit}
        width="100%"
        height="100%"
      />
    );

const ScreenShareView: FC<React.PropsWithChildren<ScreenShareViewProps>> = ({
  participant,
  width,
  height,
  onClick,
  track,
}) => {
  const { isLocal } = useParticipant(participant);

  const activeSpeakerName = useMemo(
    () => participant.name || participant.identity,
    [participant]
  );

  const VideoRenderer = useMemo(() => videoRendererFactory(track), [track]);

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        borderRadius: '12px',
        overflow: 'hidden',
        position: 'relative',
      }}
      width={width}
      height={height}
      onClick={onClick}
    >
      <VideoRenderer isLocal={false} objectFit="contain" />
      <ParticipantBar height={48}>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            justifySelf: 'start',
            cursor: 'pointer',
            display: 'flex',
            gap: 0.5,
            minWidth: '100%',
            maxWidth: '100%',
          }}
        >
          <Typography
            variant="p5"
            sx={(theme) => ({
              color: theme.text.t1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            })}
          >
            {activeSpeakerName}
          </Typography>
          {isLocal && (
            <Typography
              variant="p5"
              sx={(theme) => ({
                color: theme.text.t1,
              })}
            >
              (you)
            </Typography>
          )}
        </Box>
      </ParticipantBar>
    </Box>
  );
};

export default ScreenShareView;
