import { take, uniq, map, sortBy, cloneDeep, findLast } from 'lodash';
import { createSelector } from 'reselect';
import {
  ADD_ACTIONS,
  UPDATE_ACTION,
} from '../../actions/board/drawingActionsActions';
import { UPDATE_RECORDING_POSITION } from '../../actions/board/recordingPositionActions';
import {
  CLEAR_SESSION,
  SESSION_DISCONNECTED,
} from '../../actions/session/sessionActions';
import DrawingUtil from '../../utils/drawing.util';
import * as imagesReducer from '../../reducers/imagesReducer';
import * as currentDrawingReducer from './currentDrawingReducer';
import * as activePageReducer from './activePageReducer';

const processActions = (newActions, byId, byPageId) => {
  const pageIds = uniq(map(newActions, 'pageNumber'));
  return pageIds.reduce((acc, pageId) => {
    const filteredActions = newActions.filter((a) => a.pageNumber === pageId);
    const {
      startDrawIndex = 0,
      endDrawIndex = 0,
      actionIds = [],
      notDrawingActions = [],
    } = byPageId[pageId] || {};
    const oldActions = actionIds.map((id) => byId[id]);
    const drawingUtil = new DrawingUtil(
      startDrawIndex,
      endDrawIndex,
      oldActions,
      notDrawingActions
    );
    const {
      startDrawIndex: newStartDrawIndex,
      endDrawIndex: newEndDrawIndex,
      actions: newPageActions,
      notDrawingActions: newNotDrawingActions,
    } = drawingUtil.processActions(filteredActions);
    return {
      ...acc,
      [pageId]: {
        startDrawIndex: newStartDrawIndex,
        endDrawIndex: newEndDrawIndex,
        actionIds: newPageActions.map((a) => a.id),
        notDrawingActions: newNotDrawingActions,
      },
    };
  }, {});
};

const initialState = {
  byId: {},
  rawOrder: [],
  byPageId: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ACTIONS: {
      const { actions } = payload;
      const newActions = actions.filter((action) => {
        return state.rawOrder.indexOf(action.id) !== action.order;
      });
      const result = processActions(newActions, state.byId, state.byPageId);

      return {
        ...state,
        rawOrder: uniq([...state.rawOrder, ...actions.map((a) => a.id)]),
        byId: {
          ...state.byId,
          ...actions.reduce(
            (acc, a) => ({
              ...acc,
              [a.id]: a,
            }),
            {}
          ),
        },
        byPageId: {
          ...state.byPageId,
          ...result,
        },
      };
    }
    case UPDATE_ACTION: {
      const { action } = payload;
      const { rawOrder: oldRawOrder } = state;
      const index = oldRawOrder.indexOf(action.id);
      const newById = {
        ...state.byId,
        [action.id]: action,
      };
      let newRawOrder;
      let newByPageId = state.byPageId;
      if (index === -1) {
        newRawOrder = [...oldRawOrder, action.id];
        newByPageId = {
          ...state.byPageId,
          ...processActions([action], state.byId, state.byPageId),
        };
      } else {
        const notSortedActions = oldRawOrder.map((id) => newById[id]);
        const notSortedActionsWithOrder = notSortedActions.filter(
          (a) => a.order !== null || a.id === action.id
        );
        const notSortedActionsWithoutOrder = notSortedActions.filter(
          (a) => a.order === null && a.id !== action.id
        );
        newRawOrder = sortBy(notSortedActionsWithOrder, ['order']);
        newRawOrder = [...newRawOrder, ...notSortedActionsWithoutOrder];
        newRawOrder = newRawOrder.map((a) => a.id);
      }
      return {
        ...state,
        byId: newById,
        rawOrder: newRawOrder,
        byPageId: newByPageId,
      };
    }
    case UPDATE_RECORDING_POSITION: {
      const { actionId } = payload;
      if (!actionId) return state;

      const index = state.rawOrder.indexOf(actionId);
      const actions = take(state.rawOrder, index + 1);

      const result = processActions(
        actions.map((id) => state.byId[id]),
        state.byId,
        {}
      );

      return {
        ...state,
        byPageId: {
          ...state.byPageId,
          ...result,
        },
      };
    }
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const rawActionsSelector = createSelector(
  (state) => state.board.actions.rawOrder,
  (state) => state.board.actions.byId,
  (rawOrder, actionsById) => rawOrder.map((id) => actionsById[id])
);

const pageActionsCombiner = (page, byId, images, targetId) => {
  if (!page) return [];
  const getAction = (id) => {
    const action = byId[id];

    if (
      ['imageMeta', 'lockedImageMeta', 'formula', 'graph'].includes(action.name)
    ) {
      const cloned = cloneDeep(action);
      cloned.image = images[id];

      return cloned;
    }

    return action;
  };
  const { startDrawIndex, endDrawIndex, notDrawingActions, actionIds } = page;
  let filteredActions = actionIds.map(getAction).filter((action, i) => {
    return (
      (i >= startDrawIndex || action.name === 'lockedImageMeta') &&
      i < endDrawIndex &&
      !notDrawingActions.has(action.id)
    );
  });

  if (targetId) {
    filteredActions = filteredActions.filter(
      (action) => targetId !== action.id
    );
  }

  return filteredActions;
};

const pageActionsSelector = (pageId) =>
  createSelector(
    [
      (state) => state.board.actions.byPageId[pageId],
      (state) => state.board.actions.byId,
      imagesReducer.imagesHashSelector,
      (state) => {
        const currentDrawing = currentDrawingReducer.getCurrentDrawing(state);
        if (!currentDrawing || !currentDrawing.targetId) return null;
        return currentDrawing.targetId;
      },
    ],
    pageActionsCombiner
  );

const createActionsSelector = () =>
  createSelector(
    [
      (state, pageId) => state.board.actions.byPageId[pageId],
      (state) => state.board.actions.byId,
      imagesReducer.imagesHashSelector,
      (state) => {
        const currentDrawing = currentDrawingReducer.getCurrentDrawing(state);
        if (!currentDrawing || !currentDrawing.targetId) return null;
        return currentDrawing.targetId;
      },
    ],
    pageActionsCombiner
  );

const canUndo = createSelector(
  (state) => {
    const activePageId = activePageReducer.getActivePageId(state);
    return state.board.actions.byPageId[activePageId];
  },
  (state) => state.board.actions.byId,
  (page, byId) => {
    if (!page) return false;
    const { startDrawIndex, endDrawIndex, actionIds } = page;

    if (startDrawIndex !== endDrawIndex) {
      const actionId = page.actionIds[endDrawIndex - 1];

      return !(byId[actionId] && byId[actionId].name === 'lockedImageMeta');
    }

    const prevActionIndex = endDrawIndex - 1;
    if (prevActionIndex < 0) return false;

    const prevActionId = actionIds[prevActionIndex];
    if (!prevActionIndex) return false;

    const prevAction = byId[prevActionId];

    if (!prevAction) return false;

    if (prevAction.name === 'clear_v2') return false;

    return prevAction.name === 'clear';
  }
);

const canRedo = createSelector(
  (state) => {
    const activePageId = activePageReducer.getActivePageId(state);
    return state.board.actions.byPageId[activePageId];
  },
  (page) => {
    if (!page) return false;
    const { endDrawIndex, actionIds } = page;

    return endDrawIndex < actionIds.length;
  }
);

const lastActivePageSelector = createSelector(
  (state) => Object.values(state.board.actions.byId),
  (actionsById) => {
    const activePageActions = actionsById.filter(
      (action) => action.name === 'activePage'
    );
    const lastActivePage = findLast(activePageActions);

    return lastActivePage?.pageNumber ?? null;
  }
);

export {
  createActionsSelector,
  rawActionsSelector,
  canUndo,
  canRedo,
  pageActionsSelector,
  lastActivePageSelector,
};
