import { ForwardedRef, forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as FormatListComplete } from '../../../assets/svgIcons/FormatListCompleteSvgIcon.svg';

const Icon = forwardRef(
  (props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
    <SvgIcon
      ref={ref}
      component={FormatListComplete}
      inheritViewBox
      {...props}
    />
  )
);

export default Icon;
