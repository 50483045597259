import React, { FC, useCallback, useMemo } from 'react';
import ToolNames from '../ToolNames';
import DrawingButton from '../DrawingButton/DrawingButton';
import SelectIcon from '../../../ui/atoms/Icons/SelectIcon';
import { SelectedTool } from './types/SelectToolType';
import analyticsService from '../../../common/services/analytics.service';

type SelectToolProps = {
  selectedTool: SelectedTool;
  selectTool: (name: string) => void;
};

const SelectTool: FC<React.PropsWithChildren<SelectToolProps>> = ({
  selectedTool,
  selectTool,
}) => {
  const selectSelectionTool = useCallback(() => {
    analyticsService.event('Tool Selected', {
      tool: 'selectionTool',
    });
    selectTool(ToolNames.Select);
  }, [selectTool]);
  const isActive = useMemo(
    () => selectedTool.name === ToolNames.Select,
    [selectedTool.name]
  );

  return (
    <DrawingButton
      icon={<SelectIcon />}
      onClick={selectSelectionTool}
      tooltipTitle="Select"
      tooltipPlacement="bottom"
      active={isActive}
    />
  );
};

export default SelectTool;
