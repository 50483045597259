import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MuiDivider, { DividerProps } from '@mui/material/Divider';

const StyledDivider = styled(MuiDivider)(({ theme }) => ({
  '::before': {
    borderTopColor: theme.text.t3,
    top: 0,
  },
  '::after': {
    borderTopColor: theme.text.t3,
    top: 0,
  },
}));

const HorizontalDivider: FC<React.PropsWithChildren<DividerProps>> = ({
  children,
  ...props
}) => (
  <StyledDivider {...props}>
    {children && (
      <Typography
        variant="p4"
        sx={(theme) => ({
          color: theme.text.t7,
        })}
      >
        {children}
      </Typography>
    )}
  </StyledDivider>
);

export default HorizontalDivider;
