import { FC, forwardRef, ForwardedRef, useCallback } from 'react';
import { first } from 'lodash';
import { useSnackbar } from 'notistack';
import fileUtils from '../../common/utils/file.utils';

type Props = {
  id?: string;
  onChange: ({
    name,
    data,
  }: {
    name: string;
    data: string | ArrayBuffer | null;
  }) => void;
};

type PropsWithRef = Props & {
  ref: ForwardedRef<HTMLInputElement>;
};

const PdfFileInput: FC<React.PropsWithChildren<PropsWithRef>> = forwardRef<
  HTMLInputElement,
  Props
>(({ id = '', onChange }, ref) => {
  const { enqueueSnackbar } = useSnackbar();

  const onInputChange = useCallback(
    async (e: any) => {
      const file = first<File>(e.target.files);
      if (!file) return;

      const result = await fileUtils.checkMimeTypeInList(file, ['pdf']);
      const fileName = file.name;
      e.target.value = null;

      if (!result || !fileName.match(/\.(pdf)$/gi)) {
        enqueueSnackbar('Selected file is not a PDF file', {
          autoHideDuration: 3000,
          variant: 'error',
        });
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        onChange({
          name: fileName,
          data: reader.result,
        });
      });
      reader.readAsArrayBuffer(file);
      e.target.value = null;
    },
    [enqueueSnackbar, onChange]
  );

  return (
    <input
      id={id}
      style={{
        display: 'none',
      }}
      type="file"
      accept="application/pdf"
      onChange={onInputChange}
      ref={ref}
    />
  );
});

export default PdfFileInput;
