import React, { FC } from 'react';
import Badge, { badgeClasses, BadgeProps } from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PencilIcon from '../../../ui/atoms/Icons/PencilIcon';

type StyledBadgeProps = {
  active: boolean;
} & BadgeProps;

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StyledBadgeProps>(({ theme, active }) => ({
  '&:hover': {
    [`& > .${badgeClasses.badge}`]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      right: '50%',
      bottom: '50%',
      pointerEvents: 'unset',
      '& svg': {
        fontSize: '1.5rem',
        transition: theme.transitions.create(['font-size'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  },
  [`& .${badgeClasses.badge}`]: {
    background: 'red',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    padding: 0,
    backgroundColor: active ? '#1eb270' : '#f4f6f8',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '& svg': {
      fontSize: '1rem',
      color: active ? '#f4f6f8' : '#32325d',
      transition: theme.transitions.create(['font-size'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    top: 'unset',
    left: 'unset',
    right: '14%',
    bottom: '14%',
    transform: 'scale(1) translate(50%, 50%)',
    transformOrigin: '100% 100%',
    transition: theme.transitions.create(
      ['width', 'height', 'right', 'bottom'],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }
    ),
    borderRadius: '50%',
    pointerEvents: 'none',
  },
}));

type PresenceBadgeProps = {
  onClick: () => void;
  active: boolean;
};

const PresenceBadge: FC<React.PropsWithChildren<PresenceBadgeProps>> = ({
  children,
  onClick,
  active,
}) => (
  <StyledBadge
    active={active}
    overlap="circular"
    badgeContent={
      <IconButton
        onClick={onClick}
        sx={{
          width: '48px',
          height: '48px',
          border: 'unset',
        }}
      >
        <PencilIcon
          sx={{
            width: '14px',
            height: '14px',
          }}
        />
      </IconButton>
    }
  >
    {children}
  </StyledBadge>
);

export default PresenceBadge;
