import React, { FC, useCallback, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '../../atoms/Typography';
import authService from '../../../common/services/auth.service';
import ReasonOtherField from './ReasonOtherField';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';

type Props = {
  open: boolean;
  onClose: () => void;
  reasons: string[];
  onSuccess: () => void;
};

const DeleteAccountReasonDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  reasons,
  onSuccess,
}) => {
  const {
    handleSubmit,
    control,
    clearErrors,
    reset,
    getFieldState,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      reason: '',
      reasonOther: '',
    },
  });

  const { error: reasonError } = getFieldState('reason');

  const [submitError, setSubmitError] = useState('');

  const onSubmit = useCallback(
    handleSubmit(async ({ reason, reasonOther }) => {
      try {
        await authService.deleteAccount(reasonOther || reason);
        onSuccess();
      } catch (err) {
        setSubmitError(err.message);
      }
    }),
    [setSubmitError, onSuccess]
  );

  const deleteReason = useWatch({ control, name: 'reason' });

  const onEnter = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={onClose}>Tell us the reason</DialogTitle>
        <DialogContent>
          <FormControl>
            <Controller
              rules={{ required: 'No reason is selected' }}
              control={control}
              name="reason"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {reasons.map((reason) => (
                    <FormControlLabel
                      key={reason}
                      value={reason}
                      control={<Radio />}
                      label={reason}
                    />
                  ))}
                  <FormControlLabel
                    key="other"
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {deleteReason === 'Other' && (
            <Box width={1}>
              <ReasonOtherField control={control} clearErrors={clearErrors} />
            </Box>
          )}
          {(submitError || reasonError?.message) && (
            <Typography
              sx={{
                color: '#e71d32',
              }}
            >
              {submitError || reasonError?.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton
            onClick={onClose}
            disabled={isSubmitting}
            color="primary"
          >
            Keep account
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton
            loading={isSubmitting}
            type="submit"
            color="error"
          >
            Delete account
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteAccountReasonDialog;
