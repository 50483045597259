import { FC, useCallback } from 'react';
import Dialog from '../../../ui/atoms/Dialog';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import Typography from '../../../ui/atoms/Typography';
import analyticsService from '../../../common/services/analytics.service';

type Props = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
};

const DeleteRecordingDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onDiscard,
}) => {
  const onDiscardClick = useCallback(() => {
    analyticsService.event('Board Delete Recording Click');
    onDiscard();
  }, [onDiscard]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle hasCloseButton={false}>Delete the recording?</DialogTitle>
      <DialogContent>
        <Typography>
          You are permanently deleting this recording. This action cannot be
          undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onDiscardClick} color="error">
          Discard
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecordingDialog;
