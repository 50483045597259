import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as AccountMultiplePlusOutlineIcon } from '../../../assets/svgIcons/AccountMultiplePlusOutlineSvgIcon.svg';

const Icon = (props: SvgIconProps) => (
  <SvgIcon
    component={AccountMultiplePlusOutlineIcon}
    inheritViewBox
    {...props}
  />
);

export default Icon;
