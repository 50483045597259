import MenuComponent, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import { listItemClasses } from '@mui/material/ListItem';

const Menu = styled(MenuComponent, {
  shouldForwardProp: prop => prop !== 'listItemWidth',
})<MenuProps & {
  listItemWidth: number,
}>(({ listItemWidth, theme }) => ({
  [`& .${paperClasses.root}`]: {
    border: '1px solid',
    borderColor: theme.background.bg5,
    backgroundColor: theme.background.bg2,
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1.75),
    padding: '7px',
    marginTop: theme.spacing(2.375),
  },
  [`& .${listClasses.root}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  [`& .${listItemClasses.root}`]: {
    width: listItemWidth,
  },
}));

export default Menu;
