const getStartingPoint = (start, end) => ({
  x: Math.min(start.x, end.x),
  y: Math.min(start.y, end.y),
});

const getEndPoint = (start, end) => ({
  x: Math.max(start.x, end.x),
  y: Math.max(start.y, end.y),
});

const getWidth = (start, end) => Math.abs(start.x - end.x);

const getHeight = (start, end) => Math.abs(start.y - end.y);

export {
  getStartingPoint,
  getEndPoint,
  getWidth,
  getHeight,
};
