import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Call from '../Call/Call';
import ChatTool from '../Chat/ChatTool';
import RecordingTools from '../RecordingTools';
import { isCurrentUserHostSelector } from '../../../common/reducers/session/sessionReducer';
import InviteTool from '../InviteTool';

type Props = {
  boardIsFinished: boolean;
  showChatTool: boolean;
  showRecordingTools: boolean;
  showVoice: boolean;
  showInviteTool: boolean;
  collaborationBarExpanded: boolean;
};

type CollaborationToolsWrapperProps = {
  direction: 'row' | 'column';
};

const CollaborationToolsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'direction',
})<CollaborationToolsWrapperProps>(({ theme, direction }) => ({
  display: 'flex',
  flexDirection: direction,
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: `1px solid ${theme.background.bg3}`,
  padding: '8px',
}));

const CollaborationTools: FC<React.PropsWithChildren<Props>> = ({
  boardIsFinished,
  showChatTool,
  showRecordingTools,
  showVoice,
  showInviteTool,
  collaborationBarExpanded,
}) => {
  const isHost = useSelector(isCurrentUserHostSelector);
  const tooltipPlacment = useMemo(
    () => (!collaborationBarExpanded ? 'left' : 'top'),
    [collaborationBarExpanded]
  );

  return (
    <CollaborationToolsWrapper
      direction={collaborationBarExpanded ? 'row' : 'column'}
    >
      {isHost && !boardIsFinished && showInviteTool && (
        <InviteTool tooltipPlacement={tooltipPlacment} />
      )}
      {showVoice && (
        <Call
          collaborationBarExpanded={collaborationBarExpanded}
          tooltipPlacment={tooltipPlacment}
        />
      )}
      {showRecordingTools && isHost && (
        <RecordingTools
          collaborationBarExpanded={collaborationBarExpanded}
          tooltipPlacment={tooltipPlacment}
        />
      )}
      <ChatTool
        editable={!boardIsFinished && showChatTool}
        key="chat-tool"
        tooltipPlacment={tooltipPlacment}
      />
    </CollaborationToolsWrapper>
  );
};

export default memo(CollaborationTools);
