import { ControlButton } from './ControlButton';
import StopScreenShareIcon from '../../ui/atoms/Icons/StopScreenShareIcon';
import ScreenShareIcon from '../../ui/atoms/Icons/ScreenShareIcon';

export interface ShareScreenButtonProps {
  isEnabled: boolean;
  onClick: () => void;
  className?: string;
  isButtonDisabled?: boolean;
  popoverContainerClassName?: string;
  popoverTriggerBtnClassName?: string;
  popoverTriggerBtnSeparatorClassName?: string;
  minimized?: boolean;
}

export const ShareScreenButton = ({
  isEnabled,
  onClick,
  className,
  isButtonDisabled,
  popoverContainerClassName,
  popoverTriggerBtnClassName,
  popoverTriggerBtnSeparatorClassName,
  minimized = false,
}: ShareScreenButtonProps) => (
  <ControlButton
    icon={isEnabled ? StopScreenShareIcon : ScreenShareIcon}
    active={!isEnabled}
    disabled={isButtonDisabled}
    onClick={onClick}
    className={className}
    popoverContainerClassName={popoverContainerClassName}
    popoverTriggerBtnClassName={popoverTriggerBtnClassName}
    popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
    minimized={minimized}
  />
);
