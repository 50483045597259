import CircleDrawingAction from './shapes/circle.drawing.action';
import ClearAction from './clear.action';
import ClearV2Action from './clear.v2.action';
import DotPointerAction from './pointers/dot.pointer.action';
import EraseDrawingAction from './erase.drawing.action';
import FreeDrawingAction from './free.drawing.action';
import ImageDrawingAction from './image.drawing.action';
import LineDrawingAction from './shapes/line.drawing.action';
import LinePointerAction from './pointers/line.pointer.action';
import OvalDrawingAction from './shapes/oval.drawing.action';
import RectangleDrawingAction from './shapes/rectangle.drawing.action';
import RedoAction from './redo.action';
import TextDrawingAction from './text.drawing.action';
import TriangleDrawingAction from './shapes/triangle.drawing.action';
import UndoAction from './undo.action';
import DeleteAction from './delete.action';
import ActivePageAction from './pages/active.page.action';
import FreeV2DrawingAction from './free.drawing.v2.action';
import EraseV2DrawingAction from './erase.drawing.v2.action';
import FreeV3DrawingAction from './free.drawing.v3.action';
import EraseV3DrawingAction from './erase.drawing.v3.action';
import NotSupportedAction from './notSupported.action';
import LockedImageDrawingAction from './lockedImage.drawing.action';
import ArrowDrawingAction from './shapes/arrow.drawing.action';
import PathShapeDrawingAction from './shapes/pathShape.drawing.action';
import FormulaDrawingAction from './formula.drawing.action';
import GraphDrawingAction from './graph.drawing.action';

const deserialize = (json) => {
  switch (json['@']) {
    case 'free_v3':
      return new FreeV3DrawingAction(json);
    case 'erase_v3':
      return new EraseV3DrawingAction(json);
    case 'free_v2':
      return new FreeV2DrawingAction(json);
    case 'erase_v2':
      return new EraseV2DrawingAction(json);
    case 'free':
      return new FreeDrawingAction(json);
    case 'circle':
      return new CircleDrawingAction(json);
    case 'line':
      return new LineDrawingAction(json);
    case 'arrow':
      return new ArrowDrawingAction(json);
    case 'oval':
      return new OvalDrawingAction(json);
    case 'rectangle':
      return new RectangleDrawingAction(json);
    case 'triangle':
      return new TriangleDrawingAction(json);
    case 'clear':
      return new ClearAction(json);
    case 'clear_v2':
      return new ClearV2Action(json);
    case 'undo':
      return new UndoAction(json);
    case 'redo':
      return new RedoAction(json);
    case 'erase':
      return new EraseDrawingAction(json);
    case 'text':
      return new TextDrawingAction(json);
    case 'imageMeta':
      return new ImageDrawingAction(json);
    case 'lockedImageMeta':
      return new LockedImageDrawingAction(json);
    case 'pointLine':
      return new LinePointerAction(json);
    case 'pointDot':
      return new DotPointerAction(json);
    case 'delete':
      return new DeleteAction(json);
    case 'activePage':
      return new ActivePageAction(json);
    case 'pathShape':
      return new PathShapeDrawingAction(json);
    case 'formula':
      return new FormulaDrawingAction(json);
    case 'graph':
      return new GraphDrawingAction(json);
    default:
      return new NotSupportedAction(json);
  }
};

export default { deserialize };
