import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FormatListOutlinedIcon from '../../../../../ui/atoms/Icons/FormatListOutlinedIcon';
import Typography from '../../../../../ui/atoms/Typography';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import ListItem from '../../../../../ui/atoms/ListItem';
import FormatListPendingIcon from '../../../../../ui/atoms/Icons/FormatListPendingIcon';
import FormatListCompleteIcon from '../../../../../ui/atoms/Icons/FormatListCompleteIcon';
import DrawerToolbar from '../../../../../ui/atoms/DrawerToolbar/DrawerToolbar';
import { sessionIdSelector } from '../../../../../common/reducers/session/sessionReducer';
import IconButton from '../../../../../ui/atoms/IconButton';
import CloseIcon from '../../../../../ui/atoms/Icons/CloseIcon';
import AssessmentStatus from '../../../common/types/AsssessmentStatus';
import * as assessmentPaperService from '../../services/assessment-paper.service';
import { LoadingListItem } from './LoadingListItem';
import ScrollbarWrapper from '../../../../../ui/atoms/ScrollbarWrapper/ScrollbarWrapper';
import NoContentCard from '../../../../../ui/molecules/NoContentCard';
import analyticsService from '../../../../../common/services/analytics.service';
import { NavListItem } from '../../../common/components/NavListItem';

type AssessmentSidebarProps = {
  open: boolean;
  onClose: () => void;
};

const getAssessmentIcon = (status: AssessmentStatus) => {
  switch (status) {
    case AssessmentStatus.CREATED:
      return <FormatListOutlinedIcon />;
    case AssessmentStatus.COMPLETED:
      return <FormatListCompleteIcon />;
    case AssessmentStatus.IN_PROGRESS:
      return <FormatListPendingIcon />;
    default:
      throw new Error('Unknown assessment status');
  }
};

export const AssessmentPaperSidebar: FC<
  React.PropsWithChildren<AssessmentSidebarProps>
> = ({ open, onClose }) => {
  const sessionId = useSelector(sessionIdSelector);
  const assessmentPapersQuery = useQuery({
    queryKey: ['assessment-papers', { sessionId }],
    queryFn: () =>
      assessmentPaperService.getParticipantAssessmentPapers(sessionId),
    enabled: open,
  });

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: (theme) => ({
          width: 300,
          zIndex: theme.zIndex.drawer + 1,
          overflowY: 'unset',
          backgroundColor: theme.background.bg1,
        }),
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <DrawerToolbar>
        <Typography variant="s4">Assessments</Typography>
        <IconButton
          variant={ButtonVariantTypes.TEXT}
          size={ButtonSizeTypes.S}
          onClick={onClose}
          shape={ButtonShapeTypes.ROUNDED}
        >
          <CloseIcon />
        </IconButton>
      </DrawerToolbar>
      <ScrollbarWrapper showOnHover>
        <Box
          maxWidth={300}
          height="100%"
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            backgroundColor: theme.background.bg1,
            borderTop: `1px solid ${theme.background.bg3}`,
          })}
        >
          {assessmentPapersQuery.isLoading && (
            <>
              <LoadingListItem />
              <LoadingListItem />
            </>
          )}
          {!assessmentPapersQuery.isLoading &&
            !assessmentPapersQuery.data?.data.length && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={1.75}
                height="100%"
              >
                <NoContentCard
                  Icon={FormatListOutlinedIcon}
                  title="No assessments have been assigned to you"
                  description=""
                />
              </Box>
            )}
          {!assessmentPapersQuery.isLoading &&
            Number(assessmentPapersQuery.data?.data.length) > 0 &&
            assessmentPapersQuery.data?.data.map(({ id, name, status }) => (
              <NavListItem
                key={id}
                to={`/paper/${id}`}
                onClick={() => {
                  analyticsService.event('Assessment Paper Selected', {
                    paperId: id,
                  });
                }}
              >
                <ListItem
                  startIcon={getAssessmentIcon(status)}
                  title={name}
                  rounded={false}
                />
              </NavListItem>
            ))}
        </Box>
      </ScrollbarWrapper>
    </Drawer>
  );
};
