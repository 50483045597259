const SET_VERIFICATION_TIME = Symbol('SET_VERIFICATION_TIME');
const SET_SHOW_PRODUCT_TOUR = Symbol('SET_SHOW_PRODUCT_TOUR');

const setVerificationTime = (payload) => ({
  type: SET_VERIFICATION_TIME,
  payload,
});

const setShowProductTour = (payload) => ({
  type: SET_SHOW_PRODUCT_TOUR,
  payload,
});

export {
  setVerificationTime,
  setShowProductTour,
  SET_VERIFICATION_TIME,
  SET_SHOW_PRODUCT_TOUR,
};
