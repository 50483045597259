import { cloneDeep, findLast, isEmpty } from 'lodash';
import { initializeApp } from 'firebase/app';
import { push as redirect } from 'redux-first-history';
import {
  getDatabase,
  child,
  orderByKey,
  query,
  limitToLast,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
  runTransaction,
  push,
  update,
  onValue,
  get,
  set,
  onDisconnect,
  startAt,
  off,
  orderByChild,
  serverTimestamp,
  remove,
} from 'firebase/database';
import { deviceStorage } from '../../config/storage';
import * as userReducer from '../reducers/userReducer';
import * as sessionActions from '../actions/session/sessionActions';
import {
  addActions,
  updateAction,
} from '../actions/board/drawingActionsActions';
import * as temporaryDraingActionsActions from '../actions/board/temporaryDraingActionsActions';
import {
  participantsAdded,
  participantJoined,
  setDrawingEnabled,
  participantUpdated,
  participantLeft,
  addParticipant,
  removeParticipant,
} from '../actions/session/participantsActions';
import {
  messagesReceived,
  messageReceived,
} from '../actions/session/messageActions';
import { getActivePageId } from '../reducers/board/activePageReducer';
import { addPage, changePage, setOrders } from '../actions/board/pagesActions';
import DrawingActionsSerializer from '../drawingActions/serializer';
import DrawingActionsFactory from '../drawingActions/drawingActionsFactory';
import sessionService from './session.service';
import { getIsDocumentVisible } from '../hooks/PageVisibility';
import BlobUtils from '../utils/blob.utils';
import numberUtil from '../utils/number.util';
import UserPresenceStatus from '../constants/UserPresenceStatus';
import GridType from '../drawingActions/gridType';
import guid from '../utils/guid.util';
import { imagesHashSelector } from '../reducers/imagesReducer';
import {
  isCurrentUserHostSelector,
  ownerIdSelector,
} from '../reducers/session/sessionReducer';
import {
  getNextCursorColor,
  HOST_CURSOR_COLOR,
} from '../utils/participant-cursor-color-manager';
import environment from '../../config/environment';
import BACKGROUND_COLOR from '../constants/boardBackgroundColor';
import { setActivePage } from '../actions/board/activePageActions';
import ActivePageAction from '../drawingActions/pages/active.page.action';
import { lastActivePageSelector } from '../reducers/board/drawingActionsReducer';
import { setShouldUpdateThumbnail } from '../actions/board/shouldUpdateThumbnailActions';
import { participantsWithoutHostSelector } from '../reducers/session/participantsReducer';
import { updateInvitedContacts } from '../actions/session/invitesActions';
import { getFilteredInvitedParticipants } from '../utils/participants.utils';

let store = null;

const setStore = (reduxStore) => {
  store = reduxStore;
};

const dispatch = (...args) => {
  store.dispatch(...args);
};

const deserializeAction = (snapshot, setOrder = true) => {
  const serializedAction = snapshot.val();
  if (setOrder) {
    serializedAction.order = parseInt(snapshot.key, 10);
  }

  return DrawingActionsSerializer.deserialize(serializedAction);
};

const sessionRefPath = 'sessions_v3';
const sessionQuestionRefPath = 'sessionQuestion';

class FireBaseService {
  id = null;

  session = null;

  handlersToRemove = [];

  serverTimeOffset = 0;

  constructor() {
    this.app = initializeApp({
      apiKey: environment.firebase.apiKey,
      authDomain: environment.firebase.authDomain,
      projectId: environment.firebase.projectId,
      storageBucket: environment.firebase.storageBucket,
      messagingSenderId: environment.firebase.messagingSenderId,
    });
  }

  user = () => userReducer.userSelector(store.getState());
  ownerId = () => ownerIdSelector(store.getState());
  imagesHashSelector = () => imagesHashSelector(store.getState());
  getActivePage = () => getActivePageId(store.getState());
  getParticipants = () => participantsWithoutHostSelector(store.getState());

  getDatabaseRefFromUrl(path, url) {
    const database = getDatabase(this.app, url);

    return ref(database, path);
  }

  _getOnlineRef(config = null) {
    const firebaseConfig = config ?? this.config;

    return this.getDatabaseRefFromUrl(
      '.info/connected',
      firebaseConfig.firebaseDatabaseUrl
    );
  }

  _getTimeOffsetRef(config = null) {
    const firebaseConfig = config ?? this.config;

    return this.getDatabaseRefFromUrl(
      '.info/serverTimeOffset',
      firebaseConfig.firebaseDatabaseUrl
    );
  }

  _generateSessionRef(id = null, config = null) {
    const sessionId = !!id ? id : this.id;
    const firebaseConfig = config ?? this.config;
    const sessionRef = this.getDatabaseRefFromUrl(
      sessionRefPath,
      firebaseConfig.firebaseDatabaseUrl
    );

    return child(sessionRef, sessionId);
  }

  _generateSessionQuestionRef(id = null, config = null) {
    const sessionId = !!id ? id : this.id;
    const firebaseConfig = config ?? this.config;
    const sessionQuestionRef = this.getDatabaseRefFromUrl(
      sessionQuestionRefPath,
      firebaseConfig.firebaseDatabaseUrl
    );

    return child(sessionQuestionRef, sessionId);
  }

  async connect(id, session, config) {
    try {
      this.id = id;
      this.session = session;
      this.config = config;

      await this.removeHandlers();

      this.listenForServerTimeOffset();
      await this.sessionExist();
      await this.startTimeHandler();
      await this.sessionStopped();
      await this.initializeSession();

      this.conferenceHandler();
      this.optionsHandler();

      await this.participantsHandler();
      await this.messagesHandler();

      this.pagesHandler();

      await this.actionsHandler();

      this.temporaryActionHandler();
      this.assessmentHandler();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  lastSendingPromise = Promise.resolve();

  getServerTimestamp = () => {
    return serverTimestamp();
  };

  sendDrawingAction = async (action, oldSessionRef) => {
    const send = async (a) => {
      const sessionRef = oldSessionRef || this._generateSessionRef();
      const actionsRef = child(sessionRef, 'actions');
      const orderedActionsRef = query(actionsRef, orderByKey());
      const recentActionRef = query(orderedActionsRef, limitToLast(1));
      const snapshot = await new Promise((resolve) => {
        onChildAdded(recentActionRef, resolve);
      });
      const timestamp = this.getServerTimestamp();

      const order = snapshot.exists() ? parseInt(snapshot.key, 10) + 1 : 0;

      return this.sendSerializedAction(
        {
          ...a.serialize(),
          createdTime: timestamp,
        },
        order,
        sessionRef
      );
    };

    if (action.name !== 'imageMeta') return send(action);

    const updatedAction = await this.sendImage(action);
    return send(updatedAction);
  };

  sendAction = async (action, sessionRef = null) => {
    this.lastSendingPromise = this.lastSendingPromise.then(() =>
      this.sendDrawingAction(action, sessionRef)
    );

    return this.lastSendingPromise;
  };

  sendSerializedAction = async (action, order, oldSessionRef) => {
    const sessionRef = oldSessionRef || this._generateSessionRef();
    const actionRef = child(sessionRef, 'actions');
    const orderRef = child(actionRef, order.toString());

    const { committed } = await runTransaction(orderRef, (currentData) => {
      if (!currentData) return action;

      return undefined;
    });

    if (committed) return null;

    return this.sendSerializedAction(action, order + 1, sessionRef);
  };

  sendImage = async (action) => {
    if (!action.image) {
      const images = this.imagesHashSelector();
      action.image = images[action.imageId];
    }

    const imageUrl = action.imageUrl;

    // if image is already uploaded, just return src
    if (imageUrl) return action;

    const blob = await BlobUtils.imageToBlob(action.image);
    action.imageUrl = await sessionService.uploadSessionImage(this.id, blob);
    return action;
  };

  sendMessage = async (message) => {
    const user = this.user();
    const sessionRef = this._generateSessionRef();
    const messagesRef = child(sessionRef, 'messages');
    const timestamp = this.getServerTimestamp();

    return push(messagesRef, {
      sender: user.name,
      text: message,
      senderId: user.id,
      createdTime: timestamp,
    });
  };

  removeHandlers = async () => {
    this.handlersToRemove.forEach((handler) => handler());
    this.handlersToRemove = [];
    await this.clearOnDisconnect();
  };

  onParticipantDisconnectReference = null;
  clearOnDisconnect = async () => {
    if (this.onParticipantDisconnectReference) {
      await this.onParticipantDisconnectReference.cancel();
      this.onParticipantDisconnectReference = null;
    }
  };

  disconnect = async () => {
    const user = this.user();
    const sessionRef = this._generateSessionRef();
    const participantsRef = child(sessionRef, 'participants');
    const userRef = child(participantsRef, user.id);
    const timestamp = this.getServerTimestamp();
    const userSnapshot = await get(userRef);

    if (userSnapshot.val()) {
      await update(userRef, {
        online: false,
        leftTime: timestamp,
        status: UserPresenceStatus.offline,
        statusChangeDate: timestamp,
      });
    }

    await this.removeHandlers();
    this.serverTimeOffset = 0;
  };

  createPage = async (
    pageConfig = {
      backgroundType: GridType.DOT,
      color: BACKGROUND_COLOR,
      deleted: false,
    },
    activePageId,
    oldSessionRef = null,
    setAsActive = true
  ) => {
    const sessionRef = oldSessionRef || this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const pagesDataRef = child(pagesRef, 'data');
    const pagesSnapshot = await get(pagesDataRef);

    const pagesCount = pagesSnapshot.val().filter((p) => !p.deleted).length;

    if (pagesCount >= 50) {
      return Promise.resolve();
    }

    const orderedPagesDataRef = query(pagesDataRef, orderByKey());
    const recentPageRef = query(orderedPagesDataRef, limitToLast(1));

    const snapshot = await new Promise((resolve) => {
      onChildAdded(recentPageRef, resolve);
    });
    const oldId = Number(snapshot.key) || 0;
    const newId = oldId + 1;

    await set(child(pagesDataRef, newId.toString()), {
      ...pageConfig,
      backgroundType: pageConfig.backgroundType || GridType.DOT,
      color: pageConfig.color || BACKGROUND_COLOR,
      deleted: pageConfig.deleted || false,
    });

    const ordersRef = child(pagesRef, 'orders');

    await runTransaction(ordersRef, (currentData) => {
      if (currentData === null) return null;

      const activePageIndex = currentData.indexOf(activePageId);

      return [
        ...currentData.slice(0, activePageIndex + 1),
        newId,
        ...currentData.slice(activePageIndex + 1),
      ];
    });

    if (setAsActive) {
      this.setActivePage(newId, sessionRef);
    }

    return newId;
  };

  setActivePage = async (id, sessionRef = null) => {
    const action = DrawingActionsFactory.createActivePageAction(
      id,
      this.user().id
    );

    dispatch(addActions([action]));
    return this.sendAction(action, sessionRef);
  };

  setPageOrders = async (orders) => {
    const sessionRef = this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const ordersRef = child(pagesRef, 'orders');

    await set(ordersRef, orders);
  };

  getPageOrders = async () => {
    const sessionRef = await this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const ordersRef = child(pagesRef, 'orders');
    const snapshot = await get(ordersRef);

    return snapshot.val();
  };

  editPage = async (id, type, color) => {
    const sessionRef = this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const pagesDataRef = child(pagesRef, 'data');
    const pageIdRef = child(pagesDataRef, id.toString());

    const updatedPage = await update(pageIdRef, {
      backgroundType: type,
      color,
    });

    return updatedPage;
  };

  copyPage = async (id, actions) => {
    const sessionRef = this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const pageDataRef = child(pagesRef, 'data');
    const pageIdRef = child(pageDataRef, id.toString());

    const pageSnapshot = await get(pageIdRef);
    const page = pageSnapshot.val();
    const newPage = {
      backgroundType: page.backgroundType,
      color: page.color || BACKGROUND_COLOR,
    };

    if (page.width && page.height) {
      newPage.width = page.width;
      newPage.height = page.height;
    }

    const newPageId = await this.createPage(newPage, id, false);

    const idsMap = {};
    for await (const action of actions) {
      const newId = guid();
      idsMap[action.id] = newId;
      const newAction = cloneDeep(action);
      newAction.setPageNumber(newPageId);
      newAction.setId(newId);
      newAction.setCreatorId(this.user().id);
      if (action.targetId) {
        newAction.targetId = idsMap[action.targetId];
      }
      await this.sendAction(newAction);
    }

    await this.setActivePage(newPageId);

    return newPageId;
  };

  deletePage = async (id) => {
    const sessionRef = this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const pageDataRef = child(pagesRef, 'data');
    const pageIdRef = child(pageDataRef, id.toString());
    const deletedPageRef = child(pageIdRef, 'deleted');
    await set(deletedPageRef, true);

    const [orders, activePage] = await Promise.all([
      this.getPageOrders(),
      this.getActivePage(),
    ]);
    const oldIndex = orders.indexOf(id);
    const newOrders = orders.filter((o) => o !== id);

    if (activePage === id) {
      let newActivePage = newOrders[oldIndex];
      if (!newActivePage) {
        newActivePage = newOrders[newOrders.length - 1];
      }

      await this.setActivePage(newActivePage);
    }

    return this.setPageOrders(newOrders);
  };

  setDrawingEnabledForParticipant = async (participantId, drawingEnabled) => {
    const sessionRef = this._generateSessionRef();
    const participantsRef = child(sessionRef, 'participants');
    const participantIdRef = child(participantsRef, participantId);
    const drawingEnabledRef = child(participantIdRef, 'drawingEnabled');

    await set(drawingEnabledRef, drawingEnabled);
  };

  listenForServerTimeOffset = () => {
    const offsetRef = this._getTimeOffsetRef();

    onValue(offsetRef, (snapshot) => {
      this.serverTimeOffset = snapshot.val() || 0;
    });

    this.handlersToRemove.push(() => off(offsetRef));
  };

  sessionExist = async () => {
    const sessionRef = this._generateSessionRef();
    const ownerIdRef = child(sessionRef, 'ownerId');
    const ownerId = await get(ownerIdRef);

    if (!ownerId.exists()) throw new Error('No session found!');

    return ownerId;
  };

  startTimeHandler = async () => {
    const sessionRef = this._generateSessionRef();
    const startTimeRef = child(sessionRef, 'startTime');

    const snapshot = await get(startTimeRef);

    if (snapshot.exists()) {
      dispatch(sessionActions.setStartTime(snapshot.val()));
      return;
    }

    onValue(startTimeRef, (snapshot) => {
      const startTime = snapshot.val();
      if (!startTime) return;

      dispatch(sessionActions.setStartTime(startTime));
    });

    this.handlersToRemove.push(() => off(startTimeRef));
  };

  assessmentHandler = () => {
    const sessionRef = this._generateSessionRef();
    const assessmentRef = child(sessionRef, 'assessment');

    onValue(assessmentRef, (snapshot) => {
      const assessmentInfo = snapshot.val();

      dispatch(sessionActions.changeAssessmentInfo(assessmentInfo));
    });

    this.handlersToRemove.push(() => off(assessmentRef));
  };

  sessionStopped = async () => {
    const sessionRef = this._generateSessionRef();
    const endTimeRef = child(sessionRef, 'endTime');

    const endTimeSnapshot = await get(endTimeRef);

    if (endTimeSnapshot.exists()) {
      dispatch(sessionActions.setEndTime(endTimeSnapshot.val()));
      return;
    }

    onValue(endTimeRef, (snapshot) => {
      const endTime = snapshot.val();
      if (!endTime) return;

      dispatch(sessionActions.setEndTime(endTime));
      dispatch(sessionActions.setFinished(true));
    });

    this.handlersToRemove.push(() => off(endTimeRef));
  };

  initializeSession = async () => {
    const sessionRef = this._generateSessionRef();
    const [ownerId, initialized] = await Promise.all([
      get(child(sessionRef, 'ownerId')),
      get(child(sessionRef, 'initialized')),
    ]);

    if (
      ownerId.val() !== this.user().id ||
      (initialized.exists() && initialized.val())
    )
      return;

    const activePageId = 0;
    const firstActionIndex = 0;
    const activePageAction = DrawingActionsFactory.createActivePageAction(
      activePageId,
      this.user().id
    );

    const timestamp = this.getServerTimestamp();

    await update(sessionRef, {
      pages: {
        data: {
          [activePageId]: {
            deleted: false,
            backgroundType: GridType.DOT,
            color: BACKGROUND_COLOR,
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        orders: [activePageId],
      },
      initialized: true,
      actions: {
        [firstActionIndex]: {
          ...activePageAction.serialize(),
          createdTime: timestamp,
        },
      },
    });
  };

  conferenceHandler = () => {
    const sessionRef = this._generateSessionRef();
    const conferenceRef = child(sessionRef, 'conference');

    onValue(conferenceRef, (snapshot) => {
      const conference = snapshot.val();

      if (!conference) {
        dispatch(sessionActions.setConference(null));
      } else {
        dispatch(
          sessionActions.setConference({ provider: conference.provider })
        );
      }
    });

    this.handlersToRemove.push(() => off(conferenceRef));
  };

  optionsHandler = () => {
    const sessionRef = this._generateSessionRef();
    const optionsRef = child(sessionRef, 'options');
    const isHost = isCurrentUserHostSelector(store.getState());

    onValue(optionsRef, (snapshot) => {
      const options = snapshot.val();
      if (options === null) return;
      const lastActivePage = lastActivePageSelector(store.getState());

      dispatch(
        sessionActions.setSessionOptions({
          ...options,
          participantsPageNavigation:
            options.allowParticipantsPageNavigation || false,
        })
      );

      if (
        !isHost &&
        !options?.allowParticipantsPageNavigation &&
        lastActivePage !== null
      ) {
        dispatch(setActivePage(lastActivePage));
      }
    });

    this.handlersToRemove.push(() => off(optionsRef));
  };

  participantsHandler = async () => {
    const user = this.user();
    const ownerId = this.ownerId();
    const sessionRef = this._generateSessionRef();
    const participantsRef = child(sessionRef, 'participants');
    const currentParticipantQueryRef = child(participantsRef, user.id);
    const currentParticipantSnapshot = await get(currentParticipantQueryRef);
    const timestamp = this.getServerTimestamp();

    const participant = {
      name: user.name,
      profileImageUrl: user.profileImageUrl,
      online: true,
      lastJoinedTime: timestamp,
      leftTime: null,
      deviceId: deviceStorage.getItem('deviceId'),
      status: UserPresenceStatus.online,
      statusChangeDate: timestamp,
    };

    if (!currentParticipantSnapshot.exists()) {
      await set(currentParticipantQueryRef, {
        ...participant,
        joinedTime: timestamp,
        drawingEnabled: this.session.drawingEnabledOnJoin,
        online: true,
      });
    } else {
      await update(currentParticipantQueryRef, participant);
    }

    let firstSnapshot = true;
    const onlineStatusRef = this._getOnlineRef();

    onValue(onlineStatusRef, async (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      await this.clearOnDisconnect();
      const participantDisconnect = onDisconnect(currentParticipantQueryRef);

      this.onParticipantDisconnectReference = participantDisconnect;
      await participantDisconnect.update({
        status: UserPresenceStatus.offline,
        online: false,
        statusChangeDate: timestamp,
      });

      if (firstSnapshot) {
        firstSnapshot = false;
        return;
      }

      const isPageVisible = getIsDocumentVisible();
      const status = isPageVisible
        ? UserPresenceStatus.online
        : UserPresenceStatus.away;
      await update(currentParticipantQueryRef, {
        statusChangeDate: timestamp,
        status,
      });
    });

    onValue(
      child(currentParticipantQueryRef, 'drawingEnabled'),
      async (snapshot) => {
        const drawingEnabled = snapshot.val();
        dispatch(setDrawingEnabled(user.id, !!drawingEnabled));
      }
    );

    const participantsSnapshot = await get(participantsRef);

    const participants = [];
    participantsSnapshot.forEach((s) => {
      const participant = s.val();
      participant.id = s.key;
      participant.cursorColor =
        participant.id === ownerId ? HOST_CURSOR_COLOR : getNextCursorColor();

      if (participant.name) {
        participants.push(participant);
      }
    });
    const invitedContactsEmails = store.getState().session.invites.emails;
    const invitedContactsIds = store.getState().session.invites.contacts;
    dispatch(participantsAdded(participants));

    const { contacts, emails } = getFilteredInvitedParticipants(
      participants,
      invitedContactsEmails,
      invitedContactsIds
    );
    dispatch(
      updateInvitedContacts({
        contacts,
        emails,
      })
    );

    onChildRemoved(participantsRef, (snapshot) => {
      const participantId = snapshot.key;
      const participant = participants.find((p) => p.id === participantId);

      if (!participant) return;

      dispatch(removeParticipant(participantId));

      if (participantId === this.user().id) {
        dispatch(redirect('/'));
      }
    });

    onChildAdded(participantsRef, async (snapshot) => {
      const participant = snapshot.val();
      participant.id = snapshot.key;
      participant.cursorColor =
        participant.id === ownerId ? HOST_CURSOR_COLOR : getNextCursorColor();
      const participantIncluded = participants.some(
        (p) => p.id === participant.id
      );

      if (participantIncluded) return;
      const invites = store.getState().session.invites;
      const filteredEmails = invites.emails.filter(
        (email) => email !== participant.email
      );
      const filteredContacts = invites.contacts.filter(
        (contactId) => contactId !== participant.id
      );

      dispatch(
        updateInvitedContacts({
          contacts: filteredContacts,
          emails: filteredEmails,
        })
      );
      dispatch(setShouldUpdateThumbnail(true));

      if (participant.name) {
        dispatch(addParticipant(participant));
        dispatch(participantJoined(participant));
      }
    });

    onChildChanged(participantsRef, (snapshot) => {
      const updateParticipant = snapshot.val();
      updateParticipant.id = snapshot.key;
      const participants = this.getParticipants();
      const oldParticipant = participants.find(
        (participant) => participant.id === updateParticipant.id
      );

      if (
        oldParticipant &&
        oldParticipant.status === UserPresenceStatus.offline &&
        updateParticipant.status !== oldParticipant.status
      ) {
        dispatch(participantJoined(updateParticipant));
      }

      if (
        oldParticipant &&
        oldParticipant.status !== UserPresenceStatus.offline &&
        updateParticipant.status === UserPresenceStatus.offline
      ) {
        dispatch(participantLeft(updateParticipant));
      }

      dispatch(participantUpdated(updateParticipant));
    });

    this.handlersToRemove.push(() => {
      off(participantsRef);
      off(child(currentParticipantQueryRef, 'drawingEnabled'));
      off(onlineStatusRef);
    });
  };

  getLastMessagesQueryRef = (messagesRef, lastMessageKey) => {
    const orderedMessagesRef = query(messagesRef, orderByKey());

    return query(orderedMessagesRef, startAt(`${lastMessageKey}_`));
  };

  messagesHandler = async () => {
    const sessionRef = this._generateSessionRef();
    const messagesRef = child(sessionRef, 'messages');
    const messagesSnapshot = await get(messagesRef);

    const messages = [];
    let lastMessageKey = null;
    messagesSnapshot.forEach((snapshot) => {
      const message = snapshot.val();
      message.id = snapshot.key;
      messages.push(message);
      lastMessageKey = snapshot.key;
    });

    dispatch(messagesReceived(messages));

    const queryRef = lastMessageKey
      ? this.getLastMessagesQueryRef(messagesRef, lastMessageKey)
      : messagesRef;

    onChildAdded(queryRef, (newMessageSnapshot) => {
      const message = newMessageSnapshot.val();
      message.id = newMessageSnapshot.key;

      dispatch(messageReceived(message));
    });

    this.handlersToRemove.push(() => off(queryRef));
  };

  pagesHandler = () => {
    const sessionRef = this._generateSessionRef();
    const pagesRef = child(sessionRef, 'pages');
    const pagesDataRef = child(pagesRef, 'data');
    const ordersRef = child(pagesRef, 'orders');

    onChildAdded(pagesDataRef, async (snapshot) => {
      const page = snapshot.val();
      page.id = Number(snapshot.key);
      if (page.backgroundImageUrl) {
        page.backgroundImage = await Promise.resolve(
          page.backgroundImageUrl
        ).then(
          (url) =>
            new Promise((resolve) => {
              const img = new Image();
              img.onload = () => resolve(img);
              img.setAttribute('crossOrigin', 'anonymous');
              img.src = url;
            })
        );
      }
      dispatch(addPage(page));
    });

    onChildChanged(pagesDataRef, async (snapshot) => {
      const page = snapshot.val();
      page.id = Number(snapshot.key);
      dispatch(changePage(page));
    });

    onValue(ordersRef, (snapshot) => {
      const prevOrders = store.getState().board.pages.orders;
      const newOrders = [];

      snapshot.forEach((childSnapshot) => {
        newOrders.push(childSnapshot.val());
      });

      const shouldUpdate = !newOrders.every(
        (item, index) => prevOrders[index] === item
      );

      if (shouldUpdate) {
        dispatch(setOrders(newOrders));
      }
    });

    this.handlersToRemove.push(() => {
      off(pagesDataRef);
      off(ordersRef);
    });
  };

  actionsHandler = async () => {
    const sessionRef = this._generateSessionRef();
    const actionsRef = child(sessionRef, 'actions');
    const actionsSnapshot = await get(actionsRef);
    const ownerId = await get(child(sessionRef, 'ownerId'));
    const actions = [];
    let lastOrder = 0;
    actionsSnapshot.forEach((s) => {
      const action = deserializeAction(s);
      actions.push(action);
      lastOrder = action.order;
    });
    const participantsPageNavigation =
      store.getState().session.participantsPageNavigation;

    if (ownerId.val() !== this.user().id && participantsPageNavigation) {
      const lastActivePageAction = findLast(
        actions,
        (action) => action instanceof ActivePageAction
      );
      const pageId = lastActivePageAction.getPageNumber();
      dispatch(setActivePage(pageId));
    }

    dispatch(addActions(actions));
    const actionOrderByKeyRef = query(actionsRef, orderByKey());
    const queryRef = query(
      actionOrderByKeyRef,
      startAt((lastOrder + 1).toString())
    );

    onChildAdded(queryRef, (newActionSnapshot) => {
      const action = deserializeAction(newActionSnapshot);

      const localAction = store.getState().board.actions.byId[action.id];

      if (!localAction) {
        dispatch(addActions([action]));
      } else {
        dispatch(updateAction(action));
      }
    });

    onChildChanged(actionsRef, (s) => {
      const action = deserializeAction(s);
      dispatch(updateAction(action));
    });

    this.handlersToRemove.push(() => {
      off(queryRef);
      off(actionsRef);
    });
  };

  temporaryActionHandler = () => {
    const sessionRef = this._generateSessionRef();
    const temporaryActionsRef = child(sessionRef, 'temporaryActions');

    const removeTimeouts = {};

    onChildRemoved(temporaryActionsRef, (snapshot) => {
      const action = deserializeAction(snapshot, false);
      // pointerLine will delete itself after animation
      if (action.name === 'pointLine') return;
      const timeout = removeTimeouts[action.getId()];
      if (timeout) {
        clearTimeout(timeout);
      }
      dispatch(temporaryDraingActionsActions.removeAction(action));
    });

    onChildChanged(temporaryActionsRef, (snapshot) => {
      const action = deserializeAction(snapshot, false);
      clearTimeout(removeTimeouts[action.getId()]);
      removeTimeouts[action.getId()] = setTimeout(
        () => dispatch(temporaryDraingActionsActions.removeAction(action)),
        5000
      );
      dispatch(temporaryDraingActionsActions.updateAction(action));
    });

    onChildAdded(temporaryActionsRef, (snapshot) => {
      const action = deserializeAction(snapshot, false);
      const timeout = removeTimeouts[action.getId()];
      if (timeout) {
        clearTimeout(timeout);
      }
      removeTimeouts[action.getId()] = setTimeout(
        () => dispatch(temporaryDraingActionsActions.removeAction(action)),
        5000
      );
      dispatch(temporaryDraingActionsActions.addAction(action));
    });

    this.handlersToRemove.push(() => off(temporaryActionsRef));
  };

  createTemporaryAction = async (action) => {
    const sessionRef = this._generateSessionRef();
    const temporaryActionsRef = child(sessionRef, 'temporaryActions');
    const actionWithIdRef = child(temporaryActionsRef, action.getId());

    await set(actionWithIdRef, action.serialize());
  };

  updateTemporaryAction = async (action) => {
    const sessionRef = this._generateSessionRef();
    const temporaryActionsRef = child(sessionRef, 'temporaryActions');
    const actionIdRef = child(temporaryActionsRef, action.getId());
    const timestamp = this.getServerTimestamp();

    await update(actionIdRef, {
      x: numberUtil.toOneDecimal(action.point.x),
      y: numberUtil.toOneDecimal(action.point.y),
      updatedAt: timestamp,
    });
  };

  deleteTemporaryAction = async (action) => {
    const sessionRef = this._generateSessionRef();
    const temporaryActionsRef = child(sessionRef, 'temporaryActions');
    const actionIdRef = child(temporaryActionsRef, action.getId());

    await remove(actionIdRef);
  };

  initializeFromImages = async (id, pagesData, config) => {
    await this.removeHandlers();

    const sessionRef = this._generateSessionRef(id, config);
    const [ownerId, initialized] = await Promise.all([
      get(child(sessionRef, 'ownerId')),
      get(child(sessionRef, 'initialized')),
    ]);
    const userId = this.user().id;

    if (ownerId.val() !== userId || initialized.exists() || initialized.val())
      return;

    const activePageId = 0;

    const pages = pagesData.map(({ page }) => ({
      deleted: false,
      backgroundType: GridType.DOT,
      color: BACKGROUND_COLOR,
      width: page.width,
      height: page.height,
    }));

    const actions = [
      DrawingActionsFactory.createActivePageAction(activePageId, userId),
      ...pagesData.map(({ image }, index) =>
        DrawingActionsFactory.createLockedImageDrawingAction(
          userId,
          image.x,
          image.y,
          image.width,
          image.height,
          image.url,
          index
        )
      ),
    ];

    const timestamp = this.getServerTimestamp();

    await update(sessionRef, {
      pages: {
        data: pages.reduce(
          (acc, val, index) => ({
            ...acc,
            [index]: val,
          }),
          {}
        ),
        orders: pages.map((_, index) => index),
      },
      initialized: true,
      actions: actions.reduce(
        (acc, val, index) => ({
          ...acc,
          [index]: {
            ...val.serialize(),
            createdTime: timestamp,
          },
        }),
        {}
      ),
    });
  };

  importImagePages = async (
    pagesData,
    activePageId,
    id,
    config,
    changeActivePage = true
  ) => {
    if (pagesData.length === 0) return;

    const sessionId = id || this.id;
    const firebaseConfig = config || this.config;
    const userId = this.user().id;
    const sessionRef = this._generateSessionRef(sessionId, firebaseConfig);

    const pagesRef = child(sessionRef, 'pages');
    const pagesDataRef = child(pagesRef, 'data');
    const activePageRef = child(pagesDataRef, activePageId.toString());

    const pageSnapshot = await get(activePageRef);
    const lastPage = pageSnapshot.val();
    const color = lastPage.color;
    const backgroundType = lastPage.backgroundType;

    const pagesWithImage = pagesData.map(({ page, image }) => ({
      page: {
        deleted: false,
        backgroundType,
        color: color,
        width: page.width,
        height: page.height,
      },
      image,
    }));

    let lastPageId = activePageId;
    let firstCreatedPageId = null;
    for await (const [index, { page, image }] of pagesWithImage.entries()) {
      lastPageId = await this.createPage(page, lastPageId, sessionRef, false);
      if (index === 0) {
        firstCreatedPageId = lastPageId;
      }
      const imageAction = DrawingActionsFactory.createLockedImageDrawingAction(
        userId,
        image.x,
        image.y,
        image.width,
        image.height,
        image.url,
        lastPageId
      );

      await this.sendAction(imageAction, sessionRef);
    }
    if (changeActivePage) {
      await this.setActivePage(firstCreatedPageId);
    }
  };

  updatePresence = async (present) => {
    const user = this.user();
    const sessionRef = this._generateSessionRef();
    const participantsRef = child(sessionRef, 'participants');
    const currentParticipantQueryRef = child(participantsRef, user.id);

    const snapshot = await get(currentParticipantQueryRef);

    const currentParticipant = snapshot.val();
    const timestamp = this.getServerTimestamp();

    if (currentParticipant && currentParticipant.online) {
      const participantUpdate = {
        statusChangeDate: timestamp,
      };
      if (present) {
        participantUpdate.status = UserPresenceStatus.online;
      } else {
        participantUpdate.status = UserPresenceStatus.away;
      }
      await update(currentParticipantQueryRef, participantUpdate);
    }
  };

  listenForNewQuestion = (onNewQuestion) => {
    const sessionQuestionRef = this._generateSessionQuestionRef();
    const questionsRef = child(sessionQuestionRef, 'questions');
    const questionByCreationDateRef = query(
      questionsRef,
      orderByChild('creationDate')
    );
    const recentQuestionRef = query(questionByCreationDateRef, limitToLast(1));

    onChildAdded(recentQuestionRef, async (snapshot) => {
      const question = snapshot.val();
      question.id = snapshot.key;

      onNewQuestion(question);

      const isQuestionExpired =
        question.creationDate + question.duration - this.serverTimeOffset <
        Date.now().valueOf();
      if (isQuestionExpired) return;

      const answersRef = child(sessionQuestionRef, 'answers');
      const questionByIdRef = child(answersRef, question.id);
      const userByIdRef = child(questionByIdRef, this.user().id);
      const timestamp = this.getServerTimestamp();

      await runTransaction(userByIdRef, (currentData) => {
        if (currentData) return undefined;

        return {
          optionId: null,
          date: timestamp,
        };
      });
    });

    return () => off(recentQuestionRef);
  };

  listenForQuestions = (onQuestions) => {
    const sessionQuestionRef = this._generateSessionQuestionRef();
    const questionsRef = child(sessionQuestionRef, 'questions');

    onValue(questionsRef, (snapshot) => {
      const questionsData = snapshot.val();
      if (questionsData) {
        const questions = Object.entries(questionsData).map(([id, data]) => ({
          id,
          ...data,
        }));
        onQuestions(questions);
      }
    });

    return () => off(questionsRef);
  };

  listenForAnswers = (onAnswers) => {
    const sessionQuestionRef = this._generateSessionQuestionRef();
    const answersRef = child(sessionQuestionRef, 'answers');

    onValue(answersRef, (snapshot) => {
      const answers = snapshot.val();
      onAnswers(answers);
    });

    return () => off(answersRef);
  };

  sendQuestionAnswer = async (questionId, values) => {
    const user = this.user();
    const sessionQuestionRef = this._generateSessionQuestionRef();
    const answersRef = child(sessionQuestionRef, 'answers');
    const questionByIdRef = child(answersRef, questionId);
    const userByIdRef = child(questionByIdRef, user.id);
    const timestamp = this.getServerTimestamp();

    await set(userByIdRef, {
      values,
      date: timestamp,
    });
  };
}

const fireBaseService = new FireBaseService();

export default fireBaseService;

export { setStore };
