import React from 'react';
import Typography from '@mui/material/Typography';

type ButtonContentProps = {
  price: number;
  discountedPrice: number;
};

const ButtonContent = ({ price, discountedPrice }: ButtonContentProps) => {
  if (!discountedPrice) {
    return <Typography color="inherit">Pay ${price.toFixed(2)}</Typography>;
  }

  return (
    <Typography component="span" color="inherit">
      Pay ${discountedPrice.toFixed(2)}{' '}
      <Typography
        display="inline"
        color="inherit"
        sx={{
          textDecoration: 'line-through',
        }}
      >
        ${price}
      </Typography>
    </Typography>
  );
};

export default ButtonContent;
