import React, { useCallback, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import BaseTool from './BaseTool';
import ToolNames from '../ToolNames';
import keyMap from '../../../config/hotkeys';
import MarkerIcon from '../../../ui/atoms/Icons/Shapes/MarkerIcon';
import DrawingButton from '../DrawingButton/DrawingButton';
import PencilIcon from '../../../ui/atoms/Icons/PencilIcon';
import ToolOptionsLayout from '../ToolOptionsLayout/ToolOptionsLayout';
import Typography from '../../../ui/atoms/Typography';
import ColorPicker from '../ColorPicker';
import ToolSizeControl from '../ToolSizeControl/ToolSizeControl';
import analyticsService from '../../../common/services/analytics.service';

const settingsFactory = ({
  selectPen,
  activeTool,
  selectMarker,
  activeToolHotkey,
  updateColor,
  selectedToolSizes,
  updateFontSize,
}) => (
  <ToolOptionsLayout>
    <Box
      display="flex"
      alignItems="center"
      sx={(theme) => ({
        paddingBottom: '16px',
        borderBottom: `1px solid ${theme.background.bg5}`,
      })}
    >
      <Box display="flex" gap={0.75} flex={1}>
        <DrawingButton
          key="Pencil"
          icon={<PencilIcon />}
          onClick={selectPen}
          tooltipTitle={`Pen (${keyMap.pen.description})`}
          tooltipPlacement="bottom"
          active={activeTool === ToolNames.Pen}
        />
        <DrawingButton
          key="Marker"
          icon={<MarkerIcon />}
          onClick={selectMarker}
          tooltipTitle={`Marker (${keyMap.marker.description})`}
          tooltipPlacement="bottom"
          active={activeTool === ToolNames.Marker}
        />
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.alpha20,
          borderRadius: 4,
          width: 20,
          height: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Typography
          variant="p6"
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
        >
          {activeToolHotkey}
        </Typography>
      </Box>
    </Box>
    <ColorPicker onChange={updateColor(activeTool)} isLarge />
    {!!selectedToolSizes.length && (
      <ToolSizeControl
        sizes={selectedToolSizes}
        onChange={updateFontSize(activeTool)}
      />
    )}
  </ToolOptionsLayout>
);

const PenTool = ({
  activeTool,
  selectedTool,
  selectTool,
  updateColor,
  updateFontSize,
}) => {
  const selectPen = useCallback(() => {
    analyticsService.event('Tool Selected', {
      tool: 'pen',
    });
    selectTool(ToolNames.Pen);
  }, [selectTool]);

  const selectMarker = useCallback(() => {
    analyticsService.event('Tool Selected', {
      tool: 'marker',
    });
    selectTool(ToolNames.Marker);
  }, [selectTool]);

  const renderSelectedTool = () => {
    switch (activeTool) {
      case ToolNames.Pen:
        return <EditIcon />;
      case ToolNames.Marker:
        return <MarkerIcon />;
      default:
        return null;
    }
  };

  const icon = renderSelectedTool();
  const activeToolHotkey = useMemo(
    () => selectedTool.name.charAt(0).toUpperCase(),
    [selectedTool.name]
  );

  const selectedToolSizes = useMemo(() => {
    if (selectedTool.name === ToolNames.Pen) return [2, 4, 6, 10, 14, 16, 20];
    if (selectedTool.name === ToolNames.Marker)
      return [10, 15, 20, 25, 30, 35, 40];

    return [];
  }, [selectedTool.name]);

  const settings = useMemo(
    () =>
      settingsFactory({
        selectPen,
        activeTool,
        selectMarker,
        activeToolHotkey,
        updateColor,
        selectedToolSizes,
        updateFontSize,
      }),
    [
      selectPen,
      activeTool,
      selectMarker,
      activeToolHotkey,
      updateColor,
      selectedToolSizes,
      updateFontSize,
    ]
  );

  const showSettings =
    selectedTool.name === ToolNames.Pen ||
    selectedTool.name === ToolNames.Marker;

  return (
    <BaseTool
      icon={icon}
      tooltipText={`Pen (${keyMap.pen.description})/Marker (${keyMap.marker.description})`}
      settings={settings}
      selectedTool={selectedTool}
      activeTool={activeTool}
      selectTool={selectTool}
      showSettings={showSettings}
      showToolOptionsIcon={true}
    />
  );
};

export default PenTool;
