import { StepType } from '@reactour/tour';
import PopoverContent from './PopoverContent';
import createProductTourStyles from './createProductTourStyles';

const steps: StepType[] = [
  {
    selector: '.product-tour-step-1.no-group',
    content: (
      <PopoverContent title="Get Started" description="First, create a Board" />
    ),
    padding: {
      mask: 0,
    },
    styles: createProductTourStyles({
      maskArea: (base) => ({
        ...base,
        rx: 16,
      }),
    }),
  },
  {
    selector: '.product-tour-step-3',
    content: (
      <PopoverContent
        title="Add Students"
        description="Add participants to start the collaboration"
      />
    ),
    padding: {
      mask: 0,
    },
    styles: createProductTourStyles({
      maskArea: (base) => ({
        ...base,
        rx: 14,
      }),
    }),
  },
  {
    selector: '.product-tour-step-4',
    content: (
      <PopoverContent
        title="Invite"
        description="Either send a public link/code or invite students by email to join the classroom"
      />
    ),
    padding: {
      mask: 0,
    },
    styles: createProductTourStyles({
      maskArea: (base) => ({
        ...base,
        rx: 12,
      }),
      maskWrapper: (baseStyles) => ({
        ...baseStyles,
        opacity: 0,
      }),
    }),
  },
  {
    selector: '.product-tour-step-5',
    content: (
      <PopoverContent
        title="Start Call"
        description="Launch audio or video call with students and teach them in real-time"
      />
    ),
    padding: {
      mask: 0,
    },
    styles: createProductTourStyles({
      maskArea: (base) => ({
        ...base,
        rx: 14,
      }),
    }),
  },
];

export default steps;
