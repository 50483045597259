import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import useDialog from '../../../../common/hooks/useDialog';
import fireBaseService from '../../../../common/services/firebaseService';
import QuestionDialogTitle from '../common/QuestionDialogTitle';
import QuestionTimer from '../common/QuestionTimer';
import FirebaseQuestionDto from '../dto/FirebaseQuestionDto';
import AnswerOptionDto from '../dto/AnswerOptionDto';
import SingleChoiceAnswer from './SingleChoiceAnswer';
import QuestionType from '../types/QuestionType';
import MultiChoiceAnswer from './MultiChoiceAnswer';
import analyticsService from '../../../../common/services/analytics.service';
import usePageVisibility from '../../../../common/hooks/PageVisibility';

const AnswerQuestion = () => {
  const visibility = usePageVisibility();

  const [selectedValue, setSelectedValue] = useState<AnswerOptionDto[]>([]);

  const [question, setQuestion] = useState<FirebaseQuestionDto | null>(null);
  const {
    open: dialogOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose,
  } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const updateQuestion = (newQuestion: FirebaseQuestionDto) => {
      if (
        newQuestion.creationDate + newQuestion.duration >
        Date.now().valueOf()
      ) {
        setQuestion(newQuestion);
        onDialogOpen();
      }
    };

    const cancel = fireBaseService.listenForNewQuestion(updateQuestion);
    return () => {
      cancel();
    };
  }, [setQuestion, onDialogOpen]);

  const setInitialState = useCallback(() => {
    setQuestion(null);
  }, [setQuestion]);

  const sendAnswer = useCallback(
    async (values: AnswerOptionDto[]) => {
      if (values.length === 0) {
        enqueueSnackbar('Select an option', {
          variant: 'error',
        });

        return;
      }

      await fireBaseService.sendQuestionAnswer(question?.id, values);

      analyticsService.event('Quiz Answered', {
        quizId: question?.id || '',
      });

      onDialogClose();
    },
    [enqueueSnackbar, onDialogClose, question]
  );

  const timeExpired = useCallback(() => {
    onDialogClose();
  }, [onDialogClose]);

  const timerDuration = useMemo(() => {
    if (!question) return null;

    const endTime = question.creationDate + question.duration;
    return (
      (endTime - Date.now().valueOf() - fireBaseService.serverTimeOffset) / 1000
    );
  }, [question, visibility]);

  if (!question || !timerDuration || timerDuration <= 0) return null;

  return (
    <Dialog
      open={dialogOpen}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.spacing(2),
          overflow: 'unset',
        }),
      }}
      TransitionProps={{
        onExited: setInitialState,
      }}
    >
      <QuestionDialogTitle title="QUIZ" />
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid container item alignItems="center" justifyContent="center">
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: theme.spacing(12),
                height: theme.spacing(12),
                backgroundColor: '#f4f6f8',
                borderRadius: theme.spacing(12),
              })}
            >
              <QuestionTimer
                key={timerDuration}
                duration={question.duration / 1000}
                initialRemainingTime={timerDuration}
                onComplete={timeExpired}
              />
            </Box>
          </Grid>
          <Grid container item justifyContent="center">
            <Box fontWeight="fontWeightBold" whiteSpace="pre-line">
              <Typography variant="body1" gutterBottom align="center">
                {question.content.text}
              </Typography>
            </Box>
          </Grid>
          <Grid container item alignItems="center" justifyContent="center" xs>
            SELECT CORRECT ANSWER
          </Grid>
          <Grid container item alignItems="center" justifyContent="center">
            {question.options &&
              (question.type === QuestionType.SINGLE_CHOICE ||
                question.type === QuestionType.BOOLEAN) && (
                <SingleChoiceAnswer
                  options={question.options}
                  setSelectedValue={setSelectedValue}
                />
              )}
            {question.options &&
              question.type === QuestionType.MULTI_CHOICE && (
                <MultiChoiceAnswer
                  options={question.options}
                  setSelectedValue={setSelectedValue}
                />
              )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => sendAnswer(selectedValue)}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnswerQuestion;
