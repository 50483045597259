import React, { useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Input from '../../../ui/atoms/Input';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import Button from '../../../ui/atoms/Button';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import LinkVariantIcon from '../../../ui/atoms/Icons/LinkVariantIcon';
import analyticsService from '../../../common/services/analytics.service';

const PublicLink = ({ url }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onClick = useCallback(() => {
    analyticsService.event('Board Invite Public Link Copy Click');
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      autoHideDuration: 1500,
    });
  }, []);

  return (
    <Box display="flex" gap="10px">
      <Input
        value={url}
        readOnly
        margin="none"
        fullWidth
        label="Link"
        helperText="To join by clicking on the link"
        size={InputSize.S}
        variant={InputVariant.CONTAINED}
      />
      <Box pt="25px">
        <CopyToClipboard text={url}>
          <Button
            size={ButtonSizeTypes.S}
            variant={ButtonVariantTypes.OUTLINED}
            startIcon={<LinkVariantIcon />}
            onClick={onClick}
          >
            Copy
          </Button>
        </CopyToClipboard>
      </Box>
    </Box>
  );
};

export default PublicLink;
