import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import authService from '../../../common/services/auth.service';
import analyticsService from '../../../common/services/analytics.service';
import LoadingButton from '../../../components/common/LoadingButton';
import PersonalInformationQuestionDto from '../../../common/services/dto/auth/PersonalInformationQuestionDto';
import PersonalInformationAnswerDto from '../../../common/services/dto/auth/PersonalInformationAnswerDto';
import PersonalInformationQuestionType from '../../../common/services/dto/auth/PersonalInformationQuestionType';
import RequestPersonalInformationQuestion from './RequestPersonalInformationQuestion';
import { submitPersonalInformationAnswers } from '../../../common/actions/userActions';
import FormDataType from './FormDataType';
import PropertiesDto from '../../../common/services/dto/analytics/PropertiesDto';
import * as userActions from '../../../common/actions/userActions';

type Props = {
  questions: PersonalInformationQuestionDto[];
  isFetching: boolean;
};

const transformUserChoices = (
  userChoices: string[],
  currentQuestion: PersonalInformationQuestionDto | undefined
) => {
  if (!currentQuestion) return [];

  return userChoices.map((optionText: string) => {
    const filteredOption = currentQuestion.options.find(
      (option: { text: string }) => option.text === optionText
    );
    return filteredOption?.key || '';
  });
};

const RequestPersonalInformationModal = ({ questions, isFetching }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const dispatchSubmit = useCallback(() => {
    dispatch(submitPersonalInformationAnswers());
  }, [dispatch]);

  const {
    handleSubmit,
    resetField,
    control,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  const onSubmit = useCallback(
    handleSubmit(async (formData) => {
      const data: PersonalInformationAnswerDto[] = Object.keys(formData)
        .filter((questionKey) => Boolean(formData[questionKey]))
        .map((questionKey) => {
          const questionData = get(formData, questionKey);
          const { isOther, userChoices, userInput } = questionData;
          const currentQuestion = questions.find(
            (question) => question.key === questionKey
          );
          return {
            questionKey,
            userChoices: userChoices
              ? transformUserChoices(userChoices, currentQuestion)
              : [],
            userInput: userInput?.trim() || null,
            isOther,
          };
        });

      try {
        await authService.setPersonalInformation(data);

        const properties = data.reduce<PropertiesDto>((acc, cur) => {
          if (cur.userInput && !cur.isOther) {
            return {
              ...acc,
              [`question_${cur.questionKey}`]: cur.userInput,
            };
          }

          const propertyValue = cur.userChoices;

          if (cur.userInput && cur.isOther) {
            propertyValue.push(cur.userInput);
          }

          return {
            ...acc,
            [`question_${cur.questionKey}`]: propertyValue,
          };
        }, {});

        analyticsService.setUserProperties(properties);
        dispatch(userActions.getUser());
        dispatchSubmit();
      } catch (e) {
        enqueueSnackbar('Error occurred, please try again', {
          autoHideDuration: 3000,
          variant: 'error',
        });
      }
    }),
    [questions, enqueueSnackbar, dispatch, dispatchSubmit]
  );

  const values = useWatch({ control });

  const onChange = useCallback(
    (questionKey: any) => {
      const sliceFromQuestion = questions.find(
        (question) => question.key === questionKey
      );

      if (sliceFromQuestion === undefined) return;

      const index = questions.indexOf(sliceFromQuestion) + 1;

      const keysPresent = Object.keys(values);
      const keysToDelete = questions
        .slice(index)
        .map((question) => question.key)
        .filter((key) => keysPresent.includes(key));

      keysToDelete.forEach((key) => resetField(key));
    },
    [values, questions, resetField]
  );

  const parser = useCallback(
    (value: any, questionKey: any): FormDataType | undefined => {
      const currentQuestion = questions.find(
        (question) => question.key === questionKey
      );

      if (!currentQuestion) {
        throw new Error('No such question');
      }

      if (
        currentQuestion.type === PersonalInformationQuestionType.MultiChoice
      ) {
        if (value.length === 0) {
          return undefined;
        }

        const isOther = value.includes('Other');

        return {
          userChoices: value
            .filter((optionText: string) => optionText !== 'Other')
            .map(
              (optionText: string) =>
                currentQuestion.options.find(
                  (option) => option.text === optionText
                )?.text
            ),
          userInput: '',
          isOther,
        };
      }

      if (
        currentQuestion.type === PersonalInformationQuestionType.SingleChoice
      ) {
        const isOther = value === 'Other';

        return {
          userChoices: isOther
            ? []
            : [
                currentQuestion.options.find((option) => option.text === value)
                  ?.text || '',
              ],
          userInput: '',
          isOther,
        };
      }

      if (value === '') {
        return undefined;
      }

      return {
        userChoices: [],
        userInput: value,
        isOther: false,
      };
    },
    [questions]
  );

  const questionsToShow = useMemo(
    () =>
      questions.filter((prerequisiteQuestion) =>
        prerequisiteQuestion.prerequisites.every((prerequisite) => {
          const questionAnswer = get(values, prerequisite.questionKey);
          const questionKey = Object.keys(values)[0];
          const currentQuestion = questions.find(
            (question) => question.key === questionKey
          );
          const transformedQuestionAnswer = {
            ...questionAnswer,
            userChoices: questionAnswer?.userChoices
              ? transformUserChoices(
                  questionAnswer?.userChoices,
                  currentQuestion
                )
              : [],
          };

          const isQuestionKeyPresent =
            !prerequisite.answerKeys.length &&
            (questionAnswer?.isOther
              ? questionAnswer?.userChoices.length > 0 ||
                questionAnswer?.userInput !== ''
              : questionAnswer?.userChoices.length > 0 ||
                (questionAnswer?.userChoices.length === 0 &&
                  questionAnswer?.userInput !== ''));

          const hasPossibleOtherValue =
            questionAnswer?.isOther &&
            prerequisite.answerKeys.includes('$other') &&
            questionAnswer?.userInput !== '';

          const hasAnyAnswer = prerequisite.answerKeys.some((answerKey) =>
            transformedQuestionAnswer?.userChoices.includes(answerKey)
          );

          return isQuestionKeyPresent || hasPossibleOtherValue || hasAnyAnswer;
        })
      ),
    [questions, values]
  );

  return (
    <Dialog fullScreen open maxWidth={false}>
      <Box minWidth={300} display="flex" justifyContent="center">
        <form onSubmit={onSubmit}>
          <DialogTitle>Complete your profile</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please, tell us more about yourself to help us improve your
              experience with LiveBoard
            </DialogContentText>
            {questions.length > 0 &&
              questionsToShow &&
              questionsToShow.map((question) => (
                <RequestPersonalInformationQuestion
                  key={question.key}
                  value={values[question.key]}
                  question={question}
                  onChange={onChange}
                  parser={parser}
                  control={control}
                />
              ))}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              disabled={isFetching}
              loading={isSubmitting}
              color="primary"
              type="submit"
            >
              Done
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default RequestPersonalInformationModal;
