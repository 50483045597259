import BoundaryBox from './BoundaryBox';
import vectorUtils from '../../../../../common/utils/vector.utils';

export default class LineBoundaryBox extends BoundaryBox {
  draw() {}

  hitTest(x, y) {
    const distance = vectorUtils.distanceToSegment(
      { x, y },
      this.startPoint,
      this.endPoint
    );
    return distance < this.padding;
  }
}
