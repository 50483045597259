import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import IconButton from '../../../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../../ui/atoms/Button/buttonTypes';
import TrashCanOutlineIcon from '../../../../../../ui/atoms/Icons/TrashCanOutlineIcon';
import ContentCopyIcon from '../../../../../../ui/atoms/Icons/ContentCopyIcon';
import { getCurrentDrawing } from '../../../../../../common/reducers/board/currentDrawingReducer';
import PencilOutlineIcon from '../../../../../../ui/atoms/Icons/PencilOutlineIcon';
import ToolbarContainer from '../../../../ToolbarContainer';
import ToolbarDivider from '../../../../ToolbarDivider';

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Props = {
  position: Position;
  onDelete: () => void;
  onCopy: () => void;
  onEdit: () => void;
  onApply: () => void;
  openCropModal: () => void;
  open: boolean;
};

const popperPadding = {
  top: 56,
  right: 320,
};

const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: popperPadding,
      fallbackPlacements: ['bottom', 'right', 'left'],
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 20],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: true,
      altAxis: true,
      padding: popperPadding,
    },
  },
];

const FormulaToolbar: FC<React.PropsWithChildren<Props>> = ({
  position,
  open,
  onDelete,
  onCopy,
  onEdit,
}) => {
  const currentDrawing = useSelector(getCurrentDrawing);

  const virtualElement = useMemo(
    () => ({
      getBoundingClientRect: (): DOMRect =>
        new DOMRectReadOnly(
          position.x,
          position.y,
          position.width,
          position.height
        ),
    }),
    [position]
  );

  return (
    <>
      <Popper
        open={open}
        placement="top"
        anchorEl={virtualElement}
        modifiers={popperModifiers}
      >
        <ToolbarContainer>
          <>
            <IconButton
              variant={ButtonVariantTypes.TEXT}
              size={ButtonSizeTypes.S}
              shape={ButtonShapeTypes.ROUNDED}
              onClick={onEdit}
            >
              <PencilOutlineIcon />
            </IconButton>
            <ToolbarDivider />
          </>
          {currentDrawing && (
            <>
              <IconButton
                variant={ButtonVariantTypes.TEXT}
                size={ButtonSizeTypes.S}
                shape={ButtonShapeTypes.ROUNDED}
                onClick={onCopy}
              >
                <ContentCopyIcon />
              </IconButton>
              <ToolbarDivider />
            </>
          )}
          <Box>
            <IconButton
              onClick={onDelete}
              variant={ButtonVariantTypes.TEXT}
              size={ButtonSizeTypes.S}
              shape={ButtonShapeTypes.ROUNDED}
            >
              <TrashCanOutlineIcon />
            </IconButton>
          </Box>
        </ToolbarContainer>
      </Popper>
    </>
  );
};

export default FormulaToolbar;
