import GridType from '../drawingActions/gridType';
import drawers from '../../components/board/PureCanvas/drawers';
import { background } from '../../ui/atoms/Colors/colorStyles';

const drawBackgroundColorOnCanvas = (drawingContext, width, height, color) => {
  drawingContext.clearRect(0, 0, width, height);

  drawingContext.save();
  drawingContext.fillStyle = color;

  drawingContext.fillRect(0, 0, width, height);

  drawingContext.restore();
};

const NET_COLOR = '#acacac';
const NET_WIDTH = 1;

const gridDrawers = {
  [GridType.GRID]: (ctx, originX, originY, width, height, lineDistance) => {
    ctx.save();
    ctx.strokeStyle = NET_COLOR;
    ctx.lineWidth = NET_WIDTH;
    ctx.beginPath();
    for (let i = originY + lineDistance; i <= height; i += lineDistance) {
      ctx.moveTo(originX, i);
      ctx.lineTo(width, i);
    }
    for (let i = originX + lineDistance; i <= width; i += lineDistance) {
      ctx.moveTo(i, originY);
      ctx.lineTo(i, height);
    }
    ctx.stroke();
    ctx.restore();
  },
  [GridType.LINE]: (ctx, originX, originY, width, height, lineDistance) => {
    ctx.save();
    ctx.strokeStyle = NET_COLOR;
    ctx.lineWidth = NET_WIDTH;
    ctx.beginPath();
    for (let i = originY + lineDistance; i <= height; i += lineDistance) {
      ctx.moveTo(originX, i);
      ctx.lineTo(width, i);
    }
    ctx.stroke();
    ctx.restore();
  },
  [GridType.DOT]: (ctx, originX, originY, width, height, lineDistance) => {
    ctx.save();
    ctx.fillStyle = NET_COLOR;
    ctx.beginPath();
    const doublePi = 2 * Math.PI;
    for (let y = originY + lineDistance; y <= height; y += lineDistance) {
      for (let x = originX + lineDistance; x <= width; x += lineDistance) {
        ctx.moveTo(x, y);
        ctx.arc(x, y, NET_WIDTH, 0, doublePi);
      }
    }

    ctx.fill();
    ctx.restore();
  },
};

const drawGridOnCanvas = (
  drawingContext,
  transformData,
  lineDistance,
  backgroundType
) => {
  const { width, height, originX, originY, scale } = transformData;

  const gridType = backgroundType || GridType.NONE;
  if (gridType === GridType.NONE) return;

  gridDrawers[gridType](
    drawingContext,
    Math.trunc(originX / lineDistance) * lineDistance - originX * scale,
    Math.trunc(originY / lineDistance) * lineDistance - originY * scale,
    width,
    height,
    lineDistance
  );
};

const drawImagesOnCanvas = (drawingContext, transformData, imageActions) => {
  const { scale, originX, originY } = transformData;

  drawingContext.translate(-originX, -originY);
  drawingContext.scale(scale, scale);

  for (let i = 0; i < imageActions.length; i += 1) {
    const action = imageActions[i];
    drawers.imageMeta(drawingContext, action);
  }

  drawingContext.setTransform(1, 0, 0, 1, 0, 0);
};

const drawNonImagesOnCanvas = (
  drawingContext,
  scale,
  nonImageActions,
  currentDrawing
) => {
  drawingContext.scale(scale, scale);

  nonImageActions.forEach((action) => {
    const drawer = drawers[action.name];
    if (!drawer) return;
    drawer(drawingContext, action);
  });

  if (
    currentDrawing &&
    ['erase', 'erase_v2', 'erase_v3'].includes(currentDrawing.name)
  ) {
    const drawer = drawers[currentDrawing.name];
    if (!drawer) return;
    drawer(drawingContext, currentDrawing);
  }

  drawingContext.setTransform(1, 0, 0, 1, 0, 0);
};

const drawBordersOnCanvas = (drawingContext, transformData) => {
  const { width, height, scaledBoardWidth, scaledBoardHeight } = transformData;

  const widthDiff = width - scaledBoardWidth;
  if (widthDiff > 0) {
    drawingContext.save();
    drawingContext.fillStyle = background.bg2;
    drawingContext.fillRect(0, 0, widthDiff / 2, height);
    drawingContext.fillRect(
      widthDiff / 2 + scaledBoardWidth,
      0,
      widthDiff / 2,
      height
    );
    drawingContext.restore();
  }

  const heightDiff = height - scaledBoardHeight;
  if (heightDiff > 0) {
    drawingContext.save();
    drawingContext.fillStyle = background.bg2;
    drawingContext.fillRect(0, 0, width, heightDiff / 2);
    drawingContext.fillRect(
      0,
      heightDiff / 2 + scaledBoardHeight,
      width,
      height / 2
    );
    drawingContext.restore();
  }
};

export default {
  drawBackgroundColorOnCanvas,
  drawGridOnCanvas,
  drawImagesOnCanvas,
  drawNonImagesOnCanvas,
  drawBordersOnCanvas,
};
