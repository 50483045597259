import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ToolNames from '../ToolNames';
import BoardType from '../../../common/services/types/session/BoardType';
import { boardTypeSelector } from '../../../common/reducers/session/sessionReducer';
import SessionContext from '../../Session/SessionContext';
import DrawingButton from '../DrawingButton/DrawingButton';

const BaseTool = ({
  activeTool,
  selectedTool,
  selectTool,
  icon,
  tooltipText,
  settings,
  showSettings,
  showToolOptionsIcon,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const boardType = useSelector(boardTypeSelector);
  const { openInfiniteDialog } = useContext(SessionContext);

  const node = useRef();

  const onIconClick = () => {
    if (activeTool === ToolNames.Text && boardType === BoardType.INFINITE) {
      openInfiniteDialog();
      return;
    }

    if (activeTool === selectedTool?.name) {
      setIsVisible(!isVisible);
    } else if (!isVisible) {
      setIsVisible(true);
    }

    if (activeTool) {
      selectTool(activeTool);
    }
  };

  useEffect(() => {
    if (showSettings && isVisible) setIsVisible(true);

    return () => {
      setIsVisible(false);
    };
  }, [showSettings]);

  const handleOutsideClick = (e) => {
    if (node.current.contains(e.target)) return;

    setIsVisible(false);
  };

  const isActive = useMemo(() => {
    if (!activeTool || !selectedTool) return false;

    return selectedTool.name === activeTool || selectedTool.id === activeTool;
  }, [activeTool, selectedTool]);

  return (
    <Box
      ref={node}
      sx={{
        position: 'relative',
      }}
    >
      <DrawingButton
        icon={icon}
        onClick={onIconClick}
        tooltipTitle={tooltipText}
        active={isActive}
        showOptionsIcon={showToolOptionsIcon}
      />
      {settings && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleOutsideClick}
        >
          <Box
            sx={{
              position: 'relative',
              top: 8,
              display: isVisible ? 'flex' : 'none',
            }}
          >
            {settings}
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default BaseTool;
