import { httpService, httpServiceV4 } from './http.service';
import fileService from './file.service';
import BlobUtils from '../utils/blob.utils';
import SessionDto from './dto/session/SessionDto';
import BoardType from './types/session/BoardType';
import PathShape from '../../components/PixiBoard/actions/pathShape';
import FirebaseConfig from './types/session/FirebaseConfig';
import PublicLinkDto from './dto/session/PublicLinkDto';

const apiSessionPart = '/session';

const getSessionList = ({
  groupId = 'default',
  finished = false,
  pageNumber = 0,
  pageSize = 12,
}) => {
  const params = new URLSearchParams();
  if (groupId !== 'default') {
    params.append('groupId', groupId);
  }
  params.append('finished', finished.toString());
  params.append('pageNumber', pageNumber.toString());
  params.append('pageSize', pageSize.toString());

  return httpService.get(`${apiSessionPart}`, { params });
};

const createSession = (
  width: number,
  height: number,
  dpi: number,
  groupId: string | null,
  name: string,
  boardType: BoardType = BoardType.BASIC
) =>
  httpService.post<SessionDto, SessionDto>(apiSessionPart, {
    width,
    height,
    dpi,
    groupId,
    name,
    boardType,
  });

const shareSession = (
  id: string,
  chatEnabled: boolean,
  drawingEnabledOnJoin: boolean
) =>
  httpService.post(`${apiSessionPart}/${id}/share`, {
    chat: Boolean(chatEnabled),
    drawingEnabledOnJoin: Boolean(drawingEnabledOnJoin),
  });

const reshareSession = (
  id: string,
  chatEnabled: boolean,
  drawingEnabledOnJoin: boolean
) =>
  httpService.post(`${apiSessionPart}/${id}/reshare`, {
    chat: Boolean(chatEnabled),
    drawingEnabledOnJoin: Boolean(drawingEnabledOnJoin),
  });

const finishSession = (id: string) =>
  httpService.post<SessionDto, SessionDto>(`${apiSessionPart}/${id}/finish`);

const inviteToSession = (
  id: string,
  contactIds: string[] = [],
  emails: string[] = []
) =>
  httpService.post<SessionDto, SessionDto>(`${apiSessionPart}/${id}/invite`, {
    contactIds,
    emails,
  });

const removeInvitesToSession = (
  id: string,
  contactIds: string[] = [],
  emails: string[] = []
) =>
  httpService.post<SessionDto, SessionDto>(
    `${apiSessionPart}/${id}/invite/remove`,
    { contactIds, emails }
  );

const copySession = (id: string) =>
  httpService.post<SessionDto, SessionDto>(`${apiSessionPart}/${id}/copy`);

const renameSession = (id: string, name: string) =>
  httpService.put(`${apiSessionPart}/${id}/name`, { name: name.trim() });

const changeSessionGroup = (id: string, groupId: string | null) =>
  httpService.put<SessionDto, SessionDto>(`${apiSessionPart}/${id}/groupId`, {
    groupId,
  });

const deleteSession = (id: string) =>
  httpService.delete(`${apiSessionPart}/${id}`);

const getSessionById = (id: string) =>
  httpService.get(`${apiSessionPart}/${id}`);

const joinSession = (id: string) =>
  httpService.post<SessionDto, SessionDto>(`${apiSessionPart}/${id}/join`);

const getSessionByCode = (code: string) =>
  httpService.get<SessionDto, SessionDto>(`${apiSessionPart}/code/${code}`);

const startConference = (id: string) =>
  httpService.post(`${apiSessionPart}/${id}/conference`);

const getConferenceToken = () => httpService.get('/conference/accessToken');

const endConference = (id: string) =>
  httpService.delete(`${apiSessionPart}/${id}/conference`);

const uploadSessionImage = (id: string, blob: Blob) => {
  const extension = BlobUtils.getExtensionFromBlob(blob);
  const credentialsUrl = `${apiSessionPart}/${id}/upload/image/credentials?filename=upload.${extension}`;

  return fileService.uploadFile(credentialsUrl, blob);
};

const updateSessionThumbnail = (id: string, url: string) =>
  httpService.put(`${apiSessionPart}/${id}/thumbnail`, { url });

const uploadSessionThumbnail = async (id: string, blob: Blob) => {
  const extension = BlobUtils.getExtensionFromBlob(blob);
  const credentialsUrl = `${apiSessionPart}/${id}/upload/thumbnail/credentials?filename=upload.${extension}`;

  const url = await fileService.uploadFile(credentialsUrl, blob);

  return updateSessionThumbnail(id, url);
};

const updateChatOption = (id: string, chat: boolean) =>
  httpService.put(`${apiSessionPart}/${id}/chat`, { chat: Boolean(chat) });

const updateDrawingEnabledOnJoinOption = (
  id: string,
  drawingEnabledOnJoin: boolean
) =>
  httpService.put(`${apiSessionPart}/${id}/drawing-enabled-on-join`, {
    drawingEnabledOnJoin: Boolean(drawingEnabledOnJoin),
  });

const updatePageNavigationOption = (id: string, pageNavigation: boolean) =>
  httpService.put(`${apiSessionPart}/${id}/participants-page-navigation`, {
    allowParticipantsPageNavigation: Boolean(pageNavigation),
  });

const getPublicLink = (id: string) =>
  httpService.get(`${apiSessionPart}/${id}/publicLink`);

const getPublicLinkBySessionCode = (code: string) =>
  httpServiceV4.get<PublicLinkDto, PublicLinkDto>(
    `${apiSessionPart}/public-link/${code}`
  );

const createPublicLink = (id: string) =>
  httpService.post(`${apiSessionPart}/${id}/publicLink`);

const deletePublicLink = (id: string) =>
  httpService.delete(`${apiSessionPart}/${id}/publicLink`);

const getFirebaseConfig = (id: string) =>
  httpService.get<FirebaseConfig, FirebaseConfig>(
    `${apiSessionPart}/${id}/firebaseConfig`
  );

const getPathShapeTemplates = () =>
  httpService.get<PathShape[], PathShape[]>(
    `${apiSessionPart}/action-template/path-shape`
  );

export default {
  getSessionList,
  createSession,
  deleteSession,
  getSessionById,
  getSessionByCode,
  startConference,
  getConferenceToken,
  endConference,
  uploadSessionImage,
  uploadSessionThumbnail,
  shareSession,
  reshareSession,
  inviteToSession,
  removeInvitesToSession,
  renameSession,
  copySession,
  changeSessionGroup,
  finishSession,
  joinSession,
  updateChatOption,
  updateDrawingEnabledOnJoinOption,
  updatePageNavigationOption,
  getPublicLink,
  getPublicLinkBySessionCode,
  createPublicLink,
  deletePublicLink,
  getFirebaseConfig,
  getPathShapeTemplates,
};
