import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RightContainer from '../components/AuthCover';
import ColorLiveBoardLogo from '../../../atoms/Icons/ColorLiveBoardLogo';
import GoogleSignInButton from '../../../atoms/GoogleSignInButton';
import HorizontalDivider from '../../../atoms/HorizontalDivider';
import SignUpForm from './SignUpForm';
import Container from '../components/Container';
import AuthFooter from '../components/AuthFooter';

const SignUp: FC<React.PropsWithChildren<unknown>> = () => {
  const [planId, setPlanId] = useState<string | undefined>(undefined);

  const { search } = useLocation();
  const history = useHistory();
  const { userRole } = useParams<{ userRole: 'student' | 'teacher' }>();

  useEffect(() => {
    const query = new URLSearchParams(search);

    const pid = query.get('pid');

    if (pid) {
      setPlanId(pid);
      query.delete('pid');
      history.replace({
        search: query.toString(),
      });
    }
  }, [setPlanId]);

  return (
    <Box display="flex" height="100%">
      <Container>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 440,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
              maxWidth: '320px',
            },
          })}
        >
          <ColorLiveBoardLogo
            sx={{
              width: '149px',
              height: '30px',
              margin: 'auto',
              mb: 3,
            }}
          />
          <Typography mb={2.5} align="center" variant="h6">
            {userRole === 'teacher' ? 'Sign up as Tutor' : 'Sign up as Student'}
          </Typography>
          <Typography align="center" variant="p4" mb={5} component="span">
            {userRole === 'teacher'
              ? 'To create & share lessons with students'
              : "To attend your tutor's lessons"}
          </Typography>
          <Box mb={1.75}>
            <GoogleSignInButton planId={planId} role={userRole}>
              Continue with Google
            </GoogleSignInButton>
          </Box>
          <HorizontalDivider
            sx={{
              mb: 1.75,
            }}
          >
            OR
          </HorizontalDivider>
          <Box mb={1.75}>
            <SignUpForm planId={planId} userRole={userRole} />
          </Box>
          <AuthFooter questionText="Have an account?" formButtonText="Log in" />
        </Box>
      </Container>
      <RightContainer />
    </Box>
  );
};

export default SignUp;
