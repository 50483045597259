import { MouseEvent, FC } from 'react';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import GridType from '../../../common/drawingActions/gridType';

type Props = {
  value: GridType;
  icon: FC<React.PropsWithChildren<SvgIconProps>>;
  selected: boolean;
  onSelect: (event: MouseEvent<HTMLElement>, value: any) => void;
};

const BackgroundTypeButton: FC<React.PropsWithChildren<Props>> = ({
  value,
  icon: Component,
  selected,
  onSelect,
}) => (
  <ToggleButton
    value={value}
    selected={selected}
    onChange={onSelect}
    disableRipple
    sx={(theme) => ({
      border: '1px solid #D9D9D9',
      ':hover': {
        backgroundColor: theme.background.bg3,
      },
      [`&.${toggleButtonClasses.root}.${toggleButtonClasses.selected}`]: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'unset',
        padding: '10px',
      },
    })}
  >
    <Component
      sx={{
        fontSize: '2.5rem',
      }}
    />
  </ToggleButton>
);

export default BackgroundTypeButton;
