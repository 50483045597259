import React, { FC, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FormatListOutlinedIcon from '../../../../../ui/atoms/Icons/FormatListOutlinedIcon';
import Typography from '../../../../../ui/atoms/Typography';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import PlusIcon from '../../../../../ui/atoms/Icons/PlusIcon';
import ListItem from '../../../../../ui/atoms/ListItem';
import { AddAssessmentDialog } from '../AddAssessmentDialog';
import Button from '../../../../../ui/atoms/Button';
import FormatListPendingIcon from '../../../../../ui/atoms/Icons/FormatListPendingIcon';
import FormatListCompleteIcon from '../../../../../ui/atoms/Icons/FormatListCompleteIcon';
import DrawerToolbar from '../../../../../ui/atoms/DrawerToolbar/DrawerToolbar';
import { sessionIdSelector } from '../../../../../common/reducers/session/sessionReducer';
import IconButton from '../../../../../ui/atoms/IconButton';
import CloseIcon from '../../../../../ui/atoms/Icons/CloseIcon';
import useDialog from '../../../../../common/hooks/useDialog';
import AssessmentStatus from '../../../common/types/AsssessmentStatus';
import * as assessmentService from '../../services/assessment.service';
import { LoadingListItem } from './LoadingListItem';
import ScrollbarWrapper from '../../../../../ui/atoms/ScrollbarWrapper/ScrollbarWrapper';
import NoContentCard from '../../../../../ui/molecules/NoContentCard';
import analyticsService from '../../../../../common/services/analytics.service';
import { NavListItem } from '../../../common/components/NavListItem';

type AssessmentSidebarProps = {
  open: boolean;
  onClose: () => void;
};

const getAssessmentIcon = (status: AssessmentStatus) => {
  switch (status) {
    case AssessmentStatus.CREATED:
      return <FormatListOutlinedIcon />;
    case AssessmentStatus.COMPLETED:
      return <FormatListCompleteIcon />;
    case AssessmentStatus.IN_PROGRESS:
      return <FormatListPendingIcon />;
    default:
      throw new Error('Unknown assessment status');
  }
};

export const AssessmentSidebar: FC<
  React.PropsWithChildren<AssessmentSidebarProps>
> = ({ open, onClose }) => {
  const sessionId = useSelector(sessionIdSelector);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const addBoardAssessmentDialog = useDialog();
  const history = useHistory();
  const assessmentsQuery = useQuery({
    queryKey: ['assessments', sessionId],
    queryFn: () => assessmentService.getBoardAssessmentList(sessionId),
    enabled: open,
  });
  const createAssessmentMutation = useMutation({
    mutationFn: (assessmentData: { sessionId: string; templateId: string }) =>
      assessmentService.createBoardAssessment(assessmentData),
    onSuccess: async (data) => {
      history.push(`/assessment/${data.id}`);
      await queryClient.invalidateQueries({
        queryKey: ['assessments', sessionId],
      });
    },
    onError: () => {
      enqueueSnackbar('Unknown error occurred when adding an assessment', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const onBoardAssessmentDialogSuccess = useCallback(
    async (templateId: string) => {
      addBoardAssessmentDialog.onClose();
      createAssessmentMutation.mutate({
        templateId,
        sessionId,
      });
    },
    [sessionId, addBoardAssessmentDialog.onClose]
  );

  const onAddAssessmentClick = useCallback(() => {
    analyticsService.event('Add Assessment Click');
    addBoardAssessmentDialog.onOpen();
  }, [addBoardAssessmentDialog.onOpen]);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: (theme) => ({
            width: 300,
            zIndex: theme.zIndex.drawer + 1,
            overflowY: 'unset',
            backgroundColor: theme.background.bg1,
          }),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerToolbar>
          {!assessmentsQuery.isLoading &&
            Boolean(assessmentsQuery.data?.data.length) && (
              <IconButton
                variant={ButtonVariantTypes.PRIMARY}
                size={ButtonSizeTypes.S}
                onClick={onAddAssessmentClick}
                shape={ButtonShapeTypes.ROUNDED}
              >
                <PlusIcon />
              </IconButton>
            )}
          <Typography variant="s4">Assessments</Typography>
          <IconButton
            variant={ButtonVariantTypes.TEXT}
            size={ButtonSizeTypes.S}
            onClick={onClose}
            shape={ButtonShapeTypes.ROUNDED}
          >
            <CloseIcon />
          </IconButton>
        </DrawerToolbar>
        <ScrollbarWrapper showOnHover>
          <Box
            maxWidth={300}
            height="100%"
            display="flex"
            flexDirection="column"
            sx={(theme) => ({
              backgroundColor: theme.background.bg1,
              borderTop: `1px solid ${theme.background.bg3}`,
            })}
          >
            {assessmentsQuery.isLoading && (
              <>
                <LoadingListItem />
                <LoadingListItem />
              </>
            )}
            {!assessmentsQuery.isLoading &&
              !assessmentsQuery.data?.data.length && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={1.75}
                  height="100%"
                >
                  <NoContentCard
                    Icon={FormatListOutlinedIcon}
                    title="You have no assessments"
                    description="Add assessment from templates or create one"
                    actions={
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant={ButtonVariantTypes.PRIMARY}
                          size={ButtonSizeTypes.S}
                          onClick={onAddAssessmentClick}
                          startIcon={<PlusIcon />}
                        >
                          Add
                        </Button>
                      </Box>
                    }
                  />
                </Box>
              )}
            {!assessmentsQuery.isLoading &&
              Number(assessmentsQuery.data?.data.length) > 0 &&
              assessmentsQuery.data?.data.map(({ id, name, status }) => (
                <NavListItem
                  key={id}
                  to={`/assessment/${id}`}
                  onClick={() => {
                    analyticsService.event('Assessment Selected', {
                      assessmentId: id,
                    });
                  }}
                >
                  <ListItem
                    startIcon={getAssessmentIcon(status)}
                    title={name}
                    rounded={false}
                  />
                </NavListItem>
              ))}
          </Box>
        </ScrollbarWrapper>
      </Drawer>
      <AddAssessmentDialog
        open={addBoardAssessmentDialog.open}
        onClose={addBoardAssessmentDialog.onClose}
        onSuccess={onBoardAssessmentDialogSuccess}
      />
    </>
  );
};
