import { FC } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import platformUtils from '../common/utils/platform.utils';
import PencilIcon from '../ui/atoms/Icons/PencilIcon';
import EraserIcon from '../ui/atoms/Icons/EraserIcon';
import MarkerIcon from '../ui/atoms/Icons/Shapes/MarkerIcon';
import ShapesIcon from '../ui/atoms/Icons/Shapes/ShapesIcon';
import TextIcon from '../ui/atoms/Icons/TextIcon';
import LineIcon from '../ui/atoms/Icons/Shapes/LineIcon';
import ArrowIcon from '../ui/atoms/Icons/Shapes/ArrowIcon';
import ImageIcon from '../ui/atoms/Icons/ImageAddIcon';
import UndoIcon from '../ui/atoms/Icons/UndoIcon';
import RedoIcon from '../ui/atoms/Icons/RedoIcon';
import PointerIcon from '../ui/atoms/Icons/Shapes/PointerIcon';
import FormulaIcon from '../ui/atoms/Icons/Shapes/FormulaIcon';
import GraphIcon from '../ui/atoms/Icons/Shapes/GraphIcon';

export type ActionName = string;
export type KeySequence = string | string[];

export interface KeyMapOptions {
  sequence: KeySequence;
  name?: string;
  group?: string;
  description?: string;
  icon: FC<React.PropsWithChildren<SvgIconProps>>;
}

export type KeyMap = { [key in ActionName]: KeyMapOptions };

const keyMap: KeyMap = {
  pointer: {
    name: 'Pointer',
    sequence: 'KeyN',
    description: 'N',
    group: 'Drawable',
    icon: PointerIcon,
  },
  pen: {
    name: 'Pen',
    sequence: 'KeyP',
    description: 'P',
    group: 'Drawable',
    icon: PencilIcon,
  },
  marker: {
    name: 'Marker',
    sequence: 'KeyM',
    description: 'M',
    group: 'Drawable',
    icon: MarkerIcon,
  },
  shape: {
    name: 'Current Shape',
    sequence: 'KeyH',
    description: 'H',
    group: 'Drawable',
    icon: ShapesIcon,
  },
  line: {
    name: 'Line',
    sequence: 'KeyL',
    description: 'L',
    group: 'Drawable',
    icon: LineIcon,
  },
  arrow: {
    name: 'Arrow',
    sequence: 'KeyA',
    description: 'A',
    group: 'Drawable',
    icon: ArrowIcon,
  },
  text: {
    name: 'Text',
    sequence: 'KeyT',
    description: 'T',
    group: 'Drawable',
    icon: TextIcon,
  },
  formula: {
    name: 'Formula',
    sequence: 'KeyF',
    description: 'F',
    group: 'Drawable',
    icon: FormulaIcon,
  },
  graph: {
    name: 'Graph',
    sequence: 'KeyG',
    description: 'G',
    group: 'Drawable',
    icon: GraphIcon,
  },
  eraser: {
    name: 'Eraser',
    sequence: 'KeyE',
    description: 'E',
    group: 'Drawable',
    icon: EraserIcon,
  },
  image: {
    name: 'Import Image',
    sequence: 'KeyI',
    description: 'I',
    group: 'File',
    icon: ImageIcon,
  },
  undo: {
    name: 'Undo',
    sequence: '$mod+KeyZ',
    description: platformUtils.isMac() ? '⌘+Z' : 'Ctrl+Z',
    group: 'General',
    icon: UndoIcon,
  },
  redo: {
    name: 'Redo',
    sequence: ['$mod+Shift+KeyZ'],
    description: platformUtils.isMac() ? '⌘+Shift+Z' : 'Ctrl+Shift+Z',
    group: 'General',
    icon: RedoIcon,
  },
};

export default keyMap;
