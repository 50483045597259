import React, { createRef } from 'react';
import { Subject, BehaviorSubject, pipe, combineLatest, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import DomEventsUtil from '../../../../../common/utils/domEvents.util';
import drawers from '../../drawers';
import BoundaryBox from '../boundaries/BoundaryBox';
import DrawingModes from '../DrawingModes';
import shapesHitTest from '../shapesHitTest';
import clearSelection from '../clearSelection';
import ToolNames from '../../../../DrawingTools/ToolNames';
import SelectCursorSvgIcon from '../../../../../assets/svgIcons/SelectCursorSvgIcon.svg';
import ShapeCursorSvgIcon from '../../../../../assets/svgIcons/ShapeCursorSvgIcon.svg';
import ShapeToolbar from './components/ShapeToolbar';
import DeleteAction from '../../../../../common/drawingActions/delete.action';

class ShapeDrawingLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toolbarVisible: false,
    };

    this.shouldApplyShapeRef = createRef();
    this.shouldApplyShapeRef.current = true;
  }

  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.zoomProp$.next(this.props.zoom);
    this.scaleProp$.next(this.props.scale);
    this.originXProp$.next(this.props.originX);
    this.originYProp$.next(this.props.originY);
    this.currentPageIdProp$.next(this.props.currentPageId);
    if (this.props.currentDrawing) {
      this.coordinates$.next({
        startPoint: { ...this.props.currentDrawing.startPoint },
        endPoint: { ...this.props.currentDrawing.endPoint },
      });
      this.shapePosition$.next({
        startPoint: { ...this.props.currentDrawing.startPoint },
        endPoint: { ...this.props.currentDrawing.endPoint },
      });
      this.setState({
        toolbarVisible: true,
      });
      this.colorProp$.next(+this.props.currentDrawing.paint.color);
      this.sizeProp$.next(this.props.currentDrawing.paint.strokeWidth);
      this.fillProp$.next(this.props.currentDrawing.paint.fill);
      this.mode$.next(DrawingModes.Drawn);
    } else {
      this.colorProp$.next(this.props.color);
      this.sizeProp$.next(this.props.size);
      this.fillProp$.next(this.props.fill);
      this.mode$.next(DrawingModes.Initial);
    }
    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();
  }

  componentDidUpdate(prevProps) {
    if (this.props.zoom !== prevProps.zoom) {
      this.zoomProp$.next(this.props.zoom);
    }
    if (this.props.scale !== prevProps.scale) {
      this.scaleProp$.next(this.props.scale);
    }
    if (this.props.originX !== prevProps.originX) {
      this.originXProp$.next(this.props.originX);
    }
    if (this.props.originY !== prevProps.originY) {
      this.originYProp$.next(this.props.originY);
    }
    if (!this.props.currentDrawing && this.props.color !== prevProps.color) {
      this.colorProp$.next(this.props.color);
    }
    if (
      this.props.currentDrawing &&
      this.props.currentDrawing.paint.color !== prevProps.color
    ) {
      this.colorProp$.next(this.props.currentDrawing.paint.color);
      this.fillProp$.next(this.props.currentDrawing.paint.fill);
    }
    if (this.props.size !== prevProps.size) {
      this.sizeProp$.next(this.props.size);
    }
    if (this.props.fill !== prevProps.fill) {
      this.fillProp$.next(this.props.fill);
    }
    if (this.props.currentPageId !== prevProps.currentPageId) {
      this.currentPageIdProp$.next(this.props.currentPageId);
    }
  }

  componentWillUnmount() {
    if (this.shouldApplyShapeRef.current) {
      const action = this.shape$.getValue();
      this.applyAction(action);
    }

    if (this.props.currentDrawing) {
      this.props.setCurrentDrawing(null);
    }
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();
    this.zoomProp$.complete();
    this.scaleProp$.complete();
    this.originXProp$.complete();
    this.originYProp$.complete();
    this.colorProp$.complete();
    this.sizeProp$.complete();
    this.fillProp$.complete();
    this.currentPageIdProp$.complete();
    this.shape$.complete();
    this.shapePosition$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');
    this.drawingContext.canvas.style.cursor = `url(${ShapeCursorSvgIcon}) 12 12, crosshair`;
  };

  canvas = null;
  drawingContext = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();
  mode$ = new BehaviorSubject();
  zoomProp$ = new BehaviorSubject();
  scaleProp$ = new BehaviorSubject();
  originXProp$ = new BehaviorSubject();
  originYProp$ = new BehaviorSubject();
  colorProp$ = new BehaviorSubject();
  sizeProp$ = new BehaviorSubject();
  fillProp$ = new BehaviorSubject();
  currentPageIdProp$ = new Subject();
  coordinates$ = new BehaviorSubject();
  shape$ = new BehaviorSubject(null);
  shapePosition$ = new BehaviorSubject(null);

  props$ = combineLatest(
    this.zoomProp$,
    this.scaleProp$,
    this.originXProp$,
    this.originYProp$,
    this.colorProp$,
    this.sizeProp$,
    this.fillProp$
  ).pipe(
    map(([zoom, scale, originX, originY, color, size, fill]) => ({
      zoom,
      scale,
      originX,
      originY,
      color,
      size,
      fill,
    }))
  );

  draw = (shape, boundaries) => {
    if (!shape) return;
    const { zoom, scale, originX, originY } = this.props;
    if (!this.drawingContext) return;

    const drawer = drawers[shape.name];
    if (!drawer) return;

    this.clear();
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    drawer(this.drawingContext, shape);

    if (boundaries && boundaries.length) {
      boundaries.forEach((boundary) => boundary.draw(this.drawingContext));
    }
    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);
  };

  clear = () => {
    const { width, height } = this.props;
    this.drawingContext.clearRect(0, 0, width, height);
  };

  subscribeToDrawing = () => {
    const transformPoint = () =>
      pipe(
        withLatestFrom(this.props$),
        map(([point, props]) => {
          const { originX, originY, scale, zoom } = props;

          return {
            x: (point.x / zoom + originX) / scale,
            y: (point.y / zoom + originY) / scale,
          };
        })
      );

    const withMode = (m) =>
      pipe(
        withLatestFrom(this.mode$),
        filter(([, mode]) => mode === m),
        map(([e]) => e)
      );

    const startEvents$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint(), tap(clearSelection));

    const moveEvents$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(transformPoint());

    const endEvents$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    ).pipe(transformPoint());

    const rectangle$ = combineLatest(this.coordinates$, this.props$).pipe(
      map(([positions, props]) => {
        if (!positions || !props) return null;

        const createdShape = this.createShape(positions, props);
        this.shape$.next(createdShape);

        return createdShape;
      })
    );

    const boundaries$ = combineLatest(this.coordinates$, this.props$).pipe(
      map(([positions, props]) => {
        if (!positions || !props) return [];

        return this.createBoundaries(positions, props);
      })
    );

    // Drawing
    startEvents$
      .pipe(
        withMode(DrawingModes.Initial),
        concatMap(() => moveEvents$.pipe(takeUntil(endEvents$))),
        withLatestFrom(startEvents$),
        map(([lastPoint, startPoint]) =>
          this.normalizePoints(startPoint, lastPoint)
        )
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Drawing end
    startEvents$
      .pipe(
        withMode(DrawingModes.Initial),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(rectangle$)
      )
      .subscribe(([, action]) => {
        if (!action) {
          this.mode$.next(DrawingModes.Initial);
          return;
        }

        this.shapePosition$.next({
          startPoint: action.startPoint,
          endPoint: action.endPoint,
        });
        this.setState({
          toolbarVisible: true,
        });

        this.mode$.next(DrawingModes.Drawn);
      });

    // Drag start
    startEvents$
      .pipe(
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$, rectangle$)
      )
      .subscribe(([coordinates, shapes, action]) => {
        const shape = shapesHitTest(coordinates, shapes);
        if (shape) {
          this.setState({
            toolbarVisible: false,
          });
          const newMode =
            shape instanceof BoundaryBox
              ? DrawingModes.Dragging
              : DrawingModes.Resizing;
          this.mode$.next(newMode);
          return;
        }

        this.shouldApplyShapeRef.current = false;
        const isOutsideClick = true;
        this.applyAction(action, isOutsideClick);
      });

    // Dragging
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        withLatestFrom(boundaries$),
        concatMap(() =>
          moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Dragging),
            pairwise(),
            map(([oldPos, newPos]) => ({
              deltaX: newPos.x - oldPos.x,
              deltaY: newPos.y - oldPos.y,
            }))
          )
        ),
        withLatestFrom(this.coordinates$),
        map(([transform, { startPoint, endPoint }]) => ({
          startPoint: {
            x: startPoint.x + transform.deltaX,
            y: startPoint.y + transform.deltaY,
          },
          endPoint: {
            x: endPoint.x + transform.deltaX,
            y: endPoint.y + transform.deltaY,
          },
        }))
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Drag end
    startEvents$
      .pipe(
        withMode(DrawingModes.Dragging),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ startPoint, endPoint }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.shapePosition$.next({
          startPoint,
          endPoint,
        });
        this.setState({
          toolbarVisible: true,
        });
      });

    // Resizing
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        withLatestFrom(boundaries$),
        concatMap(([coordinates, shapes]) => {
          const shape = shapesHitTest(coordinates, shapes);

          return moveEvents$.pipe(
            takeUntil(endEvents$),
            withMode(DrawingModes.Resizing),
            map((pos) => shape.onResize(pos)),
            map(({ startPoint, endPoint }) =>
              this.normalizePoints(startPoint, endPoint)
            )
          );
        })
      )
      .subscribe((a) => {
        this.coordinates$.next(a);
      });

    // Resize end
    startEvents$
      .pipe(
        withMode(DrawingModes.Resizing),
        concatMap(() => endEvents$.pipe(take(1))),
        withLatestFrom(this.coordinates$),
        map((params) => Object.assign({}, ...params))
      )
      .subscribe(({ startPoint, endPoint }) => {
        this.mode$.next(DrawingModes.Drawn);
        this.shapePosition$.next({
          startPoint,
          endPoint,
        });
        this.setState({
          toolbarVisible: true,
        });
      });

    this.mouseMove$
      .pipe(
        map(DomEventsUtil.mouseEventToCoordinates),
        transformPoint(),
        withMode(DrawingModes.Drawn),
        withLatestFrom(boundaries$)
      )
      .subscribe(([coordinates, shapes]) => {
        for (let i = shapes.length - 1; i >= 0; i -= 1) {
          const shape = shapes[i];
          if (shape.hitTest(coordinates.x, coordinates.y)) {
            shape.onHover(this.drawingContext);
            return;
          }
        }

        this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
      });

    combineLatest(
      rectangle$,
      combineLatest(boundaries$, this.mode$).pipe(
        map(([boundaries, mode]) => {
          if (mode === DrawingModes.Initial || mode === DrawingModes.Dragging)
            return [];

          return boundaries;
        })
      )
    ).subscribe(([shape, boundaries]) => {
      if (!shape) {
        this.clear();
        return;
      }

      this.draw(shape, boundaries);
    });

    this.currentPageIdProp$.subscribe(() => {
      if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
        this.props.setCurrentDrawing(null);
        this.props.updateDrawingTool(this.props.shapeType, {
          previousDrawingTool: null,
        });
      }
      this.shouldApplyShapeRef.current = false;
      this.props.selectDrawingTool(ToolNames.Select);
      this.reset();
    });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  applyAction = (action, isOutsideClick = false) => {
    const drawingAction = action;
    if (!drawingAction) return;
    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      drawingAction.targetId = this.props.currentDrawing.targetId;
    }
    this.props.addActionAndSend(drawingAction);

    if (this.props.previousDrawingTool) {
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    }
    if (isOutsideClick) {
      this.props.selectDrawingTool(ToolNames.Select);
    }
    this.reset();
  };

  reset = () => {
    this.coordinates$.next(null);
    this.shapePosition$.next(null);
    this.mode$.next(DrawingModes.Initial);
    this.clear();
  };

  createShape = (positions, props) => {
    throw new Error('Not implemented');
  };

  createBoundaries = (positions, props) => {
    throw new Error('Not implemented');
  };

  normalizePoints = (startPoint, endPoint) => {
    throw new Error('Not implemented');
  };

  copyShape = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const action = this.shape$.getValue();

      const drawingAction = action;
      drawingAction.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(drawingAction);
      this.mode$.next(DrawingModes.Drawn);
      const copiedShapePosition = {
        startPoint: {
          x: action.startPoint.x + 20,
          y: action.startPoint.y + 20,
        },
        endPoint: {
          x: action.endPoint.x + 20,
          y: action.endPoint.y + 20,
        },
      };

      this.coordinates$.next(copiedShapePosition);
      this.shapePosition$.next(copiedShapePosition);
    }
  };

  deleteShape = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;
    this.shouldApplyShapeRef.current = false;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const deleteAction = new DeleteAction();
      deleteAction.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(deleteAction);
      this.props.updateDrawingTool(this.props.shapeType, {
        previousDrawingTool: null,
      });
      this.props.setCurrentDrawing(null);
    }

    this.props.selectDrawingTool(ToolNames.Select);
    this.reset();
  };
  getToolbarPosition = (position) => {
    if (!position) return null;

    const { originX, originY, scale, zoom, originOffsetY } = this.props;

    return {
      x: (position.startPoint.x * scale - originX) * zoom,
      y: (position.startPoint.y * scale - originY + originOffsetY) * zoom,
      width: (position.endPoint.x - position.startPoint.x) * scale * zoom,
      height: (position.endPoint.y - position.startPoint.y) * scale * zoom,
    };
  };

  getBoundaryBoxPosition = (position) => {
    if (!position) return null;
    if (!!super.getBoundaryBoxPosition)
      return super.getBoundaryBoxPosition(position);

    const { scale, size } = this.props;
    const scaledPadding = 20 / scale + size / 2;
    const startPoint = {
      x: Math.min(position.startPoint.x, position.endPoint.x),
      y: Math.min(position.startPoint.y, position.endPoint.y),
    };
    const endPoint = {
      x: Math.max(position.startPoint.x, position.endPoint.x),
      y: Math.max(position.startPoint.y, position.endPoint.y),
    };

    return {
      startPoint: {
        x: startPoint.x - scaledPadding,
        y: startPoint.y - scaledPadding,
      },
      endPoint: {
        x: endPoint.x + scaledPadding,
        y: endPoint.y + scaledPadding,
      },
    };
  };

  render() {
    const { width, height } = this.props;
    const position = this.shapePosition$.getValue();
    const boundaryBoxPosition = this.getBoundaryBoxPosition(position);
    const toolbarPosition = this.getToolbarPosition(boundaryBoxPosition);
    const openToolbar = this.state.toolbarVisible;

    return (
      <>
        <canvas
          ref={this.setDrawingContext}
          width={width}
          height={height}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
          onMouseUp={this.onMouseUp}
          onContextMenu={this.onContextMenu}
        />
        {toolbarPosition && (
          <ShapeToolbar
            position={toolbarPosition}
            open={openToolbar}
            onDelete={this.deleteShape}
            onCopy={this.copyShape}
          />
        )}
      </>
    );
  }
}

export default ShapeDrawingLayer;
