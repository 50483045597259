import { cloneDeep } from 'lodash';
import Point from '../types/Point';
import DrawingAction from './drawingAction';
import OldShapeAction from '../../../common/drawingActions/shapes/shape.drawing.action';
import guid from '../../../common/utils/guid.util';

class Shape extends DrawingAction {
  startPoint: Point;

  endPoint: Point;

  constructor(action?: OldShapeAction) {
    super(action);

    if (action) {
      this.startPoint = action.startPoint;
      this.endPoint = action.endPoint;
    }
  }

  update(startPoint: Point, endPoint: Point) {
    this.startPoint = startPoint;
    this.endPoint = endPoint;
  }

  toDto(): OldShapeAction {
    const dto = new OldShapeAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldShapeAction) {
    super.setFields(action);
    action.startPoint = this.startPoint;
    action.endPoint = this.endPoint;
  }

  createMoveAction(newPos: Point): Shape {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;

    const center = {
      x: (this.startPoint.x + this.endPoint.x) / 2,
      y: (this.startPoint.y + this.endPoint.y) / 2,
    };

    const delta = {
      x: newPos.x - center.x,
      y: newPos.y - center.y,
    };

    newAction.startPoint = {
      x: this.startPoint.x + delta.x,
      y: this.startPoint.y + delta.y,
    };
    newAction.endPoint = {
      x: this.endPoint.x + delta.x,
      y: this.endPoint.y + delta.y,
    };

    return newAction;
  }
}

export default Shape;
