import { createSelector } from 'reselect';
import {
  SET_LINE_CURRENT_DRAWING,
  SET_SHAPE_CURRENT_DRAWING,
  SET_IMAGE_CURRENT_DRAWING,
  UPDATE_LINE_CURRENT_DRAWING,
  UPDATE_SHAPE_CURRENT_DRAWING,
} from '../../actions/board/currentDrawingAction';

export default (state = null, action) => {
  switch (action.type) {
    case SET_LINE_CURRENT_DRAWING:
    case SET_SHAPE_CURRENT_DRAWING:
    case SET_IMAGE_CURRENT_DRAWING:
      return action.action;
    case UPDATE_LINE_CURRENT_DRAWING:
      return {
        ...state,
        points: [...action.action.points],
        processedPoints: [...action.action.processedPoints],
      };
    case UPDATE_SHAPE_CURRENT_DRAWING:
      return {
        ...state,
        endPoint: action.action.endPoint,
      };
    default:
      return state;
  }
};

const getCurrentDrawing = state => state.board.currentDrawing;

const hasCurrentDrawingSelector = createSelector(
  getCurrentDrawing,
  currentDrawing => !!currentDrawing,
);

const isCurrentDrawingShapeDrawingSelector = createSelector(
  getCurrentDrawing,
  currentDrawing => currentDrawing &&
    ['circle', 'line', 'oval', 'rectangle', 'triangle'].indexOf(currentDrawing.name) !== -1,
);

const isNotCurrentDrawingEraseDrawingSelector = createSelector(
  getCurrentDrawing,
  currentDrawing => currentDrawing && currentDrawing.name !== 'erase_v2',
);

export {
  getCurrentDrawing,
  hasCurrentDrawingSelector,
  isCurrentDrawingShapeDrawingSelector,
  isNotCurrentDrawingEraseDrawingSelector,
};
