import Resizer from './Resizer';

export default class TriangleResizer extends Resizer {
  cursor = 'grab';

  constructor(top, right, left, scale, padding) {
    const startPoint = {
      x: Math.min(top.x, right.x, left.x),
      y: Math.min(top.y, right.y, left.y),
    };
    const endPoint = {
      x: Math.max(top.x, right.x, left.x),
      y: Math.max(top.y, right.y, left.y),
    };
    super(startPoint, endPoint, scale, padding);

    this.top = top;
    this.right = right;
    this.left = left;
    this.scale = scale;
    this.padding = padding;
  }
}
