import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import ReactDOM from 'react-dom';
import { setLogLevel, VideoPresets, ConnectionState } from 'livekit-client';
import { DisplayContext } from '@livekit/react-components';
import 'react-aspect-ratio/aspect-ratio.css';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import StageView from './StageView';
import LiveKitContext from '../BoardControls/CollaborationTools/Manager/LiveKitManager/context/LiveKitContext';
import environment from '../../config/environment';
import useRoom from '../../common/hooks/useRoom';
import ControlsRenderer from './ControlsRenderer';
import ViewOptions from './ViewOptions';
import ParticipantRenderer from './ParticipantRenderer';
import {
  isConferenceInProgressSelector,
  isCurrentUserHostSelector,
} from '../../common/reducers/session/sessionReducer';
import ResizeIcon from '../../ui/atoms/Icons/ResizeIcon';
import useWindowSize from '../../common/hooks/useWindowSize';
import CollapseStage from './CollapseStage';
import DraggableMinimizedComponent from './DraggableMinimizedComponent';
import { collaborationBarWidthSelector } from '../../common/reducers/uiReducer';
import Snackbar from '../Snackbar/SnackbarConfig';

const dialogMargin = 10;
const collaborationToolsHeight = 40;
const collaborationTop = 20;

const dialogDefaultSize = {
  width: 420,
  height: 296,
};

export const getPopupPosition = (event, dropPosition, userPresenceBarWidth) => {
  const windowWidth = event.target.innerWidth;
  const windowHeight = event.target.innerHeight;
  const draggablePopup = document.querySelector('.dragging-handle');
  const draggablePopupParent = draggablePopup.parentElement;
  const popupWidth =
    draggablePopupParent.offsetWidth || dialogDefaultSize.width;
  const popupHeight =
    draggablePopupParent.offsetHeight || dialogDefaultSize.height;
  const popupPosition = dropPosition || {
    x:
      windowWidth -
      userPresenceBarWidth -
      dialogDefaultSize.width -
      dialogMargin,
    y: collaborationTop + collaborationToolsHeight + dialogMargin,
  };

  let newPosition = { ...popupPosition };

  if (windowWidth < popupPosition.x + popupWidth) {
    newPosition.x = Math.max(windowWidth - popupWidth, 0);
  }
  if (windowHeight < popupPosition.y + popupHeight) {
    newPosition.y = Math.max(windowHeight - popupHeight, 0);
  }

  return newPosition;
};

const LiveKitPopup = ({
  token,
  open,
  onClose,
  muted,
  cameraEnabled,
  deviceOptions,
}) => {
  const isHost = useSelector(isCurrentUserHostSelector);
  const isConferenceInProgress = useSelector(isConferenceInProgressSelector);
  const collaborationBarWidth = useSelector(collaborationBarWidthSelector);
  const conferenceInProgressRef = useRef(isConferenceInProgress);
  const { width: windowWidth } = useWindowSize();
  const userPresenceBarWidth = isHost ? collaborationBarWidth : 0;
  const rndRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const resetDialogSize = useCallback(() => {
    if (!rndRef.current) return;

    rndRef.current.updateSize(dialogDefaultSize);
  }, []);

  const resetDialogPosition = useCallback(() => {
    if (!rndRef.current) return;

    rndRef.current.updatePosition({
      x:
        windowWidth -
        dialogDefaultSize.width -
        userPresenceBarWidth -
        dialogMargin,
      y: collaborationTop + collaborationToolsHeight + dialogMargin,
    });
  }, [windowWidth, userPresenceBarWidth]);

  const [dropPosition, setDropPosition] = useState(null);

  const rndDefault = useMemo(
    () => ({
      x:
        (windowWidth -
          userPresenceBarWidth -
          dialogDefaultSize.width -
          dialogMargin) /
        2,
      y: (collaborationTop + collaborationToolsHeight + dialogMargin) / 2,
      width: `${dialogDefaultSize.width}px`,
      height: `${dialogDefaultSize.height}px`,
    }),
    [windowWidth]
  );

  const roomState = useRoom({
    adaptiveStream: true,
    dynacast: true,
    videoCaptureDefaults: {
      resolution: VideoPresets.h720.resolution,
    },
  });

  const [size, setSize] = useState({
    width: dialogDefaultSize.width,
    height: dialogDefaultSize.height,
  });
  const [displayOptions, setDisplayOptions] = useState({
    stageLayout: isHost ? 'grid' : 'speaker',
  });

  const livekitContext = useContext(LiveKitContext);

  const onResize = useCallback(
    (e, direction, ref) => {
      setSize({
        width: ref.offsetWidth,
        height: ref.offsetHeight,
      });
    },
    [setSize]
  );

  useEffect(() => {
    livekitContext.onChangeConnectionState(roomState.connectionState);
  }, [roomState.connectionState, livekitContext.onChangeConnectionState]);

  const resetStageLayout = useCallback(() => {
    setDisplayOptions((prevOptions) => ({
      ...prevOptions,
      stageLayout: isHost ? 'grid' : 'speaker',
    }));
  }, [isHost, setDisplayOptions]);

  useEffect(() => {
    if (roomState.connectionState === ConnectionState.Disconnected) {
      onClose();
      resetDialogPosition();
      resetDialogSize();
      resetStageLayout();
    }
  }, [
    roomState.connectionState,
    onClose,
    resetDialogPosition,
    resetDialogSize,
    resetStageLayout,
  ]);

  const disconnectFromRoom = useCallback(async () => {
    await roomState.disconnect();

    if (isHost || conferenceInProgressRef.current) return;

    Snackbar.presenceStatus('The host has ended this call.', {
      type: 'error',
    });
  }, [isHost]);

  useEffect(() => {
    if (!token || !open) return undefined;

    resetDialogPosition();

    roomState.connect(environment.livekit.url, token).then((room) => {
      if (!room) return;

      if (onConnected && room.state === ConnectionState.Connected) {
        if (!environment.isProduction) {
          setLogLevel('debug');
        }

        onConnected(room, muted, cameraEnabled, deviceOptions);
      }
    });

    return () => {
      if (!token || !open) return;

      disconnectFromRoom();
    };
  }, [open, token, disconnectFromRoom]);

  useEffect(() => {
    const changePosition = (event) => {
      if (!rndRef.current) return;

      const newPosition = getPopupPosition(
        event,
        dropPosition,
        userPresenceBarWidth
      );
      setDropPosition(null);
      rndRef.current.updatePosition(newPosition);
    };

    window.addEventListener('resize', changePosition);

    return () => {
      window.removeEventListener('resize', changePosition);
    };
  }, [dropPosition, userPresenceBarWidth, setDropPosition]);

  const rndStyle = useMemo(
    () => ({
      position: 'absolute',
      zIndex: theme.zIndex.drawer + 1,
      borderRadius: livekitContext.isScreenShared ? 0 : '16px',
      overflow: 'hidden',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      ...(!open || !token ? { display: 'none' } : {}),
    }),
    [open, token, livekitContext.isScreenShared, theme.zIndex.drawer]
  );

  const onViewSpeakerClick = useCallback(() => {
    setDisplayOptions((prevOptions) => ({
      ...prevOptions,
      stageLayout: 'speaker',
    }));
  }, [setDisplayOptions]);

  const onViewGridClick = useCallback(() => {
    setDisplayOptions((prevOptions) => ({
      ...prevOptions,
      stageLayout: 'grid',
    }));
  }, [setDisplayOptions]);

  const onViewMinimizeClick = useCallback(() => {
    setDisplayOptions((prevOptions) => ({
      ...prevOptions,
      stageLayout: 'minimize',
    }));
    enqueueSnackbar('Your camera is still ON', {
      variant: 'warning',
      autoHideDuration: 3000,
    });
  }, [setDisplayOptions, enqueueSnackbar]);

  const onDragEnd = useCallback(
    (event, { lastX, lastY }) => {
      setDropPosition({ x: lastX, y: lastY });
    },
    [setDropPosition]
  );

  const onLeave = useCallback(async () => {
    await livekitContext.disconnectFromConference();
    resetDialogPosition();
    resetDialogSize();
    resetStageLayout();
  }, [livekitContext, resetDialogPosition, resetDialogSize, resetStageLayout]);

  useEffect(() => {
    if (!rndRef.current) return;

    if (livekitContext.isScreenShared) {
      rndRef.current.updateSize({
        width: '100%',
        height: '100%',
      });
      rndRef.current.updatePosition({
        x: 0,
        y: 0,
      });

      return;
    }

    resetDialogPosition();
    rndRef.current.updateSize({
      width: dialogDefaultSize.width,
      height: dialogDefaultSize.height,
    });
  }, [livekitContext.isScreenShared, resetDialogPosition]);

  return ReactDOM.createPortal(
    <>
      {displayOptions.stageLayout === 'minimize' && open && roomState.room ? (
        <DraggableMinimizedComponent
          rndDefault={rndDefault}
          rndStyle={rndStyle}
          userPresenceBarWidth={userPresenceBarWidth}
          dialogMargin={dialogMargin}
          collaborationToolsHeight={collaborationToolsHeight}
          collaborationTop={collaborationTop}
        >
          <CollapseStage
            roomState={roomState}
            onLeave={onLeave}
            stageLayout={displayOptions.stageLayout}
            onViewSpeakerClick={onViewSpeakerClick}
            onViewGridClick={onViewGridClick}
            onViewMinimizeClick={onViewMinimizeClick}
          />
        </DraggableMinimizedComponent>
      ) : (
        <Rnd
          default={rndDefault}
          onResize={onResize}
          onDragStop={onDragEnd}
          bounds="window"
          dragHandleClassName="dragging-handle"
          style={rndStyle}
          width={size.width}
          height={size.height}
          minWidth={360}
          minHeight={250}
          maxWidth={window.innerWidth}
          maxHeight={window.innerHeight}
          ref={rndRef}
          enableResizing={{
            bottomRight:
              livekitContext.isScreenShared && !isHost ? false : true,
          }}
          cancel=".cancel"
        >
          <DisplayContext.Provider value={displayOptions}>
            <Box
              className="dragging-handle"
              sx={{
                height: '100%',
                width: '100%',
                cursor: 'grab',
              }}
            >
              <Box
                sx={(t) => ({
                  height: '100%',
                  backgroundColor: t.background.bg2,
                  position: 'relative',
                  padding: '4px 4px 0px',
                  ':hover': {
                    [`& .${toggleButtonGroupClasses.root}`]: {
                      opacity: 1,
                    },
                  },
                })}
              >
                <>
                  {open && (
                    <StageView
                      controlRenderer={ControlsRenderer}
                      participantRenderer={ParticipantRenderer}
                      roomState={roomState}
                      onLeave={onLeave}
                    />
                  )}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 12,
                      left: 12,
                      display:
                        !isHost && livekitContext.isScreenShared
                          ? 'none'
                          : 'block',
                    }}
                  >
                    <ViewOptions
                      onViewSpeakerClick={onViewSpeakerClick}
                      onViewGridClick={onViewGridClick}
                      onViewMinimizeClick={onViewMinimizeClick}
                      stageLayout={displayOptions.stageLayout}
                    />
                  </Box>
                  <Box
                    className="resize-dialog-button"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      display:
                        !isHost && livekitContext.isScreenShared
                          ? 'none'
                          : 'block',
                    }}
                  >
                    <ResizeIcon
                      sx={(theme) => ({
                        color: theme.text.t7,
                      })}
                    />
                  </Box>
                </>
              </Box>
            </Box>
          </DisplayContext.Provider>
        </Rnd>
      )}
    </>,
    document.querySelector('.main.lb-holygrail')
  );
};

async function onConnected(room, muted, cameraEnabled, options) {
  const { cameraDeviceId, microphoneDeviceId } = options;

  await room.localParticipant.setCameraEnabled(cameraEnabled, {
    deviceId: cameraDeviceId,
  });
  await room.localParticipant.setMicrophoneEnabled(!muted, {
    deviceId: microphoneDeviceId,
  });
}

export default memo(LiveKitPopup);
