import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../common/utils/compose.utils';
import { withCanvasContext } from '../Contexts/CanvasContext';
import * as temporaryActionsReducer from '../../../common/reducers/board/temporaryDrawingActionsReducer';
import drawers from './drawers/index';

const enhancer = compose(
  withCanvasContext,
  connect(state => ({
    currentTemporaryAction: temporaryActionsReducer.currentTemporaryActionSelector(state),
    temporaryActions: temporaryActionsReducer.temporaryActionsSelector(state),
  })),
);

class PointersLayer extends React.Component {
  componentDidMount() {
    window.requestAnimationFrame(this.draw);
  }

  componentWillUnmount() {
    if (this.cancelAnimationFrame) {
      window.cancelAnimationFrame(this.cancelAnimationFrame);
    }
  }

  setDrawingContext = (ref) => {
    if (!ref) return;

    this.drawingContext = ref.getContext('2d');
  };

  drawingContext = null;
  cancelAnimationFrame = null;

  draw = () => {
    const {
      zoom,
      width,
      height,
      temporaryActions,
      currentTemporaryAction,
      scale,
      originX,
      originY,
    } = this.props;
    if (!this.drawingContext) return;
    this.drawingContext.clearRect(0, 0, width, height);
    this.drawingContext.scale(zoom, zoom);
    this.drawingContext.translate(-originX, -originY);
    this.drawingContext.scale(scale, scale);

    temporaryActions.forEach((action) => {
      const drawer = drawers[action.name];
      if (!drawer) return;
      drawer(this.drawingContext, action, scale * zoom);
    });

    if (currentTemporaryAction) {
      const drawer = drawers[currentTemporaryAction.name];
      if (!drawer) return;
      drawer(this.drawingContext, currentTemporaryAction, scale * zoom);
    }

    this.drawingContext.setTransform(1, 0, 0, 1, 0, 0);

    this.cancelAnimationFrame = window.requestAnimationFrame(this.draw);
  };

  render() {
    const {
      width,
      height,
    } = this.props;

    return (
      <canvas
        ref={this.setDrawingContext}
        width={width}
        height={height}
      />
    );
  }
}

export default enhancer(PointersLayer);
