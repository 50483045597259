import { httpServiceV4 } from '../../../../common/services/http.service';
import { AssessmentPaperDto } from './dto/AssessmentPaperDto';
import { PageDto } from '../../../../common/dto/PageDto';

const ASSESSMENT_PAPER_URL = '/assessment-paper';

const submitAssessmentPaper = (
  paperId: string,
  answers: any[]
): Promise<AssessmentPaperDto> =>
  httpServiceV4.post<AssessmentPaperDto, AssessmentPaperDto>(
    `${ASSESSMENT_PAPER_URL}/${paperId}/submit`,
    { answers }
  );

const getAssessmentPaperByAssessment = (
  id: string
): Promise<AssessmentPaperDto> =>
  httpServiceV4.get<AssessmentPaperDto, AssessmentPaperDto>(
    `${ASSESSMENT_PAPER_URL}/by-assessment/${id}`
  );

const getAssessmentPaper = (id: string): Promise<AssessmentPaperDto> =>
  httpServiceV4.get<AssessmentPaperDto, AssessmentPaperDto>(
    `${ASSESSMENT_PAPER_URL}/${id}`
  );

const getParticipantAssessmentPapers = (
  sessionId: string
): Promise<PageDto<AssessmentPaperDto>> =>
  httpServiceV4.get<PageDto<AssessmentPaperDto>, PageDto<AssessmentPaperDto>>(
    `${ASSESSMENT_PAPER_URL}?filters[sessionId]=${sessionId}`
  );

export {
  submitAssessmentPaper,
  getAssessmentPaper,
  getParticipantAssessmentPapers,
  getAssessmentPaperByAssessment,
};
