import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { cardContentClasses } from '@mui/material';

const Card = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  borderRadius: '30px 0px 20px 10px',
  boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
  transition: 'box-shadow 0.5s',
  '&:hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.14)',
    '& .cardIcon': {
      transition: 'color 0.5s',
      color: theme.palette.primary.main,
    },
  },
  [`& .${cardContentClasses.root}`]: {
    borderRadius: '0px 0px 20px 10px',
  },
}));

export default Card;
