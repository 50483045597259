import StatusType from '../../atoms/Status/type';

export enum CardType {
  BOARD = 'board',
  RECORDING = 'recording',
}

export type CardThumbnailProps = {
  status?: StatusType,
  altText: string,
  fallbackSrc: string,
  src: string,
  type: CardType,
};
