import Box from '@mui/material/Box';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { AspectRatio } from 'react-aspect-ratio';
import { createLocalVideoTrack, Track } from 'livekit-client';
import { dialogClasses } from '@mui/material/Dialog';
import VideoRenderer from '../../../components/LiveKitPopup/VideoRenderer';
import VideoSelect from '../../../components/AudioAndVideoSettingsDialog/VideoSelect';
import AudioSelect from '../../../components/AudioAndVideoSettingsDialog/AudioSelect';
import DeviceContext from '../../../components/BoardControls/CollaborationTools/DeviceContext/DeviceContext';
import MicrophoneOffOutlineIcon from '../../atoms/Icons/MicrophoneOffOutlinecon';
import MicrophoneOutlineIcon from '../../atoms/Icons/MicrophoneOutlineIcon';
import VideoOutlineIcon from '../../atoms/Icons/VideoOutlineIcon';
import VideoOffIcon from '../../atoms/Icons/VideoOffIcon';
import { ControlButton } from '../../../components/LiveKitPopup/ControlButton';
import DialogTitle from '../../atoms/DialogTitle';
import Dialog from '../../atoms/Dialog/Dialog';
import DialogContent from '../../atoms/DialogContent/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import { ParticipantAvatar } from '../../../components/LiveKitPopup/ParticipantRenderer';
import { userSelector } from '../../../common/reducers/userReducer';
import analyticsService from '../../../common/services/analytics.service';
import { DeviceOptions } from '../../../components/BoardControls/Call/CallContext/CallContext';
import {
  isConferenceInProgressSelector,
  isCurrentUserHostSelector,
} from '../../../common/reducers/session/sessionReducer';

type onSuccessProps = {
  connectMuted: boolean;
  muteParticipantsOnEntry: boolean;
  cameraEnabled: boolean;
  deviceOptions: DeviceOptions;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: (options: onSuccessProps) => void;
};

const CallDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const isHost = useSelector(isCurrentUserHostSelector);
  const isConferenceInProgress = useSelector(isConferenceInProgressSelector);

  const [isVideoEnabled, setVideoEnabled] = useState(false);
  const [isAudioEnabled, setAudioEnabled] = useState(isHost);
  const [isSubmitting, setSubmitting] = useState(false);
  const [videoTrack, setVideoTrack] = useState<Track | null>(null);
  const user = useSelector(userSelector);

  const {
    onChangeAudioOption,
    onChangeVideoOption,
    selectedAudioOption,
    selectedVideoOption,
    listAudioDevices,
    listVideoDevices,
  } = useContext(DeviceContext);

  const fetchDevices = useCallback(async () => {
    await Promise.all([listAudioDevices(), listVideoDevices()]);
  }, [listVideoDevices, listAudioDevices]);

  const mounted = useRef<boolean>(false);

  useEffect(() => {
    if (!isConferenceInProgress) {
      onClose();
    }
  }, [isConferenceInProgress, onClose]);

  useEffect(() => {
    mounted.current = open;
  }, [open]);

  const initializeVideoTrack = useCallback(() => {
    if (!selectedVideoOption) return;

    createLocalVideoTrack({
      deviceId: selectedVideoOption,
    }).then((track) => {
      if (!mounted.current) {
        track.stop();
        return;
      }
      setVideoEnabled(true);
      setVideoTrack(track);
    });
  }, [selectedVideoOption, setVideoEnabled, setVideoTrack]);

  useEffect(() => {
    if (!selectedVideoOption) return;

    initializeVideoTrack();
  }, [selectedVideoOption, initializeVideoTrack]);

  const changeAudioOption = useCallback(
    (event: any) => {
      onChangeAudioOption(event.target.value);
    },
    [onChangeAudioOption]
  );

  const changeVideoOption = useCallback(
    (event: any) => {
      onChangeVideoOption(event.target.value);
    },
    [onChangeVideoOption]
  );

  const toggleAudioEnable = useCallback(() => {
    setAudioEnabled((prevValue) => !prevValue);
  }, [setAudioEnabled]);

  const toggleVideoEnable = useCallback(() => {
    setVideoEnabled((prevValue) => !prevValue);
  }, [setVideoEnabled]);

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      analyticsService.event('Start LiveKit Call');
      setSubmitting(true);
      await onSuccess({
        connectMuted: !isAudioEnabled,
        muteParticipantsOnEntry: false,
        cameraEnabled: isVideoEnabled,
        deviceOptions: {
          cameraDeviceId: selectedVideoOption,
          microphoneDeviceId: selectedAudioOption,
        },
      });
      setSubmitting(false);
    },
    [
      onSuccess,
      setSubmitting,
      isAudioEnabled,
      isVideoEnabled,
      selectedVideoOption,
      selectedAudioOption,
    ]
  );

  const resetFields = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
    }

    setVideoEnabled(false);
    setAudioEnabled(isHost);
  }, [setVideoEnabled, setAudioEnabled, isHost, videoTrack]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          width: 520,
        },
      }}
      TransitionProps={{
        onEnter: async () => {
          await fetchDevices();
          initializeVideoTrack();
        },
        onExited: () => {
          resetFields();
        },
      }}
    >
      <form onSubmit={onSubmit} className="start-call-form">
        <DialogTitle onClose={onClose}>Start a Call</DialogTitle>
        <DialogContent>
          <Box display="flex" gap={3}>
            <Box display="flex" flexDirection="column" gap={2}>
              {isVideoEnabled && videoTrack ? (
                <Box
                  sx={{
                    borderRadius: 1,
                    overflow: 'hidden',
                    width: 200,
                    height: 166,
                  }}
                >
                  <AspectRatio ratio={16 / 9}>
                    <VideoRenderer
                      track={videoTrack}
                      isLocal={true}
                      width={200}
                      height={166}
                      objectFit="cover"
                      isPreviewMode
                    />
                  </AspectRatio>
                </Box>
              ) : (
                <Box
                  sx={(theme) => ({
                    background: theme.background.bg8,
                    borderRadius: 1,
                    width: 200,
                    height: 166,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}
                >
                  <ParticipantAvatar displayName={user.name} />
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 3,
                }}
              >
                <ControlButton
                  className="toggle-mic-button"
                  icon={
                    isAudioEnabled && selectedAudioOption
                      ? MicrophoneOutlineIcon
                      : MicrophoneOffOutlineIcon
                  }
                  onClick={toggleAudioEnable}
                  active={isAudioEnabled}
                  disabled={!selectedAudioOption}
                />
                <ControlButton
                  className="toggle-camera-button"
                  icon={
                    isVideoEnabled && selectedVideoOption
                      ? VideoOutlineIcon
                      : VideoOffIcon
                  }
                  onClick={toggleVideoEnable}
                  active={isVideoEnabled}
                  disabled={!selectedVideoOption}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={3} minWidth={0}>
              <VideoSelect
                option={selectedVideoOption}
                onChangeOption={changeVideoOption}
              />
              <AudioSelect
                option={selectedAudioOption}
                onChangeOption={changeAudioOption}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton onClick={onClose}>
            Cancel
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton type="submit" loading={isSubmitting}>
            Start
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CallDialog;
