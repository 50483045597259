import React from 'react';
import { MemoryRouter } from 'react-router';
import { AssessmentSidebarProvider } from './components/AssessmentSidebarProvider';
import { AssessmentRoutes } from './routes';

export const AssessmentTool = () => {
  return (
    <MemoryRouter>
      <AssessmentSidebarProvider>
        <AssessmentRoutes />
      </AssessmentSidebarProvider>
    </MemoryRouter>
  );
};
