import React, { useEffect } from 'react';
import usePageVisibility from '../../../common/hooks/PageVisibility';
import fireBaseService from '../../../common/services/firebaseService';

const UserPresenceTracker = ({
  track,
}) => {
  const isVisible = usePageVisibility();

  useEffect(() => {
    const updatePresence = async () => {
      await fireBaseService.updatePresence(isVisible);
    };
    if (track) {
      updatePresence();
    }
  }, [isVisible, track]);

  return null;
};

export default UserPresenceTracker;
