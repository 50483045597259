import React from 'react';
import { Subject } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { withCanvasContext } from '../../Contexts/CanvasContext';
import DomEventsUtil from '../../../../common/utils/domEvents.util';
import clearSelection from './clearSelection';
import GrabCursorSvgIcon from '../../../../assets/svgIcons/GrabCursorSvgIcon.svg';
import GrabbingCursorSvgIcon from '../../../../assets/svgIcons/GrabbingCursorSvgIcon.svg';

class InteractionsLayer extends React.Component {
  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
    }
    this.subscribeToMove();
    this.subscribeToZoom();
  }

  componentWillUnmount() {
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.wheel$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
    }
  }

  // React synthetic events
  onMouseDown = (e) => { this.mouseDown$.next(e.nativeEvent); };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => { this.mouseUp$.next(e.nativeEvent); };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;

    this.canvas = ref;

    this.canvas.style.cursor = `url(${GrabCursorSvgIcon}) 0 0, grab`;
  };

  canvas = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  wheel$ = new Subject();

  subscribeToZoom = () => {
    this.wheel$.pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe(wheel => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isLeftMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates),
      tap(() => {
        this.canvas.style.cursor = `url(${GrabbingCursorSvgIcon}) 0 0, grabbing`;
      }),
    ).pipe(tap(clearSelection));

    const leftMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isLeftMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates),
    );

    const mouseAndTouchMove$ = leftMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isLeftMouseEvent),
      tap(() => {
        this.canvas.style.cursor = `url(${GrabCursorSvgIcon}) 0 0, grab`;
      }),
    );

    const getMovesAfterStart = () => mouseAndTouchMove$.pipe(
      takeUntil(mouseAndTouchEnd$),
      pairwise(),
      map(([a, b]) => ({
        deltaX: b.x - a.x,
        deltaY: b.y - a.y,
      })),
    );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe(move => this.props.onMove(move));
  };

  render() {
    const {
      canvasWidth,
      canvasHeight,
    } = this.props;

    return (
      <canvas
        ref={this.setDrawingContext}
        width={canvasWidth}
        height={canvasHeight}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        onContextMenu={this.onContextMenu}
      />
    );
  }
}

export default withCanvasContext(InteractionsLayer);
