import { FC } from 'react';
import Box from '@mui/material/Box';
import Alert, { alertClasses } from '@mui/material/Alert';
import Typography from '../../atoms/Typography';
import { NotificationProps } from './notificationTypes';
import { TypographyType } from '../../atoms/Typography/types/Typography';

const Notification: FC<React.PropsWithChildren<NotificationProps>> = ({
  Icon,
  color,
  title,
  message,
  actionText,
  ActionButton,
}) => (
  <Alert
    icon={false}
    sx={(t) => ({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      borderRadius: '16px',
      padding: '20px 24px',
      backgroundColor: `${color}.alpha10`,
      [`& .${alertClasses.icon}`]: {
        marginRight: '14px',
        padding: 0,
        height: '100%',
        [t.breakpoints.up('sm')]: {
          height: 'unset',
        },
      },
      [`& .${alertClasses.message}`]: {
        padding: 0,
      },
    })}
  >
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {!!Icon && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
            }}
          >
            <Icon color={color} />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          columnGap: '14px',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          variant={TypographyType.p4}
          color={`${color}.main`}
          sx={{
            display: 'inline-flex',
            padding: '4px 0',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'inline-flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: '14px',
            rowGap: '8px',
          }}
        >
          {message && (
            <Typography
              variant={TypographyType.p5}
              sx={(t) => ({
                color: t.text.t8,
              })}
            >
              {message}
            </Typography>
          )}
          {ActionButton && (
            <Box>
              <ActionButton color={color}>{actionText}</ActionButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  </Alert>
);

export default Notification;
