import React, {
  useRef,
  useEffect,
  CSSProperties,
} from 'react';
import { ReactComponent as CursorSVGElement } from '../../assets/images/cursor-pointer.svg';
import * as cursorUtils from '../../common/utils/cursor.utils';

const style: CSSProperties = {
  position: 'absolute',
  top: '-10000px',
  left: '-10000px',
};

const CursorSVG = () => {
  const cursorRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    cursorUtils.setRef(cursorRef);
  }, []);

  return (
    <div style={style}>
      <CursorSVGElement ref={cursorRef} />
    </div>
  );
};

export default CursorSVG;
