import userService from '../services/auth.service';

const GET_GENERAL_CONFIGURATION_REQUEST = Symbol('GET_GENERAL_CONFIGURATION_REQUEST');
const GET_GENERAL_CONFIGURATION_SUCCESS = Symbol('GET_GENERAL_CONFIGURATION_SUCCESS');
const GET_GENERAL_CONFIGURATION_FAILURE = Symbol('GET_GENERAL_CONFIGURATION_FAILURE');
const CREATE_RECORDING = Symbol('CREATE_RECORDING');
const COPY_RECORDING = Symbol('COPY_RECORDING');
const REMOVE_RECORDING = Symbol('REMOVE_RECORDING');
const CREATE_BOARD = Symbol('CREATE_BOARD');
const COPY_BOARD = Symbol('COPY_BOARD');
const REMOVE_BOARD = Symbol('REMOVE_BOARD');

const generalConfigurationRequest = () => ({
  type: GET_GENERAL_CONFIGURATION_REQUEST,
});

const generalConfigurationSuccess = payload => ({
  type: GET_GENERAL_CONFIGURATION_SUCCESS,
  payload,
});

const generalConfigurationFailure = error => ({
  type: GET_GENERAL_CONFIGURATION_FAILURE,
  error,
});

const getGeneralConfiguration = () => {
  return async (dispatch) => {
    dispatch(generalConfigurationRequest());

    try {
      const generalConfiguration = await userService.getGeneralConfiguration();
      dispatch(generalConfigurationSuccess(generalConfiguration));
      return generalConfiguration;
    } catch (error) {
      dispatch(generalConfigurationFailure(error));
      console.log('Error: ', error);
    }
  };
};

const createRecording = () => ({
  type: CREATE_RECORDING,
});

const copyRecording = () => ({
  type: COPY_RECORDING,
});

const removeRecording = () => ({
  type: REMOVE_RECORDING,
});

const createBoard = () => ({
  type: CREATE_BOARD,
});

const copyBoard = () => ({
  type: COPY_BOARD,
});

const removeBoard = () => ({
  type: REMOVE_BOARD,
});

export {
  getGeneralConfiguration,
  GET_GENERAL_CONFIGURATION_REQUEST,
  GET_GENERAL_CONFIGURATION_SUCCESS,
  GET_GENERAL_CONFIGURATION_FAILURE,
  createRecording,
  copyRecording,
  removeRecording,
  CREATE_RECORDING,
  COPY_RECORDING,
  REMOVE_RECORDING,
  createBoard,
  copyBoard,
  removeBoard,
  CREATE_BOARD,
  COPY_BOARD,
  REMOVE_BOARD,
};
