import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { differenceBy } from 'lodash';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import MemberItem from '../../atoms/MemberItem';
import InputWithChip from '../InputWithChip';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import ChevronDownIcon from '../../atoms/Icons/ChevronDownIcon';
import Chip from '../../atoms/Chip';
import { contactListSelector } from '../../../common/reducers/contactsReducer';
import { userEmailSelector } from '../../../common/reducers/userReducer';
import InputStatus from '../../atoms/Input/InputStatus';
import CloseIcon from '../../atoms/Icons/CloseIcon';
import { ButtonSizeTypes } from '../../atoms/Button/buttonTypes';
import isValidEmail from '../../../common/utils/email.validation.util';
import Typography from '../../atoms/Typography/Typography';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import { participantsWithoutHostSelector } from '../../../common/reducers/session/participantsReducer';

type Props = {
  contacts?: any[];
  onAddMembers: (members: any[]) => void;
  onRemoveMember: (member: any) => Promise<void> | void;
  InviteButton?: FC<React.PropsWithChildren<ButtonProps>>;
  membersListHeight?: number;
  isInviteDialog?: boolean;
  errorMessage?: string;
  source: 'invite' | 'editGroup' | 'shareRecording' | 'createGroup';
};

const PopperComponent = (props: any) => (
  <Popper
    {...props}
    placement="bottom"
    sx={{
      [`& .${autocompleteClasses.listbox}`]: {
        maxHeight: 'initial',
      },
    }}
  />
);

const getOptionlabel = (option: any) => option.id;

const getLimitTagsText = (more: any) => (
  <Typography variant={TypographyType.p5}>{`+ ${more}`}</Typography>
);

const isOptionEqualToValue = (option: any, value: any) =>
  option.id === value.id;

const renderOption = (props: any, option: any) => (
  <MemberItem
    {...props}
    avatarSrc={option.profileImageUrl}
    name={option.name}
    email={option.email}
  />
);

const validateEmail = (
  inputValue: string,
  userEmail: string,
  selectedContacts: any[]
) => {
  if (
    inputValue === '' ||
    inputValue === userEmail ||
    selectedContacts.some(
      (element: any) => element.email && element.email === inputValue
    )
  )
    return false;

  if (inputValue && !isValidEmail(inputValue)) return false;

  return true;
};

const ContactsSelect: FC<React.PropsWithChildren<Props>> = ({
  contacts,
  onAddMembers,
  onRemoveMember,
  InviteButton,
  membersListHeight = 300,
  isInviteDialog = false,
  errorMessage,
  source,
}) => {
  const allContacts = useSelector(contactListSelector);
  const userEmail = useSelector(userEmailSelector);
  const joinedContacts = useSelector(participantsWithoutHostSelector);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [availableContacts, setAvailableContacts] =
    useState<any[]>(allContacts);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | null>(null);

  const getAvailableContacts = useCallback(
    (invitedContacts: any) =>
      differenceBy(allContacts, [...invitedContacts, ...joinedContacts], 'id'),
    [allContacts, joinedContacts]
  );

  useEffect(() => {
    setAvailableContacts(getAvailableContacts(contacts));
  }, [contacts, getAvailableContacts, setAvailableContacts]);

  const addMembers = useCallback(() => {
    if (!inputValue && !selectedContacts.length) return;
    if (inputValue && source === 'shareRecording') return;

    if (inputValue && !isValidEmail(inputValue)) {
      setError('invalid mail');

      return;
    }

    setError(null);

    if (inputValue) {
      const newContact = {
        name: inputValue,
        email: inputValue,
      };

      setAvailableContacts(
        getAvailableContacts([...selectedContacts, newContact])
      );
      setSelectedContacts([]);
      setInputValue('');
      onAddMembers([...selectedContacts, newContact]);
      return;
    }

    setAvailableContacts(getAvailableContacts(selectedContacts));
    setSelectedContacts([]);
    onAddMembers(selectedContacts);
  }, [
    inputValue,
    setError,
    selectedContacts,
    onAddMembers,
    setAvailableContacts,
    getAvailableContacts,
    setSelectedContacts,
    setInputValue,
    source,
  ]);

  const members = useMemo(() => {
    if (!contacts || contacts.length === 0) return null;

    return (
      <Box
        maxHeight={membersListHeight}
        overflow="auto"
        mt={isInviteDialog ? 1.5 : 3}
      >
        {contacts.map((contact) => (
          <MemberItem
            key={contact.name}
            avatarSrc={contact.profileImageUrl}
            name={contact.name}
            email={contact.email}
            actionIcon={
              !(contact.pending && source === 'editGroup') && <CloseIcon />
            }
            onIconClick={() => onRemoveMember(contact)}
          />
        ))}
      </Box>
    );
  }, [contacts, onRemoveMember, isInviteDialog, source]);

  const onOpen = useCallback(() => {
    setAutoCompleteOpen(true);
  }, [setAutoCompleteOpen]);

  const onClose = useCallback(() => {
    setAutoCompleteOpen(false);
  }, [setAutoCompleteOpen]);

  const onAutocompleteChange = useCallback(
    (event: any, newValue: any) => {
      setSelectedContacts(newValue);
      if (newValue.length && selectedContacts.length < newValue.length) {
        setInputValue('');
      }
    },
    [setSelectedContacts, setInputValue, selectedContacts]
  );

  const addEmailContact = useCallback(
    (inputValue: any, selectedContacts: any) => {
      const newContact = {
        name: inputValue,
        email: inputValue,
      };

      setSelectedContacts([...selectedContacts, newContact]);
      setError('');
      setInputValue('');
    },
    [setSelectedContacts, setError, setInputValue]
  );

  const onKeyDown = useCallback(
    (event: any) => {
      if (!(event.keyCode === 13 || event.keyCode === 32)) return;
      if (source === 'shareRecording') return;

      const isValidEmail = validateEmail(
        inputValue,
        userEmail,
        selectedContacts
      );

      if (!isValidEmail) {
        setError('invalid mail');
        return;
      }

      addEmailContact(inputValue, selectedContacts);
    },
    [
      inputValue,
      userEmail,
      selectedContacts,
      addEmailContact,
      setInputValue,
      source,
    ]
  );

  const onBlur = useCallback(() => {
    if (!inputValue) return;
    if (source === 'shareRecording') return;

    const isValidEmail = validateEmail(inputValue, userEmail, selectedContacts);

    if (!isValidEmail) {
      setError('invalid mail');
      return;
    }

    addEmailContact(inputValue, selectedContacts);
  }, [inputValue, userEmail, selectedContacts, addEmailContact, source]);

  return (
    <>
      <Box display="flex" gap={1.25} alignItems="center">
        <Autocomplete
          value={selectedContacts}
          inputValue={inputValue}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              (option) =>
                option.name &&
                option.name.toLowerCase().startsWith(inputValue.toLowerCase())
            )
          }
          onInputChange={(event, value, reason) => {
            if (reason === 'input') {
              setInputValue(value);
            }
          }}
          multiple
          filterSelectedOptions
          disableClearable
          options={availableContacts}
          limitTags={1}
          getLimitTagsText={getLimitTagsText}
          noOptionsText="No contact yet"
          onOpen={onOpen}
          onClose={onClose}
          onChange={onAutocompleteChange}
          getOptionLabel={getOptionlabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={renderOption}
          sx={{
            width: '292px',
            [`.${autocompleteClasses.popupIndicator}`]: {
              border: 'none',
              marginRight: '16px',
            },
            [`&.${autocompleteClasses.hasPopupIcon} .${autocompleteClasses.inputRoot}`]:
              {
                paddingRight: '42px',
                paddingBottom: '8px',
              },
          }}
          PopperComponent={PopperComponent}
          componentsProps={{
            paper: {
              sx: (theme) => ({
                padding: '8px 8px 12px 8px',
                backgroundColor: theme.background.bg2,
                border: '1px solid',
                borderColor: theme.background.bg5,
                borderRadius: '0px 0px 18px 18px',
                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
                maxHeight: 200,
              }),
            },
          }}
          popupIcon={
            <ChevronDownIcon
              sx={(theme) => ({
                color: theme.text.t6,
              })}
            />
          }
          renderInput={(params) => (
            <Box width={292}>
              <InputWithChip
                status={error ? InputStatus.ERROR : undefined}
                extraParams={params}
                fullWidth
                size={InputSize.S}
                variant={InputVariant.CONTAINED}
                placeholder="Enter contact or email"
                isAutocompleteOpen={autoCompleteOpen}
              />
            </Box>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.name} {...getTagProps({ index })} />
            ))
          }
          onKeyDown={(event) => {
            onKeyDown(event);
          }}
          onBlur={onBlur}
        />
        {InviteButton && (
          <InviteButton
            onClick={addMembers}
            size={ButtonSizeTypes.S}
            sx={{
              display: 'flex',
              flex: '1 1 auto',
            }}
          />
        )}
      </Box>
      {errorMessage && (
        <Box mt={1}>
          <Typography variant="p5" color="error">
            {errorMessage}
          </Typography>
        </Box>
      )}
      {members && isInviteDialog && (
        <Box mt={3}>
          <Typography
            variant="p5"
            sx={(theme) => ({
              color: theme.text.t7,
            })}
          >
            Invited
          </Typography>
        </Box>
      )}
      {members}
    </>
  );
};

export default ContactsSelect;
