import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import MuiFab, { FabProps } from '@mui/material/Fab';
import AccountMultiplePlusOutline from '../../../ui/atoms/Icons/AccountMultiplePlusOutline';

const Fab = styled(MuiFab)<FabProps>(({ theme, variant }) => ({
  ...theme.typography.b4,
  padding: '8px 14px',
  fontFamily: 'Barlow',
  textTransform: 'none',
  letterSpacing: 'unset',
  boxShadow: 'none',
  webkitTransition: 'none',
  verticalAlign: 'unset',
  position: 'unset',
  border: '2px solid',
  ...(variant === 'extended'
    ? {
        borderRadius: '14px',
        minWidth: '100px',
        width: 'unset',
        height: '40px',
      }
    : {
        height: '48px',
        width: '48px',
      }),
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  color: theme.text.t1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark5,
    border: `2px solid ${theme.palette.primary.dark5}`,
    boxShadow: 'unset',
  },
  '&:active': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    boxShadow: 'unset',
  },
  '&:disabled': {
    backgroundColor: theme.background.bg2,
    borderColor: theme.background.bg2,
    color: theme.text.t4,
  },
}));

type Props = {
  extended: boolean;
  onClick: () => void;
};

const InviteButton: FC<React.PropsWithChildren<Props>> = ({
  extended,
  onClick,
}) => {
  return (
    <Fab
      onClick={onClick}
      variant={extended ? 'extended' : 'circular'}
      className="product-tour-step-3"
    >
      <AccountMultiplePlusOutline
        sx={{
          width: extended ? 18 : 20,
          height: extended ? 18 : 20,
          mr: extended ? 1 : 'unset',
          color: 'white',
        }}
      />
      {extended && 'Invite students'}
    </Fab>
  );
};

export default InviteButton;
