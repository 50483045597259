import { addSeconds, format } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  remainingTime: number,
}

const RemainingTime = ({
  remainingTime,
}: Props) => {
  const helperDate = addSeconds(new Date(0), remainingTime);
  return (
    <Box>
      <Typography
        variant="body1"
        align="center"
        color="textPrimary"
        component="div"
      >
        <Box fontWeight="fontWeightBold" fontSize="1rem">
          {format(helperDate, 'mm:ss')}
        </Box>
      </Typography>
    </Box>
  );
};

export default RemainingTime;
