import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { boardDrawingToolsColor } from '../../../ui/atoms/Colors/colorStyles';
import { getSelectedDrawingTool } from '../../../common/reducers/board/drawingToolReducer';
import {
  rgbaToHexWithoutAlpha,
  toColor,
} from '../../../common/utils/color.utils';
import { getCurrentDrawing } from '../../../common/reducers/board/currentDrawingReducer';

type ColorBoxProps = {
  color: string;
  active: boolean;
  onClick: (color: string) => void;
  isLarge?: boolean;
};

const whiteColor = '#FFFFFF';

const ColorBox: FC<React.PropsWithChildren<ColorBoxProps>> = ({
  color,
  active,
  onClick,
  isLarge,
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width={isLarge ? 40 : 32}
    height={isLarge ? 40 : 32}
  >
    <Box
      onClick={(event) => {
        onClick(color);
        event.stopPropagation();
      }}
      width={32}
      height={32}
      borderRadius={7.5}
      sx={(theme) => ({
        border: `3px solid ${color === whiteColor ? theme.text.t2 : '#fff'}`,
        // Todo: Remove important when we finally remove bambourgh-wrappers
        outline: `1px solid ${
          active ? theme.palette.primary.main : 'transparent'
        } !important`,
        backgroundColor: color,
        cursor: 'pointer',
      })}
    />
  </Box>
);

const StrokeColorBox: FC<React.PropsWithChildren<ColorBoxProps>> = ({
  color,
  active,
  onClick,
  isLarge,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    onClick={(event) => {
      onClick(color);
      event.stopPropagation();
    }}
    width={isLarge ? 40 : 32}
    height={isLarge ? 40 : 32}
    borderRadius={10.5}
    sx={(theme) => ({
      // Todo: Remove important when we finally remove bambourgh-wrappers
      border: `1px solid ${
        active ? theme.palette.primary.main : 'transparent'
      } !important`,
    })}
  >
    <Box
      width={24}
      height={24}
      borderRadius={7.5}
      sx={(theme) => ({
        border: `2px solid ${color === whiteColor ? theme.text.t2 : color}`,
        cursor: 'pointer',
      })}
    />
  </Box>
);

type ColorPickerProps = {
  type?: 'fill' | 'outline';
  onChange: (color: string, fill: boolean) => void;
  isSelectedToolFill?: boolean;
  isLarge?: boolean;
};

const ColorPicker: FC<React.PropsWithChildren<ColorPickerProps>> = ({
  type = 'fill',
  onChange,
  isSelectedToolFill = true,
  isLarge = false,
}) => {
  const selectedTool = useSelector(getSelectedDrawingTool);
  const currentDrawing = useSelector(getCurrentDrawing);

  const selectedColor = useMemo(() => {
    const selectedToolColor =
      Number(currentDrawing?.paint.color) || selectedTool.color;
    const rgbaColor = toColor(selectedToolColor);

    return rgbaToHexWithoutAlpha(rgbaColor);
  }, [currentDrawing, selectedTool.color]);

  return (
    <Box display="flex" gap={0.5}>
      {boardDrawingToolsColor.map((color) => {
        if (type === 'fill') {
          return (
            <ColorBox
              key={`${color}-${type}`}
              color={color}
              active={selectedColor === color && isSelectedToolFill}
              onClick={() => onChange(color, true)}
              isLarge={isLarge}
            />
          );
        }

        return (
          <StrokeColorBox
            key={`${color}-${type}`}
            color={color}
            active={selectedColor === color && !isSelectedToolFill}
            onClick={() => onChange(color, false)}
            isLarge={isLarge}
          />
        );
      })}
    </Box>
  );
};

export default ColorPicker;
