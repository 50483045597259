import React, { Component, createRef } from 'react';
import { Subject } from 'rxjs';
import {
  auditTime,
  concatMap,
  filter,
  map,
  pairwise,
  takeUntil,
} from 'rxjs/operators';
import { connect } from 'react-redux';
import DomEventsUtil from '../../../../common/utils/domEvents.util';
import ResizableTextArea from './ResizableTextarea';
import {
  setPosition,
  setText,
  setSizes,
  clearDrawingTool,
} from '../../../../common/actions/board/textDrawingToolActions';
import { addActionAndSend } from '../../../../common/actions/board/drawingActionsActions';
import TextDrawingAction from '../../../../common/drawingActions/text.drawing.action';
import { getSelectedDrawingTool } from '../../../../common/reducers/board/drawingToolReducer';
import DeleteAction from '../../../../common/drawingActions/delete.action';
import { setShapeCurrentDrawing } from '../../../../common/actions/board/currentDrawingAction';
import './TextLayer.scss';
import * as drawingToolActions from '../../../../common/actions/board/drawingToolActions';
import clearSelection from '../../PureCanvas/drawingLayers/clearSelection';
import TextToolbar from './components/TextToolbar';
import DraggingMode from './DraggingMode';
import ToolNames from '../../../DrawingTools/ToolNames';
import TextCursorSvgIcon from '../../../../assets/svgIcons/TextCursorSvgIcon.svg';

const enhancer = connect(
  (state) => ({
    position: state.board.textDrawingTool.position,
    text: state.board.textDrawingTool.text,
    textWidth: state.board.textDrawingTool.width,
    textHeight: state.board.textDrawingTool.height,
    selectedTool: getSelectedDrawingTool(state),
  }),
  {
    setPosition,
    setText,
    setSizes,
    addActionAndSend,
    setShapeAction: setShapeCurrentDrawing,
    selectDrawingTool: drawingToolActions.selectDrawingTool,
    updateDrawingTool: drawingToolActions.updateDrawingTool,
    clearDrawingTool,
  }
);

class TextLayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropPosition: null,
      draggingMode: DraggingMode.Initial,
      textAreaRef: null,
    };

    this.shouldApplyTextRef = createRef();
    this.shouldApplyTextRef.current = true;
  }
  componentWillMount() {
    const { currentDrawing, scale, zoom, originX, originY } = this.props;
    if (currentDrawing && currentDrawing.name === 'text') {
      this.props.setPosition({
        x: (currentDrawing.startPoint.x * scale - originX) * zoom,
        y: (currentDrawing.startPoint.y * scale - originY) * zoom,
      });
      this.props.setText(currentDrawing.text);
      this.props.setSizes(
        currentDrawing.width * scale * zoom,
        currentDrawing.height * scale * zoom
      );
      return;
    }
    this.props.setPosition(null);
    this.props.setSizes(0, 0);
    this.props.setText('');
  }

  componentDidMount() {
    if (this.node) {
      this.node.addEventListener('wheel', this.onWheel, { passive: false });
      this.node.style.cursor = `url(${TextCursorSvgIcon}) 0 0, text`;
    }
    this.subscribeToMove();
    this.subscribeToZoom();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.position === null) {
      this.props.removeEventListeners();
    }
    if (prevProps.position && this.props.position === null) {
      this.props.addEventListeners();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedTool.name !== this.props.selectedTool.name ||
      nextProps.currentPageId !== this.props.currentPageId
    ) {
      this.shouldApplyTextRef.current = false;
      this.props.selectDrawingTool(ToolNames.Select);
      this.reset();
    }
  }

  componentWillUnmount() {
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.wheel$.complete();

    if (this.shouldApplyTextRef.current) {
      const textPosition = this.getTextToolPosition();
      if (textPosition) {
        this.applyAction(textPosition);
      }
    }

    this.props.clearDrawingTool();
    this.props.setCurrentDrawing(null);

    if (this.node) {
      this.node.removeEventListener('wheel', this.onWheel);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    clearSelection();
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  getTextToolPosition() {
    const { dropPosition } = this.state;
    const { position } = this.props;

    if (dropPosition && dropPosition.x && dropPosition.y) return dropPosition;
    if (position && position.x && position.y) return position;

    return null;
  }

  applyAction = (textPosition) => {
    const {
      scale,
      text,
      currentDrawing,
      textWidth,
      textHeight,
      originX,
      originY,
      zoom,
    } = this.props;
    if (!text) {
      this.onDelete();
      this.shouldApplyTextRef.current = true;
      return;
    }

    const { color, size } = this.getDrawingSettings();

    if (currentDrawing && currentDrawing.targetId) {
      currentDrawing.startPoint = {
        x: (textPosition.x / zoom + originX) / scale,
        y: (textPosition.y / zoom + originY) / scale,
      };
      currentDrawing.text = text;
      currentDrawing.width = textWidth / scale / zoom;
      currentDrawing.height = textHeight / scale / zoom;
      this.props.addActionAndSend(currentDrawing);
      this.props.updateDrawingTool('text', { previousDrawingTool: null });
      this.reset();
      return;
    }

    const drawingAction = new TextDrawingAction();
    drawingAction.startPoint = {
      x: (textPosition.x / zoom + originX) / scale,
      y: (textPosition.y / zoom + originY) / scale,
    };
    drawingAction.text = text;
    drawingAction.width = textWidth / scale / zoom;
    drawingAction.height = textHeight / scale / zoom;
    drawingAction.paint.color = color;
    drawingAction.paint.textSize = size;
    this.props.addActionAndSend(drawingAction);
    this.reset();
  };

  onClick = ({ target, nativeEvent }) => {
    const { dropPosition } = this.state;
    if (dropPosition) {
      this.props.setPosition({
        x: dropPosition.x,
        y: dropPosition.y,
      });
    }

    const textPosition = this.getTextToolPosition();

    if (textPosition && this.shouldApplyTextRef.current) {
      this.shouldApplyTextRef.current = false;
      this.applyAction(textPosition);
      if (this.props.text) {
        this.props.selectDrawingTool(ToolNames.Select);
      }

      return;
    }

    if (this.node !== target) return;

    const { offsetX, offsetY } = nativeEvent;
    this.props.setPosition({ x: offsetX, y: offsetY });
  };

  onTextChange = (text, width, height) => {
    this.props.setSizes(width, height);
    this.props.setText(text);
  };

  onDelete = () => {
    this.shouldApplyTextRef.current = false;

    if (this.props.currentDrawing && this.props.currentDrawing.targetId) {
      const action = new DeleteAction();
      action.targetId = this.props.currentDrawing.targetId;
      this.props.addActionAndSend(action);
      this.props.selectDrawingTool(ToolNames.Select);
      this.props.updateDrawingTool('text', { previousDrawingTool: null });
      setTimeout(() => this.reset(), 0);
    } else {
      if (this.props.text) {
        this.props.selectDrawingTool(ToolNames.Select);
      }

      this.reset();
    }
  };

  getDrawingSettings = () => {
    const { currentDrawing, selectedTool } = this.props;
    if (currentDrawing && currentDrawing.targetId) {
      const { color, textSize: size } = currentDrawing.paint;
      return { color, size };
    }

    const { color, size } = selectedTool;
    const { value: fontSize } = size;
    return { color, size: fontSize };
  };

  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  wheel$ = new Subject();

  reset = () => {
    this.props.setPosition(null);
    this.props.setText('');
    this.props.setSizes(0, 0);
    this.props.setShapeAction(null);
    this.setState({
      dropPosition: null,
    });
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  setDropPosition = (dropPosition) => {
    this.setState({
      dropPosition,
    });
  };

  onChangeDragMode = (mode) => {
    this.setState({
      draggingMode: mode,
    });
  };

  getToolbarPosition = () => {
    if (!this.state.textAreaRef) return null;

    return this.state.textAreaRef?.getBoundingClientRect();
  };

  setAreaRef = (ref) => {
    this.setState({
      textAreaRef: ref,
    });
  };

  render() {
    const { position, width, height, scale, text, zoom } = this.props;
    const { color, size } = this.getDrawingSettings();
    const isDragging = this.state.draggingMode === DraggingMode.Dragging;
    const toolbarPosition = this.getToolbarPosition();

    return (
      <div className="text-layer">
        <div
          role="presentation"
          className="drop-target"
          onClick={this.onClick}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
          onMouseUp={this.onMouseUp}
          onContextMenu={this.onContextMenu}
          ref={(ref) => {
            this.node = ref;
          }}
          style={{
            width,
            height,
          }}
        >
          {position && (
            <ResizableTextArea
              textAreaRef={this.setAreaRef}
              onMoveStart={this.props.onPointerDown}
              onChangeDragMode={this.onChangeDragMode}
              position={position}
              setDropPosition={this.setDropPosition}
              fontSize={size * scale * zoom}
              color={color}
              text={text}
              onTextChange={this.onTextChange}
            />
          )}
          {toolbarPosition && (
            <TextToolbar
              position={toolbarPosition}
              open={!isDragging}
              onDelete={this.onDelete}
            />
          )}
        </div>
      </div>
    );
  }
}

export default enhancer(TextLayer);
