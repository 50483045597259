import Action from './action';

export default class DrawingAction extends Action {
  paint = {
    strokeWidth: 5,
    textSize: 20,
    color: -16777216,
    alpha: 1,
    fill: false,
  };
  targetId = null;

  constructor(deserialized) {
    super(deserialized);

    this.name = 'drawingAction';

    if (deserialized) {
      if (deserialized.pnt) {
        this.paint.strokeWidth = deserialized.pnt.sw;
        this.paint.textSize = deserialized.pnt.ts;
        this.paint.fill =
          (typeof deserialized.pnt.f === 'string' &&
            deserialized.pnt.f === 'true') ||
          (typeof deserialized.pnt.f === 'boolean' && deserialized.pnt.f);
        this.paint.color = deserialized.pnt.c;
        // ToDo: Alpha is included in color now. Change later!
        // this.paint.alpha = deserialized.pnt.a / 255;
        this.paint.alpha = 1;
      }

      this.targetId = deserialized.tid || null;
    }
  }

  getTargetId() {
    return this.targetId;
  }

  serialize() {
    const { strokeWidth, textSize, color, alpha, fill } = this.paint;

    let serialized = {
      ...super.serialize(),
      pnt: {
        sw: `${strokeWidth}`,
        ts: `${textSize}`,
        c: `${color}`,
        a: `${alpha * 255}`,
        f: fill,
      },
    };

    const targetId = this.getTargetId();
    if (targetId !== null) {
      serialized = {
        ...serialized,
        tid: targetId,
      };
    }

    return serialized;
  }

  intersect() {
    return false;
  }
}
