import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getGroupSelector } from '../reducers/groupsReducer';

const useGroupContactWithoutInvited = (groupId: string) => {
  const groupAndContactsSelector = useMemo(() => (
    getGroupSelector(groupId)
  ), [groupId]);
  const group = useSelector(groupAndContactsSelector);

  if (group?.contacts) {
    return group.contacts.filter((contact: any) => !contact.pending);
  }

  return [];
};

export default useGroupContactWithoutInvited;
