import authService from '../services/auth.service';
import { isFetched } from './authActions';
import analyticsService from '../services/analytics.service';

const SET_USER = Symbol('SET_USER');

const UPDATE_PROFILE_IMAGE_REQUEST = Symbol('UPDATE_USER_FAILURE');
const UPDATE_USER_REQUEST = Symbol('UPDATE_USER_REQUEST');
const UPDATE_USER_SUCCESS = Symbol('UPDATE_USER_SUCCESS');
const UPDATE_USER_FAILURE = Symbol('UPDATE_USER_FAILURE');

const GET_ACCOUNT_DATA_REQUEST = Symbol('GET_ACCOUNT_DATA_REQUEST');
const GET_ACCOUNT_DATA_SUCCESS = Symbol('GET_ACCOUNT_DATA_SUCCESS');
const GET_ACCOUNT_DATA_FAILURE = Symbol('GET_ACCOUNT_DATA_FAILURE');

const SET_VERIFIED = Symbol('SET_VERIFIED');

const SUBMIT_PERSONAL_INFORMATION_ANSWERS = Symbol(
  'SUBMIT_PERSONAL_INFORMATION_ANSWERS'
);

const setUser = (user) => ({
  type: SET_USER,
  user,
});

const requestAccountData = () => ({
  type: GET_ACCOUNT_DATA_REQUEST,
});

const receiveAccountData = (user) => ({
  type: GET_ACCOUNT_DATA_SUCCESS,
  user,
});

const accountDataError = (message) => ({
  type: GET_ACCOUNT_DATA_FAILURE,
  message,
});

const getUser = () => {
  return async (dispatch) => {
    // We dispatch requestSignIn to kickoff the call to the API
    dispatch(requestAccountData());
    try {
      const data = await authService.getUser();
      dispatch(receiveAccountData(data));
      dispatch(isFetched(true));
      return data;
    } catch (error) {
      dispatch(accountDataError(error.message));
      dispatch(isFetched(false));
    }
  };
};

const updateProfileImageRequest = () => ({
  type: UPDATE_PROFILE_IMAGE_REQUEST,
});

const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
});

const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  error,
});

const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  user,
});

const updateUserName = (name) => (dispatch) => {
  dispatch(updateUserRequest());
  return authService
    .updateName(name)
    .then((user) => dispatch(updateUserSuccess(user)))
    .catch((error) => dispatch(updateUserFailure(error)));
};

const updateProfilePicture = (file) => (dispatch) => {
  dispatch(updateProfileImageRequest());
  authService
    .editProfilePicture(file)
    .then((user) => dispatch(updateUserSuccess(user)))
    .catch((error) => dispatch(updateUserFailure(error)));
};

const setVerified = () => ({
  type: SET_VERIFIED,
  payload: {
    isVerified: true,
  },
});

const submitPersonalInformationAnswers = () => ({
  type: SUBMIT_PERSONAL_INFORMATION_ANSWERS,
});

export {
  setUser,
  getUser,
  updateUserName,
  updateProfilePicture,
  setVerified,
  submitPersonalInformationAnswers,
  SET_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_PROFILE_IMAGE_REQUEST,
  GET_ACCOUNT_DATA_REQUEST,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAILURE,
  SET_VERIFIED,
  SUBMIT_PERSONAL_INFORMATION_ANSWERS,
};
