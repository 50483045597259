enum ActionName {
  FREE_LINE_V1 = 'free',
  FREE_LINE_V2 = 'free_v2',
  FREE_LINE_V3 = 'free_v3',
  ERASE_V1 = 'erase',
  ERASE_V2 = 'erase_v2',
  ERASE_V3 = 'erase_v3',
  CIRCLE = 'circle',
  LINE = 'line',
  ARROW = 'arrow',
  OVAL = 'oval',
  RECTANGLE = 'rectangle',
  TRIANGLE = 'triangle',
  CLEAR = 'clear',
  CLEAR_V2 = 'clear_v2',
  UNDO = 'undo',
  REDO = 'redo',
  TEXT = 'text',
  IMAGE = 'imageMeta',
  PDF_IMAGE = 'lockedImageMeta',
  POINT_LINE = 'pointLine',
  POINT_DOT = 'pointDot',
  DELETE = 'delete',
  ACTIVE_PAGE = 'activePage',
  PATH_SHAPE = 'pathShape',
  FORMULA = 'formula',
  GRAPH = 'graph',
}

export default ActionName;
