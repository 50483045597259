import BlobUtils from './blob.utils';
import FileReaderUtils from './fileReader.utils';

export type Dimensions = {
  width: number;
  height: number;
};

type Position = {
  x: number,
  y: number,
};

const toImage = (src: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => resolve(img);
  img.onerror = () => reject(new Error('Cannot load image'));

  img.src = src;
});

const resizeImage = async (
  file: File,
  dimensions: {
    maxWidth: number,
    maxHeight: number,
  },
): Promise<File> => {
  const dataURL = await FileReaderUtils.toDataURL(file);

  const image = await toImage(dataURL);

  if (image.width < dimensions.maxWidth && image.height < dimensions.maxHeight) return file;

  const canvas = document.createElement('canvas');

  let { width, height } = image;

  if (width > dimensions.maxWidth) {
    height = Math.round((height * dimensions.maxWidth) / width);
    width = dimensions.maxWidth;
  }
  if (height > dimensions.maxHeight) {
    width = Math.round((width * dimensions.maxHeight) / height);
    height = dimensions.maxHeight;
  }

  canvas.width = width;
  canvas.height = height;

  const drawingContext = canvas.getContext('2d');
  drawingContext?.drawImage(image, 0, 0, width, height);

  const resizedDataUrl = canvas.toDataURL(file.type, 0.7);
  const blob = BlobUtils.dataUriToBlob(resizedDataUrl);
  blob.name = file.name;
  blob.lastModifiedDate = new Date();

  return blob;
};

const getMimeTypeFromDataUrl = (dataUrl: string): string => dataUrl.substring('data:'.length, dataUrl.indexOf(';base64'));

const scaleDownToFit = (inner: Dimensions, outer: Dimensions) => {
  let imgWidth = inner.width;
  let imgHeight = inner.height;

  const maxSize = Math.min(outer.width, outer.height);

  if (Math.max(imgHeight, imgWidth) > maxSize) {
    const scale = maxSize / Math.max(imgHeight, imgWidth);

    imgWidth *= scale;
    imgHeight *= scale;
  }

  return {
    width: imgWidth,
    height: imgHeight,
  };
};

const scaleImageDownToFit = (img: HTMLImageElement, dimensions: Dimensions) => {
  const { width, height } = scaleDownToFit({
    width: img.width,
    height: img.height,
  }, {
    width: dimensions.width * 0.6,
    height: dimensions.height * 0.6,
  });

  return {
    width,
    height,
  };
};

const calculateImageMinSize = (startPoint: Position, endPoint: Position) => {
  let width = Math.abs(endPoint.x - startPoint.x);
  let height = Math.abs(endPoint.y - startPoint.y);

  if (width < height) {
    height = (height / width) * 10;
    width = 10;
  } else {
    width = (width / height) * 10;
    height = 10;
  }

  return {
    width,
    height,
  };
};

export default {
  scaleImageDownToFit,
  toImage,
  resizeImage,
  getMimeTypeFromDataUrl,
  calculateImageMinSize,
};
