export enum ButtonSizeTypes {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
}

export enum ButtonVariantTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT = 'text',
  OUTLINED = 'outlined',
}

export enum ButtonColorTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum ButtonShapeTypes {
  ROUNDED = 'rounded',
  CIRCLE = 'circle',
}
