import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import PlanDto from '../../../common/services/dto/subscriptions/PlanDto';
import CheckIcon from '../../../ui/atoms/Icons/CheckIcon';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import useDialog from '../../../common/hooks/useDialog';
import Button from '../../../ui/atoms/Button';
import PlanDialog from '../../../ui/molecules/PlanDialog';
import { SectionCard } from '../../../features/profile/components/SectionCard';
import { PlanTitleWithPrice } from '../../../features/profile/features/plan-details/components/PlanTitleWithPrice';
import PlanToggle from '../../../ui/molecules/PlanToggle';
import SubscriptionDto from '../../../common/services/dto/auth/SubscriptionDto';
import {
  userSelector,
  userSubscriptionSelector,
} from '../../../common/reducers/userReducer';
import authService from '../../../common/services/auth.service';
import analyticsService from '../../../common/services/analytics.service';
import { getGeneralConfiguration } from '../../../common/actions/configurationActions';
import { getLocaleDate } from '../../../common/utils/getLocaleDate';
import SubscriptionSourceType from '../../../common/services/types/auth/SubscriptionSourceType';
import * as userActions from '../../../common/actions/userActions';
import VerifyEmailNotification from '../../../ui/molecules/VerifyEmailNotification';
import CheckoutModal from '../../../containers/Modals/CheckoutModal';
import MobilePlanChangeWarning from '../../Plans/MobilePlanChangeWarning';

const PlanToggleWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '224px',
  marginBottom: '24px',
  paddingBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
    paddingBottom: '20px',
  },
}));

type PaidPlanProps = {
  isFetching: boolean;
  monthlyPlan: PlanDto;
  yearlyPlan: PlanDto;
  canStartTrial: boolean;
};

const PaidPlan: FC<React.PropsWithChildren<PaidPlanProps>> = ({
  monthlyPlan,
  yearlyPlan,
  isFetching,
  canStartTrial,
}) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const currentSubscription = useSelector<any, SubscriptionDto>(
    userSubscriptionSelector
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const planDialog = useDialog();
  const [isYearlySelected, setIsYearlySelected] = useState(true);
  const planToShow = isYearlySelected ? yearlyPlan : monthlyPlan;

  const {
    open: verificationDialogOpen,
    onClose: onVerificationDialogClose,
    onOpen: onVerificationDialogOpen,
  } = useDialog();

  const {
    open: checkoutDialogOpen,
    onClose: onCheckoutDialogClose,
    onOpen: onCheckoutDialogOpen,
  } = useDialog();

  const {
    open: mobileWarningDialogOpen,
    onClose: onMobileWarningDialogClose,
    onOpen: onMobileWarningDialogOpen,
  } = useDialog();

  const onChangePlanPeriod = useCallback(
    (event: any, value: any) => {
      if (!value) return;

      setIsYearlySelected(value === 'yearly');
    },
    [setIsYearlySelected]
  );

  const discountPercent = useMemo(() => {
    if (!yearlyPlan || !monthlyPlan) return '';

    const monthlyPrice = monthlyPlan.price;
    const yearlyPrice = yearlyPlan.price;
    const yearlyPriceMonthlyPeriod = yearlyPrice / 12;

    return `${Math.round(
      (1 - yearlyPriceMonthlyPeriod / monthlyPrice) * 100
    )}%`;
  }, [monthlyPlan?.price, yearlyPlan?.price]);

  const checkCanStartTrial = useCallback(
    (plan: PlanDto) => {
      if (plan.canStartTrial) {
        return (
          currentSubscription.plan.isFree &&
          user.canActivateTrial &&
          plan.canStartTrial
        );
      }

      return currentSubscription.plan.isFree && user.canActivateTrial;
    },
    [currentSubscription, user]
  );

  const onStartTrialClick = useCallback(
    async (planId: any) => {
      try {
        setIsSubmitting(true);
        const updatedUser = await authService.startTrial(planId);

        dispatch(userActions.setUser(updatedUser));
        enqueueSnackbar('Congratulations! Your 14-day trial started', {
          variant: 'success',
        });

        analyticsService.event('Trial Activated', {
          planId,
          source: 'Settings',
        });

        dispatch(getGeneralConfiguration());
      } catch (error) {
        if (error.code === '102001') {
          onVerificationDialogOpen();
        } else {
          enqueueSnackbar('Starting trial has failed', {
            variant: 'error',
          });
        }

        setIsSubmitting(false);
      }
    },
    [dispatch, setIsSubmitting, enqueueSnackbar, onVerificationDialogOpen]
  );

  const onPaymentSuccessFactory = useCallback(
    (planId: string) => async (promoCodeId?: string) => {
      try {
        const updatedUser = await authService.startSubscription(
          planId,
          promoCodeId
        );

        dispatch(userActions.setUser(updatedUser));

        let planName = updatedUser.subscription.plan.name;
        let message = `Your transaction has been successfully processed and ${planName} plan benefits are already reflected in your account`;

        if (
          currentSubscription.upcomingPlanChange &&
          !updatedUser.subscription.upcomingPlanChange &&
          currentSubscription.plan.id === updatedUser.subscription.plan.id
        ) {
          message = 'Your current plan has been reactivated';
        }

        if (updatedUser.subscription.upcomingPlanChange) {
          planName = updatedUser.subscription.upcomingPlanChange.plan.name;
          const changeDate = getLocaleDate(
            updatedUser.subscription.upcomingPlanChange.changeDate
          );

          message = `Your transaction has been successfully scheduled and ${planName} plan benefits will be reflected in your account on ${changeDate}`;
        }

        enqueueSnackbar(message, {
          variant: 'success',
        });
        dispatch(getGeneralConfiguration());
      } catch (e) {
        if (e.code === '302004') {
          enqueueSnackbar(
            'Payment failed due to a card issue or insufficient funds',
            {
              variant: 'error',
            }
          );

          throw e;
        }

        enqueueSnackbar('Plan purchase has failed', {
          variant: 'error',
        });
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const onPurchaseClick = useCallback(
    async (plan: PlanDto) => {
      if (
        !currentSubscription.plan.isFree &&
        user.subscriptionSource !== SubscriptionSourceType.STRIPE
      ) {
        onMobileWarningDialogOpen();

        return;
      }

      setIsSubmitting(true);

      if (currentSubscription.isTrial) {
        const onPaymentSuccess = onPaymentSuccessFactory(plan.id);
        try {
          await onPaymentSuccess();
        } finally {
          setIsSubmitting(false);
        }
        return;
      }

      try {
        const card = await authService.getDefaultPaymentMethod();

        if (card) {
          const onPaymentSuccess = onPaymentSuccessFactory(plan.id);
          await onPaymentSuccess();
        } else {
          onCheckoutDialogOpen();
        }
      } catch {
        onCheckoutDialogOpen();
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      currentSubscription,
      setIsSubmitting,
      onPaymentSuccessFactory,
      onCheckoutDialogOpen,
      onMobileWarningDialogOpen,
      user,
    ]
  );

  const onButtonClick = useCallback(() => {
    if (checkCanStartTrial(planToShow!)) {
      onStartTrialClick(planToShow!.id);
      return;
    }

    onPurchaseClick(planToShow!);
  }, [checkCanStartTrial, onPurchaseClick, onStartTrialClick, planToShow]);

  if (isFetching) {
    return (
      <SectionCard title="Upgrade plan">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <CircularProgress variant="indeterminate" />
        </Box>
      </SectionCard>
    );
  }

  return (
    <SectionCard title="Upgrade plan">
      <PlanToggleWrapper>
        <PlanToggle
          isYearlySelected={isYearlySelected}
          onChange={onChangePlanPeriod}
          discountText={discountPercent}
        />
      </PlanToggleWrapper>
      <Box mb={3}>
        <PlanTitleWithPrice
          name={planToShow.name}
          price={planToShow.price}
          billingCycle={planToShow.billingCycle}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={0.875} mb={5}>
        {planToShow.features.map((feature, index) => (
          <Box key={index} display="flex" alignItems="center" gap={1}>
            <CheckIcon
              color="primary"
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
            <Typography
              variant="p4"
              dangerouslySetInnerHTML={{ __html: feature.text }}
            />
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Button
          onClick={onButtonClick}
          variant={ButtonVariantTypes.PRIMARY}
          size={ButtonSizeTypes.S}
        >
          {canStartTrial ? 'Start Free Trial' : 'Upgrade'}
        </Button>
      </Box>
      <PlanDialog
        source="Plan & Billing"
        open={planDialog.open}
        onClose={planDialog.onClose}
      />
      <VerifyEmailNotification
        dialog
        open={verificationDialogOpen}
        onClose={onVerificationDialogClose}
      />
      <CheckoutModal
        open={checkoutDialogOpen}
        planId={planToShow!.id}
        price={planToShow!.price}
        onClose={onCheckoutDialogClose}
        onSuccess={onPaymentSuccessFactory(planToShow!.id)}
      />
      <MobilePlanChangeWarning
        open={mobileWarningDialogOpen}
        onClose={onMobileWarningDialogClose}
      />
    </SectionCard>
  );
};

export default PaidPlan;
