import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupList } from '../common/actions/groupsActions';
import { getContactList } from '../common/actions/contactsActions';
import { getFeatureTours } from '../common/actions/featureToursActions';
import * as contactsReducer from '../common/reducers/contactsReducer';
import * as groupsReducer from '../common/reducers/groupsReducer';
import * as configurationReducer from '../common/reducers/configurationReducer';
import Loader from './Icons/Loader';
import { getGeneralConfiguration } from '../common/actions/configurationActions';
import analyticsService from '../common/services/analytics.service';
import { useUtmParams } from '../common/hooks/useUtmParams';

const InitialDataFetcher = ({ children }) => {
  const contactsFetched = useSelector(contactsReducer.isFetchedSelector);
  const groupsFetched = useSelector(groupsReducer.isFetchedSelector);
  const configurationFetched = useSelector(
    configurationReducer.isFetchedSelector
  );

  const dispatch = useDispatch();
  const utmParams = useUtmParams();

  useEffect(() => {
    dispatch(getGroupList());
    dispatch(getContactList());
    dispatch(getFeatureTours());
    dispatch(getGeneralConfiguration());

    if (utmParams) {
      analyticsService.setUserPropertiesOnce(utmParams);
    }
  }, [utmParams, dispatch]);

  return contactsFetched && groupsFetched && configurationFetched ? (
    children
  ) : (
    <Loader />
  );
};

export default InitialDataFetcher;
