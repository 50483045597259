import { ControlButton } from './ControlButton';
import VideoOutlineIcon from '../../ui/atoms/Icons/VideoOutlineIcon';
import VideoOffIcon from '../../ui/atoms/Icons/VideoOffIcon';

export interface VideoSelectButtonProps {
  isEnabled: boolean;
  onClick: () => void;
  className?: string;
  isButtonDisabled?: boolean;
  popoverContainerClassName?: string;
  popoverTriggerBtnClassName?: string;
  popoverTriggerBtnSeparatorClassName?: string;
  minimized?: boolean;
}

export const VideoSelectButton = ({
  isEnabled,
  onClick,
  className,
  isButtonDisabled,
  popoverContainerClassName,
  popoverTriggerBtnClassName,
  popoverTriggerBtnSeparatorClassName,
  minimized = false,
}: VideoSelectButtonProps) => (
  <ControlButton
    icon={isEnabled ? VideoOutlineIcon : VideoOffIcon}
    active={isEnabled}
    disabled={isButtonDisabled}
    onClick={onClick}
    className={className}
    popoverContainerClassName={popoverContainerClassName}
    popoverTriggerBtnClassName={popoverTriggerBtnClassName}
    popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
    minimized={minimized}
  />
);
