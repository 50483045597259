import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  browserName,
  fullBrowserVersion,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';
import Link from '@mui/material/Link';
import {
  supportEmailSelector,
  userEmailSelector,
} from '../../common/reducers/userReducer';

const getMailBody = (email: any) => {
  let body = `\n\n\nOS: ${osName} ${osVersion}\nBrowser: ${browserName} ${fullBrowserVersion}`;
  if (mobileVendor !== 'none' && mobileModel !== 'none') {
    body = `${body}\nDevice: ${mobileVendor} ${mobileModel}`;
  }
  body = `${body}\nAccount Email: ${email}`;
  return encodeURIComponent(body);
};

const SupportMailLink: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const supportEmail = useSelector(supportEmailSelector);
  const userEmail = useSelector(userEmailSelector);

  return (
    <Link
      href={`mailto:${supportEmail}?body=${getMailBody(userEmail)}`}
      underline="none"
      target="_blank"
    >
      {children}
    </Link>
  );
};

export default SupportMailLink;
