import React from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import Link from '@mui/material/Link';
import Typography from '../../atoms/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import CardForm from './CardForm';
import environment from '../../../config/environment';

const promise = loadStripe(environment.stripePublishKey);

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
};

const CardModal = ({ open, onClose, onSuccess }: Props) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle hasCloseButton={false}>Payment Method</DialogTitle>
    <DialogContent
      sx={{
        mb: 0,
      }}
    >
      <Elements stripe={promise}>
        <CardForm buttonContent="Confirm card" onSuccess={onSuccess} />
      </Elements>
      <Typography
        variant="subtitle2"
        textAlign="right"
        sx={{
          fontWeight: 700,
        }}
      >
        Powered by{' '}
        <Link
          href="https://stripe.com/"
          target="_blank"
          className="text-primary"
        >
          Stripe
        </Link>
      </Typography>
    </DialogContent>
  </Dialog>
);

export default CardModal;
