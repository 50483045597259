import { FC } from 'react';
import Box from '@mui/material/Box';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '../../../ui/atoms/Typography';

type HotkeyBoxProps = {
  Icon: FC<React.PropsWithChildren<SvgIconProps>>;
  title: string;
  hotkey: string;
};

const HotkeyBox: FC<React.PropsWithChildren<HotkeyBoxProps>> = ({
  Icon,
  title,
  hotkey,
}) => (
  <Box
    display="flex"
    width="100%"
    height={48}
    padding="14px 0px"
    sx={(theme) => ({
      borderBottom: `1px solid ${theme.background.bg4}`,
    })}
  >
    <Box mr={1.25}>
      <Icon
        sx={{
          width: 20,
          height: 20,
        }}
      />
    </Box>
    <Box flex={1}>
      <Typography variant="p5">{title}</Typography>
    </Box>
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.background.bg5}`,
        borderRadius: '2px',
        padding: '4px',
        minWidth: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Typography variant="p6">{hotkey}</Typography>
    </Box>
  </Box>
);

export default HotkeyBox;
