import React, { forwardRef } from 'react';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import InformationIcon from '../../ui/atoms/Icons/InformationIcon';
import Typography from '../../ui/atoms/Typography';
import LiveBoardTheme from '../../ui/LiveBoardTheme';
import CheckCircleIcon from '../../ui/atoms/Icons/CheckCircleIcon';
import AlertIcon from '../../ui/atoms/Icons/AlertIcon';
import PresenceStatusNotification from '../../ui/molecules/PresenceStatusNotification';
import { SnackbarUtilsConfigurator } from './SnackbarConfig';
import styles from './SnackbarWrapper.module.css';

const SnackbarWrapper = ({ children }) => {
  const notistackRef = React.createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const CustomSnackbar = forwardRef(
    (
      { key, message, statusIcon, link = null, linkText = null, ...props },
      ref
    ) => (
      <LiveBoardTheme>
        <Box
          {...props}
          ref={ref}
          sx={(theme) => ({
            padding: '8px 16px',
            backgroundColor: theme.background.bg9,
            height: 48,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderRadius: '8px',
          })}
        >
          <Box display="flex" alignItems="center" gap={1} flex={1}>
            {statusIcon}
            <Typography
              variant="p5"
              sx={(theme) => ({
                color: theme.text.t2,
              })}
            >
              {message}
              {link && linkText && (
                <>
                  {' '}
                  <Link href={link} target="_blank">
                    {linkText}
                  </Link>
                </>
              )}
            </Typography>
          </Box>
          <Box
            onClick={onClickDismiss(key)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <CloseIcon
              sx={(theme) => ({
                color: theme.text.t4,
                width: 18,
                height: 18,
              })}
            />
          </Box>
        </Box>
      </LiveBoardTheme>
    )
  );

  const SuccessSnackbar = forwardRef((props, ref) => (
    <CustomSnackbar
      {...props}
      ref={ref}
      statusIcon={
        <CheckCircleIcon
          sx={(theme) => ({
            color: theme.palette.success.main,
            width: 18,
            height: 18,
          })}
        />
      }
    />
  ));

  const ErrorSnackbar = forwardRef((props, ref) => (
    <CustomSnackbar
      {...props}
      ref={ref}
      statusIcon={
        <InformationIcon
          sx={(theme) => ({
            color: theme.palette.error.main,
            width: 18,
            height: 18,
          })}
        />
      }
    />
  ));

  const WarningSnackbar = forwardRef((props, ref) => (
    <CustomSnackbar
      {...props}
      ref={ref}
      statusIcon={
        <AlertIcon
          sx={(theme) => ({
            color: theme.palette.warning.main,
            width: 18,
            height: 18,
          })}
        />
      }
    />
  ));

  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      Components={{
        success: SuccessSnackbar,
        error: ErrorSnackbar,
        warning: WarningSnackbar,
        presenceStatus: PresenceStatusNotification,
      }}
      classes={{
        root: styles.snackbar,
      }}
    >
      {children}
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  );
};

export default SnackbarWrapper;
