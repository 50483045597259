import { first, last } from 'lodash';
import { toColor } from '../../../../common/utils/color.utils';

const freeLineDrawer = (ctx, item) => {
  const {
    name,
    processedPoints: points,
    paint,
  } = item;
  const {
    color,
    strokeWidth,
  } = paint;
  if (!points || !points.length) return;
  const rgbaColor = toColor(color);
  ctx.save();
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  if (name === 'erase_v3' || name === 'erase_v2' || name === 'erase') {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.strokeStyle = '#fff';
  } else {
    ctx.strokeStyle = rgbaColor;
  }
  ctx.lineWidth = strokeWidth;

  ctx.beginPath();
  const firstPoint = first(points);
  ctx.moveTo(firstPoint.x1, firstPoint.y1);
  points.forEach(({
    x1,
    y1,
    x2,
    y2,
  }) => {
    if (!x2) return;

    ctx.quadraticCurveTo(x1, y1, x2, y2);
  });
  const lastPoint = last(points);
  // Draw last line as a straight line while
  // we wait for the next point to be able to calculate
  // the bezier control point
  ctx.lineTo(lastPoint.x1, lastPoint.y1);

  ctx.stroke();
  ctx.restore();
};

export default freeLineDrawer;
