import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import { recordingsCountByGroupIdSelector } from '../../../common/reducers/recordingsReducer';
import { boardsCountByGroupIdSelector } from '../../../common/reducers/sessionsReducer';
import analyticsService from '../../../common/services/analytics.service';
import TabItem from '../../molecules/TabItem';
import { SortOptions } from '../../molecules/SortingCard/sortTypes';
import RouteTypes from './dashboardTypes';
import SortingCard from '../../molecules/SortingCard';

type SubheaderProps = {
  isFetching: boolean;
  changeSelectOption: (value: SortOptions) => void;
  sortConfig: SortOptions;
};

const SubHeader: FC<React.PropsWithChildren<SubheaderProps>> = ({
  isFetching,
  changeSelectOption,
  sortConfig,
}) => {
  const params = useParams<{ groupId: string; tab: RouteTypes }>();
  const { groupId = 'default' } = params;
  const boardsCountSelector = useMemo(
    () => boardsCountByGroupIdSelector(groupId),
    [groupId]
  );
  const boardsCount = useSelector(boardsCountSelector);

  const recordingsCountSelector = useMemo(
    () => recordingsCountByGroupIdSelector(groupId),
    [groupId]
  );
  const recordingsCount = useSelector(recordingsCountSelector);

  const onBoardsClick = useCallback(() => {
    analyticsService.event('Boards Tab Click');
  }, []);

  const onRecordingsClick = useCallback(() => {
    analyticsService.event('Recordings Tab Click');
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={6}
      sx={(t) => ({
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px',
        [t.breakpoints.up('sm')]: {
          marginBottom: '32px',
        },
        [t.breakpoints.up('lg')]: {
          marginBottom: '48px',
        },
      })}
    >
      <Box display="flex" gap={2}>
        <TabItem
          component={NavLink}
          to={`/group/${groupId}/sessions`}
          onClick={onBoardsClick}
          activeClassName="active"
          exact
        >
          {`Boards${isFetching ? '' : ` (${boardsCount})`}`}
        </TabItem>
        <TabItem
          component={NavLink}
          to={`/group/${groupId}/recordings`}
          onClick={onRecordingsClick}
          activeClassName="active"
          exact
        >
          {`Recordings${isFetching ? '' : ` (${recordingsCount})`}`}
        </TabItem>
      </Box>
      <SortingCard
        sortConfig={sortConfig}
        changeSelectOption={changeSelectOption}
      />
    </Box>
  );
};

export default SubHeader;
