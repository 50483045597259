import { svgIconClasses } from '@mui/material/SvgIcon';

export const iconButtonXlStyle = {
  width: '64px',
  height: '64px',
  padding: '20px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1.5rem',
  },
};

export const iconButtonLStyle = {
  width: '56px',
  height: '56px',
  padding: '17px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1.375rem',
  },
};

export const iconButtonMStyle = {
  width: '48px',
  height: '48px',
  padding: '14px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1.25rem',
  },
};

export const iconButtonSStyle = {
  width: '40px',
  height: '40px',
  padding: '11px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1.125rem',
  },
};

export const iconButtonXSStyle = {
  width: '32px',
  height: '32px',
  padding: '8px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1rem',
  },
};

export const iconButtonXSSStyle = {
  width: '24px',
  height: '24px',
  padding: '5px',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '0.875rem',
  },
};

export const iconButtonRounded = {
  borderRadius: '16px',
};
