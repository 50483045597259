import { omit } from 'lodash';
import {
  SELECT_DRAWING_TOOL,
  UPDATE_DRAWING_TOOL,
  SET_DEFAULTS,
  ADD_PATH_SHAPE_TOOLS,
  CLEAR_PATH_SHAPE_TOOLS,
  UPDATE_DRAWING_TOOLS_SIZES,
} from '../../actions/board/drawingToolActions';
import { rgbToArgbNumber } from '../../utils/color.utils';
import ToolNames from '../../../components/DrawingTools/ToolNames';

const createInitialColor = (alpha = 1, r = 0, g = 0, b = 0) =>
  rgbToArgbNumber({
    a: alpha * 255,
    b,
    g,
    r,
  });

const createSize = (min, max, value) => ({
  min,
  max,
  value,
  originValue: value,
  ratio: 1,
});

const initialState = {
  availableTools: {
    move: {
      name: ToolNames.Move,
    },
    select: {
      name: ToolNames.Select,
    },
    pointer: {
      name: ToolNames.Pointer,
    },
    pen: {
      name: ToolNames.Pen,
      size: createSize(2, 20, 2),
      color: createInitialColor(),
    },
    marker: {
      name: ToolNames.Marker,
      size: createSize(10, 40, 10),
      color: createInitialColor(0.4, 255, 210, 66),
    },
    eraser: {
      name: ToolNames.Eraser,
      size: createSize(20, 70, 40),
    },
    line: {
      name: ToolNames.Line,
      size: createSize(2, 10, 4),
      color: createInitialColor(),
    },
    arrow: {
      name: ToolNames.Arrow,
      size: createSize(2, 10, 4),
      color: createInitialColor(),
    },
    oval: {
      name: ToolNames.Oval,
      size: createSize(2, 10, 4),
      color: createInitialColor(),
      fill: false,
    },
    circle: {
      name: ToolNames.Circle,
      size: createSize(2, 10, 4),
      color: createInitialColor(),
      fill: false,
    },
    rectangle: {
      name: ToolNames.Rectangle,
      size: createSize(2, 10, 4),
      color: createInitialColor(),
      fill: false,
    },
    triangle: {
      name: ToolNames.Triangle,
      size: createSize(2, 10, 4),
      color: createInitialColor(),
      fill: false,
    },
    text: {
      name: ToolNames.Text,
      size: createSize(12, 72, 14),
      color: createInitialColor(),
    },
    formula: {
      name: ToolNames.Formula,
      previousDrawingTool: null,
      color: createInitialColor(),
    },
    graph: {
      name: ToolNames.Graph,
      previousDrawingTool: null,
      color: createInitialColor(),
    },
    image: {
      name: ToolNames.Image,
      previousDrawingTool: null,
    },
    importPdf: {
      name: ToolNames.ImportPdf,
      previousDrawingTool: null,
    },
    pathShapes: [],
  },
  active: {
    shape: ToolNames.Rectangle,
    pen: ToolNames.Pen,
    function: undefined,
  },
  selected: ToolNames.Pen,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DRAWING_TOOL:
      const shapeTools = [
        ToolNames.Oval,
        ToolNames.Rectangle,
        ToolNames.Circle,
        ToolNames.Line,
        ToolNames.Arrow,
        ToolNames.Triangle,
      ];
      if (
        shapeTools.indexOf(action.name) !== -1 ||
        state.availableTools.pathShapes.findIndex(
          (t) => t.id === action.name
        ) !== -1
      ) {
        return {
          ...state,
          selected: action.name,
          active: {
            ...state.active,
            shape: action.name,
            function: undefined,
          },
        };
      }

      const penTools = [ToolNames.Pen, ToolNames.Marker];
      if (penTools.indexOf(action.name) !== -1) {
        return {
          ...state,
          selected: action.name,
          active: {
            ...state.active,
            pen: action.name,
            function: undefined,
          },
        };
      }

      const functionTools = [ToolNames.Formula, ToolNames.Graph];
      if (functionTools.indexOf(action.name) !== -1) {
        return {
          ...state,
          selected: action.name,
          active: {
            ...state.active,
            function: action.name,
          },
        };
      }

      if (ToolNames.Select === action.name) {
        return {
          ...state,
          selected: action.name,
          active: {
            ...state.active,
            function: undefined,
          },
        };
      }

      return {
        ...state,
        selected: action.name,
        active: {
          ...state.active,
          function: undefined,
        },
      };
    case UPDATE_DRAWING_TOOLS_SIZES: {
      const drawingTools = [
        ToolNames.Oval,
        ToolNames.Rectangle,
        ToolNames.Circle,
        ToolNames.Line,
        ToolNames.Arrow,
        ToolNames.Triangle,
        ToolNames.Pen,
        ToolNames.Marker,
        ToolNames.Eraser,
        ToolNames.Text,
      ];

      return {
        ...state,
        availableTools: {
          ...state.availableTools,
          ...drawingTools.reduce(
            (acc, tool) => ({
              ...acc,
              [tool]: {
                ...state.availableTools[tool],
                size: {
                  ...state.availableTools[tool].size,
                  value:
                    state.availableTools[tool].size.originValue * action.ratio,
                  ratio: action.ratio,
                },
              },
            }),
            {}
          ),
        },
      };
    }

    case UPDATE_DRAWING_TOOL: {
      const { name: toolName, props } = action;
      const shapeTools = [
        ToolNames.Oval,
        ToolNames.Rectangle,
        ToolNames.Circle,
        ToolNames.Line,
        ToolNames.Arrow,
        ToolNames.Triangle,
      ];
      if (
        shapeTools.indexOf(toolName) === -1 &&
        state.availableTools.pathShapes.findIndex((t) => t.id === toolName) ===
          -1
      ) {
        return {
          ...state,
          availableTools: {
            ...state.availableTools,
            [toolName]: {
              ...state.availableTools[toolName],
              ...props,
            },
          },
        };
      }

      return {
        ...state,
        availableTools: {
          ...state.availableTools,
          ...shapeTools
            .filter((tool) => tool !== 'line')
            .reduce(
              (acc, tool) => ({
                ...acc,
                [tool]: {
                  ...state.availableTools[tool],
                  ...props,
                },
              }),
              {}
            ),
          line: {
            ...state.availableTools.line,
            ...omit(props, 'fill'),
          },
          arrow: {
            ...state.availableTools.arrow,
            ...omit(props, 'fill'),
          },
          pathShapes: state.availableTools.pathShapes.map((t) => ({
            ...t,
            ...omit(props, 'fill'),
          })),
        },
      };
    }
    case SET_DEFAULTS:
      return initialState;
    case ADD_PATH_SHAPE_TOOLS: {
      const { tools } = action.payload;
      return {
        ...state,
        availableTools: {
          ...state.availableTools,
          pathShapes: [
            ...tools.map((t) => ({
              ...t,
              size: createSize(2, 10, 4),
              color: createInitialColor(),
            })),
          ],
        },
      };
    }
    case CLEAR_PATH_SHAPE_TOOLS:
      return {
        ...state,
        availableTools: {
          ...state.availableTools,
          pathShapes: [],
        },
      };
    default:
      return state;
  }
};

const getSelectedDrawingTool = (state) => {
  const { availableTools, selected } = state.board.drawingTool;

  if (availableTools[selected]) {
    return availableTools[selected];
  }

  return availableTools.pathShapes.find((t) => t.id === selected);
};

const getFormulaToolColor = (state) =>
  state.board.drawingTool.availableTools.formula.color;

const getPathShapeTools = (state) =>
  state.board.drawingTool.availableTools.pathShapes;

const getActiveTools = (state) => state.board.drawingTool.active;

export {
  getSelectedDrawingTool,
  getFormulaToolColor,
  getPathShapeTools,
  getActiveTools,
};
