import ShapeDrawingAction from './shape.drawing.action';
import numberUtil from '../../utils/number.util';
import * as pathDataUtils from '../../utils/pathData.utils';

export default class PathShapeDrawingAction extends ShapeDrawingAction {
  viewBoxWidth = null;
  viewBoxHeight = null;
  pathData = null;
  drawingData = [];

  constructor(deserialized) {
    super(deserialized);

    this.name = 'pathShape';
    if (deserialized) {
      this.viewBoxWidth = deserialized.vbw;
      this.viewBoxHeight = deserialized.vbh;
      this.setPathData(deserialized.pd);
    }
  }

  intersect(point) {
    return (
      this.startPoint.x < point.x &&
      this.endPoint.x > point.x &&
      this.startPoint.y < point.y &&
      this.endPoint.y > point.y
    );
  }

  serialize() {
    return {
      ...super.serialize(),
      vbw: numberUtil.toOneDecimal(this.viewBoxWidth),
      vbh: numberUtil.toOneDecimal(this.viewBoxHeight),
      pd: this.pathData,
    };
  }

  setPathData(pathData) {
    this.pathData = pathData;
    this.drawingData = pathDataUtils.parsePathData(pathData);
  }
}
