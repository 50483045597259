import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '../../atoms/ListItem';
import CheckIcon from '../../atoms/Icons/CheckIcon';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import sessionService from '../../../common/services/session.service';
import * as sessionsActions from '../../../common/actions/sessionsActions';
import * as groupsReducer from '../../../common/reducers/groupsReducer';

type Props = {
  open: boolean;
  onClose: () => void;
  card: any;
};

const defaultGroupName = 'default';
// Todo: change below constants when we have responsive dialogs
const listItemIcon = 20;
const listItemTextMargin = 10;
const listItemPadding = 16;

const MoveBoardDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  card,
}) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const listItemRef = useRef<HTMLLIElement | null>(null);

  const [selectedGroupId, setSelectedGroupId] = useState(defaultGroupName);
  const [listItemTextWidth, setListItemTextWidth] = useState(0);
  const groups = useSelector(groupsReducer.myGroupListSelector);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentGroupId = useMemo(
    () => (card && card.groupId ? card.groupId : defaultGroupName),
    [card]
  );

  const onEnter = useCallback(() => {
    setSelectedGroupId(currentGroupId);

    if (!listItemRef.current) return;

    const listItemTextSize =
      listItemRef.current.offsetWidth -
      (listItemIcon + listItemTextMargin + listItemPadding * 2);
    setListItemTextWidth(listItemTextSize);
  }, [setSelectedGroupId, setListItemTextWidth, currentGroupId]);

  const onSubmit = useCallback(async () => {
    const oldGroupId = card.groupId;
    const newGroupId =
      selectedGroupId === defaultGroupName ? null : selectedGroupId;

    dispatch(sessionsActions.changeSessionGroup(card.id, newGroupId));
    onClose();

    try {
      const movedSession = await sessionService.changeSessionGroup(
        card.id,
        newGroupId
      );
      if (movedSession.groupId !== newGroupId) {
        dispatch(
          sessionsActions.changeSessionGroup(card.id, movedSession.groupId)
        );
      }
    } catch (error) {
      if (error.code) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
      if (!error.code) {
        enqueueSnackbar('Failed to find the board.', {
          variant: 'error',
        });
      }

      dispatch(sessionsActions.changeSessionGroup(card.id, oldGroupId));
    }
  }, [card, selectedGroupId, onClose, dispatch, enqueueSnackbar]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      ref={dialogRef}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <DialogTitle onClose={onClose}>Move to ...</DialogTitle>
      <DialogContent>
        <List>
          <ListItem
            title="LiveBoard"
            startIcon={
              selectedGroupId === defaultGroupName ? (
                <CheckIcon
                  sx={{
                    fontSize: '1.25rem',
                  }}
                />
              ) : (
                <Box width={20} height={20} />
              )
            }
            selected={selectedGroupId === defaultGroupName}
            onSelect={() => {
              setSelectedGroupId(defaultGroupName);
            }}
          />
          {groups.map((group: any) => (
            <ListItem
              ref={listItemRef}
              key={group.id}
              title={group.name}
              width={listItemTextWidth}
              startIcon={
                selectedGroupId === group.id ? (
                  <CheckIcon
                    sx={{
                      fontSize: '1.25rem',
                    }}
                  />
                ) : (
                  <Box width={20} height={20} />
                )
              }
              selected={selectedGroupId === group.id}
              onSelect={() => {
                setSelectedGroupId(group.id);
              }}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton
          onClick={onSubmit}
          disabled={selectedGroupId === currentGroupId}
        >
          Move
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveBoardDialog;
