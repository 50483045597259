import React, { FC } from 'react';
import Box from '@mui/material/Box';
import CallControlsPanel from '../CallControlsPanel';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import IconButton from '../../../../../ui/atoms/IconButton';
import VideoOutlineIcon from '../../../../../ui/atoms/Icons/VideoOutlineIcon';
import Tooltip from '../../../../../ui/atoms/Tooltip';

type Props = {
  connected: boolean;
  onEndCall: () => void;
  onJoinCall: () => void;
  disabled: boolean;
  loading: boolean;
  tooltipPlacment: 'top' | 'left';
};

const HostCallTools: FC<React.PropsWithChildren<Props>> = ({
  connected,
  onEndCall,
  onJoinCall,
  disabled,
  loading,
  tooltipPlacment,
}) => (
  <>
    {connected && (
      <CallControlsPanel
        onEndCall={onEndCall}
        loading={loading}
        disabled={disabled}
        tooltipTitle="End call"
        tooltipPlacment={tooltipPlacment}
      />
    )}
    {!connected && (
      <Tooltip title="Rejoin Call" placement={tooltipPlacment}>
        <Box>
          <IconButton
            onClick={onJoinCall}
            variant={ButtonVariantTypes.SECONDARY}
            shape={ButtonShapeTypes.ROUNDED}
            size={ButtonSizeTypes.S}
          >
            <VideoOutlineIcon
              sx={{
                width: 18,
                height: 18,
              }}
            />
          </IconButton>
        </Box>
      </Tooltip>
    )}
  </>
);

export default HostCallTools;
