import LineBoundaryBox from '../boundaries/LineBoundaryBox';
import LineDrawingAction from '../../../../../common/drawingActions/shapes/line.drawing.action';
import ShapeDrawingLayer from './ShapeDrawingLayer';
import { withCanvasContext } from '../../../Contexts/CanvasContext';
import LeftLineResizer from '../boundaries/resizers/LeftLineResizer';
import RightLineResizer from '../boundaries/resizers/RightLineResizer';

class LineDrawingLayer extends ShapeDrawingLayer {
  createShape = (positions, props) => {
    const {
      color,
      size,
    } = props;
    const { startPoint, endPoint } = positions;
    const drawingAction = new LineDrawingAction();
    drawingAction.paint.color = color;
    drawingAction.paint.strokeWidth = size;
    drawingAction.localStartTime = new Date();
    drawingAction.setStartPoint(startPoint);
    drawingAction.setEndPoint(endPoint);
    return drawingAction;
  };

  createBoundaries = (positions, props) => {
    const { size, scale, zoom } = props;
    const { startPoint, endPoint } = positions;

    const padding = (20 / scale) + (size / 2);
    const params = [startPoint, endPoint, scale * zoom, padding];

    const shapes = [
      new LineBoundaryBox(...params),
      new LeftLineResizer(...params),
      new RightLineResizer(...params),
    ];

    return shapes;
  };

  normalizePoints = (startPoint, endPoint) => {
    if (startPoint.x > endPoint.x) {
      return {
        startPoint: endPoint,
        endPoint: startPoint,
      };
    }
    return { startPoint, endPoint };
  };
}

export default withCanvasContext(LineDrawingLayer);
