import { createSelector } from 'reselect';
import {
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,

  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,

  CREATE_CONTACT_REQUEST,
  // CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,

  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
} from '../actions/contactsActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  hash: {},
  order: [],
  totalCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_LIST_REQUEST:
    case GET_CONTACT_REQUEST:
    case CREATE_CONTACT_REQUEST:
    case DELETE_CONTACT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_CONTACT_LIST_SUCCESS: {
      const { data: newContacts, totalCount } = action.payload;
      const newContactIds = newContacts.map(c => c.id);
      return {
        ...state,
        hash: {
          ...state.hash,
          ...newContacts
            .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
        },
        order: [
          ...state.order.filter(id => !newContactIds.includes(id)),
          ...newContactIds,
        ],
        totalCount,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_CONTACT_SUCCESS: {
      const { payload: contact } = action;
      return {
        ...state,
        hash: {
          ...state.hash,
          [contact.id]: contact,
        },
        isFetching: false,
      };
    }
    case DELETE_CONTACT_SUCCESS: {
      const { id: deletedContactId } = action;
      return {
        ...state,
        hash: Object.keys(state.hash)
          .filter(id => id !== deletedContactId)
          .reduce((acc, curr) => ({ ...acc, [curr]: state.hash[curr] }), {}),
        order: state.order.filter(id => id !== deletedContactId),
        isFetching: false,
      };
    }
    case GET_CONTACT_LIST_FAILURE:
    case GET_CONTACT_FAILURE:
    case CREATE_CONTACT_FAILURE:
    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

const contactsSelector = state => state.contacts.hash;
const contactsOrderSelector = state => state.contacts.order;
const isFetchedSelector = state => state.contacts.isFetched;
const contactSelector = (state, id) => state.contacts.hash[id];
const contactListSelector = createSelector(
  [contactsSelector, contactsOrderSelector],
  (contacts, order) => order.map(id => contacts[id]),
);
const contactsWithNamesSelector = createSelector(
  [contactsSelector, contactsOrderSelector],
  (contacts, order) => order.map(id => contacts[id]).filter(contact => contact.name && contact.name !== ''),
);
const contactsByIdsSelector = (state, ids) => ids.map(id => contactSelector(state, id))
  .filter(contact => !!contact);

export {
  contactSelector,
  contactListSelector,
  contactsWithNamesSelector,
  contactsByIdsSelector,
  isFetchedSelector,
  contactsSelector,
};
