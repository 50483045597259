import { StepType } from '@reactour/tour';

type Side = 'top' | 'right' | 'bottom' | 'left';
type OppositeSideMap = {
  [key in Side]: Side;
};

const oppositeSide: OppositeSideMap = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

const doArrow = (
  position: Side | 'custom',
  verticalAlign: string,
  horizontalAlign: Side
) => {
  if (!position || position === 'custom') {
    return {};
  }

  const width = 20;
  const height = 12;
  const padding = 10;
  const color = 'white';
  const isVertical = position === 'top' || position === 'bottom';

  const triangleWidth = `${width / 2}px solid transparent`;
  const triangleHeight = `${height}px solid ${color}`;
  const positionCapitalized = position[0].toUpperCase() + position.substring(1);
  const borderStyleName = `border${positionCapitalized}`;
  const spaceFromSide = height + padding;

  const styles = {
    [isVertical ? 'borderLeft' : 'borderTop']: triangleWidth,
    [isVertical ? 'borderRight' : 'borderBottom']: triangleWidth,
    [borderStyleName]: triangleHeight,
    [isVertical ? oppositeSide[horizontalAlign] : verticalAlign]: spaceFromSide,
    [oppositeSide[position]]: -height + 2,
  };

  return {
    '&::after': {
      content: "''",
      width: 0,
      height: 0,
      position: 'absolute',
      ...styles,
    },
  };
};

const generalStyles: StepType['styles'] = {
  controls: (baseStyles) => ({
    ...baseStyles,
    justifyContent: 'flex-end',
  }),
  maskWrapper: (baseStyles) => ({
    ...baseStyles,
    opacity: 0.5,
  }),
  popover: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: 14,
    maxWidth: '426px',
    padding: '24px',
    ...doArrow(state?.position, state?.verticalAlign, state?.horizontalAlign),
  }),
};

const createProductTourStyles = (styleOverrides: StepType['styles']) => ({
  ...generalStyles,
  ...styleOverrides,
});

export default createProductTourStyles;
