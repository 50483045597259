import React from 'react';
import Radio from '@mui/material/Radio';
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import AnswerOptionDto from '../dto/AnswerOptionDto';
import FirebaseOptionDto from '../dto/FirebaseOptionDto';

type Props = {
  options: any[],
  setSelectedValue: (value: AnswerOptionDto[]) => void,
};

const SingleChoiceAnswer = ({
  options,
  setSelectedValue,
}: Props) => {
  const handleChange = (event: any) => {
    setSelectedValue([{ optionId: event.target.value }]);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup>
        {
          options.map((option: FirebaseOptionDto) => (
            <FormControlLabel
              color="primary"
              key={option.id}
              value={option.id}
              control={<Radio color="primary" />}
              label={option.text}
              onChange={handleChange}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  );
};

export default SingleChoiceAnswer;
