import { FC } from 'react';
import { styled } from '@mui/material/styles';
import MuiDialogActions, {
  DialogActionsProps,
} from '@mui/material/DialogActions';
import { ButtonProps } from '@mui/material/Button';
import Button from '../../atoms/Button';
import { ButtonSizeTypes, ButtonVariantTypes } from '../Button/buttonTypes';

const DialogActions = styled(MuiDialogActions)<DialogActionsProps>({
  display: 'flex',
  gap: '10px',
  padding: '0px 24px',
});

type DialogActionButtonProps = {
  color?: 'primary' | 'premium' | 'error' | 'neutral';
  loading?: boolean;
  loadingPosition?: 'start' | 'end' | 'center';
};

export type DialogActionProps = ButtonProps & DialogActionButtonProps;

const DialogActionPrimaryButton: FC<
  React.PropsWithChildren<DialogActionProps>
> = ({ color, children, ...props }) => (
  <Button
    {...props}
    size={ButtonSizeTypes.S}
    variant={ButtonVariantTypes.PRIMARY}
    color={color}
  >
    {children}
  </Button>
);

const DialogActionSecondaryButton: FC<
  React.PropsWithChildren<DialogActionProps>
> = ({ color, children, variant = ButtonVariantTypes.TEXT, ...props }) => (
  <Button {...props} size={ButtonSizeTypes.S} variant={variant} color={color}>
    {children}
  </Button>
);

export default DialogActions;
export { DialogActionPrimaryButton, DialogActionSecondaryButton };
