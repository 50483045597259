import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { orderBy } from 'lodash';
import messagesReducer, { messagesSelector } from './messagesReducer';
import participantsReducer, * as participantsSelectors from './participantsReducer';
import invites, { invitedContactsSelector } from './invitesReducer';
import reducers, * as sessionReducer from './sessionReducer';
import * as plansPermissionsSelectors from '../../../common/selectors/planPermissionSelectors';

export default combineReducers({
  ...reducers,
  messages: messagesReducer,
  participants: participantsReducer,
  invites,
});

const participantsSelector = createSelector(
  [
    sessionReducer.ownerIdSelector,
    sessionReducer.isCurrentUserHostSelector,
    participantsSelectors.participantsSelector,
    plansPermissionsSelectors.canManageDrawingAccessSelector,
  ],
  (ownerId, isCurrentUserHost, participants, canControlDrawingAccess) => {
    const reshapedParticipants = participants.map((p) => ({
      ...p,
      isOwner: p.id === ownerId,
      canManageDrawingPermissions: isCurrentUserHost && canControlDrawingAccess,
    }));

    return orderBy(reshapedParticipants, ['isOwner', 'name'], ['desc', 'asc']);
  }
);

const createSessionSelector = () =>
  createSelector(
    [
      sessionReducer.sessionIdSelector,
      sessionReducer.nameSelector,
      sessionReducer.ownerIdSelector,
      sessionReducer.ownerSelector,
      sessionReducer.sharedSelector,
      sessionReducer.finishedSelector,
      sessionReducer.dimensionsSelector,
      sessionReducer.connectedSelector,
      sessionReducer.codeSelector,
      sessionReducer.startTimeSelector,
      sessionReducer.endTimeSelector,
      sessionReducer.isConferenceInProgressSelector,
      sessionReducer.conferenceProviderSelector,
      sessionReducer.chatSelector,
      sessionReducer.drawingEnabledOnJoinSelector,
      sessionReducer.mutedOnJoinSelector,
      sessionReducer.trackPresenceSelector,
      sessionReducer.groupIdSelector,
      sessionReducer.publicLinkSelector,
      sessionReducer.callEnabledSelector,
      sessionReducer.localRecordingEnabledSelector,
      sessionReducer.questionEnabledSelector,
      sessionReducer.configurationSelector,
      sessionReducer.boardTypeSelector,
      sessionReducer.boardOpenSourceSelector,
      sessionReducer.canvasTouchCountSelector,
      messagesSelector,
      participantsSelector,
      invitedContactsSelector,
    ],
    (
      id,
      name,
      ownerId,
      owner,
      shared,
      finished,
      dimensions,
      connected,
      code,
      startTime,
      endTime,
      isConferenceInProgress,
      conferenceProvider,
      chat,
      drawingEnabledOnJoin,
      mutedOnJoin,
      trackPresence,
      groupId,
      publicLink,
      callEnabled,
      localRecordingEnabled,
      questionEnabled,
      configuration,
      boardType,
      boardOpenSource,
      canvasTouchCount,
      messages,
      participants,
      contacts
    ) => ({
      id,
      name,
      ownerId,
      owner,
      shared,
      finished,
      dimensions,
      connected,
      code,
      startTime,
      endTime,
      isConferenceInProgress,
      conferenceProvider,
      chat,
      drawingEnabledOnJoin,
      mutedOnJoin,
      trackPresence,
      groupId,
      publicLink,
      callEnabled,
      localRecordingEnabled,
      questionEnabled,
      configuration,
      boardType,
      boardOpenSource,
      canvasTouchCount,
      messages,
      participants,
      contacts,
    })
  );

const createSessionCutSelector = () =>
  createSelector(
    [
      sessionReducer.sessionIdSelector,
      sessionReducer.connectedSelector,
      sessionReducer.sharedSelector,
      sessionReducer.chatSelector,
      sessionReducer.boardTypeSelector,
      sessionReducer.finishedSelector,
      sessionReducer.trackPresenceSelector,
    ],
    (id, connected, shared, chat, boardType, finished, trackPresence) => ({
      id,
      connected,
      shared,
      chat,
      boardType,
      finished,
      trackPresence,
    })
  );

const isSessionLiveSelector = createSelector(
  [sessionReducer.sharedSelector, sessionReducer.finishedSelector],
  (shared, finished) => shared && !finished
);

export {
  createSessionSelector,
  createSessionCutSelector,
  participantsSelector,
  isSessionLiveSelector,
};
