import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Loader from '../../Icons/Loader';

const getFirstLetterUpperCase = (str) =>
  str ? str.charAt(0).toUpperCase() : '';

const LetterAvatar = ({ letter, style }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#793de6',
      border: '1px solid rgba(0,0,0,.23)',
      ...style,
    }}
  >
    <span>{letter}</span>
  </Box>
);

// ToDo: Replace this component usage with UI system Avatar
const ProfileAvatar = ({ width, height, src, alt, onClick }) => (
  <Box
    sx={{
      width,
      height,
      border: '1px solid rgba(0,0,0,.23)',
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img
      src={src}
      alt={alt}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  </Box>
);

const ImageLoading = ({ loadingStyles, width, height }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width,
      height,
    }}
  >
    <Loader {...loadingStyles} />
  </Box>
);

const ProfileImage = ({ src, alt, width, height, onClick, loading }) => {
  const loadingStyles = {
    size: width / 2,
  };

  const letterStyle = {
    fontSize: width / 2,
    width,
    height,
  };

  return loading ? (
    <ImageLoading width={width} height={height} loadingStyles={loadingStyles} />
  ) : (
    <div>
      {src ? (
        <ProfileAvatar
          width={width}
          height={height}
          src={src}
          alt={alt}
          onClick={onClick}
        />
      ) : (
        <LetterAvatar
          width={width}
          height={height}
          style={letterStyle}
          letter={getFirstLetterUpperCase(alt)}
        />
      )}
    </div>
  );
};

ProfileImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

ProfileImage.defaultProps = {
  width: 36,
  height: 36,
  src: '',
};

export default ProfileImage;
