import { AvatarProps } from '@mui/material/Avatar';
import { Modify } from '../../../../common/types/ModifyType';

export enum AvatarSizeTypes {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
}

export enum AvatarBorderTypes {
  NONE = 'none',
  DEFAULT = 'default',
  ACTIVE = 'active',
}

export type AvatarCustomProps = Modify<
  AvatarProps,
  {
    size: AvatarSizeTypes;
    border?: AvatarBorderTypes;
    color?: string;
  }
>;
