import React from 'react';
import { connect } from 'react-redux';
import { last, has } from 'lodash';
import Canvas from '../../board/Canvas';
import { firstPageSelector } from '../../../common/reducers/board/pagesReducer';
import * as drawingActionsReducer from '../../../common/reducers/board/drawingActionsReducer';
import { shouldUpdateThumbnailSelector } from '../../../common/reducers/board/shouldUpdateThumbnail';
import { setShouldUpdateThumbnail } from '../../../common/actions/board/shouldUpdateThumbnailActions';
import { updateSessionInList } from '../../../common/actions/sessionsActions';
import { createSessionSelector } from '../../../common/reducers/session';
import sessionService from '../../../common/services/session.service';
import CanvasContext from '../../board/Contexts/CanvasContext';

const PAGE_WIDTH = 640;
const PAGE_HEIGHT = 640;

const makeMapStateToProps = () => {
  const sessionSelector = createSessionSelector();
  const actionsSelector = drawingActionsReducer.createActionsSelector();

  return (state) => {
    const page = firstPageSelector(state);
    const session = sessionSelector(state);

    return {
      session: session,
      actions: has(page, 'id') ? actionsSelector(state, page.id) : [],
      page,
      shouldUpdateThumbnail: shouldUpdateThumbnailSelector(state),
    };
  };
};
const enhancer = connect(
  makeMapStateToProps,
  {
    setShouldUpdateThumbnail,
    updateSessionInList,
  },
  null,
  { forwardRef: true }
);

class ThumbnailSaver extends React.Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.shouldUpdateThumbnail && this.props.shouldUpdateThumbnail) {
      this.saveThumbnail();
    }
  }

  getLastAction = () => {
    const { actions, page } = this.props;

    return last(actions.filter((a) => a.pageNumber === page.id));
  };

  canvasRef = React.createRef();
  lastSavedAction = this.getLastAction();

  saveThumbnail = async () => {
    const { session } = this.props;
    const lastAction = this.getLastAction();

    if (
      !lastAction ||
      (this.lastSavedAction && this.lastSavedAction.id === lastAction.id)
    ) {
      this.props.setShouldUpdateThumbnail(false);
      return;
    }

    const blob = await this.canvasRef.current.getThumbnail();

    sessionService
      .uploadSessionThumbnail(session.id, blob)
      .then((updatedSession) => this.props.updateSessionInList(updatedSession));

    this.props.setShouldUpdateThumbnail(false);
    this.lastSavedAction = lastAction;
  };

  render() {
    const { session, actions, page } = this.props;
    const { dimensions } = session;
    const scale = Math.min(
      PAGE_WIDTH / dimensions.width,
      PAGE_HEIGHT / dimensions.height
    );
    const width = dimensions.width * scale;
    const height = dimensions.height * scale;
    return (
      <div
        style={{
          position: 'absolute',
          top: -9999,
          left: -9999,
        }}
      >
        <CanvasContext.Provider
          value={{
            boardWidth: dimensions.width,
            boardHeight: dimensions.height,
            boardDpi: dimensions.dpi,
            canvasWidth: width,
            canvasHeight: height,
            scale,
            zoom: 1,
            scaledBoardWidth: width,
            scaledBoardHeight: height,
            scaledBoardOriginX: 0,
            scaledBoardOriginY: 0,
            originX: 0,
            originY: 0,
          }}
        >
          <Canvas
            width={width}
            height={height}
            dimensions={dimensions}
            actions={actions}
            page={page}
            ref={this.canvasRef}
          />
        </CanvasContext.Provider>
      </div>
    );
  }
}

export default enhancer(ThumbnailSaver);
