import { FC, useContext, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import { paperClasses } from '@mui/material/Paper';
import MicrophoneOutlineIcon from '../../ui/atoms/Icons/MicrophoneOutlineIcon';
import Typography from '../../ui/atoms/Typography';
import DeviceContext from '../BoardControls/CollaborationTools/DeviceContext/DeviceContext';
import SelectItem from '../../ui/atoms/SelectItem';
import ChevronDownIcon from '../../ui/atoms/Icons/ChevronDownIcon';
import useDialog from '../../common/hooks/useDialog';
import { noop } from '../../common/constants';

type AudioSelectProps = {
  option: string;
  onChangeOption: (event: SelectChangeEvent) => void;
};

const AudioSelect: FC<React.PropsWithChildren<AudioSelectProps>> = ({
  option,
  onChangeOption,
}) => {
  const { audioSources, listAudioDevices } = useContext(DeviceContext);
  const {
    open: selectOpen,
    onOpen: onSelectOpen,
    onClose: onSelectClose,
  } = useDialog();
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', listAudioDevices);

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        listAudioDevices
      );
    };
  }, [listAudioDevices]);

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center" mb={0.5}>
        <MicrophoneOutlineIcon
          sx={{
            fontSize: '16px',
          }}
        />
        <Typography variant="p5">Microphone</Typography>
      </Box>
      <FormControl fullWidth disabled={!option}>
        <Select
          ref={selectRef}
          value={option}
          onChange={onChangeOption}
          displayEmpty
          fullWidth
          IconComponent={ChevronDownIcon}
          onOpen={onSelectOpen}
          onClose={onSelectClose}
          MenuProps={{
            sx: {
              [`& .${paperClasses.root}`]: {
                padding: '8px',
                borderRadius: '0px 0px 18px 18px',
                ...(selectRef?.current
                  ? {
                      width: selectRef?.current?.offsetWidth,
                    }
                  : {}),
              },
            },
          }}
          sx={(theme) => ({
            borderRadius: selectOpen ? '20px 20px 0px 0px' : '28px',
            backgroundColor: theme.background.bg4,
            border: 'none',
            [`.${selectClasses.select}`]: {
              ...theme.typography.p4,
              color: theme.text.t6,
            },
            ':hover': {
              backgroundColor: theme.background.bg5,
            },
            [`& .${selectClasses.icon}`]: {
              fontSize: '18px',
            },
            [`& .${selectClasses.select}`]: {
              padding: '8px 16px',
            },
          })}
        >
          {audioSources.map((source) => (
            <SelectItem
              key={source.deviceId}
              value={source.deviceId}
              isSelected={source.deviceId === option}
              onClick={noop}
            >
              {source.label}
            </SelectItem>
          ))}
        </Select>
      </FormControl>
      {!option && (
        <Typography variant="p5" color="error">
          Enable access from browser settings
        </Typography>
      )}
    </Box>
  );
};

export default AudioSelect;
