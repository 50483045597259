import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCountdown from '../common/hooks/useCountdown';
import { createSessionSelector } from '../common/reducers/session';
import ParticipantDto from '../common/services/dto/firebase/ParticipantDto';
import RecordingContext from './Recording/RecordingContext';
import CanvasContext from './board/Contexts/CanvasContext';
import { getPageCount } from '../common/reducers/board/pagesReducer';
import analyticsService from '../common/services/analytics.service';
import {
  conferenceProviderSelector,
  isCurrentUserHostSelector,
} from '../common/reducers/session/sessionReducer';
import { invitedGroupIdsSelector } from '../common/reducers/groupsReducer';
import { getActivePageId } from '../common/reducers/board/activePageReducer';
import { resetCanvasTouch } from '../common/actions/session/sessionActions';
import Board from './Board';
import BoardType from '../common/services/types/session/BoardType';

const timerTimeInMilliseconds = 3 * 60 * 1000;
const timerEventThresholdInMilliseconds = 20 * 1000;

const BoardEventTimer: FC<React.PropsWithChildren<unknown>> = () => {
  const [openSent, setOpenSent] = useState(false);

  const dispatch = useDispatch();

  const session = useSelector(createSessionSelector());
  const recording = useContext(RecordingContext);
  const canvas = useContext(CanvasContext);
  const isHost = useSelector(isCurrentUserHostSelector);
  const pageCount = useSelector(getPageCount);
  const currentPage = useSelector(getActivePageId);
  const invitedGroupIds = useSelector(invitedGroupIdsSelector);
  const conferenceProvider = useSelector(conferenceProviderSelector);

  const sharedStatus = useMemo(() => {
    if (session.finished) return 'Closed';

    if (session.shared) return 'Shared';

    return 'Not shared';
  }, [session]);

  useEffect(() => {
    if (session.connected && !openSent) {
      analyticsService.event('Board Opened', {
        sessionId: session.id,
        own: isHost,
        sharedStatus,
        source: session.boardOpenSource,
        inGroup: invitedGroupIds.includes(session.groupId),
        type: session.boardType === BoardType.INFINITE ? 'Infinite' : 'Basic',
        pageNumber: currentPage,
        pageCount,
        participantsCount: session.participants.filter(
          (participant: ParticipantDto) => participant.status !== 0
        ).length,
      });
      setOpenSent(true);
    }
  }, [
    isHost,
    sharedStatus,
    openSent,
    setOpenSent,
    invitedGroupIds,
    currentPage,
    pageCount,
    session,
  ]);

  const timerCallback = useCallback(
    (timeElapsed: any) => {
      if (timeElapsed < timerEventThresholdInMilliseconds) return;

      analyticsService.event('Board Activity', {
        sessionId: session.id,
        own: isHost,
        sharedStatus,
        inGroup: invitedGroupIds.includes(session.groupId),
        type: session.boardType === BoardType.INFINITE ? 'Infinite' : 'Basic',
        pageNumber: currentPage,
        pageCount,
        participantsCount: session.participants.filter(
          (participant: ParticipantDto) => participant.status !== 0
        ).length,
        touchesCount: session.canvasTouchCount,
        duration: timeElapsed / 1000,
        isRecording: recording.isRecording,
        inCall: session.isConferenceInProgress,
        callProvider: conferenceProvider,
        zoomLevel: canvas.zoom,
      });

      dispatch(resetCanvasTouch());
    },
    [
      isHost,
      sharedStatus,
      invitedGroupIds,
      currentPage,
      pageCount,
      session,
      recording,
      canvas,
      dispatch,
      conferenceProvider,
    ]
  );

  const [timeLeft, { start, pause, resume, complete }] = useCountdown(
    timerCallback,
    timerTimeInMilliseconds,
    25
  );

  useEffect(() => {
    start();

    if (!document.hasFocus()) {
      pause();
    }
    return () => complete();
  }, [start, pause, complete]);

  useEffect(() => {
    window.addEventListener('focus', resume);
    window.addEventListener('blur', pause);

    return () => {
      window.removeEventListener('focus', resume);
      window.removeEventListener('blur', pause);
    };
  }, [resume, pause]);

  useEffect(() => {
    if (timeLeft <= 0) {
      start();
    }
  }, [timeLeft, start]);

  return <Board />;
};

export default BoardEventTimer;
