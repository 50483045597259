import React, { FC, MutableRefObject, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import { Box } from '@mui/material';
import { imageTourSelector } from '../../../common/reducers/featureToursReducer';
import { useInputOnChange } from '../../ImageSelectionProvider/useInputOnChange';
import keyMap from '../../../config/hotkeys';
import UnleashFlags from '../../../common/constants/UnleashFlag';
import DrawingButton from '../DrawingButton/DrawingButton';
import { SelectedTool } from './types/SelectToolType';
import ToolNames from '../ToolNames';
import ImageIcon from '../../../ui/atoms/Icons/ImageAddIcon';

type ImageToolProps = {
  reference: MutableRefObject<HTMLInputElement | null>;
  selectedTool: SelectedTool;
  updateTool: (name: string, props: { [key: string]: any }) => void;
  selectTool: (name: string) => void;
  onboardingChange: () => void;
};

const ImageTool: FC<React.PropsWithChildren<ImageToolProps>> = ({
  reference,
  selectedTool,
  updateTool,
  selectTool,
  onboardingChange,
}) => {
  const imageTourData = useSelector(imageTourSelector);
  const imageRotationEnabled = useFlag(UnleashFlags.IMAGE_ROTATION);
  const { inputOnChange } = useInputOnChange();

  const onChange = useCallback(
    async (e: any) => {
      await inputOnChange(e);
      if (!reference.current) return;
      //@ts-ignore
      reference.current.value = null;
    },
    [inputOnChange]
  );

  const isActive = useMemo(
    () => selectedTool.name === ToolNames.Image,
    [selectedTool]
  );
  const onSelectImageTool = useCallback(() => {
    if (
      !isActive &&
      reference.current &&
      (!imageRotationEnabled || !imageTourData || !imageTourData.hasUpdates)
    ) {
      reference.current.click();
      return;
    }

    onboardingChange();
  }, [
    imageRotationEnabled,
    imageTourData,
    selectedTool,
    selectTool,
    updateTool,
    isActive,
  ]);

  return (
    <Box>
      <input
        id="file-upload"
        style={{
          display: 'none',
        }}
        type="file"
        accept="image/*"
        onChange={onChange}
        ref={reference}
      />
    </Box>
  );
};

export default ImageTool;
