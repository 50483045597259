export const getFilteredInvitedParticipants = (
  joinedParticipants: any[],
  invitedParticipantsEmails: any[],
  invitedContactIds: any[]
) => {
  const joinedParticipantsEmails = joinedParticipants.map(
    (joinedParticipant: any) => joinedParticipant.email
  );
  const joinedParticipantsIds = joinedParticipants.map(
    (joinedParticipant: any) => joinedParticipant.id
  );

  const filteredContacts = invitedContactIds.filter(
    (id: string) => !joinedParticipantsIds.includes(id)
  );
  const filteredEmails = invitedParticipantsEmails.filter(
    (email: string) => !joinedParticipantsEmails.includes(email)
  );

  return {
    contacts: filteredContacts,
    emails: filteredEmails,
  };
};
