import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CardActionArea from '@mui/material/CardActionArea';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import Box from '@mui/material/Box';
import Menu from '../Menu';
import MenuItem from '../../atoms/MenuItem';
import CardThumbnail from '../CardThumbnail';
import Card from '../Card';
import CardInfo from '../CardInfo';
import VideoOutlineIcon from '../../atoms/Icons/VideoOutlineIcon';
import AccountPlusOutlineSvgIcon from '../../atoms/Icons/AccountPlusOutlineIcon';
import DotsVerticalSvgIcon from '../../atoms/Icons/DotsVerticalIcon';
import { CardType } from '../CardThumbnail/cardThumbnailTypes';
import recordingService from '../../../common/services/recording.service';
import DownloadOutlineIcon from '../../atoms/Icons/DownloadOutlineIcon';
import useDialog from '../../../common/hooks/useDialog';
import PlanDialog from '../PlanDialog';
import { canMoveRecordingEnabledSelector } from '../../../common/reducers/configurationReducer';
import AccountRemoveOutlineIcon from '../../atoms/Icons/AccountRemoveOutlineIcon';
import useCardAvatarImage from '../../../common/hooks/useCardAvatarImage';
import FileMoveOutlineIcon from '../../atoms/Icons/FileMoveOutlineIcon';
import {
  changeRecordingInGroupRequest,
  copyRecordingInList,
} from '../../../common/actions/recordingsActions';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  id: string;
  ownerId: string;
  groupId: string;
  altText: string;
  src: string;
  recordingName: string;
  recordingOwnerName: string;
  recordingSubtitle: string;
  date: string;
  fallbackSrc: string;
  onRename: (recordingId: string) => void;
  onMove: () => void;
  onDelete: (recordingId: string) => void;
  onCardClick: () => void;
  onShareWithUsers: () => void;
  isOwner: any;
  onCopyLimitDialogOpen: () => void;
  shared: boolean;
};

const downloadFromUrl = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  // @ts-ignore
  // Todo: Fix this issue
  a.style = 'display: none';
  a.click();
  a.remove();
};

const RecordingCard: FC<React.PropsWithChildren<Props>> = ({
  id,
  ownerId,
  groupId,
  altText,
  shared,
  src,
  recordingName,
  recordingOwnerName,
  recordingSubtitle,
  date,
  fallbackSrc,
  isOwner,
  onRename,
  onMove,
  onDelete,
  onCardClick,
  onShareWithUsers,
  onCopyLimitDialogOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const contextMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const canMoveRecording = useSelector(canMoveRecordingEnabledSelector);

  const [imageSrc, setImageSrc] = useState('');
  const onVisibilityChange = useCallback(
    (inView: any) => {
      if (inView) {
        setImageSrc(src);
      }
    },
    [setImageSrc, src]
  );

  const avatarImg = useCardAvatarImage(ownerId);

  const { ref } = useInView({
    root: null,
    triggerOnce: true,
    rootMargin: '200px 0px',
    onChange: onVisibilityChange,
  });

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const handleContextMenuOpen = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleContextMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const download = useCallback(async () => {
    const url = await recordingService.getRecordingUrl(id);
    downloadFromUrl(url);
    handleContextMenuClose();
  }, [id, handleContextMenuClose]);

  const generateCardActions = useCallback(
    (isHost: any) =>
      isHost
        ? [
            {
              Icon: DotsVerticalSvgIcon,
              onClick: (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                handleContextMenuOpen(event);
              },
            },
          ]
        : [],
    [handleContextMenuOpen]
  );

  const onDeleteClick = useCallback(() => {
    onDelete(id);
    handleContextMenuClose();
  }, [id, onDelete, handleContextMenuClose]);

  const onCopy = useCallback(async () => {
    handleContextMenuClose();

    dispatch(copyRecordingInList(id, onCopyLimitDialogOpen));
  }, [id, dispatch, onCopyLimitDialogOpen, handleContextMenuClose]);

  const onMoveClick = useCallback(() => {
    if (canMoveRecording) {
      onMove();
      handleContextMenuClose();

      return;
    }

    onPlansDialogOpen();
    handleContextMenuClose();
  }, [canMoveRecording, onPlansDialogOpen, onMove, handleContextMenuClose]);

  const onRenameClick = useCallback(() => {
    onRename(id);
    handleContextMenuClose();
  }, [id, onRename, handleContextMenuClose]);

  const onShareRecordingInGroup = useCallback(async () => {
    await recordingService.shareInGroup(id, !shared);
    dispatch(changeRecordingInGroupRequest(id, !shared));
    handleContextMenuClose();
  }, [dispatch, handleContextMenuClose, shared]);

  return (
    <CardGridItem ref={ref}>
      <Card>
        <CardActionArea component={Box} disableRipple onClick={onCardClick}>
          <CardThumbnail
            type={CardType.RECORDING}
            altText={altText}
            src={imageSrc}
            fallbackSrc={fallbackSrc}
          />
        </CardActionArea>
        <CardInfo
          title={recordingName}
          subtitle={isOwner ? 'My recording' : recordingOwnerName}
          subtitleExtra={recordingSubtitle}
          date={date}
          CardIcon={VideoOutlineIcon}
          cardActionsData={generateCardActions(isOwner)}
          avatarImg={avatarImg}
          hasAvatar={!isOwner}
        />
      </Card>
      <Menu
        anchorEl={anchorEl}
        open={contextMenuOpen}
        onClose={handleContextMenuClose}
        listItemWidth={164}
      >
        {!groupId && (
          <MenuItem
            title="Share recording"
            onSelect={() => {
              onShareWithUsers();
              handleContextMenuClose();
            }}
            startIcon={<AccountPlusOutlineSvgIcon />}
          />
        )}
        {groupId && (
          <MenuItem
            title={shared ? 'Stop sharing' : 'Share with group'}
            onSelect={onShareRecordingInGroup}
            startIcon={
              shared ? (
                <AccountRemoveOutlineIcon />
              ) : (
                <AccountPlusOutlineSvgIcon />
              )
            }
          />
        )}
        <MenuItem
          title="Rename"
          onSelect={onRenameClick}
          startIcon={<EditIcon />}
        />
        <MenuItem
          title="Duplicate"
          onSelect={onCopy}
          startIcon={<CopyIcon />}
        />
        <MenuItem
          title="Move"
          onSelect={onMoveClick}
          startIcon={<FileMoveOutlineIcon />}
        />
        <MenuItem
          title="Download"
          startIcon={<DownloadOutlineIcon />}
          onSelect={download}
        />
        <MenuItem
          title="Delete"
          onSelect={onDeleteClick}
          startIcon={<DeleteIcon />}
        />
      </Menu>
      <PlanDialog
        source="Move Group"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </CardGridItem>
  );
};

export default RecordingCard;
