import { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Dialog from '../../../ui/atoms/Dialog';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogActions, {
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import blobUtils from '../../../common/utils/blob.utils';
import fireBaseService from '../../../common/services/firebaseService';
import sessionService from '../../../common/services/session.service';
import * as sessionReducer from '../../../common/reducers/session/sessionReducer';
import * as activePageReducer from '../../../common/reducers/board/activePageReducer';
import { getPageConfigs } from './pageConfigCalculator';
import { getPageCount } from '../../../common/reducers/board/pagesReducer';
import analyticsService from '../../../common/services/analytics.service';
import ImageDrawingAction from '../../../common/drawingActions/image.drawing.action';
import { boardTypeSelector } from '../../../common/reducers/session/sessionReducer';
import BoardType from '../../../common/services/types/session/BoardType';
import CanvasContext from '../../../components/board/Contexts/CanvasContext';
import * as drawingActionsActions from '../../../common/actions/board/drawingActionsActions';
import imageUtils from '../../../common/utils/image.utils';
import Typography from '../../../ui/atoms/Typography';
import UploadPdfIcon from '../../../ui/atoms/Icons/UploadPdfIcon';
import InformationOutlineIcon from '../../../ui/atoms/Icons/InformationOutlineIcon';

const enhancer = connect((state) => ({
  sessionId: sessionReducer.sessionIdSelector(state),
  activePageId: activePageReducer.getActivePageId(state),
}));

const pageMargin = 100;

const calculatePdfPagePosition = (index, rowCount, dimensions) => ({
  x: (index % rowCount) * (dimensions.width + pageMargin),
  y: Math.floor(index / rowCount) * (dimensions.height + pageMargin),
});

const IconWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '32px',
  width: '40px',
  height: '40px',
  backgroundColor: theme.palette.primary.alpha10,
  padding: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Error = () => (
  <Box display="flex" gap={2}>
    <Box
      width={40}
      height={40}
      borderRadius={8}
      padding={1}
      sx={(theme) => ({
        backgroundColor: theme.palette.error.alpha10,
      })}
    >
      <InformationOutlineIcon color="error" />
    </Box>
    <Box display="flex" flexDirection="column">
      <Typography variant="p5">
        Error occurred while processing the PDF document.
      </Typography>
      <Typography variant="p5">
        Please try again with another document!
      </Typography>
    </Box>
  </Box>
);

const ImportPdfToBoardDialog = ({
  open,
  pages,
  onClose,
  handleSuccess,
  sessionId,
  activePageId,
}) => {
  const [processedCount, setProcessedCount] = useState(0);
  const [error, setError] = useState(null);
  const pageCount = useSelector(getPageCount);
  const boardType = useSelector(boardTypeSelector);
  const canvasContext = useContext(CanvasContext);
  const { viewportCenterPosition } = canvasContext;
  const dispatch = useDispatch();

  const importImagePages = useCallback(
    async (importData, activePageId, boardType) => {
      if (boardType !== BoardType.INFINITE) {
        await fireBaseService.importImagePages(importData, activePageId);
        analyticsService.event('PDF Pages Imported', {
          sessionId,
          pageCount: pageCount + pages.length,
          pdfPagesCount: pages.length,
        });

        return;
      }

      const rowCount = Math.ceil(Math.sqrt(importData.length));
      const pdfImages = importData.map((page) => page.image);

      const maxWidth = pdfImages.reduce(
        (maxValue, action) => Math.max(maxValue, action.width),
        0
      );
      const maxHeight = pdfImages.reduce(
        (maxValue, action) => Math.max(maxValue, action.height),
        0
      );

      await importData.map(async (currentPage, index) => {
        const pagePosition = calculatePdfPagePosition(index, rowCount, {
          width: maxWidth,
          height: maxHeight,
        });
        const image = await imageUtils.toImage(currentPage.imageData);
        const action = new ImageDrawingAction();
        action.image = image;
        action.startPoint = {
          x:
            pagePosition.x +
            viewportCenterPosition.x -
            currentPage.image.width / 2,
          y:
            pagePosition.y +
            viewportCenterPosition.y -
            currentPage.image.height / 2,
        };
        action.localStartTime = new Date();
        action.width = image.width;
        action.height = image.height;

        dispatch(drawingActionsActions.addActionAndSend(action));
      });
    },
    [dispatch, viewportCenterPosition, pageCount, pages, sessionId]
  );

  useEffect(() => {
    const importPages = async (boardType) => {
      try {
        setProcessedCount(0);

        if (pages.length === 0) {
          return;
        }

        const pagesData = await Promise.all(pages);
        const importData = pagesData.map((page) => getPageConfigs(page));

        for await (const { image, imageData } of importData) {
          const blob = blobUtils.dataUriToBlob(imageData);
          image.url = await sessionService.uploadSessionImage(sessionId, blob);
          setProcessedCount((progress) => progress + 1);
        }

        await importImagePages(importData, activePageId, boardType);

        handleSuccess();
      } catch (e) {
        setError(e);
        console.log(e);
      }
    };

    if (open && pages.length) {
      importPages(boardType);
    }
  }, [pages, open, boardType, sessionId]);

  const onDialogClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') return;

      onClose();
    },
    [onClose]
  );

  return (
    <Dialog open={open} onClose={onDialogClose} disableEscapeKeyDown>
      <DialogTitle hasCloseButton={false}>Import PDF</DialogTitle>
      <DialogContent>
        {error ? (
          <Error />
        ) : (
          <>
            <Box display="flex" alignItems="center" gap={2} mb={2}>
              <IconWrapper>
                <UploadPdfIcon color="primary" />
              </IconWrapper>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexWrap="nowrap">
                  <Typography variant="b3">
                    Processed {processedCount} of {pages.length}{' '}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box pr={1} display="flex" flex={1}>
                <Box width={1}>
                  <LinearProgress
                    variant="determinate"
                    value={(processedCount * 100) / pages.length}
                    color="primary"
                    sx={{
                      borderRadius: '4px',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      {error && (
        <DialogActions>
          <DialogActionSecondaryButton onClick={onDialogClose}>
            Ok
          </DialogActionSecondaryButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default enhancer(ImportPdfToBoardDialog);
