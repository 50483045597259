import { httpServiceV4 } from './http.service';
import { OnboardingRefreshDto } from './dto/collectPayments/OnboardingRefreshDto';
import { PaymentStatusDto } from './dto/collectPayments/PaymentStatusDto';
import { PaymentLinkDto } from './dto/collectPayments/PaymentLinkDto';

const getAcceptPaymentStatus = (): Promise<PaymentStatusDto> =>
  httpServiceV4.get<PaymentStatusDto, PaymentStatusDto>(
    '/collect-payment/account'
  );

const createOnboardingUrl = (): Promise<OnboardingRefreshDto> =>
  httpServiceV4.post<OnboardingRefreshDto, OnboardingRefreshDto>(
    '/collect-payment/account/onboarding'
  );
const createAcceptPaymentUrl = ({
  name,
  amount,
}: {
  name: string;
  amount: number;
}): Promise<PaymentLinkDto> =>
  httpServiceV4.post<PaymentLinkDto, PaymentLinkDto>(
    '/collect-payment/payment-link',
    {
      name,
      amount,
    }
  );

export default {
  createOnboardingUrl,
  getAcceptPaymentStatus,
  createAcceptPaymentUrl,
};
