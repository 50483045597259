import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import sessionService from '../../../../common/services/session.service';
import { addSessionInList } from '../../../../common/actions/sessionsActions';
import { createBoard } from '../../../../common/actions/configurationActions';
import { setOpenSource } from '../../../../common/actions/session/sessionActions';
import analyticsService from '../../../../common/services/analytics.service';
import { dpiSelector } from '../../../../common/reducers/uiReducer';
import { useProductTour } from '../../../../components/ProductTour';

const groupId = 'default';

export const useBoard = () => {
  const dispatch = useDispatch();
  const dpi = useSelector(dpiSelector);
  const location = useLocation();
  const productTour = useProductTour();

  const processBoardCreating = useCallback(async () => {
    try {
      const session = await sessionService.createSession(
        window.innerWidth,
        window.innerHeight,
        dpi,
        null,
        ''
      );

      analyticsService.event('Board Created', {
        defaultGroup: groupId,
        orientation:
          window.innerHeight > window.innerWidth ? 'Portrait' : 'Landscape',
      });

      dispatch(setOpenSource('Sign up'));
      dispatch(addSessionInList(session, groupId));
      dispatch(createBoard());
      dispatch(
        push({
          pathname: `/session/${session.id}`,
          state: { referrer: location.pathname },
        })
      );

      productTour.startTour();
      productTour.setCurrentStep(1);
      productTour.setSideEffect('');
      productTour.pauseTour();
    } catch (err) {
      dispatch(push('/'));

      productTour.startTour();
      productTour.setCurrentStep(0);
      productTour.setSideEffect('');
      productTour.pauseTour();
    }
  }, [dispatch, location, dpi, productTour]);

  return {
    processBoardCreating,
  };
};
