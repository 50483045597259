import React, { useCallback } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  Column,
  ColumnDef,
  Row,
} from '@tanstack/react-table';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '../Typography';
import { buttonTextStyle } from '../Typography/typographyStyles';
import { TableContainer } from './TableContainer';
import { TablePagination } from './TablePagination';
import { MobileTable } from './MobileTable';

type TableProps<T extends object> = {
  initialDataLoaded: boolean;
  columns: ColumnDef<T>[];
  data: T[];
  globalFilter?: string;
  onGlobalFilterChange?: (globalFilterChange: string) => void;
  getColumnCanGlobalFilter?: (column: Column<T>) => boolean;
  noResults: React.ReactNode;
  onRowClick?: (row: Row<T>) => void;
};
export const Table = <T extends object>({
  initialDataLoaded,
  columns,
  data,
  globalFilter,
  onGlobalFilterChange,
  getColumnCanGlobalFilter,
  noResults,
  onRowClick,
}: TableProps<T>) => {
  const theme = useTheme();
  const showTable = useMediaQuery(theme.breakpoints.up('sm'));
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange,
    globalFilterFn: 'includesString',
    getColumnCanGlobalFilter,
    state: { globalFilter /* pagination: { pageIndex: 0, pageSize: 5 }*/ },
    defaultColumn: {
      cell: (info: any) => (
        <Typography variant="p4">{info.getValue() || ''}</Typography>
      ),
    },
    autoResetPageIndex: false,
  });

  const pageSize = table.getState().pagination.pageSize;
  const totalRowCount = table.getFilteredRowModel().rows.length;
  const currentPageIndex = 1 + table.getState().pagination.pageIndex;
  const emptyRows =
    table.getPageCount() > 0
      ? Math.max(0, currentPageIndex * pageSize - totalRowCount)
      : 0;

  if (!showTable) {
    return (
      <MobileTable
        table={table}
        initialDataLoaded={initialDataLoaded}
        onRowClick={onRowClick}
        noResults={noResults}
      />
    );
  }

  return (
    <>
      {showTable && (
        <TableContainer>
          <MuiTable>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      scope="col"
                      align={
                        header.column.columnDef.meta?.isActionsColumn
                          ? 'right'
                          : 'left'
                      }
                      sx={(theme) => ({
                        backgroundColor: theme.background.bg3,
                        height: '64px',
                        padding: '0 8px',
                        color: theme.text.t8,
                        ...buttonTextStyle.b4,
                        '&:first-of-type': {
                          paddingLeft: '16px',
                        },
                        '&:last-child': {
                          paddingRight: '16px',
                        },
                      })}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody
              sx={{
                height: `${
                  (table.getPageCount() !== 0
                    ? table.getState().pagination.pageSize
                    : 5) * 64
                }px`,
              }}
            >
              {table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                  sx={{
                    '&:hover': {
                      backgroundColor: theme.background.bg1,
                    },
                    cursor: onRowClick ? 'pointer' : 'initial',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      align={
                        cell.column.columnDef.meta?.isActionsColumn
                          ? 'right'
                          : 'left'
                      }
                      sx={{
                        height: '64px',
                        padding: '0 8px',
                        '&:first-of-type': {
                          paddingLeft: '16px',
                        },
                        '&:last-child': {
                          paddingRight: '16px',
                        },
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  sx={{
                    height: `${64 * emptyRows}px`,
                  }}
                >
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
              {initialDataLoaded && table.getPageCount() === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    {noResults}
                  </TableCell>
                </TableRow>
              )}
              {!initialDataLoaded && (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <Stack height="100%">
                      <Box>
                        <CircularProgress
                          color="primary"
                          variant="indeterminate"
                        />
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  columnsCount={columns.length}
                  count={table.getFilteredRowModel().rows.length}
                  rowsPerPage={table.getState().pagination.pageSize}
                  page={table.getState().pagination.pageIndex}
                  onPageChange={(page) => table.setPageIndex(page)}
                  onRowsPerPageChange={(rowsPerPage) =>
                    table.setPageSize(rowsPerPage)
                  }
                />
              </TableRow>
            </TableFooter>
          </MuiTable>
        </TableContainer>
      )}
    </>
  );
};
