import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import AuthCover from '../components/AuthCover';
import ResetPasswordForm from './ResetPasswordForm';
import Container from '../components/Container';

const CustomTypography = styled(Typography)(() => ({
  fontWeight: 600,
  lineHeight: '21.6px',
}));

const ResetPassword = () => (
  <Box
    sx={(t) => ({
      display: 'flex',
      height: '100%',
      backgroundColor: t.background.bg2,
    })}
  >
    <Container>
      <Box
        sx={{
          maxWidth: '360px',
          width: '100%',
        }}
      >
        <Box mb={1.75}>
          <Typography
            align="center"
            variant="p1"
            sx={{
              fontWeight: 600,
            }}
          >
            Reset your password
          </Typography>
        </Box>
        <ResetPasswordForm />
        <Box mt={1.75}>
          <NavLink
            to="/sign-in"
            style={{
              textAlign: 'center',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            <CustomTypography color="primary" align="center">
              Back to Sign in
            </CustomTypography>
          </NavLink>
        </Box>
      </Box>
    </Container>
    <AuthCover />
  </Box>
);

export default ResetPassword;
