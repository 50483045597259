const reorder = (items, startIndex, endIndex) => {
  let newItems = [...items];
  const [removed] = newItems.splice(startIndex, 1);
  newItems.splice(endIndex, 0, removed);

  return newItems;
};

const nextClosestElement = (array, value) =>
  array.find((arrayValue) => arrayValue > value);
const previousClosestElement = (array, value) =>
  array.findLast((arrayValue) => arrayValue < value);

export { reorder, nextClosestElement, previousClosestElement };
