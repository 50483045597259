import { FC, ReactNode, useMemo } from 'react';
import BadgeComponent, { badgeClasses } from '@mui/material/Badge';
import { SxProps } from '@mui/material';
import { BadgeVariants, BadgeSize } from './badgeTypes';
import Typography from '../Typography';

type Props = {
  invisible: boolean;
  variant?: BadgeVariants | undefined;
  badgeContent?: string;
  size: BadgeSize;
  styles?: SxProps;
  children?: ReactNode;
};

const Badge: FC<React.PropsWithChildren<Props>> = ({
  invisible,
  variant = BadgeVariants.CONTAINED,
  badgeContent,
  size,
  styles = {},
  children,
}) => {
  const content = useMemo(
    () => (
      <Typography color="inherit" variant="p6">
        {badgeContent}
      </Typography>
    ),
    [badgeContent]
  );

  return (
    <BadgeComponent
      invisible={invisible}
      badgeContent={content}
      sx={(theme) => ({
        [`& .${badgeClasses.badge}`]: {
          pointerEvents: 'none',
          right: '16px',
          top: '26.5px',
          transform: 'none',
          border: !invisible && `1px solid ${theme.status.new.main}`,
          borderRadius: '8px 8px 8px 0px',
          backgroundColor:
            variant === BadgeVariants.OUTLINED
              ? 'transparent'
              : theme.status.new.main,
          color:
            variant === BadgeVariants.OUTLINED
              ? theme.status.new.main
              : theme.text.t1,
          minWidth: size === BadgeSize.M ? '33px' : '31px',
          height: size === BadgeSize.M ? '18px' : '16px',
          padding: '0px 4px 1px 4px',
          ...styles,
        },
      })}
    >
      {children}
    </BadgeComponent>
  );
};

export default Badge;
