import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '../../../ui/atoms/Typography';

type ToolOptionsLayoutProps = {
  title?: string;
  width?: number | string;
};

const ToolOptionsLayout: FC<
  React.PropsWithChildren<ToolOptionsLayoutProps>
> = ({ title, width = 'auto', children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      borderRadius={4}
      padding={2}
      sx={(theme) => ({
        position: 'absolute',
        backgroundColor: theme.background.bg1,
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.14)',
        top: '8px',
        width,
      })}
    >
      {title && (
        <Box>
          <Typography
            variant="p6"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default ToolOptionsLayout;
