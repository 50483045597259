import { cloneDeep } from 'lodash';
import ShapeDrawingAction from './shape.drawing.action';
import guid from '../../utils/guid.util';
import numberUtil from '../../utils/number.util';

export default class TriangleDrawingAction extends ShapeDrawingAction {
  top = null;
  left = null;
  right = null;

  constructor(deserialized) {
    super(deserialized);

    this.name = 'triangle';

    if (deserialized) {
      let [x, y] = deserialized.t;
      this.top = { x, y };

      [x, y] = deserialized.l;
      this.left = { x, y };

      [x, y] = deserialized.r;
      this.right = { x, y };
    } else {
      this.startPoint = { x: 0, y: 0};
      this.endPoint = { x: 0, y: 0};
    }
  }

  createMoveAction() {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;
    const { x: x1, y: y1 } = this.top;
    const { x: x2, y: y2 } = this.left;
    const { x: x3, y: y3 } = this.right;
    this.top = { x: x1, y: y1 };
    this.left = { x: x2, y: y2 };
    this.right = { x: x3, y: y3 };
    return newAction;
  }

  move({ deltaX, deltaY }) {
    this.top = {
      x: this.top.x + deltaX,
      y: this.top.y + deltaY,
    };
    this.left = {
      x: this.left.x + deltaX,
      y: this.left.y + deltaY,
    };
    this.right = {
      x: this.right.x + deltaX,
      y: this.right.y + deltaY,
    };
  }

  intersect(point) {
    const sign = (p1, p2, p3) => {
      return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
    }

    const d1 = sign(point, this.top, this.right);
    const d2 = sign(point, this.right, this.left);
    const d3 = sign(point, this.left, this.top);

    const hasNeg = (d1 < 0) || (d2 < 0) || (d3 < 0);
    const hasPos = (d1 > 0) || (d2 > 0) || (d3 > 0);
    return !(hasNeg && hasPos);
  }

  serialize() {
    return {
      ...super.serialize(),
      t: [numberUtil.toOneDecimal(this.top.x), numberUtil.toOneDecimal(this.top.y)],
      l: [numberUtil.toOneDecimal(this.left.x), numberUtil.toOneDecimal(this.left.y)],
      r: [numberUtil.toOneDecimal(this.right.x), numberUtil.toOneDecimal(this.right.y)],
    };
  }
}
