import { useCallback, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { formatDistanceToNowStrict } from 'date-fns';
import { useInterval } from 'use-interval';
import UserPresenceStatus from '../../../common/constants/UserPresenceStatus';

const colors = {
  none: 'transparent',
  offline: '#AEAEB2',
  away: '#FFCD24',
  online: '#42A976',
};

type Participant = {
  deviceId: string;
  drawingEnabled: boolean;
  joinedTime: number;
  lastJoinedTime: number;
  name: string;
  online: boolean;
  profileImageUrl: string;
  status: number;
  statusChangeDate: number;
  id: string;
  cursorColor: string;
};

type HostPresenceData = {
  id: string;
  name: string;
  statusColor?: string;
  statusText?: string;
  profileImageUrl: string;
  hasDrawingPermission: boolean;
};

let cachedParticipant: Participant | null = null;

const useHostPresence = (participant: Participant) => {
  if (isEmpty(participant)) return;

  const [hostPresenceData, setHostUsersPresenceData] =
    useState<HostPresenceData | null>(null);

  const updateHostPresenceData = useCallback(() => {
    let statusColor;
    let statusText;

    if (participant.status === UserPresenceStatus.online) {
      statusColor = colors.online;
      statusText = 'Online';
    } else if (participant.status === UserPresenceStatus.offline) {
      statusColor = colors.offline;
      statusText = `Offline for ${formatDistanceToNowStrict(
        participant.statusChangeDate
      )}`;
    } else if (participant.status === UserPresenceStatus.away) {
      statusColor = colors.away;
      statusText = `Away for ${formatDistanceToNowStrict(
        participant.statusChangeDate
      )}`;
    } else {
      statusColor = colors.none;
    }

    setHostUsersPresenceData({
      id: participant.id,
      name: participant.name,
      statusColor,
      statusText,
      profileImageUrl: participant.profileImageUrl,
      hasDrawingPermission: participant.drawingEnabled,
    });
  }, [setHostUsersPresenceData, participant]);

  useInterval(
    () => {
      if (isEqual(cachedParticipant, participant)) return;

      updateHostPresenceData();
      cachedParticipant = participant;
    },
    3000,
    true
  );

  return hostPresenceData;
};

export default useHostPresence;
