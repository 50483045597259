import { SAVE_IMAGE } from '../actions/imagesActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_IMAGE: {
      const { id, image } = action.payload;
      return {
        ...state,
        [id]: image,
      };
    }
    default:
      return state;
  }
};

const imagesHashSelector = state => state.images;

export { imagesHashSelector };
