import { DisplayObject, BitmapText as PixiText } from 'pixi.js';
import { cloneDeep } from 'lodash';
import DrawingAction from './drawingAction';
import Point from '../types/Point';
import OldTextAction from '../../../common/drawingActions/text.drawing.action';
import guid from '../../../common/utils/guid.util';
import ActionName from '../types/ActionName';

class Text extends DrawingAction {
  text: string = '';

  startPoint: Point;

  endPoint: Point;

  constructor(action?: OldTextAction) {
    super(action);

    this.name = ActionName.TEXT;

    if (action) {
      this.text = action.text;
      this.startPoint = action.startPoint;
      this.endPoint = {
        x: action.startPoint.x + action.width,
        y: action.startPoint.y + action.height,
      };
    }
  }

  toDto(): OldTextAction {
    const dto = new OldTextAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldTextAction) {
    super.setFields(action);
    action.name = ActionName.TEXT;
    action.text = this.text;
    action.startPoint = this.startPoint;
    action.width = Math.abs(this.endPoint.x - this.startPoint.x);
    action.height = Math.abs(this.endPoint.y - this.startPoint.y);
  }

  createMoveAction(newPos: Point): Text {
    const newAction = cloneDeep(this);
    newAction.id = guid();
    newAction.key = guid();
    newAction.targetId = this.id;

    const center = {
      x: (this.startPoint.x + this.endPoint.x) / 2,
      y: (this.startPoint.y + this.endPoint.y) / 2,
    };

    const delta = {
      x: newPos.x - center.x,
      y: newPos.y - center.y,
    };

    newAction.startPoint = {
      x: this.startPoint.x + delta.x,
      y: this.startPoint.y + delta.y,
    };
    newAction.endPoint = {
      x: this.endPoint.x + delta.x,
      y: this.endPoint.y + delta.y,
    };

    return newAction;
  }

  draw(object: DisplayObject) {
    const textObject = object as PixiText;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    textObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );
    textObject.anchor.set(0.5);
    textObject.text = this.text;
  }
}

export default Text;
