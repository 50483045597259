import React, { FC, useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  BaseAssessmentTemplateCard,
  BaseAssessmentTemplateCardProps,
} from './BaseAssessmentTemplateCard';
import DotsVerticalSvgIcon from '../../../../../../../ui/atoms/Icons/DotsVerticalIcon';
import MenuItem from '../../../../../../../ui/atoms/MenuItem';
import Menu from '../../../../../../../ui/molecules/Menu';

type AssessmentTemplateCardProps = {
  id: string;
  onDelete: (id: string) => void;
} & BaseAssessmentTemplateCardProps;

export const AssessmentTemplateCard: FC<
  React.PropsWithChildren<AssessmentTemplateCardProps>
> = ({
  id,
  templateName,
  createdDate,
  updatedDate,
  questionsCount,
  onClick,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const contextMenuOpen = Boolean(anchorEl);

  const handleContextMenuOpen = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleContextMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onDeleteClick = useCallback(() => {
    onDelete(id);
    handleContextMenuClose();
  }, [id, onDelete, handleContextMenuClose]);

  return (
    <>
      <BaseAssessmentTemplateCard
        templateName={templateName}
        createdDate={createdDate}
        updatedDate={updatedDate}
        questionsCount={questionsCount}
        onClick={onClick}
        actions={[
          {
            Icon: DotsVerticalSvgIcon,
            onClick: (event: any) => {
              event.preventDefault();
              event.stopPropagation();
              handleContextMenuOpen(event);
            },
          },
        ]}
      />
      <Menu
        anchorEl={anchorEl}
        open={contextMenuOpen}
        onClose={handleContextMenuClose}
        listItemWidth={164}
      >
        <MenuItem
          title="Delete"
          onSelect={onDeleteClick}
          startIcon={<DeleteIcon />}
        />
      </Menu>
    </>
  );
};
