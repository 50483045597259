import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import {
  ConnectionQuality,
  LocalTrack,
  Participant,
  RemoteTrack,
  Track,
} from 'livekit-client';
import { useParticipant } from '@livekit/react-core';
import { DisplayContext } from '@livekit/react-components';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { Property } from 'csstype';
import Typography from '../../ui/atoms/Typography';
import MicrophoneOffOutlineIcon from '../../ui/atoms/Icons/MicrophoneOffOutlinecon';
import MicrophoneIcon from '../../ui/atoms/Icons/MicrophoneIcon';
import LiveKitVideoRenderer from '../LiveKitPopup/VideoRenderer';
import ConnectionQualityExcellentIcon from '../../ui/atoms/Icons/ConnectionQualityExelentIcon';
import ConnectionQualityGoodIcon from '../../ui/atoms/Icons/ConnectionQualityGoodIcon';
import ConnectionQualityPoorIcon from '../../ui/atoms/Icons/ConnectionQualityPoorIcon';

export interface ParticipantProps {
  participant: Participant;
  displayName?: string;
  width?: string;
  height?: string;
  aspectWidth?: number;
  aspectHeight?: number;
  orientation?: 'landscape' | 'portrait';
  showOverlay?: boolean;
  showConnectionQuality?: boolean;
  showDisplayName?: boolean;
  onClick?: () => void;
  isSmallView?: boolean;
}

const ParticipantBar = styled(Box)(() => ({
  boxSizing: 'border-box',
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  color: 'white',
  padding: '0px 8px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr min-content min-content',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  background:
    'linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80.67%)',
}));

type VideoRendererFactoryFunction = (
  isSubscribed: boolean,
  track: Track | null,
  isMuted: boolean,
  displayName?: string,
  isSmallView?: boolean,
  isSpeaking?: boolean
) => FC<
  React.PropsWithChildren<{
    isLocal: boolean;
    objectFit: Property.ObjectFit;
    handleResize: (width: number, height: number) => void;
    isSpeaking?: boolean;
  }>
>;

export const ParticipantAvatar: FC<
  React.PropsWithChildren<{
    displayName: string;
    isSmallView?: boolean;
  }>
> = ({ displayName, isSmallView }) => {
  const name = displayName.slice(0, 2).toUpperCase();

  return (
    <Avatar
      sx={(theme) => ({
        width: isSmallView ? 32 : 48,
        height: isSmallView ? 32 : 48,
        backgroundColor: theme.background.bg5,
        color: theme.text.t8,
        ...(isSmallView ? theme.typography.b6 : theme.typography.b4),
      })}
    >
      {name}
    </Avatar>
  );
};

const videoRendererFactory: VideoRendererFactoryFunction =
  (isSubscribed, track, isMuted, displayName, isSmallView) =>
  ({ isLocal, objectFit, handleResize, isSpeaking }) => {
    if (isSubscribed && track && !isMuted) {
      return (
        <LiveKitVideoRenderer
          track={track}
          isLocal={isLocal}
          objectFit={objectFit}
          width="100%"
          height="100%"
          onSizeChanged={handleResize}
          isSpeaking={isSpeaking}
        />
      );
    }

    return (
      <Box
        sx={(theme) => ({
          background: theme.background.bg8,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: `2px solid ${isSpeaking ? '#1EB270' : 'transparent'}`,
          borderRadius: '12px',
        })}
      >
        {displayName && (
          <ParticipantAvatar
            isSmallView={isSmallView}
            displayName={displayName}
          />
        )}
      </Box>
    );
  };

const ParticipantRenderer: FC<React.PropsWithChildren<ParticipantProps>> = ({
  participant,
  displayName,
  width,
  height,
  aspectWidth,
  aspectHeight,
  orientation,
  showOverlay,
  showConnectionQuality,
  showDisplayName = true,
  onClick,
  isSmallView = false,
}) => {
  const { cameraPublication, isLocal, connectionQuality, isSpeaking } =
    useParticipant(participant);
  const [videoSize, setVideoSize] = useState<string>();
  const [currentBitrate, setCurrentBitrate] = useState<number>();

  const context = useContext(DisplayContext);

  const handleResize = useCallback(
    (width: number, height: number) => {
      setVideoSize(`${width}x${height}`);
    },
    [setVideoSize]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      let total = 0;
      participant.tracks.forEach((pub) => {
        if (
          pub.track instanceof LocalTrack ||
          pub.track instanceof RemoteTrack
        ) {
          total += pub.track.currentBitrate;
        }
      });
      setCurrentBitrate(total);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [participant.tracks, setCurrentBitrate]);

  let objectFit: Property.ObjectFit = 'contain';
  let videoOrientation: 'landscape' | 'portrait' | undefined;
  if (!orientation && aspectWidth && aspectHeight) {
    orientation = aspectWidth > aspectHeight ? 'landscape' : 'portrait';
  }
  if (cameraPublication?.dimensions) {
    videoOrientation =
      cameraPublication.dimensions.width > cameraPublication.dimensions.height
        ? 'landscape'
        : 'portrait';
  }

  if (videoOrientation === orientation) {
    objectFit = 'cover';
  }

  if (!displayName) {
    displayName = participant.name || participant.identity;
  }

  const isSubscribed = cameraPublication?.isSubscribed || false;
  const track = cameraPublication?.track || null;
  const isMuted = cameraPublication?.isMuted || false;

  const VideoRenderer = useMemo(
    () =>
      videoRendererFactory(
        isSubscribed,
        track,
        isMuted,
        displayName,
        isSmallView
      ),
    [isSubscribed, track, isMuted, displayName, isSmallView]
  );

  const isAudioMuted = !participant.isMicrophoneEnabled;

  let statsContent: ReactElement | undefined;
  if (context.showStats) {
    statsContent = (
      <Box
        sx={{
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <span>{videoSize}</span>
        {currentBitrate !== undefined && currentBitrate > 0 && (
          <span>&nbsp;{Math.round(currentBitrate / 1024)} kbps</span>
        )}
      </Box>
    );
  }

  let ConnectionQualityIndicator: typeof ConnectionQualityPoorIcon | undefined;
  if (showConnectionQuality) {
    switch (connectionQuality) {
      case ConnectionQuality.Excellent:
        ConnectionQualityIndicator = ConnectionQualityExcellentIcon;
        break;
      case ConnectionQuality.Good:
        ConnectionQualityIndicator = ConnectionQualityGoodIcon;
        break;
      case ConnectionQuality.Poor:
        ConnectionQualityIndicator = ConnectionQualityPoorIcon;
        break;
    }
  }

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        borderRadius: '12px',
        overflow: 'hidden',
        position: 'relative',
      }}
      width={width}
      height={height}
      onClick={onClick}
    >
      {aspectWidth && aspectHeight && (
        <AspectRatio ratio={aspectWidth / aspectHeight}>
          <VideoRenderer
            isLocal={isLocal}
            objectFit={objectFit}
            handleResize={handleResize}
            isSpeaking={isSpeaking}
          />
        </AspectRatio>
      )}
      {(!aspectWidth || !aspectHeight) && (
        <VideoRenderer
          isLocal={isLocal}
          objectFit={objectFit}
          handleResize={handleResize}
          isSpeaking={isSpeaking}
        />
      )}
      <ParticipantBar height={isSmallView ? 28 : 48}>
        {showDisplayName && (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              justifySelf: 'start',
              cursor: 'pointer',
              display: 'flex',
              gap: 0.5,
              minWidth: '100%',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant={isSmallView ? 'p6' : 'p5'}
              sx={(theme) => ({
                color: theme.text.t1,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              })}
            >
              {displayName}
            </Typography>
            {isLocal && (
              <Typography
                variant={isSmallView ? 'p6' : 'p5'}
                sx={(theme) => ({
                  color: theme.text.t1,
                })}
              >
                (you)
              </Typography>
            )}
          </Box>
        )}
        {statsContent && (
          <Box sx={{ justifySelf: 'center' }}>{statsContent}</Box>
        )}
        {showOverlay && (
          <Box display="flex" justifyContent="end">
            <Box>
              {ConnectionQualityIndicator && <ConnectionQualityIndicator />}
            </Box>
            <Box>
              {isAudioMuted ? (
                <MicrophoneOffOutlineIcon
                  sx={{
                    width: 14,
                    height: 14,
                  }}
                />
              ) : (
                <MicrophoneIcon
                  sx={{
                    width: 14,
                    height: 14,
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </ParticipantBar>
    </Box>
  );
};

export default ParticipantRenderer;
