import { createSelector } from 'reselect';
import { participantsSelector } from '../session/participantsReducer';
import {
  ADD_TEMPORARY_ACTION,
  UPDATE_TEMPORARY_ACTION,
  REMOVE_TEMPORARY_ACTION,
  SET_CURRENT_TEMPORARY_ACTION,
} from '../../actions/board/temporaryDraingActionsActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';

const initialState = {
  hash: {},
  currentTemporaryAction: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TEMPORARY_ACTION:
      return {
        ...state,
        hash: {
          ...state.hash,
          [payload.action.getId()]: payload.action,
        },
      };
    case UPDATE_TEMPORARY_ACTION:
      return {
        ...state,
        hash: {
          ...state.hash,
          [payload.action.getId()]: payload.action,
        },
      };
    case REMOVE_TEMPORARY_ACTION: {
      const {
        [payload.action.getId()]: action,
        ...restHash
      } = state.hash;
      return {
        ...state,
        hash: {
          ...restHash,
        },
      };
    }
    case SET_CURRENT_TEMPORARY_ACTION:
      return {
        ...state,
        currentTemporaryAction: payload.action,
      };
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const currentTemporaryActionSelector = state => state.board.temporaryActions.currentTemporaryAction;

const temporaryActionsSelector = createSelector(
  [
    state => state.board.temporaryActions.hash,
    currentTemporaryActionSelector,
    participantsSelector,
  ],
  (hash, currentAction, participants) => Object.keys(hash)
    .map(id => hash[id])
    .filter(action => !currentAction || action.getId() !== currentAction.getId())
    .map(action => ({
      ...action,
      participant: participants.find(p => p.id === action.getCreatorId()),
    })),
);

export {
  currentTemporaryActionSelector,
  temporaryActionsSelector,
};
