import { createContext } from 'react';

export type AssessmentSidebarContextProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const defaultValue: AssessmentSidebarContextProps = {
  open: false,
  onOpen: () => {},
  onClose: () => {},
};

export const AssessmentSidebarContext =
  createContext<AssessmentSidebarContextProps>(defaultValue);
