import { DisplayObject, Graphics, Polygon } from 'pixi.js';
import Shape from './shape';
import Point from '../types/Point';
import OldTriangleAction from '../../../common/drawingActions/shapes/triangle.drawing.action';
import ActionName from '../types/ActionName';

class Triangle extends Shape {
  top: Point;

  left: Point;

  right: Point;

  constructor(action?: OldTriangleAction) {
    super(action);

    this.name = ActionName.TRIANGLE;

    if (action) {
      this.top = action.top;
      this.left = action.left;
      this.right = action.right;
    }
  }

  toDto(): OldTriangleAction {
    const dto = new OldTriangleAction();
    this.setFields(dto);

    return dto;
  }

  setFields(action: OldTriangleAction) {
    super.setFields(action);
    action.name = ActionName.TRIANGLE;
    action.top = this.top;
    action.left = this.left;
    action.right = this.right;
  }

  draw(object: DisplayObject) {
    const graphicsObject = object as Graphics;
    const width = this.endPoint.x - this.startPoint.x;
    const height = this.endPoint.y - this.startPoint.y;
    graphicsObject.clear();
    graphicsObject.position.set(
      this.startPoint.x + width / 2,
      this.startPoint.y + height / 2
    );

    const color = this.paint.color & 0xffffff;
    const size = +this.paint.strokeWidth;

    if (!this.paint.fill) {
      graphicsObject.lineStyle(size, color);
    } else {
      graphicsObject.beginFill(color);
    }

    this.top = {
      x: 0,
      y: -height / 2,
    };
    this.left = {
      x: width / 2,
      y: height / 2,
    };
    this.right = {
      x: -width / 2,
      y: height / 2,
    };

    graphicsObject.moveTo(0, -height / 2);
    graphicsObject.lineTo(width / 2, height / 2);
    graphicsObject.lineTo(-width / 2, height / 2);
    graphicsObject.closePath();

    if (this.paint.fill) {
      graphicsObject.endFill();
    } else {
      const xPaddedOut = width + size;
      const yPaddedOut = height + size;

      graphicsObject.hitArea = new Polygon([
        0,
        -yPaddedOut / 2,
        xPaddedOut / 2,
        yPaddedOut / 2,
        -xPaddedOut / 2,
        yPaddedOut / 2,
      ]);
    }
  }
}

export default Triangle;
