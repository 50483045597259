import FreeV2DrawingAction from './free.drawing.v2.action';

export default class EraseV2DrawingAction extends FreeV2DrawingAction {
  constructor(deserialized) {
    super(deserialized);

    this.name = 'erase_v2';
  }

  serialize() {
    const serialized = super.serialize();
    serialized.pnt = {
      ...serialized.pnt,
      // ToDo: Remove this attribute. It is needed only on android
      x: '',
    };
    return serialized;
  }
}
