import React from 'react';
import SmartBanner from 'react-smartbanner';

const InstallMobileAppBanner = () => (
  <SmartBanner
    title="LiveBoard"
    position="bottom"
  />
);

export default InstallMobileAppBanner;
