import DrawingAction from '../drawing.action';
import numberUtil from '../../utils/number.util';

export default class DotPointerAction extends DrawingAction {
  point = null;
  participant = null;

  constructor(deserialized) {
    super(deserialized);

    this.name = 'pointDot';

    if (deserialized) {
      this.point = { x: deserialized.x, y: deserialized.y };
    }
  }

  setParticipant(participant) {
    this.participant = participant;
  }

  serialize() {
    return {
      ...super.serialize(),
      x: numberUtil.toOneDecimal(this.point.x),
      y: numberUtil.toOneDecimal(this.point.y),
    };
  }
}
