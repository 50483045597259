import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '../../../../../ui/atoms/Avatar';
import CallControlsPanel from '../CallControlsPanel';
import * as sessionReducer from '../../../../../common/reducers/session/sessionReducer';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import { AvatarSizeTypes } from '../../../../../ui/atoms/Avatar/types';
import IconButton from '../../../../../ui/atoms/IconButton';
import VideoOutlineIcon from '../../../../../ui/atoms/Icons/VideoOutlineIcon';

type Props = {
  connected: boolean;
  onEndCall: () => void;
  onJoinCall: () => void;
  loading: boolean;
  disabled: boolean;
  tooltipPlacment: 'top' | 'left';
  collaborationBarExpanded: boolean;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  filter: 'drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.14))',

  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: `${theme.spacing(2.5)} !important`,
  },
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: theme.background.bg1,
    width: 256,
    padding: '16px',
    borderRadius: '10px',
  },
}));

const StyledListItem = styled(ListItem)({
  display: 'flex',
  gap: '8px',
});

type CustomArrowProps = {
  collaborationBarExpanded: boolean;
};

const CustomArrow: FC<React.PropsWithChildren<CustomArrowProps>> = ({
  collaborationBarExpanded,
}) => (
  <Box
    sx={(theme) => ({
      width: '20px',
      height: '20px',
      position: 'absolute',
      bottom: '-2px',
      ...(collaborationBarExpanded
        ? {
            left: 'calc(50% - 10px)',
          }
        : {
            right: '38px',
          }),
      transform: 'rotate(45deg)',
      backgroundColor: theme.background.bg1,
      borderBottomRightRadius: '2px',
    })}
  />
);

const ParticipantCallTools: FC<React.PropsWithChildren<Props>> = ({
  connected,
  onEndCall,
  onJoinCall,
  loading,
  disabled,
  tooltipPlacment,
  collaborationBarExpanded,
}) => {
  const sessionOwner = useSelector(sessionReducer.ownerSelector);

  return (
    <>
      {connected && (
        <CallControlsPanel
          onEndCall={onEndCall}
          loading={loading}
          disabled={disabled}
          tooltipTitle="Leave call"
          tooltipPlacment={tooltipPlacment}
        />
      )}
      {!connected && (
        <StyledTooltip
          open
          placement="bottom"
          components={{
            Arrow: () => (
              <CustomArrow
                collaborationBarExpanded={collaborationBarExpanded}
              />
            ),
          }}
          TransitionProps={{ timeout: 0 }}
          title={
            <StyledListItem alignItems="center" disablePadding>
              <ListItemAvatar sx={{ marginTop: 0, minWidth: '32px' }}>
                <Avatar
                  size={AvatarSizeTypes.XS}
                  alt={sessionOwner.name}
                  src={sessionOwner.profileImageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  marginTop: 0,
                  marginBottom: 0,
                }}
                primary={
                  <Box>
                    <Typography
                      variant="p4"
                      color="primary"
                      component="span"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '16ch',
                        verticalAlign: 'bottom',
                        display: 'inline-block',
                      }}
                    >
                      {sessionOwner.name}
                    </Typography>{' '}
                    <Typography
                      variant="p5"
                      component="span"
                      sx={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: '150%',
                        verticalAlign: 'bottom',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        display: 'inline-block',
                      }}
                    >
                      has started a video call.
                    </Typography>
                  </Box>
                }
              />
            </StyledListItem>
          }
        >
          <Tooltip title="Join Call" placement={tooltipPlacment}>
            <Box>
              <IconButton
                variant={ButtonVariantTypes.SECONDARY}
                size={ButtonSizeTypes.S}
                shape={ButtonShapeTypes.ROUNDED}
                onClick={onJoinCall}
                className="join-call-button"
              >
                <VideoOutlineIcon
                  sx={{
                    width: 18,
                    height: 18,
                  }}
                />
              </IconButton>
            </Box>
          </Tooltip>
        </StyledTooltip>
      )}
    </>
  );
};

export default ParticipantCallTools;
