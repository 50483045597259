import { toColor } from '../../../../common/utils/color.utils';

const lineDrawer = (ctx, item) => {
  const {
    startPoint,
    endPoint,
    paint,
  } = item;
  const {
    color,
    strokeWidth,
  } = paint;
  const rgbaColor = toColor(color);
  ctx.save();
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.strokeStyle = rgbaColor;
  ctx.lineWidth = strokeWidth;
  ctx.beginPath();
  ctx.moveTo(startPoint.x, startPoint.y);
  ctx.lineTo(endPoint.x, endPoint.y);
  ctx.stroke();
  ctx.restore();
};

export default lineDrawer;
