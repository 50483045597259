import ShapeControl from '../ShapeControl';
import { getImage } from '../../../../../../common/utils/rotate-image.utils';

export default class RotateButton extends ShapeControl {
  constructor(startPoint, endPoint, scale, padding, rotationAngle, angle) {
    super(startPoint, endPoint, scale, padding, rotationAngle);

    this.angle = angle;
    this.cursor = 'pointer';
    this.buttonWidth = 20;
    this.buttonHeight = 20;
    this.rotationAngle = rotationAngle;
  }

  draw(ctx, selectedRotation) {
    if (!selectedRotation) return;

    const { x, y } = this.center();
    const image = getImage();

    ctx.save();
    ctx.imageSmoothingQuality = 'high';

    ctx.translate(x, y);
    ctx.rotate(this.angle + this.rotationAngle);

    ctx.scale(1 / this.scale, 1 / this.scale);
    ctx.drawImage(
      image,
      -image.width / 2,
      -image.height / 2,
      image.width,
      image.height,
    );

    ctx.restore();
  };

  center() {
    throw new Error('Not implemented');
  };

  hitTest(x, y) {
    const center = this.center();
    const a = (x - center.x) ** 2;
    const b = (y - center.y) ** 2;

    return Math.sqrt(a + b) <= 25 / this.scale;
  };
}
