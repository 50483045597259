import { z } from 'zod';

const digit = 1;
const letter = 1;
const length = 8;

const passwordSchema = z.object({
  password: z.string()
    .min(length, { message: `Password must contain at least ${length} character${length > 1 ? 's' : ''}.` })
    .regex(/\d/, {
      message: `Password must contain at least ${digit} digit${digit > 1 ? 's' : ''}.`,
    })
    .regex(/[a-zA-Z]/, { message: `Password must contain at least ${letter} letter${letter > 1 ? 's' : ''}.` }),
  repeatedPassword: z.string().min(1, { message: 'This field is required' }),
})
  .refine(data => data.password === data.repeatedPassword, {
    message: 'Passwords doesn\'t match',
    path: ['repeatedPassword'],
  });

export type PasswordSchemaType = z.infer<typeof passwordSchema>;

export default passwordSchema;
