import { FC, useState } from 'react';
import { Room } from 'livekit-client';
import { AudioRenderer, RoomState, useParticipant } from '@livekit/react-core';
import Box from '@mui/material/Box';
import { ControlButton } from './ControlButton';
import { AudioSelectButton } from './AudioSelectButton';
import useDialog from '../../common/hooks/useDialog';
import MediaDeviceNotSupportedDialog from '../../ui/molecules/MediaDeviceNotSupportDialog';
import CallEndIcon from '../../ui/atoms/Icons/CallEndIcon';
import { useSortedParticipants } from '../../common/hooks/livekit/useSortedParticipants';
import Avatar from '../../ui/atoms/Avatar';
import { AvatarSizeTypes } from '../../ui/atoms/Avatar/types';
import Typography from '../../ui/atoms/Typography';
import ViewOptions from './ViewOptions';
import { VideoSelectButton } from './VideoSelectButton';

type CollapseStageProps = {
  roomState: RoomState;
  onLeave?: (room: Room) => void;
  onViewSpeakerClick: () => void;
  onViewGridClick: () => void;
  onViewMinimizeClick: () => void;
  stageLayout: 'speaker' | 'grid' | 'minimize';
};

const CollapseStage: FC<React.PropsWithChildren<CollapseStageProps>> = ({
  roomState,
  onLeave,
  onViewSpeakerClick,
  onViewGridClick,
  onViewMinimizeClick,
  stageLayout,
}) => {
  const { room, participants } = roomState;
  const [audioButtonDisabled, setAudioButtonDisabled] = useState(false);
  const [videoButtonDisabled, setVideoButtonDisabled] = useState(false);
  const [activeSpeaker] = useSortedParticipants({
    participants,
  });
  const { cameraPublication: camPub, microphonePublication: micPub } =
    useParticipant(room!.localParticipant);
  const isMicrophoneEnabled = !(micPub?.isMuted ?? true);
  const isCameraEnabled = !(camPub?.isMuted ?? true);

  const {
    open: mediaDeviceNotSupportedDialogOpen,
    onOpen: onMediaDeviceNotSupportedDialogOpen,
    onClose: onMediaDeviceNotSupportedDialogClose,
  } = useDialog();

  const activeSpeakerName = activeSpeaker.name || activeSpeaker.identity;

  return (
    <>
      <Box
        sx={{
          width: 420,
          padding: '8px',
          borderRadius: 3,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1.25,
        }}
      >
        <Box display="flex" alignItems="center" gap={1.5}>
          <Avatar size={AvatarSizeTypes.XS} alt={activeSpeakerName} />
          <Box>
            <Typography variant="p5">Talking:{activeSpeakerName}</Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1.5}>
          {onLeave && (
            <ControlButton
              minimized
              onClick={() => {
                if (!room) return;

                room.disconnect();
                onLeave(room);
              }}
              icon={CallEndIcon}
              color="error"
            />
          )}
          <AudioSelectButton
            isMuted={!isMicrophoneEnabled}
            isButtonDisabled={audioButtonDisabled}
            minimized
            onClick={async () => {
              if (!room) return;

              if (room.localParticipant.lastMicrophoneError) {
                navigator.permissions.query({ name: 'microphone' });
                onMediaDeviceNotSupportedDialogOpen();
                return;
              }
              setAudioButtonDisabled(true);
              room.localParticipant
                .setMicrophoneEnabled(!isMicrophoneEnabled)
                .finally(() => setAudioButtonDisabled(false));
            }}
          />
          <VideoSelectButton
            isEnabled={isCameraEnabled}
            isButtonDisabled={videoButtonDisabled}
            minimized
            onClick={() => {
              if (!room) return;

              if (room.localParticipant.lastCameraError) {
                navigator.permissions.query({ name: 'camera' });
                onMediaDeviceNotSupportedDialogOpen();
                return;
              }
              if (videoButtonDisabled) return;

              setVideoButtonDisabled(true);
              room.localParticipant
                .setCameraEnabled(!isCameraEnabled)
                .finally(() => setVideoButtonDisabled(false));
            }}
          />
          <ViewOptions
            onViewSpeakerClick={onViewSpeakerClick}
            onViewGridClick={onViewGridClick}
            onViewMinimizeClick={onViewMinimizeClick}
            stageLayout={stageLayout}
          />
        </Box>
      </Box>
      <MediaDeviceNotSupportedDialog
        open={mediaDeviceNotSupportedDialogOpen}
        onClose={onMediaDeviceNotSupportedDialogClose}
      />
      {roomState.audioTracks.map((track) => (
        <AudioRenderer key={track.sid} track={track} isLocal={false} />
      ))}
    </>
  );
};

export default CollapseStage;
