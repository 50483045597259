import * as uiReducer from '../../reducers/uiReducer';
import * as sessionReducer from '../../reducers/session/sessionReducer';
import { participantsSelector } from '../../reducers/session/participantsReducer';
import Snackbar from '../../../components/Snackbar/SnackbarConfig';

const ADD_MESSAGE = Symbol('ADD_MESSAGE');
const ADD_MESSAGES = Symbol('ADD_MESSAGES');

const addMessages = (messages) => ({
  type: ADD_MESSAGES,
  messages,
});

const addMessage = (message) => ({
  type: ADD_MESSAGE,
  message,
});

const messagesReceived = (messages) => (dispatch) => {
  const messagesWithIsRead = messages.map((message) => ({
    ...message,
    isRead: true,
  }));

  dispatch(addMessages(messagesWithIsRead));
};

const messageReceived = (message) => (dispatch, getState) => {
  const state = getState();
  const messagesSideBarOpened = uiReducer.messagesSideBarOpenedSelector(state);
  const chatEnabled = sessionReducer.chatSelector(state);

  dispatch(
    addMessage({
      ...message,
      isRead: messagesSideBarOpened,
    })
  );

  if (messagesSideBarOpened || !chatEnabled) return;

  const participants = participantsSelector(state);
  const participant = participants.find(
    (participant) => participant.id === message.senderId
  );

  Snackbar.presenceStatus(message.text, {
    userName: participant.name,
    type: 'info',
    profileImageSrc: participant.profileImageUrl,
  });
};

export { messagesReceived, messageReceived, ADD_MESSAGE, ADD_MESSAGES };
