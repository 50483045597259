import { FC, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import CallButton from '../CallButton/CallButton';
import useDialog from '../../../../common/hooks/useDialog';
import HostCallTools from './HostCallTools';
import ParticipantCallTools from './ParticipantCallTools';
import * as planPermissionSelectors from '../../../../common/selectors/planPermissionSelectors';
import analyticsService from '../../../../common/services/analytics.service';
import UnleashFlags from '../../../../common/constants/UnleashFlag';
import CallDialog from '../../../../ui/molecules/CallDialog';
import { ConnectionState } from '../CallContext/defaultValue';
import LiveKitContext from '../../CollaborationTools/Manager/LiveKitManager/context/LiveKitContext';
import UpgradeDialog from '../../../../ui/molecules/UpgradeDialog';
import PlanDialog from '../../../../ui/molecules/PlanDialog';

type CallToolProps = {
  tooltipPlacment: 'top' | 'left';
  collaborationBarExpanded: boolean;
};

const CallTool: FC<React.PropsWithChildren<CallToolProps>> = ({
  tooltipPlacment,
  collaborationBarExpanded,
}) => {
  const showCallButton = useSelector(
    planPermissionSelectors.hasVoiceButtonPermissionSelector
  );
  const hasVoicePermission = useSelector(
    planPermissionSelectors.hasVoicePermissionSelector
  );

  const livekitConferenceEnabled = useFlag(UnleashFlags.LIVEKIT_CONFERENCE);

  const {
    open: callDialogOpen,
    onOpen: onCallDialogOpen,
    onClose: onCallDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const {
    open: upgradeDialog,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const livekitContext = useContext(LiveKitContext);

  const onCallDialogSuccess = useCallback(
    async ({
      connectMuted,
      muteParticipantsOnEntry,
      cameraEnabled,
      deviceOptions = {},
    }: any) => {
      if (livekitContext.isConferenceInProgress) {
        await livekitContext.joinConference({
          selfMuted: connectMuted,
          cameraEnabled,
          deviceOptions,
        });
        onCallDialogClose();
        return;
      }

      await livekitContext.startConference(
        connectMuted,
        muteParticipantsOnEntry,
        {
          cameraEnabled,
          deviceOptions,
        }
      );
      onCallDialogClose();
    },
    [livekitContext, onCallDialogClose]
  );

  const callButtonClick = useCallback(() => {
    analyticsService.event('Call Button Click', {
      livekitConferenceEnabled,
      source: 'Collaboration',
    });

    if (!hasVoicePermission) {
      onUpgradeDialogOpen();

      return;
    }

    onCallDialogOpen();
  }, [
    onCallDialogOpen,
    livekitConferenceEnabled,
    hasVoicePermission,
    onUpgradeDialogOpen,
  ]);

  const isConnectingCall = useMemo(
    () =>
      livekitContext.connectionState === ConnectionState.Connecting ||
      livekitContext.connectionState === ConnectionState.Reconnecting,
    [livekitContext.connectionState]
  );

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
  }, [onPlansDialogOpen, onUpgradeDialogClose]);

  return (
    <>
      {showCallButton &&
        livekitContext.isHost &&
        !livekitContext.isConferenceInProgress && (
          <CallButton
            onClick={callButtonClick}
            tooltipPlacement={tooltipPlacment}
          />
        )}
      {livekitContext.isHost && livekitContext.isConferenceInProgress && (
        <HostCallTools
          connected={livekitContext.connectedToConference}
          onEndCall={livekitContext.disconnectFromConference}
          onJoinCall={onCallDialogOpen}
          loading={isConnectingCall}
          disabled={
            livekitContext.connectionState !== ConnectionState.Connected
          }
          tooltipPlacment={tooltipPlacment}
        />
      )}
      {!livekitContext.isHost && livekitContext.isConferenceInProgress && (
        <ParticipantCallTools
          connected={livekitContext.connectedToConference}
          onEndCall={livekitContext.disconnectFromConference}
          onJoinCall={onCallDialogOpen}
          loading={isConnectingCall}
          disabled={
            livekitContext.connectionState !== ConnectionState.Connected
          }
          tooltipPlacment={tooltipPlacment}
          collaborationBarExpanded={collaborationBarExpanded}
        />
      )}
      <CallDialog
        open={callDialogOpen}
        onClose={onCallDialogClose}
        onSuccess={onCallDialogSuccess}
      />
      <UpgradeDialog
        open={upgradeDialog}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source="Start Call"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </>
  );
};

export default CallTool;
