import React from 'react';
import { connect } from 'react-redux';
import { Subject, Observable, merge } from 'rxjs';
import {
  map,
  auditTime,
  concatMap,
  filter,
  pairwise,
  takeUntil,
  take,
  tap,
} from 'rxjs/operators';
import compose from '../../../../common/utils/compose.utils';
import { withCanvasContext } from '../../Contexts/CanvasContext';
import DomEventsUtil from '../../../../common/utils/domEvents.util';
import fireBaseService from '../../../../common/services/firebaseService';
import drawingActionsFactory from '../../../../common/drawingActions/drawingActionsFactory';
import * as temporaryDraingActionsActions from '../../../../common/actions/board/temporaryDraingActionsActions';
import * as userReducer from '../../../../common/reducers/userReducer';
import * as sessionReducer from '../../../../common/reducers/session';
import clearSelection from './clearSelection';
import SelectCursorSvgIcon from '../../../../assets/svgIcons/SelectCursorSvgIcon.svg';

function repeatLastValue(source) {
  return Observable.create((subscriber) => {
    let timeOut;
    return source.subscribe(
      (value) => {
        try {
          subscriber.next(value);
          clearInterval(timeOut);
          timeOut = setInterval(() => subscriber.next(value), 1000);
        } catch (err) {
          subscriber.error(err);
        }
      },
      (err) => subscriber.error(err),
      () => {
        clearInterval(timeOut);
        subscriber.complete();
      }
    );
  });
}

const enhancer = compose(
  withCanvasContext,
  connect(
    (state) => ({
      userId: userReducer.userIdSelector(state),
      isSessionLive: sessionReducer.isSessionLiveSelector(state),
    }),
    {
      setCurrentTemporaryAction:
        temporaryDraingActionsActions.setCurrentTemporaryAction,
    }
  )
);

class PointerDrawingLayer extends React.Component {
  componentDidMount() {
    if (this.canvas) {
      this.canvas.addEventListener('wheel', this.onWheel, { passive: false });
      this.canvas.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
      this.canvas.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      });
      this.canvas.addEventListener('touchend', this.onTouchEnd, {
        passive: false,
      });
    }

    this.subscribeToMove();
    this.subscribeToZoom();
    this.subscribeToDrawing();
  }

  componentWillUnmount() {
    this.mouseDown$.complete();
    this.mouseMove$.complete();
    this.mouseUp$.complete();
    this.touchStart$.complete();
    this.touchMove$.complete();
    this.touchEnd$.complete();
    this.wheel$.complete();

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onWheel);
      this.canvas.removeEventListener('touchstart', this.onTouchStart);
      this.canvas.removeEventListener('touchmove', this.onTouchMove);
      this.canvas.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  // React synthetic events
  onMouseDown = (e) => {
    this.mouseDown$.next(e.nativeEvent);
    this.props.onPointerDown();
  };
  onMouseMove = (e) => {
    this.props.onCursorPositionUpdate(e.nativeEvent);
    this.mouseMove$.next(e.nativeEvent);
  };
  onMouseLeave = (e) => {
    this.props.onCursorPositionUpdate(null);
    this.mouseUp$.next(e.nativeEvent);
  };
  onMouseUp = (e) => {
    this.mouseUp$.next(e.nativeEvent);
  };
  onContextMenu = (e) => {
    e.preventDefault();
    return false;
  };

  // Plain JS events
  onTouchStart = (e) => {
    this.touchStart$.next(e);
    this.props.onPointerDown();
  };
  onTouchMove = (e) => {
    this.touchMove$.next(e);
  };
  onTouchEnd = (e) => {
    this.touchEnd$.next(e);
  };
  onWheel = (e) => {
    e.preventDefault();
    this.wheel$.next(e);
  };

  setDrawingContext = (ref) => {
    if (!ref) return;
    this.props.setRef(ref);
    this.canvas = ref;
    this.drawingContext = ref.getContext('2d');
  };

  canvas = null;
  drawingContext = null;
  drawingAction = null;
  mouseDown$ = new Subject();
  mouseMove$ = new Subject();
  mouseUp$ = new Subject();
  touchStart$ = new Subject();
  touchMove$ = new Subject();
  touchEnd$ = new Subject();
  wheel$ = new Subject();

  subscribeToDrawing = () => {
    const start$ = merge(
      this.mouseDown$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchStart$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    ).pipe(tap(clearSelection));

    const mouseAndTouchMove$ = merge(
      this.mouseMove$.pipe(
        filter(DomEventsUtil.isLeftMouseEvent),
        map(DomEventsUtil.mouseEventToCoordinates)
      ),
      this.touchMove$.pipe(map(DomEventsUtil.touchEventToCoordinates))
    );

    const mouseAndTouchEnd$ = merge(
      this.mouseUp$.pipe(filter(DomEventsUtil.isLeftMouseEvent)),
      this.touchEnd$
    );

    const getMovesAfterStart = () =>
      repeatLastValue(
        mouseAndTouchMove$.pipe(auditTime(200), takeUntil(mouseAndTouchEnd$))
      );

    const move$ = start$.pipe(
      concatMap(() => mouseAndTouchMove$.pipe(takeUntil(mouseAndTouchEnd$)))
    );
    const moveWithRepeat$ = start$.pipe(concatMap(getMovesAfterStart));

    const stop$ = start$.pipe(concatMap(() => mouseAndTouchEnd$.pipe(take(1))));

    start$.subscribe(this.createPointer);
    move$.subscribe(this.moveLocal);
    moveWithRepeat$.subscribe(this.movePointer);

    stop$.subscribe({
      next: this.removePointer,
      complete: this.removePointer,
    });
  };

  createPointer = ({ x, y }) => {
    this.drawingContext.canvas.style.cursor = 'none';
    this.drawingContext.canvas.style.cursor = '-webkit-none';
    this.drawingContext.canvas.style.cursor = '-moz-none';

    const { userId, zoom, originX, originY, scale, isSessionLive } = this.props;
    this.drawingAction = drawingActionsFactory.createDotPointerAction(
      userId,
      (x / zoom + originX) / scale,
      (y / zoom + originY) / scale
    );

    this.props.setCurrentTemporaryAction(this.drawingAction);

    if (isSessionLive) {
      fireBaseService.createTemporaryAction(this.drawingAction);
    }
  };

  movePointer = ({ x, y }) => {
    const { zoom, originX, originY, scale, isSessionLive } = this.props;
    this.drawingAction.point = {
      x: (x / zoom + originX) / scale,
      y: (y / zoom + originY) / scale,
    };

    this.props.setCurrentTemporaryAction(this.drawingAction);

    if (isSessionLive) {
      fireBaseService.updateTemporaryAction(this.drawingAction);
    }
  };

  moveLocal = ({ x, y }) => {
    const { zoom, originX, originY, scale } = this.props;
    this.drawingAction.point = {
      x: (x / zoom + originX) / scale,
      y: (y / zoom + originY) / scale,
    };

    this.props.setCurrentTemporaryAction(this.drawingAction);
  };

  removePointer = () => {
    this.drawingContext.canvas.style.cursor = `url(${SelectCursorSvgIcon}) 0 0, default`;

    if (!this.drawingAction) return;

    const { isSessionLive } = this.props;

    const { drawingAction } = this;
    this.drawingAction = null;

    drawingAction.localEndTime = new Date();
    this.props.setCurrentTemporaryAction(null);

    if (isSessionLive) {
      fireBaseService.deleteTemporaryAction(drawingAction);
    }
  };

  subscribeToZoom = () => {
    this.wheel$
      .pipe(map(({ deltaY, layerX, layerY }) => ({ deltaY, layerX, layerY })))
      .subscribe((wheel) => this.props.onZoom(wheel));
  };

  subscribeToMove = () => {
    const start$ = this.mouseDown$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const rightMouseMove$ = this.mouseMove$.pipe(
      filter(DomEventsUtil.isRightMouseEvent),
      map(DomEventsUtil.mouseEventToCoordinates)
    );

    const mouseAndTouchMove$ = rightMouseMove$.pipe(auditTime(17));
    const mouseAndTouchEnd$ = this.mouseUp$.pipe(
      filter(DomEventsUtil.isRightMouseEvent)
    );

    const getMovesAfterStart = () =>
      mouseAndTouchMove$.pipe(
        takeUntil(mouseAndTouchEnd$),
        pairwise(),
        map(([a, b]) => ({
          deltaX: b.x - a.x,
          deltaY: b.y - a.y,
        }))
      );

    start$
      .pipe(concatMap(getMovesAfterStart))
      .subscribe((move) => this.props.onMove(move));
  };

  render() {
    const { width, height } = this.props;

    return (
      <canvas
        ref={this.setDrawingContext}
        width={width}
        height={height}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onMouseUp={this.onMouseUp}
        onContextMenu={this.onContextMenu}
      />
    );
  }
}

export default enhancer(PointerDrawingLayer);
