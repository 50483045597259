import { forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';

type ToolbarContainerProps = {
  id?: string;
  children: ReactNode;
};

const ToolbarContainer = forwardRef<HTMLDivElement, ToolbarContainerProps>(
  ({ id, children }, ref) => (
    <Box
      ref={ref}
      id={id}
      display="flex"
      borderRadius="16px"
      padding="5px 8px"
      bgcolor="#fff"
      boxShadow="0px 4px 10px 0px rgba(0, 0, 0, 0.28)"
    >
      {children}
    </Box>
  )
);

export default ToolbarContainer;
