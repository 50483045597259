import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { push } from 'redux-first-history';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PublicLinkRegistrationForm from './PublicLinkRegistrationForm';
import Dialog from '../../ui/atoms/Dialog';
import DialogTitle from '../../ui/atoms/DialogTitle';
import DialogContent from '../../ui/atoms/DialogContent/DialogContent';
import HorizontalDivider from '../../ui/atoms/HorizontalDivider';
import analyticsService from '../../common/services/analytics.service';
import authService from '../../common/services/auth.service';
import useFetch from '../../common/hooks/useFetch';

type PublicLinkRegistrationProps = {
  code: string;
};

const PublicLinkRegistration: FC<
  React.PropsWithChildren<PublicLinkRegistrationProps>
> = ({ code }) => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const {
    isLoading,
    error,
    response: publicLink,
  } = useFetch(authService.existPublicLink, code);
  const dispatch = useDispatch();
  const onSignUpClick = useCallback(async () => {
    analyticsService.event('Open Sign Up Click', {
      source: 'registration with public link',
    });
    dispatch(
      push({
        pathname: '/sign-up/student',
        state: { referrer: `/session/${publicLink?.sessionId}` },
      })
    );
  }, [publicLink]);

  if (error) {
    return <Redirect push to="/" />;
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  if (isAuthenticated && publicLink) {
    return <Redirect push to={`/session/${publicLink.sessionId}`} />;
  }

  return (
    <Dialog open disableEscapeKeyDown>
      <DialogTitle hasCloseButton={false}>Join session</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <PublicLinkRegistrationForm code={code} />
          <HorizontalDivider>OR</HorizontalDivider>
          <Typography variant="p4">
            In order to re-visit the lessons after they are finished you need to{' '}
            <a href="javascript:void(0)">
              <Typography
                color="primary"
                component="span"
                sx={{
                  fontWeight: 600,
                  lineHeight: '19.2px',
                  textDecoration: 'underline',
                }}
                onClick={onSignUpClick}
              >
                Sign up
              </Typography>
            </a>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PublicLinkRegistration;
