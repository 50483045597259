import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import FeatureTourDialog from '../../../components/BoardControls/QuickQuestion/common/FeatureTourDialog';
import FeatureTourDto from '../../../common/services/dto/featureTours/FeatureTourDto';
import useDialog from '../../../common/hooks/useDialog';
import { imageTourSelector } from '../../../common/reducers/featureToursReducer';
import analyticsService from '../../../common/services/analytics.service';
import FeatureTourType from '../../../common/services/types/featureTours/FeatureTourType';
import { useInputOnChange } from '../../../components/ImageSelectionProvider/useInputOnChange';
import styles from './OpenImageDialog.module.css';

type Props = {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

const OpenImageDialog: FC<React.PropsWithChildren<Props>> = ({
  open = true,
  onClose,
  onSubmit,
}) => {
  const featureTourData = useSelector<any, FeatureTourDto>(imageTourSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputOnChange } = useInputOnChange();

  const {
    open: featureTourDialogOpen,
    onOpen: onFeatureTourDialogOpen,
    onClose: onFeatureTourDialogClose,
  } = useDialog();

  useEffect(() => {
    if (
      featureTourData &&
      featureTourData.hasUpdates &&
      open &&
      featureTourData.steps?.length
    ) {
      onFeatureTourDialogOpen();
    }
  }, [featureTourData, onFeatureTourDialogOpen, open]);

  const onTourDialogClose = useCallback(() => {
    onFeatureTourDialogClose();
    if (onClose) {
      onClose();
    }
  }, [onFeatureTourDialogClose, onClose]);

  const onChange = useCallback(
    async (e: any) => {
      await inputOnChange(e);
      if (inputRef.current) inputRef.current.files = null;
    },
    [inputOnChange]
  );

  const featureTourDialogOnCreate = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    if (onSubmit) {
      onSubmit();
    }
    onFeatureTourDialogClose();
  }, [onFeatureTourDialogClose, onSubmit]);

  const onFeatureTourComplete = useCallback((planId: any) => {
    analyticsService.event('Image Onboarding Completed', {
      'Plan Id': planId,
    });
  }, []);

  const onFeatureTourSkip = useCallback(() => {
    analyticsService.event('Image Onboarding Skipped');
  }, []);

  return (
    <>
      <input
        ref={inputRef}
        style={{
          display: 'none',
        }}
        type="file"
        accept="image/*"
        onChange={onChange}
      />
      {featureTourData &&
        featureTourData.hasUpdates &&
        featureTourData.steps?.length > 0 &&
        featureTourData.version && (
          <FeatureTourDialog
            open={featureTourDialogOpen}
            onClose={onTourDialogClose}
            featureTourData={featureTourData}
            imageClass={styles.image}
            type={FeatureTourType.IMAGE}
            title="Introducing image Editor"
            featureAction="Import"
            onComplete={onFeatureTourComplete}
            onSkip={onFeatureTourSkip}
            onCreate={featureTourDialogOnCreate}
            steps={featureTourData.steps}
            version={featureTourData?.version || 0}
            canUseFeature
          />
        )}
    </>
  );
};

export default OpenImageDialog;
