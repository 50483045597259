import { tooltipClasses } from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import TooltipDirection from './tooltipTypes';

const generateTooltipStyle = (theme: Theme) => ({
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.BOTTOM}"] &`]: {
    marginTop: '6px',
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.TOP}"] &`]: {
    marginBottom: '6px',
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.LEFT}"] &`]: {
    marginRight: '6px',
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.RIGHT}"] &`]: {
    marginLeft: '6px',
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.BOTTOM}"] & .${tooltipClasses.arrow}`]: {
    top: '1px !important',
    '&:before': {
      backgroundColor: theme.background.bg8,
      borderTopLeftRadius: '2px',
    },
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.TOP}"] & .${tooltipClasses.arrow}`]: {
    marginBottom: '0 !important',
    bottom: '-19px !important',
    left: '-3px !important',
    width: '20px',
    height: '20px',
    '&:before': {
      backgroundColor: theme.background.bg8,
      width: '20px',
      height: '10px',
      borderBottomRightRadius: '2px',
    },
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.LEFT}"] & .${tooltipClasses.arrow}`]: {
    right: '-12px !important',
    top: '-4px !important',
    marginRight: '0 !important',
    width: '20px !important',
    height: '20px !important',
    '&:before': {
      backgroundColor: theme.background.bg8,
      borderBottomLeftRadius: '2px',
      borderTopRightRadius: '2px',
    },
  },
  [`.${tooltipClasses.popper}[data-popper-placement*="${TooltipDirection.RIGHT}"] & .${tooltipClasses.arrow}`]: {
    left: '-4px !important',
    top: '-3px !important',
    width: '20px !important',
    height: '20px !important',
    '&:before': {
      backgroundColor: theme.background.bg8,
      width: '10px',
      height: '20px',
      borderBottomLeftRadius: '2px',
      borderTopRightRadius: '2px',
    },
  },
});

export default generateTooltipStyle;
