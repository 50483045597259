import { FC, useCallback, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Box from '@mui/material/Box';
import Dialog from '../../../ui/atoms/Dialog';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../../ui/atoms/DialogActions';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import { Dimensions } from '../../../common/utils/image.utils';

type ImageDrawingTool = {
  image: HTMLImageElement;
  width: number;
  height: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  srcDimensions: Dimensions;
  insertImage: ({ image, width, height }: ImageDrawingTool) => void;
};

const ImageCropDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  imageUrl,
  srcDimensions,
  insertImage,
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [croppedImage, setCroppedImage] = useState<Crop>({
    x: 0,
    y: 0,
    unit: 'px',
    width: imgRef.current?.width || 0,
    height: imgRef.current?.height || 0,
  });

  const onLoad = useCallback(
    (img: HTMLImageElement) => {
      imgRef.current = img;
      imgRef.current.crossOrigin = 'anonymous';

      setCroppedImage({
        x: 0,
        y: 0,
        unit: 'px',
        width: img.width,
        height: img.height,
      });

      return false; // Per package author: Return false if you set crop state in here.
    },
    [setCroppedImage]
  );

  const getCroppedImg = useCallback(() => {
    if (!croppedImage || !imgRef.current) return null;

    const imageRef = imgRef.current;
    const canvas = document.createElement('canvas');
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) return null;

    canvas.width = croppedImage.width * scaleX;
    canvas.height = croppedImage.height * scaleY;

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      imageRef,
      croppedImage.x * scaleX,
      croppedImage.y * scaleY,
      croppedImage.width * scaleX,
      croppedImage.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const dataUrl = canvas.toDataURL();

    return {
      dataUrl,
      width: (croppedImage.width * srcDimensions.width) / imageRef.width,
      height: (croppedImage.height * srcDimensions.height) / imageRef.height,
    };
  }, [croppedImage, srcDimensions]);

  const onSubmit = useCallback(() => {
    const result = getCroppedImg();

    if (!result) return;

    const { width, height, dataUrl } = result;

    const img = document.createElement('img');
    img.onload = () => {
      insertImage({
        image: img,
        width,
        height,
      });
      onClose();
    };

    img.width = width;
    img.height = height;
    img.src = dataUrl;
  }, [insertImage, onClose, getCroppedImg]);

  const cropOnChange = useCallback(
    (c: any) => {
      setCroppedImage(c);
    },
    [setCroppedImage]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // keep image in dialog with keepMounted method when dialog is closing
      keepMounted
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title" onClose={onClose}>
        Crop Image
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          bgcolor="rgba(0, 0, 0, 0.08)"
          borderRadius={3.5}
        >
          <ReactCrop
            src={imageUrl}
            minWidth={20}
            minHeight={20}
            onImageLoaded={onLoad}
            crop={croppedImage}
            onChange={cropOnChange}
            imageStyle={{
              maxHeight: '600px',
              maxWidth: '600px',
            }}
            keepSelection
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onSubmit}>
          Import
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropDialog;
