import HorizontalResizer from './HorizontalResizer';

export default class LeftResizer extends HorizontalResizer {
  center() {
    const x = Math.min(this.startPoint.x, this.endPoint.x);
    return {
      x: x - this.padding,
      y: (this.startPoint.y + this.endPoint.y) / 2,
    };
  }

  onResize({ x }) {
    if (this.endPoint.x > this.startPoint.x) {
      return {
        startPoint: {
          x: Math.min(x + this.padding, this.endPoint.x),
          y: this.startPoint.y,
        },
        endPoint: { ...this.endPoint },
      };
    }

    return {
      startPoint: { ...this.startPoint },
      endPoint: {
        x: Math.min(x + this.padding, this.startPoint.x),
        y: this.endPoint.y,
      },
    };
  }
}
