import { FC } from 'react';
import LoadingCard from '../LoadingCard';

type Props = {
  counts: number;
  hasThumbnail?: boolean;
};

const SkeletonGrid: FC<React.PropsWithChildren<Props>> = ({
  counts,
  hasThumbnail = false,
}) => (
  <>
    {[...Array(counts)].map((_, index) => (
      <LoadingCard key={index} hasThumbnail={hasThumbnail} />
    ))}
  </>
);

export default SkeletonGrid;
