import {
  FC,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { typographyClasses } from '@mui/material/Typography';
import FormHelperText, {
  formHelperTextClasses,
} from '@mui/material/FormHelperText';
import MuiInput, { inputClasses, InputProps } from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import Typography from '../Typography';
import generateInputStyle from './generateInputStyle';
import InputSize from './InputSize';
import InputVariant from './InputVariant';
import InputStatus from './InputStatus';
import { Modify } from '../../../common/types/ModifyType';

type Props = Modify<
  Omit<InputProps, 'type'>,
  {
    labelIcon?: ReactElement;
    size: InputSize;
    icon?: ReactElement;
    variant: InputVariant;
    status?: InputStatus;
    label?: string;
    helperText?: string;
    limit?: number;
    placeholder?: string;
    value?: number | string;
    disabled?: boolean;
    onChange?: (value: number) => void;
    endAdornmentPrimaryIcon?: ReactElement;
  }
>;

export const NumberInput: FC<React.PropsWithChildren<Props>> = forwardRef(
  (
    {
      labelIcon,
      size,
      icon,
      variant,
      status,
      label,
      helperText,
      limit,
      placeholder,
      value = '',
      disabled = false,
      onChange = () => {},
      endAdornmentPrimaryIcon,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const generateStyles = useCallback(
      (theme: any) => generateInputStyle(theme, variant, status, false),
      [variant, status, inputValue]
    );
    const inputOnChange = useCallback(
      (e: any) => {
        if (limit && e.target.value.length > limit) {
          e.target.value = e.target.value.substring(
            0,
            e.target.value.length - 1
          );
          return;
        }
        setInputValue(e.target.value);
        onChange(e.target.value);
      },
      [setInputValue, limit, onChange]
    );

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <FormControl
        sx={(theme) => ({
          width: '100%',
          [`& .${inputClasses.input}`]: {
            padding: 0,
            height: 'unset',
          },
          [`& .input-${InputSize.L.toUpperCase()}`]: {
            [`& .${inputClasses.root}`]: {
              borderWidth: '2px',
              borderRadius: '18px',
              padding: '14px 14px',
              fontSize: '18px',
              [`.${svgIconClasses.root}`]: {
                fontSize: '22px',
              },
            },
            [`& .${inputClasses.input}::-webkit-input-placeholder`]: {
              fontSize: '18px',
            },
            [`& .${inputLabelClasses.root} .${typographyClasses.root}`]: {
              fontSize: '16px',
              fontWeight: '500',
              fontFamily: 'Barlow',
            },
            [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
              fontSize: '18px',
            },
          },
          [`& .input-${InputSize.M.toUpperCase()}`]: {
            [`& .${inputClasses.root}`]: {
              borderWidth: '2px',
              borderRadius: '16px',
              padding: '8px 14px',
              fontSize: '16px',
              [`.${svgIconClasses.root}`]: {
                fontSize: '20px',
              },
            },
            [`& .${inputClasses.input}::-webkit-input-placeholder`]: {
              fontSize: '18px',
            },
            [`& .${inputLabelClasses.root} .${typographyClasses.root}`]: {
              fontSize: '16px',
              fontWeight: '500',
              fontFamily: 'Barlow',
            },
            [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
              fontSize: '18px',
            },
          },
          [`& .input-${InputSize.S.toUpperCase()}`]: {
            [`& .${inputClasses.root}`]: {
              borderStyle: 'solid',
              borderWidth: '1px',
              marginTop: '0px',
              padding: '8px 14px',
              borderRadius: '14px',
              fontSize: '16px',
              [`.${svgIconClasses.root}`]: {
                fontSize: '18px',
              },
            },
            [`& .${inputClasses.input}::-webkit-input-placeholder`]: {
              fontSize: '16px',
            },
            [`& .${inputLabelClasses.root} .${typographyClasses.root}`]: {
              fontSize: '14px',
              fontWeight: '500',
              fontFamily: 'Barlow',
            },
            [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
              fontSize: '16px',
            },
          },
          [`& .${inputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t6,
            fontWeight: 500,
            opacity: 1,
          },
          [`& .${formHelperTextClasses.root}`]: {
            color: status ? theme.palette[status].main : theme.text.t8,
            fontSize: '12px',
            fontWeight: '500',
            fontFamily: 'Barlow',
          },
          [`& .${inputLabelClasses.root}`]: {
            color: theme.text.t8,
          },
          [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
            color: theme.text.t6,
          },
          '&': {},
          ...(disabled && {
            [`.${formHelperTextClasses.root}`]: {
              color: `${theme.text.t3} !important`,
            },
            [`.${inputLabelClasses.root} .${typographyClasses.root}`]: {
              color: `${theme.text.t3} !important`,
            },
            [`.${inputLabelClasses.root} .${svgIconClasses.root}`]: {
              color: theme.text.t3,
            },
          }),
          ...generateStyles(theme),
        })}
        variant="outlined"
      >
        <Box className={`input-${size.toUpperCase()}`}>
          {(label || labelIcon) && (
            <InputLabel
              sx={{
                width: '100%',
                margin: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                transform: 'none',
                position: 'inherit',
                mb: 0.5,
              }}
            >
              {label && <Typography>{label}</Typography>}
              {labelIcon}
            </InputLabel>
          )}
          <MuiInput
            {...props}
            type="number"
            inputRef={ref}
            disableUnderline
            fullWidth
            onChange={inputOnChange}
            startAdornment={
              icon && <InputAdornment position="start">{icon}</InputAdornment>
            }
            endAdornment={
              <Box display="flex" sx={{ cursor: 'pointer' }}>
                {endAdornmentPrimaryIcon}
              </Box>
            }
            value={inputValue}
            placeholder={placeholder}
            disabled={disabled}
          />
          {(helperText || limit) && (
            <FormHelperText
              className="statusTextStyle"
              sx={{
                margin: '0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 0.5,
              }}
            >
              <span>{helperText || ''}</span>
            </FormHelperText>
          )}
        </Box>
      </FormControl>
    );
  }
);
