import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import Box from '@mui/material/Box';
import './DrawingTools.scss';
import PenTool from './ToolItems/PenTool';
import ShapeTool from './ToolItems/ShapeTool';
import EraserTool from './ToolItems/EraserTool';
import TextTool from './ToolItems/TextTool';
import UndoAction from '../../common/drawingActions/undo.action';
import RedoAction from '../../common/drawingActions/redo.action';
import FunctionTool from './ToolItems/FunctionTool';
import * as drawingActionsActions from '../../common/actions/board/drawingActionsActions';
import * as drawingToolActions from '../../common/actions/board/drawingToolActions';
import {
  getActiveTools,
  getSelectedDrawingTool,
} from '../../common/reducers/board/drawingToolReducer';
import * as drawingActionsReducer from '../../common/reducers/board/drawingActionsReducer';
import { hexToRgb, rgbToArgbNumber } from '../../common/utils/color.utils';
import ToolNames from './ToolNames';
import Color from '../../common/utils/types/color/Color.interface';
import useKeyboardShortcuts from '../../common/hooks/useKeyboardShortcuts';
import keyMap from '../../config/hotkeys';
import OpenImageDialog from '../../containers/Modals/OpenImageDialog';
import BoardType from '../../common/services/types/session/BoardType';
import { boardTypeSelector } from '../../common/reducers/session/sessionReducer';
import SessionContext from '../Session/SessionContext';
import UnleashFlags from '../../common/constants/UnleashFlag';
import UploadFileTool from './ToolItems/UploadFileTool';
import useBoardRatio from '../../common/hooks/useBoardRatio';
import PointerTool from './ToolItems/PointerTool';
import SelectTool from './ToolItems/SelectTool';

const DrawingTools = () => {
  const selectedTool = useSelector(getSelectedDrawingTool);
  const activeTools = useSelector(getActiveTools);
  const canUndo = useSelector(drawingActionsReducer.canUndo);
  const canRedo = useSelector(drawingActionsReducer.canRedo);
  const boardType = useSelector(boardTypeSelector);
  const { openInfiniteDialog } = useContext(SessionContext);
  const [imageOnBoardingOpen, setImageOnBoardingOpen] = useState(false);
  const dispatch = useDispatch();
  const ratio = useBoardRatio();

  const imageRotationEnabled = useFlag(UnleashFlags.IMAGE_ROTATION);

  const selectTool = useCallback(
    (name: string) => {
      dispatch(drawingToolActions.selectDrawingTool(name));
    },
    [dispatch]
  );

  const updateTool = useCallback(
    (name: string, props: { [key: string]: any }) => {
      dispatch(drawingToolActions.updateDrawingTool(name, props));
    },
    [dispatch]
  );

  const addActionAndSend = useCallback(
    (action: { [key: string]: any }) => {
      dispatch(drawingActionsActions.addActionAndSend(action));
    },
    [dispatch]
  );

  const changeImageOnboardingOpenStatus = useCallback(() => {
    if (!imageRotationEnabled) return;

    setImageOnBoardingOpen((status) => !status);
  }, [imageRotationEnabled, setImageOnBoardingOpen]);

  const imageUploadRef = useRef<HTMLInputElement | null>(null);

  const sendUndoAction = useCallback(() => {
    if (!canUndo) return;

    const action = new UndoAction();
    addActionAndSend(action);
  }, [canUndo, addActionAndSend]);

  const sendRedoAction = useCallback(() => {
    if (!canRedo) return;

    const action = new RedoAction();
    addActionAndSend(action);
  }, [canRedo, addActionAndSend]);

  const updateColor = useCallback(
    (tool: string) => (color: Color) => {
      const { r, g, b } = hexToRgb(color);
      const alpha = tool === ToolNames.Marker ? 0.4 : 1;
      const argbColor = rgbToArgbNumber({
        a: alpha * 255,
        r,
        g,
        b,
      });
      updateTool(tool, { color: argbColor });
    },
    [updateTool]
  );

  const updateFontSize = useCallback(
    (tool: string) => (fontSize: number) => {
      updateTool(tool, {
        size: {
          ...selectedTool.size,
          value: fontSize * ratio,
          originValue: fontSize,
        },
      });
    },
    [updateTool, selectedTool, ratio]
  );

  const hotkeyHandlers = useMemo(
    () => ({
      arrow: () => {
        selectTool(ToolNames.Arrow);
      },
      eraser: () => {
        selectTool(ToolNames.Eraser);
      },
      shape: () => {
        selectTool(activeTools.shape);
      },
      image: () => {
        if (selectedTool.name === ToolNames.Image) return;
        if (imageUploadRef.current) {
          imageUploadRef.current.click();
        }
      },
      marker: () => {
        selectTool(ToolNames.Marker);
      },
      line: () => {
        selectTool(ToolNames.Line);
      },
      pen: () => {
        selectTool(ToolNames.Pen);
      },
      pointer: () => {
        selectTool(ToolNames.Pointer);
      },
      text: () => {
        if (boardType === BoardType.INFINITE) {
          openInfiniteDialog();
          return;
        }

        selectTool(ToolNames.Text);
      },
      formula: () => {
        selectTool(ToolNames.Formula);
      },
      graph: () => {
        selectTool(ToolNames.Graph);
      },
      undo: () => {
        sendUndoAction();
      },
      redo: (event: any) => {
        event.preventDefault();
        sendRedoAction();
      },
    }),
    [
      selectTool,
      activeTools,
      selectedTool,
      sendUndoAction,
      sendRedoAction,
      boardType,
      openInfiniteDialog,
    ]
  );

  useKeyboardShortcuts(keyMap, hotkeyHandlers);

  const toolProps = {
    selectedTool,
    updateTool,
    selectTool,
    addActionAndSend,
    updateColor,
    updateFontSize,
  };

  return (
    <>
      <Box
        className="product-tour-step-2"
        sx={(theme) => ({
          backgroundColor: theme.background.bg1,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mr: 1,
        })}
      >
        <SelectTool {...toolProps} />
        <PointerTool {...toolProps} />
        <PenTool {...toolProps} activeTool={activeTools.pen} />
        <ShapeTool {...toolProps} activeTool={activeTools.shape} />
        <TextTool {...toolProps} />
        <FunctionTool {...toolProps} activeTool={activeTools.function} />
        <EraserTool {...toolProps} />
        <UploadFileTool
          {...toolProps}
          imageReference={imageUploadRef}
          onboardingChange={changeImageOnboardingOpenStatus}
        />
      </Box>
      {imageOnBoardingOpen && <OpenImageDialog />}
    </>
  );
};

export default DrawingTools;
