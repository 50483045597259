import { httpService, httpServiceV4 }  from './http.service';

const apiGroupsPart = '/groups';

const getGroupList = () => httpServiceV4.get(apiGroupsPart);

const getGroup = id => httpService.get(`${apiGroupsPart}/${id}`);

const createGroup = (name, contactEmails, contactIds) => httpServiceV4.post(apiGroupsPart, { name: name.trim(), contactEmails, contactIds });

const editGroup = (id, name, addContactEmails, addContactIds, removeContacts) => httpServiceV4.put(`${apiGroupsPart}/${id}`, { name: name.trim(), addContactEmails, addContactIds, removeContacts });

const deleteGroup = (id, deleteBoards = false) => {
  const params = new URLSearchParams();
  params.append('deleteBoards', deleteBoards.toString());

  return httpService.delete(`${apiGroupsPart}/${id}`, { params });
};

const addGroupContacts = (id, contactEmails = [], contactIds = []) => httpServiceV4.post(`${apiGroupsPart}/${id}/contacts`, { contactEmails, contactIds });

export default {
  getGroupList,
  getGroup,
  createGroup,
  editGroup,
  deleteGroup,
  addGroupContacts,
};
