import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { typographyClasses } from '@mui/material/Typography';

const selectStyles = (theme: Theme, active: boolean) =>
  active
    ? {
        padding: '11px',
        color: theme.palette.primary.dark5,
        backgroundColor: theme.palette.primary.alpha10,
        '&:hover': {
          backgroundColor: theme.palette.primary.alpha20,
        },
        [`& .${typographyClasses.root}`]: {
          color: theme.palette.primary.dark5,
        },
      }
    : {
        padding: '10px',
        border: `1px solid ${theme.palette.primary.alpha20}`,
        color: theme.text.t8,
        [`& .${typographyClasses.root}`]: {
          color: theme.text.t8,
        },
        '&:hover': {
          color: theme.palette.primary.main,
          [`& .${typographyClasses.root}`]: {
            color: theme.palette.primary.main,
          },
        },
      };

type Props = {
  active: boolean;
  onClick: (event: any) => void;
  content: ReactNode;
};

const SelectButton: FC<React.PropsWithChildren<Props>> = ({
  active = false,
  onClick,
  content,
}) => (
  <Box
    onClick={onClick}
    sx={(t) => ({
      cursor: 'pointer',
      display: 'flex',
      borderRadius: '14px',
      height: '40px',
      ...selectStyles(t, active),
    })}
  >
    {content}
  </Box>
);

export default SelectButton;
