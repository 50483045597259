import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useStopwatch } from 'react-timer-hook';
import { padStart } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import StopIcon from '@mui/icons-material/Stop';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import IconButton from '../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import PauseIcon from '../../../ui/atoms/Icons/PauseIcon';
import Typography from '../../../ui/atoms/Typography';
import useDialog from '../../../common/hooks/useDialog';
import RecordingContext from '../../Recording/RecordingContext';
import analyticsService from '../../../common/services/analytics.service';
import BoardType from '../../../common/services/types/session/BoardType';
import SessionContext from '../../Session/SessionContext';
import UpgradeDialog from '../../../ui/molecules/UpgradeDialog';
import PlanDialog from '../../../ui/molecules/PlanDialog';
import { canCreateNewRecordingSelector } from '../../../common/selectors/planPermissionSelectors';
import { recordingWatermarkInfoSelector } from '../../../common/reducers/configurationReducer';

type RecordingControlsProps = {
  collaborationBarExpanded: boolean;
  tooltipPlacment: 'top' | 'left';
  boardType: BoardType;
};

const RecordingControls: FC<
  React.PropsWithChildren<RecordingControlsProps>
> = ({ collaborationBarExpanded, tooltipPlacment, boardType }) => {
  const canCreateNewRecording = useSelector(canCreateNewRecordingSelector);
  const watermarkInfo = useSelector(recordingWatermarkInfoSelector);
  const [isPause, setIsPause] = useState(false);

  const {
    seconds,
    minutes,
    start: resumeTimer,
    pause: pauseTimer,
    reset: resetTimer,
  } = useStopwatch({
    autoStart: false,
  });
  const {
    open: upgradeDialogOpen,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const {
    isRecording,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useContext(RecordingContext);
  const { openInfiniteDialog } = useContext(SessionContext);

  const onStopRecordingClick = useCallback(() => {
    analyticsService.event('Stop Recording Button Click', {
      source: 'Collaboration',
    });
    stopRecording();
    resetTimer(new Date(), false);
  }, [stopRecording, resetTimer]);

  const onPauseRecordingClick = useCallback(() => {
    analyticsService.event('Pause Recording Button Click', {
      source: 'Collaboration',
    });
    setIsPause(true);
    pauseRecording();
    pauseTimer();
  }, [setIsPause, pauseRecording, pauseTimer]);

  const onResumeRecording = useCallback(() => {
    analyticsService.event('Resume Recording Button Click', {
      source: 'Collaboration',
    });
    resumeRecording();
    resumeTimer();
  }, [resumeTimer, resumeRecording]);

  const onUpdateRecordingState = useCallback(() => {
    if (isPause) {
      onResumeRecording();
      setIsPause(false);

      return;
    }

    onPauseRecordingClick();
  }, [isPause, onPauseRecordingClick, onResumeRecording, setIsPause]);

  const onClick = useCallback(async () => {
    if (boardType === BoardType.INFINITE) {
      openInfiniteDialog();
      return;
    }

    analyticsService.event('Recording Button Click', {
      source: 'Collaboration',
    });
    if (!canCreateNewRecording) {
      analyticsService.event('Create Recording above Limit');
      onUpgradeDialogOpen();
      return;
    }

    resetTimer();

    await startRecording(watermarkInfo);
    setIsPause(false);
  }, [
    canCreateNewRecording,
    onUpgradeDialogOpen,
    startRecording,
    watermarkInfo,
    boardType,
    openInfiniteDialog,
    setIsPause,
  ]);

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogOpen();
  }, [onPlansDialogOpen, onUpgradeDialogOpen]);

  const minutesPad = useMemo(() => padStart(`${minutes}`, 2, '0'), [minutes]);
  const secondsPad = useMemo(() => padStart(`${seconds}`, 2, '0'), [seconds]);

  return (
    <>
      {isRecording ? (
        <Box
          display="flex"
          alignItems="center"
          flexDirection={collaborationBarExpanded ? 'row' : 'column'}
          gap={0.5}
        >
          <Tooltip
            title={!isPause ? 'Pause' : 'Play'}
            placement={tooltipPlacment}
          >
            <Box>
              <IconButton
                onClick={onUpdateRecordingState}
                variant={ButtonVariantTypes.SECONDARY}
                size={ButtonSizeTypes.S}
                shape={ButtonShapeTypes.ROUNDED}
              >
                {isPause ? (
                  <RadioButtonCheckedIcon
                    sx={(theme) => ({
                      color: theme.status.new.main,
                    })}
                  />
                ) : (
                  <PauseIcon
                    sx={(theme) => ({
                      color: theme.text.t9,
                    })}
                  />
                )}
              </IconButton>
            </Box>
          </Tooltip>
          {collaborationBarExpanded && (
            <Box display="flex" justifyContent="center" width={40}>
              <Typography
                variant="p5"
                sx={(theme) => ({
                  color: theme.text.t7,
                })}
              >
                {minutesPad}:{secondsPad}
              </Typography>
            </Box>
          )}
          <Tooltip title="Stop" placement={tooltipPlacment}>
            <Box>
              <IconButton
                variant={ButtonVariantTypes.SECONDARY}
                size={ButtonSizeTypes.S}
                onClick={onStopRecordingClick}
                shape={ButtonShapeTypes.ROUNDED}
              >
                <StopIcon
                  sx={(theme) => ({
                    color: theme.status.new.main,
                  })}
                />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      ) : (
        <Tooltip title="Start Recording" placement={tooltipPlacment}>
          <Box>
            <IconButton
              onClick={onClick}
              variant={ButtonVariantTypes.SECONDARY}
              size={ButtonSizeTypes.S}
              shape={ButtonShapeTypes.ROUNDED}
            >
              <RadioButtonCheckedIcon
                sx={(theme) => ({
                  width: 18,
                  height: 18,
                  color: theme.status.new.main,
                })}
              />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <UpgradeDialog
        open={upgradeDialogOpen}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source="Local Recording"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </>
  );
};

export default memo(RecordingControls);
