import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CreateCard from '../CreateCard';
import PlusIcon from '../../atoms/Icons/PlusIcon';
import { cardDate } from '../../../common/utils/date.utils';
import { isFetchingSelector } from '../../../common/reducers/groupsReducer';
import useDialog from '../../../common/hooks/useDialog';
import GroupCard from '../GroupCard';
import { userSelector } from '../../../common/reducers/userReducer';
import { contactListSelector } from '../../../common/reducers/contactsReducer';
import RenameGroupDialog from '../RenameGroupDialog';
import CreateGroupDialog from '../CreateGroupDialog';
import { canCreateGroupSelector } from '../../../common/selectors/planPermissionSelectors';
import UpgradeDialog from '../UpgradeDialog';
import PlanDialog from '../PlanDialog';
import SkeletonGrid from '../SkeletonGrid';
import { CardGridItem } from '../../atoms/CardGridItem';

type Props = {
  sortingGroups: (cards: any[]) => any[];
  groups: any[];
};

const GroupGrid: FC<React.PropsWithChildren<Props>> = ({
  sortingGroups,
  groups,
}) => {
  const isFetching = useSelector(isFetchingSelector);
  const user = useSelector(userSelector);
  const contactsList = useSelector(contactListSelector);
  const canCreateGroup = useSelector(canCreateGroupSelector);

  // Todo: Add group, groupList type know it is null
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);

  const {
    open: renameDialogOpen,
    onOpen: onRenameDialogOpen,
    onClose: onRenameDialogClose,
  } = useDialog();

  const {
    open: createGroupDialog,
    onOpen: onCreateGroupDialogOpen,
    onClose: onCreateGroupDialogClose,
  } = useDialog();

  const {
    open: upgradeDialog,
    onOpen: onUpgradeDialogOpen,
    onClose: onUpgradeDialogClose,
  } = useDialog();

  const {
    open: plansDialogOpen,
    onOpen: onPlansDialogOpen,
    onClose: onPlansDialogClose,
  } = useDialog();

  const sortedGroups = useMemo(
    () => sortingGroups(groups),
    [groups, sortingGroups]
  );

  const selectGroupById = useCallback(
    (cardId: any) => {
      const card = sortedGroups.find((c: { id: any }) => c.id === cardId);
      setSelectedGroup(card);
    },
    [sortedGroups, setSelectedGroup]
  );

  const onRename = useCallback(
    (cardId: any) => {
      selectGroupById(cardId);
      onRenameDialogOpen();
    },
    [selectGroupById, onRenameDialogOpen]
  );

  const getGroupOwnerName = useCallback(
    (groupOwnerId: any) => {
      if (user.id === groupOwnerId) return 'My group';

      const filteredContact = contactsList.filter(
        (contact: any) => contact.id === groupOwnerId
      );

      return filteredContact[0].name;
    },
    [user.id, contactsList]
  );

  const onUpgradeClick = useCallback(() => {
    onPlansDialogOpen();
    onUpgradeDialogClose();
  }, [onPlansDialogOpen, onUpgradeDialogClose]);

  const onCreateGroupClick = useCallback(() => {
    if (canCreateGroup) {
      onCreateGroupDialogOpen();

      return;
    }

    onUpgradeDialogOpen();
  }, [canCreateGroup, onUpgradeDialogOpen, onCreateGroupDialogOpen]);

  return (
    <>
      <Box
        sx={(t) => ({
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          [t.breakpoints.up('sm')]: {
            gap: '16px',
          },
          [t.breakpoints.up('md')]: {
            gap: '28px',
          },
        })}
      >
        {!isFetching && (
          <>
            <CardGridItem>
              <CreateCard
                Icon={PlusIcon}
                title="New group"
                width="100%"
                height="122px"
                onClick={onCreateGroupClick}
              />
            </CardGridItem>
            {sortedGroups.map((group: any) => (
              <GroupCard
                id={group.id}
                userId={user.id}
                ownerId={group.ownerId}
                key={group.id}
                groupName={group.name}
                groupOwnerName={getGroupOwnerName(group.ownerId)}
                date={cardDate(group.createdAt)}
                onRename={onRename}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                selectGroupById={selectGroupById}
              />
            ))}
          </>
        )}
        {isFetching && <SkeletonGrid counts={3} />}
      </Box>
      <CreateGroupDialog
        open={createGroupDialog}
        onClose={onCreateGroupDialogClose}
      />
      <RenameGroupDialog
        open={renameDialogOpen}
        onClose={() => {
          setSelectedGroup(null);
          onRenameDialogClose();
        }}
        group={selectedGroup}
      />
      <UpgradeDialog
        open={upgradeDialog}
        onCancel={onUpgradeDialogClose}
        onUpgrade={onUpgradeClick}
      />
      <PlanDialog
        source="Create Group"
        open={plansDialogOpen}
        onClose={onPlansDialogClose}
      />
    </>
  );
};

export default GroupGrid;
