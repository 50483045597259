import { FC, MouseEventHandler, useCallback, useState } from 'react';
import { buttonClasses } from '@mui/material/Button';
import Collapse, { collapseClasses } from '@mui/material/Collapse';
import Button from '../Button';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../Button/buttonTypes';
import PlayOutlineIcon from '../Icons/PlayOutlineIcon';
import PlayIcon from '../Icons/PlayIcon';

export enum PlayButtonVariant {
  WHITE = 'white',
  GREEN = 'green',
}

type PlayButtonProps = {
  variant: PlayButtonVariant;
  onClick?: MouseEventHandler;
};

const PlayButton: FC<React.PropsWithChildren<PlayButtonProps>> = ({
  variant,
  onClick,
}) => {
  const [hoverState, setHoverState] = useState(false);
  const hoverCallBack = useCallback(() => {
    setHoverState((prevState) => !prevState);
  }, [setHoverState]);

  return (
    <Button
      onClick={onClick}
      variant={ButtonVariantTypes.PRIMARY}
      size={ButtonSizeTypes.XS}
      color={ButtonColorTypes.PRIMARY}
      startIcon={
        variant === PlayButtonVariant.WHITE ? <PlayOutlineIcon /> : <PlayIcon />
      }
      sx={(theme) => ({
        minWidth: 'auto',
        padding: '2px',
        whiteSpace: 'nowrap',
        borderRadius: '70px',
        ...(variant === PlayButtonVariant.WHITE
          ? {
              backgroundColor: theme.background.bg2,
              borderColor: theme.background.bg2,
              color: 'primary.dark5',
              path: {
                fill: 'primary.main',
              },
              '&:hover': {
                backgroundColor: theme.background.bg2,
                borderColor: theme.background.bg2,
                color: 'primary.dark5',
              },
            }
          : {
              backgroundColor: 'primary.main',
              color: theme.text.t1,
              path: {
                fill: theme.text.t1,
              },
              '&:hover': {
                backgroundColor: 'primary.main',
                borderColor: 'primary.main',
                color: theme.text.t1,
              },
            }),
        [`& .${buttonClasses.startIcon}`]: {
          margin: 0,
        },
      })}
      onMouseEnter={hoverCallBack}
      onMouseLeave={hoverCallBack}
    >
      <Collapse
        sx={{
          [`& .${collapseClasses.horizontal}`]: {
            paddingLeft: '2px',
            paddingRight: '5px',
          },
        }}
        orientation="horizontal"
        in={hoverState}
      >
        Play
      </Collapse>
    </Button>
  );
};

export default PlayButton;
