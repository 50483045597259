import * as ellipseUtils from '../../../../common/utils/shapes/oval.util';
import { toColor } from '../../../../common/utils/color.utils';

const ellipseDrawer = (ctx, item) => {
  const {
    startPoint,
    endPoint,
    paint,
  } = item;
  const {
    color,
    fill,
    strokeWidth,
  } = paint;
  const rgbaColor = toColor(color);
  const center = ellipseUtils.getCenter(startPoint, endPoint);
  const radii = ellipseUtils.getRadii(startPoint, endPoint);

  ctx.save();
  ctx.beginPath();
  ctx.ellipse(center.x, center.y, radii.x, radii.y, 0, 0, 2 * Math.PI);

  if (fill) {
    ctx.fillStyle = rgbaColor;
    ctx.fill();
  } else {
    ctx.strokeStyle = rgbaColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
  }
  ctx.restore();
};

export default ellipseDrawer;
