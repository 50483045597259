import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import UndoIcon from '../../../ui/atoms/Icons/UndoIcon';
import RedoIcon from '../../../ui/atoms/Icons/RedoIcon';
import UndoAction from '../../../common/drawingActions/undo.action';
import * as drawingActionsReducer from '../../../common/reducers/board/drawingActionsReducer';
import * as drawingActionsActions from '../../../common/actions/board/drawingActionsActions';
import RedoAction from '../../../common/drawingActions/redo.action';
import keyMap from '../../../config/hotkeys';
import Tooltip from '../../../ui/atoms/Tooltip';
import analyticsService from '../../../common/services/analytics.service';

const UndoRedoTool = () => {
  const canUndo = useSelector(drawingActionsReducer.canUndo);
  const canRedo = useSelector(drawingActionsReducer.canRedo);

  const dispatch = useDispatch();

  const addActionAndSend = useCallback(
    (action: { [key: string]: any }) => {
      dispatch(drawingActionsActions.addActionAndSend(action));
    },
    [dispatch]
  );

  const onUndoClick = useCallback(() => {
    if (!canUndo) return;

    analyticsService.event('Tool Selected', {
      tool: 'undo',
    });
    const action = new UndoAction();
    addActionAndSend(action);
  }, [canUndo, addActionAndSend]);

  const onRedoClick = useCallback(() => {
    if (!canRedo) return;

    analyticsService.event('Tool Selected', {
      tool: 'redo',
    });
    const action = new RedoAction();
    addActionAndSend(action);
  }, [canRedo, addActionAndSend]);

  return (
    <Box display="flex">
      <Tooltip title={`Undo (${keyMap.undo.description})`} placement="top">
        <Box>
          <IconButton
            onClick={onUndoClick}
            size={ButtonSizeTypes.S}
            variant={ButtonVariantTypes.TEXT}
            disabled={!canUndo}
            shape={ButtonShapeTypes.ROUNDED}
          >
            <UndoIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title={`Redo (${keyMap.redo.description})`} placement="top">
        <Box>
          <IconButton
            onClick={onRedoClick}
            size={ButtonSizeTypes.S}
            variant={ButtonVariantTypes.TEXT}
            disabled={!canRedo}
            shape={ButtonShapeTypes.ROUNDED}
          >
            <RedoIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default UndoRedoTool;
