import CircleUtil from '../../../../common/utils/shapes/circle.util';
import { toColor } from '../../../../common/utils/color.utils';

const circleDrawer = (ctx, item) => {
  const {
    startPoint,
    endPoint,
    paint,
  } = item;
  const {
    color,
    fill,
    strokeWidth,
  } = paint;
  const rgbaColor = toColor(color);
  const radius = CircleUtil.getRadius(startPoint, endPoint);
  ctx.save();
  ctx.beginPath();
  ctx.arc(startPoint.x, startPoint.y, radius, 0, 2 * Math.PI, false);

  if (fill) {
    ctx.fillStyle = rgbaColor;
    ctx.fill();
  } else {
    ctx.strokeStyle = rgbaColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
  }
  ctx.restore();
};

export default circleDrawer;
