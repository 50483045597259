import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import environment from './environment';

if (environment.sentry.enabled()) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
