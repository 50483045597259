import React from 'react';
import Box from '@mui/material/Box';
import PagesSideBar from './PagesSideBar/PagesSideBar';
import PagesStepper from './PagesStepper';

export default ({ opened, onToggle, editable }) => (
  <Box display="flex" alignItems="center">
    <PagesStepper key="pages-button" onClick={onToggle} />
    <PagesSideBar
      editable={editable}
      key="pages-sidebar"
      opened={opened}
      onClose={onToggle}
    />
  </Box>
);
