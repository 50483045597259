import { FC, ReactElement, useContext, useEffect, useMemo } from 'react';
import { Track, VideoTrack, Participant, Room } from 'livekit-client';
import { AudioRenderer, RoomState } from '@livekit/react-core';
import { DisplayContext, ControlsProps } from '@livekit/react-components';
import Box from '@mui/material/Box';
import { GridStage } from './GridStage';
import { ParticipantProps } from './ParticipantRenderer';
import { SpeakerStage } from './SpeakerStage';
import DeviceContext from '../BoardControls/CollaborationTools/DeviceContext/DeviceContext';
import { noop } from '../../common/constants';

export interface StageProps {
  roomState: RoomState;
  participantRenderer: FC<React.PropsWithChildren<ParticipantProps>>;
  controlRenderer: FC<React.PropsWithChildren<ControlsProps>>;
  onLeave?: (room: Room) => void;
  sortParticipants?: (participants: Participant[]) => Participant[];
}

const StageView = (stageProps: StageProps) => {
  const { participants } = stageProps.roomState;
  const context = useContext(DisplayContext);
  const { listAudioDevices, listVideoDevices } = useContext(DeviceContext);

  let screenTrack: VideoTrack | undefined;
  participants.forEach((p) => {
    if (screenTrack) {
      return;
    }
    const track = p.getTrack(Track.Source.ScreenShare);
    if (track?.isSubscribed && track.videoTrack) {
      screenTrack = track.videoTrack;
    }
  });

  useEffect(() => {
    const fetchUserMedia = async () => {
      await navigator.getUserMedia(
        {
          audio: true,
          video: { facingMode: 'user' },
        },
        noop,
        noop
      );
    };
    fetchUserMedia();
    listAudioDevices();
    listVideoDevices();
  }, [listAudioDevices, listVideoDevices]);

  const mainElement: ReactElement = useMemo(() => {
    if (context.stageLayout === 'grid' && screenTrack === undefined) {
      return <GridStage {...stageProps} />;
    }

    return <SpeakerStage {...stageProps} />;
  }, [stageProps, context.stageLayout, screenTrack]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {mainElement}
      {stageProps.roomState.audioTracks.map((track) => (
        <AudioRenderer key={track.sid} track={track} isLocal={false} />
      ))}
    </Box>
  );
};

export default StageView;
