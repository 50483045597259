import { FC } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '../../atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import ExtendedAvatar from '../../atoms/ExtendedAvatar';
import AvatarGroup from '../../atoms/AvatarGroup';
import Button from '../../atoms/Button';
import AccountPlusOutlineIcon from '../../atoms/Icons/AccountPlusOutlineIcon';
import RouteTypes from './dashboardTypes';
import { TitleBar } from '../../atoms/TitleBar';

type GroupHeaderProps = {
  groupName: string;
  ownerName: string;
  avatarUrl: string;
  isHost: boolean;
  participants: any[];
  onAvatarGroupClick: () => void;
};

const GroupHeader: FC<React.PropsWithChildren<GroupHeaderProps>> = ({
  groupName,
  ownerName,
  avatarUrl,
  isHost,
  participants,
  onAvatarGroupClick,
}) => {
  const params = useParams<{ tab: RouteTypes }>();
  const { tab } = params;

  const theme = useTheme();
  const showAddMemberText = useMediaQuery(theme.breakpoints.up('md'));
  const link = `/group/default/${tab}`;

  return (
    <TitleBar
      backLink={link}
      title={groupName}
      actions={
        <>
          {isHost && (
            <Box
              sx={(t) => ({
                display: 'flex',
                justifyContent: 'flex-end',
                order: 3,
                flex: 1,
                [t.breakpoints.up('sm')]: {
                  flex: 'unset',
                },
              })}
            >
              {showAddMemberText ? (
                <Button
                  variant={ButtonVariantTypes.SECONDARY}
                  size={ButtonSizeTypes.S}
                  onClick={onAvatarGroupClick}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <AccountPlusOutlineIcon
                      sx={{
                        fontSize: '20px',
                      }}
                    />
                    <Typography
                      fontSize="inherit"
                      color="inherit"
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Add member
                    </Typography>
                  </Box>
                </Button>
              ) : (
                <IconButton
                  variant={ButtonVariantTypes.SECONDARY}
                  size={ButtonSizeTypes.S}
                  onClick={onAvatarGroupClick}
                  shape={ButtonShapeTypes.ROUNDED}
                >
                  <AccountPlusOutlineIcon
                    sx={{
                      fontSize: '20px',
                    }}
                  />
                </IconButton>
              )}
            </Box>
          )}
          <Box
            sx={(t) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '14px',
              width: '100%',
              order: 4,
              [t.breakpoints.up('sm')]: {
                order: 2,
                width: 'unset',
                flex: 1,
              },
            })}
          >
            <ExtendedAvatar
              avatarUrl={avatarUrl}
              name={ownerName}
              alt={ownerName}
            />
            {participants.length > 0 && (
              <AvatarGroup
                avatarList={participants}
                max={5}
                onClick={onAvatarGroupClick}
              />
            )}
          </Box>
        </>
      }
    />
  );
};

export default GroupHeader;
