import { RefObject } from 'react';
import imageUtils from './image.utils';

let ref: RefObject<SVGSVGElement> | null = null;

const getRef = (): RefObject<SVGSVGElement> | null => ref;

const setRef = (cursorRef: RefObject<SVGSVGElement>): void => {
  ref = cursorRef;
};

const getCursorImage = async (color: string): Promise<HTMLImageElement | null> => {
  if (!ref || !ref.current) return null;

  const svg = ref.current.outerHTML.replace(/#000000/g, color);
  const url = `data:image/svg+xml;base64,${btoa(svg)}`;

  return imageUtils.toImage(url);
};

export { getRef, setRef, getCursorImage };
