import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '../../../../../../ui/atoms/Typography';

type PlanTitleWithPriceProps = {
  name: string;
  price: number;
  billingCycle: number;
};

export const PlanTitleWithPrice: React.FC<
  React.PropsWithChildren<PlanTitleWithPriceProps>
> = ({ name, price, billingCycle }) => {
  const planPeriod = useMemo(() => {
    switch (billingCycle) {
      case 1:
        return '/month';
      case 12:
        return '/month, billed yearly';
      default:
        return '';
    }
  }, [billingCycle]);

  const planPrice = useMemo(
    () => (Math.trunc((price / billingCycle) * 100) / 100).toFixed(2),
    [price, billingCycle]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        flexWrap: 'wrap-reverse',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <Typography variant="h6">$ {planPrice}</Typography>
        <Typography
          variant="p5"
          sx={(theme) => ({
            color: theme.text.t7,
          })}
        >
          {planPeriod}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.alpha10,
          borderRadius: '10px',
          padding: '6px 12px',
          height: '32px',
        })}
      >
        <Typography
          color="primary"
          sx={(theme) => ({
            ...theme.typography.p3,
            lineHeight: '20px',
          })}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
