import { FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChevronDownIcon from '../../atoms/Icons/ChevronDownIcon';
import ChevronUpIcon from '../../atoms/Icons/ChevronUpIcon';
import SelectButton from '../../atoms/SelectButton';
import SortIcon from '../../atoms/Icons/SortIcon';
import Menu from '../Menu';
import SelectItem from '../../atoms/SelectItem';
import { SortConfigDirection, SortConfigKey, SortOptions } from './sortTypes';
import { TypographyType } from '../../atoms/Typography/types/Typography';

const getText = (sortConfig: SortOptions, open: boolean) => {
  let text;

  switch (sortConfig.key) {
    case SortConfigKey.ALPHABETICAL:
      text = 'Alphabetical';
      break;
    case SortConfigKey.DATECREATED:
      text = 'Date created';
      break;
    case SortConfigKey.LASTMODIFIED:
      text = 'Last modified';
      break;
    default:
      text = 'NaN';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        padding: '0 4px',
      }}
    >
      <Typography variant={TypographyType.b4}>{text}</Typography>
      {open ? (
        <ChevronUpIcon sx={{ fontSize: '18px' }} />
      ) : (
        <ChevronDownIcon sx={{ fontSize: '18px' }} />
      )}
    </Box>
  );
};

type Props = {
  sortConfig: SortOptions;
  changeSelectOption: (event: SortOptions) => void;
};

const SortingCard: FC<React.PropsWithChildren<Props>> = ({
  sortConfig,
  changeSelectOption,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const onClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const theme = useTheme();
  const showValueText = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <SelectButton
        active={open}
        onClick={onClick}
        content={
          showValueText ? (
            getText(sortConfig, open)
          ) : (
            <SortIcon sx={{ fontSize: '18px' }} />
          )
        }
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        listItemWidth={164}
      >
        <SelectItem
          key="created_date"
          isSelected={
            `${sortConfig.key}_${sortConfig.direction}` ===
            `${SortConfigKey.DATECREATED}_${SortConfigDirection.DESC}`
          }
          onClick={() => {
            changeSelectOption({
              key: SortConfigKey.DATECREATED,
              direction: SortConfigDirection.DESC,
            });
            onClose();
          }}
        >
          Date created
        </SelectItem>
        <SelectItem
          key="last_modified"
          isSelected={
            `${sortConfig.key}_${sortConfig.direction}` ===
            `${SortConfigKey.LASTMODIFIED}_${SortConfigDirection.DESC}`
          }
          onClick={() => {
            changeSelectOption({
              key: SortConfigKey.LASTMODIFIED,
              direction: SortConfigDirection.DESC,
            });
            onClose();
          }}
        >
          Last modified
        </SelectItem>
        <SelectItem
          key="alphabetical"
          isSelected={
            `${sortConfig.key}_${sortConfig.direction}` ===
            `${SortConfigKey.ALPHABETICAL}_${SortConfigDirection.ASC}`
          }
          onClick={() => {
            changeSelectOption({
              key: SortConfigKey.ALPHABETICAL,
              direction: SortConfigDirection.ASC,
            });
            onClose();
          }}
        >
          Alphabetical
        </SelectItem>
      </Menu>
    </>
  );
};

export default SortingCard;
