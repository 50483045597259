import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useVariant } from '@unleash/proxy-client-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '../../atoms/Dialog';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import DialogTitle from '../../atoms/DialogTitle';
import Typography from '../../atoms/Typography/Typography';
import CheckIcon from '../../../assets/svgIcons/GreenCheckSvgIcon.svg';
import { userSelector } from '../../../common/reducers/userReducer';
import analyticsService from '../../../common/services/analytics.service';
import './UpgradeDialog.css';

type Props = {
  open: boolean;
  onUpgrade: () => void;
  onCancel: () => void;
};

type CardMediaProps = {
  type?: string;
  url?: string;
};

const Image = styled('img')(({ theme }) => ({
  height: 'auto',
  width: '100%',
}));

const CardMedia: FC<React.PropsWithChildren<CardMediaProps>> = ({
  type,
  url,
}) => {
  if (type === 'image' || type === 'gif') {
    return <Image src={url} />;
  }

  if (type === 'video') {
    return (
      <video width="240px" height="240px">
        <source src={url} type="video/mp4" />
      </video>
    );
  }

  return null;
};

type DotProps = {
  active: boolean;
};

const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<DotProps>(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : theme.background.bg4,
  width: '10px',
  height: '10px',
  borderRadius: '50%',
}));

type Slide = {
  title?: string;
  type?: string;
  url?: string;
  body?: string;
};

type DialogConfigDto = {
  title: string;
  cancelBtn?: string;
  successBtn: string;
};

type DialogConfig = {
  trial: DialogConfigDto;
  purchase: DialogConfigDto;
};

type VariantData = {
  name: string;
  dialog: DialogConfig;
  slides: Slide[];
};

const fallbackDialogPayload = {
  name: 'carousel-image-text',
  dialog: {
    trial: {
      title: 'Get Your Free Trial',
      successBtn: 'Start Free Trial',
      cancelBtn: 'Skip Trial',
    },
    purchase: {
      title: 'Upgrade to Premium',
      successBtn: '🔑 Unlock Features',
    },
  },
  options: {
    autoScroll: false,
  },
  slides: [
    {
      type: 'image',
      url: 'https://media.liveboard.online/images/collaborate-with-unlimited-students.png',
      title: 'Collaborate with unlimited students',
    },
  ],
};

const UpgradeDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onCancel,
  onUpgrade,
}) => {
  const user = useSelector(userSelector);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const dialogContext = useVariant('upgrade-dialog-design');
  const dialogPayload = useMemo<VariantData>(() => {
    const payload = dialogContext.payload?.value;

    if (!payload) return fallbackDialogPayload;

    return JSON.parse(payload);
  }, [dialogContext?.payload?.value]);

  const dialogConfig = user.canActivateTrial
    ? dialogPayload.dialog.trial
    : dialogPayload.dialog.purchase;

  const onClose = useCallback(() => {
    analyticsService.event('Plan Upgrade Dialog Close Tapped', {
      'experiment_upgrade-dialog-design': dialogPayload.name,
    });
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (open) {
      analyticsService.event('Plan Upgrade Dialog Opened', {
        'experiment_upgrade-dialog-design': dialogPayload.name,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{dialogConfig.title}</DialogTitle>
      <DialogContent
        sx={{
          position: 'relative',
          padding: 0,
        }}
      >
        <Slider
          ref={sliderRef}
          slidesToShow={1}
          slidesToScroll={1}
          afterChange={(index) => setCurrentSlide(index)}
          arrows={false}
          dots
          customPaging={(index) => <Dot active={currentSlide === index} />}
          dotsClass="upgrade-dialog-carousel-dot"
        >
          {dialogPayload.slides &&
            dialogPayload.slides.map((slide) => (
              <Box key={slide.title} gap={3} padding="0 20px">
                <CardMedia type={slide.type} url={slide.url} />
                <Typography
                  variant="s3"
                  mt={3}
                  mb={Boolean(slide.body) ? 1.5 : 3}
                >
                  {slide.title}
                </Typography>
                {slide.body && (
                  <Box
                    dangerouslySetInnerHTML={{ __html: slide.body }}
                    sx={(theme) => ({
                      '& p': {
                        marginBottom: '24px',
                        ...theme.typography.p4,
                      },
                      '& li': {
                        backgroundImage: `url('${CheckIcon}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '16px',
                        paddingLeft: '20px',
                      },
                    })}
                  />
                )}
              </Box>
            ))}
        </Slider>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton
          onClick={() => {
            analyticsService.event('Plan Upgrade Dialog Cancel Tapped', {
              'experiment_upgrade-dialog-design': dialogPayload.name,
            });
            onCancel();
          }}
        >
          {dialogConfig.cancelBtn}
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton
          onClick={() => {
            analyticsService.event('Plan Upgrade Dialog Success Tapped', {
              'experiment_upgrade-dialog-design': dialogPayload.name,
            });
            onUpgrade();
          }}
        >
          {dialogConfig.successBtn}
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
