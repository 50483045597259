import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography, { typographyClasses } from '@mui/material/Typography';
import { svgIconClasses, SvgIconProps } from '@mui/material/SvgIcon';
import PremiumButton from '../../atoms/NotificationButton/Premium';
import { NotificationButtonSize } from '../../atoms/NotificationButton/notificationButtonTypes';

type Props = {
  Icon?: FC<React.PropsWithChildren<SvgIconProps>>;
  title?: string;
  description?: string;
  message?: string;
  width: number | string;
  height: number | string;
  onClick?: (event: any) => void;
  onUpgrade?: () => void;
  className?: string;
};

const CreateCard: FC<React.PropsWithChildren<Props>> = ({
  Icon,
  title,
  description,
  message,
  width,
  height,
  onClick,
  onUpgrade,
  className = '',
}) => (
  <Box
    onClick={onClick}
    width={width}
    height={height}
    border="1px dashed"
    borderColor="primary.alpha50"
    padding="36px 80px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    borderRadius="20px 10px"
    bgcolor="#F0F4F3"
    sx={(theme) => ({
      ...(title
        ? {
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.primary.alpha10,
              ' .titleBox': {
                [`& .${svgIconClasses.root}, .${typographyClasses.root}`]: {
                  color: theme.palette.primary.dark5,
                },
              },
            },
          }
        : {}),
    })}
    className={className}
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        className="titleBox"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        {Icon && (
          <Icon
            sx={(theme) => ({
              fontSize: '1.875rem',
              color: theme.palette.primary.main,
            })}
          />
        )}
        <Typography variant="p3" color="primary">
          {title}
        </Typography>
      </Box>
      {description && message && (
        <>
          <Box mt={1.25} mb={1.25} whiteSpace="nowrap">
            <Typography variant="p5">{description}</Typography>
            <Typography variant="p5">{message}</Typography>
          </Box>
          <PremiumButton
            hasIcon
            size={NotificationButtonSize.XS}
            onClick={onUpgrade}
          >
            Try free for 14 days
          </PremiumButton>
        </>
      )}
    </Box>
  </Box>
);

export default CreateCard;
