import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import Box from '@mui/material/Box';
import PagesTool from '../Pages/PagesTool';
import DrawingTools from '../../DrawingTools/DrawingTools';
import UndoRedoTool from '../UndoRedoTool';
import ZoomTool from '../ZoomTool';
import * as sessionReducer from '../../../common/reducers/session/sessionReducer';
import { isCurrentUserHostSelector } from '../../../common/reducers/session/sessionReducer';
import IconButton from '../../../ui/atoms/IconButton';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../ui/atoms/Button/buttonTypes';
import HomeIcon from '../../../ui/atoms/Icons/HomeIcon';
import analyticsService from '../../../common/services/analytics.service';
import AccountType from '../../../common/services/types/auth/AccountType';
import useDialog from '../../../common/hooks/useDialog';
import FinishSessionDialog from '../../../ui/molecules/FinishSessionDialog';
import { userAccountTypeSelector } from '../../../common/reducers/userReducer';
import ThumbnailSaver from '../ThumbnailSaver/ThumbnailSaver';
import HandIcon from '../../../ui/atoms/Icons/HandIcon';
import ToolNames from '../../DrawingTools/ToolNames';
import DrawingButton from '../../DrawingTools/DrawingButton/DrawingButton';
import { getSelectedDrawingTool } from '../../../common/reducers/board/drawingToolReducer';
import * as drawingToolActions from '../../../common/actions/board/drawingToolActions';
import Tooltip from '../../../ui/atoms/Tooltip';
import CanvasContext from '../../board/Contexts/CanvasContext';
import { AssessmentTool } from '../../../features/assessment/assessment/AssessmentTool';
import { AssessmentPaperTool } from '../../../features/assessment/assessment-paper/AssessmentPaperTool';

type ToolsBarProps = {
  shouldShowPagesTool: boolean;
  boardIsFinished: boolean;
  pagesSidebarOpened: boolean;
  onTogglePageSidebar: () => void;
};

type HomeButtonProps = {
  onClick: () => void;
};

const HomeButton: FC<React.PropsWithChildren<HomeButtonProps>> = ({
  onClick,
}) => (
  <Tooltip title="Back" placement="bottom">
    <IconButton
      variant={ButtonVariantTypes.TEXT}
      size={ButtonSizeTypes.S}
      onClick={onClick}
    >
      <HomeIcon />
    </IconButton>
  </Tooltip>
);

type RefType = {
  saveThumbnail: () => Promise<void>;
};

export const toolsBarHeight = 56;

const ToolsBar: FC<React.PropsWithChildren<ToolsBarProps>> = ({
  shouldShowPagesTool,
  boardIsFinished,
  pagesSidebarOpened,
  onTogglePageSidebar,
}) => {
  const isHost = useSelector(isCurrentUserHostSelector);
  const canDraw = useSelector(sessionReducer.canCurrentUserDrawSelector);
  const groupId = useSelector(sessionReducer.groupIdSelector);
  const thumbnailSaverRef = useRef<RefType>(null);
  const isConnectedSession = useSelector(sessionReducer.connectedSelector);
  const accountType = useSelector(userAccountTypeSelector);
  const selectedTool = useSelector(getSelectedDrawingTool);
  const canvasContext = useContext(CanvasContext);

  const dispatch = useDispatch();
  const [showAssessmentPreview, setShowAssessmentPreview] = useState(false);

  const navigateTo = useCallback(
    (path: any) => {
      dispatch(push(path));
    },
    [dispatch]
  );

  const canSaveThumbnail = useMemo(
    () => isConnectedSession && !boardIsFinished,
    [isConnectedSession, boardIsFinished]
  );

  const saveThumbnail = useCallback(() => {
    if (!thumbnailSaverRef || !thumbnailSaverRef.current || !canSaveThumbnail) {
      return Promise.resolve();
    }

    return thumbnailSaverRef.current.saveThumbnail();
  }, [canSaveThumbnail]);

  const closeBoard = useCallback(async () => {
    await saveThumbnail();

    if (groupId) {
      navigateTo(`/group/${groupId}/sessions`);
      return;
    }

    navigateTo('/group/default/sessions');
  }, [saveThumbnail, navigateTo, groupId]);

  const leaveSession = useCallback(() => {
    if (groupId) {
      navigateTo(`/group/${groupId}/sessions`);
      return;
    }

    navigateTo('/group/default/sessions');
  }, [navigateTo, groupId]);

  const {
    open: leaveSessionDialogOpen,
    onOpen: onLeaveSessionDialogOpen,
    onClose: onLeaveSessionDialogClose,
  } = useDialog();

  const onBackClick = useCallback(async () => {
    if (!boardIsFinished) {
      analyticsService.event('Board Back Button Click');
    }

    if (!isHost && !boardIsFinished) {
      onLeaveSessionDialogOpen();
      return;
    }

    await closeBoard();
  }, [isHost, boardIsFinished, onLeaveSessionDialogOpen, closeBoard]);

  const selectMoveTool = useCallback(() => {
    analyticsService.event('Tool Selected', {
      tool: 'move',
    });

    dispatch(drawingToolActions.selectDrawingTool(ToolNames.Move));
  }, [dispatch]);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.background.bg1,
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${theme.background.bg3}`,
        padding: '8px 12px',
        width: '100%',
        zIndex: 1000,
        gridArea: 'toolsBar',
        height: toolsBarHeight,
      })}
    >
      <Box display="flex" gap={1}>
        {isConnectedSession && accountType !== AccountType.Temporary && (
          <Box display="flex" gap={1}>
            <HomeButton onClick={onBackClick} />
          </Box>
        )}
        {isHost ? <AssessmentTool /> : <AssessmentPaperTool />}
        {!showAssessmentPreview && shouldShowPagesTool ? (
          <PagesTool
            key="pages-tool"
            editable={!boardIsFinished && isHost}
            opened={pagesSidebarOpened}
            onToggle={onTogglePageSidebar}
          />
        ) : (
          <Box width={204} height={40} />
        )}
      </Box>
      {!showAssessmentPreview && canDraw && (
        <Box display="flex">
          <DrawingTools />
          {!boardIsFinished && <UndoRedoTool />}
        </Box>
      )}
      <Box display="flex" gap={0.5}>
        {!showAssessmentPreview && canvasContext.zoom !== 1 ? (
          <DrawingButton
            icon={<HandIcon />}
            onClick={selectMoveTool}
            tooltipTitle="Move"
            tooltipPlacement="bottom"
            active={selectedTool.name === ToolNames.Move}
          />
        ) : (
          <Box width={40} height={40} />
        )}
        {!showAssessmentPreview && <ZoomTool />}
      </Box>
      <FinishSessionDialog
        open={leaveSessionDialogOpen}
        onClose={onLeaveSessionDialogClose}
        onSuccess={leaveSession}
        isHost={isHost}
      />
      {canSaveThumbnail && (
        <ThumbnailSaver ref={thumbnailSaverRef} key="thumbnail-saver" />
      )}
    </Box>
  );
};

export default React.memo(ToolsBar);
