import ShapeControl from './ShapeControl';

export default class TriangleBoundaryBox extends ShapeControl {
  cursor = 'move';

  constructor(top, right, left, scale, padding) {
    const startPoint = {
      x: Math.min(top.x, right.x, left.x),
      y: Math.min(top.y, right.y, left.y),
    };
    const endPoint = {
      x: Math.max(top.x, right.x, left.x),
      y: Math.max(top.y, right.y, left.y),
    };
    super(startPoint, endPoint, scale, padding);

    this.top = top;
    this.right = right;
    this.left = left;
    this.scale = scale;
    this.padding = padding;
  }

  calculateAnglesData() {
    const rightAngleData = this.calculateAngleData(this.top, this.right, this.left);
    const leftAngleData = this.calculateAngleData(this.right, this.left, this.top);
    const topAngleData = this.calculateAngleData(this.left, this.top, this.right);

    const anglesData = [rightAngleData, leftAngleData, topAngleData];
    anglesData.sort((a, b) => a.angle % 1 > b.angle - b.angle % 1 ? -1 : 1)

    anglesData[0].angle = Math.round(anglesData[0].angle)

    if (anglesData[1].angle > anglesData[2].angle) {
      anglesData[1].angle = Math.round(anglesData[1].angle);
      anglesData[2].angle = 180 - anglesData[0].angle - anglesData[1].angle;
    } else {
      anglesData[2].angle = Math.round(anglesData[2].angle);
      anglesData[1].angle =  180 - anglesData[0].angle - anglesData[2].angle;
    }

    return anglesData;
   }

  calculateAngleData(point1, point2, point3) {
    const nx1 = point1.x - point2.x;
    const ny1 = point1.y - point2.y;
    const nx2 = point3.x - point2.x;
    const ny2 = point3.y - point2.y;

    let lineAngle1 = Math.atan2(ny1, nx1);
    let lineAngle2 = Math.atan2(ny2, nx2);

    if (nx1 * ny2 - ny1 * nx2 < 0) {
      const t = lineAngle2;
      lineAngle2 = lineAngle1;
      lineAngle1 = t;
    }

    if (lineAngle1 < lineAngle2) {
      lineAngle1 += Math.PI * 2;
    }

    const angle = (360 - (lineAngle1 - lineAngle2) * (180 /Math.PI))

    return {
      angle,
      lineAngle1,
      lineAngle2,
      point: point2,
    };
  }

  drawAngle(ctx, angleData) {
    const { angle, lineAngle1, lineAngle2, point } = angleData;

    ctx.strokeStyle = '#000';
    ctx.beginPath();
    ctx.moveTo(point.x, point.y);
    ctx.arc(point.x, point.y, 35, lineAngle1, lineAngle2);
    ctx.stroke();

    const mx = -Math.cos((lineAngle1 + lineAngle2) / 2) * 50 + point.x;
    const my = -Math.sin((lineAngle1 + lineAngle2) / 2) * 50 + point.y;

    ctx.fillStyle = '#000';
    ctx.font = '16px Roboto';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      `${angle}°`,
      mx,
      my,
    );
  }

  drawAngles(ctx, anglesData) {
    for(let i = 0; i < anglesData.length; i++) {
      this.drawAngle(ctx, anglesData[i]);
    }
  }

  draw(ctx) {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 2 / this.scale;
    ctx.setLineDash([4 / this.scale, 4 / this.scale]);
    ctx.moveTo(this.left.x, this.left.y);
    ctx.lineTo(this.right.x, this.right.y);
    ctx.lineTo(this.top.x, this.top.y);
    ctx.closePath();
    ctx.stroke();

    const anglesData = this.calculateAnglesData();

    this.drawAngles(ctx, anglesData);

    ctx.restore();
  }

  hitTest(x, y) {
    const point = { x, y };
    const sign = (p1, p2, p3) => {
      return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
    }

    const d1 = sign(point, this.top, this.right);
    const d2 = sign(point, this.right, this.left);
    const d3 = sign(point, this.left, this.top);

    const hasNeg = (d1 < 0) || (d2 < 0) || (d3 < 0);
    const hasPos = (d1 > 0) || (d2 > 0) || (d3 > 0);
    return !(hasNeg && hasPos);
  }
}
