import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '../../../../ui/atoms/Dialog';
import DialogContent from '../../../../ui/atoms/DialogContent';
import DialogActions from '../../../../ui/atoms/DialogActions';
import DialogTitle from '../../../../ui/atoms/DialogTitle';
import FeatureTourStepDto from '../../../../common/services/dto/featureTours/FeatureTourStepDto';
import { userPlanSelector } from '../../../../common/reducers/userReducer';
import { featureToursSuccess } from '../../../../common/actions/featureToursActions';
import featureToursService from '../../../../common/services/featureTours.service';
import FeatureTourType from '../../../../common/services/types/featureTours/FeatureTourType';
import FeatureTourDto from '../../../../common/services/dto/featureTours/FeatureTourDto';
import CustomStepper from '../../../../ui/atoms/CustomStepper';
import { DialogActionPrimaryButton } from '../../../../ui/atoms/DialogActions';

type Props = {
  open: boolean;
  onClose: () => void;
  featureTourData: FeatureTourDto;
  type: FeatureTourType;
  title: string;
  version: number;
  canUseFeature: boolean;
  featureAction: string;
  onCreate: () => void;
  onUnlock?: (version: number, activeStep: number) => void;
  steps: FeatureTourStepDto[];
  imageClass: string;
  onComplete: (planId: string, version: number) => void;
  onSkip: (version: number, activeStep: number) => void;
};

const FeatureTourDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  featureTourData,
  type,
  title,
  version,
  canUseFeature,
  featureAction,
  onCreate,
  onUnlock,
  steps,
  imageClass,
  onComplete,
  onSkip,
}) => {
  const plan = useSelector(userPlanSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const images = steps.map((step) => step.imageUrls.web.toString());

    Promise.all(
      images.map(
        (imageUrl) =>
          new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = resolve;
            image.onerror = reject;
            image.src = imageUrl;
          })
      )
    );
  }, [steps]);

  const [activeStep, setActiveStep] = useState(0);
  const [isCompleteStep, setIsCompleteStep] = useState(false);

  const postTourSeen = useCallback(
    () =>
      featureToursService.postFeatureTourSeen(type, featureTourData.version),
    [featureTourData, type]
  );

  useEffect(() => {
    if (!isCompleteStep && activeStep === steps.length - 1) {
      setIsCompleteStep(true);
      onComplete(plan.id, version);
    }
  }, [
    version,
    onComplete,
    setIsCompleteStep,
    activeStep,
    isCompleteStep,
    plan.id,
    steps,
  ]);

  const onNextClick = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [setActiveStep]);

  const onBackClick = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [setActiveStep]);

  const onCreateClick = useCallback(async () => {
    onCreate();

    const featureTours = await postTourSeen();
    dispatch(featureToursSuccess(featureTours));
  }, [dispatch, onCreate, postTourSeen]);

  const onUnlockClick = useCallback(() => {
    if (onUnlock) {
      onUnlock(version, activeStep + 1);
    }
  }, [version, onUnlock, activeStep]);

  const onDialogClose = useCallback(async () => {
    onSkip(version, activeStep + 1);
    onClose();

    const featureTours = await postTourSeen();
    dispatch(featureToursSuccess(featureTours));
  }, [dispatch, postTourSeen, version, activeStep, onClose, onSkip]);

  return (
    <Dialog onClose={onDialogClose} open={open} fullWidth maxDialogWidth={830}>
      <DialogTitle onClose={onDialogClose}>{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Box>
            <img
              className={imageClass}
              src={steps[activeStep].imageUrls.web}
              alt={steps[activeStep].label}
            />
          </Box>
          <Box ml={2} display="flex" flexDirection="column">
            {steps.length > 1 && (
              <CustomStepper
                activeStep={activeStep}
                stepsLength={steps.length}
                onBackClick={onBackClick}
                onNextClick={onNextClick}
              />
            )}
            <Box display="flex">
              <Box
                width={0}
                height={0}
                borderTop="10px solid transparent"
                borderBottom="10px solid transparent"
                borderRight="10px solid #3B7A5B"
                mt={3}
              />
              <Box
                width={256}
                p={3}
                // TODO: Change color after changing primary color of the theme
                bgcolor="#3B7A5B"
                color="primary.contrastText"
                borderRadius="10px"
              >
                <Box mb={2}>
                  <Box mb={2}>
                    <Typography
                      variant="p4"
                      sx={(theme) => ({
                        color: theme.text.t1,
                      })}
                    >
                      {steps[activeStep].label}
                    </Typography>
                  </Box>
                  <Typography
                    variant="p5"
                    minHeight={96}
                    sx={(theme) => ({
                      color: theme.palette.primary.alpha20,
                    })}
                  >
                    {steps[activeStep].description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogActionPrimaryButton
          onClick={canUseFeature ? onCreateClick : onUnlockClick}
        >
          {featureAction}
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FeatureTourDialog;
