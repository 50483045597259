const ATTRIBUTES = {
  title: 'data-mjx-error',
  width:'width',
  height: 'height',
  stroke: 'stroke',
  fill: 'fill',
  style: 'style',
  focusable: 'focusable',
  role: 'role',
};
const GROUP_TAG = 'g';
const ERROR_TAG = 'merror';
const XML_HEADER = '<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n';
const DEFAULT_RENDER_OPTIONS = {
  em: 16,
  ex: 8,
  display: true,
  scale: 1,
};

const exToPx = value => {
  const match = value.match(/^(.*)ex$/);

  if (!match) {
    return value;
  }

  return (parseFloat(match[1]) * 8).toFixed(3) + 'px';
};
const texToSvg = (latex, color) => {
  if (!latex) return null;

  window.MathJax.texReset();
  const wrapper = window.MathJax.tex2svg(latex, DEFAULT_RENDER_OPTIONS);
  const hasError = wrapper.querySelector(ERROR_TAG);

  if (hasError) {
    let error = wrapper.querySelector(ERROR_TAG).getAttribute(ATTRIBUTES.title);

    if (error.includes('Undefined control sequence')) {
      error = error.replace('Undefined control sequence', 'Invalid LaTeX syntax:');
    }

    throw new Error(error || 'Invalid Syntax');
  }

  const svg = wrapper.firstElementChild;

  svg.setAttribute(ATTRIBUTES.width, exToPx(svg.getAttribute(ATTRIBUTES.width)));
  svg.setAttribute(ATTRIBUTES.height, exToPx(svg.getAttribute(ATTRIBUTES.height)));
  svg.getElementsByTagName(GROUP_TAG)[0].setAttribute(ATTRIBUTES.stroke, color);
  svg.getElementsByTagName(GROUP_TAG)[0].setAttribute(ATTRIBUTES.fill, color);
  svg.removeAttribute(ATTRIBUTES.style);
  svg.removeAttribute(ATTRIBUTES.focusable);
  svg.removeAttribute(ATTRIBUTES.role);

  return XML_HEADER + svg.outerHTML;
};

export default {
  texToSvg,
};
