import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { TypographyType } from '../../atoms/Typography/types/Typography';

type Props = {
  Icon: FC<React.PropsWithChildren<SvgIconProps>>;
  title: string;
  description: string;
  actions?: React.ReactNode;
  dashed?: boolean;
};

const NoContentCard: FC<React.PropsWithChildren<Props>> = ({
  Icon,
  title,
  description,
  actions,
  dashed,
}) => (
  <Box
    sx={{
      padding: '47px 10.5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px 10px',
      width: '100%',
      ...(dashed
        ? {
            border: '1px dashed',
            borderColor: 'primary.alpha50',
          }
        : {}),
    }}
  >
    <Box display="flex" gap={4.5} flexDirection="column">
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Icon
            sx={(theme) => ({
              fontSize: '3.5rem',
              color: theme.background.bg6,
            })}
          />
        </Box>
        <Box mt={1.75} textAlign="center">
          <Typography variant={TypographyType.s2}>{title}</Typography>
          <Typography variant={TypographyType.p5}>{description}</Typography>
        </Box>
      </Box>
      {actions}
    </Box>
  </Box>
);

export default NoContentCard;
