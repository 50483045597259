import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const CardGridItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'calc((100% - 16px) / 2)',
  },
  [theme.breakpoints.up('md')]: {
    width: 'calc((100% - 28px) / 2)',
    minWidth: '466px',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'calc((100% - 28px * 2) / 3)',
    minWidth: '287px',
  },
}));
