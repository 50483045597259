import { inputClasses } from '@mui/material/Input';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { Theme } from '@mui/material/styles';
import { inputAdornmentClasses } from '@mui/material';
import InputVariant from './InputVariant';
import InputStatus from './InputStatus';

const generateInputStyle = (
  theme: Theme,
  variant: InputVariant,
  status: InputStatus | undefined,
  filled: boolean,
) => {
  const svgIconColor = filled
    ? theme.palette.primary.main
    : theme.palette.primary.alpha50;

  const additionalStyles = {
    [`& .${inputAdornmentClasses.root}`]: {
      color: status
        ? theme.palette[status].main
        : svgIconColor,
    },
  };

  switch (variant) {
    case InputVariant.CONTAINED:
      return {
        [`& .${inputClasses.root}`]: {
          backgroundColor: theme.background.bg4,
          borderColor: status ? theme.palette[status].main : theme.background.bg4,
          color: theme.text.t8,
          fontWeight: 500,
        },
        [`& .${inputClasses.root}:hover`]: {
          backgroundColor: theme.background.bg5,
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
        },
        [`& .${inputClasses.root}.${inputClasses.focused}`]: {
          backgroundColor: theme.background.bg5,
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
          [`& .${svgIconClasses.root}`]: {
            color: status ? theme.palette[status].main : theme.palette.primary.main,
          },
        },
        [`& .${inputClasses.disabled}`]: {
          [` .${inputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t3,
          },
          '&:hover': {
            backgroundColor: theme.background.bg4,
            borderColor: status ? theme.palette[status].main : theme.background.bg4,
          },
          [`.${svgIconClasses.root}`]: {
            color: `${theme.text.t3} !important`,
          },
        },
        ...additionalStyles,
      };

    case InputVariant.OUTLINED:
      return {
        [`& .${inputClasses.root}`]: {
          backgroundColor: 'inherit',
          borderColor: status ? theme.palette[status].main : theme.background.bg6,
          color: theme.text.t8,
          fontWeight: 500,
        },
        [`& .${inputClasses.root}:hover`]: {
          borderColor: status ? theme.palette[status].main : theme.palette.primary.alpha50,
        },
        [`& .${inputClasses.root}.${inputClasses.focused}`]: {
          borderColor: status ? theme.palette[status].main : theme.palette.primary.alpha50,
          [`& .${svgIconClasses.root}`]: {
            color: status ? theme.palette[status].main : theme.palette.primary.main,
          },
        },
        [`& .${inputClasses.disabled}`]: {
          borderColor: status ? theme.palette[status].main : theme.background.bg5,
          [` .${inputClasses.input}::-webkit-input-placeholder`]: {
            color: theme.text.t3,
          },
          '&:hover': {
            borderColor: status ? theme.palette[status].main : theme.background.bg5,
          },
          [`.${svgIconClasses.root}`]: {
            color: `${theme.text.t3} !important`,
          },
        },
        ...additionalStyles,
      };

    default: return {};
  }
};

export default generateInputStyle;
