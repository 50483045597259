import { createContext } from 'react';

export interface SocketContextInterface {
  send: (event: string, data: Record<string, any>) => void;
}

const SocketContext = createContext<SocketContextInterface>({
  send: (event: string, data: Record<string, any>) => {},
});

export default SocketContext;
