import { FC } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import SpinnerAdornment from './SpinnerAdornment';

type Props = {
  loading: boolean;
  disabled?: boolean;
};

const LoadingButton: FC<React.PropsWithChildren<Props & ButtonProps>> = ({
  children,
  loading,
  disabled = false,
  ...rest
}) => (
  <Button {...rest} disabled={loading || disabled}>
    <Box>
      {children}
      {loading && <SpinnerAdornment />}
    </Box>
  </Button>
);

export default LoadingButton;
