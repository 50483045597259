import { forwardRef } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';

type UserPresenceAvatarProps = {
  color?: string;
} & AvatarProps;

const UserPresenceAvatar = forwardRef<HTMLDivElement, UserPresenceAvatarProps>(
  ({ src, alt, color, children, ...props }, ref) => {
    if (src) {
      return (
        <Avatar
          {...props}
          alt={alt}
          src={src}
          ref={ref}
          sx={{
            width: '44px',
            height: '44px',
            border: '2px solid #fff',
            // Todo: remove important statement when we remove bambourgh-wrapper
            ...(color
              ? {
                  outline: `2px solid ${color} !important`,
                }
              : {}),
          }}
        />
      );
    }

    return (
      <Avatar
        {...props}
        ref={ref}
        sx={{
          width: '44px',
          height: '44px',
          border: '2px solid #fff',
          // Todo: remove important statement when we remove bambourgh-wrapper
          ...(color
            ? {
                outline: `2px solid ${color} !important`,
              }
            : {}),
        }}
      >
        {children || (alt && alt.charAt(0).toUpperCase())}
      </Avatar>
    );
  }
);

export default UserPresenceAvatar;
