import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { AssessmentSidebarContext } from '../AssessmentSidebarContext';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonColorTypes,
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';
import QuizIcon from '../../../../../ui/atoms/Icons/QuizIcon';
import { AssessmentSidebar } from '../AssessmentSidebar';
import analyticsService from '../../../../../common/services/analytics.service';

export const Layout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const assessmentSidebarContext = useContext(AssessmentSidebarContext);
  return (
    <Box>
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={() => {
          analyticsService.event('Open Assessments Tab Button Click', {
            role: 'teacher',
          });
          assessmentSidebarContext.onOpen();
        }}
        shape={ButtonShapeTypes.CIRCLE}
        color={ButtonColorTypes.PRIMARY}
      >
        <QuizIcon />
      </IconButton>
      <AssessmentSidebar
        open={assessmentSidebarContext.open}
        onClose={assessmentSidebarContext.onClose}
      />
      {children}
    </Box>
  );
};
