import { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import VideoOutlineIcon from '../../../../ui/atoms/Icons/VideoOutlineIcon';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../ui/atoms/Button/buttonTypes';
import IconButton from '../../../../ui/atoms/IconButton';

type Props = {
  onClick: () => void;
  tooltipPlacement: 'top' | 'left';
};

const CallButton: FC<React.PropsWithChildren<Props>> = ({
  onClick,
  tooltipPlacement,
}) => (
  <Tooltip title="Video Call" placement={tooltipPlacement}>
    <Box className="product-tour-step-5">
      <IconButton
        variant={ButtonVariantTypes.SECONDARY}
        shape={ButtonShapeTypes.ROUNDED}
        size={ButtonSizeTypes.S}
        onClick={onClick}
      >
        <VideoOutlineIcon
          sx={{
            width: 18,
            height: 18,
          }}
        />
      </IconButton>
    </Box>
  </Tooltip>
);

export default CallButton;
