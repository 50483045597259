import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '../../../../ui/atoms/IconButton';
import {
  ButtonShapeTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../ui/atoms/Button/buttonTypes';
import ChatOutlineIcon from '../../../../ui/atoms/Icons/ChatOutlineIcon';
import styles from './ChatButton.module.css';

const ChatButton = ({ onClick, unreadMessageCount, tooltipPlacment }) => {
  return (
    <Tooltip title="Chat" placement={tooltipPlacment}>
      <Box>
        <Badge
          classes={{
            root: styles.badgeContainer,
            badge: styles.badge,
          }}
          badgeContent={unreadMessageCount}
          component="div"
          overlap="circular"
          invisible={!unreadMessageCount}
        >
          <IconButton
            onClick={onClick}
            variant={ButtonVariantTypes.SECONDARY}
            size={ButtonSizeTypes.S}
            shape={ButtonShapeTypes.ROUNDED}
          >
            <ChatOutlineIcon />
          </IconButton>
        </Badge>
      </Box>
    </Tooltip>
  );
};

ChatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  unreadMessageCount: PropTypes.number,
};

ChatButton.defaultProps = {
  unreadMessageCount: 0,
};

export default ChatButton;
