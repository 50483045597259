import { createSelector } from 'reselect';
import { userSelector }  from '../reducers/userReducer';
import { boardConfigurationSelector, recordingConfigurationSelector } from '../reducers/configurationReducer';
import INFINITE from '../utils/types/configuration/Infinite.constant';

const planPermissionsSelector = createSelector(
  userSelector,
  user => user.subscription.plan.permissions,
);

const canManageDrawingAccessSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.canControlDrawingAccess,
);

const hasShareSessionPermission = createSelector(
  planPermissionsSelector,
  permissions => permissions.canShareSession,
);

const hasVoicePermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.voice,
);

const hasServerRecordingPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.serverRecording,
);

const canCreateNewRecordingSelector = createSelector(
    recordingConfigurationSelector,
    recordingConfiguration => (
      recordingConfiguration.create.enabled
      && (
        recordingConfiguration.create.limit === INFINITE
        || recordingConfiguration.create.limit > recordingConfiguration.create.count
      )
  ),
);

const hasPdfExportPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.pdfExport,
);

const hasPdfImportPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.pdfImport,
);

const hasGuestSharePermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.guestShare,
);

const hasManagementToolsPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.managementTools,
);

const hasSessionQuestionsPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.sessionQuestions,
);

const isEnterpriseSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.isEnterprise,
);

const isUpgradableSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.isUpgradable,
);

const maxSessionParticipantsSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.maxSessionParticipants === -1
    ? Infinity
    : permissions.maxSessionParticipants - 1,
);

const hasVoiceButtonPermissionSelector = createSelector(
  hasVoicePermissionSelector,
  isEnterpriseSelector,
  isUpgradableSelector,
  (hasVoice, isEnterprise, isUpgradable) => hasVoice || (!isEnterprise && isUpgradable),
);

const hasPfdImportButtonPermissionSelector = createSelector(
  hasPdfImportPermissionSelector,
  isEnterpriseSelector,
  isUpgradableSelector,
  (hasPdfImport, isEnterprise, isUpgradable) => hasPdfImport || (!isEnterprise && isUpgradable),
);

const hasGuestShareButtonPermissionSelector = createSelector(
  hasGuestSharePermissionSelector,
  isEnterpriseSelector,
  isUpgradableSelector,
  (hasGuestShare, isEnterprise, isUpgradable) => hasGuestShare || (!isEnterprise && isUpgradable),
);

const maxSessionPagesCountPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.maxSessionPagesCount,
);

const hasCreateSessionPermissionSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.canCreateSession,
);

const canCreateNewBoardSelector = createSelector(
    boardConfigurationSelector,
    boardConfiguration => (
    boardConfiguration.create.enabled
    && (
      boardConfiguration.create.limit === INFINITE
      || boardConfiguration.create.limit > boardConfiguration.create.count
    )
  ),
);

const canExportPdfSelector = createSelector(
  planPermissionsSelector,
  permissions => permissions.pdfExport,
);

const canCreateGroupSelector = createSelector(
  planPermissionsSelector,
  (permissions) => permissions.canCreateGroup,
);

const canDeleteSessionSelector = createSelector(
  planPermissionsSelector,
  (permissions) => permissions.canDeleteSession,
);

export {
  hasShareSessionPermission,
  hasVoicePermissionSelector,
  hasServerRecordingPermissionSelector,
  hasPdfExportPermissionSelector,
  hasPdfImportPermissionSelector,
  hasGuestSharePermissionSelector,
  hasManagementToolsPermissionSelector,
  hasSessionQuestionsPermissionSelector,
  maxSessionParticipantsSelector,
  hasVoiceButtonPermissionSelector,
  hasPfdImportButtonPermissionSelector,
  hasGuestShareButtonPermissionSelector,
  maxSessionPagesCountPermissionSelector,
  hasCreateSessionPermissionSelector,
  isUpgradableSelector,
  canCreateNewBoardSelector,
  canCreateNewRecordingSelector,
  canExportPdfSelector,
  canCreateGroupSelector,
  canManageDrawingAccessSelector,
  canDeleteSessionSelector,
};
