import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../atoms/Typography';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import Dialog from '../../atoms/Dialog';
import recordingService from '../../../common/services/recording.service';
import { removeRecording } from '../../../common/actions/recordingsActions';
import { removeRecording as changeRecordingConfig } from '../../../common/actions/configurationActions';
import TrashCanOutlineIcon from '../../atoms/Icons/TrashCanOutlineIcon';
import { userSelector } from '../../../common/reducers/userReducer';
import ErrorCodes from '../../../common/errors/errorCodes';

type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  recording: any;
};

const DeleteRecordingDialog: FC<React.PropsWithChildren<DeleteDialogProps>> = ({
  open,
  onClose,
  recording,
}) => {
  const [error, setError] = useState('');
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const onDelete = useCallback(async () => {
    if (!recording) return;
    try {
      await recordingService.deleteRecording(recording.id);
      onClose();
      dispatch(removeRecording(recording.id));
      if (user.id === recording.userId) {
        dispatch(changeRecordingConfig());
      }
    } catch (err) {
      const { code } = err;
      if (code === ErrorCodes.E_RECORDING_NOT_FOUND) {
        setError('Failed to find the recording.');
        dispatch(removeRecording(recording.id));

        if (user.id === recording.userId) {
          dispatch(removeRecording(recording.id));
        }
      }
    }
  }, [dispatch, recording, onClose, user, setError]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        icon={<TrashCanOutlineIcon color="error" />}
        onClose={onClose}
        error={error}
      >
        Delete recording
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={TypographyType.p5}
          sx={(theme) => ({
            color: theme.text.t8,
          })}
        >
          Are you sure you want to delete this recording?
        </Typography>
      </DialogContent>
      <DialogActions>
        <DialogActionSecondaryButton onClick={onClose}>
          Cancel
        </DialogActionSecondaryButton>
        <DialogActionPrimaryButton onClick={onDelete} color="error">
          Delete
        </DialogActionPrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecordingDialog;
