const ADD_TEMPORARY_ACTION = Symbol('ADD_TEMPORARY_ACTION');
const UPDATE_TEMPORARY_ACTION = Symbol('UPDATE_TEMPORARY_ACTION');
const REMOVE_TEMPORARY_ACTION = Symbol('REMOVE_TEMPORARY_ACTION');
const SET_CURRENT_TEMPORARY_ACTION = Symbol('SET_CURRENT_TEMPORARY_ACTION');

const addAction = action => ({
  type: ADD_TEMPORARY_ACTION,
  payload: {
    action,
  },
});

const updateAction = action => ({
  type: UPDATE_TEMPORARY_ACTION,
  payload: {
    action,
  },
});

const removeAction = action => ({
  type: REMOVE_TEMPORARY_ACTION,
  payload: {
    action,
  },
});

const setCurrentTemporaryAction = action => ({
  type: SET_CURRENT_TEMPORARY_ACTION,
  payload: {
    action,
  },
});

export {
  addAction,
  updateAction,
  removeAction,
  setCurrentTemporaryAction,
  ADD_TEMPORARY_ACTION,
  UPDATE_TEMPORARY_ACTION,
  REMOVE_TEMPORARY_ACTION,
  SET_CURRENT_TEMPORARY_ACTION,
};
