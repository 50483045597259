import VerticalResizer from './VerticalResizer';

export default class TopResizer extends VerticalResizer {
  center() {
    const y = Math.min(this.startPoint.y, this.endPoint.y);
    return {
      x: (this.startPoint.x + this.endPoint.x) / 2,
      y: y - this.padding,
    };
  }

  onResize({ y }) {
    if (this.endPoint.y > this.startPoint.y) {
      return {
        startPoint: {
          x: this.startPoint.x,
          y: Math.min(y + this.padding, this.endPoint.y),
        },
        endPoint: { ...this.endPoint },
      };
    }

    return {
      startPoint: { ...this.startPoint },
      endPoint: {
        x: this.endPoint.x,
        y: Math.min(y + this.padding, this.startPoint.y),
      },
    };
  }
}
