import { FC } from 'react';
import Typography from '../../atoms/Typography';
import { TypographyType } from '../../atoms/Typography/types/Typography';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  isHost?: boolean;
};

const FinishSessionDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onSuccess,
  isHost = false,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle onClose={onClose}>
      {`${isHost ? 'End' : 'Leave'} session`}
    </DialogTitle>
    <DialogContent>
      <Typography variant={TypographyType.p5}>
        {`Are you sure you want to ${isHost ? 'end' : 'leave'} the session?`}
      </Typography>
    </DialogContent>
    <DialogActions>
      <DialogActionSecondaryButton onClick={onClose}>
        Cancel
      </DialogActionSecondaryButton>
      <DialogActionPrimaryButton onClick={onSuccess} color="error">
        {`${isHost ? 'End' : 'Leave'} session`}
      </DialogActionPrimaryButton>
    </DialogActions>
  </Dialog>
);

export default FinishSessionDialog;
