import { httpServiceV4 } from '../../../../common/services/http.service';
import { PageDto } from '../../../../common/dto/PageDto';
import { AssessmentTemplateListItemDto } from './dto/AssessmentTemplateListItemDto';
import { AssessmentTemplateDto } from './dto/AssessmentTemplateDto';
import { UpdateAssessmentTemplateParams } from './dto/UpdateAssessmentTemplateParams';
import { CreateAssessmentTemplateParams } from './dto/CreateAssessmentTemplateParams';

const ASSESSMENT_TEMPLATE_URL = '/assessment-template';

const getAssessmentTemplates = (): Promise<
  PageDto<AssessmentTemplateListItemDto>
> =>
  httpServiceV4.get<
    PageDto<AssessmentTemplateListItemDto>,
    PageDto<AssessmentTemplateListItemDto>
  >(ASSESSMENT_TEMPLATE_URL);

const getPublishedAssessmentTemplates = (): Promise<
  PageDto<AssessmentTemplateListItemDto>
> =>
  httpServiceV4.get<
    PageDto<AssessmentTemplateListItemDto>,
    PageDto<AssessmentTemplateListItemDto>
  >(`${ASSESSMENT_TEMPLATE_URL}?filters[status]=published`);

const createAssessmentTemplate = ({
  name,
  questions = [],
}: CreateAssessmentTemplateParams): Promise<AssessmentTemplateDto> =>
  httpServiceV4.post<AssessmentTemplateDto, AssessmentTemplateDto>(
    ASSESSMENT_TEMPLATE_URL,
    {
      name,
      questions,
    }
  );
const getAssessmentTemplate = (id: string): Promise<AssessmentTemplateDto> =>
  httpServiceV4.get<AssessmentTemplateDto, AssessmentTemplateDto>(
    `${ASSESSMENT_TEMPLATE_URL}/${id}`
  );

const updateAssessmentTemplate = ({
  name,
  questions,
  id,
}: UpdateAssessmentTemplateParams): Promise<AssessmentTemplateDto> =>
  httpServiceV4.put<AssessmentTemplateDto, AssessmentTemplateDto>(
    `${ASSESSMENT_TEMPLATE_URL}/${id}`,
    {
      name,
      questions,
    }
  );

const publishAssessmentTemplate = (
  id: string
): Promise<AssessmentTemplateDto> =>
  httpServiceV4.post<AssessmentTemplateDto, AssessmentTemplateDto>(
    `${ASSESSMENT_TEMPLATE_URL}/${id}/publish`
  );
const deleteAssessmentTemplate = (id: string): Promise<void> =>
  httpServiceV4.delete(`${ASSESSMENT_TEMPLATE_URL}/${id}`);

export {
  getAssessmentTemplates,
  getPublishedAssessmentTemplates,
  createAssessmentTemplate,
  getAssessmentTemplate,
  updateAssessmentTemplate,
  publishAssessmentTemplate,
  deleteAssessmentTemplate,
};
