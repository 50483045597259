import { FC } from 'react';
import { dropRight } from 'lodash';
import MuiAvatarGroup from '@mui/material/AvatarGroup';
import { avatarClasses } from '@mui/material/Avatar';
import Avatar from '../Avatar';
import { AvatarSizeTypes } from '../Avatar/types';
import Typography from '../Typography';
import { TypographyType } from '../Typography/types/Typography';

type AvatarListType = {
  src: string;
  alt: string;
};

type AvatarGroupProps = {
  max?: number;
  avatarList: Array<AvatarListType>;
  onClick: () => void;
};

const AvatarGroup: FC<React.PropsWithChildren<AvatarGroupProps>> = ({
  max,
  avatarList,
  onClick,
}) => (
  <MuiAvatarGroup
    // In order to hide default Mui AvatarGroup additional avatars Component,
    // max should be more then avatars count
    max={avatarList.length + 1}
    sx={(theme) => ({
      [`& .${avatarClasses.root}`]: {
        marginLeft: '-11px',
        border: `2px solid ${theme.background.bg1}`,
        boxSizing: 'border-box',
      },
    })}
  >
    {dropRight(avatarList, avatarList.length - (max || avatarList.length)).map(
      (avatar, index) => (
        <Avatar
          key={index}
          size={AvatarSizeTypes.M}
          alt={avatar.alt}
          src={avatar.src}
          sx={(theme) => ({
            bgcolor: theme.background.bg5,
            color: theme.text.t8,
          })}
        />
      )
    )}
    {avatarList.length - (max || avatarList.length) > 0 && (
      <Avatar
        size={AvatarSizeTypes.M}
        onClick={onClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant={TypographyType.b6}>
          {`+${avatarList.length - (max || 0)}`}
        </Typography>
      </Avatar>
    )}
  </MuiAvatarGroup>
);

export default AvatarGroup;
