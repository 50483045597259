import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { ADD_PAGE, CHANGE_PAGE, SET_ORDERS } from '../../actions/board/pagesActions';
import { CLEAR_SESSION, SESSION_DISCONNECTED } from '../../actions/session/sessionActions';
import GridType from '../../drawingActions/gridType';
import BACKGROUND_COLOR from '../../constants/boardBackgroundColor';

const initialState = {
  data: {
    0: {
      backgroundColor: 0,
      backgroundType: GridType.NONE,
      color: BACKGROUND_COLOR,
      id: 0,
      deleted: false,
    },
  },
  orders: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAGE:
      if (isEqual(state.data[action.payload.id], action.payload)) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    case CHANGE_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            backgroundColor: action.backgroundColor,
            backgroundType: action.backgroundType,
            color: action.color,
            deleted: action.deleted,
          },
        },
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: action.ids,
      };
    case CLEAR_SESSION:
    case SESSION_DISCONNECTED:
      return initialState;
    default:
      return state;
  }
};

const getPageOrders = state => state.board.pages.orders;
const getActualPageOrders = state => state.board.pages.orders.filter((item) => Boolean(state.board.pages.data[item]));
const getPage = (state, pageId) => state.board.pages.data[pageId];
const getAllPages = state => state.board.pages.data;

const getPages = createSelector(
  getPageOrders,
  getAllPages,
  (orders, pages) => orders.map(id => pages[id]).filter(page => !!page),
);

const getPageCount = createSelector(
  getPages,
  pages => pages.length,
);

const firstPageSelector = createSelector(
  getPageOrders,
  getAllPages,
  (orders, pages) => {
    const firstPageId = orders[0];
    return pages[firstPageId];
  },
);

export {
  getPageOrders,
  getActualPageOrders,
  getPage,
  getAllPages,
  getPages,
  getPageCount,
  firstPageSelector,
};
