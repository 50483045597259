import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '../../../atoms/Button';
import { ButtonSizeTypes, ButtonVariantTypes } from '../../../atoms/Button/buttonTypes';
import authService from '../../../../common/services/auth.service';
import passwordSchema from '../../../../common/utils/passwordSchema';
import PasswordField from '../../../../components/Forms/PasswordField';
import RepeatedPasswordField from '../../../../components/Forms/RepeatedPasswordField';

const ResetPasswordForm = () => {
  const [message, setMessage] = useState('');
  const [serverError, setServerError] = useState('');

  const { resetToken } = useParams<{ resetToken: string }>();

  const {
    handleSubmit,
    control,
    setError,
  } = useForm({
    defaultValues: {
      password: '',
      repeatedPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(passwordSchema),
  });

  const { isSubmitting } = useFormState({ control });

  const onSubmit = useCallback(handleSubmit(async ({ password, repeatedPassword }) => {
    try {
      const { message: serverMessage } = await authService.resetPassword(
        password,
        repeatedPassword,
        resetToken,
      );

      setMessage(serverMessage);
    } catch (error) {
      if (error.fields) {
        // Todo: change any type
        Object.entries(error.fields).forEach(([key, value]: any) => {
          setError(key, {
            message: value.message,
          });
        });

        return;
      }
      setServerError(error.message);
    }
  }), [
    resetToken,
    setError,
    setMessage,
    setServerError,
  ]);

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap="14px">
        <PasswordField control={control} />
        <RepeatedPasswordField control={control} />
        {
          serverError
          && (
            <Typography
              sx={{
                color: '#DC353C',
                ml: 0.5,
                mt: 0.5,
              }}
            >
              {serverError}
            </Typography>
          )}
        {message && (
          <Typography variant="p5" color="primary">
            {message}
          </Typography>
        )}
        <Box mt={1.25}>
          <Button
            type="submit"
            variant={ButtonVariantTypes.PRIMARY}
            size={ButtonSizeTypes.M}
            fullWidth
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ResetPasswordForm;
