import { createSelector } from 'reselect';
import {
  SET_CODE,
  SESSION_CONNECTED,
  SESSION_DISCONNECTED,
  SET_END_TIME,
  SET_START_TIME,
  SET_DIMENSIONS,
  SET_SESSION,
  SET_CONFERENCE,
  SET_SHARED,
  SET_FINISHED,
  UPDATE_SESSION,
  UPDATE_SESSION_OPTIONS,
  UPDATE_PUBLIC_LINK,
  SET_OPEN_SOURCE,
  INCREMENT_CANVAS_TOUCH,
  RESET_CANVAS_TOUCH,
  CHANGE_ASSESSMENT_INFO,
} from '../../actions/session/sessionActions';
import { participantsSelector } from './participantsReducer';
import * as userReducer from '../userReducer';
import {
  generateBaseConfiguration,
  generateCreateConfiguration,
  generateWarningConfiguration,
} from '../../utils/configuration.util';
import { SET_ORDERS } from '../../actions/board/pagesActions';
import BoardType from '../../services/types/session/BoardType';

const idInitialState = '';
const idReducer = (state = idInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return idInitialState;
    case SET_SESSION:
      return action.id;
    default:
      return state;
  }
};

const nameInitialState = '';
const nameReducer = (state = nameInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return nameInitialState;
    case SET_SESSION:
      return action.name;
    case UPDATE_SESSION:
      return action.session.name;
    default:
      return state;
  }
};

const connectedReducer = (state = false, action) => {
  switch (action.type) {
    case SESSION_CONNECTED:
      return true;
    case SESSION_DISCONNECTED:
      return false;
    default:
      return state;
  }
};

const codeInitialState = '';
const codeReducer = (state = codeInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return codeInitialState;
    case SET_CODE:
    case SET_SESSION:
      return action.code || '';
    case UPDATE_SESSION:
      return action.session.code || '';
    default:
      return state;
  }
};

const startTimeInitialState = null;
const startTimeReducer = (state = startTimeInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return startTimeInitialState;
    case SET_START_TIME:
      return action.startTime;
    default:
      return state;
  }
};

const endTimeInitialState = null;
const endTimeReducer = (state = endTimeInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return endTimeInitialState;
    case SET_END_TIME:
      return action.endTime;
    default:
      return state;
  }
};

const dimensionsInitialState = {
  width: window.innerWidth,
  height: window.innerHeight,
  dpi: 96,
};

const dimensionsReducer = (state = dimensionsInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return dimensionsInitialState;
    case SET_DIMENSIONS:
    case SET_SESSION:
      return action.displayMetrics;
    case UPDATE_SESSION:
      return {
        ...state,
        ...action.session.displayMetrics,
      };
    default:
      return state;
  }
};

const ownerIdInitialState = -1;
const ownerIdReducer = (state = ownerIdInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return ownerIdInitialState;
    case SET_SESSION:
      return action.ownerId;
    case UPDATE_SESSION:
      return action.session.ownerId;
    default:
      return state;
  }
};

const ownerInitialState = {
  id: '',
  name: '',
  profileImageUrl: '',
};
const ownerReducer = (state = ownerInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return ownerInitialState;
    case SET_SESSION:
      return {
        id: action.owner.id,
        name: action.owner.name,
        profileImageUrl: action.owner.profileImageUrl,
      };
    case UPDATE_SESSION:
      return {
        id: action.session.owner.id,
        name: action.session.owner.name,
        profileImageUrl: action.session.owner.profileImageUrl,
      };
    default:
      return state;
  }
};

const conferenceInProgressInitialState = false;
const conferenceInProgressReducer = (
  state = conferenceInProgressInitialState,
  action
) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return conferenceInProgressInitialState;
    case SET_CONFERENCE:
      return Boolean(action.payload);
    default:
      return state;
  }
};

const conferenceProviderInitialState = null;
const conferenceProviderReducer = (
  state = conferenceProviderInitialState,
  action
) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return conferenceProviderInitialState;
    case SET_CONFERENCE:
      return action.payload ? action.payload.provider : null;
    default:
      return state;
  }
};

const sharedInitialState = false;
const sharedReducer = (state = sharedInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return sharedInitialState;
    case SET_SESSION:
    case SET_SHARED:
      return action.shared;
    case UPDATE_SESSION:
      return action.session.shared;
    default:
      return state;
  }
};

const finishedInitialState = false;
const finishedReducer = (state = finishedInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return finishedInitialState;
    case SET_SESSION:
    case SET_FINISHED:
      return action.finished;
    case UPDATE_SESSION:
      return action.session.finished;
    default:
      return state;
  }
};

const chatInitialState = false;
const chatReducer = (state = chatInitialState, { type, payload }) => {
  switch (type) {
    case SESSION_DISCONNECTED:
      return chatInitialState;
    case UPDATE_SESSION_OPTIONS: {
      if (payload.chat === undefined) return state;

      return payload.chat;
    }
    default:
      return state;
  }
};

const drawingEnabledOnJoinInitialState = false;
const drawingEnabledOnJoinReducer = (
  state = drawingEnabledOnJoinInitialState,
  { type, payload }
) => {
  switch (type) {
    case SESSION_DISCONNECTED:
      return drawingEnabledOnJoinInitialState;
    case UPDATE_SESSION_OPTIONS: {
      if (payload.drawingEnabledOnJoin === undefined) return state;

      return payload.drawingEnabledOnJoin;
    }
    default:
      return state;
  }
};

const mutedOnJoinInitialState = false;
const mutedOnJoinReducer = (
  state = mutedOnJoinInitialState,
  { type, payload }
) => {
  switch (type) {
    case SESSION_DISCONNECTED:
      return mutedOnJoinInitialState;
    case UPDATE_SESSION_OPTIONS: {
      if (payload.mutedOnJoin === undefined) return state;

      return payload.mutedOnJoin;
    }
    default:
      return state;
  }
};

const trackPresenceInitialState = false;
const trackPresenceReducer = (state = trackPresenceInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return trackPresenceInitialState;
    case SET_SESSION:
      return action.trackPresence || trackPresenceInitialState;
    case UPDATE_SESSION:
      return action.session.trackPresence || trackPresenceInitialState;
    default:
      return state;
  }
};

const groupIdInitialState = '';
const groupIdReducer = (state = groupIdInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return groupIdInitialState;
    case SET_SESSION:
      return action.groupId;
    default:
      return state;
  }
};

const publicLinkInitial = null;
const publicLinkReducer = (state = publicLinkInitial, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return publicLinkInitial;
    case SET_SESSION:
      return action.publicLink;
    case UPDATE_SESSION:
      return action.session.publicLink;
    case UPDATE_PUBLIC_LINK:
      return action.publicLink;
    default:
      return state;
  }
};

const callEnabledInitialState = true;
const callEnabledReducer = (state = callEnabledInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return callEnabledInitialState;
    case SET_SESSION:
      return action.callEnabled;
    default:
      return state;
  }
};

const localRecordingEnabledInitialState = true;
const localRecordingEnabledReducer = (
  state = localRecordingEnabledInitialState,
  action
) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return localRecordingEnabledInitialState;
    case SET_SESSION:
      return action.localRecordingEnabled;
    default:
      return state;
  }
};

const questionEnabledInitialState = true;
const questionEnabledReducer = (
  state = questionEnabledInitialState,
  action
) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return questionEnabledInitialState;
    case SET_SESSION:
      return action.questionEnabled;
    default:
      return state;
  }
};

const assessmentInitialState = null;
const assessmentInfoReducer = (state = assessmentInitialState, action) => {
  switch (action.type) {
    case SESSION_DISCONNECTED:
      return assessmentInitialState;
    case CHANGE_ASSESSMENT_INFO:
      return action.info;
    default:
      return state;
  }
};
const configurationInitialState = {
  participant: {
    create: generateCreateConfiguration(),
    delete: generateBaseConfiguration(),
  },
  page: {
    create: generateCreateConfiguration(),
    edit: generateBaseConfiguration(),
    delete: generateBaseConfiguration(),
    warning: generateWarningConfiguration(),
  },
  importPDF: {
    import: generateCreateConfiguration(),
  },
};
const configurationReducer = (state = configurationInitialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      if (action.ids.length === state.page.create.count) return state;

      return {
        ...state,
        page: {
          ...state.page,
          create: {
            ...state.page.create,
            count: action.ids.length,
          },
        },
      };
    case SESSION_DISCONNECTED:
      return configurationInitialState;
    case SET_SESSION:
      return action.configuration;
    default:
      return state;
  }
};

const boardTypeInitialState = BoardType.BASIC;
const boardTypeReducer = (state = boardTypeInitialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return action.boardType || state;
    default:
      return state;
  }
};

const boardOpenSourceInitialState = 'Session link';
const boardOpenSourceReducer = (
  state = boardOpenSourceInitialState,
  action
) => {
  switch (action.type) {
    case SET_OPEN_SOURCE:
      return action.payload || state;
    default:
      return state;
  }
};

const canvasTouchCountInitialState = 0;
const canvasTouchCountReducer = (
  state = canvasTouchCountInitialState,
  action
) => {
  switch (action.type) {
    case INCREMENT_CANVAS_TOUCH:
      return state + 1;
    case RESET_CANVAS_TOUCH:
      return canvasTouchCountInitialState;
    default:
      return state;
  }
};

const participantsPageNavigationInitialState = false;
const participantsPageNavigationReducer = (
  state = participantsPageNavigationInitialState,
  { type, payload }
) => {
  switch (type) {
    case SESSION_DISCONNECTED:
      return participantsPageNavigationInitialState;
    case UPDATE_SESSION_OPTIONS: {
      if (payload.participantsPageNavigation === undefined) return state;

      return payload.participantsPageNavigation;
    }
    default:
      return state;
  }
};

const sessionIdSelector = (state) => state.session.id;
const nameSelector = (state) => state.session.name;
const ownerIdSelector = (state) => state.session.ownerId;
const ownerSelector = (state) => state.session.owner;
const sharedSelector = (state) => state.session.shared;
const finishedSelector = (state) => state.session.finished;
const dimensionsSelector = (state) => state.session.dimensions;
const connectedSelector = (state) => state.session.connected;
const codeSelector = (state) => state.session.code;
const startTimeSelector = (state) => state.session.startTime;
const endTimeSelector = (state) => state.session.endTime;
const isConferenceInProgressSelector = (state) =>
  state.session.conferenceInProgress;
const conferenceProviderSelector = (state) => state.session.conferenceProvider;
const isCurrentUserHostSelector = createSelector(
  ownerIdSelector,
  userReducer.userIdSelector,
  (ownerId, userId) => ownerId === userId
);

const chatSelector = (state) => state.session.chat;
const drawingEnabledOnJoinSelector = (state) =>
  state.session.drawingEnabledOnJoin;
const mutedOnJoinSelector = (state) => state.session.mutedOnJoin;
const participantsPageNavigationSelector = (state) =>
  state.session.participantsPageNavigation;
const trackPresenceSelector = (state) => state.session.trackPresence;
const groupIdSelector = (state) => state.session.groupId;
const publicLinkSelector = (state) => state.session.publicLink;
const callEnabledSelector = (state) => state.session.callEnabled;
const localRecordingEnabledSelector = (state) =>
  state.session.localRecordingEnabled;
const questionEnabledSelector = (state) => state.session.questionEnabled;
const boardOpenSourceSelector = (state) => state.session.boardOpenSource;
const canvasTouchCountSelector = (state) => state.session.canvasTouchCount;

const canCurrentUserDrawSelector = (state) =>
  !finishedSelector(state) &&
  (!state.session.connected ||
    (state.session.connected &&
      (isCurrentUserHostSelector(state) ||
        !!participantsSelector(state).find(
          (participant) =>
            participant.id === userReducer.userIdSelector(state) &&
            participant.drawingEnabled
        ))));

const configurationSelector = (state) => state.session.configuration;
const showPagesLimitInfoSelector = (state) =>
  state.session.configuration.page.warning.includes('list');
const pagesCountSelector = (state) =>
  state.session.configuration.page.create.count;
const pagesCountLimitSelector = (state) =>
  state.session.configuration.page.create.limit;

const boardTypeSelector = (state) => state.session.boardType;

const assessmentInfoSelector = (state) => state.session.assessmentInfo;

export default {
  id: idReducer,
  name: nameReducer,
  connected: connectedReducer,
  code: codeReducer,
  startTime: startTimeReducer,
  endTime: endTimeReducer,
  dimensions: dimensionsReducer,
  ownerId: ownerIdReducer,
  owner: ownerReducer,
  conferenceInProgress: conferenceInProgressReducer,
  conferenceProvider: conferenceProviderReducer,
  shared: sharedReducer,
  finished: finishedReducer,
  chat: chatReducer,
  drawingEnabledOnJoin: drawingEnabledOnJoinReducer,
  mutedOnJoin: mutedOnJoinReducer,
  participantsPageNavigation: participantsPageNavigationReducer,
  trackPresence: trackPresenceReducer,
  groupId: groupIdReducer,
  publicLink: publicLinkReducer,
  callEnabled: callEnabledReducer,
  localRecordingEnabled: localRecordingEnabledReducer,
  questionEnabled: questionEnabledReducer,
  configuration: configurationReducer,
  boardType: boardTypeReducer,
  boardOpenSource: boardOpenSourceReducer,
  canvasTouchCount: canvasTouchCountReducer,
  assessmentInfo: assessmentInfoReducer,
};

export {
  sessionIdSelector,
  nameSelector,
  ownerIdSelector,
  ownerSelector,
  sharedSelector,
  finishedSelector,
  dimensionsSelector,
  connectedSelector,
  codeSelector,
  startTimeSelector,
  endTimeSelector,
  isConferenceInProgressSelector,
  conferenceProviderSelector,
  isCurrentUserHostSelector,
  canCurrentUserDrawSelector,
  chatSelector,
  drawingEnabledOnJoinSelector,
  mutedOnJoinSelector,
  participantsPageNavigationSelector,
  trackPresenceSelector,
  groupIdSelector,
  publicLinkSelector,
  callEnabledSelector,
  localRecordingEnabledSelector,
  questionEnabledSelector,
  configurationSelector,
  showPagesLimitInfoSelector,
  pagesCountSelector,
  pagesCountLimitSelector,
  boardTypeSelector,
  boardOpenSourceSelector,
  canvasTouchCountSelector,
  assessmentInfoSelector,
};
