const formulaDrawer = (ctx, item) => {
  const {
    startPoint,
    image,
    width,
    height,
  } = item;
  const { x, y } = startPoint;
  ctx.save();

  if (image) {
    ctx.drawImage(
      image,
      x,
      y,
      width,
      height,
    );
  }

  ctx.restore();
};

export default formulaDrawer;
