import { FC } from 'react';
import Box from '@mui/material/Box';

type ScrollbarWrapperProps = {
  showOnHover?: boolean;
};

const ScrollbarWrapper: FC<React.PropsWithChildren<ScrollbarWrapperProps>> = ({
  showOnHover = false,
  children,
}) => (
  <Box
    sx={(theme) => ({
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.background.bg4,
        borderRadius: '16px',
      },
      overflowY: showOnHover ? 'hidden' : 'auto',
      margin: '0 4px',
      height: '100%',
      '&:hover': {
        overflowY: 'auto',
      },
    })}
  >
    {children}
  </Box>
);

export default ScrollbarWrapper;
