import { FC, useCallback, useMemo } from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NotificationButtonSize } from '../../atoms/NotificationButton/notificationButtonTypes';
import AlertIcon from '../../atoms/Icons/AlertIcon';
import { AlertColor } from '../Notification/notificationTypes';
import Notification from '../Notification';
import NotificationButton from '../../atoms/NotificationButton/NotificationButton';
import authenticationService from '../../../common/services/auth.service';
import * as userActions from '../../../common/actions/userActions';
import EmailFastIcon from '../../atoms/Icons/EmailFastIcon';
import ResendEmailVerificationDialog from '../ResendEmailVerificationDialog';
import { setVerificationTime } from '../../../common/actions/dashboardActions';
import { verificationTimeSelector } from '../../../common/reducers/dashboardReducer';
import getTimeLeft from '../../../common/utils/countDown.utils';
import {
  VerifyEmailNotificationProps,
  FactoryFunction,
} from './verifyEmailNotificationTypes';

const resendTimeInSeconds = 60;

const actionButtonFactory: FactoryFunction =
  (countdownTime, onCompleteTimer, handleClick) =>
  ({ color, children }) => {
    const theme = useTheme();
    const showXsButton = useMediaQuery(theme.breakpoints.up('sm'));

    if (countdownTime) {
      return (
        <NotificationButton
          size={
            showXsButton
              ? NotificationButtonSize.XS
              : NotificationButtonSize.XXS
          }
          hasIcon={false}
          sx={{
            minWidth: 0,
          }}
          disabled
        >
          <Countdown
            autoStart={!!countdownTime}
            date={Date.now() + resendTimeInSeconds * 1000}
            renderer={getTimeLeft}
            onComplete={onCompleteTimer}
          />
        </NotificationButton>
      );
    }

    return (
      <NotificationButton
        color={color}
        size={
          showXsButton ? NotificationButtonSize.XS : NotificationButtonSize.XXS
        }
        hasIcon={false}
        sx={{
          minWidth: 0,
        }}
        onClick={handleClick}
      >
        {children}
      </NotificationButton>
    );
  };

const VerifyEmailNotification: FC<
  React.PropsWithChildren<VerifyEmailNotificationProps>
> = ({ dialog, open, onClose }) => {
  const countdownTime = useSelector(verificationTimeSelector);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onCompleteTimer = useCallback(() => {
    dispatch(setVerificationTime(null));
  }, [dispatch]);

  const handleClick = useCallback(async () => {
    try {
      dispatch(setVerificationTime(Date.now()));
      await authenticationService.resendVerificationEmail();
      enqueueSnackbar('Verification mail sent!', {
        variant: 'success',
      });
    } catch (e) {
      if (e.code === '102002') {
        dispatch(setVerificationTime(null));
        enqueueSnackbar('Your account is already verified!', {
          variant: 'success',
        });
        dispatch(userActions.setVerified());
        if (onClose) {
          onClose();
        }
        return;
      }

      dispatch(setVerificationTime(null));
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, dispatch, onClose]);

  const ActionButton = useMemo(
    () => actionButtonFactory(countdownTime, onCompleteTimer, handleClick),
    [countdownTime, onCompleteTimer, handleClick]
  );

  return (
    <>
      {!dialog && (
        <Notification
          Icon={!countdownTime ? AlertIcon : EmailFastIcon}
          title={
            !countdownTime
              ? 'Please verify your account.'
              : 'We have sent you an email.'
          }
          message={
            !countdownTime
              ? 'If you have not received the email'
              : 'Please check your inbox'
          }
          actionText="Resend"
          color={AlertColor.WARNING}
          ActionButton={ActionButton}
        />
      )}
      {dialog && open && onClose && (
        <ResendEmailVerificationDialog
          open={open}
          onClose={onClose}
          handleClick={handleClick}
          countdownTime={countdownTime}
        />
      )}
    </>
  );
};

export default VerifyEmailNotification;
