import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiContainer, { ContainerProps } from '@mui/material/Container';
import { toolsBarHeight } from '../../../../../components/BoardControls/ToolsBar/ToolsBar';
import { collaborationBarWidthSelector } from '../../../../../common/reducers/uiReducer';
import { AssessmentPaperSidebarContext } from '../AssessmentPaperSidebarContext';

const StyledContainer = styled(MuiContainer)<ContainerProps>(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: '0 16px',
  [theme.breakpoints.up('sm')]: {
    padding: '0 24px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '980px',
  },
}));

export const AssessmentPaperLayout: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const collaborationBarWidth = useSelector(collaborationBarWidthSelector);
  const assessmentSidebarContext = useContext(AssessmentPaperSidebarContext);
  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        zIndex: 2000,
        left: assessmentSidebarContext.open ? '300px' : 0,
        top: 0,
        right: collaborationBarWidth,
        minHeight: '100%',
        backgroundColor: theme.background.bg2,
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.background.bg1,
          borderTop: `1px solid ${theme.background.bg3}`,
          width: '100%',
          height: toolsBarHeight,
        })}
      />
      <StyledContainer>{children}</StyledContainer>
    </Box>
  );
};
