import { FC } from 'react';
import MuiCircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const CircularProgress = styled(MuiCircularProgress)<CircularProgressProps>(
  () => ({
    position: 'absolute',
    zIndex: 99999,
    top: 'calc(50% - 36px)',
    left: 'calc(50% - 36px)',
  })
);

type LoaderProps = {
  size?: number;
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
};
const Loader: FC<React.PropsWithChildren<LoaderProps>> = ({
  size = 72,
  color = 'primary',
}) => <CircularProgress size={size} color={color} />;

export default Loader;
