import React, { ReactElement, useMemo } from 'react';
import { Participant, Track, VideoTrack } from 'livekit-client';
import Box from '@mui/material/Box';
import { StageProps } from './StageView';
import { useSortedParticipants } from '../../common/hooks/livekit/useSortedParticipants';
import ScreenShareView from './ScreenShareView';

export const SpeakerStage = ({
  roomState,
  participantRenderer: ParticipantRenderer,
  controlRenderer: ControlRenderer,
  onLeave,
  sortParticipants,
}: StageProps) => {
  const { isConnecting, error, participants, room } = roomState;

  const sortedParticipants = useSortedParticipants({
    sortFunction: sortParticipants,
    participants,
  });

  const screenTrack = useMemo(() => {
    if (!sortedParticipants.length) return;

    return sortedParticipants.reduce(
      (screenTrack: VideoTrack | null, participant: Participant) => {
        if (screenTrack) return screenTrack;

        const track = participant.getTrack(Track.Source.ScreenShare);
        if (track?.isSubscribed && track.videoTrack) {
          screenTrack = track.videoTrack;
        }

        return screenTrack;
      },
      null
    );
  }, [sortedParticipants]);

  const activeParticipants = useMemo(() => {
    if (screenTrack) return sortedParticipants;
    if (sortedParticipants.length === 1) return [];

    return sortedParticipants.slice(1);
  }, [sortedParticipants, screenTrack]);

  if (error) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Error {error.message}
      </Box>
    );
  }

  if (isConnecting) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Connecting
      </Box>
    );
  }
  if (!room) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Room closed
      </Box>
    );
  }

  if (sortedParticipants.length === 0) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        No one is in the room
      </Box>
    );
  }

  let participantInFocus: Participant;
  let mainView: ReactElement;
  [participantInFocus] = sortedParticipants;

  if (screenTrack) {
    mainView = (
      <ScreenShareView
        participant={participantInFocus}
        track={screenTrack}
        height="100%"
        width="100%"
      />
    );
  } else {
    [participantInFocus] = sortedParticipants;

    mainView = (
      <ParticipantRenderer
        key={participantInFocus.identity}
        participant={participantInFocus}
        width="100%"
        height="100%"
        orientation="landscape"
        showOverlay
      />
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto min-content',
        minHeight: 0,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `${
            activeParticipants.length ? 'repeat(12, 1fr)' : 'repeat(9, 1fr)'
          }`,
          gridTemplateRows: '100%',
          gap: '4px',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            gridColumn: '1 / 10',
          }}
        >
          {mainView}
        </Box>
        {activeParticipants.length && (
          <Box
            sx={{
              gridColumn: '10 / 13',
              display: 'grid',
              gap: '8px',
              gridAutoRows: 'min-content',
              overflowY: 'auto',
            }}
          >
            {activeParticipants.map((participant: Participant) => {
              return (
                <ParticipantRenderer
                  key={participant.identity}
                  participant={participant}
                  width="100%"
                  aspectWidth={16}
                  aspectHeight={9}
                  isSmallView
                  showOverlay
                />
              );
            })}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
          padding: '8px',
        }}
      >
        <ControlRenderer room={room} onLeave={onLeave} />
      </Box>
    </Box>
  );
};
