import { FC } from 'react';
import Box from '@mui/material/Box';
import IconButton from '../../atoms/IconButton';
import {
  ButtonColorTypes,
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../atoms/Button/buttonTypes';
import { SvgIconProps } from '@mui/material/SvgIcon';

type CardActionType = {
  Icon: FC<React.PropsWithChildren<SvgIconProps>>;
  props?: SvgIconProps;
  onClick: (...args: any[]) => void;
};

type Props = {
  cardActionsData: CardActionType[];
};

const CardActions: FC<React.PropsWithChildren<Props>> = ({
  cardActionsData,
}) => (
  <Box
    display="flex"
    gap={0.5}
    sx={{
      display: 'flex',
      flex: '0 0 auto',
      gap: '4px',
    }}
  >
    {cardActionsData.map(({ Icon, props = {}, onClick }, index) => (
      <IconButton
        key={index}
        variant={ButtonVariantTypes.TEXT}
        color={ButtonColorTypes.PRIMARY}
        size={ButtonSizeTypes.S}
        onClick={onClick}
      >
        <Icon {...props} />
      </IconButton>
    ))}
  </Box>
);

export default CardActions;
