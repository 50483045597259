import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ListItemProps as MuiListItemProps } from '@mui/material/ListItem/ListItem';
import { ListItemProps } from '../ListItem/listItemTypes';
import ListItem from '../ListItem';

type LinkButtonProps = ListItemProps & {
  navigateTo: string;
};

const LinkButton: FC<
  React.PropsWithChildren<LinkButtonProps & MuiListItemProps>
> = ({
  startIcon,
  title,
  subtitle,
  badgeVisible = false,
  endIcon,
  selected,
  onSelect,
  textPrimaryColor,
  navigateTo,
  listItemTextStyles,
  primaryTypographyProps,
  secondaryTypographyProps,
  ...props
}) => (
  <NavLink
    to={navigateTo}
    style={{
      textDecoration: 'none',
    }}
  >
    <ListItem
      startIcon={startIcon}
      title={title}
      subtitle={subtitle}
      badgeVisible={badgeVisible}
      endIcon={endIcon}
      selected={selected}
      onSelect={onSelect}
      textPrimaryColor={textPrimaryColor}
      listItemTextStyles={listItemTextStyles}
      primaryTypographyProps={primaryTypographyProps}
      secondaryTypographyProps={secondaryTypographyProps}
      {...props}
    />
  </NavLink>
);

export default LinkButton;
