import React, { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Dialog from '../../../ui/atoms/Dialog';
import DialogContent from '../../../ui/atoms/DialogContent';
import DialogTitle from '../../../ui/atoms/DialogTitle';
import keyMap, { KeyMap } from '../../../config/hotkeys';
import HotkeyBox from './HotkeyBox';
import Typography from '../../../ui/atoms/Typography';

const groupByName = (map: KeyMap, groupName: string) =>
  Object.keys(map)
    .filter((key) => map[key].group === groupName)
    .map((key) => map[key]);

type HotKeysDialogProps = {
  open: boolean;
  onClose: () => void;
  onExiting?: () => void;
};

const HotkeysDialog: FC<React.PropsWithChildren<HotKeysDialogProps>> = ({
  open,
  onClose,
  onExiting = () => {},
}) => {
  const drawingKeys = useMemo(() => groupByName(keyMap, 'Drawable'), [keyMap]);
  const generalKeys = useMemo(() => groupByName(keyMap, 'General'), [keyMap]);
  const fileKeys = useMemo(() => groupByName(keyMap, 'File'), [keyMap]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      TransitionProps={{
        onExiting,
      }}
    >
      <DialogTitle onClose={onClose}>Hotkeys</DialogTitle>
      <DialogContent>
        <Box display="flex" gap={7.5}>
          <Box display="flex" flexDirection="column" gap={1.75} flex={1}>
            <Typography variant="b5">Drawing</Typography>
            <Box display="flex" flexDirection="column">
              {drawingKeys.map((key) => (
                <HotkeyBox
                  key={key.name}
                  title={key.name || ''}
                  hotkey={key.description || ''}
                  Icon={key.icon}
                />
              ))}
            </Box>
          </Box>
          <Box flex={1}>
            <Box
              display="flex"
              flexDirection="column"
              gap={1.75}
              flex={1}
              marginBottom={2.5}
            >
              <Typography variant="b5">General</Typography>
              <Box display="flex" flexDirection="column">
                {generalKeys.map((key) => (
                  <HotkeyBox
                    key={key.name}
                    title={key.name || ''}
                    hotkey={key.description || ''}
                    Icon={key.icon}
                  />
                ))}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={1.75} flex={1}>
              <Typography variant="b5">File management</Typography>
              <Box display="flex" flexDirection="column">
                {fileKeys.map((key) => (
                  <HotkeyBox
                    key={key.name}
                    title={key.name || ''}
                    hotkey={key.description || ''}
                    Icon={key.icon}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HotkeysDialog;
