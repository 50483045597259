import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '../../atoms/Dialog';
import DialogTitle from '../../atoms/DialogTitle';
import DialogContent from '../../atoms/DialogContent';
import DialogActions, {
  DialogActionPrimaryButton,
  DialogActionSecondaryButton,
} from '../../atoms/DialogActions';
import Input from '../../atoms/Input';
import InputSize from '../../atoms/Input/InputSize';
import InputVariant from '../../atoms/Input/InputVariant';
import * as groupsActions from '../../../common/actions/groupsActions';
import groupsService from '../../../common/services/groups.service';
import InputStatus from '../../atoms/Input/InputStatus';

type Props = {
  open: boolean;
  onClose: () => void;
  group: any;
};

const RenameGroupDialog: FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  group,
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleChange = useCallback(
    (value: any) => {
      setName(value);
    },
    [setName]
  );

  const dispatch = useDispatch();

  const { control, setValue, handleSubmit, clearErrors } = useForm({
    defaultValues: {
      groupName: '',
    },
    reValidateMode: 'onSubmit',
  });

  const renameGroup = useCallback(
    handleSubmit(async () => {
      try {
        const updatedGroup = await groupsService.editGroup(group.id, name);
        dispatch(groupsActions.updateGroup(updatedGroup));
        onClose();
      } catch (e) {
        setError('Failed to find the group.');
      }
    }),
    [group, name, onClose, dispatch, setError]
  );

  const onEnter = useCallback(() => {
    setName(group.name);
    setValue('groupName', group.name);
    clearErrors('groupName');
  }, [setValue, clearErrors, setName, group]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEntering: onEnter,
      }}
    >
      <DialogTitle onClose={onClose} error={error}>
        Rename group
      </DialogTitle>
      <form onSubmit={renameGroup}>
        <DialogContent>
          <Controller
            name="groupName"
            control={control}
            rules={{
              pattern: {
                value: /^((?![/<>:"\\|?*]).)*$/g,
                message:
                  'Name can\'t contain any of the following characters:[/<>:"|?*]',
              },
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Input
                onChange={(boardName) => {
                  onChange(boardName);
                  handleChange(boardName);
                }}
                value={value}
                size={InputSize.M}
                variant={InputVariant.CONTAINED}
                status={errors.groupName ? InputStatus.ERROR : undefined}
                helperText={
                  errors.groupName ? errors.groupName.message : undefined
                }
                placeholder="Type the group name"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <DialogActionSecondaryButton onClick={onClose}>
            Cancel
          </DialogActionSecondaryButton>
          <DialogActionPrimaryButton
            type="submit"
            disabled={(group && name === group.name) || name === ''}
          >
            Rename
          </DialogActionPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenameGroupDialog;
