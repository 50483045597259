import React, { FC } from 'react';
import Box from '@mui/material/Box';
import ChatOutlineIcon from '../../../../../ui/atoms/Icons/ChatOutlineIcon';
import Typography from '../../../../../ui/atoms/Typography';
import CloseIcon from '../../../../../ui/atoms/Icons/CloseIcon';
import IconButton from '../../../../../ui/atoms/IconButton';
import {
  ButtonSizeTypes,
  ButtonVariantTypes,
} from '../../../../../ui/atoms/Button/buttonTypes';

type ChatTabHeaderProps = {
  onClose: () => void;
};

const ChatTabHeader: FC<React.PropsWithChildren<ChatTabHeaderProps>> = ({
  onClose,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={(theme) => ({
        backgroundColor: theme.background.bg1,
        borderBottom: `1px solid ${theme.background.bg3}`,
        height: 48,
        padding: '4px 4px 4px 16px',
      })}
    >
      <Box display="flex" alignItems="center" gap={0.5} flex={1}>
        <ChatOutlineIcon
          sx={{
            width: 18,
            height: 18,
          }}
        />
        <Typography variant="s4">Chat</Typography>
      </Box>
      <IconButton
        variant={ButtonVariantTypes.TEXT}
        size={ButtonSizeTypes.S}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default ChatTabHeader;
