import { FC, useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import Input from '../../../ui/atoms/Input';
import InputVariant from '../../../ui/atoms/Input/InputVariant';
import InputSize from '../../../ui/atoms/Input/InputSize';
import LockOutlineIcon from '../../../ui/atoms/Icons/LockOutlineIcon';
import EyeOutlineIcon from '../../../ui/atoms/Icons/EyeOutlineIcon';
import EyeOffOutlineIcon from '../../../ui/atoms/Icons/EyeOffOutlineIcon';
import InputStatus from '../../../ui/atoms/Input/InputStatus';

type Props = {
  control: any;
  size?: InputSize;
};

const PasswordField: FC<React.PropsWithChildren<Props>> = ({
  control,
  size = InputSize.M,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  return (
    <Controller
      name="password"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          value={value}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          variant={InputVariant.CONTAINED}
          size={size}
          placeholder="Password"
          label="Password"
          icon={<LockOutlineIcon />}
          endAdornmentPrimaryIcon={
            showPassword ? (
              <EyeOutlineIcon
                onClick={handleClickShowPassword}
                sx={(theme) => ({
                  color: theme.text.t6,
                })}
              />
            ) : (
              <EyeOffOutlineIcon
                onClick={handleClickShowPassword}
                sx={(theme) => ({
                  color: theme.text.t6,
                })}
              />
            )
          }
          status={error ? InputStatus.ERROR : undefined}
          helperText={error ? error.message : undefined}
        />
      )}
    />
  );
};

export default PasswordField;
