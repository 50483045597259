import { findLast } from 'lodash';
import fireBaseService from '../../services/firebaseService';
import {
  connectedSelector,
  isCurrentUserHostSelector,
} from '../../reducers/session/sessionReducer';
import {
  getPage,
  getAllPages,
  getPageOrders,
  firstPageSelector,
} from '../../reducers/board/pagesReducer';
import {
  getActivePageId,
  getActivePage,
} from '../../reducers/board/activePageReducer';
import { setActivePage } from './activePageActions';
import { setShouldUpdateThumbnail } from './shouldUpdateThumbnailActions';
import {
  lastActivePageSelector,
  rawActionsSelector,
} from '../../reducers/board/drawingActionsReducer';
import ActivePageAction from '../../drawingActions/pages/active.page.action';

const ADD_PAGE = Symbol('ADD_PAGE');
const CHANGE_PAGE = Symbol('CHANGE_PAGE');
const SET_ORDERS = Symbol('SET_ORDERS');

const addPage = ({
  id,
  backgroundColor,
  backgroundType,
  color,
  deleted = false,
  width = undefined,
  height = undefined,
}) => ({
  type: ADD_PAGE,
  payload: {
    id,
    backgroundColor,
    backgroundType,
    color,
    deleted,
    width,
    height,
  },
});

const updatePage = ({
  id,
  backgroundColor,
  backgroundType,
  color,
  deleted = false,
}) => ({
  type: CHANGE_PAGE,
  id,
  backgroundColor,
  backgroundType,
  color,
  deleted,
});

const changePage = ({
  id,
  backgroundColor,
  backgroundType,
  color,
  deleted = false,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const isHost = isCurrentUserHostSelector(state);
    const activePageId = getActivePageId(state);
    const pages = getAllPages(state);

    const orders = Object.values(pages).reduce((acc, current, index) => {
      if (!current.deleted && current.id !== id) return [...acc, current.id];

      return acc;
    }, []);

    if (!isHost && deleted) {
      if (id === activePageId) {
        const activePage = lastActivePageSelector(state);
        const lastActivePage = orders.includes(activePage)
          ? activePage
          : findLast(orders);
        dispatch(setActivePage(lastActivePage));
      }
      dispatch(setOrders(orders));
    }

    dispatch(
      updatePage({
        id,
        backgroundColor,
        backgroundType,
        color,
        deleted,
      })
    );
  };
};

const setOrders = (ids = []) => ({
  type: SET_ORDERS,
  ids,
});

const createPage = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const oldActivePage = getActivePage(state);
    const firstPage = firstPageSelector(state);
    if (firstPage && firstPage.id === oldActivePage.id) {
      dispatch(setShouldUpdateThumbnail(true));
    }

    const connected = connectedSelector(state);

    const props = {
      backgroundColor: 0,
      backgroundType: oldActivePage.backgroundType,
      color: oldActivePage.color,
    };

    if (connected) {
      return fireBaseService.createPage(props, oldActivePage.id);
    }

    const orders = getPageOrders(state);
    const pages = getAllPages(state);
    const newId = Object.keys(pages).length;

    const config = {
      id: newId,
      backgroundColor: 0,
      backgroundType: oldActivePage.backgroundType,
      color: oldActivePage.color,
      deleted: false,
      width: undefined,
      height: undefined,
    };

    dispatch(
      addPage({
        id: newId,
        ...config,
      })
    );

    const activePageIndex = orders.indexOf(oldActivePage.id);
    dispatch(
      setOrders([
        ...orders.slice(0, activePageIndex + 1),
        newId,
        ...orders.slice(activePageIndex + 1),
      ])
    );

    return dispatch(setActivePage(newId));
  };
};

const copyPage = (id) => (dispatch, getState) => {
  const state = getState();
  const actions = rawActionsSelector(state);
  const pageActions = actions.filter(
    (action) =>
      action.getPageNumber() === id && !(action instanceof ActivePageAction)
  );

  return fireBaseService.copyPage(id, pageActions);
};

const deletePage = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    const connected = connectedSelector(state);
    const firstPage = firstPageSelector(state);

    const deleteLocalPage = () => {
      const page = getPage(state, id);
      const props = { ...page, deleted: true };
      dispatch(updatePage(props));

      const orders = getPageOrders(state);
      const oldIndex = orders.indexOf(id);
      const newOrders = orders.filter((o) => o !== id);
      const activePageId = getActivePageId(state);

      if (activePageId !== id) {
        return dispatch(setOrders(newOrders));
      }

      let newActivePage = newOrders[oldIndex];
      if (!newActivePage) {
        newActivePage = newOrders[newOrders.length - 1];
      }

      dispatch(setActivePage(newActivePage));

      return dispatch(setOrders(newOrders));
    };

    if (connected) {
      return fireBaseService.deletePage(id).then(() => {
        if (firstPage && firstPage.id === id) {
          dispatch(setShouldUpdateThumbnail(true));
        }

        deleteLocalPage();
      });
    }

    return deleteLocalPage();
  };
};

const updateOrders = (orders = []) => {
  return (dispatch, getState) => {
    const state = getState();

    const connected = connectedSelector(state);
    if (connected) {
      return fireBaseService.setPageOrders(orders);
    }

    return dispatch(setOrders(orders));
  };
};

export {
  addPage,
  updatePage,
  setOrders,
  createPage,
  copyPage,
  deletePage,
  updateOrders,
  changePage,
  ADD_PAGE,
  CHANGE_PAGE,
  SET_ORDERS,
};
